import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState
} from 'react'

import { logger } from '../common/utils/logger'

type IFaqContext = {
  categories?: FaqCategoryDTO[]
  setCategories?: React.Dispatch<
    React.SetStateAction<IFaqContext['categories']>
  >
}

const warnMessage = () => {
  const warnMessageData = 'FaqProvider not found in the context'
  logger(warnMessageData)

  return warnMessageData
}

const FaqContext = createContext<IFaqContext>({})

/**
 *
 * @param props Component Props
 * @returns WrappedComponent
 */
export function FaqProvider(props: PropsWithChildren<unknown>) {
  const [categories, setCategories] = useState<IFaqContext['categories']>([])

  const faqProviderProps: IFaqContext = {
    categories,
    setCategories
  }

  return (
    <FaqContext.Provider value={faqProviderProps}>
      {props.children}
    </FaqContext.Provider>
  )
}

/**
 * @returns IFaqContext
 * @throws Error message
 */
export function useFaq(): IFaqContext {
  const context = useContext(FaqContext)

  if (!context) {
    throw new Error(warnMessage())
  }

  return context
}
