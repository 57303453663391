import { getAllCmsBanners } from '@volvo-apps/shared/core/actions/Banner'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all cms banners
 *
 * @returns bannersConfig, isLoading, refetch, isFetching
 */
export const useGetCmsBanners = () => {
  const {
    data: bannersConfig,
    isLoading,
    refetch,
    isFetching
  } = useQuery('cmsBanners', {
    queryFn: getAllCmsBanners,
    onError: handleErrorFeedback
  })

  return { bannersConfig, isLoading, refetch, isFetching }
}
