import { useHasPartsAndService } from '@volvo-apps/shared/core/hooks/partsAndServices'
import { useGetRentalContracts } from '@volvo-apps/shared/core/hooks/sales'
import { useHasAutbankContract } from '@volvo-apps/shared/core/hooks/user'

import React, { createContext, PropsWithChildren, useContext } from 'react'

import { useAuth } from './Auth'

type IFinancialMenuActionsContext = {
  hasAutbank: boolean | null
  hasRentalTruck: boolean | null
  hasPartsAndServices: boolean | null
  isMenuActionsLoading: boolean
}

const initialState: IFinancialMenuActionsContext = {
  hasAutbank: null,
  hasRentalTruck: null,
  hasPartsAndServices: null,
  isMenuActionsLoading: false
}

export const financialMenuActionsContext =
  createContext<IFinancialMenuActionsContext>(initialState)
const { Provider } = financialMenuActionsContext

/**
 *
 * @param props Component Props
 * @returns JSX
 */
export const FinancialMenuActionsProvider = (props: PropsWithChildren) => {
  const { children } = props
  const { hasLogin } = useAuth()

  const { data: hasAutbank = false, isLoading: hasAutbankLoading } =
    useHasAutbankContract({
      enabled: hasLogin,
      cacheTime: 0,
      retry: 0
    })

  const { data: rentalContracts = [], isLoading: getRentalContractsLoading } =
    useGetRentalContracts({
      enabled: hasLogin,
      cacheTime: 0,
      retry: 0
    })

  const {
    data: hasPartsAndServices = false,
    isLoading: hasPartsAndServicesLoading
  } = useHasPartsAndService({
    enabled: false,
    cacheTime: 0,
    retry: 0
  })

  const providerProps = {
    hasAutbank,
    hasRentalTruck: rentalContracts.length > 0,
    hasPartsAndServices,
    isMenuActionsLoading:
      hasAutbankLoading ||
      getRentalContractsLoading ||
      hasPartsAndServicesLoading
  }

  return <Provider value={providerProps}>{children}</Provider>
}

/**
 * @returns Context
 */
export const useFinancialMenuActions = () =>
  useContext(financialMenuActionsContext)
