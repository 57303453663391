/* eslint-disable react/prop-types */
import React, { MouseEvent } from 'react'

import { Box, Button } from '@mui/material'
import { useUser } from 'context'
import { useLocation } from 'react-router-dom'

import { Menu as UIMenu } from 'components/Menu/Menu'
import { Link, TitleWithIcon } from 'components/Project'

import { MenuItem } from 'routes/types'

import Colors from 'constants/Colors'

import { ReactComponent as User } from 'assets/icons/user.svg'

type Props = {
  items: MenuItem[]
}

const MenuButtonTrigger = (props: {
  open: boolean
  onClick?: (event: MouseEvent<HTMLElement>) => void
}) => {
  const { open, onClick } = props
  const { userData } = useUser()

  return (
    <Button
      sx={{ width: 'fit-content', margin: '0', padding: '0' }}
      onClick={onClick}
    >
      <TitleWithIcon
        label={userData?.name}
        icon={<User />}
        color={open ? Colors.light.interactActive : Colors.light.greys.GREY_600}
      />
    </Button>
  )
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Menu(props: Props) {
  const { items } = props

  const location = useLocation()
  const [menuItemOpen, setMenuItemOpen] = React.useState(false)

  const isCurrentRoute = (pathname: string) => {
    return location.pathname.startsWith(pathname)
  }

  return (
    <Box flexDirection="row" display="flex" gap={3}>
      {items.map((props, index) => {
        if (props.__typename === 'component') {
          return <React.Fragment key={index}>{props.component}</React.Fragment>
        }

        if (props.__typename === 'link') {
          const to = props.to as string
          const isActive = isCurrentRoute(to) && !menuItemOpen

          return (
            <Link key={index} to={to}>
              <TitleWithIcon
                {...props}
                label={props.label ?? ''}
                icon={props.icon}
                color={
                  isActive
                    ? Colors.light.interactActive
                    : Colors.light.greys.GREY_600
                }
              />
            </Link>
          )
        }

        if (props.__typename === 'menu') {
          return (
            <UIMenu
              key={index}
              triggerComponent={<MenuButtonTrigger open={menuItemOpen} />}
              options={props.options ?? []}
              stateCallback={setMenuItemOpen}
            />
          )
        }

        return null
      })}
    </Box>
  )
}

export default Menu
export { Menu }
