import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { Form, Input, InputCurrency } from 'components'
import { OrderSelect } from 'components/Form/Fields/OrderSelect'
import { PlainButton } from 'components/PlainButton'

import { useLanguage } from 'context/Language'

import { ProductLineData } from './schema'

type ProductLineFormProps = {
  onSubmit: (formData: ProductLineData) => void
  isLoading: boolean
  methods: UseFormReturn<ProductLineData, unknown>
  submitButtonLabel?: string | null
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function ProductLineForm(props: ProductLineFormProps) {
  const { onSubmit, isLoading, methods, submitButtonLabel } = props

  const { t } = useLanguage()

  const disabled = React.useMemo(
    () => !methods.formState.isValid || !methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '100%'
        })
      }}
    >
      <Input
        margin="normal"
        fullWidth
        label={t('cms.productLines.form.name')}
        name="name"
        autoFocus
        sx={{ mb: 3 }}
      />
      <OrderSelect
        label={t('cms.vehicles.form.order')}
        name="order"
        sx={{ width: '100%' }}
      />
      <InputCurrency
        margin="normal"
        fullWidth
        label={t('cms.vehicles.form.initialValue')}
        name="minimumValue"
        sx={{ mb: 3 }}
      />
      <PlainButton
        type="submit"
        fullWidth
        variant="contained"
        disabled={disabled}
        loading={isLoading}
        sx={{ mt: 3, mb: 2 }}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default ProductLineForm
export { ProductLineForm }
