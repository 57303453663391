import React from 'react'

import { InputMask, InputMaskProps } from 'components'

import { Document as DocumentMasks } from 'constants/Masks'

type DocumentProps = InputMaskProps & {
  documentType?: 'cpf' | 'cnpj'
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Document(props: DocumentProps) {
  const maskChanger = (value: string) => {
    const { documentType } = props

    if (!value || documentType) {
      return documentType === 'cpf' ? DocumentMasks.onlyCpf : DocumentMasks.cnpj
    }

    return value?.replace(/\D/g, '').length <= 11
      ? DocumentMasks.cpf
      : DocumentMasks.cnpj
  }

  return (
    <InputMask
      maskChanger={maskChanger}
      alwaysShowMask={false}
      maskPlaceholder=""
      {...props}
    />
  )
}

export default Document
export { Document }
