import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'
import FaqQuestionDTO from '@volvo-apps/shared/models/FaqQuestionDTO'
import { GenericService } from './GenericService'
import { AxiosResponse } from 'axios'

/**
 * FaqQuestion service
 */
export class FaqQuestionService extends GenericService<FaqQuestionDTO> {
  readonly URI: string = 'gateway/FaqQuestion'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * @returns Promise
   */
  getAll() {
    return this.get('GetAll')
  }

  /**
   * @param params all Params
   * @param params.categoryId Id Category
   * @returns Promise
   */
  getAllByCategory(params: { categoryId: FaqCategoryDTO['id'] }) {
    return this.get('GetAllByCategory', params)
  }

  /**
   *
   * @param id The id of the faq question
   */
  public async getFaqQuestion(id: number) {
    return super.get<AxiosResponse<FaqCategoryDTO>>('Get', { id })
  }

  /**
   * Delete faq category
   *
   * @param id The id of the faq question
   */
  public async deleteFaqQuestion(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Update faq question
   *
   * @param updatedFaqQuestion The updated faqQuestion
   */
  public async updateFaqQuestion(updatedFaqQuestion: FaqQuestionDTO) {
    return super.put<AxiosResponse<FaqQuestionDTO>>('Update', {
      ...updatedFaqQuestion
    })
  }

  /**
   * Create faq question
   *
   * @param categoryId The category id of faq question
   * @param title The title of faq question
   * @param description The description of faq question
   */
  public async createFaqQuestion(
    categoryId: number,
    title: string,
    description: string
  ) {
    return super.post<AxiosResponse<FaqQuestionDTO>>('Add', {
      categoryId,
      title,
      description
    })
  }

  /**
   * Get all cms faq question
   */
  public async getAllCmsFaqQuestion() {
    return super.get<AxiosResponse<FaqQuestionDTO[]>>('GetAllCms')
  }
}
