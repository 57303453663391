import { LinkProps, TitleWithIconProps } from 'components'
import { MenuProps } from 'components/Menu'

type MenuItemType =
  | {
      __typename: 'link'
      to?: LinkProps['to']
    }
  | {
      __typename: 'component'
      component?: JSX.Element
    }
  | {
      __typename: 'menu'
      options?: MenuProps['options']
    }

/**
 *
 */
export type MenuItem = MenuItemType &
  Partial<Pick<TitleWithIconProps, 'icon' | 'label'>>

/**
 *
 */
export type TabsProps = {
  /**
   *
   */
  label: string
  /**
   *
   */
  path: string
  /**
   *
   */
  disabled?: boolean
}

export enum ExternalRoutesEnum {
  CONSORTIUM = 'https://netatend-prd.siens.srv.volvo.com/',
  INSTITUTIONAL = 'http://www.volvogroup.com/br',
  PRIVACY_POLICY = 'https://www.vfsco.com/br/about-us/data-privacy.html',
  FINE_PORTAL = 'https://www.sistemamultas.com.br/app/',
  RENTAL_GENERAL_CONDITIONS = 'https://www.volvofinancialservices.com/content/dam/volvo-financial-services/markets/brazil/compliance/Condicoes-Gerais-Contrato-De-Locacao-n-623.256.pdf'
}
