import React from 'react'

import {
  Register,
  Home,
  Login,
  SideContent,
  AppContent,
  Dashboard,
  Faq,
  PasswordRetrieval,
  ConsortiumResources,
  IncomeReports
} from 'pages'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import { DashboardActionsProvider } from 'context/DashboardActions'
import { FaqProvider } from 'context/Faq'
import FaqDetail from 'pages/Faq/FaqDetail'

export enum AppRoutesEnum {
  MAIN = '/',
  LOGIN = '/login',
  DASHBOARD = '/dashboard',
  PASSWORD_RETRIEVAL = '/login/passwordretrieval',
  FAQ = '/faq',
  FAQ_DETAILS = '/faq/:id',
  REGISTER = '/register',
  CONSORTIUM_RESOURCES = '/consortiumresources',
  INCOME_REPORTS = '/income-reports'
}

const appRoutes: CustomRouteProps[] = [
  {
    path: AppRoutesEnum.MAIN,
    layout: SideContent,
    layoutProps: {
      footerHidden: true
    },
    element: <Home />
  },
  {
    path: AppRoutesEnum.DASHBOARD,
    protected: true,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '',
        label: 'breadCrumbs.home'
      }
    ],
    element: (
      <DashboardActionsProvider>
        <Dashboard />
      </DashboardActionsProvider>
    )
  },
  {
    path: AppRoutesEnum.LOGIN,
    layout: SideContent,
    noAuthOnly: true,
    element: <Login />
  },
  {
    path: AppRoutesEnum.FAQ,
    layout: AppContent,
    breadcrumbs: [
      {
        to: AppRoutesEnum.DASHBOARD,
        label: 'breadCrumbs.home'
      },
      {
        to: '/faq',
        label: 'breadCrumbs.faq'
      }
    ],
    protected: true,
    element: (
      <FaqProvider>
        <Faq />
      </FaqProvider>
    )
  },
  {
    path: AppRoutesEnum.FAQ_DETAILS,
    layout: AppContent,
    breadcrumbs: [
      {
        to: AppRoutesEnum.DASHBOARD,
        label: 'breadCrumbs.home'
      },
      {
        to: '/faq',
        label: 'breadCrumbs.faq'
      }
    ],
    protected: true,
    element: (
      <FaqProvider>
        <FaqDetail />
      </FaqProvider>
    )
  },
  {
    path: AppRoutesEnum.REGISTER,
    layout: SideContent,
    noAuthOnly: true,
    element: <Register />
  },
  {
    path: AppRoutesEnum.PASSWORD_RETRIEVAL,
    layout: SideContent,
    noAuthOnly: true,
    element: <PasswordRetrieval />
  },
  {
    path: AppRoutesEnum.CONSORTIUM_RESOURCES,
    layout: AppContent,
    noAuthOnly: true,
    element: <ConsortiumResources />,
    layoutProps: {
      hideHeader: true
    }
  },
  {
    path: AppRoutesEnum.INCOME_REPORTS,
    layout: AppContent,
    breadcrumbs: [
      {
        to: AppRoutesEnum.DASHBOARD,
        label: 'breadCrumbs.home'
      },
      {
        to: '/income-reports',
        label: 'breadCrumbs.incomeReports'
      }
    ],
    protected: true,
    element: <IncomeReports />
  }
]

export default appRoutes
