import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import Colors from 'constants/Colors'

import { conditionalFormat } from 'common/utils/documents'

/**
 *
 * @returns TSX
 */
export const columns: GridColDef[] = [
  {
    field: 'userName',
    headerName: 'cms.linkUsers.list.columns.user',
    width: 250
  },
  {
    field: 'email',
    headerName: 'cms.linkUsers.list.columns.email',
    width: 250
  },
  {
    field: 'document',
    headerName: 'cms.linkUsers.list.columns.document',
    width: 180,
    renderCell: (params: GridRenderCellParams) => {
      const document = params.row.document as string

      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {conditionalFormat(document)}
        </Typography>
      )
    }
  },
  {
    field: 'nameOrCompanyName',
    headerName: 'cms.linkUsers.list.columns.name',
    width: 250
  },
  {
    field: 'insertedDate',
    headerName: 'cms.linkUsers.list.columns.inclusionDate',
    width: 140,
    renderCell: (params: GridRenderCellParams) => {
      const date = params.row.insertedDate as Date

      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {dayjs(date).format('DD/MM/YYYY')}
        </Typography>
      )
    }
  }
]
