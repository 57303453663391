import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getInsuranceClaims } from '../../actions/Insurance'
import InsuranceClaimDTO from '../../../models/InsuranceClaimDTO'

type useGetInsuranceClaimsParams = HookQueryParams<InsuranceClaimDTO[]>

/**
 * Hook to get insurance claims
 *
 * @param params params
 * @returns insuranceClaims, isLoading, refetch
 */
export const useGetInsuranceClaims = (params: useGetInsuranceClaimsParams) => {
  const { onSuccess, onError } = params
  const {
    data: insuranceClaims,
    isLoading,
    isFetching,
    refetch
  } = useQuery('getInsuranceClaims', {
    queryFn: () => getInsuranceClaims(),
    onSuccess,
    onError
  })

  return { insuranceClaims, isLoading, refetch, isFetching }
}
