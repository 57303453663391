import { AxiosResponse } from 'axios'
import BannerConfigDTO from '@volvo-apps/shared/models/BannerConfigDTO'
import BannerDTO from '@volvo-apps/shared/models/BannerDTO'
import { GenericService } from './GenericService'

/**
 * Banner Service
 */
export class BannerService extends GenericService<BannerDTO> {
  readonly URI: string = 'gateway/Banner'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   *
   */
  public async getAll() {
    return super.get<AxiosResponse<BannerConfigDTO[]>>('GetAll')
  }

  /**
   *
   * @param bannerId Banner Id
   */
  public async getImageByBannerConfigId(bannerId: BannerDTO['id']) {
    return super.get<AxiosResponse<BannerDTO[]>>('GetImageByBannerConfigId', {
      id: bannerId
    })
  }

  /**
   *
   * @param id banner id
   */
  public async getUnique(id: number) {
    return super.get<AxiosResponse<BannerConfigDTO>>(`Get?id=${id}`)
  }

  /**
   * Create Banners
   *
   * @param bannerConfig Banners
   */
  public async createBanners(bannerConfig: BannerConfigDTO) {
    return super.post<AxiosResponse<BannerConfigDTO>>('Add', {
      ...bannerConfig
    })
  }

  /**
   * Update Banners
   *
   * @param bannerConfig Banners
   */
  public async updateBanners(bannerConfig: BannerConfigDTO) {
    return super.put<AxiosResponse<BannerConfigDTO>>('Update', {
      ...bannerConfig
    })
  }

  /**
   * Get All Cms Banners
   *
   */
  public async getAllCmsBanners() {
    return super.get<AxiosResponse<BannerConfigDTO[]>>('GetAllCms')
  }
}
