import { updateFaqQuestion } from '@volvo-apps/shared/core/actions/FaqQuestion'
import FaqQuestionDTO from '@volvo-apps/shared/models/FaqQuestionDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateFaqQuestionParams = {
  onSuccess?: () => void
}

/**
 * Hook to update one faq question
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useUpdateFaqQuestion = (
  params: useUpdateFaqQuestionParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data, mutateAsync } = useMutation(
    'updateFaqQuestion',
    {
      mutationFn: ({ faqQuestion }: { faqQuestion: FaqQuestionDTO }) =>
        updateFaqQuestion(faqQuestion),
      onSuccess,
      onError: handleErrorFeedback
    }
  )

  return { mutate, data, isLoading, mutateAsync }
}
