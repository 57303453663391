import UserReportByDocResponseDTO from '@volvo-apps/shared/models/UserReportByDocResponseDTO'

import React from 'react'

import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { conditionalFormat } from 'common/utils/documents'
import { useLanguage } from 'context/Language'

import { RowFlexBox } from './styles'

type UserListProps = {
  userData: UserReportByDocResponseDTO
}

/**
 * @param props Component Props
 * @returns TSX
 */
const UserList = (props: UserListProps) => {
  const { userData } = props

  const { t } = useLanguage()

  const handleDate = (date?: Date) => {
    return date ? dayjs(date).format('DD/MM/YYYY [às] HH:mm[h]') : ''
  }

  const makeRow = (label: string, value: string | number) => {
    return (
      <RowFlexBox>
        <Typography color={Colors.light.greys.GREY_700} fontSize={14}>
          {label}
        </Typography>
        <Typography color={Colors.light.text} fontSize={16}>
          {value}
        </Typography>
      </RowFlexBox>
    )
  }

  const makeChildrenComponent = (userData: UserReportByDocResponseDTO) => (
    <Box width="100%">
      {makeRow(
        t('cms.reports.userSearch.userList.columns.id'),
        userData.userId
      )}
      {makeRow(
        t('cms.reports.userSearch.userList.columns.cpf'),
        conditionalFormat(userData.userCPF)
      )}
      {makeRow(
        t('cms.reports.userSearch.userList.columns.name'),
        userData.userName
      )}
      {makeRow(
        t('cms.reports.userSearch.userList.columns.email'),
        userData.userEmailLogin
      )}
      {makeRow(
        t('cms.reports.userSearch.userList.columns.registrationDate'),
        handleDate(userData.creationDate)
      )}
      {makeRow(
        t('cms.reports.userSearch.userList.columns.validationDate'),
        handleDate(userData.validationDate)
      )}
      {makeRow(
        t('cms.reports.userSearch.userList.columns.lastAccessApp'),
        handleDate(userData.lastLoginAppDate)
      )}
      {makeRow(
        t('cms.reports.userSearch.userList.columns.lastAccessPortal'),
        handleDate(userData.lastLoginWebDate)
      )}
      {makeRow(
        t('cms.reports.userSearch.userList.columns.emailAutbank'),
        userData.userAutbankEmail
      )}
    </Box>
  )

  return (
    <FlexBox flexDirection="column">
      <Typography
        my={2}
        fontSize={16}
        fontFamily="Volvo-Novum-Medium"
        color={Colors.light.greys.GREY_600}
      >
        {t('cms.reports.userSearch.userList.title')}
      </Typography>

      {makeChildrenComponent(userData)}
    </FlexBox>
  )
}

export default UserList
export { UserList }
