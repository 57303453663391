import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * NpsUserDTO
 */
class NpsUserDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedDate!: string

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('userDescription')
  @jsonProperty(String)
  public userDescription!: string

  @jsonName('degree')
  @jsonProperty(Number)
  public degree!: number

  @jsonName('userid')
  @jsonProperty(Number)
  public userid!: number

  @jsonName('submitDate')
  @jsonProperty(String)
  public submitDate!: string

  @jsonName('npsId')
  @jsonProperty(Number)
  public npsId!: number

  @jsonName('userPath')
  @jsonProperty(String)
  public userPath!: string

  @jsonName('completed')
  @jsonProperty(Boolean)
  public completed!: boolean

  @jsonName('attempts')
  @jsonProperty(Number)
  public attempts!: number
}

export default NpsUserDTO
