import React from 'react'

import { Box, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon, svgIcons } from 'components/Icon'
import { Loader } from 'components/Loader'

import Colors from 'constants/Colors'

type CardProps = {
  title: string
  icon: keyof typeof svgIcons
  description: string
  isLoading?: boolean
}

/**
 *
 * @param props CardProps
 * @returns TSX
 */
function Card(props: CardProps) {
  const { title, icon, description, isLoading } = props

  return (
    <Box
      width="50%"
      py={1}
      pl={1}
      pr={3}
      border={`1px solid ${Colors.light.greys.GREY_200}`}
      borderRadius={0.5}
    >
      {isLoading ? (
        <Loader size={16} />
      ) : (
        <>
          <Typography color={Colors.light.interactActive} variant="h6">
            {title}
          </Typography>
          <FlexBox alignItems="center">
            <Icon
              family="svg"
              name={icon}
              sx={{ color: Colors.light.interactActive }}
            />
            <Typography
              color={Colors.light.greys.GREY_700}
              variant="body2"
              ml={1}
            >
              {description}
            </Typography>
          </FlexBox>
        </>
      )}
    </Box>
  )
}

export default Card
export { Card }
