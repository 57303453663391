import React, { useEffect } from 'react'

import { Typography } from '@mui/material'
import { useUser } from 'context'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { Form, Select } from 'components'
import { PlainButton } from 'components/PlainButton'

import { SimulationRoutesEnum } from 'routes/simulation.routes'

import Colors from 'constants/Colors'

import { getDocumentsOptionsByType } from 'common/validators/mappers'
import { useLanguage } from 'context/Language'
import { useUserSimulation } from 'context/Simulation'

import { documentSelectionSchema } from '../helpers'
import { DocumentSelectionForm } from '../types'

/**
 * @returns TSX
 */
function DocumentSelection() {
  const { userData } = useUser()
  const { t } = useLanguage()
  const { updateSimulation, clearSimulation } = useUserSimulation()
  const navigate = useNavigate()

  const methods = useForm<DocumentSelectionForm>({
    resolver: documentSelectionSchema
  })

  const documentTypes = [
    {
      label: t('simulation.documentSelection.physicalPerson'),
      value: 'CPF'
    },
    {
      label: t('simulation.documentSelection.legalPerson'),
      value: 'CNPJ'
    }
  ]

  const documents = React.useMemo(
    () => getDocumentsOptionsByType(userData?.documents).valid,
    [userData?.documents]
  )

  const onSubmit = (data: DocumentSelectionForm) => {
    const { documentType, documentNumber: document } = data
    updateSimulation({ documentType, document })
    navigate(SimulationRoutesEnum.CHOOSE_FINANCING)
  }

  useEffect(() => {
    clearSimulation()
  }, [clearSimulation])

  return (
    <Form
      methods={methods}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '100%'
        }),
        mt: 1,
        display: 'flex',
        flexDirection: 'column'
      }}
      onSubmit={onSubmit}
    >
      <Typography variant="body1" color={Colors.light.interactActive}>
        {t('simulation.documentSelection.title')}
      </Typography>
      <Typography variant="body2" my={2}>
        {t('simulation.documentSelection.description')}
      </Typography>
      <Select
        name="documentType"
        testId="document-type-select"
        margin="normal"
        fullWidth
        label={t('simulation.documentSelection.select')}
        options={documentTypes}
      />

      <Select
        name="documentNumber"
        testId="document-number-select"
        margin="normal"
        fullWidth
        label={t('simulation.documentSelection.cpfCnpj')}
        options={documents}
      />

      <PlainButton
        type="submit"
        testId="document-selection-next-button"
        variant="contained"
        disabled={!methods.formState.isValid}
        sx={{ mt: 4 }}
      >
        {t('general.next')}
      </PlainButton>
    </Form>
  )
}

export default DocumentSelection
export { DocumentSelection }
