import TempCmsRoutes from './cms.routes'
import leadRoutes from './lead.routes'
import parametersRoutes from './parameters.routes'
import registersRoutes from './registers.routes'
import reportsRoutes from './reports.routes'
import surveyRoutes from './survey.routes'
import userSettingRoutes from './userSettings.routes'

const cmsRoutes = [
  ...TempCmsRoutes,
  ...leadRoutes,
  ...surveyRoutes,
  ...userSettingRoutes,
  ...reportsRoutes,
  ...registersRoutes,
  ...parametersRoutes
]

export { breadcrumbs } from './breadcrumbs'
export default cmsRoutes
