import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getImportRentalContractsHistory } from '../../actions/Sales'
import RentalContractImportHistoryDTO from '../../../models/RentalContractImportHistoryDTO'

type useGetImportRentalContractsHistoryParams = HookQueryParams<
  RentalContractImportHistoryDTO[]
>

/**
 * Hook to get all import rental contracts history
 *
 * @param params params
 * @returns history, isLoading, refetch
 */
export const useGetImportRentalContractsHistory = (
  params: useGetImportRentalContractsHistoryParams = {}
) => {
  const { onSuccess, onError } = params
  const {
    data: history,
    isLoading,
    refetch
  } = useQuery('importRentalContractsHistory', {
    queryFn: getImportRentalContractsHistory,
    onSuccess,
    onError
  })

  return { history, isLoading, refetch }
}
