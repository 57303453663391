import { useGetInsuranceItems } from '@volvo-apps/shared/core/hooks/insurance'
import InsuranceItemDTO from '@volvo-apps/shared/models/InsuranceItemDTO'

import React from 'react'

import { Box, Divider, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import { FlexBox } from 'components/FlexBox'
import GenericList from 'components/List/GenericList'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
const Vehicles = () => {
  const { id } = useParams()

  const { t } = useLanguage()

  const { insuranceItems, isLoading } = useGetInsuranceItems({
    policyNumber: id ?? '',
    cacheTime: 0,
    onError: handleErrorFeedback
  })

  const makeRow = (label: string, value: string, backgroundColor: string) => {
    return (
      <FlexBox
        justifyContent="space-between"
        style={{ backgroundColor, display: 'flex' }}
        height={'24px'}
        alignItems={'center'}
        borderBottom={'1px'}
      >
        <Typography
          color={Colors.light.greys.GREY_600}
          fontSize={14}
          marginLeft={'5px'}
          marginRight={'5px'}
        >
          {label}
        </Typography>
        <Typography
          color={Colors.light.greys.GREY_700}
          fontWeight={700}
          fontSize={14}
          marginLeft={'5px'}
          marginRight={'5px'}
        >
          {value}
        </Typography>
      </FlexBox>
    )
  }

  const makeChildrenComponent = (item: InsuranceItemDTO, index: number) => (
    <Box width="100%">
      <FlexBox mb={2}>
        <Typography
          color={Colors.light.greys.GREY_500}
          fontWeight={700}
          fontSize={16}
        >
          {index + 1}.
        </Typography>
      </FlexBox>
      {makeRow(
        t('insurancePolicies.policies.vehicles.brand'),
        item.nomeFabricante,
        Colors.light.greys.GREY_BACKGROUND
      )}
      {makeRow(
        t('insurancePolicies.policies.vehicles.model'),
        item.descrModelo,
        Colors.light.background
      )}
      {makeRow(
        t('insurancePolicies.policies.vehicles.yearModel'),
        item.anoModelo,
        Colors.light.greys.GREY_BACKGROUND
      )}
      {makeRow(
        t('insurancePolicies.policies.vehicles.plate'),
        item.placa,
        Colors.light.background
      )}
      {makeRow(
        t('insurancePolicies.policies.vehicles.chassis'),
        item.chassi,
        Colors.light.greys.GREY_BACKGROUND
      )}
      <Divider
        orientation="horizontal"
        sx={{ mt: 2 }}
        color={Colors.light.greys.GREY_300}
      />
    </Box>
  )

  return (
    <ContentContainer
      title={t('insurancePolicies.policies.insuredVehicles').toUpperCase()}
    >
      <GenericList
        listItemStyle={{ paddingRight: 0, paddingLeft: 0 }}
        hideButton
        loading={isLoading}
        items={insuranceItems ?? []}
        makeChildrenComponent={makeChildrenComponent}
      />
    </ContentContainer>
  )
}

export default Vehicles
export { Vehicles }
