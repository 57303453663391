import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'
import { SalesVehicleDataDTO } from '../../models/SalesVehicleDataDTO'
import { SalesInvoiceDataDTO } from '../../models/SalesInvoiceDataDTO'
import RentalContractImportDTO from '../../models/RentalContractImportDTO'
import RentalContractImportHistoryDTO from '../../models/RentalContractImportHistoryDTO'
import { SalesInvoiceDTO } from '../../models/SalesInvoiceDTO'
import RentalContractDTO from '../../models/RentalContractDTO'
import RentalDetailsDTO from '../../models/RentalDetailsDTO'

/**
 * Login service
 */
export class SalesService extends GenericService<SalesVehicleDataDTO> {
  readonly URI: string = 'gateway/Sales'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   *
   * @param document The document
   */
  public async getVehiclesData(document: string) {
    return super.get<AxiosResponse<{ Response: SalesVehicleDataDTO }>>(
      `GetVehiclesData?document=${document}`
    )
  }

  /**
   *
   * @param document The document
   */
  public async getInvoicesData(document: string) {
    return super.get<AxiosResponse<{ Response: SalesInvoiceDataDTO }>>(
      `GetInvoicesData?document=${document}`
    )
  }

  /**
   * Import File
   *
   * @param formData FormData
   */
  public async importFile(formData: FormData) {
    return super.post<AxiosResponse<RentalContractImportDTO>>(
      'ImportFileAsync',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
  }

  /**
   * Get import rental contracts history
   */
  public async getImportRentalContractsHistory() {
    return super.get<AxiosResponse<RentalContractImportHistoryDTO[]>>(
      'GetImportContratosRentalHistory'
    )
  }

  /**
   * Delete import rental contract
   *
   * @param id The id of the import rental contract
   */
  public async deleteImportRentalContract(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   *
   * @param documentoCobranca The document
   * @param tipo The type
   * @param formaPagamento The payment method
   */
  public async getBoletoByInvoice(
    documentoCobranca: SalesInvoiceDTO['DocumentoCobranca'],
    tipo: SalesInvoiceDTO['Tipo'],
    formaPagamento: SalesInvoiceDTO['FormaPagamento']
  ) {
    return this.get<{ data: Blob }>(
      `getRentalBillFile`,
      {
        documentoCobranca,
        tipo,
        formaPagamento
      },
      { responseType: 'blob' }
    )
  }

  /**
   *
   */
  public async getRentalContracts() {
    return super.get<AxiosResponse<RentalContractDTO>>(`GetRentalContracts`)
  }

  /**
   * @param document Rental Details
   */
  public async getRentalDetails(document: string) {
    return super.get<AxiosResponse<RentalDetailsDTO>>(
      `GetRentalDetails?document=${document}`
    )
  }
}
