/**
 *
 */
import {
  checkFirstAccess,
  updateFirstAccess
} from '@volvo-apps/shared/core/actions/User'
import { useMutation, useQuery } from 'react-query'
import { useInvalidateQuery } from './useInvalidateQuery'
import { HookQueryParams } from '../../common/utils/types'

type useCheckFirstAccessParams = HookQueryParams<boolean> & {
  disableCheck?: boolean
}

/**
 * @param props props
 * @returns methods
 */
export const useCheckFirstAccess = (props: useCheckFirstAccessParams = {}) => {
  const { disableCheck, onSuccess, cacheTime } = props
  const { invalidate } = useInvalidateQuery()
  const {
    data: isFirstAccess = false,
    isLoading: checkFirstAccessLoading,
    refetch: refetchCheckFirstAccess
  } = useQuery<boolean>('CHECK_FIRST_ACCESS', checkFirstAccess, {
    enabled: !disableCheck,
    suspense: false,
    onSuccess,
    cacheTime
  })

  const { mutate: dismissFirstAccess, isLoading: updateFirstAccessLoading } =
    useMutation(updateFirstAccess, {
      onSuccess() {
        invalidate({ queryKey: 'CHECK_FIRST_ACCESS' })
      }
    })

  return {
    isFirstAccess,
    dismissFirstAccess,
    checkFirstAccessLoading,
    updateFirstAccessLoading,
    refetchCheckFirstAccess
  }
}
