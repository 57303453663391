import { useForm } from 'react-hook-form'

import { IncomeReportFormData, schema } from './schema'

type IncomeReportFormProps = {
  defaultValues?: IncomeReportFormData
}

/**
 *
 * @param props - IncomeReportFormProps
 * @returns useIncomeReportForm methods
 */
export const useIncomeReportForm = (props: IncomeReportFormProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<IncomeReportFormData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
