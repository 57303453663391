import ParameterDetailDTO from '@volvo-apps/shared/models/ParameterDetailDTO'

import React from 'react'

import { Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Loader } from 'components/Loader'

import Colors from 'constants/Colors'

import { toCurrency } from 'common/utils/money'
import { useLanguage } from 'context/Language'

import { InputNumberBox } from '../../Partials/InputNumberBox'

type InstallmentsProps = {
  installmentAmount?: number
  termMonths: number
  gracePeriodMonths: number
  gracePeriodDetail?: ParameterDetailDTO
  onChange: (value: number) => void
  disabled: boolean
  installmentsDetail: ParameterDetailDTO
}

/**
 *
 * @param props InstallmentsProps
 * @returns TSX
 */
function Installments(props: InstallmentsProps) {
  const {
    installmentAmount,
    onChange,
    disabled,
    termMonths,
    gracePeriodMonths,
    gracePeriodDetail,
    installmentsDetail: { minValue, maxValue, leapOfMonths }
  } = props

  const { t } = useLanguage()
  const installmentAmountCurrency = toCurrency(installmentAmount ?? 0)

  const _disabled = disabled || (!maxValue && !minValue)

  const _minValue = React.useMemo(() => {
    const maxGracePeriod = gracePeriodDetail?.maxValue ?? 0

    if (!maxGracePeriod) return minValue

    if (gracePeriodMonths === maxGracePeriod) return minValue + 3

    return gracePeriodMonths + 6
  }, [gracePeriodDetail?.maxValue, gracePeriodMonths, minValue])

  return (
    <FlexBox flexDirection="column" justifyContent="center" alignItems="center">
      <Typography mb={2} variant="h6" color={Colors.light.greys.GREY_600}>
        {t('simulation.financingAmounts.installments.title')}
      </Typography>

      <FlexBox alignItems="center" gap={12} justifyContent="start">
        <InputNumberBox
          minValue={_minValue}
          value={termMonths}
          onChange={onChange}
          label={t('simulation.financingAmounts.installments.label')}
          steps={leapOfMonths}
          maxValue={maxValue}
          disabled={_disabled}
        />
        <FlexBox minWidth="fit-content" flexDirection="column">
          <Typography variant="body1" color={Colors.light.interactActive}>
            {t('simulation.financingAmounts.installments.subtitle')}
          </Typography>

          <FlexBox alignItems="center">
            <Typography variant="body1" color={Colors.light.interactActive}>
              {installmentAmountCurrency?.symbol}
            </Typography>
            {installmentAmount ? (
              <Typography
                variant="h4"
                fontSize={32}
                color={Colors.light.interactActive}
              >
                {installmentAmountCurrency?.withoutSymbol}
              </Typography>
            ) : (
              <Loader
                size={16}
                sx={{ ml: 2 }}
                style={{ color: Colors.light.interactActive }}
              />
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default Installments
export { Installments }
