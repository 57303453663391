import { getAllTransitionInterval } from '@volvo-apps/shared/core/actions/Vehicle'
import TransitionIntervalDTO from '@volvo-apps/shared/models/TransitionIntervalDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetAllTransitionIntervalParams = {
  onSuccess?: (data: TransitionIntervalDTO[]) => void
}

/**
 * @param params - onSuccess
 * @returns Data and isLoading
 */
export const useGetAllTransitionInterval = (
  params: useGetAllTransitionIntervalParams = {}
) => {
  const { onSuccess } = params
  const { data, isLoading } = useQuery(
    'getAllTransitionInterval',
    getAllTransitionInterval,
    {
      onSuccess,
      onError: handleErrorFeedback
    }
  )

  return {
    data,
    isLoading
  }
}
