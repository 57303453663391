// --------------------------------------------------------
// GENERAL
// --------------------------------------------------------

import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'
import FaqQuestionDTO from '@volvo-apps/shared/models/FaqQuestionDTO'
import { FaqQuestionService } from '../service/FaqQuestion.service'
// --------------------------------------------------------

/**
 *
 */
export async function getAllQuestion() {
  const service = new FaqQuestionService()
  const response = (await service.getAll()) as {
    data: Record<string, unknown>[]
  }

  return response.data.map((cat) => new FaqQuestionDTO().fromJSON(cat))
}
/**
 *
 * @param params all Params
 * @param params.categoryId Id category
 */
export async function getQuestionsByCategory(params: {
  categoryId: FaqCategoryDTO['id']
}) {
  const service = new FaqQuestionService()
  const response = (await service.getAllByCategory(params)) as {
    data: Record<string, unknown>[]
  }

  return response.data.map((fq) => new FaqQuestionDTO().fromJSON(fq))
}
/**
 * @param id The id of the faq question
 * @returns faq question
 */
export async function getFaqQuestion(id: number) {
  const service = new FaqQuestionService()
  const response = await service.getFaqQuestion(id)
  return new FaqQuestionDTO().fromJSON(response.data)
}

/**
 * @param id The id of the faq question
 * @returns Delete faq question
 */
export async function deleteFaqQuestion(id: number) {
  const service = new FaqQuestionService()
  const response = await service.deleteFaqQuestion(id)

  return response
}

/**
 * @param updatedFaqQuestion The updated faq question
 */
export async function updateFaqQuestion(updatedFaqQuestion: FaqQuestionDTO) {
  const service = new FaqQuestionService()
  const response = await service.updateFaqQuestion(updatedFaqQuestion)

  return response
}

/**
 *
 * @param categoryId The category id of the faq question
 * @param title The title of the faq question
 * @param description The description of the faq question
 */
export async function createFaqQuestion(
  categoryId: number,
  title: string,
  description: string
) {
  const service = new FaqQuestionService()
  const response = await service.createFaqQuestion(
    categoryId,
    title,
    description
  )

  return response
}

/**
 * @returns All cms faq questions
 */
export async function getAllCmsFaqQuestion() {
  const service = new FaqQuestionService()
  const response = await service.getAllCmsFaqQuestion()

  return response.data.map((fp) => new FaqQuestionDTO().fromJSON(fp))
}
