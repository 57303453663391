import React from 'react'

import { Box, Typography } from '@mui/material'
import { useFieldArray } from 'react-hook-form'

import { Document, Input } from 'components'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

/**
 * @returns JSX
 */
function Step1() {
  const { t } = useLanguage()
  const { fields } = useFieldArray({ name: 'documents' })

  return (
    <>
      <Box mt={3}>
        <Typography
          fontSize={14}
          color={Colors.light.interactActive}
          fontWeight={500}
        >
          1 - {t('register.step1.title')}
        </Typography>
      </Box>
      <Box my={2}>
        <Input fullWidth label={t('register.step1.name')} name="name" />
      </Box>

      <Box my={2}>
        {fields.map((item, index) => (
          <Document
            key={item.id}
            documentType="cpf"
            fullWidth
            label={t('register.step1.cpf')}
            name={`documents[${index}].value`}
          />
        ))}
      </Box>
      <Box my={2}>
        <Input
          fullWidth
          autoComplete="username"
          label={t('register.step1.email')}
          name="email"
        />
      </Box>
    </>
  )
}

export default Step1
export { Step1 }
