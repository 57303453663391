const getDigitsFromValue = (value = '') =>
  value.replace(/(-(?!\d))|[^0-9|-]/g, '') || ''

const padDigits = (digits: string) => {
  const desiredLength = 3
  const actualLength = digits.length

  if (actualLength >= desiredLength) {
    return digits
  }

  const amountToAdd = desiredLength - actualLength
  const padding = '0'.repeat(amountToAdd)

  return padding + digits
}

const removeLeadingZeros = (number: string) =>
  number.replace(/^0+([0-9]+)/, '$1')

const addDecimalToNumber = (number: string, separator: string) => {
  const centsStartingPosition = number.length - 2
  const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition))
  const cents = number.substring(centsStartingPosition)
  return dollars + separator + cents
}

/**
 *
 * @param value Value to be converted to currency
 * @param decimalSeparator Decimal separator to be used
 * @param thousandSeparator Thousand separator to be used
 * @returns Currency string
 */
export const inputValueToCurrency = (
  value: string,
  decimalSeparator: string,
  thousandSeparator: string
) => {
  const digits = getDigitsFromValue(value)
  const digitsWithPadding = padDigits(digits)
  const digitWithDecimal = addDecimalToNumber(
    digitsWithPadding,
    decimalSeparator
  )

  return digitWithDecimal.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    `$1${thousandSeparator}`
  )
}

/**
 *
 * @param value Value to be converted to currency
 * @param options Intl.NumberFormatOptions
 * @returns Currency string
 */
export const toCurrency = (
  value: number,
  options?: Intl.NumberFormatOptions
) => {
  if (isNaN(value)) {
    return {
      withSymbol: '',
      withoutSymbol: '',
      symbol: ''
    }
  }

  const instance = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    ...options
  })

  const currencyStringByPartType = instance
    .formatToParts(value)
    .reduce<Record<'symbol' | 'all' | 'amount', string>>(
      (object, part) => {
        const isSymbol = part.type === 'currency'

        if (isSymbol) {
          object.symbol = part.value
        } else {
          object.amount = object.amount + part.value
        }

        object.all = object.all + part.value
        return object
      },
      { symbol: '', all: '', amount: '' }
    )

  return {
    withSymbol: currencyStringByPartType.all || '',
    withoutSymbol: currencyStringByPartType.amount || '',
    symbol: currencyStringByPartType.symbol || ''
  }
}

/**
 * @param value value to be converted
 * @returns currency
 */
export const stringToCurrency = (value: string) => {
  return value
    ? toCurrency(parseFloat(value.replace(',', '.'))).withSymbol
    : value
}

/**
 *
 * @param value Currency string to be converted to number
 * @returns Number
 */
export const currencyStringToNumber = (value: string) => {
  const digits = getDigitsFromValue(value)
  const decimalSeparator = '.'
  const digitsAndDecimal = addDecimalToNumber(digits, decimalSeparator)
  return Number(digitsAndDecimal)
}
