import React from 'react'

import { PlainButton, PlainButtonProps } from 'components/PlainButton'

import { ExternalRoutesEnum } from 'routes/types'

import Colors from 'constants/Colors'

import { openExternalLink } from 'common/utils/url'

import { ReactComponent as LinkExternal } from '../../../../assets/icons/linkExternal.svg'

type LinkButtonProps = {
  label: string
  href: ExternalRoutesEnum
  iconStyle?: React.CSSProperties
} & PlainButtonProps

/**
 *
 * @param props LinkButtonProps
 * @returns TSX
 */
const LinkButton = (props: LinkButtonProps) => {
  const { label, iconStyle, href, ...plainButtonProps } = props

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    openExternalLink(href)
    plainButtonProps.onClick?.(e)
  }

  return (
    <PlainButton
      startIcon={<LinkExternal style={{ ...iconStyle, marginRight: -16 }} />}
      {...plainButtonProps}
      onClick={handleClick}
      style={{
        paddingTop: 1,
        paddingBottom: 1,
        marginBottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.light.background,
        lineHeight: 1
      }}
      sx={{
        flexDirection: 'column',
        gap: 1,
        fontSize: 12
      }}
    >
      {label}
    </PlainButton>
  )
}

export default LinkButton
export { LinkButton }
