import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getInsuranceItems } from '../../actions/Insurance'
import InsuranceItemDTO from '../../../models/InsuranceItemDTO'

type useGetInsuranceItemsParams = HookQueryParams<InsuranceItemDTO[]> & {
  policyNumber: string
}

/**
 * Hook to get insurance items
 *
 * @param params params
 * @returns insuranceItems, isLoading, refetch
 */
export const useGetInsuranceItems = (params: useGetInsuranceItemsParams) => {
  const { onSuccess, onError, policyNumber, cacheTime, suspense } = params
  const {
    data: insuranceItems,
    isLoading,
    refetch
  } = useQuery('getInsuranceItems', {
    queryFn: () => getInsuranceItems(policyNumber),
    onSuccess,
    onError,
    cacheTime,
    suspense
  })

  return { insuranceItems, isLoading, refetch }
}
