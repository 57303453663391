import LeadDTO from '@volvo-apps/shared/models/LeadDTO'
import LeadNotificationDTO from '@volvo-apps/shared/models/LeadNotificationDTO'
import { LeadsService } from '@volvo-apps/shared/core/service/Lead.service'

/**
 * @param startDate The start date
 * @param endDate The end date
 * @returns LeadDTO[]
 */
export async function getAllLeads(startDate: string, endDate: string) {
  const service = new LeadsService()
  const response = await service.getAll(startDate, endDate)

  return response.data.map((lead) => new LeadDTO().fromJSON(lead))
}

/**
 * @param startDate The start date
 * @param endDate The end date
 * @returns LeadDTO[]
 */
export async function exportLeads(startDate: string, endDate: string) {
  const service = new LeadsService()
  const response = await service.exportLeads(startDate, endDate)

  return response.data
}

/**
 * @param leadNotification The notification
 * @returns LeadNotificationDTO
 */
export async function addNotification(leadNotification: LeadNotificationDTO) {
  const service = new LeadsService()
  const response = await service.addNotification(leadNotification)

  return new LeadNotificationDTO().fromJSON(response.data)
}

/**
 * @param updatedLeadNotification The updated lead notification
 */
export async function updateNotification(
  updatedLeadNotification: LeadNotificationDTO
) {
  const service = new LeadsService()
  const response = await service.updateNotification(updatedLeadNotification)

  return response
}

/**
 * @returns LeadNotificationDTO[]
 */
export async function getAllLeadNotifications() {
  const service = new LeadsService()
  const response = await service.getAllLeadNotifications()

  return response.data.map((leadNotification) =>
    new LeadNotificationDTO().fromJSON(leadNotification)
  )
}
