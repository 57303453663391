import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import 'reflect-metadata'

import VehicleImagesDTO from './VehicleImagesDTO'

/**
 * VehicleDTO
 */
class VehicleDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('name')
  @jsonProperty(String)
  public name!: string

  @jsonName('model')
  @jsonProperty(String)
  public model!: string

  @jsonName('productLineId')
  @jsonProperty(Number)
  public productLineId!: number

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: string

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: string

  @jsonName('vehicleHash')
  @jsonProperty(String)
  public vehicleHash?: string

  @jsonName('vehicleImagesConfigId')
  @jsonProperty(Number)
  public vehicleImagesConfigId?: number

  @jsonName('profilePictures')
  @jsonProperty(Array, null, undefined)
  public profilePictures?: VehicleImagesDTO[]
}

export default VehicleDTO
