import DocumentDTO from '@volvo-apps/shared/models/DocumentDTO'
import UserDTO from '@volvo-apps/shared/models/UserDTO'

import React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

import AccordionHeader from './AccordionHeader'
import Details from './Details'

type ContractManagementAccordionProps = {
  document: DocumentDTO
  user: UserDTO
}

const AccordionSummary = styled(
  ({ attached, ...props }: AccordionSummaryProps & { attached?: boolean }) => (
    <MuiAccordionSummary
      expandIcon={attached ? <Icon family="mui" name="ExpandMore" /> : <></>}
      {...props}
    />
  )
)(() => ({
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0px 0px 4px 0px'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    right: '16px',
    top: '12px'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    position: 'absolute',
    right: '16px',
    top: '20px'
  }
}))

/**
 *
 * @param props ContractManagementAccordionProps
 * @returns TSX
 */
function ContractManagementAccordion(props: ContractManagementAccordionProps) {
  const { document, user } = props
  const [expanded, setExpanded] = React.useState(false)

  const handleExpanded = React.useCallback(() => {
    setExpanded((prev) => !prev)
  }, [])

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpanded}
      sx={{
        border: `1px solid ${Colors.light.greys.GREY_200}`,
        boxShadow: `1px 1px 5px ${Colors.light.shadow}`
      }}
    >
      <AccordionSummary
        sx={{ mb: 0 }}
        attached={document.isContractsAttached || !document.isSignUpDocument}
      >
        <AccordionHeader
          document={document}
          user={user}
          boxProps={{ width: '100%' }}
        />
      </AccordionSummary>
      <AccordionDetails>
        {expanded && <Details documentDTO={document} />}
      </AccordionDetails>
    </Accordion>
  )
}

export default ContractManagementAccordion
export { ContractManagementAccordion }
