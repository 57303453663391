import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import { ChangePassword } from 'pages/ChangePassword'
import { ContractManagement } from 'pages/ContractManagement'
import { ExcludeAccount } from 'pages/ExcludeAccount'
import { AppContent } from 'pages/layout/AppContent'
import { Profile, ProfileEditMenu } from 'pages/Profile'
import { TermsAndConditions } from 'pages/TermsAndConditions'

export enum ProfileRoutesEnum {
  PROFILE = '/profile',
  PROFILE_EDIT = '/profile/edit',
  EXCLUDE_ACCOUNT = '/profile/excludeaccount',
  TERM_AND_CONDITIONS = '/profile/termsandconditions',
  CHANGE_PASSWORD = '/profile/changepassword',
  CHANGE_PASSWORD_SUCCESS = '/profile/changepassword/success',
  // CREDIT_AUTHORIZATION = '/profile/creditauthorization',
  LINK_DOCUMENTS = '/profile/linkdocuments'
}

const profileRoutes: CustomRouteProps[] = [
  {
    path: ProfileRoutesEnum.PROFILE,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: ProfileRoutesEnum.PROFILE,
        label: 'breadCrumbs.profile'
      }
    ],
    element: <Profile />
  },
  {
    path: ProfileRoutesEnum.PROFILE_EDIT,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: ProfileRoutesEnum.PROFILE,
        label: 'breadCrumbs.profile'
      },
      {
        to: ProfileRoutesEnum.PROFILE_EDIT,
        label: 'breadCrumbs.editProfile'
      }
    ],
    element: <ProfileEditMenu />
  },
  {
    path: ProfileRoutesEnum.EXCLUDE_ACCOUNT,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: '/profile',
        label: 'breadCrumbs.profile'
      },
      {
        to: '/profile/excludeaccount',
        label: 'breadCrumbs.excludeAccount'
      }
    ],
    protected: true,
    element: <ExcludeAccount />
  },
  {
    path: ProfileRoutesEnum.TERM_AND_CONDITIONS,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: '/profile',
        label: 'breadCrumbs.profile'
      },
      {
        to: '/profile/termsandconditions',
        label: 'breadCrumbs.termsAndConditions'
      }
    ],
    protected: true,
    element: <TermsAndConditions />
  },
  // {
  //   path: ProfileRoutesEnum.CREDIT_AUTHORIZATION,
  //   layout: AppContent,
  //   breadcrumbs: [
  //     {
  //       to: '/dashboard',
  //       label: 'breadCrumbs.home'
  //     },
  //     {
  //       to: '/profile',
  //       label: 'breadCrumbs.profile'
  //     },
  //     {
  //       to: '/profile/creditauthorization',
  //       label: 'breadCrumbs.creditAuthorization'
  //     }
  //   ],
  //   protected: true,
  //   element: <CreditAuthorizations />
  // },
  {
    path: ProfileRoutesEnum.CHANGE_PASSWORD,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: ProfileRoutesEnum.PROFILE,
        label: 'breadCrumbs.profile'
      },
      {
        to: ProfileRoutesEnum.CHANGE_PASSWORD,
        label: 'breadCrumbs.changePassword'
      }
    ],
    element: <ChangePassword />
  },
  {
    path: ProfileRoutesEnum.LINK_DOCUMENTS,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: ProfileRoutesEnum.PROFILE,
        label: 'breadCrumbs.profile'
      },
      {
        to: ProfileRoutesEnum.LINK_DOCUMENTS,
        label: 'breadCrumbs.linkDocuments'
      }
    ],
    element: <ContractManagement />
  }
]

export default profileRoutes
