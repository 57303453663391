import { enUS, ptBR } from 'i18n'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const defaultLanguage = 'pt'

export const resources = {
  en: {
    translation: enUS
  },
  pt: {
    translation: ptBR
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  react: {
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added',
    useSuspense: true
  },
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false
  }
})

export default i18n
