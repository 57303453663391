import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * TransitionIntervalDTO
 */
class TransitionIntervalDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('transitionTime')
  @jsonProperty(Number)
  public transitionTime!: number

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: string

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: string
}

export default TransitionIntervalDTO
