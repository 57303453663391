import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import 'reflect-metadata'

import BannerDTO from './BannerDTO'

/**
 * BannerDTO
 */
class BannerConfigDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedDate!: string

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('transitionTime')
  @jsonProperty(Number)
  public transitionTime!: number

  @jsonName('banners')
  @jsonProperty(Array, null, undefined)
  public banners!: BannerDTO[]
}

export default BannerConfigDTO
