import ParameterDetailDTO from '@volvo-apps/shared/models/ParameterDetailDTO'

/**
 *
 * @param parameters - ParameterDetailDTO[]
 * @returns filtered parameters
 */
export const filterParametersDetail = (parameters: ParameterDetailDTO[]) => {
  return parameters.reduce<{
    installments?: ParameterDetailDTO
    gracePeriod?: ParameterDetailDTO
  }>((acc, item) => {
    if (item.isInstallmentParameters && !acc.installments) {
      acc.installments = item
    } else if (!acc.gracePeriod) {
      acc.gracePeriod = item
    }
    return acc
  }, {})
}
