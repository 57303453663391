import NotificationDTO from '@volvo-apps/shared/models/NotificationDTO'
import { NotificationsService } from '../service/Notification.service'

/**
 * @returns NotificationDTO[]
 */
export async function getAllNotifications() {
  const service = new NotificationsService()
  const response = await service.getAll()

  return response.data.map((notifications) =>
    new NotificationDTO().fromJSON(notifications)
  )
}

/**
 * @param id The id of the notification
 * @returns notification
 */
export async function getNotification(id: number) {
  const service = new NotificationsService()
  const response = await service.getNotification(id)
  return new NotificationDTO().fromJSON(response.data)
}

/**
 * @param id The id of the notification
 * @returns Delete notification
 */
export async function deleteNotification(id: number) {
  const service = new NotificationsService()
  const response = await service.deleteNotification(id)

  return response
}

/**
 * @param updatedNotification The updated notification
 */
export async function updateNotification(updatedNotification: NotificationDTO) {
  const service = new NotificationsService()
  const response = await service.updateNotification(updatedNotification)

  return response
}

/**
 *
 * @param payload The payload to create the notification
 */
export async function createNotification(payload: NotificationDTO) {
  const service = new NotificationsService()
  const response = await service.createNotification(payload)

  return response
}

/**
 *
 *  @returns NotificationIntervalEnum[]
 */
export async function getIntervalEnums() {
  const service = new NotificationsService()
  const response = await service.getIntervalEnums()

  return response.data
}

/**
 *
 * @returns NotificationDayEnum[]
 */
export async function getDayEnums() {
  const service = new NotificationsService()
  const response = await service.getDayEnums()

  return response.data
}
