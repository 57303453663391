import React from 'react'

import { styled } from '@mui/material'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'

import Colors from 'constants/Colors'

/**
 * Tooltip Mui Styled to match the design system
 */
export const MuiTooltipStyled = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popperArrow: className }} />
  )
)(() => ({
  '& .MuiTooltip-arrow': {
    color: Colors.light.interactDefault
  },
  '& .MuiTooltip-tooltip': {
    backgroundColor: Colors.light.interactDefault,
    fontSize: 12,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
  }
}))
