import { changePasswordNewPassword } from '@volvo-apps/shared/core/actions/User'
import LoginDTO from '@volvo-apps/shared/models/LoginDTO'

import React, { useMemo, useState } from 'react'

import { Box, IconButton, InputAdornment, Typography } from '@mui/material'
import { useAuth } from 'context'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Form, Input, useModalMethods } from 'components'
import ContentContainer from 'components/ContentContainer/ContentContainer'
import { FormPasswordValidation } from 'components/FormPasswordValidation'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'

import useReCaptcha from 'hooks/useReCaptcha'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { ChangePasswordSuccess } from './ChangePasswordSuccess'
import { changePasswordSchema } from './helpers'

type changePasswordForm = Pick<LoginDTO, 'password'> & {
  oldPassword: string
  confirmPassword: string
}

/**
 *
 * @returns TSX
 */
function ChangePassword() {
  const { logout } = useAuth()
  const { open, setClosed, toggleOpen } = useModalMethods()
  const { t } = useLanguage()

  const [confirmOldPasswordVisible, setConfirmOldPasswordVisible] =
    useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const methods = useForm<changePasswordForm>({
    mode: 'onChange',
    resolver: changePasswordSchema,
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    }
  })
  const { isLoading, mutate, data } = useMutation(changePasswordNewPassword, {
    onSuccess: () => {
      toggleOpen()
    },

    onError: handleErrorFeedback
  })

  const { reCaptchaValue, ReCaptcha } = useReCaptcha()

  const handleModalSubmit = React.useCallback(() => {
    setClosed()
    logout()
  }, [setClosed, logout])
  const handleSubmit = React.useCallback(
    async ({ oldPassword, password }: changePasswordForm) => {
      mutate({
        oldPassword,
        password
      })
    },
    [mutate]
  )

  const isFormValid = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isValid, methods.formState.isDirty]
  )

  return (
    <ContentContainer
      boxProps={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Form
        methods={methods}
        onSubmit={handleSubmit}
        formProps={{
          sx: (t) => ({
            maxWidth: t.breakpoints.values.sm,
            width: '100%'
          }),
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography component="h1" variant="h6" align="left">
          {t('changePassword.changePassword')}
        </Typography>
        <Typography component="p" variant="body2" align="left" mt="1em">
          {t('changePassword.description')}
        </Typography>
        <Box mt={3}>
          <Input
            margin="normal"
            fullWidth
            name="oldPassword"
            label={t('changePassword.inputCurrentPassword')}
            type={confirmOldPasswordVisible ? 'text' : 'password'}
            id="oldPassword"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    onClick={() => setConfirmOldPasswordVisible((s) => !s)}
                  >
                    {confirmOldPasswordVisible ? (
                      <Icon family="mui" name="Visibility" />
                    ) : (
                      <Icon family="mui" name="VisibilityOff" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Input
            margin="normal"
            fullWidth
            name="password"
            label={t('general.insertNewPassword')}
            type={passwordVisible ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    onClick={() => setPasswordVisible((s) => !s)}
                  >
                    {passwordVisible ? (
                      <Icon family="mui" name="Visibility" />
                    ) : (
                      <Icon family="mui" name="VisibilityOff" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Input
            margin="normal"
            fullWidth
            name="confirmPassword"
            label={t('general.confirmNewPassword')}
            type={confirmPasswordVisible ? 'text' : 'password'}
            id="confirmPassword"
            autoComplete="current-password"
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    onClick={() => setConfirmPasswordVisible((s) => !s)}
                  >
                    {confirmPasswordVisible ? (
                      <Icon family="mui" name="Visibility" />
                    ) : (
                      <Icon family="mui" name="VisibilityOff" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <FormPasswordValidation />
          {isFormValid && ReCaptcha}

          <PlainButton
            type="submit"
            fullWidth
            variant="contained"
            disabled={!methods.formState.isValid || !reCaptchaValue}
            loading={isLoading}
            sx={{ mt: 2, mb: 3 }}
          >
            {t('passwordRetrieval.alterPassword1')}
          </PlainButton>
        </Box>
      </Form>
      {data && open && (
        <ChangePasswordSuccess setClosed={handleModalSubmit} user={data} />
      )}
    </ContentContainer>
  )
}

export default ChangePassword
export { ChangePassword }
