import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'
import BaseYearDTO from '../../models/BaseYearDTO'
import IncomeReportDTO from '../../models/IncomeReportDTO'

/**
 * IncomeReport
 */
export class IncomeReportService extends GenericService<BaseYearDTO> {
  readonly URI: string = 'gateway/IncomeReport'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * @param incomeReportDTO IncomeReportDTO
   * @returns Promise
   */
  getIncomeReportFile(incomeReportDTO: IncomeReportDTO) {
    return this.get<{ data: Blob }>(
      `GetIncomeReportFile`,
      {
        documentNumber: incomeReportDTO.documentNumber,
        year: incomeReportDTO.year,
        period: incomeReportDTO.period
      },
      { responseType: 'blob' }
    )
  }

  /**
   * Get all base year
   */
  public async getYears() {
    return super.get<AxiosResponse<BaseYearDTO[]>>('GetYears')
  }
}
