import React from 'react'

import { BoxProps, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

type EmptyListProps = {
  flexProps?: BoxProps
  emptyMessage: string
}

/**
 *  EmptyList component
 *
 * @param props EmptyListProps
 * @returns TSX
 */
const EmptyList = (props: EmptyListProps) => {
  const { flexProps, emptyMessage } = props
  return (
    <FlexBox justifyContent="start" mt={4} alignItems="center" {...flexProps}>
      <Icon
        family="svg"
        name="exclamation"
        changeViewBox
        compProps={{
          fill: Colors.light.blue
        }}
        style={{
          height: 32,
          width: 32,
          marginRight: 8
        }}
      />
      <Typography fontSize={16} fontFamily="Volvo-Novum-Medium">
        {emptyMessage}
      </Typography>
    </FlexBox>
  )
}

export default EmptyList
export { EmptyList }
