import * as React from 'react'

import { Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useQueryClient } from 'react-query'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

/**
 * Language Select
 *
 * @returns TSX
 */
const LanguageSelect = () => {
  const { lng, setLng, i18n } = useLanguage()

  const queryClient = useQueryClient()

  const options = {
    pt: 'PT (BR)',
    en: 'EN (US)'
  }

  const invalidateQueries = () => {
    queryClient.resetQueries({
      queryKey: ['getContractByUser']
    })
    queryClient.resetQueries({
      queryKey: ['getPartsAndServicesContractByUser']
    })
    queryClient.resetQueries({
      queryKey: ['getInsuranceClaims']
    })
  }

  const handleChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(lng)
    setLng?.(event.target.value)
    invalidateQueries()
  }

  const CustomIcon = ({ className, ...rest }: { className: string }) => (
    <Icon
      family="svg"
      name="arrowDown"
      style={{
        position: 'absolute',
        right: 7,
        height: 16,
        width: 16,
        pointerEvents: 'none'
      }}
      changeViewBox
      {...rest}
      className={className}
    />
  )

  return (
    <FormControl sx={{ minWidth: 120, height: 24 }} size="small">
      <Select
        value={lng}
        onChange={handleChange}
        defaultValue="pt"
        variant="outlined"
        displayEmpty
        style={{ height: 24, fontSize: 12 }}
        inputProps={{ style: { height: 24 } }}
        SelectDisplayProps={{ style: { fontSize: 12 } }}
        renderValue={(value) => {
          return (
            <FlexBox justifyContent="center" alignItems="center" gap={1}>
              <Icon
                family="svg"
                name="worldGlobe"
                style={{ width: 14, height: 14 }}
                changeViewBox
              />
              <Typography fontSize={12} color={Colors.light.greys.GREY_600}>
                {options[value]}
              </Typography>
            </FlexBox>
          )
        }}
        IconComponent={CustomIcon}
      >
        {Object.entries(options).map(([key, value]) => (
          <MenuItem
            key={key}
            value={key}
            style={{ fontSize: '12px !important' }}
            sx={{ fontSize: 12 }}
          >
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export { LanguageSelect }
