import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * TermDTO
 */
class TermDTO extends Serializable {
  @jsonName('accepted')
  @jsonProperty(Boolean)
  public accepted!: boolean

  @jsonName('termText')
  @jsonProperty(String)
  public termText!: string

  @jsonName('author')
  @jsonProperty(String)
  public author!: string

  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: Date

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: Date

  @jsonName('updatedDate')
  @jsonProperty(Date)
  public updatedDate!: Date

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean
}

export default TermDTO
