import React from 'react'

import { useAuth } from 'context'
import { useNavigate } from 'react-router-dom'

import { LanguageSelect } from 'components/LanguageSelect'

import { AppRoutesEnum } from 'routes/app.routes'
import { FinancialRoutesEnum } from 'routes/financial.routes'
import { ProfileRoutesEnum } from 'routes/profile.routes'
import { SimulationRoutesEnum } from 'routes/simulation.routes'
import { MenuItem } from 'routes/types'

import { ReactComponent as Faq } from 'assets/icons/faq-icon.svg'
import { ReactComponent as Home } from 'assets/icons/home.svg'
import { ReactComponent as Money } from 'assets/icons/money.svg'
import { ReactComponent as Truck } from 'assets/icons/truck.svg'
import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
export const useAppHeaderItems = () => {
  const navigate = useNavigate()
  const { logout } = useAuth()
  const { t } = useLanguage()
  const [showNps, setShowNps] = React.useState(false)

  const handleNpsCloseOnLogout = () => {
    navigate(AppRoutesEnum.LOGIN)
    logout()
  }

  const items: MenuItem[] = [
    {
      __typename: 'link',
      to: AppRoutesEnum.DASHBOARD,
      label: t('general.homepage'),
      icon: <Home />
    },
    {
      __typename: 'link',
      to: FinancialRoutesEnum.FINANCIAL_MENU,
      label: t('general.financial'),
      icon: <Money />
    },
    {
      __typename: 'link',
      to: SimulationRoutesEnum.SIMULATION_MENU,
      label: t('general.simulation'),
      icon: <Truck />
    },
    {
      __typename: 'menu',
      options: [
        {
          label: t('menu.accessConfigurations'),
          icon: 'settings',
          onClick: () => {
            navigate(ProfileRoutesEnum.PROFILE)
          }
        },
        {
          label: t('menu.logout'),
          icon: 'arrowLeft',
          onClick: () => {
            setShowNps(true)
          }
        }
      ]
    },
    {
      __typename: 'component',
      component: <LanguageSelect />
    },
    {
      __typename: 'link',
      to: AppRoutesEnum.FAQ,
      icon: <Faq />
    }
  ]

  return { items, showNps, handleNpsCloseOnLogout }
}
