import { useForm } from 'react-hook-form'

import { AutomationData, schema } from './schema'

type AutomationProps = {
  defaultValues?: Partial<AutomationData>
}

/**
 *
 * @param props - AutomationProps
 * @returns useForm methods
 */
export const useAutomationForm = (props: AutomationProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<AutomationData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
