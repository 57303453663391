import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'
import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'

/**
 * Category service
 */
export class CategoryService extends GenericService<FaqCategoryDTO> {
  readonly URI: string = 'gateway/Category'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * @returns Promise
   */
  getAll() {
    return this.get('GetAll')
  }

  /**
   *
   * @param id The id of the faq category
   */
  public async getFaqCategory(id: number) {
    return super.get<AxiosResponse<FaqCategoryDTO>>('Get', { id })
  }

  /**
   * Delete faq category
   *
   * @param id The id of the faq category
   */
  public async deleteFaqCategory(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Update faq category
   *
   * @param updatedFaqCategory The updated faqCategory
   */
  public async updateFaqCategory(updatedFaqCategory: FaqCategoryDTO) {
    return super.put<AxiosResponse<FaqCategoryDTO>>('Update', {
      ...updatedFaqCategory
    })
  }

  /**
   * Create faq category
   *
   * @param icon The icon of the category
   * @param label The label of the category
   * @param description The description of the category
   */
  public async createFaqCategory(
    icon: string,
    label: string,
    description: string
  ) {
    return super.post<AxiosResponse<FaqCategoryDTO>>('Add', {
      icon,
      label,
      description
    })
  }

  /**
   * Get all cms faq category
   */
  public async getAllCms() {
    return super.get<AxiosResponse<FaqCategoryDTO[]>>('GetAllCms')
  }
}
