import { getAllPermissions } from '@volvo-apps/shared/core/actions/UserRoles'

import { useQuery } from 'react-query'
import PermissionDTO from '../../../models/PermissionDTO'

type useGetAllPermissionsParams = {
  onSuccess?: (data: PermissionDTO[]) => void
  onError?: (error: Error) => void
}
/**
 * Hook to get all permissions from cms user roles
 *
 * @param params getAllPermissions params
 * @returns user roles, isLoading, refetch
 */
export const useGetAllPermissions = (
  params: useGetAllPermissionsParams = {}
) => {
  const { onError, onSuccess } = params
  const {
    data: permissions,
    isFetched,
    isLoading,
    refetch
  } = useQuery('getAllPermissions', {
    queryFn: getAllPermissions,
    onSuccess,
    onError,
    cacheTime: 0
  })

  return { permissions, isLoading, refetch, isFetched }
}
