import InsuranceClaimDTO from '@volvo-apps/shared/models/InsuranceClaimDTO'

import React from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { InsurancePoliciesRoutesEnum } from 'routes/insurancePolicies.routes'

/**
 * Hook to validate if the insurance claim page has all the required data
 *
 * @returns insurance claim
 */
export const useInsuranceClaimsLocation = () => {
  const navigate = useNavigate()
  const locationState = useLocation().state as {
    /**
     *
     */
    claim?: InsuranceClaimDTO
  }

  React.useEffect(() => {
    if (!locationState?.claim) {
      navigate(InsurancePoliciesRoutesEnum.CLAIMS)
    }
  }, [locationState, navigate])

  return locationState?.claim ?? new InsuranceClaimDTO()
}
