import { useMutation } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getScrByUser } from '../../actions/Scr'
import React from 'react'

type useGetScrByUserParams = HookQueryParams<string> & {
  ip?: string
  device?: string
}

type MutationParams = {
  ip: string
  device: string
}

/**
 * Hook to get scr by user
 *
 * @param params params
 * @returns scrHtml, isLoading, refetch
 */
export const useGetScrByUser = (params: useGetScrByUserParams) => {
  const { onSuccess, onError, retry } = params

  const request = React.useCallback(async ({ ip, device }: MutationParams) => {
    return getScrByUser(ip, device).then((res) => res)
  }, [])

  const {
    data: scrHtml,
    isLoading,
    mutate
  } = useMutation('getScrByUser', {
    mutationFn: request,
    retry,
    onSuccess,
    onError
  })

  return { scrHtml, isLoading, mutate }
}
