import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'

import React from 'react'

import { Box } from '@mui/material'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

import { Subtitle, Title, List } from 'components'
import { Loader } from 'components/Loader'

import useCategories from 'hooks/useCategories'
import useSearchBar from 'hooks/useSearchBar'

import { useFaq } from 'context/Faq'
import { useLanguage } from 'context/Language'

import { getIconFromString } from './helpers'

/**
 * @returns JSX
 */
function Faq() {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const { setCategories } = useFaq()
  const { searchTerm, searchBar } = useSearchBar({
    style: { margin: '24px 0 16px 0' }
  })

  const onSuccess = (data: FaqCategoryDTO[]) => {
    setCategories?.(data)
  }

  const { data, isLoading } = useCategories({ onSuccess })

  const items = React.useMemo(() => {
    return data
      ?.filter((item) =>
        _.deburr(item.label).match(new RegExp(searchTerm, 'ig'))
      )
      .map((item) => ({
        icon: getIconFromString(item.icon),
        primary: item.label,
        secondary: item.description,
        onClick: () => navigate('/faq/' + item.id)
      }))
  }, [data, navigate, searchTerm])

  return isLoading ? (
    <Loader />
  ) : (
    <Box width={(t) => t.breakpoints.values.md}>
      <Title>{t('faq.title')}</Title>
      <Box mt={2}>
        <Subtitle>{t('faq.subtitle')}</Subtitle>
      </Box>
      {searchBar}
      <Box>
        <List items={items} />
      </Box>
    </Box>
  )
}

export default Faq
export { Faq }
