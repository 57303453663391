import React from 'react'

import { Box, Typography } from '@mui/material'

import { ButtonLink } from 'components'

import { useLanguage } from 'context/Language'

import HeaderBackComponent from './HeaderBackComponent'

/**
 *
 * @returns TSX
 */
function PasswordRetrievalSuccess() {
  const { t } = useLanguage()

  return (
    <HeaderBackComponent>
      <Box>
        <Typography component="h1" variant="h4" align="center">
          {t('passwordRetrieval.completed')}
        </Typography>
        <ButtonLink to="/login/">{t('general.makeLogin')}</ButtonLink>
      </Box>
    </HeaderBackComponent>
  )
}
export default PasswordRetrievalSuccess
export { PasswordRetrievalSuccess }
