import React from 'react'

import dayjs from 'dayjs'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import { Detail } from 'components/Detail'

import { useInsuranceClaimsLocation } from 'hooks/useInsuranceClaimsLocation'

import { stringToCurrency } from 'common/utils/money'
import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
const Prize = () => {
  const { t } = useLanguage()
  const claim = useInsuranceClaimsLocation()

  return (
    <ContentContainer
      title={t('insurancePolicies.claims.prize.title').toUpperCase()}
    >
      <Detail
        rows={[
          {
            key: t('insurancePolicies.claims.prize.totalPrize'),
            value: stringToCurrency(claim.premioTotal)
          },
          {
            key: t('insurancePolicies.claims.prize.paymentMethod'),
            value: claim.documento
          },
          {
            key: t('insurancePolicies.claims.prize.installmentPaymentMethod'),
            value: claim.formaDePagamento
          },
          {
            key: t('insurancePolicies.claims.prize.installmentQuantity'),
            value: claim.quantidadeDeParcelas
          },
          {
            key: t('insurancePolicies.claims.prize.dueDate'),
            value: claim.dataVencPrimeira
              ? dayjs(claim.dataVencPrimeira, 'DD/MM/YYYY HH:mm:ss').format(
                  'DD/MM/YYYY'
                )
              : ''
          }
        ]}
      />
    </ContentContainer>
  )
}

export default Prize
export { Prize }
