import React from 'react'

import { Box } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { Loader } from 'components/Loader'

import { useTermsAndConditions } from 'hooks/useTermsAndConditions'

/**
 * @returns TSX
 */
function TermsAndConditions() {
  const { data, isLoading } = useTermsAndConditions()

  if (isLoading) {
    return <Loader boxProps={{ justifyContent: 'center' }} />
  }

  return (
    <Box width="765px">
      {data?.map((t, index) => (
        <ReactMarkdown key={index} rehypePlugins={[rehypeRaw]}>
          {t.termText}
        </ReactMarkdown>
      ))}
    </Box>
  )
}

export default TermsAndConditions
export { TermsAndConditions }
