import React from 'react'

import { Typography } from '@mui/material'
import { FieldError } from 'react-hook-form'

import { useLanguage } from 'context/Language'

type ErrorMessageProps = {
  error: FieldError
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  const { error } = props

  const { t } = useLanguage()

  return (
    <>
      {Object.values(
        error.types ?? {
          generic: error.message
        }
      ).map((errorMsg) => (
        <Typography
          key={errorMsg?.toString()}
          component="span"
          fontSize={12}
          color={(t) => t.palette.error.light}
        >
          &bull; {errorMsg ? t(String(errorMsg)) : ''}
        </Typography>
      ))}
    </>
  )
}

export default ErrorMessage
