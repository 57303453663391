import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'
import PolicyItemDTO from './PolicyItemDTO'

/**
 * PolicyDTO
 */
class PolicyDTO extends Serializable {
  @jsonProperty(String)
  policyNumber!: string

  @jsonProperty(String)
  itemCount!: string

  @jsonProperty(Array)
  policies!: Array<PolicyItemDTO>

  /**
   * Get first policy
   *
   * @returns PolicyItemDTO
   */
  public getFirstPolicy(): PolicyItemDTO {
    const firstPolicy = { ...this.policies[0] }

    if (Number(this.itemCount) > 1) {
      firstPolicy.nomefabricante =
        firstPolicy.descrModelo =
        firstPolicy.anoModelo =
        firstPolicy.placa =
          'Frota'
    }

    return new PolicyItemDTO().fromJSON(firstPolicy)
  }
}

export default PolicyDTO
