import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'
import RoleDTO from '../../models/RoleDTO'
import PermissionDTO from '../../models/PermissionDTO'

/**
 * UserRoles service
 */
export class UserRolesService extends GenericService<RoleDTO> {
  readonly URI: string = 'gateway/UserRoles'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all users roles
   *
   */
  public async getAll() {
    return super.get<AxiosResponse<RoleDTO[]>>('GetUserRoles')
  }

  /**
   * Update user roles
   *
   * @param updatedUserRole The updated user role
   */
  public async updateUserRole(updatedUserRole: RoleDTO) {
    return super.patch<AxiosResponse<RoleDTO>>('UpdateUserRole', {
      ...updatedUserRole
    })
  }

  /**
   * Delete user role
   *
   * @param id The id of the user role
   */
  public async deleteUserRole(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Create user role
   *
   * @param userRole The user role
   */
  public async createUserRole(userRole: RoleDTO) {
    return super.post<AxiosResponse<RoleDTO>>('AddUserRole', userRole)
  }

  /**
   * get user role by id
   *
   * @param id The user id
   */
  public async getUserRoleById(id: RoleDTO['id']) {
    return super.get<AxiosResponse<RoleDTO>>(
      'GetUserRolesById?' + 'userRoleId' + '=' + id
    )
  }

  /**
   *
   * get all permissions
   *
   */
  public async getAllPermissions() {
    return super.get<AxiosResponse<PermissionDTO[]>>('GetAllPermissions')
  }
}
