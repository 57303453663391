import { updateProfile } from '@volvo-apps/shared/core/actions/User'
import { useInvalidateQuery } from '@volvo-apps/shared/core/hooks'
import DocumentDTO from '@volvo-apps/shared/models/DocumentDTO'
import UserDTO from '@volvo-apps/shared/models/UserDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { Modal, useModalMethods } from 'components'
import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { ReactComponent as Trashcan } from 'assets/icons/trashcan.svg'
import { conditionalFormat } from 'common/utils/documents'
import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

type DeleteDocumentButtonProps = {
  user: UserDTO
  documentDTO: DocumentDTO
}

/**
 * Edit Document Button
 *
 * @param props DeleteDocumentButtonProps
 * @returns TSX
 */
const DeleteDocumentButton: React.FC<DeleteDocumentButtonProps> = (props) => {
  const { user, documentDTO } = props
  const { open, setClosed, toggleOpen } = useModalMethods()

  const { invalidate } = useInvalidateQuery()
  const { t } = useLanguage()

  const { mutateAsync, isLoading: updateProfileLoading } = useMutation({
    mutationFn: updateProfile,
    onError: handleErrorFeedback
  })

  const Content = React.useMemo(
    () => (
      <FlexBox flexDirection="column" justifyContent="center" mt={1}>
        <Typography color={Colors.light.greys.GREY_600} fontSize={16}>
          {t('configurations.contracts.modals.delete.description')}
        </Typography>

        <Typography
          color={Colors.light.greys.GREY_600}
          fontSize={16}
          fontFamily="Volvo-Novum-Medium"
        >
          {t('configurations.contracts.modals.delete.descripiontPart2', [
            conditionalFormat(documentDTO.document)
          ])}
        </Typography>
      </FlexBox>
    ),
    [documentDTO.document, t]
  )

  const handleOnSubmit = () => {
    if (!user || !user.documents) return

    let userDocumentsToSend: DocumentDTO[] = []

    if (documentDTO.isSignUpDocument) {
      userDocumentsToSend = user.documents.map((doc) => {
        if (doc.document === documentDTO.document) {
          return new DocumentDTO().fromJSON({
            ...doc,
            verified: false,
            isContractsAttached: false
          })
        }

        return doc
      })
    } else {
      userDocumentsToSend = user.documents.filter(
        (doc) => doc.document !== documentDTO.document
      )
    }

    const newUser = new UserDTO().fromJSON({
      ...user,
      documents: userDocumentsToSend
    })

    mutateAsync(newUser).then(() => {
      toast.success(t('profile.menu.editProfile.success'))
      setClosed()
      invalidate({ queryKey: 'getDocumentsWithContracts' })
      invalidate({ queryKey: 'user' })
    })
  }

  return (
    <FlexBox>
      <Trashcan
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          toggleOpen()
        }}
        width={24}
        height={24}
        style={{ marginRight: 20, color: Colors.light.error }}
      />

      <Modal
        open={open}
        showCloseButton
        onClose={setClosed}
        PaperProps={{ sx: { px: 1, py: 2, borderRadius: 2.5 } }}
        title={
          <Typography
            variant="body1"
            color={Colors.light.text}
            fontWeight={700}
            fontSize={24}
          >
            {t('configurations.contracts.modals.delete.title')}
          </Typography>
        }
        content={Content}
        actions={[
          {
            children: t('configurations.contracts.modals.delete.submit'),
            onClick: () => {
              handleOnSubmit()
            },
            loading: updateProfileLoading,
            disabled: updateProfileLoading,

            style: {
              backgroundColor: Colors.light.error,
              width: '100%'
            },
            variant: 'contained'
          },
          {
            children: t('configurations.contracts.modals.delete.cancel'),
            onClick: () => {
              setClosed()
            },
            disabled: updateProfileLoading,
            variant: 'outlined',
            sx: { color: Colors.light.interactActive, width: '100%' }
          }
        ]}
        actionsDirection="column"
        actionsGap={2}
      />
    </FlexBox>
  )
}

export default DeleteDocumentButton
export { DeleteDocumentButton }
