import { handleDocumentFilter } from '@volvo-apps/shared/common/utils/filterDocument'
import { useGetDocumentsWithContracts } from '@volvo-apps/shared/core/hooks/useGetDocumentsWithContracts'
import DocumentDTO from '@volvo-apps/shared/models/DocumentDTO'
import UserDTO from '@volvo-apps/shared/models/UserDTO'

import React, { useMemo } from 'react'

import _ from 'lodash'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import { EmptyList } from 'components/EmptyList'
import { FlexBox } from 'components/FlexBox'
import { Loader } from 'components/Loader'

import useSearchBar from 'hooks/useSearchBar'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { ContractManagementAccordion } from './ContractManagementAccordion'
import { NewDocumentButton } from './Modals'

/**
 * @returns TSX
 */
function ContractManagement() {
  const { t } = useLanguage()
  const { data, isLoading, isFetching } = useGetDocumentsWithContracts({
    onError: handleErrorFeedback
  })

  const { searchBar, searchTerm } = useSearchBar({
    style: { marginBottom: 32 }
  })

  const documents = useMemo(() => {
    const sortedDocuments = _.orderBy(
      data?.documents,
      ['isSignUpDocument'],
      ['desc']
    )
    return (
      sortedDocuments?.filter((document) => {
        if (document.isSignUpDocument && !document.isContractsAttached)
          return false

        return handleDocumentFilter(document, searchTerm, {
          verified: {
            true: t('configurations.contracts.status.authorized'),
            false: t('configurations.contracts.status.awaiting')
          }
        })
      }) ?? []
    )
  }, [data?.documents, searchTerm, t])

  if (isLoading || isFetching) {
    return <Loader />
  }

  return (
    <ContentContainer
      title={t('configurations.contracts.title').toUpperCase()}
      suffix={
        data && (
          <FlexBox style={{ position: 'absolute', right: 0 }}>
            <NewDocumentButton user={data} />
          </FlexBox>
        )
      }
    >
      {searchBar}
      {data && (
        <FlexBox gap={2} flexDirection="column" mb={2}>
          {documents.length > 0 ? (
            documents.map((document, index) => (
              <ContractManagementAccordion
                key={index}
                document={new DocumentDTO().fromJSON({ ...document })} // need to clone the object to avoid react query mutation
                user={new UserDTO().fromJSON({ ...data })} // need to clone the object to avoid react query mutation
              />
            ))
          ) : (
            <EmptyList emptyMessage={t('configurations.contracts.empty')} />
          )}
        </FlexBox>
      )}
    </ContentContainer>
  )
}

export default ContractManagement
export { ContractManagement }
