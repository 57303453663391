import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'

/**
 * InsuranceClaimDTO
 */
class InsuranceClaimDTO extends Serializable {
  @jsonProperty(String)
  status!: string

  @jsonProperty(String)
  situacao!: string

  @jsonProperty(String)
  documento!: string

  @jsonProperty(String)
  numeroSinistro!: string

  @jsonProperty(String)
  sinistroCia!: string

  @jsonProperty(String)
  apolice!: string

  @jsonProperty(String)
  seguradora!: string

  @jsonProperty(String)
  item!: string

  @jsonProperty(String)
  dataSinistro!: string

  @jsonProperty(String)
  dataAviso!: string

  @jsonProperty(String)
  dataLiquidacao!: string

  @jsonProperty(String)
  reclamante!: string

  @jsonProperty(String)
  proprietario!: string

  @jsonProperty(String)
  modelo!: string

  @jsonProperty(String)
  fabricante!: string

  @jsonProperty(String)
  ano!: string

  @jsonProperty(String)
  placa!: string

  @jsonProperty(String)
  chassi!: string

  @jsonProperty(String)
  condutor!: string

  @jsonProperty(String)
  habilitacao!: string

  @jsonProperty(String)
  nascimento!: string

  @jsonProperty(String)
  vencimento!: string

  @jsonProperty(String)
  categoria!: string

  @jsonProperty(String)
  dataAcidente!: string

  @jsonProperty(String)
  horaAcidente!: string

  @jsonProperty(String)
  local!: string

  @jsonProperty(String)
  estado!: string

  @jsonProperty(String)
  cidade!: string

  @jsonProperty(String)
  descricao!: string

  @jsonProperty(String)
  premioTotal!: string

  @jsonProperty(String)
  meioDePagamento!: string

  @jsonProperty(String)
  formaDePagamento!: string

  @jsonProperty(String)
  quantidadeDeParcelas!: string

  @jsonProperty(String)
  dataVencPrimeira!: string
}

export default InsuranceClaimDTO
