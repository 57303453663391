import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getAllCms } from '../../actions/Nps'
import NpsDTO from '../../../models/NpsDTO'

type useGetAllCmsNpsParams = HookQueryParams<NpsDTO[]>

/**
 * Hook to get all cms nps
 *
 * @param params params
 * @returns allAvailableNps, isLoading, refetch
 */
export const useGetAllCmsNps = (params: useGetAllCmsNpsParams) => {
  const { onSuccess, onError } = params
  const {
    data: allCmsNps,
    isLoading,
    refetch
  } = useQuery('getAllCms', {
    queryFn: getAllCms,
    onSuccess,
    onError
  })

  return { allCmsNps, isLoading, refetch }
}
