import React from 'react'

import { InputMask, InputMaskProps } from 'components'

import { Phone as PhoneMasks } from 'constants/Masks'

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Phone(props: InputMaskProps) {
  return (
    <InputMask
      maskChanger={(value) => {
        return value?.replace(/\D/g, '').length <= 10
          ? PhoneMasks.phone
          : PhoneMasks.cellphone
      }}
      alwaysShowMask={false}
      maskPlaceholder=""
      {...props}
    />
  )
}

export default Phone
export { Phone }
