import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { Form, Input } from 'components'
import { FlexBox } from 'components/FlexBox'
import { DaysSelect, IntervalSelect } from 'components/Form/Fields'
import { PlainButton } from 'components/PlainButton'

import { useLanguage } from 'context/Language'

import { AutomationData } from './schema'

type AutomationFormProps = {
  onSubmit: (formData: AutomationData) => void
  isLoading: boolean
  methods: UseFormReturn<AutomationData, unknown>
  submitButtonLabel?: string | null
  shouldSetInitialDayOfWeek?: boolean
}

const dailyIntervalLabel = 'Diariamente'
const dsdnIntervalLabel = 'DSDN'

/**
 *
 * @param props Props
 * @returns TSX
 */
function AutomationForm(props: AutomationFormProps) {
  const {
    onSubmit,
    isLoading,
    methods,
    submitButtonLabel,
    shouldSetInitialDayOfWeek
  } = props

  const { t } = useLanguage()

  const periodicity = methods.watch('interval')

  const disabled = React.useMemo(
    () => !methods.formState.isValid || !methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  const showDayOfWeekField =
    periodicity !== dailyIntervalLabel && periodicity !== dsdnIntervalLabel

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '100%'
        })
      }}
    >
      <IntervalSelect
        margin="normal"
        fullWidth
        label={t('cms.leads.automation.form.periodicity')}
        name="interval"
        labelAsValue
      />

      <FlexBox gap={2} mt={2}>
        {showDayOfWeekField && (
          <DaysSelect
            label={t('cms.leads.automation.form.day')}
            name="dayWeek"
            sx={{ width: '100%' }}
            labelAsValue
            shouldSetInitialFormValue={shouldSetInitialDayOfWeek}
          />
        )}
        <Input
          label={t('cms.leads.automation.form.hour')}
          name="hourDay"
          type="time"
          InputLabelProps={{
            shrink: true
          }}
          sx={{ width: '100%' }}
        />
      </FlexBox>

      <Input
        margin="normal"
        fullWidth
        label={t('cms.leads.automation.form.emails')}
        name="email"
      />
      <PlainButton
        type="submit"
        fullWidth
        variant="contained"
        disabled={disabled}
        loading={isLoading}
        sx={{ mt: 3, mb: 2 }}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default AutomationForm
export { AutomationForm }
