import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getAvailableNps } from '../../actions/Nps'
import NpsDTO from '../../../models/NpsDTO'

type useGetAvailableNpsParams = HookQueryParams<NpsDTO>

/**
 * Hook to get available nps
 *
 * @param params params
 * @returns availableNps, isLoading, refetch, isFetched
 */
export const useGetAvailableNps = (params: useGetAvailableNpsParams) => {
  const { onSuccess, onError, cacheTime, enabled, queryName } = params
  const {
    data: availableNps,
    isLoading,
    refetch,
    isFetched
  } = useQuery(queryName || 'getAvailableNps', {
    queryFn: getAvailableNps,
    retry: false,
    enabled,
    onSuccess,
    cacheTime,
    onError
  })

  return { availableNps, isLoading, refetch, isFetched }
}
