import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * ConsortiumClosedResourcesImportDTO
 */
class ConsortiumClosedResourcesImportDTO extends Serializable {
  @jsonName('nome')
  @jsonProperty(String)
  public nome!: string

  @jsonName('cpf')
  @jsonProperty(String)
  public cpf!: string

  @jsonName('cnpj')
  @jsonProperty(String)
  public cnpj!: string

  @jsonName('grupo')
  @jsonProperty(String)
  public grupo!: string

  @jsonName('quota')
  @jsonProperty(String)
  public quota!: string

  @jsonName('valorDevolver')
  @jsonProperty(Number)
  public valorDevolver!: number
}

export default ConsortiumClosedResourcesImportDTO
