import { createUserSimulationWithoutSave } from '@volvo-apps/shared/core/actions/UserSimulation'
import { CreateUserSimulationWithoutSaveType } from '@volvo-apps/shared/core/service/UserSimulation.service'
import SimulationCdcDTO from '@volvo-apps/shared/models/SimulationCdcDTO'
import SimulationFinameDTO from '@volvo-apps/shared/models/SimulationFinameDTO'
import UserSimulationDTO from '@volvo-apps/shared/models/UserSimulationDTO'

import _minBy from 'lodash/minBy'

import { safeParseJson } from '../../common/utils/object'

type CalculatorObjects = {
  totalFinanceAmount: number
  startupPaidPercent: number
  financeMonths: number
}

/**
 *
 * @param props Partial<UserSimulationDTO>
 * @returns Calculated value
 */
export const getStartupPaidAmount = (
  props: Pick<CalculatorObjects, 'totalFinanceAmount' | 'startupPaidPercent'>
) => {
  const { totalFinanceAmount, startupPaidPercent } = props
  return Math.ceil((totalFinanceAmount * startupPaidPercent) / 100)
}

/**
 *
 * @param props Partial<UserSimulationDTO>
 * @returns Calculated value
 */
export const getMonthlyAmount = (
  props: Pick<
    CalculatorObjects,
    'financeMonths' | 'startupPaidPercent' | 'totalFinanceAmount'
  >
) => {
  const { financeMonths, totalFinanceAmount } = props

  return Math.ceil(
    (totalFinanceAmount - getStartupPaidAmount(props)) / financeMonths
  )
}

/**
 *
 * @param simulation SimulationDTO
 * @returns Number
 */
export const getSmallestInstallmentValueFromSimulation = (
  simulation: UserSimulationDTO
) => {
  const simulationJsonParsed = safeParseJson(simulation.simulationJson)

  if (simulation.financialProductName === 'CDC') {
    const installment = _minBy(
      (simulationJsonParsed as SimulationCdcDTO).parcelas,
      (o) => Number(o.vlrFinanciado) + Number(o.vlrJuros)
    )

    return Number(installment?.vlrFinanciado) + Number(installment?.vlrJuros)
  } else if (simulation.financialProductName.startsWith('Finame')) {
    const installment = _minBy(
      (simulationJsonParsed as SimulationFinameDTO).parcelas.filter(
        (o) => o.tipoParcela === 'AMORTIZA'
      ),
      (o) => o.vlrParcela
    )
    return Number(installment?.vlrParcela)
  }

  return 0.1
}

/**
 *
 * @param simulation Partial<UserSimulationDTO>
 */
export const calculateMinInstallmentAmount = async (
  simulation: CreateUserSimulationWithoutSaveType
): Promise<number> => {
  return new Promise((resolve, reject) => {
    createUserSimulationWithoutSave(simulation)
      .then((data) => {
        resolve(getSmallestInstallmentValueFromSimulation(data))
      })
      .catch((err) => {
        reject(err)
      })
  })
}
