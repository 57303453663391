import React from 'react'

import { useNavigate } from 'react-router'

import { List, ListItemProps } from 'components'
import ContentContainer from 'components/ContentContainer/ContentContainer'

import { InsurancePoliciesRoutesEnum } from 'routes/insurancePolicies.routes'

import { useInsuranceClaimsLocation } from 'hooks/useInsuranceClaimsLocation'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function ClaimsMenu() {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const claim = useInsuranceClaimsLocation()

  const menuItems: ListItemProps[] = React.useMemo(
    () => [
      {
        primary: t('insurancePolicies.claims.menu.itemData'),
        onClick: () =>
          navigate(InsurancePoliciesRoutesEnum.CLAIMS_ITEM, {
            state: { claim }
          })
      },
      {
        primary: t('insurancePolicies.claims.menu.accidentData'),
        onClick: () =>
          navigate(InsurancePoliciesRoutesEnum.CLAIMS_ACCIDENT, {
            state: { claim }
          })
      },
      {
        primary: t('insurancePolicies.claims.menu.prize'),
        onClick: () =>
          navigate(InsurancePoliciesRoutesEnum.CLAIMS_PRIZE, {
            state: { claim }
          })
      }
    ],
    [claim, navigate, t]
  )

  return (
    <ContentContainer
      title={t('insurancePolicies.claims.menu.title', [
        claim.numeroSinistro
      ]).toUpperCase()}
    >
      <List items={menuItems} noPadding />
    </ContentContainer>
  )
}

export default ClaimsMenu
export { ClaimsMenu }
