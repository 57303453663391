import { getMunicipiosBySiglaEstado } from '@volvo-apps/shared/core/actions/User'

import { useQuery } from 'react-query'

/**
 *
 * @param state Current State
 * @returns Data
 */
export const useCityField = (state?: string) => {
  const { data: cidades = [], isLoading } = useQuery('cidades_' + state, {
    enabled: !!state,
    queryFn: () => {
      return getMunicipiosBySiglaEstado({ siglaEstado: state as string })
    }
  })

  return {
    cidades,
    isLoading
  }
}
