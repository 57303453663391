import _deburr from 'lodash/deburr'
import PolicyDTO from '../../models/PolicyDTO'
import PolicyItemDTO from '../../models/PolicyItemDTO'

const props: (keyof PolicyItemDTO)[] = [
  'apolice',
  'seguradora',
  'terminoVigencia',
  'nomefabricante',
  'descrModelo',
  'anoModelo',
  'placa',
  'situacao'
]

const match = (value: string, input: string) => {
  return _deburr(value).match(new RegExp(input, 'ig'))
}

/**
 *
 * @param policy PolicyDTO
 * @param input Input string
 * @returns Boolean
 */
export const handlePolicyFilter = (policy: PolicyDTO, input: string) => {
  let isValid = false

  if (match(policy.itemCount, input)) return true

  const item = policy.getFirstPolicy()

  for (const key of props) {
    const value = item[key]

    if (value === undefined) continue

    if (match(value as string, input)) {
      isValid = true
      break
    }
  }

  return isValid
}
