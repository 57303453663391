import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig
} from 'axios'
import {
  clearStorage,
  getAuthToken,
  getIsCms
} from '@volvo-apps/web/src/common/utils/storage'

import i18n from '@volvo-apps/web/src/i18n/i18nInstance'

import { API_FULL_PATH } from '@volvo-apps/web/src/constants/Env'

/**
 * The connection handler
 */
export class HttpHandle {
  private static instance: HttpHandle
  protected axiosInstance: AxiosInstance

  /**
   *
   */
  private constructor() {
    this.axiosInstance = axios.create({
      timeout: 50000,
      // cancelToken: (() => axios.CancelToken.source())().token,
      baseURL: API_FULL_PATH
    })

    this.setInterceptor()
  }

  /**
   * @returns the class instance
   */
  public static getInstance() {
    if (!HttpHandle.instance) {
      HttpHandle.instance = new HttpHandle()
    }

    return HttpHandle.instance
  }

  /**
   * @returns the axios object
   */
  public getAxios(): AxiosInstance {
    return this.axiosInstance
  }

  /**
   *
   */
  private setInterceptor() {
    this.axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const axiosToken = await getAuthToken()

        const isEnglish =
          i18n.languages.length > 0 && i18n.languages[0].includes('en')

        config.headers = {
          ...config.headers,
          'Accept-Language': isEnglish ? 'en-US' : 'pt-BR',
          Authorization: 'Bearer ' + axiosToken ?? 'jwt_token'
        }
        return config
      },
      (error: AxiosError) => {
        return error
      }
    )

    this.axiosInstance.interceptors.response.use(
      (
        response: AxiosResponse<
          Record<string, unknown>,
          Record<string, unknown>
        >
      ) => {
        return response
      },
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          const adminPath = '/admin/login'
          const userPath = '/login'
          const currentPage = window.location.pathname
          const urlParams = '?reason=loginRequired&continue=' + currentPage
          const path = getIsCms() ? adminPath : userPath

          try {
            if (![adminPath, userPath].includes(currentPage)) {
              clearStorage()
              window.location.replace(path + urlParams)
            }
          } catch {}
        }

        return Promise.reject(error)
      }
    )
  }
}
