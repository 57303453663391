import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import UserCmsAccessDTO from '../../../models/UserCmsAccessDTO'
import {
  getUsersListWithDocumentCms,
  resentUserRegistrationMail,
  resetUserPassword
} from '../../actions/User'
import UserWithDocumentDTO from '../../../models/UserWithDocumentDTO'

type useGetUserListParams = HookQueryParams<UserWithDocumentDTO[]>

const queryKey = 'GetUserListWithDocumentCms'

/**
 *
 * @param params params
 * @returns userList, isLoading, refetch
 */
export const useGetUserListWithDocumentCms = (
  params: useGetUserListParams = {}
) => {
  const { data: userList, ...rest } = useQuery(queryKey, {
    queryFn: getUsersListWithDocumentCms,
    ...params
  })

  return { userList, ...rest }
}

/**
 *
 * @param params useMutation props
 * @returns mutate return
 */
export const useResetUserPasswordMutation = (
  params?: Omit<
    UseMutationOptions<
      UserCmsAccessDTO,
      unknown,
      string,
      {
        cachedData: UserCmsAccessDTO[] | undefined
      }
    >,
    'onMutate' | 'onSuccess' | 'onSettled'
  >
) => {
  const queryClient = useQueryClient()

  return useMutation(resetUserPassword, {
    onSuccess: async (data, document) => {
      // Update current cache that has this queryKey
      queryClient.setQueryData<UserCmsAccessDTO[]>(queryKey, (prevState) => {
        const cachedData = prevState || [data]

        const updatedCachedData = cachedData.map((userData) => {
          if (userData.document === document) {
            return new UserCmsAccessDTO().fromJSON({
              ...userData,
              resetPasswordTime: data.resetPasswordTime
            })
          }

          return userData
        })

        return updatedCachedData
      })
    },
    onSettled: () => {
      // Revalidate the query after mutation
      queryClient.invalidateQueries(queryKey)
    },
    ...params
  })
}

/**
 *
 * @param params useMutation props
 * @returns mutate return
 */
export const useResendUserRegistrationMailMutation = (
  params?: Omit<
    UseMutationOptions<
      UserCmsAccessDTO,
      unknown,
      string,
      {
        cachedData: UserCmsAccessDTO[] | undefined
      }
    >,
    'onMutate' | 'onSuccess' | 'onSettled'
  >
) => {
  const queryClient = useQueryClient()

  return useMutation(resentUserRegistrationMail, {
    onSuccess: async (data, document) => {
      // Update current cache that has this queryKey
      queryClient.setQueryData<UserCmsAccessDTO[]>(queryKey, (prevState) => {
        const cachedData = prevState || [data]

        const updatedCachedData = cachedData.map((userData) => {
          if (userData.document === document) {
            return new UserCmsAccessDTO().fromJSON({
              ...userData,
              resendEmailTime: data.resendEmailTime
            })
          }

          return userData
        })

        return updatedCachedData
      })
    },
    onSettled: () => {
      // Revalidate the query after mutation
      queryClient.invalidateQueries(queryKey)
    },
    ...params
  })
}
