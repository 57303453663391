import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * ProductLineDTO
 */
class ProductLineDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('name')
  @jsonProperty(String)
  public name!: string

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: string

  @jsonName('dateCreated')
  @jsonProperty(Date)
  public dateCreated!: string

  @jsonName('order')
  @jsonProperty(Number)
  public order!: number

  @jsonName('minimumValue')
  @jsonProperty(Number)
  public minimumValue!: number
}

export default ProductLineDTO
