// --------------------------------------------------------
// GENERAL
// --------------------------------------------------------
import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'
// --------------------------------------------------------

/**
 * Income Report view model
 */
class IncomeReportDTO extends Serializable {
  @jsonProperty(String)
  public documentNumber!: string

  @jsonProperty(String)
  public year!: string

  @jsonProperty(String)
  public period!: string
}

export default IncomeReportDTO
