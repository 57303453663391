import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { Datepicker, Form, Input } from 'components'
import { PlainButton } from 'components/PlainButton'

import { useLanguage } from 'context/Language'

import { HolidayData } from './schema'

type HolidayFormProps = {
  onSubmit: (formData: HolidayData) => void
  isLoading: boolean
  methods: UseFormReturn<HolidayData, unknown>
  submitButtonLabel?: string | null
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function HolidayForm(props: HolidayFormProps) {
  const { onSubmit, isLoading, methods, submitButtonLabel } = props

  const { t } = useLanguage()

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '100%'
        })
      }}
    >
      <Input
        margin="normal"
        fullWidth
        label={t('cms.holidays.form.description')}
        name="name"
        autoFocus
        sx={{ mb: 4 }}
      />
      <Datepicker
        margin="normal"
        fullWidth
        label={t('cms.holidays.form.date')}
        name="date"
      />
      <PlainButton
        type="submit"
        fullWidth
        variant="contained"
        disabled={!methods.formState.isValid}
        loading={isLoading}
        sx={{ mt: 3, mb: 2 }}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default HolidayForm
export { HolidayForm }
