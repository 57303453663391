import React from 'react'

import { Link as MLink, LinkProps as MLinkProps } from '@mui/material'
import { Link as RRLink, LinkProps as RRLinkProps } from 'react-router-dom'

/**
 *
 */
export type LinkProps = Partial<Pick<RRLinkProps, 'to' | 'state'>> & MLinkProps
/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Link(props: LinkProps) {
  const newProps = {
    ...(!props.href
      ? { component: RRLink }
      : { target: '_blank', rel: 'noopener noreferrer' })
  }

  return <MLink {...newProps} underline="none" {...props} />
}

export default Link
export { Link }
