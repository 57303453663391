import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import { List as FunctionalDomainsList } from 'pages/cms/UserSettings/FunctionalDomains/List'
import { List as LinkUserList } from 'pages/cms/UserSettings/LinkUsers/List'
import { Menu as UserSettingsMenu } from 'pages/cms/UserSettings/Menu'
import { List as ResetEmailAndPasswordList } from 'pages/cms/UserSettings/ResetEmailAndPassword/List'
import {
  Create as CreateRole,
  Update as UpdateRole,
  List as RolesList
} from 'pages/cms/UserSettings/Roles'
// import {
//   Menu as ScrMenu,
//   Emails as ScrEmails,
//   Clients as ScrClients
// } from 'pages/cms/UserSettings/Scr'
import { List as UserAccessList } from 'pages/cms/UserSettings/UsersAccess/List'
import { Update as UserAccessListUpdate } from 'pages/cms/UserSettings/UsersAccess/Update'
import { CmsContent } from 'pages/layout/CmsContent'
import { RoleEnum } from 'types/roles'

import { UserSettingsRoutesEnum } from './routesEnums'

const registersRoutes: CustomRouteProps[] = [
  {
    path: UserSettingsRoutesEnum.USER_SETTINGS,
    layout: CmsContent,
    protected: true,
    element: <UserSettingsMenu />
  },
  {
    path: UserSettingsRoutesEnum.USERS_ACCESS_LIST,
    layout: CmsContent,
    protected: true,
    element: <UserAccessList />,
    role: RoleEnum.USUARIO_CONFIG_ACESSO_CMS
  },
  {
    path: UserSettingsRoutesEnum.USERS_ACCESS_LIST_UPDATE,
    layout: CmsContent,
    protected: true,
    element: <UserAccessListUpdate />,
    role: RoleEnum.USUARIO_CONFIG_ACESSO_CMS
  },
  // {
  //   path: UserSettingsRoutesEnum.SCR_MENU,
  //   layout: CmsContent,
  //   protected: true,
  //   element: <ScrMenu />,
  //   role: RoleEnum.USUARIO_CONFIG_CONSULTA
  // },
  // {
  //   path: UserSettingsRoutesEnum.SCR_EMAILS,
  //   layout: CmsContent,
  //   protected: true,
  //   element: <ScrEmails />,
  //   role: RoleEnum.USUARIO_CONFIG_CONSULTA
  // },
  // {
  //   path: UserSettingsRoutesEnum.SCR_CLIENTS,
  //   layout: CmsContent,
  //   protected: true,
  //   element: <ScrClients />,
  //   role: RoleEnum.USUARIO_CONFIG_CONSULTA
  // },
  {
    path: UserSettingsRoutesEnum.LINK_USERS_LIST,
    layout: CmsContent,
    protected: true,
    element: <LinkUserList />,
    role: RoleEnum.USUARIO_CONFIG_VINCULAR
  },
  {
    path: UserSettingsRoutesEnum.RESET_EMAIL_AND_PASSWORD,
    layout: CmsContent,
    protected: true,
    element: <ResetEmailAndPasswordList />,
    role: RoleEnum.USUARIO_CONFIG_REDEFINIR_SENHA_EMAIL
  },
  {
    path: UserSettingsRoutesEnum.ROLES_LIST,
    layout: CmsContent,
    protected: true,
    element: <RolesList />,
    role: RoleEnum.USUARIO_CONFIG_FUNCOES
  },
  {
    path: UserSettingsRoutesEnum.ROLES_CREATE,
    layout: CmsContent,
    protected: true,
    element: <CreateRole />,
    role: RoleEnum.USUARIO_CONFIG_FUNCOES
  },
  {
    path: UserSettingsRoutesEnum.ROLES_UPDATE,
    layout: CmsContent,
    protected: true,
    element: <UpdateRole />,
    role: RoleEnum.USUARIO_CONFIG_FUNCOES
  },
  {
    path: UserSettingsRoutesEnum.USER_DOMAINS,
    layout: CmsContent,
    protected: true,
    element: <FunctionalDomainsList />,
    role: RoleEnum.USUARIO_CONFIG_DOMINIO
  }
]

export default registersRoutes
