import { useMutation } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { unlinkDocument } from '../../actions/LinkedDocuments'

type useUnlinkDocumentParams = HookQueryParams

/**
 * Hook to unlink one document
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useUnlinkDocument = (params: useUnlinkDocumentParams = {}) => {
  const { mutate, isLoading, data } = useMutation('unlinkDocument', {
    mutationFn: ({ id }: { id: number }) => unlinkDocument(id),
    ...params
  })

  return { mutate, data, isLoading }
}
