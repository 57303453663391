import { useCallback } from 'react'
import { useQueryClient, InvalidateQueryFilters } from 'react-query'

/**
 * @returns Invalidate fn
 */
export const useInvalidateQuery = () => {
  const queryClient = useQueryClient()
  const invalidate = useCallback(
    (queryNames: InvalidateQueryFilters<unknown>) =>
      queryClient.invalidateQueries(queryNames),
    [queryClient]
  )

  const clear = useCallback(() => {
    queryClient.clear()
  }, [queryClient])

  return {
    clear,
    invalidate
  }
}
