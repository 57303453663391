import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as HolidayRoutesEnum } from 'routes/cms/routesEnums'

import { useCreateHoliday } from 'hooks/holidays/useCreateHoliday'

import Colors from 'constants/Colors'

import { setUTCStartOfDay } from 'common/utils/date'
import { useLanguage } from 'context/Language'

import { HolidayForm } from '../Form/HolidayForm'
import { HolidayData } from '../Form/schema'
import { useHolidayForm } from '../Form/useHolidayForm'

/**
 * Holiday Create
 *
 * @returns TSX
 */
function Create() {
  const { methods } = useHolidayForm({
    defaultValues: {
      name: '',
      date: new Date()
    }
  })
  const navigate = useNavigate()
  const { t } = useLanguage()
  const { mutate, isLoading } = useCreateHoliday({
    onSuccess: () => {
      toast.success(t('cms.holidays.create.success'))
      navigate(HolidayRoutesEnum.HOLIDAYS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: HolidayData) => {
      mutate({
        name: formData.name,
        date: setUTCStartOfDay(formData.date)
      })
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.holidays.create.title')}
      </Typography>
      <HolidayForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.holidays.create.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
