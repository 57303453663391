import { useGetNpsById, useUpdateNps } from '@volvo-apps/shared/core/hooks/nps'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { SurveyRoutesEnum } from 'routes/cms/routesEnums'

import Colors from 'constants/Colors'

import { setUTCEndOfDay, setUTCStartOfDay } from 'common/utils/date'
import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { NpsForm } from '../Form/NpsForm'
import { NpsData } from '../Form/schema'
import { useNpsForm } from '../Form/useNpsForm'

/**
 * Nps Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const { methods } = useNpsForm({
    defaultValues: {
      title: '',
      beginDate: new Date(),
      endDate: new Date()
    }
  })

  const { isLoading: getLoading, nps: npsFromGet } = useGetNpsById({
    id: Number(id),
    onSuccess: (nps) => {
      methods.reset({
        title: nps.title,
        beginDate: new Date(nps.beginDate),
        endDate: new Date(nps.endDate)
      })
      methods.trigger()
    },
    onError: handleErrorFeedback
  })

  const { mutate, isLoading: updateLoading } = useUpdateNps({
    onSuccess: () => {
      toast.success(t('cms.surveys.nps.update.success'))
      navigate(SurveyRoutesEnum.SURVEY_NPS)
    },
    onError: handleErrorFeedback
  })

  const onSubmit = React.useCallback(
    (formData: NpsData) => {
      mutate({
        nps: {
          ...npsFromGet,
          ...formData,
          beginDate: setUTCStartOfDay(formData.beginDate),
          endDate: setUTCEndOfDay(formData.endDate),
          createdDate: undefined
        }
      })
    },
    [mutate, npsFromGet]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.surveys.nps.update.title')}
      </Typography>
      <NpsForm
        isLoading={getLoading || updateLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.surveys.nps.update.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
