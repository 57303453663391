import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
dayjs.extend(utc)

/**
 *
 * @param dt Date
 * @returns Date
 */
const dateWithoutTimezone = (dt: Date) => new Date(dt.valueOf()) // + dt.getTimezoneOffset() * 60 * 1000);

const stringToDate = (str: string) => {
  const strJoin = str.split('/').reverse().join('/')

  const strFinal = new Date(strJoin)

  return strFinal
}

/**
 *
 * @param dt Date
 * @returns Date
 */
const setUTCTimezone = (dt: Date) => {
  return new Date(
    Date.UTC(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate(),
      dt.getHours(),
      dt.getMinutes(),
      dt.getSeconds(),
      dt.getMilliseconds()
    )
  )
}

/**
 *
 * @param date Date
 * @param formatMask Format mask
 * @param utc convert to UTC
 * @returns string Date formatted
 */
const formatDate = (
  date?: number | string | Date,
  formatMask = 'DD/MM/YYYY',
  utc = false
) => {
  if (!date) return ''
  const formatDate = new Date(date)
    .toISOString()
    .substring(0, 10)
    .replace(/-/g, '/')

  const dt = date instanceof Date ? date : new Date(formatDate)

  const dtDateOnly = dateWithoutTimezone(dt)

  if (utc) return dayjs.utc(dtDateOnly).format(formatMask)

  return dayjs(dtDateOnly).format(formatMask)
}

/**
 *
 * @param date Date
 * @param format Format mask
 * @param locale Locale
 * @returns string Date formatted
 */
const formatDateAndLocale = (
  date: number | string | Date,
  format = 'DD/MM/YYYY',
  locale = 'pt-br'
) => {
  const formatDate = new Date(date)
    .toISOString()
    .substring(0, 10)
    .replace(/-/g, '/')

  const dt = date instanceof Date ? date : new Date(formatDate)

  const dtDateOnly = dateWithoutTimezone(dt)

  return dayjs(dtDateOnly).locale(locale).format(format)
}

/**
 *
 * @param date Date
 * @param format Format mask
 * @returns string Date UTC formatted
 */
const formatUTC = (date?: string, format = 'YYYY-MM-DD') => {
  if (!date) return ''

  const stringDate = stringToDate(date)

  const newDate = new Date(stringDate)

  const formattedDate = formatDate(newDate, format, true)

  return formattedDate
}

/**
 *
 * @param startDate Start date
 * @param endDate End date
 * @returns number Working days
 */
const getWorkingDays = (startDate: Date, endDate: Date) => {
  let result = 0

  const currentDate = new Date(startDate.getTime())
  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDate) {
    const weekDay = currentDate.getDay()
    if (weekDay !== 0 && weekDay !== 6) result++

    currentDate.setDate(currentDate.getDate() + 1)
  }

  return result
}

/**
 *
 * @param date Date
 * @returns Date
 */
const setUTCStartOfDay = (date: Date) =>
  dayjs.utc(date).startOf('day').toISOString()

/**
 *
 * @param date Date
 * @returns Date
 */
const setUTCEndOfDay = (date: Date) =>
  dayjs.utc(date).endOf('day').toISOString()

/**
 *
 * @param timeString ex: 13:00
 * @returns Date
 */
const createDateFromTimeString = (timeString: string) => {
  const [hours, minutes] = timeString.split(':').map((x) => parseInt(x, 10))

  const date = new Date()
  date.setHours(hours, minutes, 0, 0)
  return date
}
/**
 * Compares two dates ignoring the timezone and year
 *
 * @param date1 First date
 * @param date2 Second date
 * @returns boolean - true if the dates are the same in month and day, otherwise false
 */
const areDatesEqualIgnoringYearAndTimezone = (
  date1: Date,
  date2: Date
): boolean => {
  const date1WithoutTimezone = dateWithoutTimezone(date1)
  const date2WithoutTimezone = dateWithoutTimezone(date2)

  return (
    date1WithoutTimezone.getDate() === date2WithoutTimezone.getDate() &&
    date1WithoutTimezone.getMonth() === date2WithoutTimezone.getMonth()
  )
}

/**
 *
 * @param date Date
 * @returns string - A key representing the month and day of the date, ignoring the year and timezone
 */
const getDateKeyIgnoringYearAndTimezone = (date: Date): string => {
  const formattedDate = dateWithoutTimezone(date)
  return `${formattedDate.getMonth()}-${formattedDate.getDate()}`
}

export {
  formatDate,
  formatUTC,
  dateWithoutTimezone,
  getWorkingDays,
  setUTCStartOfDay,
  formatDateAndLocale,
  setUTCEndOfDay,
  setUTCTimezone,
  createDateFromTimeString,
  areDatesEqualIgnoringYearAndTimezone,
  getDateKeyIgnoringYearAndTimezone
}
