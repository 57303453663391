import { getSimulationParameter } from '@volvo-apps/shared/core/actions/SimulationParameter'
import SimulationParameterDTO from '@volvo-apps/shared/models/SimulationParameterDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetSimulationParameterParams = {
  onSuccess?: (simulationParameter: SimulationParameterDTO) => void
  id?: number
}

/**
 * Hook to get simulation parameter
 *
 * @param params params
 * @returns simulationParameter, isLoading, refetch
 */
export const useGetSimulationParameter = (
  params: useGetSimulationParameterParams
) => {
  const { id, onSuccess } = params
  const {
    data: simulationParameter,
    isLoading,
    refetch
  } = useQuery('getSimulationParameter', {
    enabled: !!id,
    queryFn: () => getSimulationParameter(id || 0),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { simulationParameter, isLoading, refetch }
}
