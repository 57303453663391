import BannerConfigDTO from '@volvo-apps/shared/models/BannerConfigDTO'
import BannerDTO from '@volvo-apps/shared/models/BannerDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum } from 'routes/cms/routesEnums'

import { useCreateBanners } from 'hooks/banners/useCreateBanners'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { BannerForm } from '../Form/BannerForm'
import { BannerData } from '../Form/schema'
import { useBannerForm } from '../Form/useBannerForm'

/**
 * Banner Create
 *
 * @returns TSX
 */
function Create() {
  const { methods } = useBannerForm({
    defaultValues: {}
  })

  const navigate = useNavigate()
  const { t } = useLanguage()

  const { mutate, isLoading } = useCreateBanners({
    onSuccess: () => {
      toast.success(t('cms.banners.create.success'))
      navigate(RegistersRoutesEnum.BANNERS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: BannerData) => {
      const { banners, name, transitionTime } = formData

      const bannersArray = banners.map((banner) => {
        const { base64, fileName } = banner
        return new BannerDTO().fromJSON({
          fileName,
          bannerBase64: base64,
          title: name
        })
      })

      mutate(
        new BannerConfigDTO().fromJSON({
          banners: bannersArray,
          transitionTime
        })
      )
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.banners.create.title')}
      </Typography>
      <BannerForm
        isLoading={isLoading}
        methods={methods}
        onSubmit={onSubmit}
        submitButtonLabel={t('cms.banners.create.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
