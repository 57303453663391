import BannerConfigDTO from '@volvo-apps/shared/models/BannerConfigDTO'
import BannerDTO from '@volvo-apps/shared/models/BannerDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as BannerRoutesEnum } from 'routes/cms/routesEnums'

import { useGetBannerConfigAndBanners } from 'hooks/banners/useGetBannerConfigAndBanners'
import { useUpdateBanners } from 'hooks/banners/useUpdateBanners'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { BannerForm } from '../Form/BannerForm'
import { BannerData } from '../Form/schema'
import { useBannerForm } from '../Form/useBannerForm'

/**
 * Banner Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const { methods } = useBannerForm({
    defaultValues: {
      name: '',
      transitionTime: 0,
      banners: []
    }
  })

  const { bannerConfigAndBanner, isLoading: getLoading } =
    useGetBannerConfigAndBanners({
      id: Number(id),
      onSuccess: ({ bannerConfig, banners }) => {
        methods.reset({
          name: banners.length ? banners[0].title : '',
          transitionTime: parseFloat(bannerConfig.transitionTime.toString()),
          banners: banners.map((bannerWithImage) => {
            return {
              ...bannerWithImage,
              objId: bannerWithImage.id,
              azureUrl: bannerWithImage.azureUrl,
              base64: ''
            }
          })
        })
      }
    })

  const { mutate, isLoading } = useUpdateBanners({
    onSuccess: () => {
      toast.success(t('cms.banners.update.success'))
      navigate(BannerRoutesEnum.BANNERS)
    }
  })

  const onRemoveBanner = React.useCallback(
    (bannerId?: number) => {
      if (bannerId && bannerConfigAndBanner) {
        const index = bannerConfigAndBanner.banners.findIndex(
          (banner) => banner.id === bannerId
        )

        if (index === -1) return

        bannerConfigAndBanner.banners[index].active = false
      }
    },
    [bannerConfigAndBanner]
  )

  const onSubmit = React.useCallback(
    async (formData: BannerData) => {
      if (!bannerConfigAndBanner) return
      const { banners: bannersForm, name, transitionTime } = formData
      const { bannerConfig, banners: oldBanners } = bannerConfigAndBanner

      const newBanners = bannersForm.filter((banner) => !banner.objId) // removes the banners that already exists

      const bannersUpdated = [...oldBanners, ...newBanners].map((banner) => {
        if (banner instanceof BannerDTO) {
          return new BannerDTO().fromJSON({
            ...banner,
            title: name
          })
        }

        return new BannerDTO().fromJSON({
          ...banner,
          azureUrl: banner.azureUrl,
          bannerBase64: banner.base64,
          idBannerConfig: bannerConfig?.id,
          title: name
        })
      })

      mutate(
        new BannerConfigDTO().fromJSON({
          ...bannerConfig,
          transitionTime,
          banners: bannersUpdated
        })
      )
    },
    [bannerConfigAndBanner, mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.banners.update.title')}
      </Typography>
      <BannerForm
        isLoading={isLoading || getLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.banners.update.button')}
        onRemoveBanner={onRemoveBanner}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
