import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { hasAutbankContract } from '../../actions/User'

type useHasAutbankContractsParams = HookQueryParams<boolean>

/**
 * Hook to get insurances
 *
 * @param params params
 * @returns insurances, isLoading, refetch
 */
export const useHasAutbankContract = (
  params: useHasAutbankContractsParams = {}
) => {
  const { data, isLoading } = useQuery('hasAutbankContract', {
    queryFn: hasAutbankContract,
    ...params
  })

  return { data, isLoading }
}
