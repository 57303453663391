import UserSimulationDTO from '@volvo-apps/shared/models/UserSimulationDTO'

import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState
} from 'react'

interface IUserSimulationContext {
  simulation?: Partial<UserSimulationDTO>
  updateSimulation: (simulation: Partial<UserSimulationDTO>) => void
  clearSimulation: () => void
}

const UserSimulationContext = createContext<IUserSimulationContext>(
  {} as IUserSimulationContext
)

/**
 * UserSimulationContext
 *
 * @param props props
 * @param props.children children
 * @returns TSX.Element
 */
const UserSimulationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [simulation, setSimulation] = useState<
    Partial<IUserSimulationContext['simulation']>
  >({})

  const updateSimulation = useCallback(
    (simulation: IUserSimulationContext['simulation']) => {
      setSimulation((prevState) => {
        const defaultValues = {
          vehicleQtd: 1,
          newVehicle: true
        }

        return {
          ...(prevState ?? {}),
          ...simulation,
          ...defaultValues
        }
      })
    },
    []
  )

  const clearSimulation = useCallback(() => {
    setSimulation({})
  }, [])

  return (
    <UserSimulationContext.Provider
      value={{
        simulation,
        updateSimulation,
        clearSimulation
      }}
    >
      {children}
    </UserSimulationContext.Provider>
  )
}

/**
 * useUserSimulation
 *
 * @throws Error
 * @returns IUserSimulationContext
 */
function useUserSimulation(): IUserSimulationContext {
  const context = useContext(UserSimulationContext)

  if (!context) {
    throw new Error(
      'useUserSimulation must be used within an UserSimulationProvider'
    )
  }

  return context
}

export { UserSimulationContext, UserSimulationProvider, useUserSimulation }
