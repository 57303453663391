import ProductLineDTO from '@volvo-apps/shared/models/ProductLineDTO'
import { ProductLineService } from '../service/ProductLine.service'

/**
 * @returns ProductLineDTO[]
 */
export async function getAllProductLines() {
  const service = new ProductLineService()
  const response = await service.getAll()

  return response.data.map((productLine) =>
    new ProductLineDTO().fromJSON(productLine)
  )
}

/**
 * @param id The id of the product line
 * @returns product line
 */
export async function getProductLine(id: number) {
  const service = new ProductLineService()
  const response = await service.getProductLine(id)
  return new ProductLineDTO().fromJSON(response.data)
}

/**
 * @param id The id of the product line
 * @returns Delete product line
 */
export async function deleteProductLine(id: number) {
  const service = new ProductLineService()
  const response = await service.deleteProductLine(id)

  return response
}

/**
 * @param updatedProductLine The updated product line
 */
export async function updateProductLine(updatedProductLine: ProductLineDTO) {
  const service = new ProductLineService()
  const response = await service.updateProductLine(updatedProductLine)

  return response
}

/**
 *
 * @param name The name of the product line
 * @param order The order of the product line
 * @param minimumValue The initial value of the product line
 */
export async function createProductLine(
  name: string,
  order: number,
  minimumValue: number
) {
  const service = new ProductLineService()
  const response = await service.createProductLine(name, order, minimumValue)

  return response
}

/**
 * @returns All cms product lines
 */
export async function getAllCmsProductLines() {
  const service = new ProductLineService()
  const response = await service.getAllCms()

  return response.data.map((productLine) =>
    new ProductLineDTO().fromJSON(productLine)
  )
}
