import { createFaqQuestion } from '@volvo-apps/shared/core/actions/FaqQuestion'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCreateFaqQuestionParams = {
  onSuccess?: () => void
}

/**
 * Hook to create a faq question
 *
 * @param params params
 * @returns mutate, faq question, isLoading
 */
export const useCreateFaqQuestion = (
  params: useCreateFaqQuestionParams = {}
) => {
  const { onSuccess } = params
  const {
    data: faqQuestion,
    isLoading,
    mutate
  } = useMutation('createFaqQuestion', {
    mutationFn: ({
      categoryId,
      title,
      description
    }: {
      categoryId: number
      title: string
      description: string
    }) => createFaqQuestion(categoryId, title, description),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, faqQuestion, isLoading }
}
