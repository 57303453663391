import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * LinkedDocumentsDTO
 */
class LinkedDocumentsDTO extends Serializable {
  @jsonName('userDocumentId')
  @jsonProperty(Number)
  public userDocumentId!: number

  @jsonName('userName')
  @jsonProperty(String)
  public userName!: string

  @jsonName('email')
  @jsonProperty(String)
  public email!: string

  @jsonName('nameOrCompanyName')
  @jsonProperty(String)
  public nameOrCompanyName!: string

  @jsonName('document')
  @jsonProperty(String)
  public document!: string

  @jsonName('insertedDate')
  @jsonProperty(Date)
  public insertedDate!: Date
}

export default LinkedDocumentsDTO
