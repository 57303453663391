import React from 'react'

import { Box, Icon, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Title } from 'components'
import { FlexBox } from 'components/FlexBox'

// import { AppRoutesEnum } from 'routes/app.routes'
import { FinancialRoutesEnum } from 'routes/financial.routes'
import { InsurancePoliciesRoutesEnum } from 'routes/insurancePolicies.routes'
import { SimulationRoutesEnum } from 'routes/simulation.routes'
import { ExternalRoutesEnum } from 'routes/types'

import Colors from 'constants/Colors'

import { ReactComponent as Consortium } from 'assets/icons/consortium.svg'
import { ReactComponent as DocumentsGeneralIcon } from 'assets/icons/documentsGeneral.svg'
import { ReactComponent as FileBigIcon } from 'assets/icons/fileBig.svg'
// import { ReactComponent as FinanceReporting } from 'assets/icons/financeReporting.svg'
import { ReactComponent as PartsAndServices } from 'assets/icons/partsServices.svg'
import { ReactComponent as SymbolDollarIcon } from 'assets/icons/symbolDollar.svg'
import { ReactComponent as Lock } from 'assets/icons/sysLock.svg'
import { ReactComponent as SysTruck } from 'assets/icons/sysTruck.svg'
import { openExternalLink } from 'common/utils/url'
import { useDashboardActions } from 'context/DashboardActions'
import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function Actions() {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const {
    hasConsortium,
    hasInsurance,
    hasAutbank,
    hasRentalTruck,
    hasPartsAndServices
  } = useDashboardActions()

  const actions = React.useMemo(
    () => [
      ...(hasAutbank
        ? [
            {
              label: t('dashboard.myContracts'),
              icon: <DocumentsGeneralIcon />,
              onClick: () => {
                navigate(FinancialRoutesEnum.CONTRACTS)
              }
            },
            {
              label: t('dashboard.tickets'),
              icon: <FileBigIcon />,
              onClick: () => {
                navigate(FinancialRoutesEnum.TICKETS)
              }
            }
          ]
        : []),
      {
        label: t('dashboard.newFinancingSimulation'),
        icon: <SymbolDollarIcon />,
        disabled: false,
        onClick: () => {
          navigate(SimulationRoutesEnum.DOCUMENT_SELECTION)
        }
      },
      // {
      //   label: t('dashboard.incomeReports'),
      //   icon: <FinanceReporting />,
      //   disabled: false,
      //   onClick: () => {
      //     navigate(AppRoutesEnum.INCOME_REPORTS)
      //   }
      // },
      ...(hasInsurance
        ? [
            {
              label: t('dashboard.insurancePolicies'),
              icon: <Lock />,
              disabled: false,
              onClick: () => {
                navigate(InsurancePoliciesRoutesEnum.MENU)
              }
            }
          ]
        : []),
      ...(hasConsortium
        ? [
            {
              label: t('dashboard.consortium'),
              icon: <Consortium />,
              disabled: false,
              onClick: () => {
                openExternalLink(ExternalRoutesEnum.CONSORTIUM)
              }
            }
          ]
        : []),
      ...(hasRentalTruck
        ? [
            {
              label: t('dashboard.rentalTruck'),
              icon: <SysTruck />,
              disabled: false,
              onClick: () => {
                navigate(FinancialRoutesEnum.RENTAL)
              }
            }
          ]
        : []),
      ...(hasPartsAndServices
        ? [
            {
              label: t('dashboard.partsAndServices'),
              icon: <PartsAndServices />,
              disabled: false,
              onClick: () => {
                navigate(FinancialRoutesEnum.PARTS_AND_SERVICES)
              }
            }
          ]
        : [])
    ],
    [
      hasAutbank,
      t,
      hasInsurance,
      hasConsortium,
      hasRentalTruck,
      hasPartsAndServices,
      navigate
    ]
  )

  return (
    <Box>
      <Title variant="h6" fontSize={20} sx={{ my: 3 }}>
        {t('dashboard.wantToDoToday')}
      </Title>
      <FlexBox gap={7}>
        {actions.map((action, index) => (
          <FlexBox
            key={index}
            width={70}
            flexDirection="column"
            alignItems="center"
          >
            <IconButton
              onClick={action.onClick}
              sx={{
                backgroundColor: Colors.light.greys.GREY_200,
                p: 1.5,
                opacity: action.disabled ? 0.4 : 1,
                width: 60,
                height: 60
              }}
            >
              <Icon sx={{ width: 30, height: 30, color: Colors.light.text }}>
                {action.icon}
              </Icon>
            </IconButton>
            <Box textAlign="center">
              <Typography
                variant="body2"
                color={Colors.light.greys.GREY_600}
                lineHeight={1.2}
                sx={{ mt: 1 }}
              >
                {action.label}
              </Typography>
            </Box>
          </FlexBox>
        ))}
      </FlexBox>
    </Box>
  )
}

export default Actions
export { Actions }
