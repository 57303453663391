import { useQuery } from 'react-query'

import { getYears } from '../../actions/IncomeReport'
import BaseYearDTO from '../../../models/BaseYearDTO'
import { HookQueryParams } from '../../../common/utils/types'

type useGetYearsParams = HookQueryParams<BaseYearDTO>

/**
 * Hook to get years
 *
 * @param params params
 * @returns years, isLoading, refetch
 */
export const useGetYears = (params: useGetYearsParams = {}) => {
  const { suspense } = params

  const {
    data: years,
    isLoading,
    refetch
  } = useQuery('getYears', {
    queryFn: () => getYears(),
    suspense
  })

  return { years, isLoading, refetch }
}
