import VehicleDTO from '@volvo-apps/shared/models/VehicleDTO'
import { VehicleService } from '../service/Vehicle.service'
import md5 from 'md5'
import VehicleImagesDTO from '@volvo-apps/shared/models/VehicleImagesDTO'
import TransitionIntervalDTO from '@volvo-apps/shared/models/TransitionIntervalDTO'

/**
 * @returns ProductLineDTO[]
 */
export function getAllVehicle() {
  const service = new VehicleService()
  return service.getAll().then(({ data }) =>
    data.map((vehicle) =>
      new VehicleDTO().fromJSON({
        ...vehicle,
        vehicleHash: md5(vehicle.modifiedDate)
      })
    )
  )
}

/**
 *
 * @param vehicleId Vehicle Id
 * @returns Promise
 */
export function getImageByVehicleId(vehicleId: VehicleDTO['id']) {
  const service = new VehicleService()

  return service.getImageByVehicleId(vehicleId).then(({ data }) => {
    return data.map((vehicleImage) => {
      return new VehicleImagesDTO().fromJSON({
        ...vehicleImage,
        vehicleImageHash: md5(vehicleImage.modifiedDate)
      })
    })
  })
}

/**
 * @returns Promise<TransitionIntervalDTO[]>
 */
export function getAllTransitionInterval() {
  const service = new VehicleService()
  return service.getAllTransitionInterval().then(({ data }) => data)
}

/**
 * @param payload TransitionIntervalDTO
 * @returns Promise<TransitionIntervalDTO[]>
 */
export async function updateTransitionInterval(
  payload: Pick<TransitionIntervalDTO, 'id' | 'transitionTime'>
) {
  const service = new VehicleService()
  const response = await service.updateTransitionInterval(payload)
  return new TransitionIntervalDTO().fromJSON(response.data)
}

/**
 * @param vehicle Vehicle
 * @returns VehicleDTO
 */
export async function createVehicle(vehicle: VehicleDTO) {
  const service = new VehicleService()
  const response = await service.createVehicle(vehicle)
  return response
}

/**
 * @param vehicle Vehicle
 * @returns VehicleDTO
 */
export async function updateVehicle(vehicle: VehicleDTO) {
  const service = new VehicleService()
  const response = await service.updateVehicle(vehicle)
  return new VehicleDTO().fromJSON(response.data)
}

/**
 * @param id Vehicle Id
 * @returns VehicleDTO
 */
export async function getUnique(id: number) {
  const service = new VehicleService()
  const response = await service.getUnique(id)
  return new VehicleDTO().fromJSON(response.data)
}

/**
 * @returns VehicleDTO[]
 */
export async function getAllCmsVehicles() {
  const service = new VehicleService()
  const response = await service.getAllCms()

  return response.data.map((vehicle) => new VehicleDTO().fromJSON(vehicle))
}
