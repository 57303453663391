import React from 'react'

import { Select } from '../Select'
import { SelectProps } from './types'

type OrderSelectProps = SelectProps

/**
 *
 * @param props Props
 * @returns TSX
 */
function OrderSelect(props: OrderSelectProps) {
  const { name, label, margin, fullWidth, sx } = props

  const orderSelects = [
    {
      label: '1°',
      value: 1
    },
    {
      label: '2°',
      value: 2
    },
    {
      label: '3°',
      value: 3
    },
    {
      label: '4°',
      value: 4
    },
    {
      label: '5°',
      value: 5
    }
  ]

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={orderSelects ?? []}
      sx={sx}
    />
  )
}

export default OrderSelect
export { OrderSelect }
