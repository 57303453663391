import PermissionDTO from '@volvo-apps/shared/models/PermissionDTO'

import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type RoleData = {
  /**
   *
   */
  name: string
  /**
   *
   */
  permissions: Array<PermissionDTO>
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    name: Yup.string().required('validation.required.general')
  })
)
