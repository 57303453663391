import React from 'react'

import { useNavigate } from 'react-router'

import { List, ListItemProps } from 'components'
import ContentContainer from 'components/ContentContainer/ContentContainer'
import { Loader } from 'components/Loader'
import { NoInfo } from 'components/NoInfo'

import { FinancialRoutesEnum } from 'routes/financial.routes'

import { useFinancialMenuActions } from 'context/FinancialMenuActions'
import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function FinancialMenu() {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const {
    hasAutbank,
    hasRentalTruck,
    hasPartsAndServices,
    isMenuActionsLoading
  } = useFinancialMenuActions()

  const menuItems: ListItemProps[] = React.useMemo(
    () => [
      ...(hasAutbank
        ? [
            {
              primary: t('financial.menu.tickets'),
              onClick: () => navigate(FinancialRoutesEnum.TICKETS)
            },
            {
              primary: t('financial.menu.contracts'),
              onClick: () => navigate(FinancialRoutesEnum.CONTRACTS)
            }
          ]
        : []),
      ...(hasRentalTruck
        ? [
            {
              primary: t('financial.menu.rental'),
              onClick: () => navigate(FinancialRoutesEnum.RENTAL)
            }
          ]
        : []),
      ...(hasPartsAndServices
        ? [
            {
              primary: t('financial.menu.partsAndServices'),
              onClick: () => navigate(FinancialRoutesEnum.PARTS_AND_SERVICES)
            }
          ]
        : [])
    ],
    [hasAutbank, hasRentalTruck, hasPartsAndServices, navigate, t]
  )

  if (isMenuActionsLoading) {
    return <Loader size={26} />
  }

  return (
    <ContentContainer>
      {menuItems.length > 0 ? <List items={menuItems} noPadding /> : <NoInfo />}
    </ContentContainer>
  )
}

export default FinancialMenu
export { FinancialMenu }
