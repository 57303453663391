import { useMutation } from 'react-query'
import { deleteImportRentalContract } from '../../actions/Sales'

type useDeleteImportRentalContractParams = {
  onSuccess?: () => void
  onError?: (err: unknown) => void
}

/**
 * Hook to delete one import rental contract
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteImportRentalContract = (
  params: useDeleteImportRentalContractParams = {}
) => {
  const { onSuccess, onError } = params
  const { mutate, isLoading, data } = useMutation(
    'deleteImportRentalContract',
    {
      mutationFn: ({ id }: { id: number }) => deleteImportRentalContract(id),
      onSuccess,
      onError
    }
  )

  return { mutate, data, isLoading }
}
