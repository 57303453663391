import { SalesInvoiceDTO } from '@volvo-apps/shared/models/SalesInvoiceDTO'

import React from 'react'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import RentalInvoiceDetail from './RentalInvoiceDetail'

type RentalInvoicesProps = {
  invoice: SalesInvoiceDTO
}
/**
 *
 * @param props RentalInvoicesProps
 * @returns TSX
 */
function RentalInvoices(props: RentalInvoicesProps) {
  const { invoice } = props

  return (
    <FlexBox
      sx={{
        border: `1px solid ${Colors.light.greys.GREY_200}`,
        boxShadow: `1px 1px 5px ${Colors.light.shadow}`,
        p: '16px 28px'
      }}
      flexDirection="column"
    >
      <RentalInvoiceDetail invoice={invoice} />
    </FlexBox>
  )
}

export default RentalInvoices
export { RentalInvoices }
