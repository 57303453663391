import 'reflect-metadata'
import { jsonProperty, Serializable, jsonName } from 'ts-serializable'
import ContractDTO from './ContractDTO'

/**
 * DocumentDTO
 */
class DocumentDTO extends Serializable {
  @jsonProperty(String)
  public document!: string

  @jsonProperty(Boolean)
  public isSignUpDocument!: boolean

  @jsonProperty(Boolean)
  public verified!: boolean

  @jsonProperty(Boolean)
  public isContractsAttached!: boolean

  @jsonProperty(Boolean)
  public hasABContracts!: boolean

  @jsonName('contracts')
  @jsonProperty(Array)
  public contracts?: Array<ContractDTO>
}

export default DocumentDTO
