import RoleDTO from '@volvo-apps/shared/models/RoleDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import AccessCMSSwitch from './AccessCMSSwitch'

/**
 *
 * @param handleEdit handleEdit
 * @returns TSX
 */
export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'cms.usersAccess.list.columns.name',
    minWidth: 250,
    flex: 1,
    sortable: false
  },
  {
    field: 'userFunction',
    headerName: 'cms.usersAccess.list.columns.role',
    minWidth: 325,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const userFunction = params.row.userFunction as RoleDTO | undefined

      return <Typography fontSize={16}>{userFunction?.name}</Typography>
    }
  },
  {
    field: 'createdDate',
    headerName: 'cms.usersAccess.list.columns.createdDate',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const createdDate = params.row.createdDate as Date

      return (
        <Typography fontSize={16}>
          {dayjs(createdDate).format('DD/MM/YYYY')}
        </Typography>
      )
    }
  },
  {
    field: 'canAccessCms',
    headerName: 'cms.usersAccess.list.columns.access',
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return <AccessCMSSwitch params={params} />
    }
  }
]
