import { validateVerificationCode } from '@volvo-apps/shared/core/actions/User'

import React from 'react'

import { AxiosError } from 'axios'
import { UseFormReturn } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Link } from 'components'
import { PlainButton } from 'components/PlainButton'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { FormInfo } from '../types'

type VerificationCodeAreaProps = {
  formMethods: UseFormReturn<FormInfo, unknown>
  onSuccessVerificationCode: () => void
  handleResendVerificationCode: () => void
}

/**
 *
 * @param props Component props
 * @param props.formMethods methods from react-hook-form
 * @param props.onSuccessVerificationCode callback to be executed when request is successful
 * @param props.handleResendVerificationCode resend verification code to user's email
 * @returns TSX
 */
function VerificationCodeArea(props: VerificationCodeAreaProps) {
  const {
    handleResendVerificationCode,
    onSuccessVerificationCode,
    formMethods
  } = props

  const { t } = useLanguage()
  const verificationCode = formMethods.watch('verificationCode')

  const validateVerificationCodeMutation = useMutation(
    validateVerificationCode,
    {
      onSuccess: () => {
        onSuccessVerificationCode()
      },
      onError: (err) => {
        if (
          err instanceof AxiosError &&
          err.response?.status === 422 &&
          err.response?.data?.Message
        ) {
          formMethods.setError('verificationCode', {
            message: err.response.data.Message
          })
        } else {
          handleErrorFeedback(err)
        }
      }
    }
  )

  const handleValidateVerificationCode = React.useCallback(async () => {
    const { email, verificationCode } = formMethods.getValues()
    validateVerificationCodeMutation.mutate({ email, verificationCode })
  }, [formMethods, validateVerificationCodeMutation])

  return (
    <>
      <PlainButton
        type="button"
        disabled={!formMethods.formState.isValid || !verificationCode}
        fullWidth
        loading={validateVerificationCodeMutation.isLoading}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => {
          handleValidateVerificationCode()
        }}
      >
        {t('passwordRetrieval.verifyCode')}
      </PlainButton>
      <Link
        to=""
        variant="body2"
        sx={{
          alignSelf: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
        onClick={() => {
          handleResendVerificationCode()
        }}
      >
        {t('passwordRetrieval.resendCode')}
      </Link>
    </>
  )
}

export default VerificationCodeArea
