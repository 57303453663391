import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type BannerData = {
  /**
   *
   */
  name: string
  /**
   *
   */
  transitionTime: number
  /**
   *
   */
  banners: {
    objId?: number
    base64: string
    fileName: string
    azureUrl?: string
  }[]
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    name: Yup.string().required('validation.required.general'),
    transitionTime: Yup.number().required('validation.required.general'),
    banners: Yup.array()
      .min(1, 'validation.required.general')
      .of(
        Yup.object({
          objId: Yup.number(),
          base64: Yup.string(),
          azureUrl: Yup.string(),
          fileName: Yup.string().required('validation.required.general')
        })
      )
  })
)
