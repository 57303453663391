import _deburr from 'lodash/deburr'
import RentalContractDTO from '../../models/RentalContractDTO'

const props: (keyof RentalContractDTO)[] = [
  'documento',
  'numeroContrato',
  'nomeRazao'
]

const match = (value: string, input: string) => {
  return _deburr(value).match(new RegExp(input, 'ig'))
}

/**
 *
 * @param rental RentalContractDTO
 * @param input Input string
 * @returns Boolean
 */
export const handleRentalFilter = (
  rental: RentalContractDTO,
  input: string
) => {
  let isValid = false

  for (const key of props) {
    const value = rental[key]

    if (match(value as string, input)) {
      isValid = true
      break
    }
  }

  return isValid
}
