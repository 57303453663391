import React, { useState } from 'react'

import { IconButton, InputAdornment } from '@mui/material'

import { Input, InputProps } from 'components'
import { Icon } from 'components/Icon'

type PasswordProps = InputProps

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Password(props: PasswordProps) {
  const [passwordVisible, setPasswordVisible] = useState(false)

  const handleToggleVisibility = () => setPasswordVisible((s) => !s)

  return (
    <Input
      fullWidth
      type={passwordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleToggleVisibility}>
              {passwordVisible ? (
                <Icon family="mui" name="Visibility" />
              ) : (
                <Icon family="mui" name="VisibilityOff" />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}

export default Password
export { Password }
