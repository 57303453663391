import { createFaqCategory } from '@volvo-apps/shared/core/actions/Category'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCreateFaqCategoryParams = {
  onSuccess?: () => void
}

/**
 * Hook to create a faq category
 *
 * @param params params
 * @returns mutate, faq category, isLoading
 */
export const useCreateFaqCategory = (
  params: useCreateFaqCategoryParams = {}
) => {
  const { onSuccess } = params
  const {
    data: faqCategory,
    isLoading,
    mutate
  } = useMutation('createFaqCategory', {
    mutationFn: ({
      icon,
      label,
      description
    }: {
      icon: string
      label: string
      description: string
    }) => createFaqCategory(icon, label, description),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, faqCategory, isLoading }
}
