import React, { PropsWithChildren } from 'react'

import { Box } from '@mui/material'

import { ButtonLink } from 'components'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type HeaderBackComponentProps = PropsWithChildren & {
  backPath?: string
}

/**
 * @param props HeaderBackComponentProps
 * @returns TSX
 */
function HeaderBackComponent(props: HeaderBackComponentProps) {
  const { children } = props
  const { t } = useLanguage()

  return (
    <Box
      position="relative"
      display="flex"
      flex={1}
      width="100%"
      flexDirection="column"
    >
      <ButtonLink
        variant="text"
        startIcon={<Icon family="mui" name="KeyboardBackspace" />}
        to={props.backPath || '/login'}
        sx={{
          color: Colors.light.text,
          left: 0,
          maxWidth: 'fit-content'
        }}
      >
        {t('general.back')}
      </ButtonLink>
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        justifyContent="center"
        ml={5}
        mr={5}
      >
        {children}
      </Box>
    </Box>
  )
}
export default HeaderBackComponent
export { HeaderBackComponent }
