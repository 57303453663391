import React from 'react'

import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { FlexBox } from 'components/FlexBox'
import { Header } from 'components/Header'
import { Loader } from 'components/Loader'

import Colors from 'constants/Colors'

import { ReactComponent as Logotype } from 'assets/images/Logotype.svg'

/**
 * @returns TSX
 */
function Suspense() {
  const year = dayjs().year()

  return (
    <FlexBox width="100%" flexDirection="column" minHeight="100vh">
      <Header items={[]} />
      <FlexBox
        flexDirection="column"
        width="100%"
        alignSelf="center"
        justifyContent="center"
        alignItems="center"
        flex={1}
      >
        <Loader />
      </FlexBox>

      <FlexBox
        minHeight="4em"
        alignContent="center"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        px={15}
        sx={{
          backgroundColor: Colors.light.greys.GREY_100
        }}
      >
        <Box flexDirection="row" display="flex" alignItems="center">
          <Logotype width="9.375rem" />
          <Typography fontSize={12} color={Colors.light.greys.GREY_600}>
            © Copyright AB Volvo {year}
          </Typography>
        </Box>
      </FlexBox>
    </FlexBox>
  )
}

export default Suspense
export { Suspense }
