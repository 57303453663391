import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'

class ParcelaCdcDTO {
  @jsonProperty(String)
  datVenctoid!: string

  @jsonProperty(Number)
  nroParcela!: number

  @jsonProperty(Number)
  vlrFinanciado!: number

  @jsonProperty(Number)
  vlrJuros!: number

  @jsonProperty(Number)
  diasCorridos!: number
}

/**
 *  SimulationCdc DTO
 */
class SimulationCdcDTO extends Serializable {
  @jsonProperty(Number)
  qtdParcelas!: number

  @jsonProperty(String)
  datInicio!: string

  @jsonProperty(String)
  datPrimVencto!: string

  @jsonProperty(Number)
  taxaMensal!: number

  @jsonProperty(Number)
  valorDespesas!: number

  @jsonProperty(Number)
  valorFinanciado!: number

  @jsonProperty(Number)
  valorLiberado!: number

  @jsonProperty(Number)
  valorParcela!: number

  @jsonProperty(Number)
  prazoMeses!: number

  @jsonProperty(Number)
  valorIOF!: number

  @jsonProperty(Number)
  totalJuros!: number

  @jsonProperty(Number)
  aliquotaIOFAnual!: number

  @jsonProperty(Number)
  aliquotaIOFAdicional!: number

  @jsonProperty(Number)
  cetMensal!: number

  @jsonProperty(Number)
  tirMensal!: number

  @jsonProperty(Number)
  valorSeguroPrestamista!: number

  @jsonProperty(Number)
  valorSeguroCasco!: number

  @jsonProperty(Number)
  totalDevido!: number

  @jsonProperty(Number)
  fatorSeguroPrestamista!: number

  @jsonProperty(Number)
  taxaAnual!: number

  @jsonProperty(ParcelaCdcDTO)
  parcelas!: ParcelaCdcDTO[]
}

export default SimulationCdcDTO
