import { makeResolver } from 'common/utils/form'
import { optionalNumberValidator } from 'common/validators/number'

type ParameterDetail = {
  maxValue: number
  minValue: number
  leapOfMonths: number
  valueStarts: number
}

/**
 *
 */
export type SimulationParameterData = {
  /**
   *
   */
  financialProductId: number
  /**
   *
   */
  productLineId: number
  /**
   *
   */
  startDate: Date
  /**
   *
   */
  endDate: Date
  /**
   *
   */
  taxes: {
    /**
     *
     */
    tacValue: string
    /**
     *
     */
    monthlyInterestRate: string
    /**
     *
     */
    moneyLenderInsurance: boolean
    /**
     *
     */
    isSelicInterestRate: boolean
    /**
     *
     */
    program: string
    /**
     *
     */
    subProgram: string
    /**
     *
     */
    bndesCondition: string
    /**
     *
     */
    bndesSequence: string
    /**
     *
     */
    volvoRate: string
    /**
     *
     */
    ipcaValue: string
    /**
     *
     */
    selicValue: string
  }
  /**
   *
   */
  installments: ParameterDetail
  /**
   *
   */
  gracePeriod: ParameterDetail
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    financialProductId: Yup.number().required('validation.required.general'),
    productLineId: Yup.number().required('validation.required.general'),
    startDate: Yup.date()
      .nullable()
      .typeError('validation.invalid.date')
      .required('validation.required.general'),
    endDate: Yup.date()
      .nullable()
      .typeError('validation.invalid.date')
      .required('validation.required.general'),
    taxes: Yup.object().when('financialProductId', {
      is: (financialProductId) => financialProductId !== 1,
      then: Yup.object({
        tacValue: Yup.string().required('validation.required.general'),
        monthlyInterestRate: Yup.string().required(
          'validation.required.general'
        ),
        moneyLenderInsurance: Yup.string().required(
          'validation.required.general'
        ),
        isSelicInterestRate: Yup.string().required(
          'validation.required.general'
        ),
        program: Yup.string().required('validation.required.general'),
        subProgram: Yup.string().required('validation.required.general'),
        bndesCondition: Yup.string().required('validation.required.general'),
        bndesSequence: Yup.string().required('validation.required.general'),
        volvoRate: Yup.string().required('validation.required.general'),
        ipcaValue: Yup.string().required('validation.required.general'),
        selicValue: Yup.string().required('validation.required.general')
      }),
      otherwise: Yup.object({
        tacValue: Yup.string().required('validation.required.general'),
        monthlyInterestRate: Yup.string().required(
          'validation.required.general'
        ),
        moneyLenderInsurance: Yup.string().required(
          'validation.required.general'
        ),
        isSelicInterestRate: Yup.string().required(
          'validation.required.general'
        )
      })
    }),

    installments: Yup.object({
      maxValue: optionalNumberValidator,
      minValue: optionalNumberValidator,
      leapOfMonths: optionalNumberValidator,
      valueStarts: optionalNumberValidator
    }),
    gracePeriod: Yup.object({
      maxValue: optionalNumberValidator,
      minValue: optionalNumberValidator,
      leapOfMonths: optionalNumberValidator,
      valueStarts: optionalNumberValidator
    })
  })
)
