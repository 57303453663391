import React from 'react'

import { useNavigate } from 'react-router'

import { List, ListItemProps } from 'components'
import ContentContainer from 'components/ContentContainer/ContentContainer'

import { SimulationRoutesEnum } from 'routes/simulation.routes'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function SimulationMenu() {
  const navigate = useNavigate()
  const { t } = useLanguage()

  const menuItems: ListItemProps[] = [
    {
      primary: t('simulation.menu.newSimulation'),
      onClick: () => navigate(SimulationRoutesEnum.DOCUMENT_SELECTION)
    },
    {
      primary: t('simulation.menu.simulationHistory'),
      onClick: () => navigate(SimulationRoutesEnum.SIMULATION_HISTORY)
    }
  ]

  return (
    <ContentContainer>
      <List items={menuItems} />
    </ContentContainer>
  )
}

export default SimulationMenu
export { SimulationMenu }
