import React from 'react'

import { TextField, TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'

import ErrorMessage from '../ErrorMessage/ErrorMessage'

/**
 *
 */
export type InputProps = TextFieldProps & {
  name: string
  maxLength?: number
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Input(props: InputProps) {
  return (
    <Controller
      name={props.name}
      render={({ field, fieldState }) => {
        const { error } = fieldState
        const { maxLength, ...restProps } = props

        const mergedProps = {
          ...restProps,
          ...field
        }

        return (
          <TextField
            {...mergedProps}
            error={!!error}
            FormHelperTextProps={{
              sx: {
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5
              }
            }}
            inputProps={{ maxLength, ...mergedProps.inputProps }}
            helperText={
              error?.message ? <ErrorMessage error={error} /> : props.helperText
            }
          />
        )
      }}
    />
  )
}

export default Input
export { Input }
