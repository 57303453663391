import React from 'react'

import { useFormContext } from 'react-hook-form'

import { useGetDayEnums } from 'hooks/notifications/useGetDayEnums'

import { Select } from '../Select'
import { SelectProps } from './types'

type DaysSelectProps = SelectProps

/**
 *
 * @param props Props
 * @returns TSX
 */
function DaysSelect(props: DaysSelectProps) {
  const {
    name,
    label,
    margin,
    fullWidth,
    sx,
    labelAsValue,
    shouldSetInitialFormValue
  } = props
  const { days } = useGetDayEnums()
  const { setValue } = useFormContext()

  const daysOptions = React.useMemo(
    () =>
      Object.entries(days ?? {}).map(([label, value]) => ({
        label,
        value: labelAsValue ? label : value
      })),
    [days, labelAsValue]
  )

  React.useEffect(() => {
    if (daysOptions?.length && shouldSetInitialFormValue) {
      setValue(name, daysOptions[0].value)
    }
  }, [days, daysOptions, name, setValue, shouldSetInitialFormValue])

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={daysOptions ?? []}
      sx={sx}
    />
  )
}

export default DaysSelect
export { DaysSelect }
