import { getUserRoleById } from '@volvo-apps/shared/core/actions/UserRoles'

import { useQuery } from 'react-query'
import RoleDTO from '../../../models/RoleDTO'

type useGetUserRoleByIdParams = {
  id: number
  onSuccess?: (data: RoleDTO) => void
  onError?: (error: Error) => void
}
/**
 * Hook to get user role by id
 *
 * @param params getUserRoleByID params
 * @returns user role, isLoading, refetch
 */
export const useGetUserRoleById = (params: useGetUserRoleByIdParams) => {
  const { onError, onSuccess, id } = params
  const {
    data: role,
    isLoading,
    refetch
  } = useQuery(['getUserRoleById', id], {
    queryFn: () => getUserRoleById(id),
    onSuccess,
    onError
  })

  return { role, isLoading, refetch }
}
