import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type AutomationData = {
  /**
   *
   */
  interval: string
  /**
   *
   */
  dayWeek: string
  /**
   *
   */
  hourDay: string
  /**
   *
   */
  email: string
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    interval: Yup.string().required('validation.required.general'),
    dayWeek: Yup.string().required('validation.required.general'),
    hourDay: Yup.string().required('validation.required.general'),
    email: Yup.string().required('validation.required.general')
  })
)
