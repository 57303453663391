import ProductLineDTO from '@volvo-apps/shared/models/ProductLineDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'
import { PlainButton } from 'components/PlainButton'

import { ParametersRoutesEnum as ProductLineRoutesEnum } from 'routes/cms/routesEnums'

import { useCrudProductLine } from 'hooks/cms/useCrudProductLine'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { columns } from './productLineColumns'

/**
 * Product Lines List
 *
 * @returns TSX
 */
function List() {
  const { productLines, getLoading, updateAsyncProductLine } =
    useCrudProductLine()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const handleToggle = React.useCallback(
    async (productLine: Record<string, unknown>, checked: boolean) => {
      const newProductLine = new ProductLineDTO().fromJSON({
        ...productLine,
        active: checked
      })

      const updateResponse = await updateAsyncProductLine({
        productLine: newProductLine
      })

      toast.success(
        updateResponse.data.active
          ? t('cms.productLines.toggle.enabled')
          : t('cms.productLines.toggle.disabled')
      )
    },
    [t, updateAsyncProductLine]
  )

  const handleCreate = React.useCallback(() => {
    navigate(ProductLineRoutesEnum.PRODUCT_LINES_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.productLines.list.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.productLines.list.title')}
      </Typography>
      <CrudDataGrid
        columns={columns}
        rows={productLines || []}
        loading={getLoading}
        handleToggle={handleToggle}
        linkEditProps={{
          to: (id) =>
            ProductLineRoutesEnum.PRODUCT_LINES_UPDATE.replace(
              ':id',
              id.toString()
            )
        }}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
