import { t } from 'i18next'
import { toast } from 'react-toastify'

import { useDeleteTerm } from 'hooks/terms/useDeleteTerm'
import { useGetCmsTerms } from 'hooks/terms/useGetCmsTerms'
import { useUpdateTerm } from 'hooks/terms/useUpdateTerm'

/**
 * Hook to use CRUD operations on terms
 *
 * @returns terms, deleteTerm, updateTerm, refetch, isLoading, getLoading, deleteLoading, updateLoading
 */
export const useCrudTerm = () => {
  const { terms, refetch, isLoading: getLoading } = useGetCmsTerms()
  const { mutate: deleteTerm, isLoading: deleteLoading } = useDeleteTerm({
    onSuccess: () => {
      refetch()
      toast.success(t('cms.terms.delete.success'))
    }
  })
  const {
    mutate: updateTerm,
    isLoading: updateLoading,
    mutateAsync: updateAsyncTerm
  } = useUpdateTerm({
    onSuccess: refetch
  })

  const isLoading = getLoading || deleteLoading || updateLoading

  return {
    terms,
    deleteTerm,
    updateTerm,
    updateAsyncTerm,
    refetch,
    isLoading,
    getLoading,
    deleteLoading,
    updateLoading
  }
}
