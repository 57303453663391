import { exportLeads } from '@volvo-apps/shared/core/actions/Leads'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to export leads
 *
 * @returns Leads, isLoading, mutate, mutateAsync
 */
export const useExportLeads = () => {
  const {
    data: leads,
    isLoading,
    mutate,
    mutateAsync
  } = useMutation('ExportLeads', {
    mutationFn: ({
      startDate,
      endDate
    }: {
      startDate: string
      endDate: string
    }) => exportLeads(startDate, endDate),
    onError: handleErrorFeedback
  })

  return { leads, isLoading, mutate, mutateAsync }
}
