import { t } from 'i18next'
import { toast } from 'react-toastify'

import { useGetCmsProductLines } from 'hooks/productLine/useGetCmsProductLines'

import { useDeleteProductLine } from '../productLine/useDeleteProductLine'
import { useUpdateProductLine } from '../productLine/useUpdateProductLine'

/**
 * Hook to use CRUD operations on product lines
 *
 * @returns productLines, deleteProductLine, updateProductLine, refetch, isLoading, getLoading, deleteLoading, updateLoading
 */
export const useCrudProductLine = () => {
  const {
    productLines,
    refetch,
    isLoading: getLoading
  } = useGetCmsProductLines()
  const { mutate: deleteProductLine, isLoading: deleteLoading } =
    useDeleteProductLine({
      onSuccess: () => {
        refetch()
        toast.success(t('cms.productLines.delete.success'))
      }
    })
  const {
    mutate: updateProductLine,
    isLoading: updateLoading,
    mutateAsync: updateAsyncProductLine
  } = useUpdateProductLine({
    onSuccess: refetch
  })

  const isLoading = getLoading || deleteLoading || updateLoading

  return {
    productLines,
    deleteProductLine,
    updateProductLine,
    updateAsyncProductLine,
    refetch,
    isLoading,
    getLoading,
    deleteLoading,
    updateLoading
  }
}
