import React from 'react'

import { Box, Divider, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { Link } from 'components'

import { ExternalRoutesEnum } from 'routes/types'

import Colors from 'constants/Colors'

import { ReactComponent as Logotype } from 'assets/images/Logotype.svg'
import { useLanguage } from 'context/Language'

/**
 * @returns JSX
 */
function Footer() {
  const year = dayjs().year()

  const { t } = useLanguage()

  return (
    <>
      <Box flexDirection="row" display="flex" alignItems="center">
        <Logotype width="9.375rem" />
        <Typography fontSize={12} color={Colors.light.greys.GREY_600}>
          © Copyright AB Volvo {year}
        </Typography>
      </Box>
      <Box flexDirection="row" display="flex" alignItems="center">
        <Link href={ExternalRoutesEnum.INSTITUTIONAL} mx={1}>
          <Typography fontSize={16} color={Colors.light.greys.GREY_600}>
            https://www.volvogroup.com/br/
          </Typography>
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link href={ExternalRoutesEnum.PRIVACY_POLICY} mx={1}>
          <Typography fontSize={16} color={Colors.light.greys.GREY_600}>
            {t('general.privacy')}
          </Typography>
        </Link>
      </Box>
    </>
  )
}

export default Footer
export { Footer }
