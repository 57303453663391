import React from 'react'

import { Box } from '@mui/system'
import { UseFormReturn } from 'react-hook-form'

import { Datepicker, Form } from 'components'
import { PlainButton } from 'components/PlainButton'

import { useLanguage } from 'context/Language'

import { UserReportsData } from './schema'

type UserReportsFormProps = {
  onSubmit: (formData: UserReportsData) => void
  isLoading: boolean
  methods: UseFormReturn<UserReportsData, unknown>
  submitButtonLabel?: string | null
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function UserReportsForm(props: UserReportsFormProps) {
  const { onSubmit, isLoading, methods, submitButtonLabel } = props

  const { t } = useLanguage()

  const disabled = React.useMemo(
    () => !methods.formState.isValid,
    [methods.formState.isValid]
  )

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '45%'
        })
      }}
    >
      <Box marginBottom={4}>
        <Datepicker
          fullWidth
          label={t('cms.reports.startDate')}
          name="beginDateStr"
          maxDate={new Date()}
          onChange={() => {
            methods.trigger('beginDateStr')
          }}
        />
      </Box>
      <Datepicker
        fullWidth
        label={t('cms.reports.endDate')}
        name="endDateStr"
        maxDate={new Date()}
        onChange={() => {
          methods.trigger('endDateStr')
        }}
      />
      <PlainButton
        type="submit"
        variant="contained"
        disabled={disabled}
        loading={isLoading}
        sx={{ mt: 3, mb: 2, width: '50%' }}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default UserReportsForm
export { UserReportsForm }
