import { t } from 'i18next'
import { toast } from 'react-toastify'

import { useGetNotifications } from 'hooks/notifications/useGetNotifications'

import { useDeleteNotification } from '../notifications/useDeleteNotification'
import { useUpdateNotification } from '../notifications/useUpdateNotification'

/**
 * Hook to use CRUD operations on notifications
 *
 * @returns notifications, deleteNotifications, updateNotifications, refetch, isLoading, getLoading, deleteLoading, updateLoading
 */
export const useCrudNotification = () => {
  const {
    notifications,
    refetch,
    isLoading: getLoading
  } = useGetNotifications()
  const { mutate: deleteNotification, isLoading: deleteLoading } =
    useDeleteNotification({
      onSuccess: () => {
        refetch()
        toast.success(t('cms.pushNotifications.delete.success'))
      }
    })
  const {
    mutate: updateNotification,
    isLoading: updateLoading,
    mutateAsync: updateAsyncNotification
  } = useUpdateNotification({
    onSuccess: refetch
  })

  const isLoading = getLoading || deleteLoading || updateLoading

  return {
    notifications,
    deleteNotification,
    updateNotification,
    updateAsyncNotification,
    refetch,
    isLoading,
    getLoading,
    deleteLoading,
    updateLoading
  }
}
