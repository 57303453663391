import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as FaqQuestionRoutesEnum } from 'routes/cms/routesEnums'

import { useCreateFaqQuestion } from 'hooks/faqQuestions/useCreateFaqQuestion'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { FaqQuestionForm } from '../Form/FaqQuestionForm'
import { FaqQuestionData } from '../Form/schema'
import { useFaqQuestionForm } from '../Form/useFaqQuestionForm'

/**
 * Faq Question Create
 *
 * @returns TSX
 */
function Create() {
  const { methods } = useFaqQuestionForm()
  const navigate = useNavigate()
  const { t } = useLanguage()
  const { mutate, isLoading } = useCreateFaqQuestion({
    onSuccess: () => {
      toast.success(t('cms.faq.createQuestion.success'))
      navigate(FaqQuestionRoutesEnum.FAQ_QUESTIONS_VIEW)
    }
  })

  const onSubmit = React.useCallback(
    (formData: FaqQuestionData) => {
      mutate(formData)
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.faq.createQuestion.title')}
      </Typography>
      <FaqQuestionForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.faq.createQuestion.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
