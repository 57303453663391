import React from 'react'

import { Typography } from '@mui/material'

import Colors from 'constants/Colors'

type CmsMenuHeaderProps = {
  titleText: string
  subtitleText: string
}

/**
 *
 * @param props CmsMenuHeaderProps
 * @returns TSX
 */
function CmsMenuHeader(props: CmsMenuHeaderProps) {
  const { titleText, subtitleText } = props

  return (
    <>
      <Typography
        mt={4}
        mb={1}
        fontSize={20}
        color={Colors.light.greys.GREY_600}
      >
        {titleText}
      </Typography>
      <Typography fontSize={16} color={Colors.light.greys.GREY_600}>
        {subtitleText}
      </Typography>
    </>
  )
}
export default CmsMenuHeader
export { CmsMenuHeader }
