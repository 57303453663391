import { useGetVehiclesData } from '@volvo-apps/shared/core/hooks/sales/useGetVehiclesData'

import React from 'react'

import { BoxProps, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Loader } from 'components/Loader'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import RentalVehicleCard from './RentalVehicleCard'

type RentalVehiclesProps = {
  document: string
  boxProps?: BoxProps
  detailsLabel?: {
    to: string
    state: unknown
  }
}

/**
 *
 * @param props RentalVehiclesProps
 * @returns TSX
 */
function RentalVehicles(props: RentalVehiclesProps) {
  const { document } = props

  const { t } = useLanguage()
  const { data: rental, isLoading } = useGetVehiclesData({
    document,
    cacheTime: 0
  })

  return (
    <>
      <Typography
        fontFamily={'Volvo-Novum-Medium'}
        variant="subtitle2"
        color={Colors.light.interactActive}
        mt={4}
      >
        {t('financial.rental.vehicles')}
      </Typography>
      <FlexBox flexDirection="column" gap={3} pt={2} pb={3}>
        {isLoading ? (
          <Loader size={16} />
        ) : (
          rental &&
          rental.Veiculos?.length > 0 &&
          rental.Veiculos.map((veiculo, index) => (
            <RentalVehicleCard
              vehicle={veiculo}
              key={index}
              boxProps={props.boxProps}
            />
          ))
        )}
      </FlexBox>
    </>
  )
}

export default RentalVehicles
export { RentalVehicles }
