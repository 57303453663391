import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'
import UserDomainDTO from '../../models/UserDomainDTO'

/**
 * Functional Domain
 */
export class UserDomainService extends GenericService<UserDomainDTO> {
  readonly URI: string = 'gateway/UserDomains'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get functional domains
   */
  public async getUserDomains() {
    return super.get<AxiosResponse<UserDomainDTO[]>>('GetDomains')
  }

  /**
   * Add a new functional domain
   *
   * @param domain add a new functional domain
   */
  public async addUserDomain(domain: UserDomainDTO) {
    return super.post<AxiosResponse<UserDomainDTO>>('Add', domain)
  }

  /**
   * Delete functional domain
   *
   * @param id The id of the functional domain
   */
  public async deleteUserDomain(id: number) {
    return super.delete('Inactivate', { id })
  }
}
