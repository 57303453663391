import React from 'react'

import { Typography } from '@mui/material'
import { useUser } from 'context'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

/**
 *@returns TSX
 */
function UserAvatar() {
  const style = {
    alignItems: 'center',
    borderRadius: '50%',
    overflow: 'hidden',
    height: '64px',
    width: '64px',
    background: Colors.light.greys.GREY_300,
    borderStyle: 'solid',
    borderColor: Colors.light.greys.GREY_400,
    borderWidth: '1px'
  }
  const { userData } = useUser()
  const initialLetters = (userData?.name || '')
    .split(' ')
    .slice(0, 2)
    .map((n) => n.charAt(0))
    .join('')
    .toUpperCase()

  return (
    <FlexBox sx={style} justifyContent="center" alignItems="center">
      <Typography align="center" color="white" fontSize={26}>
        {initialLetters}
      </Typography>
    </FlexBox>
  )
}

export default UserAvatar
export { UserAvatar }
