/**
 *
 * @param data blob to convert to URL
 * @param type Type of the file
 * @returns URL to download
 */
export function blobToURL(data: Blob | string, type?: string) {
  const blob = new Blob([data], { type })
  return URL.createObjectURL(blob)
}

/**
 *
 * @param url URL to download
 * @param fileName Name of the file
 */
export function download(url: string, fileName: string) {
  const link = document.createElement('a')
  link.download = fileName
  link.href = url
  link.target = '_blank'
  link.rel = 'noopener noreferrer'
  link.click()

  link.parentNode?.removeChild(link)
}
