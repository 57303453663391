import React from 'react'

import { Typography } from '@mui/material'

import { Link } from 'components'
import { FlexBox } from 'components/FlexBox'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function NotFound() {
  const { t } = useLanguage()

  return (
    <FlexBox width="100%" flexDirection="column" minHeight="100vh">
      <Header items={[]} />
      <FlexBox
        flexDirection="column"
        mt={12}
        width="80%"
        alignSelf="center"
        flex={1}
      >
        <FlexBox>
          <Icon
            family="mui"
            name="ErrorOutline"
            fontSize="large"
            sx={{
              color: Colors.light.blue
            }}
          />
          <Typography
            ml={2}
            color={Colors.light.blue}
            fontWeight="bold"
            fontSize={24}
          >
            {t('notFound.title')}
          </Typography>
        </FlexBox>

        <FlexBox>
          <Typography
            mt={3}
            mr={0.5}
            color={Colors.light.greys.GREY_500}
            fontSize={16}
            fontWeight="bold"
          >
            {t('notFound.description')}
          </Typography>
          <Link
            to="/dashboard"
            mt={3}
            color={Colors.light.interactDefault}
            fontSize={16}
            fontWeight="bold"
            underline="always"
          >
            {t(`notFound.link.home`)}
          </Link>
        </FlexBox>
      </FlexBox>

      <FlexBox
        minHeight="4em"
        alignContent="center"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        px={15}
        sx={{
          backgroundColor: Colors.light.greys.GREY_100
        }}
      >
        <Footer />
      </FlexBox>
    </FlexBox>
  )
}

export default NotFound
export { NotFound }
