import { useGetYears } from '@volvo-apps/shared/core/hooks/incomeReport/useGetYears'

import React from 'react'

import { Select } from '../Select'
import { SelectProps } from './types'

type YearSelectProps = SelectProps & {
  appendOptions?: { label: string; value: string | number }[]
  valueAsYear?: boolean
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function YearSelect(props: YearSelectProps) {
  const {
    name,
    label,
    margin,
    fullWidth,
    sx,
    appendOptions = [],
    disabled,
    valueAsYear = false
  } = props

  const { years } = useGetYears()

  const yearOptions = React.useMemo(
    () =>
      years?.map((yearDTO) => ({
        label: yearDTO.year,
        value: valueAsYear ? yearDTO.year : yearDTO.id
      })) ?? [],
    [years, valueAsYear]
  )

  const options = [...appendOptions, ...yearOptions]

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={options ?? []}
      sx={sx}
      disabled={disabled}
    />
  )
}

export default YearSelect
export { YearSelect }
