import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import PartsAndServicesDataDTO from './PartsAndServicesDataDTO'

/**
 * PartsAndServicesUserDTO
 */
class PartsAndServicesUserDTO extends Serializable {
  @jsonName('document')
  @jsonProperty(String)
  public document!: string

  @jsonName('partsAndServicesDataList')
  @jsonProperty(Array, null, undefined)
  public partsAndServicesDataList!: PartsAndServicesDataDTO[]
}

export default PartsAndServicesUserDTO
