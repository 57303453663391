import React from 'react'

import { Box, Typography } from '@mui/material'
import { useUser } from 'context'
import { useNavigate } from 'react-router-dom'

import { List, ListItemProps } from 'components'

import { ProfileRoutesEnum } from 'routes/profile.routes'
import { ExternalRoutesEnum } from 'routes/types'

import Colors from 'constants/Colors'

import { ReactComponent as Documents } from 'assets/icons/documents.svg'
import { ReactComponent as Edit } from 'assets/icons/edit.svg'
import { ReactComponent as LinkDocuments } from 'assets/icons/linkDocuments.svg'
import { ReactComponent as Remove } from 'assets/icons/remove.svg'
import { ReactComponent as Lock } from 'assets/icons/sysLock.svg'
import { ReactComponent as User } from 'assets/icons/user.svg'
import { openExternalLink } from 'common/utils/url'
import { useLanguage } from 'context/Language'

import { UserAvatar } from './UserAvatar'

/**
 * @returns JSX
 */
function Profile() {
  const { userData } = useUser()
  const { t } = useLanguage()

  const navigate = useNavigate()

  const menuItems: ListItemProps[] = [
    {
      primary: t('profile.menu.editProfile.title'),
      secondary: t('profile.menu.editProfile.description'),
      icon: <User color="black" />,
      onClick: () => navigate(ProfileRoutesEnum.PROFILE_EDIT)
    },
    {
      primary: t('profile.menu.changePassword.title'),
      secondary: t('profile.menu.changePassword.description'),
      icon: <Edit />,
      onClick: () => navigate(ProfileRoutesEnum.CHANGE_PASSWORD)
    },
    {
      primary: t('profile.menu.linkDocuments.title'),
      secondary: t('profile.menu.linkDocuments.description'),
      icon: <LinkDocuments color="black" fill="black" />,
      onClick: () => navigate(ProfileRoutesEnum.LINK_DOCUMENTS)
    },
    {
      primary: t('profile.menu.termsAndConditions.title'),
      secondary: t('profile.menu.termsAndConditions.description'),
      icon: <Documents />,
      onClick: () => navigate(ProfileRoutesEnum.TERM_AND_CONDITIONS)
    },
    {
      primary: t('profile.menu.privacyPolicy.title'),
      secondary: t('profile.menu.privacyPolicy.description'),
      icon: <Lock />,
      onClick: () => openExternalLink(ExternalRoutesEnum.PRIVACY_POLICY)
    },
    // {
    //   primary: t('profile.menu.creditAuthorization.title'),
    //   secondary: t('profile.menu.creditAuthorization.description'),
    //   icon: <Document />,
    //   onClick: () => navigate(ProfileRoutesEnum.CREDIT_AUTHORIZATION)
    // },
    {
      primary: t('profile.menu.deleteAccount.title'),
      secondary: t('profile.menu.deleteAccount.description'),
      icon: <Remove />,
      onClick: () => navigate(ProfileRoutesEnum.EXCLUDE_ACCOUNT)
    }
  ]

  return (
    <Box width={(t) => t.breakpoints.values.md}>
      <Box mt={1}>
        <Box
          mx={5}
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
        >
          <UserAvatar />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography fontSize={16} color={Colors.light.blue}>
              {userData?.name}
            </Typography>
          </Box>
        </Box>
        <List items={menuItems} />
      </Box>
    </Box>
  )
}

export default Profile
export { Profile }
