import { FinancialProductService } from '@volvo-apps/shared/core/service/FinancialProduct.service'
import FinancialProductDTO from '@volvo-apps/shared/models/FinancialProductDTO'

/**
 * @param productLine productLine id
 * @returns All financial products
 */
export async function getAllFinancialProducts(productLine: number) {
  const service = new FinancialProductService()
  const response = await service.getAll(productLine)

  return response.data.map((fp) => new FinancialProductDTO().fromJSON(fp))
}

/**
 * @param id The id of the financial product
 * @returns financial product
 */
export async function getFinancialProduct(id: number) {
  const service = new FinancialProductService()
  const response = await service.getFinancialProduct(id)
  return new FinancialProductDTO().fromJSON(response.data)
}

/**
 * @param id The id of the financial product
 * @returns Delete financial product
 */
export async function deleteFinancialProduct(id: number) {
  const service = new FinancialProductService()
  const response = await service.deleteFinancialProduct(id)

  return response
}

/**
 * @param updatedFinancialProduct The updated financial product
 */
export async function updateFinancialProduct(
  updatedFinancialProduct: FinancialProductDTO
) {
  const service = new FinancialProductService()
  const response = await service.updateFinancialProduct(updatedFinancialProduct)

  return response
}

/**
 *
 * @param name The name of the financial product
 */
export async function createFinancialProduct(name: string) {
  const service = new FinancialProductService()
  const response = await service.createFinancialProduct(name)

  return response
}

/**
 * @returns All cms financial products
 */
export async function getAllCmsFinancialProducts() {
  const service = new FinancialProductService()
  const response = await service.getAllCms()

  return response.data.map((fp) => new FinancialProductDTO().fromJSON(fp))
}
