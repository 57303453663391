import { updateBanners } from '@volvo-apps/shared/core/actions/Banner'
import BannerConfigDTO from '@volvo-apps/shared/models/BannerConfigDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateBannersParams = {
  onSuccess?: (response: BannerConfigDTO) => void
}

/**
 * Hook to update one banners
 *
 * @param params params
 * @returns mutate, bannersConfig, isLoading and mutateAsync
 */
export const useUpdateBanners = (params: useUpdateBannersParams = {}) => {
  const { onSuccess } = params
  const {
    mutate,
    isLoading,
    data: bannersConfig,
    mutateAsync
  } = useMutation('updateBanners', {
    mutationFn: updateBanners,
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, bannersConfig, isLoading, mutateAsync }
}
