import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import { conditionalFormat } from 'common/utils/documents'

/**
 *
 * @param handleEdit handleEdit
 * @returns TSX
 */
export const tableColumns: GridColDef[] = [
  {
    field: 'signUpDocument',
    headerName: 'cms.resetEmailAndPassword.document',
    minWidth: 170,
    flex: 1,
    sortable: false,
    renderCell: (params) => conditionalFormat(params.row.signUpDocument)
  },
  {
    field: 'name',
    headerName: 'cms.resetEmailAndPassword.name',
    minWidth: 280,
    flex: 1,
    sortable: false
  },
  {
    field: 'email',
    headerName: 'cms.resetEmailAndPassword.userEmail',
    minWidth: 280,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const email = params.row.email as string

      return (
        <Typography fontSize={16} overflow="auto">
          {email}
        </Typography>
      )
    }
  }
]
