import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import ParameterDetailDTO from './ParameterDetailDTO'

/**
 * SimulationParameterDTO
 */
class SimulationParameterDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: string

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: string

  @jsonName('updatedDate')
  @jsonProperty(Date)
  public updatedDate!: string

  @jsonName('financialProductId')
  @jsonProperty(Number)
  public financialProductId!: number

  @jsonName('productLineId')
  @jsonProperty(Number)
  public productLineId!: number

  @jsonName('tacValue')
  @jsonProperty(Number)
  public tacValue!: number

  @jsonName('monthlyInterestRate')
  @jsonProperty(Number)
  public monthlyInterestRate!: number

  @jsonName('moneyLenderInsurance')
  @jsonProperty(Boolean)
  public moneyLenderInsurance!: boolean

  @jsonName('isSelicInterestRate')
  @jsonProperty(Boolean)
  public isSelicInterestRate!: boolean

  @jsonName('program')
  @jsonProperty(String)
  public program!: string

  @jsonName('subProgram')
  @jsonProperty(String)
  public subProgram!: string

  @jsonName('bndesCondition')
  @jsonProperty(String)
  public bndesCondition!: string

  @jsonName('bndesSequence')
  @jsonProperty(String)
  public bndesSequence!: string

  @jsonName('startDate')
  @jsonProperty(Date)
  public startDate!: string

  @jsonName('endDate')
  @jsonProperty(Date)
  public endDate!: string

  @jsonName('volvoRate')
  @jsonProperty(Number)
  public volvoRate!: number

  @jsonName('selicValue')
  @jsonProperty(Number)
  public selicValue!: number

  @jsonName('ipcaValue')
  @jsonProperty(Number)
  public ipcaValue!: number

  @jsonName('author')
  @jsonProperty(String)
  public author!: string

  @jsonName('userId')
  @jsonProperty(Number)
  public userId!: number

  @jsonName('parametersDetailDto')
  @jsonProperty(Array)
  public parametersDetailDto!: ParameterDetailDTO[]
}

export default SimulationParameterDTO
