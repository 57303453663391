import React from 'react'

import { Divider } from '@mui/material'

import { Link } from 'components'
import { TermsAndConditionsModal } from 'components/TermsAndConditions'

import { useLanguage } from 'context/Language'

/**
 * @returns JSX
 */
function Footer() {
  const { t } = useLanguage()

  return (
    <>
      <TermsAndConditionsModal
        triggerComponent={
          <Link to="#" mx={1}>
            {t('general.termsAndConditions')}
          </Link>
        }
      />
      <Divider orientation="vertical" flexItem />
      <Link
        href="https://www.vfsco.com/br/about-us/data-privacy.html"
        mx={1}
        underline="none"
      >
        {t('general.privacyPolicy')}
      </Link>
    </>
  )
}

export default Footer
export { Footer }
