import ConsortiumClosedResourcesDocumentDTO from '../../models/ConsortiumClosedResourcesDocumentDTO'
import ConsortiumClosedResourcesHistoryDTO from '../../models/ConsortiumClosedResourcesHistoryDTO'
import ConsortiumClosedResourcesImportDTO from '../../models/ConsortiumClosedResourcesImportDTO'
import { ConsortiumClosedResourcesService } from '../service/ConsortiumClosedResources'

/**
 * @returns ConsortiumClosedResourcesImportDTO[]
 */
export async function getImportXMLHistory() {
  const service = new ConsortiumClosedResourcesService()
  const response = await service.getImportXMLHistory()

  return response.data.map((item) =>
    new ConsortiumClosedResourcesHistoryDTO().fromJSON(item)
  )
}

/**
 *
 * @param formData formData
 */
export async function importFile(formData: FormData) {
  const service = new ConsortiumClosedResourcesService()
  const response = await service.importFile(formData)
  return new ConsortiumClosedResourcesImportDTO().fromJSON(response.data)
}

/**
 *
 * @param document document
 */
export async function getByDocument(document: string) {
  const service = new ConsortiumClosedResourcesService()
  const response = await service.getByDocument(document)
  return new ConsortiumClosedResourcesDocumentDTO().fromJSON(response.data)
}

/**
 * @param id The id of resource
 * @returns Delete consortium closed resource
 */
export async function deleteConsortiumClosedResource(id: number) {
  const service = new ConsortiumClosedResourcesService()
  const response = await service.deleteConsortiumClosedResource(id)

  return response
}
