import React, { PropsWithChildren } from 'react'

import { Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'

import useCmsRoles from 'hooks/cms/useCmsRoles'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'
import { RoleEnum } from 'types/roles'

type NoPermissionContainerProps = {
  role?: RoleEnum
}

/**
 * @param props NoPermissionContainerProps
 * @returns TSX
 */
function NoPermissionContainer(
  props: PropsWithChildren<NoPermissionContainerProps>
) {
  const { role, children } = props
  const { hasRole } = useCmsRoles()

  const { t } = useLanguage()

  if (!role || hasRole(role)) {
    return <>{children}</>
  }

  return (
    <FlexBox width="100%" flexDirection="column">
      <FlexBox flexDirection="column" mt={6} width="100%" alignSelf="center">
        <FlexBox>
          <Icon
            family="mui"
            name="ErrorOutline"
            fontSize="large"
            sx={{
              color: Colors.light.blue
            }}
          />
          <Typography
            ml={2}
            color={Colors.light.blue}
            fontWeight="bold"
            fontSize={24}
          >
            {t('noPermission.title')}
          </Typography>
        </FlexBox>

        <FlexBox>
          <Typography
            mt={3}
            mr={0.5}
            color={Colors.light.greys.GREY_500}
            fontSize={16}
            fontWeight="bold"
          >
            {t('noPermission.description')}
          </Typography>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default NoPermissionContainer
export { NoPermissionContainer }
