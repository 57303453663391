import { getFaqQuestion } from '@volvo-apps/shared/core/actions/FaqQuestion'
import FaqQuestionDTO from '@volvo-apps/shared/models/FaqQuestionDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetFaqQuestionParams = {
  onSuccess?: (faqQuestion: FaqQuestionDTO) => void
  id?: number
}

/**
 * Hook to get a faq question
 *
 * @param params params
 * @returns faqQuestion, isLoading, refetch
 */
export const useGetFaqQuestion = (params: useGetFaqQuestionParams) => {
  const { id, onSuccess } = params
  const {
    data: faqQuestion,
    isLoading,
    refetch
  } = useQuery('getFaqQuestion', {
    enabled: !!id,
    queryFn: () => getFaqQuestion(id || 0),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { faqQuestion, isLoading, refetch }
}
