import React, { PropsWithChildren, ReactNode } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  SvgIcon,
  Typography
} from '@mui/material'

import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

import { FormNamesEnum } from './types'

type Props = {
  formName: FormNamesEnum
  formExpanded: FormNamesEnum | null
  toggleFormExpanded: (formName: FormNamesEnum) => VoidFunction
  title: string
  subtitle: string
  icon: ReactNode
  iconActive?: ReactNode
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function AccordionItem(props: PropsWithChildren<Props>) {
  const {
    formName,
    formExpanded,
    toggleFormExpanded,
    title,
    subtitle,
    icon,
    iconActive,
    children
  } = props

  const isActive = formExpanded === formName

  return (
    <Accordion
      disableGutters
      expanded={isActive}
      onChange={toggleFormExpanded(formName)}
      elevation={1}
    >
      <AccordionSummary expandIcon={<Icon family="mui" name="ExpandMore" />}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <SvgIcon
            sx={(t) => ({
              width: t.spacing(6),
              height: t.spacing(6),
              mr: t.spacing(1),
              color: isActive ? Colors.light.interactDefault : Colors.light.text
            })}
          >
            {isActive ? iconActive ?? icon : icon}
          </SvgIcon>
          <Box>
            <Typography
              color={
                isActive ? Colors.light.interactDefault : Colors.light.text
              }
            >
              {title}
            </Typography>

            <Typography variant="body2">{subtitle}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column">
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionItem
export { AccordionItem }
