import React from 'react'

import { SideContent } from 'pages'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import { useLanguage } from 'context/Language'
import { Login } from 'pages/cms/Login'
import { CmsContent } from 'pages/layout/CmsContent'

import { TabsProps } from '../types'

export enum CmsTabsRoutesEnum {
  LOGIN = '/admin/login',
  HOME = '/admin/home',
  REGISTERS = '/admin/registers',
  PARAMETERS = '/admin/parameters',
  LEADS = '/admin/leads',
  PARTS_AND_SERVICES = '/admin/parts-and-services',
  USER_SETTINGS = '/admin/user-settings',
  SURVEYS = '/admin/surveys',
  REPORTS = '/admin/reports'
}

export const tabs: TabsProps[] = [
  {
    label: 'cms.dashboard.tabs.home',
    path: CmsTabsRoutesEnum.HOME
  },
  {
    label: 'cms.dashboard.tabs.registers',
    path: CmsTabsRoutesEnum.REGISTERS
  },
  {
    label: 'cms.dashboard.tabs.parameters',
    path: CmsTabsRoutesEnum.PARAMETERS
  },
  {
    label: 'cms.dashboard.tabs.leads',
    path: CmsTabsRoutesEnum.LEADS
  },
  {
    label: 'cms.dashboard.tabs.userSettings',
    path: CmsTabsRoutesEnum.USER_SETTINGS
  },

  {
    label: 'cms.dashboard.tabs.surveys',
    path: CmsTabsRoutesEnum.SURVEYS
  },
  {
    label: 'cms.dashboard.tabs.reports',
    path: CmsTabsRoutesEnum.REPORTS
  }
]

const GenericRoute = () => {
  const { t } = useLanguage()

  return (
    <div>
      <h3>{t('cms.dashboard.tabs.home')}</h3>
    </div>
  )
}

const cmsLoginRoute: CustomRouteProps = {
  path: CmsTabsRoutesEnum.LOGIN,
  noAuthOnly: true,
  layout: SideContent,
  element: <Login />
}

const cmsRoutes: CustomRouteProps[] = [
  {
    path: CmsTabsRoutesEnum.HOME,
    protected: true,
    layout: CmsContent,
    element: <GenericRoute />
  }
]

export default cmsRoutes
export { cmsLoginRoute }
