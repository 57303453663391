import { updateProductLine } from '@volvo-apps/shared/core/actions/ProductLine'
import ProductLineDTO from '@volvo-apps/shared/models/ProductLineDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateProductLineParams = {
  onSuccess?: () => void
}

/**
 * Hook to update one product line
 *
 * @param params params
 * @returns mutate, productLine, isLoading and mutateAsync
 */
export const useUpdateProductLine = (
  params: useUpdateProductLineParams = {}
) => {
  const { onSuccess } = params
  const {
    mutate,
    isLoading,
    data: productLine,
    mutateAsync
  } = useMutation('updateProductLine', {
    mutationFn: ({ productLine }: { productLine: ProductLineDTO }) =>
      updateProductLine(productLine),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, productLine, isLoading, mutateAsync }
}
