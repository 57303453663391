import { useGetActiveContractsByDocuments } from '@volvo-apps/shared/core/hooks/useGetActiveContractsByDocuments'
import DocumentDTO from '@volvo-apps/shared/models/DocumentDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useUser } from 'context'

import { FlexBox } from 'components/FlexBox'
import { Loader } from 'components/Loader'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

type DetailsProps = {
  documentDTO: DocumentDTO
}

/**
 *
 * @param props DetailsProps
 * @returns TSX
 */
function Details(props: DetailsProps) {
  const { documentDTO } = props

  const { t } = useLanguage()
  const { data = [], isLoading } = useGetActiveContractsByDocuments({
    document: documentDTO.document,
    onError: handleErrorFeedback
  })

  const { userData } = useUser()

  const companyName = data && data.length > 0 ? data[0].nome : ''

  if (!documentDTO.verified) {
    return (
      <FlexBox>
        <Typography fontSize={14} color={Colors.light.greys.GREY_600}>
          {t('configurations.contracts.pendingEmail')}{' '}
        </Typography>
        <Typography
          fontSize={14}
          color={Colors.light.text}
          pl={0.5}
          fontFamily="Volvo-Novum-Medium"
        >
          {userData?.maskedEmailAutbank || userData?.maskedEmail}
        </Typography>
      </FlexBox>
    )
  }

  if (isLoading) {
    return <Loader boxProps={{ justifyContent: 'center', py: 2 }} size={32} />
  }

  return (
    <FlexBox flexDirection="column" justifyContent="start" width="fit-content">
      <FlexBox flexDirection="column">
        <FlexBox flexDirection="row" gap={1} mb={2}>
          <Typography fontSize={14} color={Colors.light.greys.GREY_500}>
            {t('configurations.contracts.name')}
          </Typography>
          <Typography fontSize={14} color={Colors.light.greys.GREY_600}>
            {companyName}
          </Typography>
        </FlexBox>

        <Typography fontSize={14} color={Colors.light.greys.GREY_500} pb={2}>
          {t('configurations.contracts.itemContracts')}
        </Typography>

        {data?.map((contract, index) => (
          <FlexBox key={contract.numeroContrato + index} flexDirection="column">
            <FlexBox flexDirection="row">
              <Typography
                fontSize={14}
                color={Colors.light.greys.GREY_600}
                mr={0.5}
              >
                {index + 1}.
              </Typography>
              <Typography
                fontSize={14}
                color={Colors.light.greys.GREY_700}
                fontWeight="bold"
              >
                {contract.numeroContrato}
              </Typography>
            </FlexBox>

            <FlexBox flexDirection="row" columnGap={6} pl={3}>
              <FlexBox flexDirection="column">
                {/* <Typography fontSize={14} color={Colors.light.greys.GREY_600}>
                  {t('configurations.contracts.date')}
                </Typography> */}
                <Typography fontSize={14} color={Colors.light.greys.GREY_600}>
                  {t('configurations.contracts.financingType')}
                </Typography>
              </FlexBox>
              <FlexBox flexDirection="column">
                {/* <Typography fontSize={14} color={Colors.light.text}>
                  {contract.vencimento}
                </Typography> */}
                <Typography fontSize={14} color={Colors.light.text}>
                  {contract.tipoDeProduto}
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
    </FlexBox>
  )
}

export default Details
export { Details }
