import { NpsOriginEnum } from '@volvo-apps/shared/common/utils/enums'

import React from 'react'

import { Box } from '@mui/material'

import { NavBar } from 'components'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { NpsModals } from 'components/NpsModals'

import Colors from 'constants/Colors'

import { LayoutProps } from '../types'
import { useAppHeaderItems } from './useAppHeaderItems'

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function AppContent(props: LayoutProps) {
  const { breadcrumbs, hideHeader } = props

  const { items, handleNpsCloseOnLogout, showNps } = useAppHeaderItems()

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {!hideHeader && (
        <>
          <Header items={items} />
          {showNps && (
            <NpsModals
              origin={NpsOriginEnum.LOGOUT}
              handleClose={handleNpsCloseOnLogout}
            />
          )}
        </>
      )}
      {breadcrumbs && <NavBar routes={breadcrumbs} />}
      <Box display="flex" flex={1} justifyContent="center" pb={3}>
        {props.children}
      </Box>
      <Box
        minHeight="4em"
        display="flex"
        alignContent="center"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        px={15}
        sx={{
          backgroundColor: Colors.light.greys.GREY_100
        }}
      >
        <Footer />
      </Box>
    </Box>
  )
}

export default AppContent
export { AppContent }
