import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudList } from 'components/CrudList'
import { PlainButton } from 'components/PlainButton'

import { RegistersRoutesEnum as FaqCategoryRoutesEnum } from 'routes/cms/routesEnums'

import { useCrudFaqCategories } from 'hooks/cms/useCrudFaqCategories'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type FaqCategoryJSON = ReturnType<FaqCategoryDTO['toJSON']>

/**
 * Faq Category List
 *
 * @returns TSX
 */
function List() {
  const { faqCategory, updateAsyncFaqCategory, getLoading } =
    useCrudFaqCategories()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const items = React.useMemo(() => {
    return (
      faqCategory?.map((item) => ({
        ...item.toJSON(),
        primary: item.label,
        id: item.id
      })) ?? []
    )
  }, [faqCategory])

  const handleEdit = React.useCallback(
    (faqCategory: FaqCategoryJSON) => {
      const path = FaqCategoryRoutesEnum.FAQ_CATEGORIES_UPDATE.replace(
        ':id',
        faqCategory.id as string
      )

      navigate(path)
    },
    [navigate]
  )

  const handleToggle = React.useCallback(
    async (category: FaqCategoryJSON, checked: boolean) => {
      const newFaqCategory = new FaqCategoryDTO().fromJSON({
        ...category,
        active: checked
      })

      const updateResponse = await updateAsyncFaqCategory({
        faqCategory: newFaqCategory
      })

      toast.success(
        updateResponse.data.active
          ? t('cms.faq.categoryToggle.enabled')
          : t('cms.faq.categoryToggle.disabled')
      )
    },
    [t, updateAsyncFaqCategory]
  )

  const handleCreate = React.useCallback(() => {
    navigate(FaqCategoryRoutesEnum.FAQ_CATEGORIES_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.faq.categoryList.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.faq.categoryList.title')}
      </Typography>

      <CrudList
        items={items}
        title={t('cms.faq.categoryList.subtitle')}
        toggleKey="active"
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        getLoading={getLoading && !faqCategory}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
