import React, { ReactNode } from 'react'

import { ReactComponent as ChatCallouts } from 'assets/icons/chatCallouts.svg'
import { ReactComponent as Consortium } from 'assets/icons/consortium.svg'
import { ReactComponent as CreditCard } from 'assets/icons/creditCard.svg'
import { ReactComponent as Document } from 'assets/icons/document.svg'
import { ReactComponent as File } from 'assets/icons/file.svg'
import { ReactComponent as FinanceMoney } from 'assets/icons/financeMoney.svg'
import { ReactComponent as Key } from 'assets/icons/key.svg'
import { ReactComponent as Lock } from 'assets/icons/lock.svg'
import { ReactComponent as Money } from 'assets/icons/money.svg'
import { ReactComponent as Pix } from 'assets/icons/pix.svg'
import { ReactComponent as QuestionMark } from 'assets/icons/questionMark.svg'
import { ReactComponent as Truck } from 'assets/icons/truck.svg'
import { ReactComponent as User } from 'assets/icons/user.svg'

export const iconTranslator: Record<string, ReactNode> = {
  user: <User />,
  money: <Money />,
  truck: <Truck />,
  lock: <Lock />,
  chatCallouts: <ChatCallouts />,
  consortium: <Consortium />,
  file: <File />,
  key: <Key />,
  document: <Document />,
  financeMoney: <FinanceMoney />,
  creditCard: <CreditCard />,
  pix: <Pix />,
  questionMark: <QuestionMark />
}

/**
 *
 * @param iconStr IconAsString
 * @returns Icon Element
 */
export const getIconFromString = (iconStr = 'user') => iconTranslator[iconStr]
