import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import {
  ClaimsMenu,
  List as ClaimsList,
  Item,
  Accident
} from 'pages/InsurancePolicies/Claims'
import { Prize } from 'pages/InsurancePolicies/Claims/Prize'
import { InsurancePoliciesMenu } from 'pages/InsurancePolicies/Menu'
import {
  Detail,
  Vehicles,
  List as PoliciesList
} from 'pages/InsurancePolicies/Policies'
import { AppContent } from 'pages/layout/AppContent'

export enum InsurancePoliciesRoutesEnum {
  MENU = '/insurancepolicies/menu',
  POLICIES = '/insurancepolicies/policies',
  DETAIL = '/insurancepolicies/policies/detail',
  VEHICLES = '/insurancepolicies/vehicles/:id',
  CLAIMS = '/insurancepolicies/claims',
  CLAIMS_MENU = '/insurancepolicies/claims/menu',
  CLAIMS_ITEM = '/insurancepolicies/claims/item',
  CLAIMS_ACCIDENT = '/insurancepolicies/claims/accident',
  CLAIMS_PRIZE = '/insurancepolicies/claims/prize'
}

const InsurancePoliciesRoutes: CustomRouteProps[] = [
  {
    path: InsurancePoliciesRoutesEnum.MENU,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: InsurancePoliciesRoutesEnum.MENU,
        label: 'breadCrumbs.insurance'
      }
    ],
    protected: true,
    element: <InsurancePoliciesMenu />
  },
  {
    path: InsurancePoliciesRoutesEnum.POLICIES,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: InsurancePoliciesRoutesEnum.MENU,
        label: 'breadCrumbs.insurance'
      },
      {
        to: InsurancePoliciesRoutesEnum.POLICIES,
        label: 'insurancePolicies.menu.insurancePolicies'
      }
    ],
    protected: true,
    element: <PoliciesList />
  },
  {
    path: InsurancePoliciesRoutesEnum.DETAIL,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: InsurancePoliciesRoutesEnum.MENU,
        label: 'breadCrumbs.insurance'
      },
      {
        to: InsurancePoliciesRoutesEnum.POLICIES,
        label: 'insurancePolicies.menu.insurancePolicies'
      },
      {
        to: InsurancePoliciesRoutesEnum.DETAIL,
        label: 'insurancePolicies.policies.detail.title'
      }
    ],
    protected: true,
    element: <Detail />
  },
  {
    path: InsurancePoliciesRoutesEnum.VEHICLES,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: InsurancePoliciesRoutesEnum.MENU,
        label: 'breadCrumbs.insurance'
      },
      {
        to: InsurancePoliciesRoutesEnum.POLICIES,
        label: 'insurancePolicies.menu.insurancePolicies'
      },
      {
        to: InsurancePoliciesRoutesEnum.DETAIL,
        label: 'insurancePolicies.policies.detail.title',
        persistState: true
      },
      {
        to: InsurancePoliciesRoutesEnum.VEHICLES,
        label: 'insurancePolicies.policies.insuredVehicles'
      }
    ],
    protected: true,
    element: <Vehicles />
  },
  {
    path: InsurancePoliciesRoutesEnum.CLAIMS,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: InsurancePoliciesRoutesEnum.MENU,
        label: 'breadCrumbs.insurance'
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS,
        label: 'insurancePolicies.claims.title'
      }
    ],
    protected: true,
    element: <ClaimsList />
  },
  {
    path: InsurancePoliciesRoutesEnum.CLAIMS_MENU,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: InsurancePoliciesRoutesEnum.MENU,
        label: 'breadCrumbs.insurance'
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS,
        label: 'insurancePolicies.claims.title'
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS_MENU,
        label: 'insurancePolicies.claims.detail'
      }
    ],
    protected: true,
    element: <ClaimsMenu />
  },
  {
    path: InsurancePoliciesRoutesEnum.CLAIMS_ITEM,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: InsurancePoliciesRoutesEnum.MENU,
        label: 'breadCrumbs.insurance'
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS,
        label: 'insurancePolicies.claims.title'
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS_MENU,
        label: 'insurancePolicies.claims.detail',
        persistState: true
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS_ITEM,
        label: 'insurancePolicies.claims.item.title'
      }
    ],
    protected: true,
    element: <Item />
  },
  {
    path: InsurancePoliciesRoutesEnum.CLAIMS_ACCIDENT,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: InsurancePoliciesRoutesEnum.MENU,
        label: 'breadCrumbs.insurance'
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS,
        label: 'insurancePolicies.claims.title'
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS_MENU,
        label: 'insurancePolicies.claims.detail',
        persistState: true
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS_ACCIDENT,
        label: 'insurancePolicies.claims.accident.title'
      }
    ],
    protected: true,
    element: <Accident />
  },
  {
    path: InsurancePoliciesRoutesEnum.CLAIMS_PRIZE,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: InsurancePoliciesRoutesEnum.MENU,
        label: 'breadCrumbs.insurance'
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS,
        label: 'insurancePolicies.claims.title'
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS_MENU,
        label: 'insurancePolicies.claims.detail',
        persistState: true
      },
      {
        to: InsurancePoliciesRoutesEnum.CLAIMS_PRIZE,
        label: 'insurancePolicies.claims.prize.title'
      }
    ],
    protected: true,
    element: <Prize />
  }
]

export default InsurancePoliciesRoutes
