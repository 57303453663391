const tintColorLight = '#1C6BBA'

const BLACK = '#141414'

const WHITE = '#FFFFFF'

const BLUE_1 = '#2B8EDE'

const BLUE_2 = '#1C6BBA'

const BLUE_3 = '#104E9B'

const BLUE_4 = '#202A44'

const GREEN_1 = '#1A6C31'

const GREEN_2 = '#A8D46B'

const GREEN_3 = '#78B833'

const YELLOW = '#FFA000'

const RED = '#BF2012'

const TRANSPARENT = '#00000000'

const SHADOW = '#0000001a'

export const GREY_100 = '#f7f7f7'
export const GREY_200 = '#E1DFDD'
export const GREY_300 = '#d0d0d0'
export const GREY_400 = '#A7A8A9'
export const GREY_500 = '#888B8D'
export const GREY_600 = '#53565A'
export const GREY_700 = '#323232' // << sombra do modal
export const GREY_BACKGROUND = '#E4E7EC' // << background dos seguros

export const BROWN_ALERT = '#663C00'

export default {
  light: {
    /**
     * '#141414'
     */
    text: BLACK,
    /**
     * '#FFFFFF'
     */
    background: WHITE,
    /**
     * #1C6BBA'
     */
    tint: tintColorLight,
    /**
     * '#2B8EDE'
     */
    interactHover: BLUE_1,
    /**
     * '#1C6BBA'
     */
    interactDefault: BLUE_2,
    /**
     * '#104E9B'
     */
    interactActive: BLUE_3,
    /**
     * '#1A6C31'
     */
    success: GREEN_1,
    /**
     * '#A8D46B'
     */
    check: GREEN_2,
    /**
     * '#FFA000'
     */
    warning: YELLOW,
    /**
     * '#BF2012'
     */
    error: RED,
    greys: {
      /**
       * '#f7f7f7'
       */
      GREY_100,
      /**
       * '#E1DFDD'
       */
      GREY_200,
      /**
       * '#d0d0d0'
       */
      GREY_300,
      /**
       * '#A7A8A9'
       */
      GREY_400,
      /**
       * '#888B8D'
       */
      GREY_500,
      /**
       * #53565A
       */
      GREY_600,
      /**
       * '#323232'
       * Sombra do modal
       */
      GREY_700,
      /**
       * '#E4E7EC'
       * Background
       */
      GREY_BACKGROUND
    },
    /**
     * #202A44
     */
    blue: BLUE_4,
    transparent: TRANSPARENT,
    shadow: SHADOW,
    checkLight: GREEN_3
  }
}
