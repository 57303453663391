import { HookQueryParams } from '@volvo-apps/shared/common/utils/types'
import { getClientHasConsortium } from '@volvo-apps/shared/core/actions/Siems'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type UseGetClientHasConsortiumParams = HookQueryParams<boolean> & {
  enabled?: boolean
}

/**
 *
 * @param params Params
 * @param params.enabled If the query should be enabled or not
 * @returns Data, isLoading
 */
const useGetClientHasConsortium = (params: UseGetClientHasConsortiumParams) => {
  const { data, isLoading } = useQuery<boolean>(
    'hasConsortium',
    getClientHasConsortium,
    {
      ...params,
      onError: handleErrorFeedback
    }
  )

  return {
    data,
    isLoading
  }
}

export default useGetClientHasConsortium
