import React from 'react'

import { ChangePasswordModal } from 'components/ChangePasswordModal'

/**
 * @returns TSX
 */
const Modals: React.FC = () => {
  return (
    <>
      <ChangePasswordModal />
    </>
  )
}

export default Modals
export { Modals }
