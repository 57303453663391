import React from 'react'

import { Typography } from '@mui/material'

import { Modal } from 'components/Modal'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { ModalEnum, NpsModalsGenericProps } from './NpsModals'

type ThanksModalProps = NpsModalsGenericProps

/**
 * @param props ThanksModalProps
 * @returns TSX
 */
const ThanksModal = (props: ThanksModalProps) => {
  const { changeModal, handleClose } = props

  const { t } = useLanguage()

  const _handleClose = () => {
    changeModal(ModalEnum.Empty)
    handleClose?.()
  }
  return (
    <Modal
      disableBackdropClick
      showCloseButton
      title={
        <Typography fontSize={32} color={Colors.light.text}>
          {t('nps.thanksModal.title')}
        </Typography>
      }
      PaperProps={{ sx: { p: 1, borderRadius: 2.5 } }}
      subtitle={t('nps.thanksModal.description')}
      actions={[
        {
          children: t('nps.thanksModal.ok'),
          onClick: _handleClose,
          sx: {
            backgroundColor: Colors.light.interactDefault,
            width: '100%'
          },
          variant: 'contained'
        }
      ]}
      onClose={_handleClose}
      open={true}
    />
  )
}

export default ThanksModal
export { ThanksModal }
