import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'
import { InvoiceDataDTO } from './ContractPaginationDTO'

/**
 *  Rental detail model
 */
class RentalDetailsDTO extends Serializable {
  @jsonProperty(Boolean)
  hasMonthlyBillFile!: boolean

  @jsonProperty(Boolean)
  hasAdditionalFile!: boolean

  @jsonProperty(Number)
  document!: number

  @jsonProperty(Number)
  qtdVeiculos!: number

  @jsonProperty(InvoiceDataDTO)
  rentalDetails!: InvoiceDataDTO
}

export default RentalDetailsDTO
export { RentalDetailsDTO }
