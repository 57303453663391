import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import Colors from 'constants/Colors'

/**
 *
 * @param handleEdit handleEdit
 * @returns TSX
 */
export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'cms.roles.list.id',
    width: 150,
    sortable: false
  },
  {
    field: 'name',
    headerName: 'cms.roles.list.role',
    width: 300,
    sortable: false
  },
  {
    field: 'createdDate',
    headerName: 'cms.roles.list.createdDate',
    width: 280,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const createdDate = params.row.createdDate as Date

      return (
        <Typography fontSize={16} color={Colors.light.greys.GREY_500}>
          {dayjs(createdDate).format('DD/MM/YYYY')}
        </Typography>
      )
    }
  }
]
