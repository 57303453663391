import { CnpjValidation, CpfValidation } from 'common/validators/cpfCnpj'

/**
 *
 */
export type DocumentType = 'CPF' | 'CNPJ' | 'invalid'

/**
 *
 * @param document Document string
 * @returns DocumentType
 */
export const getDocumentType = (document: string): DocumentType => {
  return CpfValidation(document)
    ? 'CPF'
    : CnpjValidation(document)
    ? 'CNPJ'
    : 'invalid'
}

/**
 * @param value CPF
 * @returns CPF Masked
 */
export const cpfMasked = (value: string) =>
  value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')

/**
 * @param value CNPJ
 * @returns CNPJ Masked
 */
export const cnpjMasked = (value: string) =>
  value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')

/**
 *
 * @param document Document string
 * @returns Formatted document
 */
export const conditionalFormat = (document: string): string => {
  const formatter: Record<DocumentType, (document: string) => string> = {
    CNPJ: cnpjMasked,
    CPF: cpfMasked,
    invalid: (a: string) => a
  }

  return formatter[getDocumentType(document)](document)
}

/**
 * @param value CPF
 * @returns CPF Masked
 */
export const dynamicCpfMasked = (value: string) => {
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  return value
}

/**
 * @param value CNPJ
 * @returns CPF Masked
 */
export const dynamicCnpjMasked = (value: string) => {
  value = value.replace(/\D/g, '')
  value = value.replace(/^(\d{2})(\d)/, '$1.$2')
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
  value = value.replace(/\.(\d{3})(\d)/, '.$1/$2')
  value = value.replace(/(\d{4})(\d)/, '$1-$2')
  return value
}

/**
 *
 * @param document Document string
 * @returns Document without mask
 */
export const maskDocument = (document: string): string => {
  const formatter: Record<DocumentType, (document: string) => string> = {
    CNPJ: dynamicCnpjMasked,
    CPF: dynamicCpfMasked,
    invalid: (a: string) => a
  }

  const type = document?.replace(/\D/g, '').length <= 11 ? 'CPF' : 'CNPJ'

  return formatter[type](document)
}
