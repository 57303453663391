import { BannerService } from '../service/Banner.service'
import BannerDTO from '@volvo-apps/shared/models/BannerDTO'
import BannerConfigDTO from '@volvo-apps/shared/models/BannerConfigDTO'

/**
 * @returns BannerDTO[]
 */
export function getAllBanner() {
  const service = new BannerService()
  return service.getAll().then(({ data }) => data)
}

/**
 *
 * @param bannerId Banner Id
 * @returns Promise
 */
export function getImageByBannerConfigId(bannerId: BannerDTO['id']) {
  const service = new BannerService()

  return service.getImageByBannerConfigId(bannerId).then(({ data }) => data)
}

/**
 * Get Banners Images and Transition Time
 *
 * @returns Promise with banners and transition time
 */
export function getBannersWithConfig() {
  return getAllBanner().then(async (banners) => {
    const bannersActive = banners.filter((item) => item.active)

    if (!bannersActive.length) {
      return {
        banners: [],
        transitionTime: 0
      }
    }

    const promises = bannersActive.map(async (banner) => {
      const bannersWithImages = await getImageByBannerConfigId(banner.id)
      return {
        banners: bannersWithImages,
        transitionTime: banner.transitionTime
      }
    })

    const allBanners = await Promise.all(promises).then((allResponses) => {
      const banners = allResponses.map((item) => item.banners)
      return {
        banners: banners.flat(),
        transitionTime: allResponses[0].transitionTime ?? 0.5
      }
    })

    return allBanners
  })
}

/**
 * @param bannerConfig Banners
 * @returns BannerConfigDTO[]
 */
export async function createBanners(bannerConfig: BannerConfigDTO) {
  const service = new BannerService()
  const response = await service.createBanners(bannerConfig)
  return new BannerConfigDTO().fromJSON(response.data)
}

/**
 * @param bannerConfig Banners
 * @returns BannerConfigDTO[]
 */
export async function updateBanners(bannerConfig: BannerConfigDTO) {
  const service = new BannerService()
  const response = await service.updateBanners(bannerConfig)
  return new BannerConfigDTO().fromJSON(response.data)
}

/**
 * @param id banner id
 * @returns BannerConfigDTO[]
 */
export async function getUnique(id: number) {
  const service = new BannerService()
  const response = await service.getUnique(id)

  return new BannerConfigDTO().fromJSON(response.data)
}

/**
 * @returns BannerDTO[]
 */
export function getAllCmsBanners() {
  const service = new BannerService()
  return service
    .getAllCmsBanners()
    .then(({ data }) =>
      data.map((item) => new BannerConfigDTO().fromJSON(item))
    )
}
