import React, { ReactNode } from 'react'

import { Typography, TypographyProps, Box } from '@mui/material'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

type TitleProps = TypographyProps

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Title(props: TitleProps) {
  return (
    <Typography color={Colors.light.interactActive} fontSize={16} {...props} />
  )
}
/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Subtitle(props: TitleProps) {
  return (
    <Typography color={Colors.light.greys.GREY_700} fontSize={12} {...props} />
  )
}

/**
 *
 */
export type TitleWithIconProps = {
  /**
   *
   */
  label: TitleProps['children']
  /**
   *
   */
  icon: ReactNode
  /**
   *
   */
  color?: string
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function TitleWithIcon(props: TitleWithIconProps) {
  const { color, icon, label } = props
  const elementColor = color ?? Colors.light.greys.GREY_600

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      color={elementColor}
      gap={0.5}
    >
      <FlexBox width={24} alignItems="center">
        {icon}
      </FlexBox>
      <Title fontSize={12} color={elementColor}>
        {label}
      </Title>
    </Box>
  )
}

export default Title
export { Title, Subtitle, TitleWithIcon }
