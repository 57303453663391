import { useForm } from 'react-hook-form'

import { VehicleData, schema } from './schema'

type VehicleProps = {
  defaultValues?: Partial<VehicleData>
}

/**
 *
 * @param props - VehicleProps
 * @returns useForm methods
 */
export const useVehicleForm = (props: VehicleProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<VehicleData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
