import React, { PropsWithChildren } from 'react'

import { Box, BoxProps } from '@mui/material'

type FlexBoxProps = Omit<BoxProps, 'display'>

/**
 * @param props FlexBox Props
 * @returns TSX
 */
const FlexBox = React.forwardRef<unknown, PropsWithChildren<FlexBoxProps>>(
  (props, ref) => {
    const { children, ...rest } = props
    return (
      <Box ref={ref} display="flex" {...rest}>
        {children}
      </Box>
    )
  }
)

FlexBox.displayName = 'FlexBox'

export default FlexBox
export { FlexBox }
