import { generateVerificationCode } from '@volvo-apps/shared/core/actions/User'

import React, { useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Form, Input } from 'components'
import { PlainButton } from 'components/PlainButton'

import useReCaptcha from 'hooks/useReCaptcha'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { validationSchema } from '../helpers'
import { FormInfo, PasswordRetrievalStepEnum } from '../types'
import HeaderBackComponent from './HeaderBackComponent'
import VerificationCodeArea from './VerificationCodeArea'

type VerifyEmailAndCodeProps = {
  goToStep: (step: PasswordRetrievalStepEnum) => void
  persistFormInfo: (formInfo: FormInfo) => void
}

/**
 *
 * @param props Component props
 * @param props.goToStep sends user to a specific step
 * @returns TSX
 */
function VerifyEmailAndCode(props: VerifyEmailAndCodeProps) {
  const { goToStep, persistFormInfo } = props

  const { t } = useLanguage()
  const [verificationCodeSent, setVerificationCodeSent] = useState(false)

  const methods = useForm<FormInfo>({
    mode: 'onChange',
    resolver: validationSchema,
    defaultValues: {
      email: ''
    }
  })

  const { reCaptchaValue, ReCaptcha } = useReCaptcha()

  const sendVerificationCodeMutation = useMutation(generateVerificationCode, {
    onSuccess: () => setVerificationCodeSent(true),
    onError: handleErrorFeedback
  })

  const handleSendVerificationCode = React.useCallback(async () => {
    sendVerificationCodeMutation.mutate({ email: methods.getValues().email })
  }, [methods, sendVerificationCodeMutation])

  const onSuccessVerificationCode = React.useCallback(() => {
    persistFormInfo(methods.getValues())
    goToStep(PasswordRetrievalStepEnum.NEW_PASSWORD)
  }, [goToStep, methods, persistFormInfo])

  useEffect(() => {
    methods.reset()
  }, [methods])

  return (
    <HeaderBackComponent>
      <Form
        methods={methods}
        onSubmit={handleSendVerificationCode}
        formProps={{ width: '100%' }}
      >
        <Typography component="h1" variant="h4" align="center">
          {t('passwordRetrieval.alterPassword')}
        </Typography>
        <Typography component="p" variant="body1" align="center" mt="1em">
          {t('passwordRetrieval.description')}
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Input
            margin="normal"
            fullWidth
            name="email"
            label={t('passwordRetrieval.email')}
            autoComplete="email"
            autoFocus
          />
          {methods.formState.isValid && !verificationCodeSent && ReCaptcha}
          {verificationCodeSent && (
            <Input
              margin="normal"
              fullWidth
              name="verificationCode"
              label={t('passwordRetrieval.insertCode')}
              maxLength={8}
            />
          )}
          {verificationCodeSent ? (
            <VerificationCodeArea
              onSuccessVerificationCode={onSuccessVerificationCode}
              formMethods={methods}
              handleResendVerificationCode={handleSendVerificationCode}
            />
          ) : (
            <PlainButton
              type="submit"
              disabled={!methods.formState.isValid || !reCaptchaValue}
              loading={sendVerificationCodeMutation.isLoading}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('passwordRetrieval.sendCode')}
            </PlainButton>
          )}
        </Box>
      </Form>
    </HeaderBackComponent>
  )
}

export default VerifyEmailAndCode
export { VerifyEmailAndCode }
