import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import SimulationCdcDTO from './SimulationCdcDTO'
import SimulationFinameDTO from './SimulationFinameDTO'
import _omit from 'lodash/omit'

/**
 *  UserSimulationDTO
 */
class UserSimulationDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number, null)
  id?: number | null = undefined

  @jsonName('financialProduct')
  @jsonProperty(String, Number, null, undefined)
  financialProduct?: string | number

  @jsonName('financialProductName')
  @jsonProperty(String)
  financialProductName!: string

  @jsonName('productLine')
  @jsonProperty(String, Number, null, undefined)
  productLine?: string | number

  @jsonName('vehicleId')
  @jsonProperty(Number)
  vehicleId!: number

  @jsonName('vehicleQtd')
  @jsonProperty(Number)
  vehicleQtd!: number

  @jsonName('newVehicle')
  @jsonProperty(Boolean)
  newVehicle!: boolean

  @jsonName('gracePeriodMonths')
  @jsonProperty(Number)
  gracePeriodMonths!: number

  @jsonName('termMonths')
  @jsonProperty(Number)
  termMonths!: number

  @jsonName('documentType')
  @jsonProperty(String)
  documentType!: string

  @jsonName('document')
  @jsonProperty(String)
  document!: string

  @jsonName('productValue')
  @jsonProperty(Number)
  productValue!: number

  @jsonName('depositValue')
  @jsonProperty(Number)
  depositValue!: number

  @jsonName('isValid')
  @jsonProperty(Boolean)
  isValid?: boolean

  @jsonName('userId')
  @jsonProperty(Number)
  userId?: number

  @jsonName('financialProductId')
  @jsonProperty(Number)
  financialProductId?: number

  @jsonName('productLineId')
  @jsonProperty(Number)
  productLineId?: number

  @jsonName('simulationParameterId')
  @jsonProperty(Number)
  simulationParameterId?: number

  @jsonName('simulationJson')
  @jsonProperty(String)
  simulationJson?: string // SimulationCdcDTO or SimulationFinameDTO

  @jsonProperty(SimulationFinameDTO, null, undefined)
  simulationFiname?: SimulationFinameDTO

  @jsonProperty(SimulationCdcDTO, null, undefined)
  simulationCdc?: SimulationCdcDTO

  @jsonName('maxParcelaFiname')
  @jsonProperty(Number)
  maxParcelaFiname?: number

  @jsonName('createdDate')
  @jsonProperty(String)
  createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  modifiedDate?: string

  @jsonName('active')
  @jsonProperty(Boolean)
  active?: boolean

  @jsonName('vehicleName')
  @jsonProperty(String)
  vehicleName?: string

  /**
   *
   * @param props Serializable
   * @returns JSON
   */
  withoutId(props: Partial<typeof this>) {
    return this.fromJSON(_omit(props, 'id'))
  }
}

export default UserSimulationDTO
