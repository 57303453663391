import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'

/**
 * PermissionDTO
 */
export class PermissionDTO extends Serializable {
  @jsonProperty(Number)
  id!: number

  @jsonProperty(Date)
  createdDate!: Date

  @jsonProperty(Date)
  modifiedDate!: Date

  @jsonProperty(Boolean)
  active!: boolean

  @jsonProperty(String)
  name!: string

  @jsonProperty(String)
  description!: string
}

export default PermissionDTO
