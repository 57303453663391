import React from 'react'

import { Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'

import { Form, Input } from 'components'
import { FlexBox } from 'components/FlexBox'
import { TransitionTimeSlider } from 'components/Form/TransitionTimeSlider'
import { UploadImageField } from 'components/Form/UploadImageField'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { BannerData } from './schema'

type BannerFormProps = {
  onSubmit: (formData: BannerData) => void
  isLoading: boolean
  methods: UseFormReturn<BannerData, unknown>
  submitButtonLabel?: string | null
  onRemoveBanner?: (id?: number) => void
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function BannerForm(props: BannerFormProps) {
  const { onSubmit, isLoading, methods, submitButtonLabel, onRemoveBanner } =
    props

  const { t } = useLanguage()

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: {
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <FlexBox
        sx={(t) => ({
          maxWidth: t.breakpoints.values.sm,
          flexDirection: 'column'
        })}
      >
        <Input
          margin="normal"
          fullWidth
          label={t('cms.banners.form.name')}
          name="name"
          autoFocus
        />

        <Typography my={3} fontSize={20} color={Colors.light.greys.GREY_600}>
          {t('cms.banners.form.transitionTime')}
        </Typography>

        <TransitionTimeSlider name="transitionTime" />

        <Typography my={3} fontSize={20} color={Colors.light.greys.GREY_600}>
          {t('cms.banners.form.photo')}
        </Typography>

        <UploadImageField
          name="banners"
          description={t('cms.banners.form.uploadDescription')}
          onRemove={onRemoveBanner}
          width={{
            max: 800,
            min: 800
          }}
          height={{
            max: 200,
            min: 200
          }}
        />
      </FlexBox>

      <PlainButton
        type="submit"
        fullWidth
        variant="contained"
        disabled={!methods.formState.isValid || !methods.formState.isDirty}
        loading={isLoading}
        sx={(t) => ({
          maxWidth: t.breakpoints.values.sm,
          mt: 3,
          mb: 2
        })}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default BannerForm
export { BannerForm }
