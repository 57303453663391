import { getAllLeadNotifications } from '@volvo-apps/shared/core/actions/Leads'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all lead Notifcations
 *
 * @returns leadNotifications, isLoading, refetch
 */
export const useGetLeadNotifications = () => {
  const {
    data: leadNotifications,
    isLoading,
    refetch
  } = useQuery('leadNotifications', {
    queryFn: getAllLeadNotifications,
    onError: handleErrorFeedback
  })

  return { leadNotifications, isLoading, refetch }
}
