import { useForm } from 'react-hook-form'

import { RoleData, schema } from './schema'

type RoleProps = {
  defaultValues?: Partial<RoleData>
}

/**
 *
 * @param props - RoleProps
 * @returns useForm methods
 */
export const useRoleForm = (props: RoleProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<RoleData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
