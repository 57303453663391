import LoginDTO from '@volvo-apps/shared/models/LoginDTO'

import React, { useState } from 'react'

import { Box, IconButton, InputAdornment, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Form, Input, Link } from 'components'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'

import useReCaptcha from 'hooks/useReCaptcha'

import { handleLoginError } from 'common/utils/error'
import { useAuth } from 'context/Auth'
import { useLanguage } from 'context/Language'

import { validationSchema } from './helpers'

type FormData = Pick<LoginDTO, 'email' | 'password'>

/**
 * @returns JSX
 */
function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const { t } = useLanguage()
  const methods = useForm<FormData>({
    criteriaMode: 'all',
    resolver: validationSchema,
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const { login } = useAuth()

  const { isLoading, mutate } = useMutation(login, {
    onError: (err: AxiosError) => {
      handleLoginError(err, methods)
    }
  })

  const { reCaptchaValue, ReCaptcha } = useReCaptcha()

  const isFormValid = methods.formState.isValid && methods.formState.isDirty

  const isSubmitValid = !isLoading && isFormValid

  return (
    <Form
      methods={methods}
      onSubmit={mutate}
      formProps={{ style: { width: '100%' } }}
    >
      <Typography
        component="p"
        variant="body1"
        align="center"
        mt="1em"
        fontSize={16}
      >
        {t('login.welcomeTo')}
      </Typography>
      <Typography component="h1" variant="h4" align="center">
        {t('general.volvoBank')}
      </Typography>
      <Typography component="p" variant="body1" align="center" mt="1em">
        {t('login.loginWithYourAccount')}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Input
          margin="normal"
          fullWidth
          name="email"
          label={t('general.email')}
          autoComplete="username"
          autoFocus
        />
        <Input
          margin="normal"
          fullWidth
          name="password"
          label={t('general.password')}
          type={passwordVisible ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setPasswordVisible((s) => !s)}>
                  {passwordVisible ? (
                    <Icon family="mui" name="Visibility" />
                  ) : (
                    <Icon family="mui" name="VisibilityOff" />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {isFormValid && ReCaptcha}
        <PlainButton
          type="submit"
          loading={isLoading}
          disabled={!isSubmitValid || !reCaptchaValue}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {t('general.signIn')}
        </PlainButton>
        <Box display="flex" justifyContent="flex-end" flexDirection="row">
          <Link to="/login/passwordretrieval" variant="body2">
            {t('login.forgotPassword')}
          </Link>
        </Box>
      </Box>
    </Form>
  )
}

export default Login
export { Login }
