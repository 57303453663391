import _deburr from 'lodash/deburr'
import LinkedDocumentsDTO from '../../models/LinkedDocumentsDTO'

const props: (keyof LinkedDocumentsDTO)[] = [
  'nameOrCompanyName',
  'userName',
  'email',
  'document',
  'insertedDate'
]

const match = (value: string, input: string) => {
  return _deburr(value).match(new RegExp(input, 'ig'))
}

/**
 *
 * @param claim LinkedDocumentsDTO
 * @param input Input string
 * @returns Boolean
 */
export const handleLinkedDocumentFilter = (
  claim: LinkedDocumentsDTO,
  input: string
) => {
  if (input === '') return true

  let isValid = false
  for (const key of props) {
    const value = claim[key]

    if (value === undefined) continue

    if (match(value as string, input)) {
      isValid = true
      break
    }
  }

  return isValid
}
