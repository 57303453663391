import React from 'react'

import { TextField, TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'

import { inputValueToCurrency } from 'common/utils/money'
import { getOnlyNumbers } from 'common/utils/string'

import ErrorMessage from '../ErrorMessage/ErrorMessage'

/**
 *
 */
export type InputNumberProps = TextFieldProps & {
  name: string
  decimalSeparator?: string
  thousandSeparator?: string
  decimal?: boolean
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function InputNumber(props: InputNumberProps) {
  const {
    decimalSeparator = ',',
    thousandSeparator = '.',
    name,
    helperText,
    decimal,
    ...rest
  } = props

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const { error } = fieldState

        const mergedProps = {
          ...rest,
          ...field
        }

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          decimal
            ? field.onChange(
                inputValueToCurrency(
                  e.target.value,
                  decimalSeparator,
                  thousandSeparator
                )
              )
            : field.onChange(getOnlyNumbers(e.target.value))
        }

        return (
          <TextField
            {...mergedProps}
            error={!!error}
            onChange={onChange}
            FormHelperTextProps={{
              sx: {
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5
              }
            }}
            helperText={
              error?.message ? <ErrorMessage error={error} /> : helperText
            }
          />
        )
      }}
    />
  )
}

export default InputNumber
export { InputNumber }
