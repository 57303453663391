import React from 'react'

import { Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { Datepicker } from 'components'
import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { formatDate } from 'common/utils/date'
import { useLanguage } from 'context/Language'

import { FormValues } from './List'

type FiltersProps = {
  onFilter: ({
    startDate,
    endDate
  }: {
    startDate: string
    endDate: string
  }) => void
}

/**
 * Filters
 *
 * @param props props
 * @returns TSX
 */
function Filters(props: FiltersProps) {
  const { onFilter } = props

  const { t } = useLanguage()

  const methods = useFormContext<FormValues>()

  const startDate = methods.watch('startDate')
  const endDate = methods.watch('endDate')

  React.useEffect(() => {
    if (startDate && endDate) {
      onFilter({
        startDate: formatDate(startDate),
        endDate: formatDate(endDate)
      })
    }
  }, [startDate, endDate, onFilter])

  return (
    <FlexBox width="60%" alignItems="center" gap={2} my={2}>
      <Typography
        fontSize={16}
        color={Colors.light.text}
        minWidth="fit-content"
      >
        {t('cms.leads.management.chooseDate')}
      </Typography>
      <Datepicker
        margin="normal"
        fullWidth
        label={t('cms.leads.management.filters.startDate')}
        name="startDate"
      />
      <Typography fontSize={16} color={Colors.light.text}>
        {t('cms.leads.management.to')}
      </Typography>
      <Datepicker
        margin="normal"
        fullWidth
        label={t('cms.leads.management.filters.endDate')}
        name="endDate"
      />
    </FlexBox>
  )
}

export default Filters
export { Filters }
