import VehicleDTO from '@volvo-apps/shared/models/VehicleDTO'

import React from 'react'

import { Carousel } from 'components/Carousel'
import { FlexBox } from 'components/FlexBox'

import Vehicle from './Vehicle'

type VehiclesCardProps = {
  items: VehicleDTO[]
  onChangeVehicle: (id: number) => void
}

/**
 *
 * @param props Vehicle Card Props
 * @returns VehicleCard TSX
 */
function VehicleList(props: VehiclesCardProps) {
  const { items, onChangeVehicle } = props

  const itemsMemo = React.useMemo(() => {
    return items?.map((item) => (
      <Vehicle key={item.id} onChangeVehicle={onChangeVehicle} vehicle={item} />
    ))
  }, [items, onChangeVehicle])

  return (
    <FlexBox sx={{ mt: 2, mb: 1 }}>
      <Carousel
        items={itemsMemo}
        arrowSize={48}
        disableDotsControls
        responsive={{
          0: { items: 3 }
        }}
        paddingLeft={8}
        paddingRight={8}
      />
    </FlexBox>
  )
}

export default React.memo(VehicleList)
