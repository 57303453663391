import ContractDTO from '@volvo-apps/shared/models/ContractDTO'

import React from 'react'

import { useMediaQuery, BoxProps, Grid, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { ProgressCard } from 'components/ProgressCard'

import Colors from 'constants/Colors'

import { conditionalFormat } from 'common/utils/documents'
import { useLanguage } from 'context/Language'

type ContractCardProps = {
  contract: ContractDTO
  boxProps?: BoxProps
  detailsLabel?: {
    to: string
    state: unknown
  }
  showMoreDetails?: boolean
  titleSuffix?: React.ReactNode
  titlePrefix?: React.ReactNode
  titleSx?: BoxProps['sx']
}

/**
 *
 * @param props ContractCardProps
 * @returns TSX
 */
function ContractCard(props: ContractCardProps) {
  const {
    contract,
    boxProps,
    detailsLabel,
    showMoreDetails,
    titleSuffix,
    titlePrefix,
    titleSx
  } = props

  const { t } = useLanguage()

  const mediaQuery = useMediaQuery('(min-width:1960px)')

  const sm = mediaQuery ? 4 : 6

  const makeField = React.useCallback(
    (label: string, value: string | number) => {
      return (
        <FlexBox width="100%">
          <Typography
            color={Colors.light.greys.GREY_500}
            fontSize={12}
            mr={0.5}
          >
            {label}
          </Typography>
          <Typography color={Colors.light.greys.GREY_600} fontSize={12}>
            {value}
          </Typography>
        </FlexBox>
      )
    },
    []
  )

  const SubTitle = React.useMemo(() => {
    if (!showMoreDetails) {
      return (
        <FlexBox flexDirection="column" gap={0.25} pt={0.5}>
          {makeField(t('financial.contracts.number'), contract.numeroContrato)}
          {makeField(
            t('financial.contracts.document'),
            conditionalFormat(contract.cpfCnpj)
          )}
          {/* {makeField(t('financial.contracts.vehicle'), 'Lorem ipusm')}
          {makeField(t('financial.contracts.chassi'), '0000000000')} */}
        </FlexBox>
      )
    }

    return (
      <Grid container spacing={0.25} mt={0.5}>
        <Grid item sm={sm}>
          {makeField(t('financial.contracts.number'), contract.numeroContrato)}
        </Grid>
        <Grid item sm={sm}>
          {makeField(
            t('financial.contracts.document'),
            conditionalFormat(contract.cpfCnpj)
          )}
        </Grid>
        {/* <Grid item sm={sm} >
          {makeField(t('financial.contracts.vehicle'), 'Lorem ipsum')}
        </Grid>
        <Grid item sm={sm} >
          {makeField(t('financial.contracts.chassi'), '00000000000')}
        </Grid> */}
        {/* <Grid item sm={sm} >
          {makeField(t('financial.contracts.date'), contract.vencimento)}
        </Grid> */}
        <Grid item sm={sm}>
          {makeField(t('financial.contracts.name'), contract.nome)}
        </Grid>
        <Grid item sm={sm}>
          {makeField(
            t('financial.contracts.financingType'),
            contract.tipoDeProduto
          )}
        </Grid>
      </Grid>
    )
  }, [
    showMoreDetails,
    sm,
    makeField,
    t,
    contract.numeroContrato,
    contract.cpfCnpj,
    contract.nome,
    contract.tipoDeProduto
  ])

  return (
    <ProgressCard
      boxProps={boxProps}
      title={contract.tipoDeProduto}
      subTitle={SubTitle}
      detailsLabel={detailsLabel}
      titleSuffix={titleSuffix}
      titlePrefix={titlePrefix}
      titleSx={titleSx}
      linearProgress={{
        currentLabel: t('dashboard.contracts.nMonths', [
          contract.numeroParcela
        ]),
        maxLabel: contract.prazo,
        current: Number(contract.numeroParcela),
        max: Number(contract.prazo)
      }}
    />
  )
}

export default ContractCard
export { ContractCard }
