import { createFinancialProduct } from '@volvo-apps/shared/core/actions/FinancialProduct'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCreateFinancialProductParams = {
  onSuccess?: () => void
}

/**
 * Hook to create a financial product
 *
 * @param params params
 * @returns mutate, financialProduct, isLoading
 */
export const useCreateFinancialProduct = (
  params: useCreateFinancialProductParams = {}
) => {
  const { onSuccess } = params
  const {
    data: financialProduct,
    isLoading,
    mutate
  } = useMutation('createFinancialProduct', {
    mutationFn: createFinancialProduct,
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, financialProduct, isLoading }
}
