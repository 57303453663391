import { updateFinancialProduct } from '@volvo-apps/shared/core/actions/FinancialProduct'
import FinancialProductDTO from '@volvo-apps/shared/models/FinancialProductDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateFinancialProductsParams = {
  onSuccess?: () => void
}

/**
 * Hook to update one financial product
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useUpdateFinancialProducts = (
  params: useUpdateFinancialProductsParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data, mutateAsync } = useMutation(
    'updateFinancialProduct',
    {
      mutationFn: ({
        financialProduct
      }: {
        financialProduct: FinancialProductDTO
      }) => updateFinancialProduct(financialProduct),
      onSuccess,
      onError: handleErrorFeedback
    }
  )

  return { mutate, data, isLoading, mutateAsync }
}
