import { createTerm } from '@volvo-apps/shared/core/actions/Term'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCreateTermParams = {
  onSuccess?: () => void
}

/**
 * Hook to create a term
 *
 * @param params params
 * @returns mutate, term, isLoading
 */
export const useCreateTerm = (params: useCreateTermParams = {}) => {
  const { onSuccess } = params
  const {
    data: term,
    isLoading,
    mutate
  } = useMutation('createTerm', {
    mutationFn: createTerm,
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, term, isLoading }
}
