import { useGetHasInsuranceContract } from '@volvo-apps/shared/core/hooks/insurance'
import { useHasPartsAndService } from '@volvo-apps/shared/core/hooks/partsAndServices'
import { useGetRentalContracts } from '@volvo-apps/shared/core/hooks/sales'
import { useHasAutbankContract } from '@volvo-apps/shared/core/hooks/user'

import React, { createContext, PropsWithChildren, useContext } from 'react'

import useGetClientHasConsortium from 'hooks/useGetClientHasConsortium'

import { useAuth } from './Auth'

type IDashboardActionsContext = {
  hasConsortium: boolean | null
  hasInsurance: boolean | null
  hasAutbank: boolean | null
  hasRentalTruck: boolean | null
  hasPartsAndServices: boolean | null
}

const initialState: IDashboardActionsContext = {
  hasConsortium: null,
  hasInsurance: null,
  hasAutbank: null,
  hasRentalTruck: null,
  hasPartsAndServices: null
}

export const dashboardActionsContext =
  createContext<IDashboardActionsContext>(initialState)
const { Provider } = dashboardActionsContext

/**
 *
 * @param props Component Props
 * @returns JSX
 */
export const DashboardActionsProvider = (props: PropsWithChildren) => {
  const { children } = props
  const { hasLogin } = useAuth()

  const { data: hasConsortium = false } = useGetClientHasConsortium({
    enabled: hasLogin,
    retry: 0,
    cacheTime: 0
  })

  const { hasInsurance = false } = useGetHasInsuranceContract({
    enabled: hasLogin,
    retry: 0,
    cacheTime: 0
  })

  const { data: hasAutbank = false } = useHasAutbankContract({
    enabled: hasLogin,
    retry: 0,
    cacheTime: 0
  })

  const { data: rentalContracts = [] } = useGetRentalContracts({
    enabled: hasLogin,
    retry: 0,
    cacheTime: 0
  })

  const { data: hasPartsAndServices = false } = useHasPartsAndService({
    enabled: false,
    retry: 0,
    cacheTime: 0
  })

  const providerProps = {
    hasConsortium,
    hasInsurance,
    hasAutbank,
    hasRentalTruck: rentalContracts.length > 0,
    hasPartsAndServices
  }

  return <Provider value={providerProps}>{children}</Provider>
}

/**
 * @returns Context
 */
export const useDashboardActions = () => useContext(dashboardActionsContext)
