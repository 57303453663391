import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type UserReportsData = {
  /**
   *
   */
  beginDateStr: Date
  /**
   *
   */
  endDateStr: Date
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    beginDateStr: Yup.date().nullable().typeError('validation.invalid.date'),
    endDateStr: Yup.date()
      .nullable()
      .typeError('validation.invalid.date')
      .test(
        'endDateIsValid',
        'validation.invalid.endDate',
        (
          value?: Date | null,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          context: {
            /**
             *
             */
            parent: UserReportsData
          }
        ) =>
          context.parent?.beginDateStr instanceof Date && !!value
            ? value.setHours(0, 0, 0, 0) >=
              context.parent?.beginDateStr?.setHours(0, 0, 0, 0)
            : false
      )
  })
)
