import { createNps } from '@volvo-apps/shared/core/actions/Nps'

import { useMutation } from 'react-query'
import { HookQueryParams } from '../../../common/utils/types'
import NpsDTO from '../../../models/NpsDTO'

type useCreateNpsParams = HookQueryParams<NpsDTO>

/**
 * Hook to create a nps
 *
 * @param params params
 * @returns mutate, nps, isLoading
 */
export const useCreateNps = (params: useCreateNpsParams) => {
  const { onSuccess, onError } = params
  const {
    data: nps,
    isLoading,
    mutate
  } = useMutation('createNps', {
    mutationFn: createNps,
    onSuccess,
    onError
  })

  return { mutate, nps, isLoading }
}
