import { getImageByVehicleId } from '@volvo-apps/shared/core/actions/Vehicle'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type VehicleImageParams = {
  id: number
}

/**
 * @param params VehicleImageParams
 * @returns Data
 */
export const useVehicleImage = (params: VehicleImageParams) => {
  const { id } = params
  const { isLoading, data } = useQuery(['vehicleImage', id], {
    queryFn: () => getImageByVehicleId(id),
    onError: handleErrorFeedback
  })

  return {
    isLoading,
    data
  }
}
