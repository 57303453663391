import React from 'react'

import { Box, BoxProps, Breadcrumbs } from '@mui/material'
import { useLocation } from 'react-router'

import { Link, LinkProps } from 'components/Project'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

/**
 *
 */
export type NavBarProps = {
  /**
   *
   */
  routes: {
    /**
     *
     */
    to: string
    label: LinkProps['children']
    persistState?: boolean
    showId?: boolean
  }[]

  /**
   *
   */
  px?: BoxProps['px']
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function NavBar(props: NavBarProps) {
  const { routes, px: pxProps } = props
  const { pathname, state } = useLocation()
  const { t } = useLanguage()

  return (
    <Box px={pxProps || '3.75em'} py={3}>
      <Breadcrumbs>
        {routes.map((route) => {
          const id = pathname.match(/\d+/g)?.[0]
          const pathnameWithId = pathname.replace(/\d+/g, ':id')
          const isCurrentRoute = route.to.startsWith(pathnameWithId)
          const color = isCurrentRoute
            ? Colors.light.text
            : Colors.light.interactActive
          const pointerEvents = isCurrentRoute ? 'none' : 'auto'
          const label = route.showId
            ? `${t(String(route.label))} ${id}`
            : t(String(route.label))

          return (
            <Link
              key={route.to}
              to={route.to === '' ? '#' : route.to}
              state={route.persistState ? state : undefined}
              color={color}
              fontWeight={isCurrentRoute ? 'bold' : 'normal'}
              style={{ pointerEvents }}
            >
              {label}
            </Link>
          )
        })}
      </Breadcrumbs>
    </Box>
  )
}

export default NavBar
export { NavBar }
