import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type FaqQuestionData = {
  /**
   *
   */
  categoryId: number
  /**
   *
   */
  title: string
  /**
   *
   */
  description: string
  /**
   *
   */
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    categoryId: Yup.number().required('validation.required.general'),
    title: Yup.string().required('validation.required.general'),
    description: Yup.string().required('validation.required.general')
  })
)
