import { useForm } from 'react-hook-form'

import { SvgIconsType } from 'components/Icon'

import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type Option = {
  /**
   *
   */
  value: number
  /**
   *
   */
  color: string
  /**
   *
   */
  text: string
  /**
   *
   */
  icon: SvgIconsType
}

/**
 *
 */
export type FormSchema = {
  /**
   *
   */
  option?: Option
  /**
   *
   */
  reason: string
}

const schema = makeResolver((Yup) =>
  Yup.object({
    option: Yup.object({
      value: Yup.number().required('validation.required.general'),
      color: Yup.string().required('validation.required.general'),
      text: Yup.string().required('validation.required.general'),
      icon: Yup.string().required('validation.required.general')
    }).required('validation.required.general'),
    reason: Yup.string().when('option', {
      is: (option: Option) => option.value < 5,
      then: Yup.string().required('').min(140, '')
    })
  })
)

/**
 * @returns TSX
 */
const useRateModalForm = () => {
  const methods = useForm<FormSchema>({
    defaultValues: {
      reason: ''
    },
    mode: 'onChange',
    resolver: schema
  })

  return methods
}

export { useRateModalForm }
