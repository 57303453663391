import { useMutation } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { checkDocumentAndUpdateProfile } from '../../actions/User'
import UserDTO from '../../../models/UserDTO'

type useCheckAndUpdateProfileParams = HookQueryParams<string | undefined>

/**
 *
 * @param params params
 * @returns profileEmail islLoading checkDocumentAndUpdateProfileMutation checkDocumentAndUpdateProfileAsync
 */
export const useCheckAndUpdateProfile = (
  params: useCheckAndUpdateProfileParams = {}
) => {
  const { onSuccess, onError } = params
  const {
    data: profileEmail,
    isLoading,
    mutate: checkDocumentAndUpdateProfileMutation,
    mutateAsync: checkDocumentAndUpdateProfileAsync
  } = useMutation('checkDocumentAndUpdateProfile', {
    mutationFn: ({
      params
    }: {
      params: { newDocument: string; newUser: UserDTO }
    }) => checkDocumentAndUpdateProfile(params),
    onSuccess,
    onError
  })

  return {
    profileEmail,
    isLoading,
    checkDocumentAndUpdateProfileMutation,
    checkDocumentAndUpdateProfileAsync
  }
}
