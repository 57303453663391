/**
 * @param value - string
 * @param decimalSeparator - string
 * @param thousandSeparator - string
 * @returns number | undefined
 */
export const decimalStrValueToNumber = (
  value?: string,
  decimalSeparator = ',',
  thousandSeparator = '.'
) => {
  if (!value) return undefined
  return parseFloat(
    value.replace(thousandSeparator, '').replace(decimalSeparator, '.')
  )
}
