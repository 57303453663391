import { handleClaimFilter } from '@volvo-apps/shared/common/utils/filterClaims'
import { useGetInsuranceClaims } from '@volvo-apps/shared/core/hooks/insurance'
import InsuranceClaimDTO from '@volvo-apps/shared/models/InsuranceClaimDTO'

import React from 'react'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import { GenericList } from 'components/List/GenericList'

import useSearchBar from 'hooks/useSearchBar'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { ClaimsCard } from './ClaimsCard'

/**
 * @returns TSX
 */
function List() {
  const { t } = useLanguage()
  const { searchBar, searchTerm } = useSearchBar({
    style: {
      margin: '0 0 16px 0'
    }
  })

  const {
    insuranceClaims = [],
    isLoading,
    isFetching
  } = useGetInsuranceClaims({
    onError: handleErrorFeedback
  })

  const makeChildrenComponent = React.useCallback((item: InsuranceClaimDTO) => {
    return <ClaimsCard claim={item} />
  }, [])

  const items = React.useMemo(() => {
    return (
      insuranceClaims?.filter((item) => handleClaimFilter(item, searchTerm)) ??
      []
    )
  }, [insuranceClaims, searchTerm])

  return (
    <ContentContainer title={t('insurancePolicies.claims.title').toUpperCase()}>
      {insuranceClaims.length > 0 && searchBar}
      <GenericList
        listItemStyle={{ paddingRight: 0, paddingLeft: 0 }}
        hideButton
        loading={isLoading || isFetching}
        items={items}
        makeChildrenComponent={makeChildrenComponent}
        disableNoInfoDisclaimer={!!searchTerm}
      />
    </ContentContainer>
  )
}

export default List
export { List }
