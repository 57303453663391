import { getCepInfo } from '@volvo-apps/shared/core/actions/User'
import AddressDTO from '@volvo-apps/shared/models/AddressDTO'

import { useEffect } from 'react'

import { useFormContext, UseFormReturn } from 'react-hook-form'
import { useQuery } from 'react-query'

import { useLanguage } from 'context/Language'

/**
 *
 * @param fieldMap Field mapping for result filling
 * @param zipCode Current ZipCode
 * @param methodsProp UseFormMethods
 * @returns Data
 */
export const useCEPField = (
  fieldMap: Record<keyof AddressDTO | string, string>,
  zipCode?: string,
  methodsProp?: UseFormReturn
) => {
  const methodsInternal = useFormContext()
  const methods = methodsProp ?? methodsInternal

  const { t } = useLanguage()

  const {
    data: cepInfo,
    refetch,
    isLoading
  } = useQuery('cep_' + zipCode, {
    enabled: zipCode?.replace(/\D/gi, '').length === 8,
    queryFn: () => getCepInfo(zipCode as string),
    onSuccess: (cepInfo) => {
      if (!cepInfo) {
        methods.setError('zipCode', {
          type: 'zipCode_validation',
          message: t('validation.invalid.zipCode') as string
        })
      }
    }
  })

  useEffect(() => {
    if (!cepInfo) {
      return
    }

    const info = cepInfo

    Object.entries(info).forEach(([key, value]) => {
      const fmk = fieldMap[key as keyof AddressDTO]

      fmk && value && methods.setValue(fmk, value)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cepInfo])

  return {
    cepInfo,
    refetch,
    isLoading
  }
}
