import React, { useEffect } from 'react'

import GoogleReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha'

type ReCaptchaProps = Omit<ReCAPTCHAProps, 'sitekey'>

/**
 *
 * @param props Component Props
 * @returns TSX
 */
const ReCaptcha = React.memo((props: ReCaptchaProps) => {
  const { onChange, style } = props

  useEffect(() => {
    return () => {
      onChange?.(null)
    }
  }, [onChange])

  return (
    <GoogleReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? 'sitekey'}
      onChange={onChange}
      style={style}
    />
  )
})

ReCaptcha.displayName = 'ReCaptcha'

export default ReCaptcha
export { ReCaptcha }
