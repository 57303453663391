import { verifyEmail } from '@volvo-apps/shared/core/actions/User'

import React from 'react'

import GenericConfirmation from '..'

import ReactMarkdown from 'react-markdown'

import { useLanguage } from 'context/Language'

const params = ['userdocument', 'VerificationCode']
/**
 * A component that renders a data confirmation.
 *
 * @returns The component JSX.
 */
function ConfirmEmail(): JSX.Element {
  const { t } = useLanguage()
  async function check(cbParams: Record<string, unknown>): Promise<boolean> {
    const doc = Number(cbParams[params[0]])
    const code = String(cbParams[params[1]])

    return await verifyEmail(doc, code)
  }

  return (
    <GenericConfirmation
      title={t('general.confirmEmailTitle')}
      paramNames={params}
      checkFunction={check}
    >
      <p>
        <ReactMarkdown>{t('general.confirmEmail')}</ReactMarkdown>
      </p>
    </GenericConfirmation>
  )
}

export default ConfirmEmail
