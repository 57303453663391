import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as PushNotificationsRoutesEnum } from 'routes/cms/routesEnums'

import { useGetCmsNotification } from 'hooks/notifications/useGetCmsNotification'
import { useUpdateCmsNotification } from 'hooks/notifications/useUpdateCmsNotification'

import Colors from 'constants/Colors'

import { formatDate } from 'common/utils/date'
import {
  handleSendToAll,
  optionAllId,
  optionAllString
} from 'common/utils/pushNotifications'
import { useLanguage } from 'context/Language'

import PushNotificationsForm from '../Form/PushNotificationsForm'
import { PushNotificationsData } from '../Form/schema'
import { usePushNotificationsForm } from '../Form/usePushNotificationsForm'

/**
 * Push Notifications Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { methods, getOnSubmitDTOs } = usePushNotificationsForm({
    defaultValues: {
      title: '',
      body: '',
      interval: '',
      dayWeek: '',
      hourDay: '12:00',
      beginDate: new Date(),
      endDate: new Date(),
      sendToAll: false
    }
  })

  const { data: notificationObj, isLoading: getLoading } =
    useGetCmsNotification({
      notificationId: Number(id),
      onSuccess: ({ notification, campaign }) => {
        const financialProduct = handleSendToAll(campaign, 'financialProduct')
          ? optionAllId
          : Number(campaign.financialProductId)
        const productLine = handleSendToAll(campaign, 'productLine')
          ? optionAllId
          : Number(campaign.productLineId)
        const documentType = handleSendToAll(campaign, 'documentType')
          ? optionAllString
          : campaign.documentType

        methods.reset({
          title: notification.title,
          body: notification.body,
          beginDate: new Date(notification.beginDate),
          endDate: new Date(notification.endDate),
          interval: notification.interval,
          dayWeek: notification.dayWeek,
          hourDay: formatDate(new Date(notification.hourDay), 'HH:mm'),
          financialProduct,
          documentType,
          productLine,
          sendToAll: campaign.sendToAll
        })

        methods.trigger()
      }
    })

  const { mutate, isLoading } = useUpdateCmsNotification({
    onSuccess: () => {
      toast.success(t('cms.pushNotifications.update.success'))
      navigate(PushNotificationsRoutesEnum.PUSH_NOTIFICATIONS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: PushNotificationsData) => {
      const { campaign, notification } = getOnSubmitDTOs(
        formData,
        notificationObj?.notification
      )
      campaign.id = notificationObj?.notification.campaignId ?? 0
      campaign.financialProduct = Number.isNaN(
        Number(campaign.financialProduct)
      )
        ? 0
        : Number(campaign.financialProduct)

      campaign.productLine = Number.isNaN(Number(campaign.productLine))
        ? 0
        : Number(campaign.productLine)

      mutate({
        campaign,
        notification
      })
    },
    [getOnSubmitDTOs, mutate, notificationObj?.notification]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.pushNotifications.update.title')}
      </Typography>
      <PushNotificationsForm
        isLoading={isLoading || getLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.pushNotifications.update.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
