import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { ParametersRoutesEnum as ProductLineRoutesEnum } from 'routes/cms/routesEnums'

import { useCreateProductLine } from 'hooks/productLine/useCreateProductLine'

import Colors from 'constants/Colors'

import { currencyStringToNumber } from 'common/utils/money'
import { useLanguage } from 'context/Language'

import { ProductLineForm } from '../Form/ProductLineForm'
import { ProductLineData } from '../Form/schema'
import { useProductLineForm } from '../Form/useProductLineForm'

/**
 * Product Line Create
 *
 * @returns TSX
 */
function Create() {
  const { methods } = useProductLineForm()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { mutate, isLoading } = useCreateProductLine({
    onSuccess: () => {
      toast.success(t('cms.productLines.create.success'))
      navigate(ProductLineRoutesEnum.PRODUCT_LINES)
    }
  })

  const onSubmit = React.useCallback(
    (formData: ProductLineData) => {
      const { name, order, minimumValue } = formData
      mutate({
        name,
        order,
        minimumValue: currencyStringToNumber(minimumValue)
      })
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.productLines.create.title')}
      </Typography>
      <ProductLineForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.productLines.create.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
