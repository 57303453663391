import React from 'react'

import { Typography } from '@mui/material'
import { Navigate } from 'react-router-dom'

import { ButtonLink, Link } from 'components'
import { FlexBox } from 'components/FlexBox'
import { Version } from 'components/Version/Version'

import { AppRoutesEnum } from 'routes/app.routes'

import Colors from 'constants/Colors'

import { useAuth } from 'context/Auth'
import { useLanguage } from 'context/Language'

/**
 * @returns JSX
 */
function Home() {
  const { hasLogin } = useAuth()
  const { t } = useLanguage()

  return hasLogin ? (
    <Navigate to="/dashboard" />
  ) : (
    <>
      <Typography component="h1" variant="h4">
        {t('general.volvoBank')}
      </Typography>
      <Typography component="p" variant="body1" align="center" mt="1em">
        {t('home.descriptionText')}
      </Typography>
      <ButtonLink to="/login">{t('home.alreadyRegistered')}</ButtonLink>
      <Typography variant="body1" mt={2}>
        {t('home.noAccount')}{' '}
        <Link
          to="/register"
          underline="always"
          color={Colors.light.interactActive}
        >
          <b>{t('home.register')}</b>
        </Link>
      </Typography>
      <FlexBox
        position="absolute"
        bottom={0}
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="body1" mt={2}>
          {t('consortiumResources.linkDescription')}
        </Typography>
        <Link
          to={AppRoutesEnum.CONSORTIUM_RESOURCES}
          underline="always"
          color={Colors.light.interactActive}
          mb={2}
        >
          {t('consortiumResources.link')}
        </Link>
        <Version />
      </FlexBox>
    </>
  )
}

export default Home
export { Home }
