import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import Colors from 'constants/Colors'

/**
 *
 * @param handleEdit handleEdit
 * @returns TSX
 */
export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'cms.surveys.nps.list.columns.id',
    width: 100,
    sortable: false
  },
  {
    field: 'beginDate',
    headerName: 'cms.surveys.nps.list.columns.startDate',
    width: 225,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const beginDate = params.row.beginDate as Date

      return (
        <Typography fontSize={16} color={Colors.light.greys.GREY_500}>
          {dayjs(beginDate).format('DD/MM/YYYY')}
        </Typography>
      )
    }
  },
  {
    field: 'endDate',
    headerName: 'cms.surveys.nps.list.columns.endDate',
    width: 225,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const endDate = params.row.endDate as Date

      return (
        <Typography fontSize={16} color={Colors.light.greys.GREY_500}>
          {dayjs(endDate).format('DD/MM/YYYY')}
        </Typography>
      )
    }
  }
]
