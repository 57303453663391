import React from 'react'

import { InputAdornment, TextField } from '@mui/material'

import { Icon } from 'components/Icon'

import { useLanguage } from 'context/Language'

type UseSearchBarParams = {
  style?: React.CSSProperties
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onlyNumbers?: boolean
  maskFunction?: (value: string) => string
  maxLength?: number
}

/**
 * @param params Component Props
 * @returns TSX
 */
const useSearchBar = (params: UseSearchBarParams = {}) => {
  const { style, onlyNumbers, maskFunction, maxLength } = params
  const [searchTerm, setSearchTerm] = React.useState('')
  const { t } = useLanguage()

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value

      if (onlyNumbers) {
        value = value.replace(/\D/g, '')
      }
      if (maskFunction) {
        value = maskFunction(value)
      }

      setSearchTerm(value)
      params.onChange?.(e)
    },
    [maskFunction, onlyNumbers, params]
  )

  const clearSearchTerm = React.useCallback(() => {
    setSearchTerm('')
  }, [])

  const searchBar = React.useMemo(
    () => (
      <TextField
        value={searchTerm}
        onChange={onChange}
        fullWidth
        margin="dense"
        placeholder={t('general.search') as string}
        size="small"
        style={style}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon family="mui" name="Search"></Icon>
            </InputAdornment>
          ),
          inputProps: {
            maxLength
          }
        }}
      />
    ),
    [searchTerm, onChange, t, style, maxLength]
  )

  return {
    searchTerm,
    searchBar,
    clearSearchTerm
  }
}

export default useSearchBar
export { useSearchBar }
