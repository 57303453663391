import ProductLineDTO from '@volvo-apps/shared/models/ProductLineDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { ParametersRoutesEnum as ProductLineRoutesEnum } from 'routes/cms/routesEnums'

import { useGetProductLine } from 'hooks/productLine/useGetProductLine'
import { useUpdateProductLine } from 'hooks/productLine/useUpdateProductLine'

import Colors from 'constants/Colors'

import { toCurrency, currencyStringToNumber } from 'common/utils/money'
import { useLanguage } from 'context/Language'

import ProductLineForm from '../Form/ProductLineForm'
import { ProductLineData } from '../Form/schema'
import { useProductLineForm } from '../Form/useProductLineForm'

/**
 * Product Line Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { methods } = useProductLineForm({
    defaultValues: { name: '', order: 0, minimumValue: '' }
  })

  const { productLine, isLoading: getLoading } = useGetProductLine({
    id: Number(id),
    onSuccess: (productLine) => {
      methods.reset({
        name: productLine.name,
        order: productLine.order,
        minimumValue: toCurrency(productLine.minimumValue).withoutSymbol
      })
    }
  })

  const { mutate, isLoading } = useUpdateProductLine({
    onSuccess: () => {
      toast.success(t('cms.productLines.update.success'))
      navigate(ProductLineRoutesEnum.PRODUCT_LINES)
    }
  })

  const onSubmit = React.useCallback(
    (formData: ProductLineData) => {
      const newProductLine = new ProductLineDTO().fromJSON({
        ...productLine,
        name: formData.name,
        order: formData.order,
        minimumValue: currencyStringToNumber(formData.minimumValue),
        createdDate: undefined
      })
      mutate({ productLine: newProductLine })
    },
    [productLine, mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.productLines.update.title')}
      </Typography>
      <ProductLineForm
        isLoading={isLoading || getLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.productLines.update.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
