import {
  getImageByVehicleId,
  getUnique
} from '@volvo-apps/shared/core/actions/Vehicle'
import VehicleDTO from '@volvo-apps/shared/models/VehicleDTO'
import VehicleImagesDTO from '@volvo-apps/shared/models/VehicleImagesDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetVehicleAndImagesParams = {
  onSuccess?: (response: {
    vehicle: VehicleDTO
    vehicleImages: VehicleImagesDTO[]
  }) => void
  id?: number
}

/**
 * Hook to get vehicle
 *
 * @param params params
 * @returns Vehicle, isLoading, refetch
 */
export const useGetVehicleAndImages = (
  params: useGetVehicleAndImagesParams
) => {
  const { id, onSuccess } = params

  const getVehicleAndImages = async (vehicleId: number) => {
    const [vehicle, vehicleImages] = await Promise.all([
      getUnique(vehicleId),
      getImageByVehicleId(vehicleId)
    ])
    return { vehicle, vehicleImages }
  }

  const {
    data: vehicleAndImages,
    isLoading,
    refetch
  } = useQuery('getUniqueVehicle', {
    enabled: !!id,
    queryFn: () => getVehicleAndImages(id || 0),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { vehicleAndImages, isLoading, refetch }
}
