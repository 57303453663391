import { useForm } from 'react-hook-form'

import { UsersAccessData, schema } from './schema'

type UsersAccessProps = {
  defaultValues?: UsersAccessData
}

/**
 *
 * @param props - UsersAccessProps
 * @returns useForm methods
 */
export const useUsersAccessForm = (props: UsersAccessProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<UsersAccessData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
