// --------------------------------------------------------
// GENERAL
// --------------------------------------------------------
import React from 'react'

import { useAuth } from 'context'
import { BrowserRouter, Route, Routes as RRoutes } from 'react-router-dom'

// --------------------------------------------------------

// --------------------------------------------------------
// CONTEXT
// --------------------------------------------------------

// --------------------------------------------------------
// ROUTES
// --------------------------------------------------------
import { CustomRoute } from 'components'
import { NotFound } from 'components/NotFound'
import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import appRoutes from './app.routes'
import cmsRoutes from './cms'
import { cmsLoginRoute } from './cms/cms.routes'
import financialRoutes from './financial.routes'
import InsurancePoliciesRoutes from './insurancePolicies.routes'
import profileRoutes from './profile.routes'
import simulationRoutes from './simulation.routes'
import validationRoutes from './validation.routes'

const userRoutes: CustomRouteProps[] = [
  ...validationRoutes,
  ...appRoutes,
  ...profileRoutes,
  ...simulationRoutes,
  ...financialRoutes,
  ...InsurancePoliciesRoutes
]

const adminRoutes: CustomRouteProps[] = [...cmsRoutes]

/**
 * The main app routes
 *
 * @returns React.FC
 */
const Routes: React.FC = () => {
  const { isCms } = useAuth()
  const routes = [...userRoutes, cmsLoginRoute, ...(isCms ? adminRoutes : [])]

  return (
    <BrowserRouter>
      <RRoutes>
        {routes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<CustomRoute {...route} />}
            />
          )
        })}
        <Route path="*" element={<NotFound />} />
      </RRoutes>
    </BrowserRouter>
  )
}

export default Routes
