import React from 'react'

import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'

import { inputValueToCurrency } from 'common/utils/money'

import ErrorMessage from '../ErrorMessage/ErrorMessage'

/**
 *
 */
export type InputCurrencyProps = TextFieldProps & {
  /**
   *
   */
  name: string
  /**
   *
   */
  decimalSeparator?: string
  /**
   *
   */
  thousandSeparator?: string
  /**
   *
   */
  maxLength?: number

  testId?: string
}

/**
 * @returns props
 * @param props props
 */
function InputCurrency(props: InputCurrencyProps) {
  const {
    decimalSeparator = ',',
    thousandSeparator = '.',
    name,
    helperText,
    maxLength
  } = props

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const { error } = fieldState

        const { testId, ...inputProps } = props

        const mergedProps = {
          ...inputProps,
          ...field
        }

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const inputValue = e.target.value

          field.onChange(
            inputValueToCurrency(
              inputValue,
              decimalSeparator,
              thousandSeparator
            )
          )
        }

        return (
          <TextField
            {...mergedProps}
            error={!!error}
            onChange={onChange}
            inputProps={{ maxLength, 'data-testid': testId }}
            FormHelperTextProps={{
              sx: {
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5
              }
            }}
            helperText={
              error?.message ? <ErrorMessage error={error} /> : helperText
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
          />
        )
      }}
    />
  )
}

export default InputCurrency
export { InputCurrency }
