import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * UserCmsAccessDTO
 */
class UserCmsAccessDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('document')
  @jsonProperty(String)
  public document!: string

  @jsonName('resendEmailTime')
  @jsonProperty(Date)
  public resendEmailTime!: Date

  @jsonName('resetPasswordTime')
  @jsonProperty(Date)
  public resetPasswordTime!: Date

  @jsonName('name')
  @jsonProperty(String)
  public name!: string

  @jsonName('email')
  @jsonProperty(String)
  public email!: string

  @jsonName('role')
  @jsonProperty(String)
  public role!: string

  @jsonName('canAccessCms')
  @jsonProperty(Boolean)
  public canAccessCms!: boolean

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: Date

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: Date

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean
}

export default UserCmsAccessDTO
