import { blobToURL, download } from '@volvo-apps/web/src/common/utils/download'
import { SalesService } from '../service/Sales.service'
import RentalDetailsDTO from '../../models/RentalDetailsDTO'

/**
 * @param document The document
 * @returns html string
 */
export async function getVehiclesData(document: string) {
  const service = new SalesService()

  const response = await service.getVehiclesData(document)

  return response.data.Response
}

/**
 * @param document The document
 * @returns html string
 */
export async function getInvoicesData(document: string) {
  const service = new SalesService()

  const response = await service.getInvoicesData(document)

  return response.data.Response
}

/**
 *
 * @param formData formData
 */
export async function importFileAsync(formData: FormData) {
  const service = new SalesService()
  const response = await service.importFile(formData)
  return response.data
}

/**
 *
 */
export async function getImportRentalContractsHistory() {
  const service = new SalesService()
  const response = await service.getImportRentalContractsHistory()
  return response.data
}

/**
 * @param id The id of import rental contract
 * @returns Delete import rental contract
 */
export async function deleteImportRentalContract(id: number) {
  const service = new SalesService()
  const response = await service.deleteImportRentalContract(id)

  return response
}

/**
 * Download ticket PDF
 *
 * @param documentoCobranca documentoCobranca
 * @param tipo tipo
 * @param formaPagamento formaPagamento
 * @returns void
 */
export async function downloadBillPDF(
  documentoCobranca: string,
  tipo: string,
  formaPagamento: string
) {
  const service = new SalesService()
  const title = formaPagamento === '2' ? 'boleto' : 'fatura'

  return service
    .getBoletoByInvoice(documentoCobranca, tipo, formaPagamento)
    .then(({ data }) => {
      download(
        blobToURL(data),
        title + '-' + documentoCobranca + '-' + tipo + '.pdf'
      )
    })
}

/**
 * Get rental contracts
 */
export async function getRentalContracts() {
  const service = new SalesService()
  const response = await service.getRentalContracts()
  return response.data
}

/**
 * Get rental details
 *
 * @param document The user document
 */
export async function getRentalDetails(document: string) {
  const service = new SalesService()

  const response = await service.getRentalDetails(document)
  return new RentalDetailsDTO().fromJSON(response.data)
}
