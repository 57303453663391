import { updateTerm } from '@volvo-apps/shared/core/actions/Term'
import TermDTO from '@volvo-apps/shared/models/TermDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateTermParams = {
  onSuccess?: () => void
}

/**
 * Hook to update one term
 *
 * @param params params
 * @returns mutate, term, isLoading and mutateAsync
 */
export const useUpdateTerm = (params: useUpdateTermParams = {}) => {
  const { onSuccess } = params
  const {
    mutate,
    isLoading,
    data: term,
    mutateAsync
  } = useMutation('updateTerm', {
    mutationFn: ({ term }: { term: TermDTO }) => updateTerm(term),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, term, isLoading, mutateAsync }
}
