import InsuranceClaimDTO from '@volvo-apps/shared/models/InsuranceClaimDTO'

import React from 'react'

import { BoxProps, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { Link } from 'components'
import { FlexBox } from 'components/FlexBox'

import { InsurancePoliciesRoutesEnum } from 'routes/insurancePolicies.routes'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type ClaimsCardProps = {
  claim: InsuranceClaimDTO
  boxProps?: BoxProps
}
/**
 *
 * @param props ClaimsCardProps
 * @returns TSX
 */
function ClaimsCard(props: ClaimsCardProps) {
  const { boxProps, claim } = props

  const { t } = useLanguage()

  const handleDate = React.useCallback((date?: string) => {
    return date && date !== 'Não Informado'
      ? dayjs(date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')
      : date ?? ''
  }, [])

  const isValid = dayjs(claim.dataLiquidacao, 'DD/MM/YYYY HH:mm:ss').isAfter(
    dayjs()
  )

  const makeField = React.useCallback(
    (label: string, value: string | number) => {
      return (
        <FlexBox width="22%">
          <Typography
            color={Colors.light.greys.GREY_500}
            fontSize={12}
            mr={0.5}
          >
            {label}
          </Typography>
          <Typography color={Colors.light.greys.GREY_600} fontSize={12}>
            {value}
          </Typography>
        </FlexBox>
      )
    },
    []
  )

  return (
    <FlexBox
      justifyContent="space-between"
      alignItems="center"
      {...boxProps}
      sx={{
        border: `1px solid ${Colors.light.greys.GREY_200}`,
        boxShadow: `1px 1px 5px ${Colors.light.shadow}`,
        width: '100%',
        p: 2
      }}
    >
      <FlexBox flexDirection="column" gap={2} width={'100%'}>
        <FlexBox justifyContent="space-between">
          <FlexBox alignItems="center" gap={2}>
            <FlexBox
              sx={{
                borderRadius: '50px',
                backgroundColor: isValid
                  ? Colors.light.interactDefault
                  : Colors.light.greys.GREY_200,

                px: 2,
                py: 0.5
              }}
            >
              <Typography
                fontSize={12}
                color={
                  isValid
                    ? Colors.light.background
                    : Colors.light.greys.GREY_600
                }
              >
                {claim.status}
              </Typography>
            </FlexBox>

            <Typography
              color={
                isValid
                  ? Colors.light.interactDefault
                  : Colors.light.greys.GREY_600
              }
              fontSize={16}
              fontWeight="bold"
            >
              {claim.proprietario}
            </Typography>
          </FlexBox>
          <FlexBox>
            <Link
              to={InsurancePoliciesRoutesEnum.CLAIMS_MENU}
              state={{ claim }}
              color={Colors.light.interactActive}
              fontSize={14}
              sx={{ pointerEvents: 'all' }}
              fontWeight="bold"
            >
              {t('general.seeDetails').toUpperCase()}
            </Link>
          </FlexBox>
        </FlexBox>
        <FlexBox flexDirection="column" gap={1}>
          <FlexBox gap={4}>
            {makeField(
              t('insurancePolicies.claims.claimNumber'),
              claim.numeroSinistro
            )}
            {makeField(t('insurancePolicies.claims.policy'), claim.apolice)}
            {makeField(t('insurancePolicies.claims.insurer'), claim.seguradora)}
            {makeField(t('insurancePolicies.claims.element'), claim.item)}
          </FlexBox>
          <FlexBox gap={4}>
            {makeField(
              t('insurancePolicies.claims.claimDate'),
              handleDate(claim.dataSinistro)
            )}
            {makeField(
              t('insurancePolicies.claims.noticeDate'),
              handleDate(claim.dataAviso)
            )}
            {makeField(
              t('insurancePolicies.claims.settlementDate'),
              handleDate(claim.dataLiquidacao)
            )}
            {makeField(
              t('insurancePolicies.claims.claimant'),
              claim.reclamante
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export { ClaimsCardProps }
export default ClaimsCard
export { ClaimsCard }
