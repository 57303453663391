import { useQuery } from 'react-query'

import UserDTO from '../../models/UserDTO'
import { getDocumentsWithContracts } from '../actions/User'
import { HookQueryParams } from '../../common/utils/types'

type useGetDocumentsWithContractsParams = HookQueryParams<UserDTO>

/**
 * @param params params
 * @returns user data
 */
export const useGetDocumentsWithContracts = (
  params: useGetDocumentsWithContractsParams = {}
) => {
  const { onSuccess, onError, suspense } = params
  const { data, isLoading, refetch, isFetching } = useQuery(
    'getDocumentsWithContracts',
    {
      queryFn: getDocumentsWithContracts,
      onSuccess,
      onError,
      suspense
    }
  )

  return { data, isLoading, refetch, isFetching }
}
