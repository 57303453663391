import LoginDTO from '@volvo-apps/shared/models/LoginDTO'
import { GenericService } from './GenericService'
import TokensDTO from '../../models/TokensDTO'

/**
 * Authentication service
 */
export class AuthenticationService extends GenericService<LoginDTO> {
  readonly URI: string = 'gateway/Authentication'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   *
   * @param props Func Props
   * @param props.username Username
   * @param props.password Password
   */
  public async loginApp(
    props: Pick<LoginDTO, 'email' | 'password' | 'deviceType'>
  ) {
    return super.post('LoginApp', props)
  }

  /**
   *
   * @param props Func Props
   * @param props.username Username
   * @param props.password Password
   */
  public async loginMobile(
    props: Pick<LoginDTO, 'email' | 'password' | 'deviceType'>
  ) {
    return super.post('LoginApp', props)
  }

  /**
   *
   * @param props Func Props
   */
  public async refreshToken(
    props: Pick<TokensDTO, 'accessToken' | 'refreshToken'>
  ) {
    return super.post('RefreshToken', props)
  }

  /**
   *
   * @param props Func Props
   * @param props.username Username
   * @param props.password Password
   */
  public async loginCms(props: Pick<LoginDTO, 'email' | 'password'>) {
    return super.post('LoginCms', props)
  }
}
