import { getHoliday } from '@volvo-apps/shared/core/actions/Holiday'
import HolidayDTO from '@volvo-apps/shared/models/HolidayDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetHolidayParams = {
  onSuccess?: (holiday: HolidayDTO) => void
  id?: number
}

/**
 * Hook to get all holiday
 *
 * @param params params
 * @returns holiday, isLoading, refetch
 */
export const useGetHoliday = (params: useGetHolidayParams) => {
  const { id, onSuccess } = params
  const {
    data: holiday,
    isLoading,
    refetch
  } = useQuery('getHoliday', {
    enabled: !!id,
    queryFn: () => getHoliday(id || 0),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { holiday, isLoading, refetch }
}
