import { t } from 'i18next'
import { toast } from 'react-toastify'

import { useGetCmsFinancialProducts } from 'hooks/financialProducts/useGetCmsFinancialProducts'

import { useDeleteFinancialProduct } from '../financialProducts/useDeleteFinancialProduct'
import { useUpdateFinancialProducts } from '../financialProducts/useUpdateFinancialProduct'

/**
 * Hook to use CRUD operations on financial products
 *
 * @returns financialProducts, deleteFinancialProduct, updateFinancialProduct, refetch, isLoading, getLoading, deleteLoading, updateLoading
 */
export const useCrudFinancialProduct = () => {
  const {
    financialProducts,
    refetch,
    isLoading: getLoading
  } = useGetCmsFinancialProducts()
  const { mutate: deleteFinancialProduct, isLoading: deleteLoading } =
    useDeleteFinancialProduct({
      onSuccess: () => {
        refetch()
        toast.success(t('cms.financialProducts.delete.success'))
      }
    })
  const {
    mutate: updateFinancialProduct,
    isLoading: updateLoading,
    mutateAsync: updateAsyncFinancialProduct
  } = useUpdateFinancialProducts({
    onSuccess: refetch
  })

  const isLoading = getLoading || deleteLoading || updateLoading

  return {
    financialProducts,
    deleteFinancialProduct,
    updateFinancialProduct,
    updateAsyncFinancialProduct,
    refetch,
    isLoading,
    getLoading,
    deleteLoading,
    updateLoading
  }
}
