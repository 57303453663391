import { getQuestionsByCategory } from '@volvo-apps/shared/core/actions/FaqQuestion'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 *
 * @param categoryId CategoryId
 * @returns Data
 */
const useQuestionsByCategory = (categoryId: number) => {
  const { data, isLoading } = useQuery(['questionsByCategory', categoryId], {
    queryFn: () => getQuestionsByCategory({ categoryId }),
    onError: handleErrorFeedback
  })

  return {
    data,
    isLoading
  }
}

export default useQuestionsByCategory
