import React from 'react'

import ProjectText from '..'

import Colors from '../../../constants/Colors'

import { PropTypes } from '../index'

/**
 * A component that renders a text title.
 *
 * @param props The component props.
 * @returns The component JSX.
 */
function Title(props: PropTypes): JSX.Element {
  // --------------------------------------------------------
  // REACT RETURN FUNCTION
  // --------------------------------------------------------
  return (
    <ProjectText
      {...props}
      style={{
        fontSize: 32,
        fontWeight: 500,
        marginBottom: 16,
        lineHeight: '125%',
        letterSpacing: 0.15,
        color: Colors.light.interactActive
      }}
    />
  )
  // --------------------------------------------------------
}

export default Title
