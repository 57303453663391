import { SalesVehicleDTO } from '@volvo-apps/shared/models/SalesVehicleDTO'

import React from 'react'

import { BoxProps, Grid, Typography, Box } from '@mui/material'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { toCurrency } from 'common/utils/money'
import { useLanguage } from 'context/Language'

type RentalVehicleCardProps = {
  vehicle: SalesVehicleDTO
  boxProps?: BoxProps
  detailsLabel?: {
    to: string
    state: unknown
  }
  showMoreDetails?: boolean
}

/**
 *
 * @param props RentalVehicleCardProps
 * @returns TSX
 */
function RentalVehicleCard(props: RentalVehicleCardProps) {
  const { vehicle } = props

  const { t } = useLanguage()

  const sm = 3

  const makeField = React.useCallback(
    (label: string, value: string | number) => {
      return (
        <FlexBox width="100%">
          <Typography
            color={Colors.light.greys.GREY_500}
            fontSize={12}
            mr={0.5}
          >
            {label}
          </Typography>
          <Typography color={Colors.light.greys.GREY_600} fontSize={12}>
            {value}
          </Typography>
        </FlexBox>
      )
    },
    []
  )

  return (
    <Box {...props.boxProps}>
      <Grid container spacing={0.25} mt={0.5}>
        <Grid item sm={12}>
          <Typography color={Colors.light.blue} fontSize={20} mb={1}>
            {vehicle.Modelo}
          </Typography>
        </Grid>
        <Grid item sm={sm}>
          {makeField(t('financial.rental.chassis'), vehicle.Chassi)}
        </Grid>
        <Grid item sm={sm}>
          {makeField(
            t('financial.rental.monthlyInstallment'),
            vehicle.parcelamensal
              ? toCurrency(Number(vehicle.parcelamensal)).withSymbol
              : ''
          )}
        </Grid>
        <Grid item sm={sm}>
          {makeField(t('financial.rental.plate'), vehicle.Placa)}
        </Grid>
        <Grid item sm={sm}>
          {makeField(t('financial.rental.model'), vehicle.Modelo)}
        </Grid>
        <Grid item sm={sm}>
          {makeField(t('financial.rental.modelYear'), vehicle.AnoModelo)}
        </Grid>
        <Grid item sm={sm}>
          {makeField(
            t('financial.rental.yearOfManufacture'),
            vehicle.AnoFabricacao
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default RentalVehicleCard
export { RentalVehicleCard }
