import { jsonProperty, Serializable } from 'ts-serializable'

/**
 *
 */
class BaseYearDTO extends Serializable {
  @jsonProperty(Number)
  public id!: number

  @jsonProperty(Number)
  public year!: number

  @jsonProperty(Date)
  public createdDate?: Date

  @jsonProperty(Date)
  public modifiedDate!: Date

  @jsonProperty(Boolean)
  public active!: boolean
}

export default BaseYearDTO
