import TermDTO from '@volvo-apps/shared/models/TermDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'
import { PlainButton } from 'components/PlainButton'

import { RegistersRoutesEnum as TermRoutesEnum } from 'routes/cms/routesEnums'

import { useCrudTerm } from 'hooks/cms/useCrudTerm'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { columns } from './tableColumns'

/**
 * Terms List
 *
 * @returns TSX
 */
function List() {
  const { terms, updateAsyncTerm, getLoading } = useCrudTerm()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const handleToggle = React.useCallback(
    async (term: Record<string, unknown>, checked: boolean) => {
      const newTerm = new TermDTO().fromJSON({
        ...term,
        termText: term.termText as string,
        active: checked
      })

      const updateResponse = await updateAsyncTerm({
        term: newTerm
      })

      toast.success(
        updateResponse.data.active
          ? t('cms.terms.toggle.enabled')
          : t('cms.terms.toggle.disabled')
      )
    },
    [updateAsyncTerm, t]
  )

  const handleCreate = React.useCallback(() => {
    navigate(TermRoutesEnum.TERMS_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.terms.list.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.terms.list.title')}
      </Typography>

      <CrudDataGrid
        columns={columns}
        rows={terms || []}
        loading={getLoading && !terms}
        hideFooter
        handleToggle={handleToggle}
        linkEditProps={{
          to: (id) => TermRoutesEnum.TERMS_UPDATE.replace(':id', id.toString())
        }}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
