import React from 'react'

import { BoxProps, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type NoInfoProps = {
  hideHeader?: boolean
  width?: BoxProps['width']
}

/**
 * @param props  NoInfoProps
 * @returns TSX
 */
const NoInfo = (props: NoInfoProps) => {
  const { hideHeader, width } = props

  const { t } = useLanguage()

  const makeInfo = (phone: string, description: string) => {
    return (
      <FlexBox
        alignItems="flex-start"
        flexDirection="column"
        mt={3}
        border={`1px solid ${Colors.light.greys.GREY_400}`}
        borderRadius={1}
        width="100%"
        p={2}
      >
        <Typography
          fontSize={20}
          color={Colors.light.interactDefault}
          fontWeight="bold"
        >
          {phone}
        </Typography>
        <Typography fontSize={16} color={Colors.light.greys.GREY_700}>
          {description}
        </Typography>
      </FlexBox>
    )
  }

  return (
    <FlexBox
      alignItems="flex-start"
      flexDirection="column"
      width={width || 'fit-content'}
    >
      {!hideHeader && (
        <>
          <Typography
            fontSize={16}
            color={Colors.light.greys.GREY_700}
            fontWeight="bold"
          >
            {t('general.noInfo.title')}
          </Typography>
          <Typography fontSize={16} color={Colors.light.greys.GREY_700}>
            {t('general.noInfo.contactCustomerService')}
          </Typography>
        </>
      )}
      {makeInfo(
        t('general.noInfo.callCenterNumber'),
        t('general.noInfo.callCenter')
      )}
      {makeInfo(
        t('general.noInfo.ombudsmanNumber'),
        t('general.noInfo.ombudsman')
      )}
      {makeInfo(
        t('general.noInfo.hearingImpairedNumber'),
        t('general.noInfo.hearingImpaired')
      )}
    </FlexBox>
  )
}

export default NoInfo
export { NoInfo }
