import HolidayDTO from '@volvo-apps/shared/models/HolidayDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as HolidayRoutesEnum } from 'routes/cms/routesEnums'

import { useGetHoliday } from 'hooks/holidays/useGetHoliday'
import { useUpdateHoliday } from 'hooks/holidays/useUpdateHoliday'

import Colors from 'constants/Colors'

import { setUTCStartOfDay } from 'common/utils/date'
import { useLanguage } from 'context/Language'

import HolidayForm from '../Form/HolidayForm'
import { HolidayData } from '../Form/schema'
import { useHolidayForm } from '../Form/useHolidayForm'

/**
 * Holiday Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { methods } = useHolidayForm({
    defaultValues: { name: '', date: new Date() }
  })

  const { holiday, isLoading: getLoading } = useGetHoliday({
    id: Number(id),
    onSuccess: (holiday) => {
      methods.reset({ name: holiday.name, date: new Date(holiday.date) })
    }
  })

  const { mutate, isLoading } = useUpdateHoliday({
    onSuccess: () => {
      toast.success(t('cms.holidays.update.success'))
      navigate(HolidayRoutesEnum.HOLIDAYS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: HolidayData) => {
      const newHoliday = new HolidayDTO().fromJSON({
        ...holiday,
        name: formData.name,
        date: setUTCStartOfDay(formData.date),
        createdDate: undefined
      })

      mutate({ holiday: newHoliday })
    },
    [holiday, mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.holidays.update.title')}
      </Typography>
      <HolidayForm
        isLoading={isLoading || getLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.holidays.update.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
