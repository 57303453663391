import { getEstados } from '@volvo-apps/shared/core/actions/User'

import { useQuery } from 'react-query'

/**
 * @returns Data
 */
export const useStateField = () => {
  const { data: estados } = useQuery('estados', getEstados)

  return {
    estados
  }
}
