import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type TermData = {
  /**
   *
   */
  text: string
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    text: Yup.string().required('validation.required.general')
  })
)
