import { updateNotification } from '@volvo-apps/shared/core/actions/Leads'
import LeadNotificationDTO from '@volvo-apps/shared/models/LeadNotificationDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateLeadNotificationParams = {
  onSuccess?: () => void
}

/**
 * Hook to update one lead Notifcation
 *
 * @param params params
 * @returns mutate, leadNotification, isLoading and mutateAsync
 */
export const useUpdateLeadNotification = (
  params: useUpdateLeadNotificationParams = {}
) => {
  const { onSuccess } = params
  const {
    mutate,
    isLoading,
    data: leadNotification,
    mutateAsync
  } = useMutation('updateLeadNotification', {
    mutationFn: ({
      leadNotification
    }: {
      leadNotification: LeadNotificationDTO
    }) => updateNotification(leadNotification),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, leadNotification, isLoading, mutateAsync }
}
