import React from 'react'

import { styled } from '@mui/material/styles'
import { DataGrid as MUIDataGrid } from '@mui/x-data-grid'

import Colors from 'constants/Colors'

import { StyledDataGridProps } from './types'

const striped = {
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `2px solid ${Colors.light.interactDefault}`
  },
  '& .MuiDataGrid-cellContent': {
    color: Colors.light.text,
    fontSize: 16
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600
  },
  '& .MuiDataGrid-row': {
    '&.odd': {
      backgroundColor: Colors.light.greys.GREY_100
    },
    '&:hover': {
      backgroundColor: Colors.light.greys.GREY_200
    },
    '&.Mui-selected:hover': {
      backgroundColor: Colors.light.greys.GREY_200
    },
    '&.odd:hover': {
      backgroundColor: Colors.light.greys.GREY_200
    }
  }
}

const checkbox = {
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `2px solid ${Colors.light.interactDefault}`
  },
  '& .MuiDataGrid-cellContent': {
    color: Colors.light.text,
    fontSize: 16
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: Colors.light.greys.GREY_500,
    fontFamily: 'Volvo-Novum-Medium',
    fontSize: 14
  },
  '& .MuiDataGrid-row': {
    '&.Mui-selected:hover': {
      backgroundColor: Colors.light.greys.GREY_200
    }
  }
}

const darkCells = {
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${Colors.light.greys.GREY_200}`
  },
  '& .MuiDataGrid-cellContent': {
    color: Colors.light.text,
    fontSize: 16
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: Colors.light.greys.GREY_600,
    fontFamily: 'Volvo-Novum-Medium',
    fontSize: 14
  },
  '& .MuiDataGrid-row': {
    backgroundColor: '#F2F4F7',
    '&.Mui-selected:hover': {
      backgroundColor: Colors.light.greys.GREY_200
    }
  }
}

export const StyledDataGrid = styled((props: StyledDataGridProps) => (
  <MUIDataGrid {...props} />
))((props: StyledDataGridProps) => ({
  '& .MuiDataGrid-columnSeparator': {
    minHeight: 0,
    height: 0,
    visibility: 'hidden',
    minWidth: 0,
    width: 0
  },
  '& .MuiDataGrid-cellContent': {
    color: Colors.light.greys.GREY_600,
    fontSize: '16px'
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 0
  },
  ...(props.type !== 'checkbox' && {
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: Colors.light.transparent
    }
  }),
  '& .MuiDataGrid-row.Mui-selected:hover': {
    backgroundColor: Colors.light.greys.GREY_100
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: Colors.light.greys.GREY_100
  },
  ...(props.type === 'striped' && striped),
  ...(props.type === 'checkbox' && checkbox),
  ...(props.type === 'darkCells' && darkCells)
}))
