import React from 'react'

import { Typography, Box } from '@mui/material'

import { Carousel } from 'components/Carousel'
import { FlexBox } from 'components/FlexBox'
import { Loader } from 'components/Loader'

import { useGetAllTransitionInterval } from 'hooks/useGetAllTransitionInterval'
import { useVehicleImage } from 'hooks/useVehicleImage'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'
import { useUserSimulation } from 'context/Simulation'

/**
 * @returns TSX
 */
function VehiclePictures() {
  const { simulation } = useUserSimulation()
  const { t } = useLanguage()

  const { isLoading, data } = useVehicleImage({
    id: simulation?.vehicleId ?? 0
  })

  const { data: intervalData } = useGetAllTransitionInterval()

  const transitionTime = intervalData?.length
    ? intervalData[0].transitionTime
    : 0.5

  const handleDragStart = (e: React.DragEvent) => e.preventDefault()

  const items = data?.map((item) => {
    return (
      <FlexBox
        key={item.id}
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          style={{ objectFit: 'contain', width: '50vh', minWidth: 460 }}
          src={item.azureUrl}
          onDragStart={handleDragStart}
        />
      </FlexBox>
    )
  })

  return (
    <Box>
      {isLoading ? (
        <Loader
          boxProps={{
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 200,
            minHeight: 200
          }}
        />
      ) : (
        <Carousel
          items={items}
          autoPlay
          infinite
          slideIntervalInMs={transitionTime}
        />
      )}
      <Typography color={Colors.light.greys.GREY_700}>
        {t('simulation.summary.disclaimer')}
      </Typography>
    </Box>
  )
}

export default VehiclePictures
export { VehiclePictures }
