import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { Form } from 'components'
import { TextEditor } from 'components/Form'
import { PlainButton } from 'components/PlainButton'

import { TermData } from './schema'

type TermFormProps = {
  onSubmit: (formData: TermData) => void
  isLoading: boolean
  methods: UseFormReturn<TermData, unknown>
  submitButtonLabel?: string | null
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function TermForm(props: TermFormProps) {
  const { onSubmit, isLoading, methods, submitButtonLabel } = props

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: {
          maxWidth: 1000,
          width: '100%'
        }
      }}
    >
      <TextEditor name="text" />

      <PlainButton
        type="submit"
        fullWidth
        variant="contained"
        disabled={!methods.formState.isValid || !methods.formState.isDirty}
        loading={isLoading}
        sx={{ mt: 3, mb: 2 }}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default TermForm
export { TermForm }
