import { getDayEnums } from '@volvo-apps/shared/core/actions/Notification'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get day enums
 *
 * @returns days, isLoading, refetch
 */
export const useGetDayEnums = () => {
  const {
    data: days,
    isLoading,
    refetch
  } = useQuery('getDayEnums', {
    queryFn: getDayEnums,
    onError: handleErrorFeedback
  })

  return { days, isLoading, refetch }
}
