import React, { useCallback, useMemo } from 'react'

import { Typography } from '@mui/material'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'
import { FlexBox } from 'components/FlexBox'
import { PlainButton } from 'components/PlainButton'

import { useGetUserDataReportByDoc } from 'hooks/reports/useGetUserDataReportByDoc'
import useSearchBar from 'hooks/useSearchBar'

import Colors from 'constants/Colors'

import { maskDocument } from 'common/utils/documents'
import { useLanguage } from 'context/Language'

import { deletedDocumentsColumns, documentsColumns } from './tablesColumns'
import UserList from './UserList'

/**
 * User Reports UserSearch
 *
 * @returns TSX
 */
function UserSearch() {
  const { t } = useLanguage()
  const { searchBar, searchTerm, clearSearchTerm } = useSearchBar({
    style: { maxWidth: '330px', margin: 0 },
    onlyNumbers: true,
    maskFunction: maskDocument,
    maxLength: 18
  })

  const { mutate, isLoading, userDataReport } = useGetUserDataReportByDoc({
    onSuccess() {
      clearSearchTerm()
    }
  })

  const handleSubmit = useCallback(() => {
    mutate({
      document: searchTerm.replace(/\D/g, '')
    })
  }, [mutate, searchTerm])

  const documentsExcludedList = useMemo(() => {
    return userDataReport?.documentsExcludedList.map((document, index) => ({
      ...document,
      id: index
    }))
  }, [userDataReport?.documentsExcludedList])

  return (
    <CmsChildrenWithHeader>
      <Typography
        my={4}
        fontSize={20}
        fontFamily="Volvo-Novum-Medium"
        color={Colors.light.greys.GREY_600}
      >
        {t('cms.reports.userSearch.title')}
      </Typography>

      <FlexBox
        sx={{ pb: 3 }}
        alignItems="center"
        component="form"
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        {searchBar}
        <PlainButton
          style={{ marginLeft: '32px', height: '40px', minWidth: '162px' }}
          variant="contained"
          type="submit"
          disabled={!searchTerm || isLoading}
          loading={isLoading}
        >
          {t('cms.reports.userSearch.button')}
        </PlainButton>
      </FlexBox>

      {userDataReport && (
        <>
          <UserList userData={userDataReport} />

          <FlexBox flexDirection="column">
            <Typography
              fontSize={16}
              fontFamily="Volvo-Novum-Medium"
              color={Colors.light.greys.GREY_600}
              my={4}
            >
              {t('cms.reports.userSearch.documentsTable.title')}
            </Typography>

            <CrudDataGrid
              rows={userDataReport.documentList}
              getRowId={(row) => row.document}
              columns={documentsColumns}
              hideFooter
              type="striped"
            />
          </FlexBox>

          <FlexBox flexDirection="column">
            <Typography
              fontSize={16}
              fontFamily="Volvo-Novum-Medium"
              color={Colors.light.greys.GREY_600}
              my={4}
            >
              {t('cms.reports.userSearch.deletedDocumentsTable.title')}
            </Typography>

            <CrudDataGrid
              rows={documentsExcludedList || []}
              columns={deletedDocumentsColumns}
              hideFooter
              type="striped"
            />
          </FlexBox>
        </>
      )}
    </CmsChildrenWithHeader>
  )
}

export default UserSearch
export { UserSearch }
