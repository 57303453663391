import { useMutation } from 'react-query'
import { linkDocument } from '../../actions/LinkedDocuments'
import { HookQueryParams } from '../../../common/utils/types'
import UserDocumentDTO from '../../../models/UserDocumentDTO'

type useLinkDocumentParams = HookQueryParams<UserDocumentDTO>

/**
 *
 * @param params params to use
 * @returns linkedDocument, isLoading
 */
export const useLinkDocument = (params: useLinkDocumentParams) => {
  const {
    data: linkedDocument,
    isLoading,
    mutate
  } = useMutation('linkDocument', {
    mutationFn: ({ email, document }: { email: string; document: string }) =>
      linkDocument(email, document),
    ...params
  })

  return { linkedDocument, isLoading, mutate }
}
