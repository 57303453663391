import _deburr from 'lodash/deburr'
import InsuranceClaimDTO from '../../models/InsuranceClaimDTO'

const props: (keyof InsuranceClaimDTO)[] = [
  'numeroSinistro',
  'apolice',
  'seguradora',
  'item',
  'dataSinistro',
  'dataLiquidacao',
  'reclamante',
  'proprietario',
  'status',
  'dataAviso'
]

const match = (value: string, input: string) => {
  return _deburr(value).match(new RegExp(input, 'ig'))
}

/**
 *
 * @param claim InsuranceClaimDTO
 * @param input Input string
 * @returns Boolean
 */
export const handleClaimFilter = (claim: InsuranceClaimDTO, input: string) => {
  let isValid = false

  for (const key of props) {
    const value = claim[key]

    if (value === undefined) continue

    if (match(value as string, input)) {
      isValid = true
      break
    }
  }

  return isValid
}
