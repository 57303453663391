import React from 'react'

import { styled } from '@mui/material/styles'
import MUITab from '@mui/material/Tab'
import MUITabs from '@mui/material/Tabs'
import { Link as RRLink, useLocation } from 'react-router-dom'

import { tabs } from 'routes/cms/cms.routes'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

const TabsStyled = styled(MUITabs)(() => ({
  'a.Mui-selected': {
    color: Colors.light.text,
    fontWeight: 'bold',
    backgroundColor: Colors.light.background
  }
}))

/**
 * CMS Tabs
 *
 * @returns TSX
 */
function Tabs() {
  const pathName = useLocation().pathname
  const [tab, setTab] = React.useState(pathName)
  const { t } = useLanguage()

  React.useEffect(() => {
    const searchedTab = tabs.find((tab) => pathName.includes(tab.path))
    if (searchedTab) setTab(searchedTab.path)
  }, [pathName])

  return (
    <TabsStyled
      orientation="vertical"
      value={tab}
      onChange={(_, newValue) => {
        setTab(newValue)
        window.scrollTo(0, 0)
      }}
      TabIndicatorProps={{ hidden: true }}
      sx={{
        width: 280
      }}
    >
      {tabs.map((tab) => (
        <MUITab
          key={tab.path}
          value={tab.path}
          component={RRLink}
          label={t(tab.label)}
          to={tab.path}
          disabled={tab.disabled}
          color={Colors.light.text}
          sx={{
            backgroundColor: Colors.light.greys.GREY_100,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'flex-start',
            borderBottom: `1px solid ${Colors.light.greys.GREY_300}`,
            fontSize: 16,
            py: 2.5,
            fontWeight: 400
          }}
        />
      ))}
    </TabsStyled>
  )
}

export default Tabs
export { Tabs }
