import TermDTO from '@volvo-apps/shared/models/TermDTO'
import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'

/**
 * Term service
 */
export class TermService extends GenericService<TermDTO> {
  readonly URI: string = 'gateway/Terms'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all terms
   */
  public async getAll() {
    return super.get<AxiosResponse<TermDTO[]>>('GetAll')
  }

  /**
   *
   * @param id The id of the term
   */
  public async getTerm(id: number) {
    return super.get<AxiosResponse<TermDTO>>('Get', { id })
  }

  /**
   * Delete term
   *
   * @param id The id of the term
   */
  public async deleteTerm(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Update term
   *
   * @param updatedTerms The updated term
   */
  public async updateTerm(updatedTerms: TermDTO) {
    return super.put<AxiosResponse<TermDTO>>('Update', {
      ...updatedTerms
    })
  }

  /**
   * Create term
   *
   * @param payload The payload to update the term
   */
  public async createTerm(payload: Pick<TermDTO, 'termText' | 'accepted'>) {
    return super.post<AxiosResponse<TermDTO>>('Add', payload)
  }

  /**
   * Get all cms terms
   */
  public async getAllCms() {
    return super.get<AxiosResponse<TermDTO[]>>('GetAllCms')
  }
}
