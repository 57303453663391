import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import { Menu, UserSearch } from 'pages/cms/Reports'
import { Create } from 'pages/cms/Reports/UserReport/Create'
import { CmsContent } from 'pages/layout/CmsContent'
import { RoleEnum } from 'types/roles'

import { ReportsRoutesEnum } from './routesEnums'

const reportsRoutes: CustomRouteProps[] = [
  {
    path: ReportsRoutesEnum.REPORTS_MENU,
    protected: true,
    layout: CmsContent,
    element: <Menu />
  },
  {
    path: ReportsRoutesEnum.USER_REPORTS_FORM,
    protected: true,
    layout: CmsContent,
    element: <Create />,
    role: RoleEnum.RELATORIOS_USUARIOS
  },
  {
    path: ReportsRoutesEnum.USER_SEARCH,
    protected: true,
    layout: CmsContent,
    element: <UserSearch />,
    role: RoleEnum.RELATORIOS_CONSULTA_USUARIO
  }
]

export default reportsRoutes
