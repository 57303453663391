import { yupResolver } from '@hookform/resolvers/yup'

import YupDefault, { Yup } from 'common/validators'

type SchemaProp = (yup: typeof YupDefault) => Yup.AnyObjectSchema

/**
 *
 * @param schema Schema builder
 * @returns Yup Resolver
 */
export const makeResolver = (schema: SchemaProp) => {
  return yupResolver(schema(YupDefault))
}
