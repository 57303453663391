import { useGetAllPermissions } from '@volvo-apps/shared/core/hooks/role/useGetAllPermissions'
import PermissionDTO from '@volvo-apps/shared/models/PermissionDTO'
import RoleDTO from '@volvo-apps/shared/models/RoleDTO'

import React, { useEffect } from 'react'

import { FormControlLabel, Switch, Typography } from '@mui/material'
import {
  Controller,
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray
} from 'react-hook-form'

import { Form, Input } from 'components'
import { FlexBox } from 'components/FlexBox'
import GenericList from 'components/List/GenericList'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { RoleData } from './schema'
import { PERMISSION_ID, allPermissionsRole } from './utils'

type RoleFormProps = {
  onSubmit: (formData: RoleData) => void
  isLoading: boolean
  methods: UseFormReturn<RoleData, unknown>
  submitButtonLabel?: string | null
  role?: RoleDTO
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function RoleForm(props: RoleFormProps) {
  const { onSubmit, isLoading, methods, submitButtonLabel } = props

  const { t } = useLanguage()

  const { fields } = useFieldArray({
    name: 'permissions',
    control: methods.control,
    keyName: 'arrayId'
  })

  const {
    permissions = [],
    isFetched,
    isLoading: isLoadingPermissions
  } = useGetAllPermissions({
    onError: handleErrorFeedback
  })

  useEffect(() => {
    if (isFetched && permissions.length > 0) {
      let allActive = true
      const permissionsDefaultValues = permissions.map((permission) => {
        const active =
          props.role?.permissions.some(
            (rolePermission) => rolePermission.id === permission.id
          ) ?? false

        if (!active) {
          allActive = false
        }

        return new PermissionDTO().fromJSON({
          ...permission,
          active
        })
      })

      methods.setValue('permissions', [
        new PermissionDTO().fromJSON({
          ...allPermissionsRole,
          active: allActive
        }),
        ...permissionsDefaultValues
      ])
    }
  }, [isFetched, methods, permissions, props.role])

  const makeChildrenComponent = (
    permission: FieldArrayWithId<RoleData, 'permissions', 'arrayId'>,
    index: number
  ) => {
    const isAllPermissionsRole = permission.id === PERMISSION_ID
    const handleToggle = (
      _: React.ChangeEvent<HTMLInputElement>,
      checked: boolean,
      formCallback: (
        _: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
      ) => void
    ) => {
      formCallback(_, checked)
      const clonePermissions = [...methods.getValues().permissions]
      clonePermissions[index].active = checked

      if (isAllPermissionsRole) {
        clonePermissions.forEach((permission) => {
          permission.active = checked
        })
      } else {
        const allPermissionsRoleIndex = clonePermissions.findIndex(
          (permission) => permission.id === PERMISSION_ID
        )
        if (!checked) {
          if (allPermissionsRoleIndex !== -1) {
            clonePermissions[allPermissionsRoleIndex].active = false
          }
        } else {
          const allPermissionsActive = clonePermissions.filter((permission) => {
            if (permission.id !== PERMISSION_ID) {
              return permission.active
            }
            return true
          })
          if (allPermissionsActive.length === clonePermissions.length) {
            clonePermissions[allPermissionsRoleIndex].active = true
          }
        }
      }
      methods.setValue('permissions', [...clonePermissions])
    }

    return (
      <FlexBox
        key={permission.arrayId}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'100%'}
        sx={{ py: 1 }}
        borderBottom={`1px solid ${Colors.light.greys.GREY_300}`}
      >
        <Typography fontWeight={isAllPermissionsRole ? 'bold' : ''}>
          {permission.name}
        </Typography>
        <Controller
          name={`permissions.${index}.active`}
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={
                <Switch
                  checked={value}
                  onChange={(_, checked) => {
                    handleToggle(_, checked, onChange)
                  }}
                />
              }
              label=""
            />
          )}
        />
      </FlexBox>
    )
  }

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '100%'
        })
      }}
    >
      <Input
        margin="normal"
        fullWidth
        label={t('cms.roles.create.name')}
        name="name"
        autoFocus
        sx={{ mb: 4 }}
      />
      <Typography
        color={Colors.light.greys.GREY_500}
        fontSize={14}
        borderBottom={`1px solid ${Colors.light.greys.GREY_300}`}
        sx={{ pb: 1 }}
      >
        {t('cms.roles.create.formName')}
      </Typography>
      <GenericList
        listStyle={{ paddingTop: 0 }}
        listItemStyle={{ padding: 0 }}
        hideButton
        items={fields}
        makeChildrenComponent={makeChildrenComponent}
        disableNoInfoDisclaimer
      />

      <PlainButton
        type="submit"
        variant="contained"
        disabled={!methods.formState.isValid}
        loading={isLoading || isLoadingPermissions}
        sx={{ mt: 3, mb: 2, minWidth: '200px' }}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default RoleForm
export { RoleForm }
