import { makeResolver } from 'common/utils/form'
import { emailValidator } from 'common/validators/email'
import {
  confirmPasswordValidator,
  passwordValidator
} from 'common/validators/password'

export const validationSchema = makeResolver((Yup) =>
  Yup.object({
    email: emailValidator,
    verificationCode: Yup.string()
      .min(8, 'validation.length.verificationCode')
      .max(8, 'validation.length.verificationCode')
      .when('email', {
        is: true,
        then: Yup.string().required('validation.required.verificationCode'),
        otherwise: Yup.string().notRequired()
      })
  })
)

export const newPasswordSchema = makeResolver((Yup) =>
  Yup.object({
    password: passwordValidator,
    confirmPassword: confirmPasswordValidator
  })
)
