import React from 'react'

import { useGetProductLines } from 'hooks/productLine/useGetProductLines'

import { Select } from '../Select'
import { SelectProps } from './types'

type ProductLinesSelectProps = SelectProps & {
  appendOptions?: { label: string; value: string | number }[]
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function ProductLinesSelect(props: ProductLinesSelectProps) {
  const {
    name,
    label,
    margin,
    fullWidth,
    sx,
    labelAsValue,
    appendOptions = [],
    disabled
  } = props
  const { productLines } = useGetProductLines()

  const productLinesOptions = React.useMemo(
    () =>
      productLines?.map((productLine) => ({
        label: productLine.name,
        value: labelAsValue ? productLine.name : productLine.id
      })) ?? [],
    [productLines, labelAsValue]
  )

  const options = [...appendOptions, ...productLinesOptions]

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={options ?? []}
      sx={sx}
      disabled={disabled}
    />
  )
}

export default ProductLinesSelect
export { ProductLinesSelect }
