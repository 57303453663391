import DocumentDTO from '@volvo-apps/shared/models/DocumentDTO'

import { conditionalFormat, getDocumentType } from 'common/utils/documents'
import { Option } from 'types/Option'

/**
 * @param documents Documents
 * @returns DocumentsByType
 */
export const getDocumentsOptionsByType = (
  documents?: DocumentDTO[]
): Record<'valid' | 'invalid', Option<string>[]> => {
  const emptyDocuments = { valid: [], invalid: [] }

  const documentsByType =
    documents?.reduce<Record<'valid' | 'invalid', Option<string>[]>>(
      (acc, documentDTO) => {
        const document = documentDTO.document
        const documentType =
          getDocumentType(document) !== 'invalid' ? 'valid' : 'invalid'

        const option = { label: conditionalFormat(document), value: document }
        return { ...acc, [documentType]: [...acc[documentType], option] }
      },
      emptyDocuments
    ) ?? emptyDocuments

  return documentsByType
}
