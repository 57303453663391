import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { Datepicker, Form, Input } from 'components'
import { FlexBox } from 'components/FlexBox'
import { PlainButton } from 'components/PlainButton'

import { useLanguage } from 'context/Language'

import { NpsData } from './schema'

type NpsFormProps = {
  onSubmit: (formData: NpsData) => void
  isLoading: boolean
  methods: UseFormReturn<NpsData, unknown>
  submitButtonLabel?: string | null
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function NpsForm(props: NpsFormProps) {
  const { onSubmit, isLoading, methods, submitButtonLabel } = props

  const { t } = useLanguage()
  const beginDate = methods.watch('beginDate')

  const disabled = React.useMemo(
    () => !methods.formState.isValid || !methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '100%'
        })
      }}
    >
      <FlexBox gap={2} flexDirection="column">
        <Input
          margin="normal"
          fullWidth
          label={t('cms.surveys.nps.form.title')}
          name="title"
          autoFocus
        />
        <Datepicker
          label={t('cms.surveys.nps.form.startDate')}
          name="beginDate"
          sx={{ width: '100%' }}
          margin="normal"
          onChange={() => {
            methods.trigger('endDate')
          }}
          minDate={new Date()}
        />

        <Datepicker
          label={t('cms.surveys.nps.form.endDate')}
          name="endDate"
          sx={{ width: '100%' }}
          margin="normal"
          minDate={beginDate || new Date()}
        />
      </FlexBox>

      <PlainButton
        type="submit"
        fullWidth
        variant="contained"
        disabled={disabled}
        loading={isLoading}
        sx={{ mt: 3, mb: 2 }}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default NpsForm
export { NpsForm }
