import React from 'react'

import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemTextProps,
  Switch,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Serializable } from 'ts-serializable'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

const StyledListItem = styled(ListItem)(() => ({
  ':hover': {
    backgroundColor: Colors.light.greys.GREY_100
  }
}))

/**
 *
 */
export type CrudListItemType<T> = Pick<
  ListItemTextProps,
  'primary' | 'secondary'
> &
  T

/**
 *
 */
export type CrudListItemProps<T> = {
  /**
   *
   */
  item: CrudListItemType<T>
  /**
   *
   */
  handleEdit?: (item: T) => void
  /**
   *
   */
  handleDelete?: (item: T) => void
  /**
   *
   */
  handleToggle?: (
    item: T,
    checked: boolean
  ) => Promise<void | { hasError: boolean }> | void | { hasError: boolean }

  /**
   *
   */
  toggleKey?: keyof T

  /**
   *
   */
  disableToggle?: boolean
}

/**
 *
 * @param props Component Props
 * @returns TSX
 */
function CrudListItem<T extends ReturnType<Serializable['toJSON']>>(
  props: CrudListItemProps<T>
) {
  const {
    handleDelete,
    handleEdit,
    handleToggle,
    item,
    toggleKey,
    disableToggle
  } = props

  const { primary, secondary } = item

  const { t } = useLanguage()

  const [checked, setChecked] = React.useState<boolean>(
    item[toggleKey as keyof T] as boolean
  )

  const actionsColor = Colors.light.interactActive
  const actionsSize = 14

  const handleToggleChange = async (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const toggleActionResponse = await handleToggle?.(item, checked)
    if (toggleActionResponse?.hasError) {
      return
    }

    setChecked(checked)
  }

  return (
    <React.Fragment>
      <StyledListItem>
        <FlexBox
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          flexBasis="100%"
        >
          <ListItemText
            primary={primary}
            secondary={secondary}
            style={{ display: 'flex', alignItems: 'center' }}
            primaryTypographyProps={{
              sx: { color: Colors.light.text, fontSize: 16, minWidth: 200 }
            }}
            secondaryTypographyProps={{
              sx: { color: Colors.light.greys.GREY_400, fontSize: 16 }
            }}
          />

          <FlexBox alignItems="center">
            {handleDelete && (
              <ListItemButton onClick={() => handleDelete(item)}>
                <Typography color={actionsColor} fontSize={actionsSize}>
                  {t('general.delete')}
                </Typography>
              </ListItemButton>
            )}
            {handleEdit && (
              <ListItemButton onClick={() => handleEdit(item)}>
                <Typography color={actionsColor} fontSize={actionsSize}>
                  {t('general.edit')}
                </Typography>
              </ListItemButton>
            )}
            {handleToggle && (
              <Switch
                sx={{ color: actionsColor }}
                checked={checked}
                onChange={handleToggleChange}
                disabled={disableToggle}
              />
            )}
          </FlexBox>
        </FlexBox>
      </StyledListItem>
      <Divider />
    </React.Fragment>
  )
}

export default CrudListItem
export { CrudListItem }
