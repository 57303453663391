import React, { ReactNode } from 'react'

import {
  Box,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  ListItemTextProps
} from '@mui/material'

import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

/**
 *
 */
export type ListItemProps = Pick<ListItemTextProps, 'primary' | 'secondary'> & {
  /**
   *
   */
  icon?: ReactNode
  hide?: boolean
} & ListItemButtonProps

/**
 *
 */
export type ListProps = {
  /**
   *
   */
  items?: ListItemProps[]
  /**
   *
   */
  noPadding?: boolean
}
/**
 *
 * @param props Component Props
 * @returns JSX
 */
function List(props: ListProps) {
  const { items, noPadding } = props

  const px = noPadding ? 0 : 2

  return (
    <MuiList component="nav">
      {items?.map((item, index) => {
        const { icon, primary, secondary, sx, ...restProps } = item

        if (restProps.hide) {
          return null
        }

        return (
          <ListItem key={index} sx={{ px }}>
            <ListItemButton disableRipple sx={{ ...sx, px }} {...restProps}>
              {icon && (
                <ListItemIcon
                  sx={{
                    mr: 2
                  }}
                >
                  {icon}
                </ListItemIcon>
              )}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection="row"
                flexBasis="100%"
                style={{
                  borderBottom: `1px solid ${Colors.light.greys.GREY_300}`,
                  paddingBottom: 6
                }}
              >
                <ListItemText primary={primary} secondary={secondary} />
                <ListItemIcon>
                  <Icon family="mui" name="ChevronRight" />
                </ListItemIcon>
              </Box>
            </ListItemButton>
          </ListItem>
        )
      })}
    </MuiList>
  )
}

export default List
export { List }
