import { SalesInvoiceDTO } from '@volvo-apps/shared/models/SalesInvoiceDTO'

import React from 'react'

import { Box, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'
import { NoInfo } from 'components/NoInfo'

import Colors from 'constants/Colors'

import { formatUTC, formatDate } from 'common/utils/date'
import { toCurrency } from 'common/utils/money'
import { useLanguage } from 'context/Language'

import DownloadInvoiceButton from './DownloadInvoiceButton'

type RentalInvoiceDetailProps = {
  invoice: SalesInvoiceDTO
}

/**
 *
 * @param props RentalInvoiceDetailProps
 * @returns TSX
 */
function RentalInvoiceAccordionDetail(props: RentalInvoiceDetailProps) {
  const { invoice } = props

  const { t } = useLanguage()
  const amount = toCurrency(Number(invoice.Valor)).withSymbol

  const expiredDateUTC = formatUTC(invoice.DataVencimento)
  const expireValue = formatDate(expiredDateUTC)
  const isExpired = new Date(expiredDateUTC) < new Date()

  const InfoIconColor = isExpired
    ? Colors.light.error
    : Colors.light.interactActive
  const dueDateLabelColor = isExpired
    ? Colors.light.error
    : Colors.light.interactActive
  const dueDateColor = isExpired
    ? Colors.light.error
    : Colors.light.greys.GREY_700

  return !invoice.IsValid ? (
    <NoInfo />
  ) : (
    <FlexBox flexDirection="column" justifyContent="start" width="fit-content">
      <Box mb={1}>
        <Typography
          fontFamily={'Volvo-Novum-Medium'}
          fontSize={16}
          color={Colors.light.interactActive}
        >
          {invoice.Tipo === 'FT'
            ? t('financial.rental.rentalMonthlyTicketTitle')
            : t('financial.rental.rentalAdditionalTicketTitle')}
        </Typography>
      </Box>
      <Typography fontSize={32} color={Colors.light.greys.GREY_700}>
        {amount}
      </Typography>
      <Typography fontSize={14} color={Colors.light.greys.GREY_700} mb={2}>
        {t('financial.rental.ticketValue')}
      </Typography>
      <FlexBox mt={0.5} alignItems="center">
        <Icon
          family="mui"
          name={'InfoOutlined'}
          sx={{ color: InfoIconColor, mr: 0.8 }}
        />
        <Typography fontSize={14} color={dueDateLabelColor}>
          {t('financial.rental.dueDate')}
        </Typography>
        <Typography fontSize={14} color={dueDateColor}>
          &nbsp;{expireValue}
        </Typography>
      </FlexBox>
      <FlexBox mt={0.5} alignItems="center">
        <Icon family="svg" name="hall" sx={{ mr: 0.8 }} />
        <Typography fontSize={14} color={Colors.light.interactActive}>
          {t('financial.rental.paymentType')}
        </Typography>
        <Typography fontSize={14} color={Colors.light.greys.GREY_700}>
          &nbsp;
          {invoice.Tipo === 'FT'
            ? t('financial.rental.rentalMonthlyType')
            : t('financial.rental.rentalAdditionalType')}
        </Typography>
      </FlexBox>
      <DownloadInvoiceButton invoice={invoice} />
    </FlexBox>
  )
}

export default RentalInvoiceAccordionDetail
export { RentalInvoiceAccordionDetail }
