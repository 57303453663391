import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getImportXMLHistory } from '../../actions/ConsortiumClosedResources'
import ConsortiumClosedResourcesHistoryDTO from '../../../models/ConsortiumClosedResourcesHistoryDTO'

type useGetConsortiumClosedResourcesParams = HookQueryParams<
  ConsortiumClosedResourcesHistoryDTO[]
>

/**
 * Hook to get all consortium closed resources
 *
 * @param params params
 * @returns xmlHistory, isLoading, refetch
 */
export const useGetConsortiumClosedResources = (
  params: useGetConsortiumClosedResourcesParams = {}
) => {
  const { onSuccess, onError } = params
  const {
    data: xmlHistory,
    isLoading,
    refetch
  } = useQuery('importXMLHistory', {
    queryFn: getImportXMLHistory,
    onSuccess,
    onError
  })

  return { xmlHistory, isLoading, refetch }
}
