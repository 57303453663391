import RentalContractDTO from '@volvo-apps/shared/models/RentalContractDTO'

import React, { useCallback } from 'react'

import { useMediaQuery, BoxProps, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { FlexBox } from 'components/FlexBox'
import { ProgressCard } from 'components/ProgressCard'

import Colors from 'constants/Colors'

import { conditionalFormat } from 'common/utils/documents'
import { useLanguage } from 'context/Language'

type RentalContractCardProps = {
  rentalContract: RentalContractDTO
  boxProps?: BoxProps
  detailsLabel?: {
    to: string
    state: unknown
  }
  showMoreDetails?: boolean
  disableTitle?: boolean
  fromContractsScreen?: boolean
  removeFinanceType?: boolean
}

/**
 *
 * @param props RentalContractCardProps
 * @returns TSX
 */
function RentalContractCard(props: RentalContractCardProps) {
  const {
    rentalContract,
    boxProps,
    detailsLabel,
    showMoreDetails,
    disableTitle,
    fromContractsScreen,
    removeFinanceType
  } = props

  const { t } = useLanguage()

  const mediaQuery = useMediaQuery('(min-width:1960px)')

  const sm = mediaQuery ? 4 : 6

  const handleDate = useCallback((date?: Date) => {
    return date ? dayjs(date).format('DD/MM/YYYY') : date ?? ''
  }, [])

  const makeField = React.useCallback(
    (label: string, value: string | number) => {
      return (
        <FlexBox width="100%">
          <Typography
            color={Colors.light.greys.GREY_500}
            fontSize={12}
            mr={0.5}
          >
            {label}
          </Typography>
          <Typography color={Colors.light.greys.GREY_600} fontSize={12}>
            {value}
          </Typography>
        </FlexBox>
      )
    },
    []
  )

  const SubTitle = React.useMemo(() => {
    if (!showMoreDetails) {
      return (
        <FlexBox flexDirection="column" gap={0.25} pt={0.5}>
          {makeField(
            t('financial.contracts.number'),
            rentalContract.numeroContrato
          )}
          {makeField(
            t('financial.contracts.document'),
            conditionalFormat(rentalContract.documento)
          )}
        </FlexBox>
      )
    }

    return (
      <Grid container spacing={0.25} mt={0.5}>
        {fromContractsScreen && (
          <Grid item sm={sm}>
            {makeField(
              t('financial.rental.contractNumber'),
              rentalContract.numeroContrato
            )}
          </Grid>
        )}

        <Grid item sm={sm}>
          {makeField(
            t('financial.rental.document'),
            conditionalFormat(rentalContract.documento)
          )}
        </Grid>
        <Grid item sm={sm}>
          {makeField(
            t('financial.rental.contractDate'),
            handleDate(rentalContract.dataContrato)
          )}
        </Grid>
        <Grid item sm={sm}>
          {makeField(t('financial.rental.name'), rentalContract.nomeRazao)}
        </Grid>

        <Grid item sm={sm}>
          {makeField(
            t('financial.rental.validityDate'),
            handleDate(rentalContract.vigenciaContrato)
          )}
        </Grid>

        <Grid item sm={sm}>
          {makeField(
            t('financial.rental.quantity'),
            rentalContract.qtdVeiculos
          )}
        </Grid>

        {!removeFinanceType && (
          <Grid item sm={sm}>
            {makeField(t('financial.contracts.financingType'), 'RENTAL')}
          </Grid>
        )}
      </Grid>
    )
  }, [
    showMoreDetails,
    fromContractsScreen,
    sm,
    makeField,
    t,
    rentalContract.numeroContrato,
    rentalContract.documento,
    rentalContract.dataContrato,
    rentalContract.nomeRazao,
    rentalContract.vigenciaContrato,
    rentalContract.qtdVeiculos,
    handleDate,
    removeFinanceType
  ])

  return (
    <ProgressCard
      boxProps={boxProps}
      title={
        rentalContract.numeroContrato && !disableTitle
          ? fromContractsScreen
            ? 'RENTAL'
            : rentalContract.numeroContrato.toString()
          : ''
      }
      subTitle={SubTitle}
      detailsLabel={detailsLabel}
    />
  )
}

export default RentalContractCard
export { RentalContractCard }
