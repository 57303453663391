import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { ParametersRoutesEnum as FinancialProductRoutesEnum } from 'routes/cms/routesEnums'

import { useCreateFinancialProduct } from 'hooks/financialProducts/useCreateFinancialProduct'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { FinancialProductForm } from '../Form/FinancialProductForm'
import { FinancialProductData } from '../Form/schema'
import { useFinancialProductForm } from '../Form/useFinancialProductForm'

/**
 * Financial Products Create
 *
 * @returns TSX
 */
function Create() {
  const { methods } = useFinancialProductForm()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { mutate, isLoading } = useCreateFinancialProduct({
    onSuccess: () => {
      toast.success(t('cms.financialProducts.create.success'))
      navigate(FinancialProductRoutesEnum.FINANCIAL_PRODUCTS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: FinancialProductData) => {
      mutate(formData.name)
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.financialProducts.create.title')}
      </Typography>
      <FinancialProductForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.financialProducts.create.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
