import React, { ReactNode } from 'react'

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps as MuiSelectProps
} from '@mui/material'
import { Controller } from 'react-hook-form'

import { Option } from 'types/Option'

type SelectProps = Omit<
  MuiSelectProps,
  'margin' | 'variant' | 'defaultValue'
> & {
  helperText?: FormHelperTextProps['children']
  name: string
  options?: Option[]
  formSize?: FormControlProps['size']
  formSx?: FormControlProps['sx']
  testId?: string
} & Pick<FormControlProps, 'margin'>

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Select(props: SelectProps) {
  const {
    label,
    name,
    options,
    margin,
    formSize,
    formSx,
    testId,
    ...restProps
  } = props
  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const { error } = fieldState
        return (
          <FormControl fullWidth margin={margin} size={formSize} sx={formSx}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
              label={label}
              {...restProps}
              {...field}
              error={!!error}
              disabled={!options?.length || restProps.disabled}
              value={field.value ?? ''}
              onChange={(e: SelectChangeEvent, child: ReactNode) => {
                restProps.onChange?.(e, child)
                field.onChange?.(e)
              }}
              inputProps={{
                'data-testid': testId
              }}
            >
              {options?.map(({ value, label }) => {
                const isBool = typeof value === 'boolean'
                const newValue = isBool ? String(value) : value

                return (
                  <MenuItem key={newValue} value={newValue}>
                    {label}
                  </MenuItem>
                )
              })}
            </MuiSelect>

            <FormHelperText error={!!error}>
              {error?.message ?? props.helperText}
            </FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}

export default Select
export { Select }
