import { deleteUserRole } from '@volvo-apps/shared/core/actions/UserRoles'

import { useMutation } from 'react-query'

type useDeleteUserRoleParams = {
  onSuccess?: () => void
  onError?: (error: Error) => void
}

/**
 * Hook to delete one user role
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteUserRole = (params: useDeleteUserRoleParams = {}) => {
  const { onSuccess, onError } = params
  const { mutate, isLoading, data } = useMutation('deleteUserRole', {
    mutationFn: ({ id }: { id: number }) => deleteUserRole(id),
    onSuccess,
    onError
  })

  return { mutate, data, isLoading }
}
