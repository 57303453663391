import DOMPurify from 'dompurify'

/**
 *
 * @param name Name
 * @returns String
 */
export function stringAvatar(name: string) {
  const parts = name.split(' ')
  const [first] = parts.splice(0, 1)
  const [last] = parts.splice(-1)

  return `${first?.[0] ?? ''}${last?.[0] ?? ''}`.toUpperCase()
}

/**
 *
 * @param str String
 * @returns String
 */
export function getOnlyNumbers(str: string) {
  return str.replace(/\D/g, '')
}

/**
 *
 * @param html html string
 * @returns String
 */
export function purify(html: string) {
  return DOMPurify.sanitize(html)
}
