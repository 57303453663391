import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getUsersListWithDocumentCmsById } from '../../actions/User'
import UserWithDocumentDTO from '../../../models/UserWithDocumentDTO'

type UseGetUserListWithDocumentCmsByIdParams =
  HookQueryParams<UserWithDocumentDTO> & {
    id: number
  }

/**
 *
 * @param params params
 * @returns userList, isLoading, refetch
 */
export const useGetUserListWithDocumentCmsById = (
  params: UseGetUserListWithDocumentCmsByIdParams
) => {
  const { id, ...restParams } = params

  const { data: user, ...rest } = useQuery(
    ['GetUserListWithDocumentCmsById', id],
    {
      queryFn: () => getUsersListWithDocumentCmsById(id),
      ...restParams
    }
  )

  return { user, ...rest }
}
