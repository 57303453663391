import React from 'react'

import {
  Divider,
  List,
  ListItem,
  SxProps,
  Theme,
  Typography
} from '@mui/material'
import { Serializable } from 'ts-serializable'

import { Loader } from 'components/Loader'

import Colors from 'constants/Colors'

import CrudListItem, { CrudListItemType } from './CrudListItem'

/**
 *
 */
export type CrudListProps<T> = {
  /**
   *
   */
  items?: CrudListItemType<T>[]
  /**
   *
   */
  handleEdit?: (item: T) => void
  /**
   *
   */
  handleDelete?: (item: T) => void
  /**
   *
   */
  handleToggle?: (
    item: T,
    checked: boolean
  ) => Promise<void | { hasError: boolean }> | void | { hasError: boolean }
  /**
   *
   */
  listStyle?: SxProps<Theme>

  /**
   *
   */
  title?: string | null

  /**
   *
   */
  toggleKey?: keyof T

  /**
   *
   */
  getLoading?: boolean

  /**
   *
   */
  updateLoading?: boolean

  /**
   *
   */
  disableToggle?: boolean
}

/**
 *
 * @param props Component Props
 * @returns TSX
 */
function CrudList<
  T extends ReturnType<Serializable['toJSON']> & { id: number }
>(props: CrudListProps<T>) {
  const {
    items,
    handleDelete,
    handleEdit,
    handleToggle,
    listStyle,
    title,
    toggleKey,
    getLoading,
    disableToggle
  } = props

  return (
    <List component="nav" sx={listStyle}>
      {title && (
        <>
          <ListItem>
            <Typography fontSize={14} color={Colors.light.text}>
              {title}
            </Typography>
          </ListItem>
          <Divider />
        </>
      )}
      {getLoading ? (
        <Loader boxProps={{ justifyContent: 'center', py: 2 }} size={32} />
      ) : (
        items?.map((item) => {
          return (
            <CrudListItem
              key={item.id}
              item={item}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              handleToggle={handleToggle}
              toggleKey={toggleKey}
              disableToggle={disableToggle}
            />
          )
        })
      )}
    </List>
  )
}

export default CrudList
export { CrudList }
