import { useForm } from 'react-hook-form'

import { FinancialProductData, schema } from './schema'

type FinancialProductProps = {
  defaultValues?: FinancialProductData
}

/**
 *
 * @param props - FinancialProductProps
 * @returns useForm methods
 */
export const useFinancialProductForm = (props: FinancialProductProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<FinancialProductData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
