import { useGetCmsVehicles } from 'hooks/vehicles/useGetCmsVehicles'

import { useUpdateVehicle } from '../vehicles/useUpdateVehicle'

/**
 * Hook to use CRUD operations on vehicles
 *
 * @returns vehicles, deleteBanner, updateBanner, refetch, isLoading, getLoading, deleteLoading, updateLoading
 */
export const useCrudVehicle = () => {
  const { vehicles, refetch, isLoading: getLoading } = useGetCmsVehicles()

  const {
    mutate: updateVehicle,
    isLoading: updateLoading,
    mutateAsync: updateAsyncVehicle
  } = useUpdateVehicle({
    onSuccess: refetch
  })

  const isLoading = getLoading || updateLoading

  return {
    vehicles,
    updateVehicle,
    updateAsyncVehicle,
    refetch,
    isLoading,
    getLoading,
    updateLoading
  }
}
