import React from 'react'

import dayjs from 'dayjs'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import { Detail } from 'components/Detail'

import { useInsuranceClaimsLocation } from 'hooks/useInsuranceClaimsLocation'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
const Accident = () => {
  const { t } = useLanguage()
  const claim = useInsuranceClaimsLocation()

  const handleDate = React.useCallback((date?: string) => {
    return date && date !== 'Não Informado'
      ? dayjs(date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')
      : date ?? ''
  }, [])

  return (
    <ContentContainer
      title={t('insurancePolicies.claims.accident.title').toUpperCase()}
    >
      <Detail
        rows={[
          {
            key: t('insurancePolicies.claims.accident.conductor'),
            value: claim.condutor
          },
          {
            key: t('insurancePolicies.claims.accident.document'),
            value: claim.documento
          },
          {
            key: t('insurancePolicies.claims.accident.birthday'),
            value: claim.nascimento
          },
          {
            key: t('insurancePolicies.claims.accident.license'),
            value: claim.habilitacao
          },
          {
            key: t('insurancePolicies.claims.accident.dueDate'),
            value: handleDate(claim.vencimento)
          },
          {
            key: t('insurancePolicies.claims.accident.category'),
            value: claim.categoria
          },
          {
            key: t('insurancePolicies.claims.accident.accidentDate'),
            value: handleDate(claim.dataAcidente)
          },
          {
            key: t('insurancePolicies.claims.accident.accidentHour'),
            value: claim.horaAcidente
          },
          {
            key: t('insurancePolicies.claims.accident.location'),
            value: claim.local
          },
          {
            key: t('insurancePolicies.claims.accident.state'),
            value: claim.estado
          },
          {
            key: t('insurancePolicies.claims.accident.city'),
            value: claim.cidade
          },
          {
            key: t('insurancePolicies.claims.accident.description'),
            value: claim.descricao
          }
        ]}
      />
    </ContentContainer>
  )
}

export default Accident
export { Accident }
