import ContractDTO from '@volvo-apps/shared/models/ContractDTO'
import { GenericService } from './GenericService'
import { AxiosResponse } from 'axios'
import ContractPaginationDTO from '../../models/ContractPaginationDTO'
import ContractInstallmentDTO from '../../models/ContractInstallmentDTO'
import InstallmentDTO from '../../models/InstallmentDTO'
import PartsAndServicesContractPaginationDTO from '../../models/PartsAndServicesContractPaginationDTO'

/**
 * Finance service
 */
export class FinanceService extends GenericService<ContractDTO> {
  readonly URI: string = 'gateway/Finance'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * @param page Page Number
   * @param valueToFilter Value to Filter
   * @param filters Filters to Apply
   * @returns Promise
   */
  public async getContractByUser(
    page: number,
    valueToFilter?: string,
    filters?: {
      valueToFilter: string
      attributeCodes: number[]
    }[]
  ) {
    return this.post<AxiosResponse<ContractPaginationDTO>>(
      'GetContractByUser',
      {
        pageNumber: page,
        valueToFilter,
        filters,
        pageItemsLimit: 30
      }
    )
  }

  /**
   * @param page Page Number
   * @param valueToFilter Value to Filter
   * @param filters Filters to Apply
   * @returns Promise
   */
  public async getPartsAndServicesContractByUser(
    page: number,
    valueToFilter?: string,
    filters?: {
      valueToFilter: string
      attributeCodes: number[]
    }[]
  ) {
    return this.post<AxiosResponse<PartsAndServicesContractPaginationDTO>>(
      'GetPartsAndServicesContractByUser',
      {
        pageNumber: page,
        valueToFilter,
        filters,
        pageItemsLimit: 30
      }
    )
  }

  /**
   * @param contract Contract Number
   * @param numParcela Installment Number
   * @param extractType Extract Type
   * @param tipoParcela Installment Type
   * @returns Promise
   */
  getBoletoByContract(
    contract: ContractDTO['numeroContrato'],
    numParcela: ContractDTO['numeroParcela'],
    extractType: ContractDTO['codSistema'],
    tipoParcela: ContractDTO['tipoParcela']
  ) {
    return this.get<{ data: Blob }>(
      `getBoletoByContract`,
      {
        contract,
        numParcela,
        extractType,
        tipoParcela
      },
      { responseType: 'blob' }
    )
  }

  /**
   * @param contract Contract Number
   * @returns Promise
   */
  getExtractByContract(contract: ContractDTO['numeroContrato']) {
    return this.get<{ data: Blob }>(
      `getExtractByContract`,
      {
        contract
      },
      { responseType: 'blob' }
    )
  }

  /**
   * @param contract Contract Number
   * @param documentNumber Document Number
   * @returns Promise
   */
  getInstallmentsByContract(
    contract: ContractDTO['numeroContrato'],
    documentNumber: ContractDTO['cpfCnpj']
  ) {
    return this.get<AxiosResponse<ContractInstallmentDTO>>(
      `getInstallmentsByContract?contract=${contract.trim()}&documentNumber=${documentNumber}`
    )
  }

  /**
   * @param contractNumber Contract Number
   * @param dueDate Due Date
   * @param financeType Finance Type
   * @param documentNumber Document Number
   * @param installmentsToPay Array of Installment IDs
   * @param sourceResources the source of the resource
   * @param reasonToLiquidate the reason to liquidate
   * @returns Promise
   */
  payInstallmentsByContract(
    contractNumber: ContractDTO['numeroContrato'],
    dueDate: InstallmentDTO['dueDate'],
    financeType: string,
    documentNumber: string,
    installmentsToPay?: Array<number>,
    sourceResources?: string,
    reasonToLiquidate?: string
  ) {
    return this.post<{ data: Blob }>(
      `payInstallmentsByContract`,
      {
        contractNumber,
        dueDate,
        financeType,
        documentNumber,
        installmentsToPay,
        sourceResources,
        reasonToLiquidate
      },
      { responseType: 'blob' }
    )
  }

  /**
   * @param contractNumber Contract Number
   * @param documentNumber Document Number
   * @param financeType Finance Type
   * @param dueDate Due Date
   * @param installmentsToPay Array of Installment IDs
   * @param reasonToLiquidate Reason
   * @param sourceResources Source
   * @returns Promise
   */
  payInstallmentsByContractForMobile(
    contractNumber: ContractDTO['numeroContrato'],
    documentNumber: string,
    financeType: string,
    dueDate: InstallmentDTO['dueDate'],
    installmentsToPay?: Array<number>,
    reasonToLiquidate?: string,
    sourceResources?: string
  ) {
    return this.post<{ data: Blob }>(
      `payInstallmentsByContract`,
      {
        contractNumber,
        documentNumber,
        financeType,
        dueDate,
        installmentsToPay,
        reasonToLiquidate,
        sourceResources
      },
      { responseType: 'blob' }
    )
  }
}
