import React from 'react'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import { Detail } from 'components/Detail'

import { useInsuranceClaimsLocation } from 'hooks/useInsuranceClaimsLocation'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
const Item = () => {
  const { t } = useLanguage()
  const claim = useInsuranceClaimsLocation()

  return (
    <ContentContainer
      title={t('insurancePolicies.claims.item.title').toUpperCase()}
    >
      <Detail
        rows={[
          {
            key: t('insurancePolicies.claims.item.owner'),
            value: claim.proprietario
          },
          {
            key: t('insurancePolicies.claims.item.model'),
            value: claim.modelo
          },
          {
            key: t('insurancePolicies.claims.item.manufacturer'),
            value: claim.fabricante
          },
          {
            key: t('insurancePolicies.claims.item.year'),
            value: claim.ano
          },
          {
            key: t('insurancePolicies.claims.item.plate'),
            value: claim.placa
          },
          {
            key: t('insurancePolicies.claims.item.chassis'),
            value: claim.chassi
          }
        ]}
      />
    </ContentContainer>
  )
}

export default Item
export { Item }
