// --------------------------------------------------------
// GENERAL
// --------------------------------------------------------
import { CategoryService } from '../service/Category.service'
import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'
// --------------------------------------------------------

/**
 *
 */
export async function getAllCategories() {
  const service = new CategoryService()
  const response = (await service.getAll()) as {
    data: Record<string, unknown>[]
  }

  return response.data.map((cat) => new FaqCategoryDTO().fromJSON(cat))
}
/**
 * @param id The id of the faq category
 * @returns faq category
 */
export async function getFaqCategory(id: number) {
  const service = new CategoryService()
  const response = await service.getFaqCategory(id)
  return new FaqCategoryDTO().fromJSON(response.data)
}

/**
 * @param id The id of the faq category
 * @returns Delete faq category
 */
export async function deleteFaqCategory(id: number) {
  const service = new CategoryService()
  const response = await service.deleteFaqCategory(id)

  return response
}

/**
 * @param updatedFaqCategory The updated faq category
 */
export async function updateFaqCategory(updatedFaqCategory: FaqCategoryDTO) {
  const service = new CategoryService()
  const response = await service.updateFaqCategory(updatedFaqCategory)

  return response
}

/**
 *
 * @param icon the icon of the category
 * @param label the label of the category
 * @param description The description of the category
 */
export async function createFaqCategory(
  icon: string,
  label: string,
  description: string
) {
  const service = new CategoryService()
  const response = await service.createFaqCategory(icon, label, description)

  return response
}

/**
 * @returns All cms categories
 */
export async function getAllCmsFaqCategory() {
  const service = new CategoryService()
  const response = await service.getAllCms()

  return response.data.map((fp) => new FaqCategoryDTO().fromJSON(fp))
}
