import { getAllNotifications } from '@volvo-apps/shared/core/actions/Notification'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all notifications
 *
 * @returns notifications, isLoading, refetch
 */
export const useGetNotifications = () => {
  const {
    data: notifications,
    isLoading,
    refetch
  } = useQuery('notifications', {
    queryFn: getAllNotifications,
    onError: handleErrorFeedback
  })

  return { notifications, isLoading, refetch }
}
