import { useForm } from 'react-hook-form'

import { TermData, schema } from './schema'

type TermProps = {
  defaultValues?: TermData
}

/**
 *
 * @param props - TermProps
 * @returns useForm methods
 */
export const useTermForm = (props: TermProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<TermData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
