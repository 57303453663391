import 'reflect-metadata'
import { jsonProperty, jsonName, Serializable } from 'ts-serializable'
import InstallmentDTO from './InstallmentDTO'
import InstallmentRequestDTO from './InstallmentRequestDTO'

/**
 * The response of InstallmentData
 */
class ContractInstallmentDTO extends Serializable {
  @jsonName('total')
  @jsonProperty(Number)
  total!: number

  @jsonName('totalDiscounts')
  @jsonProperty(Number)
  totalDiscounts!: number

  @jsonName('totalCurrentValue')
  @jsonProperty(Number)
  totalCurrentValue!: number

  @jsonName('totalInstallments')
  @jsonProperty(Number)
  totalInstallments!: number

  @jsonName('subtotal')
  @jsonProperty(Number)
  subtotal!: number

  @jsonName('taxes')
  @jsonProperty(Number)
  taxes!: number

  @jsonName('discount')
  @jsonProperty(Number)
  discount!: number

  @jsonName('contractNumber')
  @jsonProperty(Number)
  contractNumber!: number

  @jsonProperty(Array)
  installmentsArray!: Array<InstallmentDTO>

  @jsonName('onGoingRequests')
  @jsonProperty(Array)
  public onGoingRequests!: Array<InstallmentRequestDTO>

  @jsonName('reasonToLiquidate')
  @jsonProperty(String)
  reasonToLiquidate!: string

  @jsonName('sourceResources')
  @jsonProperty(String)
  sourceResources!: string
}

export default ContractInstallmentDTO
