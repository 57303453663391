import { useMutation } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import UserDomainDTO from '../../../models/UserDomainDTO'
import { addUserDomain } from '../../actions/UserDomains'

type useAddUserDomainParams = HookQueryParams<UserDomainDTO>

/**
 * Hook to add domain to functional domain
 *
 * @param params params
 * @returns mutate, domain, isLoading, mutateAsync
 */
export const useAddUserDomain = (params: useAddUserDomainParams = {}) => {
  const { onSuccess, onError } = params
  const {
    mutate,
    isLoading,
    data: domain,
    mutateAsync
  } = useMutation('addUserDomain', {
    mutationFn: ({ domain }: { domain: UserDomainDTO }) =>
      addUserDomain(domain),
    onSuccess,
    onError
  })

  return { mutate, domain, isLoading, mutateAsync }
}
