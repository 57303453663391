import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * LeadDTO
 */
class LeadDTO extends Serializable {
  @jsonName('Id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('Active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('datasimulacao')
  @jsonProperty(Date)
  public datasimulacao!: string

  @jsonName('horasimulacao')
  @jsonProperty(Date)
  public horasimulacao!: string

  @jsonName('nome')
  @jsonProperty(String)
  public nome!: string

  @jsonName('telefone')
  @jsonProperty(String)
  public telefone!: string

  @jsonName('email')
  @jsonProperty(String)
  public email!: string

  @jsonName('endereco')
  @jsonProperty(String)
  public endereco!: string

  @jsonName('naturezacliente')
  @jsonProperty(String)
  public naturezacliente!: string

  @jsonName('cpfcnpj')
  @jsonProperty(String)
  public cpfcnpj!: string

  @jsonName('linha')
  @jsonProperty(String)
  public linha!: string

  @jsonName('produto')
  @jsonProperty(String)
  public produto!: string

  @jsonName('tipofinanciamento')
  @jsonProperty(String)
  public tipofinanciamento!: string

  @jsonName('valorfinanciado')
  @jsonProperty(String)
  public valorfinanciado!: string

  @jsonName('valorentrada')
  @jsonProperty(String)
  public valorentrada!: string

  @jsonName('mesesparcelas')
  @jsonProperty(String)
  public mesesparcelas!: string

  @jsonName('mesescarencia')
  @jsonProperty(String)
  public mesescarencia!: string

  @jsonName('dataprimeiraparcela')
  @jsonProperty(Date)
  public dataprimeiraparcela!: string

  @jsonName('tactc')
  @jsonProperty(String)
  public tactc!: string

  @jsonName('cet')
  @jsonProperty(String)
  public cet!: string

  @jsonName('fgi')
  @jsonProperty(String)
  public fgi!: string

  @jsonName('valortotalfinanciado')
  @jsonProperty(String)
  public valortotalfinanciado!: string

  @jsonName('spreadvfs')
  @jsonProperty(String)
  public spreadvfs!: string

  @jsonName('taxariscobnds')
  @jsonProperty(String)
  public taxariscobnds!: string

  @jsonName('encargofgi')
  @jsonProperty(String)
  public encargofgi!: string

  @jsonName('taxaestimadamensal')
  @jsonProperty(String)
  public taxa_estimada_mensal!: string

  @jsonName('CreatedDate')
  @jsonProperty(Date)
  public createdDate!: string

  @jsonName('ModifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: string
}

export default LeadDTO
