import { AxiosResponse } from 'axios'
import NotificationDTO from '@volvo-apps/shared/models/NotificationDTO'
import { GenericService } from './GenericService'

/**
 * Notifications
 */
export class NotificationsService extends GenericService<NotificationDTO> {
  readonly URI: string = 'gateway/Notifications'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all notifications
   */
  public async getAll() {
    return super.get<{ data: NotificationDTO[] }>('GetAll')
  }

  /**
   *
   * @param id The id of the notification
   */
  public async getNotification(id: number) {
    return super.get<AxiosResponse<NotificationDTO>>('Get', { id })
  }

  /**
   * Delete notification
   *
   * @param id The id of the notification
   */
  public async deleteNotification(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Update notification
   *
   * @param updatedNotification The updated notification
   */
  public async updateNotification(updatedNotification: NotificationDTO) {
    return super.put<AxiosResponse<NotificationDTO>>('Update', {
      ...updatedNotification
    })
  }

  /**
   * Create a notification
   *
   * @param payload The payload to create the notification
   */
  public async createNotification(payload: NotificationDTO) {
    return super.post<AxiosResponse<NotificationDTO>>('Add', payload)
  }

  /**
   * Get interval enums
   *
   * @returns Interval enum as record
   */
  public async getIntervalEnums() {
    return super.get<AxiosResponse<Record<string, number>>>('GetIntervalEnums')
  }

  /**
   * Get day enums
   *
   * @returns Day enum as record
   */
  public async getDayEnums() {
    return super.get<AxiosResponse<Record<string, number>>>('GetDayEnums')
  }
}
