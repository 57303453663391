import { useForm } from 'react-hook-form'

import { UserReportsData, schema } from './schema'

type UserReportsProps = {
  defaultValues?: UserReportsData
}

/**
 *
 * @param props - UserReportsProps
 * @returns useForm methods
 */
export const useUserReportsForm = (props: UserReportsProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<UserReportsData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
