import { useQuery } from 'react-query'
import { HookQueryParams } from '../../../common/utils/types'
import { hasPartsAndServices } from '../../actions/PartsAndServices'

type useHasPartsAndServiceParams = HookQueryParams<boolean>

/**
 * @param params params
 * @returns data, isLoading
 */
export const useHasPartsAndService = (
  params: useHasPartsAndServiceParams = {}
) => {
  const { data, isLoading } = useQuery('hasPartsAndServices', {
    queryFn: hasPartsAndServices,
    ...params
  })

  return {
    data,
    isLoading
  }
}
