import { useInvalidateQuery } from '@volvo-apps/shared/core/hooks'
import {
  useLinkDocument,
  useSearchCompanyNameByDocument,
  useSearchNameByEmail
} from '@volvo-apps/shared/core/hooks/linkedDocuments'

import React from 'react'

import { IconButton, InputAdornment } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Document, Form, Input } from 'components'
import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { makeResolver } from 'common/utils/form'
import { CpfOrCnpjValidation } from 'common/validators/cpfCnpj'
import { useLanguage } from 'context/Language'

type LinkFormType = {
  email: string
  name: string
  document: string
  company: string
}

const schema = makeResolver((Yup) =>
  Yup.object({
    email: Yup.string()
      .email('validation.invalid.email')
      .required('validation.required.email'),
    name: Yup.string().required('validation.required.general'),
    document: Yup.string().required('validation.required.general'),
    company: Yup.string()
  })
)

type ModalContentProps = {
  closeModal: () => void
}

/**
 *
 * @param props props
 * @returns TSX
 */
const ModalContent = (props: ModalContentProps) => {
  const { closeModal } = props

  const { invalidate } = useInvalidateQuery()
  const { t } = useLanguage()

  const methods = useForm<LinkFormType>({
    mode: 'onChange',
    resolver: schema,
    defaultValues: {
      email: '',
      name: '',
      document: '',
      company: ''
    }
  })

  const { mutate, isLoading: linkDocumentLoading } = useLinkDocument({
    onSuccess: () => {
      invalidate({ queryKey: 'linkedDocuments' })
      closeModal()
    },
    onError: handleErrorFeedback
  })

  const email = methods.watch('email')
  const document = methods.watch('document')

  const documentIsValid = CpfOrCnpjValidation(document)
  const emailIsValid =
    methods.formState.errors.email === undefined && email.length > 1

  const { isLoading: searchNameLoading, name } = useSearchNameByEmail({
    retry: 0,
    enabled: emailIsValid,
    email,
    onSuccess: (data) => {
      methods.setValue('name', data)
    },
    onError: (err) => {
      handleErrorFeedback(err)
      methods.setValue('name', '')
    }
  })

  const { isLoading: searchCompanyNameLoading, nameOrCompanyName } =
    useSearchCompanyNameByDocument({
      retry: 0,
      enabled: documentIsValid,
      document: document.replace(/\D/g, ''),
      onSuccess: (data) => {
        methods.setValue('company', data)
      },
      onError: (err) => {
        handleErrorFeedback(err)
        methods.setValue('company', '')
      }
    })

  const onSubmit = React.useCallback(
    (data: LinkFormType) => {
      const { email, document } = data
      mutate({ email, document })
    },
    [mutate]
  )

  React.useEffect(() => {
    if (!documentIsValid && document.length) {
      methods.setValue('company', '')
    }

    if (!emailIsValid && email.length) {
      methods.setValue('name', '')
    }
  }, [email, document, documentIsValid, emailIsValid, methods])

  const loading =
    linkDocumentLoading || searchNameLoading || searchCompanyNameLoading

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <FlexBox flexDirection="column">
        <FlexBox gap={4}>
          <Input
            margin="normal"
            fullWidth
            label={t('cms.linkUsers.linkModal.fields.email')}
            name="email"
          />
          <Input
            margin="normal"
            fullWidth
            label={t('cms.linkUsers.linkModal.fields.name')}
            disabled
            name="name"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    style={{
                      minWidth: 40,
                      minHeight: 40
                    }}
                  >
                    {(!!name || searchNameLoading) && (
                      <Icon
                        family="mui"
                        sx={{
                          color: name
                            ? Colors.light.checkLight
                            : Colors.light.text
                        }}
                        name={name ? 'Done' : 'Loop'}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </FlexBox>
        <FlexBox gap={4}>
          <Document
            margin="normal"
            fullWidth
            label={t('cms.linkUsers.linkModal.fields.document')}
            name="document"
          />
          <Input
            margin="normal"
            fullWidth
            disabled
            label={t('cms.linkUsers.linkModal.fields.companyName')}
            name="company"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    style={{
                      minWidth: 40,
                      minHeight: 40
                    }}
                  >
                    {(!!nameOrCompanyName || searchCompanyNameLoading) && (
                      <Icon
                        family="mui"
                        sx={{
                          color: nameOrCompanyName
                            ? Colors.light.checkLight
                            : Colors.light.text
                        }}
                        name={nameOrCompanyName ? 'Done' : 'Loop'}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </FlexBox>

        <FlexBox gap={2} flexDirection="column" mt={2}>
          <PlainButton
            type="submit"
            style={{
              backgroundColor: Colors.light.interactDefault,
              width: '100%'
            }}
            disabled={loading}
            loading={loading}
            variant="contained"
          >
            {t('cms.linkUsers.linkModal.submit')}
          </PlainButton>

          <PlainButton
            onClick={() => {
              closeModal()
            }}
            style={{
              backgroundColor: Colors.light.background,
              width: '100%'
            }}
            disabled={linkDocumentLoading}
            variant="outlined"
          >
            {t('cms.linkUsers.linkModal.close')}
          </PlainButton>
        </FlexBox>
      </FlexBox>
    </Form>
  )
}

export default ModalContent
export { ModalContent }
