import { useGetUserRoleById } from '@volvo-apps/shared/core/hooks/role/useGetUserRoleById'
import { useUpdateUserRole } from '@volvo-apps/shared/core/hooks/role/useUpdateUserRole'
import RoleDTO from '@volvo-apps/shared/models/RoleDTO'

import React, { useEffect } from 'react'

import { Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { UserSettingsRoutesEnum } from 'routes/cms/routesEnums'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { RoleForm } from '../Form/RoleForm'
import { RoleData } from '../Form/schema'
import { useRoleForm } from '../Form/useRoleForm'
import { allPermissionsRole } from '../Form/utils'

/**
 * Role Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguage()
  const state = useLocation().state as { roleDTO: RoleDTO }

  const [roleById, setRoleById] = React.useState<RoleDTO>()

  useGetUserRoleById({
    id: Number(id),
    onError: handleErrorFeedback,
    onSuccess: (data) => {
      setRoleById(data)
    }
  })

  const { methods } = useRoleForm({
    defaultValues: {
      name: state?.roleDTO?.name,
      permissions: []
    }
  })

  const { mutate, isLoading: updateLoading } = useUpdateUserRole({
    onSuccess: () => {
      toast.success(t('cms.roles.update.success'))
      navigate(UserSettingsRoutesEnum.ROLES_LIST)
    },
    onError: handleErrorFeedback
  })

  const onSubmit = React.useCallback(
    (formData: RoleData) => {
      const permissions = formData.permissions.filter(
        (permission) =>
          permission.id !== allPermissionsRole.id && permission.active
      )
      mutate({
        role: new RoleDTO().fromJSON({
          ...state?.roleDTO,
          ...formData,
          permissions,
          createdDate: undefined
        })
      })
    },
    [mutate, state?.roleDTO]
  )

  useEffect(() => {
    if (!state?.roleDTO) navigate(UserSettingsRoutesEnum.ROLES_LIST)
  }, [state?.roleDTO, navigate])

  if (!state?.roleDTO) return null

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.roles.update.title')}
      </Typography>
      <RoleForm
        isLoading={updateLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.roles.update.button')}
        role={roleById}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
