import { useGetPaginatedContracts } from '@volvo-apps/shared/core/hooks/contracts'
import ContractDTO from '@volvo-apps/shared/models/ContractDTO'
import { RentalDetailsFromPaginationDTO } from '@volvo-apps/shared/models/ContractPaginationDTO'
import RentalContractDTO from '@volvo-apps/shared/models/RentalContractDTO'

import React, { useEffect, useMemo } from 'react'

import { useInView } from 'react-intersection-observer'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import { ContractCard } from 'components/ContractCard'
import { ContractStatusChip } from 'components/ContractStatusChip'
import GenericList from 'components/List/GenericList'
import { Loader } from 'components/Loader'
import { RentalContractCard } from 'components/RentalContractCard'

import { FinancialRoutesEnum } from 'routes/financial.routes'

import { useDebounce } from 'hooks/useDebounce'
import useSearchBar from 'hooks/useSearchBar'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function Contracts() {
  const [ref, inView] = useInView()

  const { searchBar, searchTerm } = useSearchBar({
    style: {
      margin: '0 0 16px 0'
    }
  })

  const debouncedSearch = useDebounce(searchTerm, 1000)
  const { t } = useLanguage()

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    isFetched,
    showData
  } = useGetPaginatedContracts({
    retry: 0,
    suspense: false,
    cacheTime: 0,
    valueToFilter: debouncedSearch
  })

  const isRental = (
    contract: ContractDTO | RentalDetailsFromPaginationDTO
  ): contract is RentalDetailsFromPaginationDTO => {
    return !(contract as ContractDTO).codEmpresa
  }

  const items = useMemo(() => {
    return (
      data?.pages.flatMap((page) => {
        const contracts = page.contracts || []
        const rentalDetails = page.rentalDetails || []

        return contracts.length > 0 || rentalDetails.length > 0
          ? [...contracts, ...rentalDetails]
          : []
      }) ?? []
    )
  }, [data])

  useEffect(() => {
    if (inView && hasNextPage && !isFetching) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, inView, isFetching])

  const makeChildrenComponent = (
    contract: ContractDTO | RentalDetailsFromPaginationDTO
  ) => {
    if (!contract) return null

    if (isRental(contract)) {
      const contractDetails =
        contract.contractsListDetails &&
        contract.contractsListDetails.length > 0
          ? contract.contractsListDetails[0]
          : null

      const item = new RentalContractDTO().fromJSON({
        numeroContrato: Number(contractDetails?.numeroContrato ?? 0),
        documento: contractDetails?.documento ?? '',
        vigenciaContrato: contractDetails?.vigenciaContrato ?? new Date(),
        nomeRazao: contractDetails?.nomeRazao ?? '',
        isValid: contractDetails?.isValid ?? false,
        dataContrato: contractDetails?.dataContrato,
        qtdVeiculos: contractDetails?.qtdVeiculos ?? 0
      })

      return (
        <RentalContractCard
          rentalContract={item}
          showMoreDetails
          detailsLabel={{
            to: FinancialRoutesEnum.RENTAL_DETAIL.replace(
              ':id',
              item.documento
            ),
            state: { rentalContract: item }
          }}
          boxProps={{
            border: `1px solid ${Colors.light.greys.GREY_200}`,
            boxShadow: `1px 1px 5px ${Colors.light.shadow}`,
            width: '100%',
            p: 2
          }}
          fromContractsScreen
        />
      )
    }

    return (
      <ContractCard
        showMoreDetails
        contract={contract}
        detailsLabel={{
          to: FinancialRoutesEnum.CONTRACT_DETAIL.replace(
            ':id',
            contract.numeroContrato
          ),
          state: { contract }
        }}
        boxProps={{
          border: `1px solid ${Colors.light.greys.GREY_200}`,
          boxShadow: `1px 1px 5px ${Colors.light.shadow}`,
          width: '100%',
          p: 2
        }}
        titleSuffix={<ContractStatusChip contract={contract} />}
      />
    )
  }

  return (
    <ContentContainer title={t('financial.menu.contracts').toUpperCase()}>
      {searchBar}
      {showData && (
        <GenericList
          listItemStyle={{ paddingRight: 0, paddingLeft: 0 }}
          hideButton
          items={items}
          makeChildrenComponent={makeChildrenComponent}
          disableNoInfoDisclaimer={!isFetched || !!searchTerm}
        />
      )}

      <Loader
        ref={ref}
        hideCircularProgress={!isLoading && !isFetching}
        size={20}
        boxProps={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
      />
    </ContentContainer>
  )
}

export default Contracts
export { Contracts }
