import { getAllHolidays } from '@volvo-apps/shared/core/actions/Holiday'
import HolidayDTO from '@volvo-apps/shared/models/HolidayDTO'

import { useQuery } from 'react-query'
import { HookQueryParams } from '../../common/utils/types'

type useGetAllHolidayParams = HookQueryParams<HolidayDTO[]>

/**
 * Hook to get all holiday
 *
 * @param params params
 * @returns holiday, isLoading, refetch
 */
export const useGetAllHoliday = (params: useGetAllHolidayParams = {}) => {
  const { onSuccess, onError } = params
  const {
    data: holidayList,
    isLoading,
    refetch
  } = useQuery('getAllHoliday', {
    queryFn: () => getAllHolidays(),
    onSuccess,
    onError
  })

  return { holidayList, isLoading, refetch }
}
