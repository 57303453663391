// --------------------------------------------------------
// GENERAL
// --------------------------------------------------------
import { AxiosRequestConfig } from 'axios'
import QueryString from 'qs'
import { HttpHandle } from '../http/HttpHandle'
// --------------------------------------------------------

/**
 *
 */
export class GenericService<T> {
  private httpHandle = HttpHandle.getInstance()
  private uri = ''

  /**
   * @param uri Service's URI
   */
  protected setUri(uri: string): void {
    this.uri = uri
  }

  /**
   * Concat url + resource
   *
   * @param resourceName The url complement
   * @returns The full url
   */
  protected getResource(resourceName: string): string {
    let _url = this.uri
    if (resourceName) {
      _url = `${_url}/${resourceName}`
    }
    return _url
  }

  /**
   * GET
   *
   * @param resourceName The url complement
   * @param params The parameters
   * @param config The config
   * @returns the server's response
   */
  public get<K = Record<string, unknown>>(
    resourceName = '',
    params?: Record<string, unknown>,
    config?: AxiosRequestConfig
  ): Promise<K> {
    const _uri = this.getResource(resourceName)

    let out
    if (params) {
      out = {
        params,
        paramsSerializer: QueryString.stringify,
        ...config
      }
    }
    return this.httpHandle.getAxios().get(_uri, out)
  }

  /**
   * POST
   *
   * @param resourceName request body
   * @param params request body
   * @param config config
   * @returns the server's response
   */
  public post<K = Record<string, unknown>>(
    resourceName = '',
    params: T | Record<string, unknown> | FormData,
    config?: AxiosRequestConfig
  ): Promise<K> {
    const _uri = this.getResource(resourceName)

    return this.httpHandle.getAxios().post(_uri, params, config)
  }

  /**
   * PUT
   *
   * @param resourceName request body
   * @param params request body
   * @returns the server's response
   */
  public put<K = Record<string, unknown>>(
    resourceName = '',
    params: T | Record<string, unknown>
  ): Promise<K> {
    const _uri = this.getResource(resourceName)
    return this.httpHandle.getAxios().put(_uri, params)
  }

  /**
   * PATCH
   *
   * @param resourceName request body
   * @param params request body
   * @returns the server's response
   */
  public patch<K = Record<string, unknown>>(
    resourceName = '',
    params: T | Record<string, unknown>
  ): Promise<K> {
    const _uri = this.getResource(resourceName)

    return this.httpHandle.getAxios().patch(_uri, params)
  }

  /**
   * HEAD
   *
   * @param resourceName The url complement
   * @param params The parameters
   * @param config The config
   * @returns the server's response
   */
  public head<K = Record<string, unknown>>(
    resourceName = '',
    params?: Record<string, unknown>,
    config?: AxiosRequestConfig
  ): Promise<K> {
    const _uri = this.getResource(resourceName)

    let out
    if (params) {
      out = {
        params,
        paramsSerializer: QueryString.stringify,
        ...config
      }
    }
    return this.httpHandle.getAxios().head(_uri, out)
  }

  /**
   * Removes something
   *
   * @param resourceName request body
   * @param params request body
   * @returns the server's response
   */
  public delete(
    resourceName = '',
    params?: T | Record<string, unknown>
  ): Promise<Record<string, unknown>> {
    const _uri = this.getResource(resourceName)

    let out
    if (params) {
      out = {
        params,
        paramsSerializer: QueryString.stringify
      }
    }

    return this.httpHandle.getAxios().delete(_uri, out)
  }
}
