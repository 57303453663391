/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react'

import { Box, BoxProps } from '@mui/material'
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form'

type FormProps = PropsWithChildren & {
  onSubmit?: (data: any) => void
  methods?: UseFormReturn<any, any>
  formProps?: BoxProps
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Form(props: FormProps) {
  const { children, onSubmit, methods, formProps } = props
  const methodsApi = useForm()
  const methodsUse = methods ?? methodsApi

  return (
    <FormProvider {...methodsUse}>
      <Box
        component="form"
        onSubmit={onSubmit && methodsUse.handleSubmit(onSubmit)}
        {...formProps}
      >
        {children}
      </Box>
    </FormProvider>
  )
}

export default Form
export { Form }
