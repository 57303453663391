import React, { PropsWithChildren } from 'react'

import { useLocation } from 'react-router'

import { FlexBox } from 'components/FlexBox'
import { NavBar } from 'components/NavBar'

import { breadcrumbs } from 'routes/cms'

type CmsChildrenWithHeaderProps = {
  headerSuffix?: React.ReactNode
}

/**
 * CMS Children Header
 *
 * @param props Props
 * @returns TSX
 */
function CmsChildrenWithHeader(
  props: PropsWithChildren<CmsChildrenWithHeaderProps>
) {
  const pathname = useLocation().pathname.replace(/\d+/g, ':id')

  const breadcrumb = breadcrumbs[pathname]

  return (
    <FlexBox flexDirection="column" width="100%" py={2}>
      <FlexBox justifyContent="space-between" alignItems="center">
        {breadcrumb && <NavBar routes={breadcrumb} px="0" />}
        {props.headerSuffix}
      </FlexBox>
      <FlexBox flexDirection="column" justifyContent="flex-start" width="100%">
        {props.children}
      </FlexBox>
    </FlexBox>
  )
}

export default CmsChildrenWithHeader
export { CmsChildrenWithHeader }
