import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * PartsAndServicesImportDTO
 */
class PartsAndServicesImportDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: string

  @jsonName('processingDate')
  @jsonProperty(Date)
  public processingDate!: string

  @jsonName('errorsRecords')
  @jsonProperty(Number)
  public errorsRecords!: number

  @jsonName('processingRecords')
  @jsonProperty(Number)
  public processingRecords!: number

  @jsonName('sequence')
  @jsonProperty(Number)
  public sequence!: number

  @jsonName('fileName')
  @jsonProperty(String)
  public fileName!: string
}

export default PartsAndServicesImportDTO
