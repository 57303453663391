import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * ConsortiumClosedResourcesHistoryDTO
 */
class ConsortiumClosedResourcesHistoryDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('fileName')
  @jsonProperty(String)
  public fileName!: string

  @jsonName('processingRecords')
  @jsonProperty(Number)
  public processingRecords!: number

  @jsonName('processingDate')
  @jsonProperty(Date)
  public processingDate!: string

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: string

  @jsonName('errorsRecords')
  @jsonProperty(Number)
  public errorsRecords!: number

  @jsonName('status')
  @jsonProperty(String)
  public status!: string
}

export default ConsortiumClosedResourcesHistoryDTO
