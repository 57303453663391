import { AxiosResponse } from 'axios'
import LeadsDTO from '@volvo-apps/shared/models/LeadDTO'
import LeadNotificationDTO from '@volvo-apps/shared/models/LeadNotificationDTO'
import { GenericService } from './GenericService'

/**
 * Leads
 */
export class LeadsService extends GenericService<LeadsDTO> {
  readonly URI: string = 'gateway/Leads'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all leads
   *
   * @param startDate The start date
   * @param endDate The end date
   */
  public async getAll(startDate: string, endDate: string) {
    return super.post<AxiosResponse<LeadsDTO[]>>('GetLeads', {
      beginDate: startDate,
      endDate
    })
  }

  /**
   * Export leads
   *
   * @param startDate The start date
   * @param endDate The end date
   */
  public async exportLeads(startDate: string, endDate: string) {
    return super.get<AxiosResponse<string>>('ExportLeadsCSV', {
      beginDate: startDate,
      endDate
    })
  }

  /**
   * Add notification
   *
   * @param leadNotification The notification
   */
  public async addNotification(leadNotification: LeadNotificationDTO) {
    return super.post<AxiosResponse<LeadNotificationDTO>>('AddNotification', {
      ...leadNotification
    })
  }

  /**
   * Update lead notification
   *
   * @param updatedLeadNotification The updated lead notification
   */
  public async updateNotification(
    updatedLeadNotification: LeadNotificationDTO
  ) {
    return super.put<AxiosResponse<LeadNotificationDTO>>('UpdateNotification', {
      ...updatedLeadNotification
    })
  }

  /**
   *
   */
  public async getAllLeadNotifications() {
    return super.get<AxiosResponse<LeadNotificationDTO[]>>(
      'getAllNotifications'
    )
  }
}
