import { useForm } from 'react-hook-form'

import { SimulationParameterData, schema } from './schema'

type SimulationParamProps = {
  defaultValues?: Partial<SimulationParameterData>
}

/**
 *
 * @param props - SimulationParamProps
 * @returns useForm methods
 */
export const useSimulationParameterForm = (
  props: SimulationParamProps = {}
) => {
  const { defaultValues } = props
  const methods = useForm<SimulationParameterData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
      installments: {
        minValue: 0,
        maxValue: 0,
        leapOfMonths: 0,
        valueStarts: 0
      },
      gracePeriod: {
        minValue: 0,
        maxValue: 0,
        leapOfMonths: 0,
        valueStarts: 0
      },
      ...defaultValues
    }
  })

  return { methods }
}
