// import './index.css'

import React from 'react'

import ReactDOM from 'react-dom/client'
import { ToastContainer } from 'react-toastify'

import { AppProviders } from 'components/Providers'
// import i18n (needs to be bundled)
import './i18n/i18nInstance'

import reportWebVitals from './reportWebVitals'
import Routes from './routes'
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <AppProviders>
    <Routes />
    <ToastContainer />
  </AppProviders>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
