import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import LinkedDocumentsDTO from './LinkedDocumentsDTO'

/**
 * LinkedDocumentsAndNamesDTO
 */
class LinkedDocumentsAndNamesDTO extends Serializable {
  @jsonName('linkedDocuments')
  @jsonProperty(Array)
  public linkedDocuments!: Array<LinkedDocumentsDTO>

  @jsonName('names')
  @jsonProperty(Array)
  public names!: Array<string>
}

export default LinkedDocumentsAndNamesDTO
