import React from 'react'

import { ReCaptcha as ReCaptchaComponent } from 'components/ReCaptcha'

/**
 * @returns TSX
 */
const useReCaptcha = () => {
  const [reCaptchaValue, setReCaptchaValue] = React.useState<string | null>(
    null
  )

  const onChangeCaptcha = (value: string | null) => {
    setReCaptchaValue(value)
  }

  const clearReCaptcha = React.useCallback(() => {
    setReCaptchaValue(null)
  }, [])

  const ReCaptcha = React.useMemo(
    () => (
      <ReCaptchaComponent
        onChange={onChangeCaptcha}
        style={{ marginTop: 8, marginBottom: 8 }}
      />
    ),
    []
  )

  return {
    reCaptchaValue,
    setReCaptchaValue,
    clearReCaptcha,
    ReCaptcha
  }
}

export default useReCaptcha
export { useReCaptcha }
