import React from 'react'

import { Box } from '@mui/material'
import { useNavigate } from 'react-router'

import { List, ListItemProps } from 'components'
import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CmsMenuHeader } from 'components/CmsMenuHeader'

import { UserSettingsRoutesEnum } from 'routes/cms/routesEnums'

import useCmsRoles from 'hooks/cms/useCmsRoles'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function Menu() {
  const navigate = useNavigate()
  const { showMenuByPage } = useCmsRoles()
  const { t } = useLanguage()

  const menuItems: ListItemProps[] = [
    // {
    //   primary: t('cms.dashboard.tabs.scr'),
    //   onClick: () => navigate(UserSettingsRoutesEnum.SCR_MENU),
    //   hide: !showMenuByPage[UserSettingsRoutesEnum.SCR_MENU]
    // },
    {
      primary: t('cms.dashboard.tabs.usersAccess'),
      onClick: () => navigate(UserSettingsRoutesEnum.USERS_ACCESS_LIST),
      hide: !showMenuByPage[UserSettingsRoutesEnum.USERS_ACCESS_LIST]
    },
    {
      primary: t('cms.dashboard.tabs.functionalDomains'),
      onClick: () => navigate(UserSettingsRoutesEnum.USER_DOMAINS),
      hide: !showMenuByPage[UserSettingsRoutesEnum.USER_DOMAINS]
    },
    {
      primary: t('cms.dashboard.tabs.roles'),
      onClick: () => navigate(UserSettingsRoutesEnum.ROLES_LIST),
      hide: !showMenuByPage[UserSettingsRoutesEnum.ROLES_LIST]
    },
    {
      primary: t('cms.dashboard.tabs.resetEmailAndPassword'),
      onClick: () => navigate(UserSettingsRoutesEnum.RESET_EMAIL_AND_PASSWORD),
      hide: !showMenuByPage[UserSettingsRoutesEnum.RESET_EMAIL_AND_PASSWORD]
    },
    {
      primary: t('cms.dashboard.tabs.linkUsers'),
      onClick: () => navigate(UserSettingsRoutesEnum.LINK_USERS_LIST),
      hide: !showMenuByPage[UserSettingsRoutesEnum.LINK_USERS_LIST]
    }
  ]

  return (
    <CmsChildrenWithHeader>
      <CmsMenuHeader
        titleText={t('cms.dashboard.tabs.userSettings')}
        subtitleText={t('cms.registers.subtitle')}
      />
      <Box mt={2} width={(t) => t.breakpoints.values.md}>
        <List items={menuItems} noPadding />
      </Box>
    </CmsChildrenWithHeader>
  )
}

export default Menu
export { Menu }
