import { useGetConsortiumClosedResourcesByDocument } from '@volvo-apps/shared/core/hooks/consortiumResources'

import React from 'react'

import { Chip, ChipProps, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Document, Form, useModalMethods } from 'components'
import { FlexBox } from 'components/FlexBox'
import { PlainButton } from 'components/PlainButton'

import useReCaptcha from 'hooks/useReCaptcha'

import Colors from 'constants/Colors'

import { handleErrorFeedbackIgnoreNotFound } from 'common/utils/error'
import { makeResolver } from 'common/utils/form'
import { cnpjValidator, cpfValidator } from 'common/validators/cpfCnpj'
import { useLanguage } from 'context/Language'

import ResultModal from './ResultModal'

type FormData = {
  documentType: 'CPF' | 'CNPJ'
  document: string
  reCaptcha: string
}

export const consortiumResourcesSchema = makeResolver((Yup) =>
  Yup.object({
    documentType: Yup.string().required('validation.required.general'),
    document: Yup.string().when('documentType', {
      is: 'CPF',
      then: cpfValidator,
      otherwise: cnpjValidator
    })
  })
)

/**
 * @returns TSX
 */
const ConsortiumResources: React.FC = () => {
  const { t } = useLanguage()
  const methods = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      documentType: 'CNPJ',
      document: ''
    },
    resolver: consortiumResourcesSchema
  })

  const { open, setClosed, setOpen } = useModalMethods()

  const { reCaptchaValue, ReCaptcha } = useReCaptcha()

  const handleClose = () => {
    methods.reset()
    setClosed()
  }

  const { consortiumClosedResources, refetch, isLoading } =
    useGetConsortiumClosedResourcesByDocument({
      document: methods.getValues('document').replace(/\D/g, ''),
      enabled: false,
      cacheTime: 0,
      retry: 0,
      onSuccess() {
        setOpen(true)
      },
      onError: (err) => {
        handleErrorFeedbackIgnoreNotFound(err, () => setOpen(true))
      }
    })

  const handleDocumentTypeChange = (value: FormData['documentType']) => {
    methods.setValue('documentType', value)
    if (methods.getValues('document')) {
      methods.trigger('document')
    }
  }

  const onSubmit = () => {
    refetch()
  }

  const isValid = React.useMemo(
    () => methods.formState.isValid,
    [methods.formState.isValid]
  )

  const watchDocumentType = methods.watch('documentType')

  const getChipProps = (
    isActive: boolean
  ): { style?: ChipProps['style']; variant: ChipProps['variant'] } => {
    if (isActive) {
      return {
        style: {
          backgroundColor: Colors.light.interactDefault,
          color: Colors.light.background
        },
        variant: 'filled'
      }
    } else {
      return {
        variant: 'outlined'
      }
    }
  }

  return (
    <Form
      methods={methods}
      formProps={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
      onSubmit={onSubmit}
    >
      <FlexBox flexDirection="column">
        <Typography
          mt={2}
          fontWeight="bold"
          fontSize={24}
          color={Colors.light.text}
        >
          {t('consortiumResources.title')}
        </Typography>
        <Typography
          mt={2}
          fontSize={16}
          color={Colors.light.greys.GREY_500}
          fontWeight="400"
        >
          {t('consortiumResources.description')}
        </Typography>

        <FlexBox gap={2} mt={2} mb={1}>
          <Chip
            label={t('general.cnpj')}
            {...getChipProps(watchDocumentType === 'CNPJ')}
            onClick={() => handleDocumentTypeChange('CNPJ')}
          />
          <Chip
            label={t('general.cpf')}
            {...getChipProps(watchDocumentType === 'CPF')}
            onClick={() => handleDocumentTypeChange('CPF')}
          />
        </FlexBox>

        <Document
          margin="normal"
          fullWidth
          documentType={watchDocumentType === 'CPF' ? 'cpf' : 'cnpj'}
          label={t('consortiumResources.cpfCnpj')}
          name="document"
        />

        {methods.formState.isValid && ReCaptcha}

        <PlainButton
          style={{ marginTop: 8 }}
          variant="contained"
          type="submit"
          disabled={!isValid || !reCaptchaValue}
          loading={isLoading}
        >
          {t('consortiumResources.button')}
        </PlainButton>
      </FlexBox>
      <ResultModal
        open={open}
        setClosed={handleClose}
        consortiumClosedDocument={consortiumClosedResources}
      />
    </Form>
  )
}

export default ConsortiumResources
export { ConsortiumResources }
