import { useChangeUseCms } from '@volvo-apps/shared/core/hooks/user'
import UserCmsAccessDTO from '@volvo-apps/shared/models/UserCmsAccessDTO'
import UserWithDocumentDTO from '@volvo-apps/shared/models/UserWithDocumentDTO'

import React from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'
import { toast } from 'react-toastify'

import DataGridInactiveSwitch from 'components/CrudDataGrid/DataGridInactiveSwitch'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

type AccessCMSSwitchProps = {
  params: GridRenderCellParams
}

/**
 *
 * @param props AccessCMSSwitchProps
 * @returns TSX
 */
const AccessCMSSwitch: React.FC<AccessCMSSwitchProps> = (props) => {
  const { params } = props

  const { t } = useLanguage()
  const { mutateAsync } = useChangeUseCms({
    onError: handleErrorFeedback
  })

  const handleToggle = React.useCallback(
    async (usersAccess: UserWithDocumentDTO, checked: boolean) => {
      const userAccess = new UserCmsAccessDTO().fromJSON({
        ...usersAccess,
        id: usersAccess.userId,
        canAccessCms: checked,
        createdDate: undefined
      })

      await mutateAsync({
        user: userAccess
      })

      toast.success(
        checked
          ? t('cms.usersAccess.list.toggle.enabled')
          : t('cms.usersAccess.list.toggle.disabled')
      )
    },
    [mutateAsync, t]
  )

  return (
    <DataGridInactiveSwitch
      checked={params.row.canAccessCms}
      handleToggle={(checked) => handleToggle(params.row, checked)}
    />
  )
}

export default AccessCMSSwitch
