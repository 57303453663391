import React, { useEffect, useMemo } from 'react'

import { Box } from '@mui/material'
import { useUser } from 'context'
import { useForm } from 'react-hook-form'

import { Datepicker, Document, Form, Input, Phone } from 'components'
import { PlainButton } from 'components/PlainButton'

import { CpfValidation } from 'common/validators/cpfCnpj'
import { useLanguage } from 'context/Language'

import { personalInfoSchema } from './helpers'
import { FormProps, FormValuesPersonalInfo } from './types'

/**
 *
 * @param props Component Props
 * @returns TSX
 */
function PersonalInfoForm(props: FormProps) {
  const { onSave, isLoading, profilePicture } = props
  const { userData } = useUser()
  const { t } = useLanguage()

  const nUserData = useMemo(
    () =>
      userData?.getAsFormValues([
        'name',
        'birthday',
        'email',
        'phoneNumber',
        'documents'
      ]),
    [userData]
  )

  const methods = useForm<FormValuesPersonalInfo>({
    mode: 'onChange',
    defaultValues: nUserData,
    resolver: personalInfoSchema
  })

  const pictureChanged =
    userData?.profilePicture !== profilePicture && profilePicture !== ''

  const isSubmitDisabled = React.useMemo(
    () =>
      (!methods.formState.isValid || !methods.formState.isDirty) &&
      !pictureChanged,
    [methods.formState.isDirty, methods.formState.isValid, pictureChanged]
  )

  const signUpDocument = userData?.documents?.find(
    (docDTO) => docDTO.isSignUpDocument
  )

  useEffect(() => {
    methods.reset(nUserData)
  }, [methods, nUserData])

  const validSignUpDocument =
    signUpDocument && CpfValidation(signUpDocument?.document)

  useEffect(() => {
    if (validSignUpDocument) return
    methods.trigger('signUpDocument')
  }, [methods, validSignUpDocument])

  return (
    <Form
      methods={methods}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '100%'
        }),
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center'
      }}
      onSubmit={onSave}
    >
      <Document
        margin="normal"
        fullWidth
        documentType="cpf"
        label={t('register.step1.cpf')}
        name="signUpDocument.document"
        disabled={validSignUpDocument}
      />
      <Input
        margin="normal"
        fullWidth
        label={t('register.step1.name')}
        name="name"
      />

      <Box mt={1}>
        <Datepicker
          margin="normal"
          fullWidth
          label={t('profile.menu.profileEditPersonal.birthday')}
          name="birthday"
          maxDate={new Date()}
        />
      </Box>
      <Input
        margin="normal"
        fullWidth
        autoComplete="off"
        label={t('register.step1.email')}
        name="email"
      />

      <Phone
        margin="normal"
        fullWidth
        name="phoneNumber"
        label={t('profile.menu.profileEditPersonal.phone')}
      />

      <PlainButton
        sx={(t) => ({ my: t.spacing(1) })}
        variant="contained"
        type="submit"
        disabled={isSubmitDisabled}
        loading={isLoading}
      >
        {t('general.saveChanges')}
      </PlainButton>
    </Form>
  )
}

export default PersonalInfoForm
export { PersonalInfoForm }
