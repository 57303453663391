import * as Yup from 'yup'

import { getOnlyNumbers } from 'common/utils/string'

/**
 *
 * @param zipCode Zip code
 * @returns Validation result
 */
export const isZipCodeValid = (zipCode: string) =>
  getOnlyNumbers(zipCode).length === 8

export const zipCodeValidator = Yup.string()
  .test('zipCode_validation', 'validation.invalid.zipCode', (value = '') =>
    isZipCodeValid(value)
  )
  .required('validation.required.general')
