import { useMutation } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { deleteUserDomain } from '../../actions/UserDomains'

type useDeleteUserDomainParams = HookQueryParams

/**
 * Hook to delete domain
 *
 * @param params params
 * @returns mutate, domain, isLoading, mutateAsync
 */
export const useDeleteUserDomain = (params: useDeleteUserDomainParams = {}) => {
  const { onSuccess, onError } = params
  const {
    mutate,
    isLoading,
    data: domain,
    mutateAsync
  } = useMutation('deleteUserDomain', {
    mutationFn: ({ id }: { id: number }) => deleteUserDomain(id),
    onSuccess,
    onError
  })

  return { mutate, domain, isLoading, mutateAsync }
}
