import React from 'react'

import { Box, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'

import { useGetParametersDetailById } from 'hooks/simulationParameters/useGetParametersDetailById'
import { useUserSimulationValidated } from 'hooks/useUserSimulationValidated'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import Card from './Card'

type BenefitsProps = {
  isValid?: boolean
}

/**
 * @param props - component props.
 * @returns TSX
 */
function Benefits(props: BenefitsProps) {
  const { isValid } = props
  const { simulation } = useUserSimulationValidated()
  const { t } = useLanguage()

  const {
    parametersDetailFiltered: { gracePeriod, installments },
    isLoading
  } = useGetParametersDetailById({
    cacheTime: 0,
    productLineId: simulation?.productLineId,
    financialProductId: simulation?.financialProductId
  })

  return (
    <Box style={{ opacity: isValid ? 1 : 0.6 }}>
      <Typography color={Colors.light.interactActive} variant="h5" my={3}>
        {t('simulation.summary.benefits.title')}
      </Typography>
      <FlexBox gap={2} mb={2}>
        <Card
          isLoading={isLoading}
          title={t('simulation.summary.upToMonths', [
            installments?.maxValue ?? 0
          ])}
          icon="calendar"
          description={t('simulation.summary.benefits.topic1')}
        />
        <Card
          title={t('simulation.summary.competitivesRates')}
          icon="calculator"
          description={t('simulation.summary.benefits.topic2')}
        />
      </FlexBox>
      <FlexBox gap={2}>
        <Card
          title={t('simulation.summary.upTo', ['100%'])}
          icon="truck"
          description={t('simulation.summary.benefits.topic3')}
        />
        {(gracePeriod?.maxValue || isLoading) && (
          <Card
            isLoading={isLoading}
            title={t('simulation.summary.upToMonths', [
              gracePeriod?.maxValue ?? 0
            ])}
            icon="clock"
            description={t('simulation.summary.benefits.topic4')}
          />
        )}
      </FlexBox>
    </Box>
  )
}

export default Benefits
export { Benefits }
