import { useMutation } from 'react-query'
import { deleteConsortiumClosedResource } from '../../actions/ConsortiumClosedResources'

type useDeleteConsortiumClosedResourceParams = {
  onSuccess?: () => void
  onError?: (err: unknown) => void
}

/**
 * Hook to delete one parts and services
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteConsortiumClosedResource = (
  params: useDeleteConsortiumClosedResourceParams = {}
) => {
  const { onSuccess, onError } = params
  const { mutate, isLoading, data } = useMutation(
    'deleteConsortiumClosedResource',
    {
      mutationFn: ({ id }: { id: number }) =>
        deleteConsortiumClosedResource(id),
      onSuccess,
      onError
    }
  )

  return { mutate, data, isLoading }
}
