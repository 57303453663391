import React from 'react'

import { PlainButton, PlainButtonProps } from 'components/PlainButton'

import { ReactComponent as Import } from '../../assets/icons/download.svg'

type SavePdfButtonProps = {
  label: string
  iconStyle?: React.CSSProperties
} & PlainButtonProps

/**
 *
 * @param props SavePdfButtonProps
 * @returns TSX
 */
const SavePdfButton = (props: SavePdfButtonProps) => {
  const { label, iconStyle, ...plainButtonProps } = props

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    plainButtonProps.onClick?.(e)
  }

  return (
    <PlainButton
      startIcon={<Import style={iconStyle} />}
      {...plainButtonProps}
      onClick={handleClick}
    >
      {label}
    </PlainButton>
  )
}

export default SavePdfButton
