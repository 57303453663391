import { getActiveContractsByDocuments } from '../actions/User'
import { HookQueryParams } from '../../common/utils/types'
import ContractDTO from '../../models/ContractDTO'
import { useQuery } from 'react-query'
type useGetActiveContractsByDocumentsParams = HookQueryParams<ContractDTO[]> & {
  document: string
}

/**
 * @param params params
 * @returns user data
 */
export const useGetActiveContractsByDocuments = (
  params: useGetActiveContractsByDocumentsParams
) => {
  const { document } = params
  const { data, isLoading, refetch, isFetching, isFetched } = useQuery(
    `getActiveContractsByDocuments-${document}`,
    {
      queryFn: () => getActiveContractsByDocuments(document),
      ...params
    }
  )

  return { data, isLoading, refetch, isFetching, isFetched }
}
