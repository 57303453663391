import React from 'react'

import { TooltipProps as TooltipMUIProps } from '@mui/material/Tooltip'

import { MuiTooltipStyled } from './MuiTooltipStyled'

type TooltipProps = {
  children: React.ReactElement
} & TooltipMUIProps

/**
 * Tooltip
 *
 * @param props TooltipProps
 * @param props.children React.ReactElement
 * @param props.title Tooltip text
 * @returns React.ReactElement
 */
const Tooltip = ({ children, ...rest }: TooltipProps) => {
  return (
    <MuiTooltipStyled arrow={true} {...rest}>
      {children}
    </MuiTooltipStyled>
  )
}

export { Tooltip }
