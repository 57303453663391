import React, { useRef } from 'react'

import { TextField, TextFieldProps } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { Controller } from 'react-hook-form'

import ErrorMessage from '../ErrorMessage/ErrorMessage'

/**
 *
 */
export type DatepickerProps = {
  /**
   *
   */
  name: string
  minDate?: Date
  maxDate?: Date
  onChange?: (e: Date | null) => void
  shouldDisableDate?: (date: Date) => boolean
} & Pick<TextFieldProps, 'helperText' | 'fullWidth' | 'label' | 'margin' | 'sx'>

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Datepicker(props: DatepickerProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <Controller
      name={props.name}
      render={({ field, fieldState }) => {
        const { error } = fieldState

        const mergedProps = {
          ...props,
          ...field,
          value: field.value ?? ''
        }
        return (
          <DesktopDatePicker
            label="Date desktop"
            inputFormat="DD/MM/YYYY"
            {...mergedProps}
            shouldDisableDate={props.shouldDisableDate}
            onChange={(e) => {
              mergedProps?.onChange?.(e)
              inputRef.current?.focus?.()
              props.onChange?.(e)
            }}
            inputRef={inputRef}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!error}
                helperText={
                  error?.message ? (
                    <ErrorMessage error={error} />
                  ) : (
                    props.helperText
                  )
                }
              />
            )}
          />
        )
      }}
    />
  )
}

export default Datepicker
export { Datepicker }
