import React from 'react'

import { ListItemIcon, ListItemText, Menu as MUIMenu } from '@mui/material'

import { Icon, SvgIconsType } from 'components/Icon'

import { MenuItemStyled } from './styles'

/**
 *
 */
export type MenuProps = {
  /**
   *
   */
  options: {
    /**
     *
     */
    label: string
    /**
     *
     */
    onClick: () => void
    /**
     *
     */
    icon?: SvgIconsType
  }[]
  /**
   *
   */
  triggerComponent: JSX.Element
  /**
   *
   */
  stateCallback?: (state: boolean) => void
}

/**
 *
 * @param props Component Props
 * @returns TSX
 */
function Menu(props: MenuProps) {
  const { options, triggerComponent, stateCallback } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
    stateCallback?.(false)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    stateCallback?.(true)
  }

  const TriggerComponent = React.cloneElement(triggerComponent, {
    onClick: handleClick
  })

  return (
    <>
      {TriggerComponent}

      <MUIMenu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          role: 'listbox'
        }}
      >
        {options.map(({ label, onClick, icon }) => (
          <MenuItemStyled
            key={label}
            onClick={() => {
              onClick()
              handleClose()
            }}
          >
            {icon && (
              <ListItemIcon>
                <Icon family="svg" name={icon} />
              </ListItemIcon>
            )}
            <ListItemText>{label}</ListItemText>
          </MenuItemStyled>
        ))}
      </MUIMenu>
    </>
  )
}

export default Menu

export { Menu }
