import React from 'react'

import { TextField, TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'
import RInputMask, { Props as RInputMaskProps } from 'react-input-mask'

import ErrorMessage from '../ErrorMessage/ErrorMessage'

/**
 *
 */
export type InputMaskProps = {
  /**
   *
   */
  name: string
  /**
   *
   */
  maskChanger?: (value: string) => string
} & Partial<
  Pick<
    RInputMaskProps,
    'mask' | 'alwaysShowMask' | 'maskPlaceholder' | 'onBlur' | 'disabled'
  >
> &
  Pick<TextFieldProps, 'helperText' | 'fullWidth' | 'label' | 'margin'>

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function InputMask(props: InputMaskProps) {
  const { maskChanger, mask, ...restProps } = props
  return (
    <Controller
      name={props.name}
      render={({ field, fieldState }) => {
        const { error } = fieldState

        const {
          alwaysShowMask,
          maskPlaceholder,
          fullWidth,
          onBlur,
          ...mergedProps
        } = {
          ...restProps,
          ...field
        }

        const inputMaskProps = {
          alwaysShowMask,
          maskPlaceholder,
          onBlur
        }

        const textFieldProps = {
          fullWidth
        }

        return (
          <RInputMask
            {...mergedProps}
            {...inputMaskProps}
            mask={maskChanger?.(mergedProps.value) ?? mask ?? '9'}
            onBlur={(e) => {
              props.onBlur?.(e)
              onBlur?.()
            }}
          >
            <TextField
              {...mergedProps}
              {...textFieldProps}
              error={!!error}
              helperText={
                error?.message ? (
                  <ErrorMessage error={error} />
                ) : (
                  props.helperText
                )
              }
            />
          </RInputMask>
        )
      }}
    />
  )
}

export default InputMask
export { InputMask }
