import { useGetUserRoles } from '@volvo-apps/shared/core/hooks/role/useGetUserRoles'

import React from 'react'

import { Select } from '../Select'
import { SelectProps } from './types'

type RolesSelectProps = SelectProps & {
  appendOptions?: { label: string; value: string | number }[]
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function RolesSelect(props: RolesSelectProps) {
  const {
    name,
    label,
    margin,
    fullWidth,
    sx,
    labelAsValue,
    appendOptions = [],
    disabled
  } = props

  const { roles } = useGetUserRoles()

  const rolesOptions = React.useMemo(
    () =>
      roles?.map((role) => ({
        label: role.name,
        value: labelAsValue ? role.name : role.id
      })) ?? [],
    [roles, labelAsValue]
  )

  const options = [...appendOptions, ...rolesOptions]

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={options ?? []}
      sx={sx}
      disabled={disabled}
    />
  )
}

export default RolesSelect
export { RolesSelect }
