import * as React from 'react'
import { useMemo } from 'react'

import { Box } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import { Link } from 'components'
import { DataGrid } from 'components/DataGrid'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import DataGridDeleteButton from './DataGridDeleteButton'
import { DataGridInactiveSwitch } from './DataGridInactiveSwitch'
import { CrudDataGridProps } from './types'

/**
 * CrudDataGrid
 *
 * @param props CrudDataGridProps
 * @returns TSX
 */
function CrudDataGrid<T>(props: CrudDataGridProps<T>) {
  const actionsColor = Colors.light.interactActive
  const actionsSize = 14

  const { t } = useLanguage()

  const {
    columns,
    rows,
    boxSx,
    loading,
    hideFooter,
    type = 'default',
    handleDelete,
    linkEditProps,
    handleToggle,
    getRowId,
    deleteModalConfig,
    actionsConfig,
    toogleConfig,
    customActions
  } = props

  const actions = useMemo<GridColDef[]>(() => {
    const actionsColumns: GridColDef[] = []

    if (handleDelete || linkEditProps || handleToggle) {
      const action: GridColDef = {
        field: 'actions',
        headerName: actionsConfig?.headerName
          ? t(actionsConfig.headerName) || ''
          : '',
        sortable: false,
        minWidth: 70,
        align: 'right',
        flex: 1,
        ...actionsConfig,
        renderCell: (params: GridRenderCellParams) => {
          const id = params.id || (params.row.id as number)
          const propertyToToggle = toogleConfig?.property || 'active'

          const state = linkEditProps?.stateProperty
            ? { [linkEditProps.stateProperty]: params.row }
            : undefined
          return (
            <>
              {handleDelete && (
                <DataGridDeleteButton
                  handleDelete={() => handleDelete(params.row)}
                  color={actionsColor}
                  size={actionsSize}
                  {...deleteModalConfig}
                  showModal={deleteModalConfig?.enable}
                />
              )}
              {linkEditProps && (
                <Link
                  fontSize={14}
                  to={linkEditProps.to(id.toString())}
                  color={Colors.light.interactActive}
                  sx={{ pr: 2 }}
                  state={state}
                >
                  {t('general.edit')}
                </Link>
              )}
              {handleToggle && (
                <DataGridInactiveSwitch
                  checked={params.row[propertyToToggle]}
                  handleToggle={(checked) => handleToggle(params.row, checked)}
                />
              )}
            </>
          )
        }
      }

      actionsColumns.push(action)
    }

    if (customActions && customActions.length > 0) {
      actionsColumns.push(...customActions)
    }

    return actionsColumns
  }, [
    actionsColor,
    actionsConfig,
    customActions,
    deleteModalConfig,
    handleDelete,
    handleToggle,
    linkEditProps,
    t,
    toogleConfig?.property
  ])

  const needActions = actions.length > 0
  const columnsWithActions = [...columns, ...(needActions ? actions : [])]

  return (
    <Box sx={boxSx || { width: '100%' }}>
      <DataGrid
        loading={loading}
        columns={columnsWithActions}
        rows={rows}
        hideFooter={hideFooter}
        type={type}
        getRowId={getRowId}
      />
    </Box>
  )
}

export default CrudDataGrid
export { CrudDataGrid }
