import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import { NpsList, Menu, NpsCreate, NpsUpdate } from 'pages/cms/Survey'
import { CmsContent } from 'pages/layout/CmsContent'
import { RoleEnum } from 'types/roles'

import { SurveyRoutesEnum } from './routesEnums'

const surveyRoutes: CustomRouteProps[] = [
  {
    path: SurveyRoutesEnum.SURVEY_MENU,
    protected: true,
    layout: CmsContent,
    element: <Menu />
  },
  {
    path: SurveyRoutesEnum.SURVEY_NPS,
    protected: true,
    layout: CmsContent,
    element: <NpsList />,
    role: RoleEnum.PESQUISAS_NPS
  },
  {
    path: SurveyRoutesEnum.SURVEY_NPS_CREATE,
    protected: true,
    layout: CmsContent,
    element: <NpsCreate />,
    role: RoleEnum.PESQUISAS_NPS
  },
  {
    path: SurveyRoutesEnum.SURVEY_NPS_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <NpsUpdate />,
    role: RoleEnum.PESQUISAS_NPS
  }
]

export default surveyRoutes
