import { getCampaign } from '@volvo-apps/shared/core/actions/Campaign'
import { getNotification } from '@volvo-apps/shared/core/actions/Notification'
import CampaignDTO from '@volvo-apps/shared/models/CampaignDTO'
import NotificationDTO from '@volvo-apps/shared/models/NotificationDTO'

import { useCallback } from 'react'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type onSuccessParams = {
  notification: NotificationDTO
  campaign: CampaignDTO
}

type useGetCmsNotificationParams = {
  onSuccess?: (params: onSuccessParams) => void
  notificationId?: number
}

/**
 *
 * @param params params
 * @returns mutate, notification, isLoading
 */
export const useGetCmsNotification = (params: useGetCmsNotificationParams) => {
  const { onSuccess, notificationId } = params

  const fetchGetNotification = useCallback(async () => {
    if (!notificationId) return
    return getNotification(notificationId).then((notification) =>
      getCampaign(notification.campaignId).then((campaign) => ({
        notification,
        campaign
      }))
    )
  }, [notificationId])

  const { data, isLoading } = useQuery('fetchGetNotification', {
    enabled: !!notificationId,
    queryFn: fetchGetNotification,
    onSuccess,
    onError: handleErrorFeedback
  })

  return { data, isLoading }
}
