import UserSimulationDTO from '@volvo-apps/shared/models/UserSimulationDTO'
import { GenericService } from './GenericService'
import _pick from 'lodash/pick'

/**
 * UserSimulationService
 */

export enum CreateUserSimulationWithoutSaveEnum {
  financialProduct = 'financialProduct',
  productLineId = 'productLineId',
  productLine = 'productLine',
  document = 'document',
  vehicleId = 'vehicleId',
  vehicleQtd = 'vehicleQtd',
  newVehicle = 'newVehicle',
  gracePeriodMonths = 'gracePeriodMonths',
  termMonths = 'termMonths',
  documentType = 'documentType',
  productValue = 'productValue',
  depositValue = 'depositValue'
}
/**
 *
 */
export type CreateUserSimulationWithoutSaveType = Pick<
  UserSimulationDTO,
  CreateUserSimulationWithoutSaveEnum
>
/**
 *
 */
export class UserSimulationService extends GenericService<UserSimulationDTO> {
  readonly URI: string = 'gateway/Simulation'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   *
   * @param data UserSimulationDTO data
   */
  public async createUserSimulation(data: UserSimulationDTO) {
    return super.post('Simulate', data) as Promise<{
      /**
       *
       */
      data: UserSimulationDTO
    }>
  }

  /**
   *
   * @param data CreateUserSimulation data
   */
  public async createUserSimulationWithoutSave(
    data: CreateUserSimulationWithoutSaveType
  ) {
    const simulationTrimmed = _pick(
      data,
      Object.keys(CreateUserSimulationWithoutSaveEnum)
    )

    return super.post('SimulateWithoutSave', simulationTrimmed) as Promise<{
      data: UserSimulationDTO
    }>
  }

  /**
   *
   * @param params Func params
   * @param params.initialDateSearch Starting date filter
   * @param params.endDateSearch Ending date filter
   */
  public async getSimulationHistory(
    params: {
      initialDateSearch?: Date
      endDateSearch?: Date
    } = {}
  ) {
    return this.post<{ data: UserSimulationDTO[] }>(
      'GetSimulationHistory',
      params
    )
  }

  /**
   *
   * @param simulationId Simulation ID
   */
  public async getSummarySimulationFile(simulationId: number) {
    return this.get<{ data: Blob }>(
      `GetSummarySimulationFile`,
      {
        simulationId
      },
      { responseType: 'blob' }
    )
  }
}
