import React from 'react'

import { Box } from '@mui/material'
import { useNavigate } from 'react-router'

import { List, ListItemProps } from 'components'
import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CmsMenuHeader } from 'components/CmsMenuHeader/CmsMenuHeader'

import { LeadsRoutesEnum } from 'routes/cms/routesEnums'

import useCmsRoles from 'hooks/cms/useCmsRoles'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function Menu() {
  const navigate = useNavigate()
  const { showMenuByPage } = useCmsRoles()

  const { t } = useLanguage()

  const menuItems: ListItemProps[] = [
    {
      primary: t('cms.leads.menu.viewAndExport'),
      onClick: () => navigate(LeadsRoutesEnum.LEADS_VIEW),
      hide: !showMenuByPage[LeadsRoutesEnum.LEADS_VIEW]
    },
    {
      primary: t('cms.leads.menu.automation'),
      onClick: () => navigate(LeadsRoutesEnum.LEADS_AUTOMATION_LIST),
      hide: !showMenuByPage[LeadsRoutesEnum.LEADS_AUTOMATION_LIST]
    }
  ]

  return (
    <CmsChildrenWithHeader>
      <CmsMenuHeader
        titleText={t('cms.leads.menu.title')}
        subtitleText={t('cms.leads.menu.subtitle')}
      />
      <Box mt={2} width={(t) => t.breakpoints.values.md}>
        <List items={menuItems} noPadding />
      </Box>
    </CmsChildrenWithHeader>
  )
}

export default Menu
export { Menu }
