import { updateNps } from '@volvo-apps/shared/core/actions/Nps'

import { useMutation } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import NpsDTO from '../../../models/NpsDTO'
import { CmsFields } from '../../service/Nps.service'

type useUpdateNpsParams = HookQueryParams<NpsDTO>

/**
 * Hook to update one nps
 *
 * @param params params
 * @returns mutate, nps, isLoading
 */
export const useUpdateNps = (params: useUpdateNpsParams) => {
  const { onSuccess, onError } = params
  const {
    mutate,
    isLoading,
    data: nps,
    mutateAsync
  } = useMutation('updateNps', {
    mutationFn: ({ nps }: { nps: NpsDTO | CmsFields }) => updateNps(nps),
    onSuccess,
    onError
  })

  return { mutate, nps, isLoading, mutateAsync }
}
