import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import Colors from 'constants/Colors'

import { addPhoneMask } from 'common/utils/masks'

/**
 *
 * @returns TSX
 */
export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'cms.leads.management.columns.id',
    minWidth: 80,
    flex: 1
  },
  {
    field: 'datasimulacao',
    headerName: 'cms.leads.management.columns.date',
    minWidth: 150,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const date = params.row.datasimulacao as Date

      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {dayjs(date).format('DD/MM/YYYY')}
        </Typography>
      )
    }
  },
  {
    field: 'horasimulacao',
    headerName: 'cms.leads.management.columns.hour',
    minWidth: 100,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const hour = params.row.horasimulacao as Date

      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {dayjs(hour).format('HH:mm')}
        </Typography>
      )
    }
  },
  {
    field: 'nome',
    headerName: 'cms.leads.management.columns.name',
    minWidth: 300,
    flex: 1
  },
  {
    field: 'telefone',
    headerName: 'cms.leads.management.columns.phone',
    minWidth: 200,
    flex: 1,

    renderCell: (params: GridRenderCellParams) => {
      const phone = (params.row.telefone as string) || ''

      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {addPhoneMask(phone)}
        </Typography>
      )
    }
  },
  {
    field: 'email',
    headerName: 'cms.leads.management.columns.email',
    minWidth: 250,
    flex: 1
  }
]
