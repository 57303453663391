import { getAllProductLines } from '@volvo-apps/shared/core/actions/ProductLine'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all product lines
 *
 * @returns ProductLines, isLoading, refetch
 */
export const useGetProductLines = () => {
  const {
    data: productLines,
    isLoading,
    refetch
  } = useQuery('productLines', {
    queryFn: getAllProductLines,
    onError: handleErrorFeedback
  })

  return { productLines, isLoading, refetch }
}
