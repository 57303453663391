import ContractDTO from '@volvo-apps/shared/models/ContractDTO'

import React from 'react'

import { Box, Typography, BoxProps } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

import { formatDate, formatUTC } from 'common/utils/date'
import { toCurrency } from 'common/utils/money'
import { useLanguage } from 'context/Language'

type TicketCardProps = {
  contract: Pick<
    ContractDTO,
    'valorParcela' | 'vencimento' | 'tipoDeProduto' | 'numeroParcela' | 'prazo'
  >
  boxProps?: BoxProps
  detailOpened?: boolean
}

/**
 *
 * @param props TicketCardProps
 * @returns TSX
 */
function TicketCard(props: TicketCardProps) {
  const { contract, boxProps, detailOpened } = props

  const { t } = useLanguage()

  const amount = toCurrency(Number(contract.valorParcela)).withSymbol

  const expiredDateUTC = formatUTC(contract.vencimento)
  const expireValue = formatDate(expiredDateUTC)
  const isExpired = new Date(expiredDateUTC) < new Date()

  const calendarIcon = detailOpened ? 'CalendarMonthOutlined' : 'CalendarMonth'
  const titleColor = detailOpened
    ? Colors.light.interactActive
    : Colors.light.blue

  const calendarIconColor = isExpired
    ? Colors.light.error
    : Colors.light.interactActive
  const dueDateLabelColor = isExpired
    ? Colors.light.error
    : Colors.light.interactActive
  const dueDateColor = isExpired
    ? Colors.light.error
    : Colors.light.greys.GREY_700

  return (
    <Box {...boxProps}>
      <Typography variant="body1" color={titleColor}>
        {t('financial.tickets.card.title', [
          contract.tipoDeProduto,
          contract.numeroParcela,
          contract.prazo
        ])}
      </Typography>
      <Typography fontSize={24} color={Colors.light.greys.GREY_700}>
        {amount}
      </Typography>
      <FlexBox mt={0.5} alignItems="center">
        <Icon
          family="mui"
          name={calendarIcon}
          sx={{ color: calendarIconColor }}
        />
        <Typography fontSize={14} color={dueDateLabelColor}>
          {t('financial.tickets.card.dueDate')}
        </Typography>
        <Typography fontSize={14} color={dueDateColor}>
          &nbsp;{expireValue}
        </Typography>
      </FlexBox>
    </Box>
  )
}

export default TicketCard
export { TicketCard }
