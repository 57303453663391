import React from 'react'

import { FormHelperText } from '@mui/material'
import { Editor } from '@tinymce/tinymce-react'
import { Controller } from 'react-hook-form'

import { purify } from 'common/utils/string'

type TextEditorProps = {
  name: string
}

/**
 *
 * @param props Props
 * @returns TSX
 */
const TextEditor = (props: TextEditorProps): JSX.Element => {
  const { name } = props

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const { error } = fieldState
        const { onChange, ...fieldProps } = field

        return (
          <>
            <Editor
              id="tinymce-editor"
              apiKey="m71uum6ga0y74zve1ta5knwgt929l96d1dm392jgq9m9avkb"
              {...fieldProps}
              onEditorChange={(newValue) => {
                onChange(purify(newValue))
              }}
              plugins={['table']}
            />
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          </>
        )
      }}
    />
  )
}

export default TextEditor
export { TextEditor }
