import React from 'react'

import { Grid, Box, Paper } from '@mui/material'

import bg from 'assets/images/bg.png'
import { ReactComponent as Logotype } from 'assets/images/Logotype.svg'

import { LayoutProps } from '../types'
import Footer from './Footer'

type SideContentProps = LayoutProps & {
  footerHidden?: boolean
}

/**
 * @param props Component Props
 * @returns JSX
 */
function SideContent(props: SideContentProps) {
  const { children, footerHidden } = props
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Logotype color="white" width="25rem" />
      </Grid>
      <Grid
        item
        xs={10}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box
          mt={4}
          mb={4}
          sx={{
            mx: 4,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          {children}
        </Box>

        {!footerHidden && (
          <Paper
            variant="outlined"
            sx={(t) => ({
              backgroundColor: t.palette.divider,
              display: 'flex',
              alignContent: 'center',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              height: '5em'
            })}
          >
            <Footer />
          </Paper>
        )}
      </Grid>
    </Grid>
  )
}

export default SideContent
export { SideContent }
