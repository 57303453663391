import { useGetUserListWithDocumentCmsById } from '@volvo-apps/shared/core/hooks/user/useGetUserListWithDocumentCmsById'
import { useUpdateUserFunction } from '@volvo-apps/shared/core/hooks/user/useUpdateUserFunction'
import RoleDTO from '@volvo-apps/shared/models/RoleDTO'
import UserWithDocumentDTO from '@volvo-apps/shared/models/UserWithDocumentDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { UserSettingsRoutesEnum } from 'routes/cms/routesEnums'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { UsersAccessData } from '../Form/schema'
import UsersAccessForm from '../Form/UsersAccessForm'
import { useUsersAccessForm } from '../Form/useUsersAccessForm'

/**
 * Product Line Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const queryClient = useQueryClient()

  const { methods } = useUsersAccessForm({
    defaultValues: {
      role: undefined,
      document: '',
      email: '',
      name: '',
      phoneNumber: ''
    }
  })

  const { user, isLoading: getUserIsLoading } =
    useGetUserListWithDocumentCmsById({
      id: Number(id),
      onError: handleErrorFeedback,
      onSuccess: (data) => {
        methods.reset({
          document: data.signUpDocument,
          email: data.email,
          name: data.name,
          phoneNumber: data.phoneNumber,
          role: data.userFunction?.id
        })
      }
    })

  const { mutate: update, isLoading } = useUpdateUserFunction({
    onSuccess: () => {
      toast.success(t('cms.usersAccess.update.success'))
      navigate(UserSettingsRoutesEnum.USERS_ACCESS_LIST)
    },
    onError: handleErrorFeedback
  })

  const onSubmit = React.useCallback(
    (formData: UsersAccessData) => {
      const userRoles: RoleDTO[] | undefined =
        queryClient.getQueryData('getAllUserRoles')

      const userFunction = userRoles?.find(
        (userRole) => userRole.id === Number(formData.role)
      )

      const newUser = new UserWithDocumentDTO().fromJSON({
        ...user,
        ...formData,
        userFunction,
        userFunctionId: userFunction?.id
      })

      update(newUser)
    },
    [queryClient, update, user]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.usersAccess.update.title')}
      </Typography>
      <UsersAccessForm
        isLoading={isLoading || getUserIsLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.usersAccess.update.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
