import React from 'react'

import { Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type StepsHeaderProps = {
  handleBack: () => void
  step: number
  title: string
  description?: string | null
}

/**
 * @param props StepsHeader Props
 * @returns TSX
 */
function StepsHeader(props: StepsHeaderProps) {
  const { handleBack, step, title, description } = props

  const { t } = useLanguage()

  return (
    <>
      <FlexBox
        mb={3}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography color={Colors.light.greys.GREY_600} fontSize={12}>
          {t('simulation.chooseFinancing.stepIndicator', [step, 2])}
        </Typography>
        <PlainButton
          variant="text"
          startIcon={<Icon family="mui" name="KeyboardBackspace" />}
          sx={{
            color: Colors.light.text
          }}
          onClick={handleBack}
        >
          {t('general.back')}
        </PlainButton>
      </FlexBox>
      <Typography variant="h6" color={Colors.light.interactActive}>
        {title}
      </Typography>
      {description && (
        <Typography variant="h6" my={2} color={Colors.light.greys.GREY_600}>
          {description}
        </Typography>
      )}
    </>
  )
}

export default StepsHeader
