import { getProfile } from '@volvo-apps/shared/core/actions/User'
import { useUserIsCms } from '@volvo-apps/shared/core/hooks/user/useUserIsCms'
import UserDTO from '@volvo-apps/shared/models/UserDTO'

import React, { createContext, PropsWithChildren, useContext } from 'react'

import { AxiosError } from 'axios'
import jwtDecode from 'jwt-decode'
import { useQuery, UseQueryResult } from 'react-query'

import { Suspense } from 'components/Suspense'

import { useAuth } from './Auth'

type IUserContext = {
  userData?: UserDTO
  refetch?: UseQueryResult<UserDTO, AxiosError>['refetch']
  isRefetching?: boolean
  roles?: string[] | string
}

const initialState: IUserContext = {}

export const userContext = createContext<IUserContext>(initialState)
const { Provider } = userContext

/**
 *
 * @param props Component Props
 * @returns JSX
 */
export const UserProvider = (props: PropsWithChildren) => {
  const { children } = props
  const { hasLogin, logout, setIsCms, token } = useAuth()

  const { userIsCms } = useUserIsCms({
    enabled: hasLogin,
    onSuccess(userIsCms) {
      setIsCms(userIsCms)
    }
  })

  const {
    data: userData,
    refetch,
    isRefetching
  } = useQuery<UserDTO, AxiosError>('user', {
    enabled: hasLogin,
    queryFn: getProfile,
    onError: (err) => {
      if (err.response?.status === 401) {
        logout()
      }
    }
  })

  const providerProps = {
    userData,
    refetch,
    isRefetching,
    roles: token
      ? (jwtDecode(token) as { role?: string[] | string }).role ?? []
      : []
  }

  return (
    <Provider value={providerProps}>
      {hasLogin && (!userData || userIsCms === undefined) ? (
        <Suspense />
      ) : (
        children
      )}
    </Provider>
  )
}

/**
 * @returns Context
 */
export const useUser = () => useContext(userContext)
