import { updateSimulationParameter } from '@volvo-apps/shared/core/actions/SimulationParameter'
import SimulationParameterDTO from '@volvo-apps/shared/models/SimulationParameterDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateSimulationParameterParams = {
  onSuccess?: () => void
}

/**
 * Hook to update one simulation parameter
 *
 * @param params params
 * @returns mutate, simulationParameter, isLoading and mutateAsync
 */
export const useUpdateSimulationParameter = (
  params: useUpdateSimulationParameterParams = {}
) => {
  const { onSuccess } = params
  const {
    mutate,
    isLoading,
    data: simulationParameter,
    mutateAsync
  } = useMutation('updateSimulationParameter', {
    mutationFn: ({
      simulationParameter
    }: {
      simulationParameter: SimulationParameterDTO
    }) => updateSimulationParameter(simulationParameter),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, simulationParameter, isLoading, mutateAsync }
}
