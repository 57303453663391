import { passwordStrength } from 'check-password-strength'
import * as Yup from 'yup'

const weakIds = [0, 1]

export const oldPasswordValidator = Yup.string().required(
  'validation.required.password'
)

export const passwordValidator = Yup.string()
  .min(8, 'validation.password.min.length')
  .test(
    'password_strength',
    'validation.password.strength.general',
    (value = '') => !weakIds.includes(passwordStrength(value).id)
  )
  .required('validation.required.password')
  .notOneOf([Yup.ref('oldPassword')], 'changePassword.changePasswordSame')

export const confirmPasswordValidator = passwordValidator.oneOf(
  [Yup.ref('password'), null],
  'validation.invalid.passwordConfirm'
)
