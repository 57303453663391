export enum RoleEnum {
  CADASTRO_BANNERS = 'CadastroBanners',
  CADASTRO_FAQ = 'CadastroFAQ',
  CADASTRO_FERIADOS = 'CadastroFeriados',
  CADASTRO_NOTIFICACOES_PUSH = 'CadastroNotificacoesPush',
  CADASTRO_RECURSOS_ENCERRADOS = 'CadastroRecursosEncerrados',
  CADASTRO_TERMOS = 'CadastroTermos',
  CADASTRO_CONTRATOS_ALUGUEL = 'CadastroContratosAluguel',
  PARAMETROS_PRODUTOS_FINANCEIROS = 'ParametrosProdutosFinanceiros',
  PARAMETROS_LINHA_PRODUTO = 'ParametrosLinhaProduto',
  PARAMETROS_SIMULACAO = 'ParametrosSimulacao',
  PARAMETROS_VEICULOS = 'ParametrosVeiculos',
  LEADS_VISUALIZAR_EXPORTAR = 'LeadsVisualizarExportar',
  LEADS_AUTOMACAO_RECEBIMENTO = 'LeadsAutomacaoRecebimento',
  USUARIO_CONFIG_CONSULTA = 'UsuarioConfigConsulta',
  USUARIO_CONFIG_ACESSO_CMS = 'UsuarioConfigAcessoCMS',
  USUARIO_CONFIG_REDEFINIR_SENHA_EMAIL = 'UsuarioConfigRedefinirSenhaEmail',
  USUARIO_CONFIG_VINCULAR = 'UsuarioConfigVincular',
  USUARIO_CONFIG_FUNCOES = 'UsuarioConfigFuncoes',
  USUARIO_CONFIG_DOMINIO = 'CadastroDominio',
  PESQUISAS_NPS = 'PesquisasNPS',
  RELATORIOS_USUARIOS = 'RelatoriosUsuarios',
  RELATORIOS_CONSULTA_USUARIO = 'RelatoriosConsultaPorDocUsuario'
}
