import FaqQuestionDTO from '@volvo-apps/shared/models/FaqQuestionDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudList } from 'components/CrudList'
import { PlainButton } from 'components/PlainButton'

import { RegistersRoutesEnum as FaqQuestionRoutesEnum } from 'routes/cms/routesEnums'

import { useCrudFaqQuestion } from 'hooks/cms/useCrudFaqQuestion'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type FaqQuestionJSON = ReturnType<FaqQuestionDTO['toJSON']>

/**
 * Faq Question List
 *
 * @returns TSX
 */
function List() {
  const { faqQuestion, updateAsyncFaqQuestion, getLoading } =
    useCrudFaqQuestion()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const items = React.useMemo(() => {
    return (
      faqQuestion?.map((item) => ({
        ...item.toJSON(),
        primary: item.title,
        id: item.id
      })) ?? []
    )
  }, [faqQuestion])

  const handleEdit = React.useCallback(
    (faqQuestion: FaqQuestionJSON) => {
      const path = FaqQuestionRoutesEnum.FAQ_QUESTIONS_UPDATE.replace(
        ':id',
        faqQuestion.id as string
      )

      navigate(path)
    },
    [navigate]
  )

  const handleToggle = React.useCallback(
    async (question: FaqQuestionJSON, checked: boolean) => {
      const newFaqQuestion = new FaqQuestionDTO().fromJSON({
        ...question,
        active: checked
      })

      const updateResponse = await updateAsyncFaqQuestion({
        faqQuestion: newFaqQuestion
      })

      toast.success(
        updateResponse.data.active
          ? t('cms.faq.questionToggle.enabled')
          : t('cms.faq.questionToggle.disabled')
      )
    },
    [t, updateAsyncFaqQuestion]
  )

  const handleCreate = React.useCallback(() => {
    navigate(FaqQuestionRoutesEnum.FAQ_QUESTIONS_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.faq.questionList.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.faq.questionList.title')}
      </Typography>

      <CrudList
        items={items}
        title={t('cms.faq.questionList.subtitle')}
        toggleKey="active"
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        getLoading={getLoading && !faqQuestion}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
