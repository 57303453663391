import React from 'react'

import { useFormContext } from 'react-hook-form'

import { Select } from 'components'
import { FlexBox } from 'components/FlexBox'

import useSearchBar from 'hooks/useSearchBar'

import { useLanguage } from 'context/Language'
import { Option } from 'types/Option'

import { FormValues } from './List'

type FiltersProps = {
  onFilter: ({ searchTerm, user }: { searchTerm: string; user: string }) => void
  users: Option[]
}

/**
 * Filters
 *
 * @param props props
 * @returns TSX
 */
function Filters(props: FiltersProps) {
  const { onFilter, users } = props
  const methods = useFormContext<FormValues>()
  const { t } = useLanguage()

  const onChangeSearchTerm = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      methods.setValue('searchTerm', e.target.value)
    },
    [methods]
  )

  const { searchBar } = useSearchBar({
    style: { margin: '24px 0 16px 0' },
    onChange: onChangeSearchTerm
  })

  const searchTerm = methods.watch('searchTerm')
  const user = methods.watch('user')

  React.useEffect(() => {
    onFilter({
      searchTerm,
      user
    })
  }, [onFilter, searchTerm, user])

  return (
    <FlexBox width="60%" alignItems="center" gap={2} mb={2}>
      {searchBar}
      <Select
        name="user"
        margin="dense"
        formSize="small"
        label={t('cms.linkUsers.list.filters.users.placeholder')}
        options={users}
        formSx={{ mt: 2 }}
      />
    </FlexBox>
  )
}

export default Filters
export { Filters }
