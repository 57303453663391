import { useForm } from 'react-hook-form'

import { ProductLineData, schema } from './schema'

type ProductLineProps = {
  defaultValues?: ProductLineData
}

/**
 *
 * @param props - ProductLineProps
 * @returns useForm methods
 */
export const useProductLineForm = (props: ProductLineProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<ProductLineData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
