import React from 'react'

import { Modal, useModalMethods } from 'components/Modal'

import { useLanguage } from 'context/Language'

import { TermsAndConditions } from './TermsAndConditions'

type TermsAndConditionsModalProps = {
  onClick?: () => void
  triggerComponent: JSX.Element
}

/**
 *
 * @param props TermsAndConditionsModalProps
 * @returns TSX
 */
function TermsAndConditionsModal(props: TermsAndConditionsModalProps) {
  const { onClick, triggerComponent } = props
  const { open, toggleOpen, setClosed } = useModalMethods()

  const { t } = useLanguage()

  const TriggerComponent = React.cloneElement(triggerComponent, {
    onClick: toggleOpen
  })

  return (
    <>
      {TriggerComponent}
      <Modal
        title={t('general.termsAndConditions')}
        PaperProps={{
          style: {
            maxWidth: 'fit-content'
          }
        }}
        content={<TermsAndConditions />}
        actions={[
          {
            children: t('general.back'),
            onClick: () => {
              onClick?.()
              setClosed()
            },
            color: 'success'
          }
        ]}
        onClose={setClosed}
        open={open}
      />
    </>
  )
}

export default TermsAndConditionsModal
export { TermsAndConditionsModal }
