// --------------------------------------------------------
// CONFIRM_DATA - ConfirmData
// --------------------------------------------------------

// --------------------------------------------------------
// GENERAL
// --------------------------------------------------------
import { verifyDocument } from '@volvo-apps/shared/core/actions/User'

import React from 'react'
// --------------------------------------------------------

// --------------------------------------------------------
// COMPONENTS
// --------------------------------------------------------
import GenericConfirmation from '..'

import ReactMarkdown from 'react-markdown'
// --------------------------------------------------------

// --------------------------------------------------------
// STYLING
// --------------------------------------------------------
// import classNames from 'classnames'
// import styles from './styles.module.css'
// --------------------------------------------------------

// --------------------------------------------------------
// CONSTANTS
// --------------------------------------------------------
// --------------------------------------------------------

// --------------------------------------------------------
// HOOKS
// --------------------------------------------------------
// --------------------------------------------------------

// @TODO: add i18n later
const i18nTitle = 'Seus dados'
const content = (
  <ReactMarkdown>
    Achamos seu CNPJ no nosso banco de dados. O **Banco Volvo** irá acessar seus
    dados de contrato.
  </ReactMarkdown>
)
const contentDeny = <ReactMarkdown>O registro foi negado.</ReactMarkdown>

const params = ['userid', 'emailVerificationCode']

type ConfirmDataProps = {
  confirm: boolean
}

/**
 * A component that renders a data confirmation.
 *
 * @param props Component Props
 * @returns The component JSX.
 */
function ConfirmData(props: ConfirmDataProps): JSX.Element {
  const { confirm } = props
  async function check(cbParams: Record<string, string>): Promise<boolean> {
    const doc = String(cbParams[params[0]])
    const code = String(cbParams[params[1]])

    return await verifyDocument(doc, code, confirm)
  }
  // --------------------------------------------------------
  // REACT RETURN FUNCTION
  // --------------------------------------------------------
  return (
    <GenericConfirmation
      title={i18nTitle}
      paramNames={params}
      checkFunction={check}
    >
      {confirm ? <p>{content}</p> : <p>{contentDeny}</p>}
    </GenericConfirmation>
  )
  // --------------------------------------------------------
}

export default ConfirmData
