export const Phone = {
  phone: '(99) 9999-99999',
  cellphone: '(99) 99999-9999'
}

export const Document = {
  onlyCpf: '999.999.999-99',
  cpf: '999.999.999-999', // used when the value can be cpf or cnpj
  cnpj: '99.999.999/9999-99'
}
