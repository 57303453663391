import React, { ReactNode, useState } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton
} from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'
import { PlainButton, PlainButtonProps } from 'components/PlainButton'

type Props = Pick<DialogProps, 'open' | 'onClose' | 'PaperProps'> & {
  title?: ReactNode
  subtitle?: string | null
  content?: ReactNode
  actions?: PlainButtonProps[]
  actionsGap?: number
  testId?: string
  showCloseButton?: boolean
  actionsDirection?: 'row' | 'column'
  disableBackdropClick?: boolean
  onScroll?: () => void
  dialogContentSx?: DialogContentProps['sx']
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
const Modal = React.forwardRef((props: Props, contentRef) => {
  const {
    open,
    onClose,
    title,
    subtitle,
    content,
    actions,
    PaperProps,
    testId,
    showCloseButton,
    actionsDirection = 'row',
    actionsGap,
    disableBackdropClick,
    onScroll,
    dialogContentSx
  } = props

  return (
    <Dialog
      open={open}
      onClose={disableBackdropClick ? undefined : onClose}
      PaperProps={PaperProps}
      data-testid={testId}
    >
      <DialogTitle>
        <FlexBox justifyContent="space-between" alignItems="center">
          {title}
          {showCloseButton && (
            <IconButton
              sx={{ p: 0.25 }}
              onClick={() => onClose?.({}, 'escapeKeyDown')}
            >
              <Icon family="mui" name="CloseOutlined" />
            </IconButton>
          )}
        </FlexBox>
      </DialogTitle>
      <DialogContent ref={contentRef} onScroll={onScroll} sx={dialogContentSx}>
        {subtitle ? <DialogContentText>{subtitle}</DialogContentText> : content}
      </DialogContent>
      {!!actions?.length && (
        <DialogActions>
          <FlexBox
            flexDirection={actionsDirection}
            px={2}
            width="100%"
            justifyContent="flex-end"
            alignItems="flex-end"
            gap={actionsGap}
          >
            {actions?.map((btn, index) => (
              <PlainButton key={index} {...btn} />
            ))}
          </FlexBox>
        </DialogActions>
      )}
    </Dialog>
  )
})

Modal.displayName = 'Modal'

/**
 * @returns Modal methods
 */
const useModalMethods = () => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen((o) => !o)

  const setClosed = () => setOpen(false)

  return {
    open,
    setOpen,
    setClosed,
    toggleOpen
  }
}

export default Modal
export { Modal, useModalMethods }
