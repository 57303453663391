import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import Colors from 'constants/Colors'

import { conditionalFormat } from 'common/utils/documents'

/**
 *
 * @returns TSX
 */
export const documentsColumns: GridColDef[] = [
  {
    field: 'document',
    headerName: 'cms.reports.userSearch.documentsTable.columns.number',
    minWidth: 280,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {conditionalFormat(params.row.document)}
        </Typography>
      )
    }
  },
  {
    field: 'creationDate',
    headerName:
      'cms.reports.userSearch.documentsTable.columns.registrationDate',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const creationDate = params.row.creationDate as Date | undefined

      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {creationDate
            ? dayjs(creationDate).format('DD/MM/YYYY [às] HH:mm[h]')
            : ''}
        </Typography>
      )
    }
  },
  {
    field: 'validationDate',
    headerName: 'cms.reports.userSearch.documentsTable.columns.validationDate',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const updatedDate = params.row.validationDate as Date | undefined

      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {updatedDate
            ? dayjs(updatedDate).format('DD/MM/YYYY [às] HH:mm[h]')
            : ''}
        </Typography>
      )
    }
  }
]

export const deletedDocumentsColumns: GridColDef[] = [
  {
    field: 'deleteDatetime',
    headerName:
      'cms.reports.userSearch.deletedDocumentsTable.columns.exclusionDate',
    minWidth: 280,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const deleteDatetime = params.row.deleteDatetime as Date | undefined

      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {deleteDatetime
            ? dayjs(deleteDatetime).format('DD/MM/YYYY [às] HH:mm[h]')
            : ''}
        </Typography>
      )
    }
  },
  {
    field: 'deleteReason',
    headerName: 'cms.reports.userSearch.deletedDocumentsTable.columns.reason',
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const deleteReason = params.row.deleteReason as string | undefined

      return (
        <Typography
          fontSize={16}
          color={Colors.light.text}
          style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
        >
          {deleteReason}
        </Typography>
      )
    }
  }
]
