import React from 'react'

import { Box, FormControlLabel, Switch } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'

import { Form } from 'components'
import QuarterSelect from 'components/Form/Fields/QuarterSelect'
import VerifiedDocumentsSelect from 'components/Form/Fields/VerifiedDocumentsSelect'
import YearSelect from 'components/Form/Fields/YearSelect'
import { PlainButton } from 'components/PlainButton'

import { getDocumentType } from 'common/utils/documents'
import { useLanguage } from 'context/Language'

import { ReactComponent as Import } from '../../assets/icons/import.svg'
import { IncomeReportFormData } from './schema'

type IncomeReportFormProps = {
  onSubmit: (formData: IncomeReportFormData) => void
  isLoading: boolean
  methods: UseFormReturn<IncomeReportFormData, unknown>
  submitButtonLabel?: string | null
}

/**
 * @param props Props
 * @returns TSX
 */
function IncomeReportForm(props: IncomeReportFormProps) {
  const { onSubmit, isLoading, methods, submitButtonLabel } = props

  const { t } = useLanguage()
  const enableQuarter = methods.watch('enableQuarter')
  const period = methods.watch('period')

  const disabled = React.useMemo(
    () =>
      !methods.formState.isValid ||
      !methods.formState.isDirty ||
      (enableQuarter && !period),
    [
      methods.formState.isDirty,
      methods.formState.isValid,
      enableQuarter,
      period
    ]
  )

  const document = methods.watch('document')

  const isCnpj = React.useMemo(
    () => getDocumentType(document) === 'CNPJ',
    [document]
  )

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.md,
          width: '100%'
        })
      }}
    >
      <VerifiedDocumentsSelect
        name="document"
        margin="normal"
        fullWidth
        label={t('financial.incomeReports.documentNumber')}
        sx={{ width: '40%' }}
      />
      <YearSelect
        name="year"
        margin="normal"
        fullWidth
        valueAsYear
        label={t('financial.incomeReports.year')}
        sx={{ width: '40%' }}
      />

      {isCnpj && (
        <>
          <FormControlLabel
            control={
              <Controller
                name="enableQuarter"
                control={methods.control}
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(_, checked) => field.onChange(checked)}
                  />
                )}
              />
            }
            label={t('financial.incomeReports.quarterYear')}
          />
          {enableQuarter && (
            <QuarterSelect
              name="period"
              margin="normal"
              fullWidth
              label={t('financial.incomeReports.quarter')}
              sx={{ width: '40%' }}
            />
          )}
        </>
      )}
      <Box style={{ marginTop: '10px' }}>
        <PlainButton
          startIcon={<Import />}
          type="submit"
          fullWidth
          variant="contained"
          disabled={disabled}
          loading={isLoading}
          sx={{
            maxWidth: '40%',
            p: 0,
            m: 0,
            fontFamily: 'Volvo-Novum'
          }}
        >
          {submitButtonLabel}
        </PlainButton>
      </Box>
    </Form>
  )
}

export default IncomeReportForm
export { IncomeReportForm }
