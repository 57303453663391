import DocumentDTO from '@volvo-apps/shared/models/DocumentDTO'
import UserDTO from '@volvo-apps/shared/models/UserDTO'

import React from 'react'

import { Typography, BoxProps, Chip } from '@mui/material'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { conditionalFormat } from 'common/utils/documents'
import { useLanguage } from 'context/Language'

import DeleteDocumentButton from './Modals/DeleteDocumentButton'

type AccordionHeaderProps = {
  document: DocumentDTO
  user: UserDTO
  boxProps?: BoxProps
}

/**
 *
 * @param props AccordionHeaderProps
 * @returns TSX
 */
function AccordionHeader(props: AccordionHeaderProps) {
  const { document, user, boxProps } = props

  const { t } = useLanguage()

  return (
    <FlexBox {...boxProps} justifyContent="space-between" marginRight={3}>
      <FlexBox flexDirection="row" columnGap={4} alignItems="center">
        <Typography
          fontSize={16}
          color={Colors.light.text}
          style={{
            userSelect: 'text'
          }}
        >
          {conditionalFormat(document.document)}
        </Typography>

        <Chip
          label={
            document.verified
              ? t('configurations.contracts.status.authorized')
              : t('configurations.contracts.status.awaiting')
          }
          sx={{
            padding: '12px 10px !important',
            backgroundColor: document.verified
              ? Colors.light.success
              : Colors.light.warning,
            color: Colors.light.background,
            height: 26,
            fontSize: 12
          }}
        />
      </FlexBox>

      <DeleteDocumentButton user={user} documentDTO={document} />
    </FlexBox>
  )
}

export default AccordionHeader
export { AccordionHeader }
