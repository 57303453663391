import {
  useGetUsersListToResetPasswordCms,
  useResendUserRegistrationMailMutation,
  useResetUserPasswordMutation
} from '@volvo-apps/shared/core/hooks/user/useGetUsersListToResetPasswordCms'
import UserWithDocumentDTO from '@volvo-apps/shared/models/UserWithDocumentDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'

import useSearchBar from 'hooks/useSearchBar'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { ActionWithTimeoutTemplate } from './ActionWithTimeoutTemplate'
import { tableColumns } from './tableColumns'

const getFilteredItems = (
  items: UserWithDocumentDTO[] | undefined,
  keyword: string
): UserWithDocumentDTO[] => {
  const parsedKeyWord = keyword
    .toLowerCase()
    // replace chars by their unaccented version
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    // grab only alphanumeric chars
    .replace(/[^a-zA-Z0-9]/g, '')

  return (items || []).filter((item) => {
    if (!parsedKeyWord) {
      return true
    }

    const a =
      item.name.toLowerCase().replaceAll(' ', '').indexOf(parsedKeyWord) !== -1
    const b =
      item.signUpDocument &&
      item.signUpDocument.toLowerCase().indexOf(parsedKeyWord) !== -1

    return a || b
  })
}

/**
 * Reset Email and Password List
 *
 * @returns TSX
 */
function List() {
  const { t } = useLanguage()
  const {
    userList,
    isLoading: isLoadingQuery,
    isFetching: isFetchingQuery
  } = useGetUsersListToResetPasswordCms({
    onError: handleErrorFeedback
  })

  const {
    mutate: resetUserPasswordMutation,
    isLoading: isLoadingResetUserPasswordMutation
  } = useResetUserPasswordMutation({
    onError: handleErrorFeedback
  })

  const {
    mutate: resendUserRegistrationMailMutation,
    isLoading: isLoadingResendUserRegistrationMailMutation
  } = useResendUserRegistrationMailMutation({
    onError: handleErrorFeedback
  })

  const listIsLoading = isLoadingQuery || isFetchingQuery

  const isLoading =
    listIsLoading ||
    isLoadingResetUserPasswordMutation ||
    isLoadingResendUserRegistrationMailMutation

  const { searchTerm, searchBar } = useSearchBar({
    style: { margin: '24px 0 16px 0', width: 600 }
  })

  return (
    <CmsChildrenWithHeader>
      <Typography
        my={4}
        fontSize={20}
        color={Colors.light.greys.GREY_600}
        fontWeight="500"
        fontFamily="Volvo-Novum-Medium"
      >
        {t('cms.resetEmailAndPassword.title')}
      </Typography>
      {searchBar}
      <CrudDataGrid
        getRowId={(row) => row.userId}
        columns={tableColumns}
        rows={getFilteredItems(userList, searchTerm)}
        loading={isLoading}
        customActions={[
          {
            field: 'resetPasswordButton',
            headerName: '',
            sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 350,
            renderCell: (params: GridRenderCellParams) => {
              let resetPasswordTime: Date | undefined

              if (params.row.resetPasswordTime) {
                resetPasswordTime = new Date(params.row.resetPasswordTime)

                resetPasswordTime.setMinutes(
                  resetPasswordTime.getMinutes() + 30
                )
              }

              return (
                <ActionWithTimeoutTemplate
                  buttonLabel={t(
                    'cms.resetEmailAndPassword.resetPasswordButton'
                  )}
                  buttonTooltipText={t(
                    'cms.resetEmailAndPassword.resetPasswordButtonTooltip'
                  )}
                  buttonOnClick={
                    params.row.signUpDocument
                      ? () =>
                          resetUserPasswordMutation(
                            params.row.signUpDocument as string
                          )
                      : undefined
                  }
                  isLoading={listIsLoading}
                  timeout={resetPasswordTime}
                />
              )
            }
          },
          {
            field: 'resendRegisterEmail',
            headerName: '',
            sortable: false,
            align: 'center',
            flex: 1,
            minWidth: 350,
            renderCell: (params: GridRenderCellParams) => {
              let resendEmailTime: Date | undefined

              if (params.row.resendEmailTime) {
                resendEmailTime = new Date(params.row.resendEmailTime)

                resendEmailTime.setHours(resendEmailTime.getHours() + 24)
              }

              return (
                <ActionWithTimeoutTemplate
                  buttonLabel={t(
                    'cms.resetEmailAndPassword.resendRegisterEmail'
                  )}
                  buttonTooltipText={t(
                    'cms.resetEmailAndPassword.resendRegisterEmailTooltip'
                  )}
                  buttonOnClick={
                    params.row.signUpDocument
                      ? () =>
                          resendUserRegistrationMailMutation(
                            params.row.signUpDocument as string
                          )
                      : undefined
                  }
                  isLoading={listIsLoading}
                  timeout={resendEmailTime}
                />
              )
            }
          }
        ]}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
