import { updateHoliday } from '@volvo-apps/shared/core/actions/Holiday'
import HolidayDTO from '@volvo-apps/shared/models/HolidayDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateHolidayParams = {
  onSuccess?: () => void
}

/**
 * Hook to update one holiday
 *
 * @param params params
 * @returns mutate, holiday, isLoading and mutateAsync
 */
export const useUpdateHoliday = (params: useUpdateHolidayParams = {}) => {
  const { onSuccess } = params
  const {
    mutate,
    isLoading,
    data: holiday,
    mutateAsync
  } = useMutation('updateHoliday', {
    mutationFn: ({ holiday }: { holiday: HolidayDTO }) =>
      updateHoliday(holiday),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, holiday, isLoading, mutateAsync }
}
