import UserDomainDTO from '../../models/UserDomainDTO'
import { UserDomainService } from '../service/UserDomains.service'

/**
 * @returns UserDomainDTO[]
 */
export async function getUserDomains() {
  const service = new UserDomainService()
  const response = await service.getUserDomains()
  return response.data.map((domain) => new UserDomainDTO().fromJSON(domain))
}

/**
 * @param domain The domain to add
 */
export async function addUserDomain(domain: UserDomainDTO) {
  const service = new UserDomainService()
  const response = await service.addUserDomain(domain)
  return new UserDomainDTO().fromJSON(response.data)
}

/**
 * @param id The id of the domain
 */
export async function deleteUserDomain(id: number) {
  const service = new UserDomainService()
  const response = await service.deleteUserDomain(id)
  return response.data
}
