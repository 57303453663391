import 'reflect-metadata'
import { jsonProperty, Serializable, jsonName } from 'ts-serializable'

/**
 * ScrDTO
 */
export class ScrDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedDate!: string

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('authorizationDate')
  @jsonProperty(String)
  public authorizationDate!: string

  @jsonName('authorizationTime')
  @jsonProperty(String)
  public authorizationTime!: string

  @jsonName('userDocument')
  @jsonProperty(String)
  public userDocument!: string

  @jsonName('clientName')
  @jsonProperty(String)
  public clientName!: string

  @jsonName('geoLocation')
  @jsonProperty(String)
  public geoLocation!: string
}

/**
 * ScrUserDTO
 */
class ScrUserDTO extends Serializable {
  @jsonName('sCRResponseList')
  @jsonProperty(Array)
  public sCRResponseList!: ScrDTO[]
}

export default ScrUserDTO
