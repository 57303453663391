import React from 'react'

import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Form } from 'components'
import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { DataGrid } from 'components/DataGrid'
import { PlainButton } from 'components/PlainButton'

import { useExportLeads } from 'hooks/leads/useExportLeads'
import { useGetLeads } from 'hooks/leads/useGetAll'

import Colors from 'constants/Colors'

import { formatDate } from 'common/utils/date'
import { blobToURL, download } from 'common/utils/download'
import { useLanguage } from 'context/Language'

import { Filters } from './Filters'
import { columns } from './tableColumns'

/**
 *
 */
export type FormValues = {
  /**
   *
   */
  startDate: Date
  /**
   *
   */
  endDate: Date
}

/**
 * List
 *
 * @returns TSX
 */
function List() {
  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      startDate: new Date(),
      endDate: new Date()
    }
  })

  const { leads, isLoading, mutate } = useGetLeads()

  const { t } = useLanguage()

  const { mutateAsync: exportLeadsMutateAsync, isLoading: isLoadingExport } =
    useExportLeads()

  const exportLeads = React.useCallback(async () => {
    const { startDate, endDate } = methods.getValues()
    const leads = await exportLeadsMutateAsync({
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    })

    const url = blobToURL(leads, 'text/csv;charset=utf-8')

    download(url, 'leads.csv')
  }, [exportLeadsMutateAsync, methods])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton
        variant="contained"
        onClick={exportLeads}
        loading={isLoadingExport}
      >
        {t('cms.leads.management.export')}
      </PlainButton>
    )
  }, [exportLeads, isLoadingExport, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.leads.management.title')}
      </Typography>
      <Form methods={methods}>
        <Filters onFilter={mutate} />
      </Form>
      <DataGrid
        columns={columns}
        rows={leads || []}
        loading={isLoading}
        type="striped"
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
