import { AxiosResponse } from 'axios'
import TransitionIntervalDTO from '@volvo-apps/shared/models/TransitionIntervalDTO'
import VehicleDTO from '@volvo-apps/shared/models/VehicleDTO'
import VehicleImagesDTO from '@volvo-apps/shared/models/VehicleImagesDTO'
import { GenericService } from './GenericService'

/**
 * VehicleService
 */
export class VehicleService extends GenericService<VehicleDTO> {
  readonly URI: string = 'gateway/Vehicle'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all vehicles
   */
  public async getAll() {
    // TODO: Fix type
    return super.get('GetAll') as unknown as { data: VehicleDTO[] }
  }

  /**
   *
   * @param id vehicle id
   */
  public async getUnique(id: number) {
    return super.get(`Get?id=${id}`) as unknown as { data: VehicleDTO }
  }

  /**
   *
   * @param vehicleId vehicle id
   */
  public async getImageByVehicleId(vehicleId: VehicleDTO['id']) {
    return super.get<{ data: VehicleImagesDTO[] }>('GetImageByVehicleId', {
      id: vehicleId
    })
  }

  /**
   * Get all transition interval
   */
  public async getAllTransitionInterval() {
    return super.get<AxiosResponse<TransitionIntervalDTO[]>>(
      'GetAllTransitionInterval'
    )
  }

  /**
   * update transition interval
   *
   * @param payload  TransitionIntervalDTO
   */
  public async updateTransitionInterval(
    payload: Pick<TransitionIntervalDTO, 'id' | 'transitionTime'>
  ) {
    return super.put<AxiosResponse<TransitionIntervalDTO[]>>(
      'UpdateTransitionInterval',
      {
        ...payload
      }
    )
  }

  /**
   * Create Vehicle
   *
   * @param vehicle Vehicle
   */
  public async createVehicle(vehicle: VehicleDTO) {
    return super.post<AxiosResponse<VehicleDTO>>('Add', {
      ...vehicle
    })
  }

  /**
   * Update Vehicle
   *
   * @param vehicle Vehicle
   */
  public async updateVehicle(vehicle: VehicleDTO) {
    return super.put<AxiosResponse<VehicleDTO>>('Update', {
      ...vehicle
    })
  }

  /**
   * Get All Cms Vehicles
   *
   */
  public async getAllCms() {
    return super.get<AxiosResponse<VehicleDTO[]>>('GetAllCms')
  }
}
