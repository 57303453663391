import UserDTO from '@volvo-apps/shared/models/UserDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useAuth } from 'context'

import { Modal } from 'components'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type ChangePasswordSuccessProps = {
  user: UserDTO
  setClosed: () => void
}

/**
 * New Document Button
 *
 * @param props ChangePasswordSuccessProps
 * @returns TSX
 */
const ChangePasswordSuccess: React.FC<ChangePasswordSuccessProps> = (props) => {
  const { logout } = useAuth()

  const { t } = useLanguage()

  return (
    <Modal
      open={true}
      showCloseButton
      disableBackdropClick
      title={
        <Typography fontSize={32} color={Colors.light.interactDefault}>
          {t('profile.menu.changePassword.modal.title')}
        </Typography>
      }
      subtitle={
        t('profile.menu.changePassword.modal.subtitle', [
          props.user.email ?? ''
        ]) as string
      }
      PaperProps={{ sx: { p: 1, borderRadius: 2.5 } }}
      actionsDirection="column"
      actionsGap={1}
      actions={[
        {
          children: t('profile.menu.changePassword.modal.confirm'),
          onClick: props.setClosed,
          sx: {
            width: '100%'
          },
          variant: 'contained'
        }
      ]}
      onClose={logout}
    />
  )
}

export default ChangePasswordSuccess
export { ChangePasswordSuccess }
