import {
  useGetImportRentalContractsHistory,
  useDeleteImportRentalContract
} from '@volvo-apps/shared/core/hooks/sales'

import React from 'react'

import { Typography } from '@mui/material'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'
import { PlainButton } from 'components/PlainButton'

import { RegistersRoutesEnum } from 'routes/cms/routesEnums'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { columns } from './tableColumns'

/**
 * Rental Contracts List
 *
 * @returns TSX
 */
function List() {
  const { history, isLoading } = useGetImportRentalContractsHistory({
    onError: handleErrorFeedback
  })

  const navigate = useNavigate()
  const { t } = useLanguage()

  const queryClient = useQueryClient()

  const { mutate } = useDeleteImportRentalContract({
    onSuccess: async () => {
      toast.success(t('cms.rentalContracts.delete.success'))
      await queryClient.invalidateQueries({
        queryKey: ['importRentalContractsHistory']
      })
    },
    onError: handleErrorFeedback
  })

  const handleDelete = React.useCallback(
    (rentalContracts: Record<string, unknown>) => {
      mutate({ id: rentalContracts.id as number })
    },
    [mutate]
  )

  const handleCreate = React.useCallback(() => {
    navigate(RegistersRoutesEnum.RENTAL_CONTRACTS_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.rentalContracts.form.register')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.rentalContracts.form.title')}
      </Typography>
      <CrudDataGrid
        columns={columns}
        rows={history || []}
        loading={isLoading}
        handleDelete={handleDelete}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
