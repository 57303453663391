import { updateUserRole } from '@volvo-apps/shared/core/actions/UserRoles'
import RoleDTO from '@volvo-apps/shared/models/RoleDTO'

import { useMutation } from 'react-query'

type useUpdateUserRoleParams = {
  onSuccess?: () => void
  onError?: (error: Error) => void
}

/**
 * Hook to update one role
 *
 * @param params params
 * @returns mutate, roles, isLoading and mutateAsync
 */
export const useUpdateUserRole = (params: useUpdateUserRoleParams = {}) => {
  const { onSuccess, onError } = params
  const {
    mutate,
    isLoading,
    data: role,
    mutateAsync
  } = useMutation('updateUserRole', {
    mutationFn: ({ role }: { role: RoleDTO }) => updateUserRole(role),
    onSuccess,
    onError
  })

  return { mutate, role, isLoading, mutateAsync }
}
