import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import { LeadsList, List, Menu, Create, Update } from 'pages/cms/Leads'
import { CmsContent } from 'pages/layout/CmsContent'
import { RoleEnum } from 'types/roles'

import { LeadsRoutesEnum } from './routesEnums'

const leadsRoutes: CustomRouteProps[] = [
  {
    path: LeadsRoutesEnum.LEADS_MENU,
    protected: true,
    layout: CmsContent,
    element: <Menu />
  },
  {
    path: LeadsRoutesEnum.LEADS_VIEW,
    protected: true,
    layout: CmsContent,
    element: <LeadsList />,
    role: RoleEnum.LEADS_VISUALIZAR_EXPORTAR
  },
  {
    path: LeadsRoutesEnum.LEADS_AUTOMATION_LIST,
    protected: true,
    layout: CmsContent,
    element: <List />,
    role: RoleEnum.LEADS_AUTOMACAO_RECEBIMENTO
  },
  {
    path: LeadsRoutesEnum.LEADS_AUTOMATION_CREATE,
    protected: true,
    layout: CmsContent,
    element: <Create />,
    role: RoleEnum.LEADS_AUTOMACAO_RECEBIMENTO
  },
  {
    path: LeadsRoutesEnum.LEADS_AUTOMATION_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <Update />,
    role: RoleEnum.LEADS_AUTOMACAO_RECEBIMENTO
  }
]

export default leadsRoutes
