import { NavBarProps } from 'components'

import { getCrudBreadCrumbs } from 'common/utils/breadCrumbs'

import {
  LeadsRoutesEnum,
  ParametersRoutesEnum,
  RegistersRoutesEnum,
  ReportsRoutesEnum,
  SurveyRoutesEnum,
  UserSettingsRoutesEnum
} from './routesEnums'

const holidayBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: RegistersRoutesEnum.REGISTERS,
  list: RegistersRoutesEnum.HOLIDAYS,
  create: RegistersRoutesEnum.HOLIDAYS_CREATE,
  update: RegistersRoutesEnum.HOLIDAYS_UPDATE,
  translationKey: 'holidays',
  mainMenuTranslationKey: 'registers'
})

const financialProductBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: ParametersRoutesEnum.PARAMETERS,
  list: ParametersRoutesEnum.FINANCIAL_PRODUCTS,
  create: ParametersRoutesEnum.FINANCIAL_PRODUCTS_CREATE,
  update: ParametersRoutesEnum.FINANCIAL_PRODUCTS_UPDATE,
  translationKey: 'financialProducts',
  mainMenuTranslationKey: 'parameters'
})

const productLineBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: ParametersRoutesEnum.PARAMETERS,
  list: ParametersRoutesEnum.PRODUCT_LINES,
  create: ParametersRoutesEnum.PRODUCT_LINES_CREATE,
  update: ParametersRoutesEnum.PRODUCT_LINES_UPDATE,
  translationKey: 'productLines',
  mainMenuTranslationKey: 'parameters'
})

const vehicleBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: ParametersRoutesEnum.PARAMETERS,
  list: ParametersRoutesEnum.VEHICLES,
  create: ParametersRoutesEnum.VEHICLES_CREATE,
  update: ParametersRoutesEnum.VEHICLES_UPDATE,
  translationKey: 'vehicles',
  mainMenuTranslationKey: 'parameters'
})

const simulationParamBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: ParametersRoutesEnum.PARAMETERS,
  list: ParametersRoutesEnum.SIMULATION_PARAMS,
  create: ParametersRoutesEnum.SIMULATION_PARAMS_CREATE,
  update: ParametersRoutesEnum.SIMULATION_PARAMS_UPDATE,
  translationKey: 'parameters',
  mainMenuTranslationKey: 'parameters'
})

const faqCategoryBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: RegistersRoutesEnum.REGISTERS,
  menu: RegistersRoutesEnum.FAQ,
  list: RegistersRoutesEnum.FAQ_CATEGORIES_VIEW,
  create: RegistersRoutesEnum.FAQ_CATEGORIES_CREATE,
  update: RegistersRoutesEnum.FAQ_CATEGORIES_UPDATE,
  translationKey: 'faqCategories',
  mainMenuTranslationKey: 'registers'
})

const faqQuestionBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: RegistersRoutesEnum.REGISTERS,
  menu: RegistersRoutesEnum.FAQ,
  list: RegistersRoutesEnum.FAQ_QUESTIONS_VIEW,
  create: RegistersRoutesEnum.FAQ_QUESTIONS_CREATE,
  update: RegistersRoutesEnum.FAQ_QUESTIONS_UPDATE,
  translationKey: 'faqQuestions',
  mainMenuTranslationKey: 'registers'
})

const bannerBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: RegistersRoutesEnum.REGISTERS,
  list: RegistersRoutesEnum.BANNERS,
  create: RegistersRoutesEnum.BANNERS_CREATE,
  update: RegistersRoutesEnum.BANNERS_UPDATE,
  translationKey: 'banners',
  mainMenuTranslationKey: 'registers'
})

const termsBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: RegistersRoutesEnum.REGISTERS,
  list: RegistersRoutesEnum.TERMS,
  create: RegistersRoutesEnum.TERMS_CREATE,
  update: RegistersRoutesEnum.TERMS_UPDATE,
  translationKey: 'termsAndConditions',
  mainMenuTranslationKey: 'registers'
})

const pushNotificationsBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: RegistersRoutesEnum.REGISTERS,
  list: RegistersRoutesEnum.PUSH_NOTIFICATIONS,
  create: RegistersRoutesEnum.PUSH_NOTIFICATIONS_CREATE,
  update: RegistersRoutesEnum.PUSH_NOTIFICATIONS_UPDATE,
  translationKey: 'pushNotifications',
  mainMenuTranslationKey: 'registers'
})

const leadsBreadcrumbs = getCrudBreadCrumbs({
  menu: LeadsRoutesEnum.LEADS_MENU,
  list: LeadsRoutesEnum.LEADS_VIEW,
  translationKey: 'leadManagement'
})

const leadsAutomationBreadcrumbs = getCrudBreadCrumbs({
  menu: LeadsRoutesEnum.LEADS_MENU,
  list: LeadsRoutesEnum.LEADS_AUTOMATION_LIST,
  create: LeadsRoutesEnum.LEADS_AUTOMATION_CREATE,
  update: LeadsRoutesEnum.LEADS_AUTOMATION_UPDATE,
  translationKey: 'leadAutomations'
})

const npsBreadcrumbs = getCrudBreadCrumbs({
  menu: SurveyRoutesEnum.SURVEY_MENU,
  list: SurveyRoutesEnum.SURVEY_NPS,
  create: SurveyRoutesEnum.SURVEY_NPS_CREATE,
  update: SurveyRoutesEnum.SURVEY_NPS_UPDATE,
  translationKey: 'nps'
})

const scrClientsBreadCrumbs = getCrudBreadCrumbs({
  mainMenu: UserSettingsRoutesEnum.SCR_MENU,
  list: UserSettingsRoutesEnum.SCR_CLIENTS,
  translationKey: 'scrClients',
  mainMenuTranslationKey: 'userSettings'
})

const scrEmailsBreadCrumbs = getCrudBreadCrumbs({
  mainMenu: UserSettingsRoutesEnum.USER_SETTINGS,
  menu: UserSettingsRoutesEnum.SCR_MENU,
  list: UserSettingsRoutesEnum.SCR_EMAILS,
  translationKey: 'scrEmails',
  mainMenuTranslationKey: 'userSettings'
})

const linkUsersBreadCrumbs = getCrudBreadCrumbs({
  mainMenu: UserSettingsRoutesEnum.USER_SETTINGS,
  list: UserSettingsRoutesEnum.LINK_USERS_LIST,
  translationKey: 'linkUsers',
  mainMenuTranslationKey: 'userSettings'
})

const usersAccessBreadCrumbs = getCrudBreadCrumbs({
  mainMenu: UserSettingsRoutesEnum.USER_SETTINGS,
  list: UserSettingsRoutesEnum.USERS_ACCESS_LIST,
  update: UserSettingsRoutesEnum.USERS_ACCESS_LIST_UPDATE,
  translationKey: 'usersAccess',
  mainMenuTranslationKey: 'userSettings'
})

const consortiumResourcesBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: RegistersRoutesEnum.REGISTERS,
  list: RegistersRoutesEnum.CONSORTIUM_RESOURCES,
  create: RegistersRoutesEnum.CONSORTIUM_RESOURCES_CREATE,
  translationKey: 'consortiumResources',
  mainMenuTranslationKey: 'registers'
})

const reportsBreadcrumbs = getCrudBreadCrumbs({
  menu: ReportsRoutesEnum.REPORTS_MENU,
  create: ReportsRoutesEnum.USER_REPORTS_FORM,
  translationKey: 'reports'
})

const reportsCpfSearchBreadcrumbs = getCrudBreadCrumbs({
  menu: ReportsRoutesEnum.REPORTS_MENU,
  list: ReportsRoutesEnum.USER_SEARCH,
  translationKey: 'reports'
})

const resetEmailAndPassword = getCrudBreadCrumbs({
  menu: UserSettingsRoutesEnum.USER_SETTINGS,
  list: UserSettingsRoutesEnum.RESET_EMAIL_AND_PASSWORD,
  translationKey: 'userSettings'
})

const rentalContractsBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: RegistersRoutesEnum.REGISTERS,
  list: RegistersRoutesEnum.RENTAL_CONTRACTS,
  create: RegistersRoutesEnum.RENTAL_CONTRACTS_CREATE,
  translationKey: 'rentalContracts',
  mainMenuTranslationKey: 'registers'
})

const userRoleBreadcrumbs = getCrudBreadCrumbs({
  mainMenu: UserSettingsRoutesEnum.USER_SETTINGS,
  list: UserSettingsRoutesEnum.ROLES_LIST,
  create: UserSettingsRoutesEnum.ROLES_CREATE,
  update: UserSettingsRoutesEnum.ROLES_UPDATE,
  translationKey: 'roles',
  mainMenuTranslationKey: 'userSettings'
})

const functionalDomainsBreadCrumbs = getCrudBreadCrumbs({
  mainMenu: UserSettingsRoutesEnum.USER_SETTINGS,
  list: UserSettingsRoutesEnum.USER_DOMAINS,
  translationKey: 'functionalDomains',
  mainMenuTranslationKey: 'userSettings'
})

export const breadcrumbs: Record<string, NavBarProps['routes']> = {
  ...holidayBreadcrumbs,
  ...financialProductBreadcrumbs,
  ...productLineBreadcrumbs,
  ...vehicleBreadcrumbs,
  ...simulationParamBreadcrumbs,
  ...bannerBreadcrumbs,
  ...termsBreadcrumbs,
  ...pushNotificationsBreadcrumbs,
  ...faqCategoryBreadcrumbs,
  ...faqQuestionBreadcrumbs,
  ...leadsBreadcrumbs,
  ...leadsAutomationBreadcrumbs,
  ...npsBreadcrumbs,
  ...scrClientsBreadCrumbs,
  ...scrEmailsBreadCrumbs,
  ...linkUsersBreadCrumbs,
  ...usersAccessBreadCrumbs,
  ...consortiumResourcesBreadcrumbs,
  ...reportsBreadcrumbs,
  ...reportsCpfSearchBreadcrumbs,
  ...resetEmailAndPassword,
  ...rentalContractsBreadcrumbs,
  ...userRoleBreadcrumbs,
  ...functionalDomainsBreadCrumbs
}
