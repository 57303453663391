import React from 'react'

import { Slider, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type StartupPaidPercentProps = {
  startupPaidPercent: number
  disabled: boolean
  setStartupPaidPercent: (value: number) => void
  label: string
}

/**
 *
 * @param props StartupPaidPercentProps
 * @returns TSX
 */
function StartupPaidPercent(props: StartupPaidPercentProps) {
  const { startupPaidPercent, disabled, setStartupPaidPercent, label } = props

  const { t } = useLanguage()

  return (
    <FlexBox flexDirection="column" justifyContent="center" alignItems="center">
      <Typography
        mt={2}
        mb={2}
        variant="h6"
        color={Colors.light.greys.GREY_600}
      >
        {t('simulation.financingAmounts.startupPaid.title')}
      </Typography>
      <Slider
        defaultValue={startupPaidPercent}
        min={0}
        max={90}
        disabled={disabled}
        sx={{ mt: 3, color: Colors.light.interactDefault, width: 480 }}
        valueLabelDisplay="on"
        valueLabelFormat={label}
        onChangeCommitted={(_, value) => setStartupPaidPercent(value as number)}
        marks={[
          {
            value: 0,
            label: '0%'
          },
          {
            value: 90,
            label: '90%'
          }
        ]}
      />
    </FlexBox>
  )
}

export default StartupPaidPercent
export { StartupPaidPercent }
