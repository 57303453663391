import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type HolidayData = {
  /**
   *
   */
  name: string
  /**
   *
   */
  date: Date
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    name: Yup.string().required('validation.required.general'),
    date: Yup.date().nullable().typeError('validation.invalid.date').required()
  })
)
