import { getAllCmsHolidays } from '@volvo-apps/shared/core/actions/Holiday'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all cms holidays
 *
 * @returns holidays, isLoading, refetch
 */
export const useGetCmsHolidays = () => {
  const {
    data: holidays,
    isLoading,
    refetch
  } = useQuery('cmsHolidays', {
    queryFn: getAllCmsHolidays,
    onError: handleErrorFeedback
  })

  return { holidays, isLoading, refetch }
}
