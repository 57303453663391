import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'

/**
 * Siems service
 */
export class SiemsService extends GenericService<boolean> {
  readonly URI: string = 'gateway/Siems'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get client consortium
   */
  public async getClientHasConsortium() {
    return super.get<AxiosResponse<boolean>>('ClientHasConsortium')
  }
}
