import { getUserRoles } from '@volvo-apps/shared/core/actions/UserRoles'

import { useQuery } from 'react-query'

type useGetUserRoleParams = {
  onSuccess?: () => void
  onError?: (error: Error) => void
}
/**
 * Hook to get all cms user roles
 *
 * @param params getUserRoles params
 * @returns user roles, isLoading, refetch
 */
export const useGetUserRoles = (params: useGetUserRoleParams = {}) => {
  const { onError, onSuccess } = params
  const {
    data: roles,
    isLoading,
    refetch
  } = useQuery('getAllUserRoles', {
    queryFn: getUserRoles,
    onSuccess,
    onError
  })

  return { roles, isLoading, refetch }
}
