import FaqQuestionDTO from '@volvo-apps/shared/models/FaqQuestionDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as FaqQuestionRoutesEnum } from 'routes/cms/routesEnums'

import { useGetFaqQuestion } from 'hooks/faqQuestions/useGetFaqQuestion'
import { useUpdateFaqQuestion } from 'hooks/faqQuestions/useUpdateFaqQuestions'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import FaqQuestionForm from '../Form/FaqQuestionForm'
import { FaqQuestionData } from '../Form/schema'
import { useFaqQuestionForm } from '../Form/useFaqQuestionForm'

/**
 * Faq Questions Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { methods } = useFaqQuestionForm({
    defaultValues: { title: '', description: '' }
  })

  const { faqQuestion, isLoading: getLoading } = useGetFaqQuestion({
    id: Number(id),
    onSuccess: (faqQuestion) => {
      methods.reset({
        title: faqQuestion.title,
        description: faqQuestion.description,
        categoryId: faqQuestion.categoryId
      })
    }
  })

  const { mutate, isLoading } = useUpdateFaqQuestion({
    onSuccess: () => {
      toast.success(t('cms.faq.updateQuestion.success'))
      navigate(FaqQuestionRoutesEnum.FAQ_QUESTIONS_VIEW)
    }
  })

  const onSubmit = React.useCallback(
    (formData: FaqQuestionData) => {
      const newFaqQuestion = new FaqQuestionDTO().fromJSON({
        ...faqQuestion,
        ...formData,
        createdDate: undefined
      })
      mutate({ faqQuestion: newFaqQuestion })
    },
    [faqQuestion, mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.faq.updateQuestion.title')}
      </Typography>
      <FaqQuestionForm
        isLoading={isLoading || getLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.faq.updateQuestion.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
