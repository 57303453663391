import React from 'react'

import { Typography, Box } from '@mui/material'
import { useLocation } from 'react-router-dom'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { toCurrency } from 'common/utils/money'
import { safeParseJson } from 'common/utils/object'
import { useLanguage } from 'context/Language'
import { useUserSimulation } from 'context/Simulation'

import Card from './Card'

type DetailsProps = {
  isValid?: boolean
}

/**
 * @param props - component props.
 * @returns TSX
 */
function Details(props: DetailsProps) {
  const { isValid } = props
  const { simulation } = useUserSimulation()
  const location = useLocation()
  const { t } = useLanguage()

  if (!simulation) {
    return null
  }

  const { state: params } = location

  const featuredInstallmentAmount = params?.featuredInstallmentAmount ?? ''

  const { valorFinanciado } = safeParseJson(simulation.simulationJson)

  return (
    <Box style={{ opacity: isValid ? 1 : 0.6 }}>
      <Typography color={Colors.light.interactActive} variant="h5" mb={3}>
        {t('simulation.summary.title')}
      </Typography>
      <FlexBox gap={2} mb={2}>
        <Card
          title={simulation.financialProductName ?? ''}
          icon="hall"
          description={t('simulation.summary.financingType')}
        />
        <Card
          title={toCurrency(simulation.productValue ?? 0).withSymbol}
          icon="money"
          description={t('simulation.summary.productValue')}
        />
      </FlexBox>
      <FlexBox gap={2}>
        <Card
          title={toCurrency(valorFinanciado ?? 0).withSymbol}
          icon="calculator"
          description={t('simulation.summary.financingAmount')}
        />

        <Card
          title={t('simulation.summary.nMonths', [simulation.termMonths])}
          icon="money"
          description={t('simulation.summary.gracePeriod', [
            toCurrency(featuredInstallmentAmount).withoutSymbol,
            simulation.gracePeriodMonths
          ])}
        />
      </FlexBox>
    </Box>
  )
}

export default Details
export { Details }
