import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import NpsUserDTO from './NpsUserDTO'

/**
 * NpsDTO
 */
class NpsDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedDate!: string

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('title')
  @jsonProperty(String)
  public title!: string

  @jsonName('userId')
  @jsonProperty(Number)
  public userId!: number

  @jsonName('userAuthorName')
  @jsonProperty(String)
  public userAuthorName!: string

  @jsonName('beginDate')
  @jsonProperty(String)
  public beginDate!: string

  @jsonName('endDate')
  @jsonProperty(String)
  public endDate!: string

  @jsonName('npsUsers')
  @jsonProperty(Array)
  public npsUsers!: NpsUserDTO[]
}

export default NpsDTO
