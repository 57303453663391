import enUSCmsJson from './en-US-cms.json'
import enUSJson from './en-US.json'
import ptBRCmsJson from './pt-BR-cms.json'
import ptBRJson from './pt-BR.json'

const ptBR = {
  ...ptBRJson,
  ...ptBRCmsJson
}

const enUS = {
  ...enUSJson,
  ...enUSCmsJson
}

export { ptBR, enUS }
