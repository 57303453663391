import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import { FinancialMenuActionsProvider } from 'context/FinancialMenuActions'
import {
  Contracts,
  FinancialMenu,
  Tickets,
  ContractDetail,
  Rental,
  RentalDetail
  // PartsAndServices
} from 'pages/Financial'
import { AppContent } from 'pages/layout/AppContent'

export enum FinancialRoutesEnum {
  FINANCIAL_MENU = '/financial',
  CONTRACTS = '/financial/contracts',
  CONTRACT_DETAIL = '/financial/contracts/:id',
  TICKETS = '/financial/tickets',
  PARTS_AND_SERVICES = '/financial/partsandservices',
  PARTS_AND_SERVICES_DETAIL = '/financial/partsandservices/:id',
  RENTAL = '/financial/rental',
  RENTAL_DETAIL = '/financial/rental/:id'
}

const financialRoutes: CustomRouteProps[] = [
  {
    path: FinancialRoutesEnum.FINANCIAL_MENU,
    protected: true,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: FinancialRoutesEnum.FINANCIAL_MENU,
        label: 'breadCrumbs.financial'
      }
    ],
    element: (
      <FinancialMenuActionsProvider>
        <FinancialMenu />
      </FinancialMenuActionsProvider>
    )
  },
  {
    path: FinancialRoutesEnum.CONTRACTS,
    protected: true,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: FinancialRoutesEnum.FINANCIAL_MENU,
        label: 'breadCrumbs.financial'
      },
      {
        to: FinancialRoutesEnum.CONTRACTS,
        label: 'breadCrumbs.contracts'
      }
    ],
    element: <Contracts />
  },
  {
    path: FinancialRoutesEnum.CONTRACT_DETAIL,
    protected: true,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: FinancialRoutesEnum.FINANCIAL_MENU,
        label: 'breadCrumbs.financial'
      },
      {
        to: FinancialRoutesEnum.CONTRACTS,
        label: 'breadCrumbs.contracts'
      },
      {
        to: FinancialRoutesEnum.CONTRACT_DETAIL,
        label: 'breadCrumbs.contractDetail'
      }
    ],
    element: <ContractDetail />
  },
  {
    path: FinancialRoutesEnum.TICKETS,
    protected: true,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: FinancialRoutesEnum.FINANCIAL_MENU,
        label: 'breadCrumbs.financial'
      },
      {
        to: FinancialRoutesEnum.TICKETS,
        label: 'breadCrumbs.tickets'
      }
    ],
    element: <Tickets />
  },
  {
    path: FinancialRoutesEnum.RENTAL,
    protected: true,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: FinancialRoutesEnum.FINANCIAL_MENU,
        label: 'breadCrumbs.financial'
      },
      {
        to: FinancialRoutesEnum.RENTAL,
        label: 'breadCrumbs.rental'
      }
    ],
    element: <Rental />
  },
  {
    path: FinancialRoutesEnum.RENTAL_DETAIL,
    protected: true,
    layout: AppContent,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: FinancialRoutesEnum.FINANCIAL_MENU,
        label: 'breadCrumbs.financial'
      },
      {
        to: FinancialRoutesEnum.RENTAL,
        label: 'breadCrumbs.rental'
      },
      {
        to: FinancialRoutesEnum.RENTAL_DETAIL,
        label: 'breadCrumbs.contractDetail'
      }
    ],
    element: <RentalDetail />
  }
  // {
  //   path: FinancialRoutesEnum.PARTS_AND_SERVICES,
  //   protected: true,
  //   layout: AppContent,
  //   breadcrumbs: [
  //     {
  //       to: '/dashboard',
  //       label: 'breadCrumbs.home'
  //     },
  //     {
  //       to: FinancialRoutesEnum.FINANCIAL_MENU,
  //       label: 'breadCrumbs.financial'
  //     },
  //     {
  //       to: FinancialRoutesEnum.PARTS_AND_SERVICES,
  //       label: 'breadCrumbs.partsAndServices'
  //     }
  //   ],
  //   element: <PartsAndServices />
  // },
  // {
  //   path: FinancialRoutesEnum.PARTS_AND_SERVICES_DETAIL,
  //   protected: true,
  //   layout: AppContent,
  //   breadcrumbs: [
  //     {
  //       to: '/dashboard',
  //       label: 'breadCrumbs.home'
  //     },
  //     {
  //       to: FinancialRoutesEnum.FINANCIAL_MENU,
  //       label: 'breadCrumbs.financial'
  //     },
  //     {
  //       to: FinancialRoutesEnum.PARTS_AND_SERVICES,
  //       label: 'breadCrumbs.partsAndServices'
  //     },
  //     {
  //       to: FinancialRoutesEnum.PARTS_AND_SERVICES_DETAIL,
  //       label: 'breadCrumbs.contractDetail'
  //     }
  //   ],
  //   element: <ContractDetail />
  // }
]

export default financialRoutes
