import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * CampaignDTO
 */
class CampaignDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedDate!: string

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('documentType')
  @jsonProperty(String)
  public documentType!: string

  @jsonName('productLine')
  @jsonProperty(String, Number)
  public productLine!: string | number

  @jsonName('financialProduct')
  @jsonProperty(String, Number)
  public financialProduct!: string | number

  @jsonName('productLineNameId')
  @jsonProperty(String)
  public productLineNameId!: string

  @jsonName('financialProductId')
  @jsonProperty(Number)
  public financialProductId!: number

  @jsonName('productLineId')
  @jsonProperty(Number)
  public productLineId!: number

  @jsonName('productLineName')
  @jsonProperty(String)
  public productLineName!: string

  @jsonName('financialProductName')
  @jsonProperty(String)
  public financialProductName!: string

  @jsonName('sendToAll')
  @jsonProperty(Boolean)
  public sendToAll!: boolean

  @jsonName('sendToAllDocumentType')
  @jsonProperty(Boolean)
  public sendToAllDocumentType!: boolean

  @jsonName('sendToAllProductLine')
  @jsonProperty(Boolean)
  public sendToAllProductLine!: boolean

  @jsonName('sendToAllFinancialProduct')
  @jsonProperty(Boolean)
  public sendToAllFinancialProduct!: boolean
}

export default CampaignDTO
