import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type FaqCategoryData = {
  /**
   *
   */
  icon: string
  /**
   *
   */
  label: string
  /**
   *
   */
  description: string
  /**
   *
   */
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    icon: Yup.string().required('validation.required.general'),
    label: Yup.string().required('validation.required.general'),
    description: Yup.string().required('validation.required.general')
  })
)
