import { getOnlyNumbers } from 'common/utils/string'

import Yup from './index'

const phoneRegex =
  /^1\d\d(\d\d)?$|^0800 ?\d{3} ?\d{4}$|^(\(0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d\) ?|0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d[ .-]?)?(9|9[ .-])?[9]\d{4}[ .-]?\d{4}$/gm

/**
 *
 * @param value Value
 * @returns Boolean
 */
const validatePhoneNumber = (value: string) => {
  return phoneRegex.test(value)
}

export const phoneNumberValidator = Yup.string()
  .nullable()
  .test('phone_validation', 'validation.invalid.phone', (value = '') => {
    if (value === null || value === '') return true
    const numbers = getOnlyNumbers(value)
    if (numbers.length <= 5) return false
    return !!numbers.match(phoneRegex)
  })

export const requiredPhoneNumberValidator = phoneNumberValidator.required(
  'validation.required.phone'
)

export { validatePhoneNumber }
