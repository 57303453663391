import React from 'react'

import {
  CircularProgress,
  CircularProgressProps,
  BoxProps
} from '@mui/material'

import { FlexBox } from 'components/FlexBox'

type LoaderProps = CircularProgressProps & {
  boxProps?: BoxProps
  hideCircularProgress?: boolean
}

/**
 *
 * @param props LoaderProps
 * @returns TSX
 */
const Loader = React.forwardRef<HTMLDivElement, LoaderProps>((props, ref) => {
  const {
    boxProps,
    hideCircularProgress = false,
    ...circularProgressProps
  } = props
  return (
    <FlexBox ref={ref} {...boxProps}>
      {!hideCircularProgress && <CircularProgress {...circularProgressProps} />}
    </FlexBox>
  )
})

Loader.displayName = 'Loader'

export default Loader
export { Loader }
