import { getFinancialProduct } from '@volvo-apps/shared/core/actions/FinancialProduct'
import FinancialProductDTO from '@volvo-apps/shared/models/FinancialProductDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetFinancialProductParams = {
  onSuccess?: (financialProduct: FinancialProductDTO) => void
  id?: number
}

/**
 * Hook to get a financial product
 *
 * @param params params
 * @returns financialProduct, isLoading, refetch
 */
export const useGetFinancialProduct = (
  params: useGetFinancialProductParams
) => {
  const { id, onSuccess } = params
  const {
    data: financialProduct,
    isLoading,
    refetch
  } = useQuery('getFinancialProduct', {
    enabled: !!id,
    queryFn: () => getFinancialProduct(id || 0),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { financialProduct, isLoading, refetch }
}
