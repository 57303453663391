import { makeResolver } from 'common/utils/form'
import { birthdayValidator } from 'common/validators/birthday'
import { cpfValidator } from 'common/validators/cpfCnpj'
import { emailValidator } from 'common/validators/email'
import { nameValidator } from 'common/validators/name'
import { phoneNumberValidator } from 'common/validators/phoneNumber'
import { zipCodeValidator } from 'common/validators/zipCode'

export const personalInfoSchema = makeResolver((Yup) =>
  Yup.object({
    name: nameValidator,
    signUpDocument: Yup.object({
      document: cpfValidator,
      isSignUpDocument: Yup.boolean()
    }),
    birthday: birthdayValidator,
    email: emailValidator,
    phoneNumber: phoneNumberValidator
  })
)

export const addressInfoSchema = makeResolver((Yup) =>
  Yup.object({
    zipCode: zipCodeValidator,
    address: Yup.string().required('validation.required.general'),
    addressNumber: Yup.string().required('validation.required.general'),
    state: Yup.string().required('validation.required.general'),
    city: Yup.string().required('validation.required.general')
  })
)
