import React, { PropsWithChildren } from 'react'

import { List, ListItem, ListItemButton } from '@mui/material'

import { Loader } from 'components/Loader'
import { NoInfo } from 'components/NoInfo'

type GenericListProps<T> = {
  listStyle?: React.CSSProperties
  listItemStyle?: React.CSSProperties
  listItemButtonStyle?: React.CSSProperties
  items: T[]
  makeChildrenComponent: (item: T, index: number) => React.ReactNode
  onClick?: (item: T) => void
  hideButton?: boolean
  loading?: boolean
  disableNoInfoDisclaimer?: boolean
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function GenericList<T>(props: PropsWithChildren<GenericListProps<T>>) {
  const {
    listStyle,
    listItemStyle,
    listItemButtonStyle,
    children,
    items,
    makeChildrenComponent,
    onClick,
    hideButton,
    loading,
    disableNoInfoDisclaimer
  } = props

  const makeChildren = () => {
    if (children) {
      return children
    }

    if (makeChildrenComponent) {
      return items.map((item, index) => (
        <ListItem style={listItemStyle} key={index}>
          {hideButton ? (
            makeChildrenComponent(item, index)
          ) : (
            <ListItemButton
              style={listItemButtonStyle}
              onClick={() => onClick?.(item)}
            >
              {makeChildrenComponent(item, index)}
            </ListItemButton>
          )}
        </ListItem>
      ))
    }

    return null
  }

  if (loading) {
    return (
      <Loader
        size={32}
        boxProps={{
          py: 2,
          justifyContent: 'center'
        }}
      />
    )
  }

  return items && items.length > 0 ? (
    <List style={listStyle}>{makeChildren()}</List>
  ) : !disableNoInfoDisclaimer ? (
    <NoInfo />
  ) : null
}

export default GenericList
export { GenericList }
