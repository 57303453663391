import { AxiosResponse } from 'axios'
import PartsAndServicesDTO from '@volvo-apps/shared/models/PartsAndServicesDTO'
import { GenericService } from './GenericService'
import PartsAndServicesUserDTO from '../../models/PartsAndServicesUserDTO'
import PartsAndServicesImportDTO from '../../models/PartsAndServicesImportDTO'
import PartsAndServicesCustomerDTO from '../../models/PartsAndServicesCustomerDTO'

/**
 * PartsAndServices
 */
export class PartsAndServicesService extends GenericService<PartsAndServicesDTO> {
  readonly URI: string = 'gateway/PartsAndServices'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all parts and services
   */
  public async getAll() {
    return super.get<{ data: PartsAndServicesDTO[] }>('GetAll')
  }

  /**
   *
   * @param id The id of the product line
   */
  public async getPartsAndServices(id: number) {
    return super.get<AxiosResponse<PartsAndServicesDTO>>('Get', { id })
  }

  /**
   * Delete parts and services
   *
   * @param id The id of the parts and services
   */
  public async deletePartsAndServices(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Update parts and services
   *
   * @param updatedPartsAndServices The updated parts and services
   */
  public async updatePartsAndServices(
    updatedPartsAndServices: PartsAndServicesDTO
  ) {
    return super.put<AxiosResponse<PartsAndServicesDTO>>('Update', {
      ...updatedPartsAndServices
    })
  }

  /**
   * Create parts and services
   *
   * @param sequence The sequence of the parts and services
   * @param fileName The name of the file of the parts and services
   * @param processingDate The date of the processing of the parts and services
   * @param processingRecords The number of records of the processing of the parts and services
   */
  public async createPartsAndServices(
    sequence: number,
    fileName: string,
    processingDate: Date,
    processingRecords: number
  ) {
    return super.post<AxiosResponse<PartsAndServicesDTO>>('Add', {
      sequence,
      fileName,
      processingDate,
      processingRecords
    })
  }

  /**
   * Get parts and services by user
   */
  public async getByUser() {
    return super.get<AxiosResponse<PartsAndServicesUserDTO[]>>('GetByUser')
  }

  /**
   *
   * @param document The document of the customer
   */
  public async getPartsAndServicesCustomer(document: string) {
    return super.get<AxiosResponse<PartsAndServicesCustomerDTO>>(
      'GetPartsAndServicesCustomer',
      { document }
    )
  }

  /**
   * Import File
   *
   * @param formData data of form
   */
  public async importFile(formData: FormData) {
    return super.post<AxiosResponse<PartsAndServicesImportDTO>>(
      'ImportFile',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
  }

  /**
   *
   */
  public async hasPartsAndServices() {
    // TODO: Fix type
    return super.get('HasPartsAndServices') as unknown as {
      /**
       *
       */
      data: boolean
    }
  }
}
