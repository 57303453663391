import { deleteHoliday } from '@volvo-apps/shared/core/actions/Holiday'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useDeleteHolidayParams = {
  onSuccess?: () => void
}

/**
 * Hook to delete one holiday
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteHoliday = (params: useDeleteHolidayParams = {}) => {
  const { onSuccess } = params
  const { mutate, isLoading, data } = useMutation('deleteHoliday', {
    mutationFn: ({ id }: { id: number }) => deleteHoliday(id),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, data, isLoading }
}
