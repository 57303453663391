import React from 'react'

import { Button, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Modal, useModalMethods } from 'components/Modal'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type DataGridDeleteButtonProps = {
  handleDelete: () => void
  color: string
  size: number
  showModal?: boolean
  title?: string | null
  description?: string | null
  disclaimer?: string | null
  confirmText?: string | null
  cancelText?: string | null
}

/**
 *
 * @param props DataGridDeleteButtonProps
 * @returns TSX
 */
const DataGridDeleteButton = (props: DataGridDeleteButtonProps) => {
  const {
    handleDelete,
    color,
    size,
    showModal,
    title,
    description,
    disclaimer,
    confirmText,
    cancelText
  } = props
  const { open, setOpen, setClosed } = useModalMethods()

  const { t } = useLanguage()

  const onClickDelete = () => {
    if (showModal) {
      setOpen(true)
    } else {
      handleDelete()
    }
  }

  const confirmDelete = () => {
    handleDelete()
    setClosed()
  }

  const Content = React.useMemo(
    () => (
      <FlexBox
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        mt={1}
      >
        <Typography
          color={Colors.light.greys.GREY_600}
          fontSize={16}
          fontFamily="Volvo-Novum-Medium"
        >
          {description || t('cms.deleteModal.description')}
        </Typography>

        <Typography color={Colors.light.greys.GREY_600} fontSize={16}>
          {disclaimer || t('cms.deleteModal.disclaimer')}
        </Typography>
      </FlexBox>
    ),
    [description, disclaimer, t]
  )

  return (
    <>
      <Modal
        showCloseButton
        disableBackdropClick
        title={
          <Typography
            color={Colors.light.text}
            fontSize={24}
            fontFamily="Volvo-Novum-Medium"
          >
            {title || t('cms.deleteModal.title')}
          </Typography>
        }
        PaperProps={{ sx: { p: 1, borderRadius: 2.5 } }}
        content={Content}
        actionsDirection="column"
        actionsGap={2}
        actions={[
          {
            children: confirmText || t('cms.deleteModal.confirm'),
            onClick: () => {
              confirmDelete()
            },
            style: {
              backgroundColor: Colors.light.interactDefault,
              width: '100%'
            },
            variant: 'contained'
          },
          {
            children: cancelText || t('cms.deleteModal.cancel'),
            onClick: () => {
              setClosed()
            },
            variant: 'outlined',
            sx: { color: Colors.light.interactActive, width: '100%' }
          }
        ]}
        onClose={setClosed}
        open={open}
      />
      <Button onClick={onClickDelete}>
        <Typography color={color} fontSize={size}>
          {t('general.delete')}
        </Typography>
      </Button>
    </>
  )
}

export default DataGridDeleteButton
