import React from 'react'

import { Box, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

type DetailProps = {
  rows: { key: string; value: string | number }[]
}

/**
 * @param props Detail Props
 * @returns TSX
 */
const Detail = (props: DetailProps) => {
  const { rows } = props
  return (
    <Box width="100%">
      {rows.map((row, index) => (
        <FlexBox
          key={row.key}
          justifyContent="space-between"
          alignItems="center"
          py={2}
          pt={index === 0 ? 0 : 2}
          sx={{
            borderBottom: `1px solid ${Colors.light.greys.GREY_300}}`
          }}
        >
          <Typography color={Colors.light.greys.GREY_700} fontSize={14}>
            {row.key}
          </Typography>
          <Typography color={Colors.light.text} fontSize={14} pl={12}>
            {row.value}
          </Typography>
        </FlexBox>
      ))}
    </Box>
  )
}

export default Detail
export { Detail }
