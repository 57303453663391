import React from 'react'

import { Typography, TypographyProps } from '@mui/material'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import packageJson from '../../../package.json'

type VersionProps = TypographyProps

/**
 * @param props - Component props
 * @returns JSX
 */
const Version = (props: VersionProps) => {
  const { t } = useLanguage()

  return (
    <Typography
      variant="body1"
      mt={2}
      fontSize={14}
      color={Colors.light.greys.GREY_500}
      {...props}
    >
      {t('version', [packageJson.version])}
    </Typography>
  )
}

export default Version
export { Version }
