import React from 'react'

import { Switch } from '@mui/material'

import Colors from 'constants/Colors'

type InactiveSwitchProps = {
  checked: boolean
  handleToggle: (checked: boolean) => void
}

/**
 *
 *
 * @param props Props
 * @returns TSX
 */
function DataGridInactiveSwitch(props: InactiveSwitchProps) {
  const [checked, setChecked] = React.useState<boolean>(props.checked)

  const handleToggle = React.useCallback(
    async (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setChecked(checked)
      props.handleToggle(checked)
    },
    [props]
  )

  return (
    <Switch
      sx={{ color: Colors.light.interactActive }}
      checked={checked}
      onChange={handleToggle}
    />
  )
}

export { DataGridInactiveSwitch }
export default DataGridInactiveSwitch
