import { useMutation } from 'react-query'
import { HookQueryParams } from '../../../common/utils/types'
import { importFileAsync } from '../../actions/Sales'
import ContractRentalImportDTO from '../../../models/RentalContractImportDTO'

type useImportRentalContractsParams = HookQueryParams<ContractRentalImportDTO>

/**
 * Hook to import rental contracts
 *
 * @param params params
 * @returns mutate, data, isLoading and mutateAsync
 */
export const useImportRentalContracts = (
  params: useImportRentalContractsParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data, mutateAsync } = useMutation(
    'importRentalContracts',
    {
      mutationFn: ({ formData }: { formData: FormData }) =>
        importFileAsync(formData),
      onSuccess
    }
  )

  return { mutate, data, isLoading, mutateAsync }
}
