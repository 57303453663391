// --------------------------------------------------------
// GENERIC_CONFIRMATION - GenericConfirmation
// --------------------------------------------------------

// --------------------------------------------------------
// GENERAL
// --------------------------------------------------------
import React, { useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

// --------------------------------------------------------
// ASSETS
// --------------------------------------------------------
import confirmImage from '../../assets/images/confirm.png'
import { ReactComponent as Volvo } from '../../assets/images/Logotype.svg'
import ProjectText from '../../components/ProjectText'
import Title from '../../components/ProjectText/Title'
import styles from './styles.module.scss'
// --------------------------------------------------------

// --------------------------------------------------------
// GENERIC_CONFIRMATION TYPE DEFINITIONS
// --------------------------------------------------------

type RequiredPropTypes = {
  title: string
  paramNames: string[]
  children: React.ReactNode
  checkFunction: (params: Record<string, string>) => Promise<boolean>
}

type OptionalPropTypes = {
  // ?
}

/**
 * Properties
 */
export type PropTypes = OptionalPropTypes & RequiredPropTypes
// --------------------------------------------------------

/**
 *
 * @returns The image
 */
function _getImage(): JSX.Element {
  return (
    <div className={classNames(styles.imageContainer)}>
      <img src={confirmImage} className={classNames(styles.image)} />
      <Volvo width={401} height={148} className={classNames(styles.volvo)} />
    </div>
  )
}

// @TODO: add i18n later
const i18nConfirming = 'Verificando'
/**
 * A component that renders a generic confirmation page.
 *
 * @param props The component props.
 * @param props.title The page title.
 * @param props.children The content.
 * @param props.paramNames The params.
 * @param props.checkFunction The callback.
 * @returns The component JSX.
 */
function GenericConfirmation({
  title,
  children,
  paramNames,
  checkFunction
}: PropTypes): JSX.Element {
  // --------------------------------------------------------
  // GENERIC_CONFIRMATION PRIVATE DECLARATIONS
  // --------------------------------------------------------
  const [isLoading, setLoading] = useState<boolean>(true)
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const location = useLocation()

  const { search, hash } = location

  const paramsToObj = useCallback(() => {
    const locationParams = search + hash
    const paramsArray = locationParams.split('?')[1].split('&')

    if (paramsArray.length > 0) {
      const obj: Record<string, string> = {}
      paramsArray.forEach((param) => {
        const [key, value] = param.split('=')
        obj[key] = value
      })
      return obj
    }
    return {}
  }, [search, hash])

  /**
   * Calls the backend to check
   *
   * @param obj The needed info
   */
  const check = useCallback(
    async (obj: Record<string, string>) => {
      if (isLoading) {
        const result: boolean = await checkFunction(obj)

        setLoading(false)
        if (result) {
          console.log('show OK', confirmed)
          setConfirmed(true)
        }
      }
    },
    [checkFunction, confirmed, isLoading]
  )

  useEffect(() => {
    if (isLoading) {
      const locationParams = paramsToObj()
      const obj: Record<string, string> = {}

      paramNames.forEach((param) => {
        obj[param] = locationParams[param] ?? ''
      })

      check(obj)
    }
  }, [isLoading, check, paramNames, paramsToObj])

  /**
   * Renders the text
   *
   * @returns The text
   */
  function _getText(): JSX.Element {
    return (
      <div className={classNames(styles.text)}>
        {isLoading ? (
          <ProjectText>{i18nConfirming}</ProjectText>
        ) : (
          <>
            <Title>{title}</Title>
            <ProjectText>{children}</ProjectText>
          </>
        )}
      </div>
    )
  }

  /**
   * When the data is validated
   *
   * @returns The ok text
   */
  function _getOk(): JSX.Element {
    return (
      <>
        {_getImage()}
        {_getText()}
      </>
    )
  }

  // --------------------------------------------------------

  // --------------------------------------------------------
  // REACT RETURN FUNCTION
  // --------------------------------------------------------
  return <main className={classNames(styles.genericContainer)}>{_getOk()}</main>
  // --------------------------------------------------------
}

export default GenericConfirmation
