import { useForm } from 'react-hook-form'

import { FaqQuestionData, schema } from './schema'

type FaqQuestionProps = {
  defaultValues?: Partial<FaqQuestionData>
}

/**
 *
 * @param props - FaqQuestionProps
 * @returns useForm methods
 */
export const useFaqQuestionForm = (props: FaqQuestionProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<FaqQuestionData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
