import { getAllProductLines } from '@volvo-apps/shared/core/actions/ProductLine'
import { getAllVehicle } from '@volvo-apps/shared/core/actions/Vehicle'
import VehicleDTO from '@volvo-apps/shared/models/VehicleDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'
import { ProductLinesParsed } from 'pages/Simulation/NewSimulation/types'

/**
 * @param onSuccess Success callback
 * @returns Vehicles and Product Lines
 */
export const useVehiclesAndProductLines = (
  onSuccess: (
    data: Awaited<ReturnType<typeof getVehiclesAndProductLines>>
  ) => void
) => {
  const getVehiclesAndProductLines = async () => {
    const vehicles = await getAllVehicle()

    const vehiclesByProductLineId = vehicles.reduce<
      Record<string, VehicleDTO[]>
    >((acc, vehicle) => {
      if (!acc[vehicle.productLineId]) {
        acc[vehicle.productLineId] = []
      }

      acc[vehicle.productLineId].push(vehicle)

      return acc
    }, {})

    const productLines = await getAllProductLines()

    const productLinesParsed = productLines
      .map(
        (productLine) =>
          ({
            key: productLine.id,
            title: productLine.name,
            value: productLine.id,
            sortIndex: productLine.order,
            minimumValue: productLine.minimumValue
          } as ProductLinesParsed)
      )
      .sort((a, b) => a.sortIndex - b.sortIndex)

    return { vehiclesByProductLineId, productLinesParsed }
  }

  const { isLoading, data: vehiclesAndProductLines } = useQuery(
    'vehiclesAndProductLines',
    {
      queryFn: getVehiclesAndProductLines,
      onSuccess,
      onError: handleErrorFeedback
    }
  )

  return { isLoading, vehiclesAndProductLines }
}
