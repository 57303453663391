import React from 'react'

import { Box } from '@mui/system'

import { TermsAndConditions as TermsAndConditionsComponent } from 'components/TermsAndConditions'

/**
 * @returns TSX
 */
function TermsAndConditions() {
  return (
    <Box width="765px" mb={4}>
      <TermsAndConditionsComponent />
    </Box>
  )
}

export default TermsAndConditions
export { TermsAndConditions }
