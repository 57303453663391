// --------------------------------------------------------
// PROJECT_TEXT - ProjectText
// --------------------------------------------------------

// --------------------------------------------------------
// GENERAL
// --------------------------------------------------------
import React from 'react'
// --------------------------------------------------------

// --------------------------------------------------------
// COMPONENTS
// --------------------------------------------------------
// --------------------------------------------------------

// --------------------------------------------------------
// STYLING
// --------------------------------------------------------
import classNames from 'classnames'

import styles from './styles.module.scss'
// --------------------------------------------------------

// --------------------------------------------------------
// CONSTANTS
// --------------------------------------------------------
// --------------------------------------------------------

// --------------------------------------------------------
// HOOKS
// --------------------------------------------------------
// --------------------------------------------------------

// --------------------------------------------------------
// PROJECT_TEXT TYPE DEFINITIONS
// --------------------------------------------------------

type RequiredPropTypes = {
  children: React.ReactNode
}

type OptionalPropTypes = {
  size?: number
  bold?: boolean
  color?: string
  center?: boolean
}

/**
 * Properties
 */
export type PropTypes = OptionalPropTypes &
  RequiredPropTypes &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >
// --------------------------------------------------------

/**
 * A component that renders a custom text.
 *
 * @param props The component props.
 * @param props.size Font Size
 * @param props.bold Font Weight
 * @param props.color Font Color
 * @param props.center Text Alignment
 * @param props.children Text
 * @returns The component JSX.
 */
function ProjectText({
  children,
  size,
  color,
  ...props
}: PropTypes): JSX.Element {
  // --------------------------------------------------------
  // PROJECT_TEXT PRIVATE DECLARATIONS
  // --------------------------------------------------------

  // --------------------------------------------------------

  // --------------------------------------------------------
  // REACT RETURN FUNCTION
  // --------------------------------------------------------
  return (
    <p
      style={{
        fontSize: size,
        color
      }}
      className={classNames(styles.text)}
      {...props}
    >
      {children}
    </p>
  )
  // --------------------------------------------------------
}

export default ProjectText
