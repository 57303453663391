import React from 'react'

import { Box } from '@mui/material'
import { useNavigate } from 'react-router'

import { List, ListItemProps } from 'components'
import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CmsMenuHeader } from 'components/CmsMenuHeader/CmsMenuHeader'

import { ParametersRoutesEnum } from 'routes/cms/routesEnums'

import useCmsRoles from 'hooks/cms/useCmsRoles'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function Menu() {
  const navigate = useNavigate()
  const { showMenuByPage } = useCmsRoles()

  const { t } = useLanguage()

  const menuItems: ListItemProps[] = [
    {
      primary: t('cms.dashboard.tabs.financialProducts'),
      onClick: () => navigate(ParametersRoutesEnum.FINANCIAL_PRODUCTS),
      hide: !showMenuByPage[ParametersRoutesEnum.FINANCIAL_PRODUCTS]
    },
    {
      primary: t('cms.dashboard.tabs.productLine'),
      onClick: () => navigate(ParametersRoutesEnum.PRODUCT_LINES),
      hide: !showMenuByPage[ParametersRoutesEnum.PRODUCT_LINES]
    },
    {
      primary: t('cms.dashboard.tabs.simulation'),
      onClick: () => navigate(ParametersRoutesEnum.SIMULATION_PARAMS),
      hide: !showMenuByPage[ParametersRoutesEnum.SIMULATION_PARAMS]
    },
    {
      primary: t('cms.dashboard.tabs.vehicles'),
      onClick: () => navigate(ParametersRoutesEnum.VEHICLES),
      hide: !showMenuByPage[ParametersRoutesEnum.VEHICLES]
    }
  ]

  return (
    <CmsChildrenWithHeader>
      <CmsMenuHeader
        titleText={t('cms.dashboard.tabs.parameters')}
        subtitleText={t('cms.registers.subtitle')}
      />
      <Box mt={2} width={(t) => t.breakpoints.values.md}>
        <List items={menuItems} noPadding />
      </Box>
    </CmsChildrenWithHeader>
  )
}

export default Menu
export { Menu }
