import { createTheme } from '@mui/material'

import colors from 'constants/Colors'

import VolvoFontRegular from 'assets/fonts/Volvo-Novum-Regular-1.ttf'
import VolvoFontItalic from 'assets/fonts/VolvoNovum-Italic.ttf'
import VolvoFontLight from 'assets/fonts/VolvoNovum-Light.ttf'
import VolvoFontMedium from 'assets/fonts/VolvoNovum-Medium.ttf'
import VolvoFontSemiLight from 'assets/fonts/VolvoNovum-SemiLight.ttf'

const theme = createTheme({
  typography: {
    fontFamily: 'Volvo-Novum',
    allVariants: {
      color: colors.light.text,
      textTransform: 'none'
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            borderColor: colors.light.text,
            color: colors.light.text,
            '&:hover': {
              borderColor: colors.light.text,
              backgroundColor: colors.light.greys.GREY_100
            }
          }
        }
      ]
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.light.text
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: 16
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
@font-face {
  font-family: 'Volvo-Novum-Medium';
  src: local('VolvoNovum-Medium'),
  url(${VolvoFontMedium}) format('truetype');
}
@font-face {
  font-family: 'Volvo-Novum-Light';
  src: local('VolvoNovum-Light'),
  url(${VolvoFontLight}) format('truetype');
}
@font-face {
  font-family: 'Volvo-Novum-SemiLight';
  src: local('VolvoNovum-SemiLight'),
  url(${VolvoFontSemiLight}) format('truetype');
}
@font-face {
  font-family: 'Volvo-Novum-Italic';
  src: local('VolvoNovum-Italic'),
  url(${VolvoFontItalic}) format('truetype');
}
@font-face {
  font-family: 'Volvo-Novum';
  src: local('Volvo-Novum-Regular-1'),
  url(${VolvoFontRegular}) format('truetype');
}
      `
    }
  }
})
export default theme
export { theme }
