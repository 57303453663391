import * as React from 'react'

import { Box } from '@mui/material'

import { useLanguage } from 'context/Language'

import { StyledDataGrid } from './styles'
import { DataGridProps } from './types'

/**
 * DataGrid
 *
 * @param props DataGridProps
 * @returns TSX
 */
function DataGrid(props: DataGridProps) {
  const {
    columns,
    rows,
    boxSx,
    loading,
    hideFooter,
    getRowId,
    type = 'default',
    isRowSelectable,
    onSelectionModelChange
  } = props

  const { t } = useLanguage()

  const selectionProps =
    type === 'checkbox'
      ? { checkboxSelection: true, onSelectionModelChange, isRowSelectable }
      : {}

  const columnsTranslated = columns.map((column) => ({
    ...column,
    headerName: t(column.headerName ?? '')
  }))

  return (
    <Box sx={boxSx || { width: '100%' }}>
      <StyledDataGrid
        pagination
        loading={loading}
        columns={columnsTranslated}
        rows={rows}
        autoHeight
        hideFooter={hideFooter}
        disableColumnMenu
        disableColumnFilter
        getRowId={getRowId}
        {...selectionProps}
        type={type}
        style={{
          border: '0'
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        localeText={{
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              t('general.form.table.currentPageLength', [
                `${from}-${to}`,
                count
              ]),
            labelRowsPerPage: t('general.form.table.rowsPerPage')
          },
          noRowsLabel: t('general.form.table.noRows') as string
        }}
      />
    </Box>
  )
}

export default DataGrid
export { DataGrid }
