import React from 'react'

import { useLanguage } from 'context/Language'

import { Select } from '../Select'
import { SelectProps } from './types'

type QuarterSelectProps = SelectProps & {
  appendOptions?: { label: string; value: string | number }[]
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function QuarterSelect(props: QuarterSelectProps) {
  const {
    name,
    label,
    margin,
    fullWidth,
    sx,
    appendOptions = [],
    disabled
  } = props

  const { t } = useLanguage()

  const quarterOptions = [
    { label: t('financial.incomeReports.quarters.first'), value: 'PRI' },
    { label: t('financial.incomeReports.quarters.second'), value: 'SEG' },
    { label: t('financial.incomeReports.quarters.third'), value: 'TER' },
    { label: t('financial.incomeReports.quarters.fourth'), value: 'QUA' }
  ]

  const options = [...appendOptions, ...quarterOptions]

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={options ?? []}
      sx={sx}
      disabled={disabled}
    />
  )
}

export default QuarterSelect
export { QuarterSelect }
