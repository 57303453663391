import { InsuranceService } from '../service/Insurance.service'
import PolicyDTO from '../../models/PolicyDTO'
import InsuranceItemDTO from '../../models/InsuranceItemDTO'
import InsuranceClaimDTO from '../../models/InsuranceClaimDTO'

/**
 * @returns PolicyDTO[]
 */
export async function getInsurances() {
  const service = new InsuranceService()
  const response = await service.getInsurances()

  return response.data.map((policy) => new PolicyDTO().fromJSON(policy))
}

/**
 * @param policyNumber string
 * @returns InsuranceItemDTO[]
 */
export async function getInsuranceItems(policyNumber: string) {
  const service = new InsuranceService()
  const response = await service.getInsuranceItems(policyNumber)

  return response.data.map((item) => new InsuranceItemDTO().fromJSON(item))
}

/**
 * @returns InsuranceItemDTO[]
 */
export async function getHasInsuranceContract() {
  const service = new InsuranceService()
  const response = await service.getHasInsuranceContract()

  return response.data
}

/**
 * @returns InsuranceClaimDTO[]
 */
export async function getInsuranceClaims() {
  const service = new InsuranceService()
  const response = await service.getInsuranceClaims()

  return response.data.map((item) => new InsuranceClaimDTO().fromJSON(item))
}
