import NotificationDTO from '@volvo-apps/shared/models/NotificationDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudList } from 'components/CrudList'
import { PlainButton } from 'components/PlainButton'

import { RegistersRoutesEnum as PushNotificationsRoutesEnum } from 'routes/cms/routesEnums'

import { useCrudNotification } from 'hooks/cms/useCrudNotification'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type NotificationJSON = ReturnType<NotificationDTO['toJSON']>

/**
 * Push Notifications List
 *
 * @returns TSX
 */
function List() {
  const { notifications, updateAsyncNotification, getLoading } =
    useCrudNotification()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const items = React.useMemo(() => {
    return (
      notifications?.map((item) => ({
        ...item.toJSON(),
        primary: item.title,
        id: item.id
      })) ?? []
    )
  }, [notifications])

  const handleEdit = React.useCallback(
    (notification: NotificationJSON) => {
      const path =
        PushNotificationsRoutesEnum.PUSH_NOTIFICATIONS_UPDATE.replace(
          ':id',
          notification.id as string
        )

      navigate(path)
    },
    [navigate]
  )

  const handletoggle = React.useCallback(
    async (notification: NotificationJSON, checked: boolean) => {
      const newNotification = new NotificationDTO().fromJSON({
        ...notification,
        active: checked
      })

      const updateResponse = await updateAsyncNotification({
        notification: newNotification
      })

      toast.success(
        updateResponse.data.active
          ? t('cms.pushNotifications.toggle.enabled')
          : t('cms.pushNotifications.toggle.disabled')
      )
    },
    [updateAsyncNotification, t]
  )

  const handleCreate = React.useCallback(() => {
    navigate(PushNotificationsRoutesEnum.PUSH_NOTIFICATIONS_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.pushNotifications.list.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.pushNotifications.list.title')}
      </Typography>

      <CrudList
        items={items}
        title={t('cms.pushNotifications.list.subtitle')}
        toggleKey="active"
        handleEdit={handleEdit}
        handleToggle={handletoggle}
        getLoading={getLoading && !notifications}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
