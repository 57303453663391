import SimulationParameterDTO from '@volvo-apps/shared/models/SimulationParameterDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { ParametersRoutesEnum as SimulationParametersRoutesEnum } from 'routes/cms/routesEnums'

import { useCreateSimulationParameter } from 'hooks/simulationParameters/useCreateSimulationParameter'

import Colors from 'constants/Colors'

import { decimalStrValueToNumber } from 'common/utils/number'
import { useLanguage } from 'context/Language'

import { SimulationParameterForm } from '../Form/SimulationParameterForm'
import { useSimulationParameterForm } from '../Form/useSimulationParameterForm'
import { OnSubmitSimulationParameter } from '../types'

/**
 * Simulation Params Create
 *
 * @returns TSX
 */
function Create() {
  const taxesDefault = {
    tacValue: '0.00',
    bndesCondition: '',
    bndesSequence: '',
    ipcaValue: '0.00',
    selicValue: '0.00',
    moneyLenderInsurance: false,
    isSelicInterestRate: false,
    monthlyInterestRate: '0.00',
    program: '',
    subProgram: '',
    volvoRate: '0.00'
  }

  const { methods } = useSimulationParameterForm({
    defaultValues: {
      taxes: taxesDefault
    }
  })

  const navigate = useNavigate()
  const { t } = useLanguage()

  const { mutate, isLoading } = useCreateSimulationParameter({
    onSuccess: () => {
      toast.success(t('cms.simulationParameters.create.success'))
      navigate(SimulationParametersRoutesEnum.SIMULATION_PARAMS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: OnSubmitSimulationParameter) => {
      const { taxes, ...formRest } = formData
      const simulationParameter = new SimulationParameterDTO().fromJSON({
        ...formRest,
        ...taxes,
        moneyLenderInsurance: formData.moneyLenderInsurance === 'true',
        isSelicInterestRate: formData.isSelicInterestRate === 'true',
        tacValue: decimalStrValueToNumber(taxes.tacValue),
        ipcaValue: decimalStrValueToNumber(taxes.ipcaValue),
        selicValue: decimalStrValueToNumber(taxes.selicValue),
        monthlyInterestRate: decimalStrValueToNumber(taxes.monthlyInterestRate),
        volvoRate: decimalStrValueToNumber(taxes.volvoRate),
        parametersDetailDto: [
          { ...formData.installments, isInstallmentParameters: true },
          { ...formData.gracePeriod, isInstallmentParameters: false }
        ]
      })
      mutate(simulationParameter)
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.simulationParameters.create.title')}
      </Typography>
      <SimulationParameterForm
        isLoading={isLoading}
        methods={methods}
        onSubmit={onSubmit}
        submitButtonLabel={t('cms.simulationParameters.create.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
