import React from 'react'

import { Grid } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'

import { Form, Input } from 'components'
import { RolesSelect } from 'components/Form/Fields/RolesSelect'
import { PlainButton } from 'components/PlainButton'

import { useLanguage } from 'context/Language'

import { UsersAccessData } from './schema'

type UsersAccessFormProps = {
  onSubmit: (formData: UsersAccessData) => void
  isLoading: boolean
  methods: UseFormReturn<UsersAccessData, unknown>
  submitButtonLabel?: string | null
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function UsersAccessForm(props: UsersAccessFormProps) {
  const { onSubmit, isLoading, methods, submitButtonLabel } = props

  const { t } = useLanguage()

  const disabled = React.useMemo(
    () => !methods.formState.isValid || !methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.md,
          width: '100%'
        })
      }}
    >
      <Grid container rowGap={3} columnGap={2}>
        <Grid item sm={5}>
          <Input
            margin="normal"
            fullWidth
            label={t('cms.usersAccess.form.document')}
            name="document"
            disabled
            sx={{ mt: 0 }}
          />
        </Grid>
        <Grid item sm={5}>
          <Input
            margin="normal"
            fullWidth
            label={t('cms.usersAccess.form.name')}
            name="name"
            disabled
            sx={{ mt: 0 }}
          />
        </Grid>

        <Grid item sm={5}>
          <Input
            margin="normal"
            fullWidth
            label={t('cms.usersAccess.form.phone')}
            name="phoneNumber"
            disabled
            sx={{ mt: 0 }}
          />
        </Grid>
        <Grid item sm={5}>
          <Input
            margin="normal"
            fullWidth
            label={t('cms.usersAccess.form.email')}
            name="email"
            disabled
            sx={{ mt: 0 }}
          />
        </Grid>

        <Grid item sm={5}>
          <RolesSelect
            label={t('cms.usersAccess.form.role')}
            name="role"
            sx={{ width: '100%' }}
          />
        </Grid>

        <Grid item sm={10}>
          <PlainButton
            type="submit"
            fullWidth
            variant="contained"
            disabled={disabled}
            loading={isLoading}
            sx={{ mb: 2, maxWidth: '50%', p: 0, m: 0 }}
          >
            {submitButtonLabel}
          </PlainButton>
        </Grid>
      </Grid>
    </Form>
  )
}

export default UsersAccessForm
export { UsersAccessForm }
