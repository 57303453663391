import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as TermRoutesEnum } from 'routes/cms/routesEnums'

import { useCreateTerm } from 'hooks/terms/useCreateTerm'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { TermData } from '../Form/schema'
import { TermForm } from '../Form/TermForm'
import { useTermForm } from '../Form/useTermForm'

/**
 * TermsAndConditions Create
 *
 * @returns TSX
 */
function Create() {
  const { methods } = useTermForm({
    defaultValues: {
      text: ''
    }
  })
  const navigate = useNavigate()
  const { t } = useLanguage()
  const { mutate, isLoading } = useCreateTerm({
    onSuccess: () => {
      toast.success(t('cms.terms.create.success'))
      navigate(TermRoutesEnum.TERMS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: TermData) => {
      mutate({
        termText: formData.text,
        accepted: true
      })
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.terms.create.title')}
      </Typography>
      <TermForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.terms.create.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
