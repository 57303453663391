import { NpsOriginEnum } from '@volvo-apps/shared/common/utils/enums'
import { downloadSummarySimulationFile } from '@volvo-apps/shared/core/actions/UserSimulation'

import React from 'react'

import { Typography } from '@mui/material'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'
import ShareAndRateButton from 'components/ShareAndRateButton/ShareAndRateButton'

import { SimulationRoutesEnum } from 'routes/simulation.routes'

import { useUserSimulationValidated } from 'hooks/useUserSimulationValidated'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { Details, Benefits, VehiclePictures } from './components'

/**
 * @returns TSX
 */
function SimulationSummary() {
  const { simulation } = useUserSimulationValidated()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const { mutate, isLoading } = useMutation(
    ['downloadSummarySimulationFile', simulation?.id],
    downloadSummarySimulationFile
  )

  const downloadPDF = () => {
    if (!simulation?.id) return
    mutate(simulation?.id)
  }

  const newSimulation = () => {
    navigate(SimulationRoutesEnum.DOCUMENT_SELECTION)
  }

  return (
    <FlexBox width="max(1200px,50%)" justifyContent="center">
      <FlexBox
        width="50%"
        flexDirection="column"
        mr={2}
        mt={1}
        justifyContent="start"
        style={{ opacity: simulation?.isValid ? 1 : 0.6 }}
      >
        <VehiclePictures />
      </FlexBox>
      <FlexBox width="50%" flexDirection="column">
        <Details isValid={simulation?.isValid} />
        <Benefits isValid={simulation?.isValid} />

        {!simulation?.isValid ? (
          <>
            <FlexBox sx={{ mt: 2 }}>
              <Icon family="mui" name="InfoOutlined" />
              <Typography color={Colors.light.greys.GREY_700} sx={{ ml: 1 }}>
                {t('simulation.summary.invalid')}
              </Typography>
            </FlexBox>

            <PlainButton
              variant="contained"
              sx={{ mt: 4, py: 1 }}
              onClick={newSimulation}
            >
              {t('simulation.summary.startNewSimulation')}
            </PlainButton>
          </>
        ) : (
          <ShareAndRateButton
            variant="outlined"
            sx={{ mt: 4, py: 1 }}
            style={{ color: Colors.light.blue }}
            onClick={downloadPDF}
            loading={isLoading}
            label={t('simulation.summary.save')}
            origin={NpsOriginEnum.SHARE_SIMULATION}
          />
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default SimulationSummary
export { SimulationSummary }
