import React from 'react'

import { Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'

import { Checkbox, Datepicker, Form, Input } from 'components'
import { FlexBox } from 'components/FlexBox'
import {
  FinancialProductsSelect,
  ProductLinesSelect,
  DocumentTypeSelect,
  IntervalSelect,
  DaysSelect
} from 'components/Form/Fields'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { optionAllId, optionAllString } from 'common/utils/pushNotifications'
import { useLanguage } from 'context/Language'

import { PushNotificationsData } from './schema'

type PushNotificationsFormProps = {
  onSubmit: (formData: PushNotificationsData) => void
  isLoading: boolean
  methods: UseFormReturn<PushNotificationsData, unknown>
  submitButtonLabel?: string | null
  shouldSetInitialDayOfWeek?: boolean
}

const dailyIntervalLabel = 'Diariamente'
const dsdnIntervalLabel = 'DSDN'

/**
 *
 * @param props Props
 * @returns TSX
 */
function PushNotificationsForm(props: PushNotificationsFormProps) {
  const {
    onSubmit,
    isLoading,
    methods,
    submitButtonLabel,
    shouldSetInitialDayOfWeek
  } = props

  const { t } = useLanguage()

  const disabled = React.useMemo(
    () => !methods.formState.isValid || !methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  const bodyMaxLength = 120
  const periodicity = methods.watch('interval')
  const body = methods.watch('body')
  const beginDate = methods.watch('beginDate')

  const productLine = methods.watch('productLine')

  const showDayOfWeekField =
    periodicity !== dailyIntervalLabel && periodicity !== dsdnIntervalLabel

  const isDisabled = methods.watch('sendToAll')

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '100%'
        })
      }}
    >
      <Input
        margin="normal"
        fullWidth
        label={t('cms.pushNotifications.form.name')}
        name="title"
        autoFocus
      />
      <Input
        margin="normal"
        fullWidth
        label={t('cms.pushNotifications.form.text')}
        name="body"
        multiline
        maxRows={0}
        rows={5}
        maxLength={bodyMaxLength}
      />
      <Typography fontSize={12} color={Colors.light.interactActive}>
        {t('cms.pushNotifications.form.charactersRemaining', [
          bodyMaxLength - body.length
        ])}
      </Typography>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.pushNotifications.form.periodicity')}
      </Typography>
      <FlexBox sx={{ flexDirection: 'row', gap: 2 }}>
        <Datepicker
          margin="normal"
          label={t('cms.pushNotifications.form.startDate')}
          name="beginDate"
          sx={{ width: '100%' }}
          onChange={() => {
            methods.trigger('endDate')
          }}
          minDate={new Date()}
        />
        <Datepicker
          margin="normal"
          label={t('cms.pushNotifications.form.endDate')}
          name="endDate"
          sx={{ width: '100%' }}
          minDate={beginDate || new Date()}
        />
      </FlexBox>
      <IntervalSelect
        margin="normal"
        fullWidth
        label={t('cms.pushNotifications.form.periodicity')}
        name="interval"
        labelAsValue
      />
      <FlexBox gap={2} sx={{ flexDirection: 'row' }}>
        {showDayOfWeekField && (
          <DaysSelect
            label={t('cms.pushNotifications.form.day')}
            name="dayWeek"
            sx={{ width: '100%' }}
            labelAsValue
            shouldSetInitialFormValue={shouldSetInitialDayOfWeek}
          />
        )}

        <Input
          label={t('cms.pushNotifications.form.hour')}
          name="hourDay"
          type="time"
          InputLabelProps={{
            shrink: true
          }}
          sx={{ width: '100%' }}
        />
      </FlexBox>
      <Typography
        mt={4}
        mb={0}
        fontSize={20}
        color={Colors.light.greys.GREY_600}
      >
        {t('cms.pushNotifications.form.targetAudience')}
      </Typography>
      <Checkbox
        label={t('cms.pushNotifications.form.sendToAll')}
        name="sendToAll"
        onChange={(checked) => {
          methods.setValue('sendToAll', checked)
          methods.setValue('productLine', optionAllId)
          methods.setValue('financialProduct', optionAllId)
          methods.setValue('documentType', optionAllString)
          methods.trigger('sendToAll')
        }}
      />
      <FlexBox
        sx={{
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center'
        }}
      >
        <DocumentTypeSelect
          label={t('cms.pushNotifications.form.clientType')}
          margin="normal"
          name="documentType"
          appendOptions={[
            {
              label: t('cms.pushNotifications.form.sendToAll'),
              value: optionAllString
            }
          ]}
          disabled={isDisabled}
        />
        <ProductLinesSelect
          name="productLine"
          margin="normal"
          fullWidth
          label={t('cms.pushNotifications.form.productLine')}
          appendOptions={[
            {
              label: t('cms.pushNotifications.form.sendToAll'),
              value: optionAllId
            }
          ]}
          disabled={isDisabled}
        />
      </FlexBox>
      <FinancialProductsSelect
        name="financialProduct"
        margin="normal"
        label={t('cms.pushNotifications.form.financing')}
        productLine={Number(productLine)}
        appendOptions={[
          {
            label: t('cms.pushNotifications.form.sendToAll'),
            value: optionAllId
          }
        ]}
        disabled={isDisabled}
        showOptionsOnlyAfterRequest
      />
      <PlainButton
        type="submit"
        fullWidth
        variant="contained"
        disabled={disabled}
        loading={isLoading}
        sx={{ mt: 3, mb: 2 }}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default PushNotificationsForm
export { PushNotificationsForm }
