import { createUserSimulation } from '@volvo-apps/shared/core/actions/UserSimulation'
import UserSimulationDTO from '@volvo-apps/shared/models/UserSimulationDTO'

import React from 'react'

import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { SimulationRoutesEnum } from 'routes/simulation.routes'

import { handleErrorFeedback } from 'common/utils/error'
import { useUserSimulation } from 'context/Simulation'

import { NextButton } from '../../Partials/NextButton'

type SubmitButtonProps = {
  disabled?: boolean
  onClick?: () => void
  payload: Pick<
    UserSimulationDTO,
    'gracePeriodMonths' | 'termMonths' | 'depositValue'
  >
  installmentAmount?: number
}

/**
 *
 * @param props SubmitButton Props
 * @returns TSX
 */
function SubmitButton(props: SubmitButtonProps) {
  const { disabled, payload, installmentAmount } = props

  const navigate = useNavigate()
  const { simulation, updateSimulation } = useUserSimulation()

  const { isLoading, mutate } = useMutation(
    ['createUserSimulation'],
    createUserSimulation,
    {
      onSuccess: (response) => {
        updateSimulation(
          new UserSimulationDTO().fromJSON({
            ...response,
            productLine: simulation?.productLine,
            financialPrduct: simulation?.financialProduct,
            isValid: true
          })
        )
        navigate(
          SimulationRoutesEnum.SIMULATION_SUMMARY.replace(
            ':id',
            String(response.id)
          ),
          {
            state: { featuredInstallmentAmount: installmentAmount }
          }
        )
      },
      onError: handleErrorFeedback
    }
  )

  const onSubmit = React.useCallback(() => {
    const { gracePeriodMonths, termMonths, depositValue } = payload
    const params = new UserSimulationDTO().withoutId({
      ...simulation,
      gracePeriodMonths,
      termMonths,
      depositValue,
      isValid: true
    })

    mutate(params)
  }, [payload, simulation, mutate])

  return (
    <NextButton
      disabled={disabled}
      isLoading={isLoading}
      onClick={onSubmit}
      testId="financing-amounts-next-button"
    />
  )
}

export default SubmitButton
export { SubmitButton }
