import { deleteCurrentUser } from '@volvo-apps/shared/core/actions/User'

import React from 'react'

import { Box, Typography } from '@mui/material'
import { useAuth } from 'context'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DialogDefault } from 'components/DialogDefault'
import { PlainButton } from 'components/PlainButton'

import { AppRoutesEnum } from 'routes/app.routes'

import Colors from 'constants/Colors'

import { ReactComponent as Remove } from 'assets/icons/remove.svg'
import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function ExcludeAccount() {
  const { logout } = useAuth()
  const navigation = useNavigate()
  const { t } = useLanguage()

  const { mutate, isLoading } = useMutation(deleteCurrentUser, {
    onSuccess: () => {
      navigation(AppRoutesEnum.MAIN)
      logout()
      toast.success(t('excludeAccount.success'))
    },
    onError: handleErrorFeedback
  })

  const handleExcludeAccount = React.useCallback(() => {
    mutate()
  }, [mutate])

  return (
    <Box mr={4} ml={4} width="fit-content">
      <Typography variant="h6" color={Colors.light.blue}>
        {t('excludeAccount.title')}
      </Typography>
      <Typography
        mt={2}
        mb={3}
        variant="body1"
        color={Colors.light.greys.GREY_600}
      >
        {t('excludeAccount.description')}
      </Typography>
      <Box display="flex" justifyContent="center">
        <DialogDefault
          triggerComponent={
            <PlainButton
              variant="outlined"
              color="inherit"
              style={{ width: '340px' }}
              loading={isLoading}
              startIcon={<Remove width={20} height={20} />}
            >
              {t('excludeAccount.button')}
            </PlainButton>
          }
          cancelText={t('excludeAccount.dialog.cancel')}
          confirmText={t('excludeAccount.dialog.confirm')}
          title={t('excludeAccount.dialog.title')}
          description={t('excludeAccount.dialog.description')}
          onConfirm={handleExcludeAccount}
        />
      </Box>
    </Box>
  )
}

export default ExcludeAccount
export { ExcludeAccount }
