import { getBannersWithConfig } from '@volvo-apps/shared/core/actions/Banner'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Get Banners Image and Transition Time
 *
 * @returns data, isLoading
 */
export const useGetBannersWithConfig = () => {
  const { data, isLoading } = useQuery(
    'bannersWithConfig',
    getBannersWithConfig,
    {
      onError: handleErrorFeedback
    }
  )

  return { data, isLoading }
}
