import RentalDetailsDTO from '@volvo-apps/shared/models/RentalDetailsDTO'

import React from 'react'

import { BoxProps, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Loader } from 'components/Loader'
import { NoInfo } from 'components/NoInfo'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import RentalInvoicesAccordion from './RentalInvoicesCard'

type RentalInvoicesProps = {
  boxProps?: BoxProps
  detailsLabel?: {
    to: string
    state: unknown
  }
  rental?: RentalDetailsDTO
  isLoading?: boolean
}

/**
 *
 * @param props RentalInvoicesProps
 * @returns TSX
 */
function RentalInvoices(props: RentalInvoicesProps) {
  const { rental, isLoading } = props

  const { t } = useLanguage()
  const invoices = rental?.rentalDetails?.Response?.Faturas ?? []

  return (
    <>
      <Typography
        fontFamily={'Volvo-Novum-Medium'}
        variant="subtitle1"
        color={Colors.light.interactActive}
      >
        {t('financial.rental.bills').toUpperCase()}
      </Typography>
      <FlexBox flexDirection="column" gap={3} pt={2} pb={3}>
        {isLoading ? (
          <Loader size={16} />
        ) : invoices.length === 0 ? (
          <NoInfo />
        ) : (
          invoices.map((invoice, index) => (
            <RentalInvoicesAccordion invoice={invoice} key={index} />
          ))
        )}
      </FlexBox>
    </>
  )
}

export default RentalInvoices
export { RentalInvoices }
