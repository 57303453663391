import { deleteSimulationParameter } from '@volvo-apps/shared/core/actions/SimulationParameter'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useDeleteSimulationParameterParams = {
  onSuccess?: () => void
}

/**
 * Hook to delete one simulation parameter
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteSimulationParameter = (
  params: useDeleteSimulationParameterParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data } = useMutation('deleteSimulationParameter', {
    mutationFn: ({ id }: { id: number }) => deleteSimulationParameter(id),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, data, isLoading }
}
