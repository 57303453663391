import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type ProductLineData = {
  /**
   *
   */
  name: string
  /**
   *
   */
  order: number
  /**
   *
   */
  minimumValue: string
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    name: Yup.string().required('validation.required.general'),
    order: Yup.number().required('validation.required.general'),
    minimumValue: Yup.string().required('validation.required.general')
  })
)
