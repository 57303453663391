import { useGetRentalDetails } from '@volvo-apps/shared/core/hooks/sales'
import RentalContractDTO from '@volvo-apps/shared/models/RentalContractDTO'

import React, { useEffect } from 'react'

import { Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import DownloadAdditionalInvoiceButton from 'components/DownloadAdditionalInvoiceButton/DownloadAdditionalInvoiceButton'
import DownloadMensalInvoiceButton from 'components/DownloadMensalInvoiceButton/DownloadMensalInvoiceButton'
import { FlexBox } from 'components/FlexBox'
import { NoInfo } from 'components/NoInfo'
import { RentalContractCard } from 'components/RentalContractCard'

import { FinancialRoutesEnum } from 'routes/financial.routes'
import { ExternalRoutesEnum } from 'routes/types'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import RentalInvoices from './Invoices/RentalInvoices'
import { LinkButton } from './LinkButton'
import RentalVehicles from './Vehicles/RentalVehicles'

/**
 * @returns TSX
 */
function RentalDetail() {
  const locationState = useLocation().state as {
    rentalContract?: RentalContractDTO
  }
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { data, isLoading } = useGetRentalDetails({
    document: locationState?.rentalContract?.documento ?? '',
    cacheTime: 0,
    retry: 0
  })

  useEffect(() => {
    if (!locationState?.rentalContract) {
      navigate(FinancialRoutesEnum.RENTAL)
    }
  }, [locationState, navigate])

  if (!locationState?.rentalContract) {
    return null
  }

  const rentalContract = locationState.rentalContract

  const cardStyle = {
    border: `1px solid ${Colors.light.greys.GREY_200}`,
    boxShadow: `1px 1px 5px ${Colors.light.shadow}`,
    width: '100%',
    p: 2,
    borderRadius: 2,
    flexGrow: 1
  }

  const buttonStyle = {
    width: 115,
    height: 100
  }

  const iconStyle = {
    marginRight: -8, // revert startIcon span margin
    width: '32px',
    height: '32px'
  }

  const monthlyStyle = data?.hasMonthlyBillFile
    ? {
        backgroundColor: Colors.light.tint
      }
    : {
        backgroundColor: Colors.light.greys.GREY_100,
        border: '0px',
        boxShadow: 'unset'
      }

  const additionalStyle = data?.hasAdditionalFile
    ? {
        backgroundColor: Colors.light.tint
      }
    : {
        backgroundColor: Colors.light.greys.GREY_100,
        border: '0px',
        boxShadow: 'unset'
      }

  return (
    <ContentContainer
      titleProps={{ color: Colors.light.interactActive }}
      title={t('financial.rental.contract').toUpperCase()}
    >
      {!rentalContract.isValid ? (
        <NoInfo />
      ) : (
        <>
          <FlexBox gap={4} flexDirection="row">
            <FlexBox
              {...cardStyle}
              {...monthlyStyle}
              {...buttonStyle}
              flexGrow={0}
              alignItems="center"
              p={0}
              flexDirection="row"
            >
              <DownloadMensalInvoiceButton
                iconStyle={iconStyle}
                label={t('financial.rental.downloadMensalInvoice')}
                rental={data}
              />
            </FlexBox>

            <FlexBox
              {...cardStyle}
              {...additionalStyle}
              {...buttonStyle}
              flexGrow={0}
              alignItems="center"
              p={0}
              flexDirection="row"
            >
              <DownloadAdditionalInvoiceButton
                label={t('financial.rental.downloadAdditionalInvoice')}
                iconStyle={iconStyle}
                rental={data}
              />
            </FlexBox>

            <FlexBox
              {...cardStyle}
              {...additionalStyle}
              {...buttonStyle}
              flexGrow={0}
              alignItems="center"
              p={0}
              flexDirection="row"
            >
              <LinkButton
                iconStyle={iconStyle}
                label={t('financial.contracts.finePortal')}
                href={ExternalRoutesEnum.FINE_PORTAL}
              />
            </FlexBox>

            <FlexBox
              {...cardStyle}
              {...additionalStyle}
              {...buttonStyle}
              flexGrow={0}
              alignItems="center"
              p={0}
              flexDirection="row"
            >
              <LinkButton
                iconStyle={iconStyle}
                label={t('financial.contracts.rentalGeneralConditions')}
                href={ExternalRoutesEnum.RENTAL_GENERAL_CONDITIONS}
              />
            </FlexBox>
          </FlexBox>
          <Typography
            fontFamily={'Volvo-Novum-Medium'}
            variant="subtitle2"
            color={Colors.light.interactActive}
            my={2}
          >
            {t('financial.rental.contractDetail')}
          </Typography>
          {rentalContract && (
            <RentalContractCard
              rentalContract={rentalContract}
              boxProps={cardStyle}
              showMoreDetails
              fromContractsScreen
              removeFinanceType
              disableTitle
            />
          )}
          <RentalVehicles
            document={rentalContract.documento}
            boxProps={cardStyle}
          />
          <RentalInvoices
            rental={data}
            boxProps={{ width: '100%' }}
            isLoading={isLoading}
          />
        </>
      )}
    </ContentContainer>
  )
}

export default RentalDetail
export { RentalDetail }
