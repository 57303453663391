import React, { PropsWithChildren } from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Localization(props: PropsWithChildren) {
  return <LocalizationProvider dateAdapter={AdapterDayjs} {...props} />
}

export default Localization
export { Localization }
