import React, { ElementType, PropsWithChildren } from 'react'

import { Button, ButtonProps as ButtonMUIProps } from '@mui/material'

import { Loader } from 'components/Loader'

import Colors from 'constants/Colors'

/**
 *
 */
export type PlainButtonProps = ButtonMUIProps & {
  /**
   *
   */
  loading?: boolean
  /**
   *
   */
  component?: ElementType
  testId?: string
}

/**
 *
 * @param props ButtonProps
 * @returns TSX
 */
function PlainButton(props: PropsWithChildren<PlainButtonProps>) {
  const {
    loading,
    children,
    disabled,
    style,
    component,
    testId,
    ...buttonMUIProps
  } = props

  const _size = buttonMUIProps.size || '1rem'
  const _disabled = loading || disabled
  const _style = {
    minHeight: '2.28rem',
    ...style
  }

  const _startIcon = !loading && buttonMUIProps.startIcon
  const _endIcon = !loading && buttonMUIProps.endIcon

  const borderColor =
    buttonMUIProps.variant === 'outlined'
      ? props.color || props.style?.color
      : 'initial'

  return (
    <Button
      component={component || 'button'}
      {...buttonMUIProps}
      disabled={_disabled}
      style={{ ..._style, borderColor }}
      startIcon={_startIcon}
      endIcon={_endIcon}
      data-testid={testId}
    >
      {loading ? (
        <Loader
          size={_size}
          sx={{ mx: 1.5, display: 'flex' }}
          style={{
            color: Colors.light.greys.GREY_500
          }}
        />
      ) : (
        children
      )}
    </Button>
  )
}

export default PlainButton
export { PlainButton }
