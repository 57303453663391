import BannerConfigDTO from '@volvo-apps/shared/models/BannerConfigDTO'

import { useGetCmsBanners } from 'hooks/banners/useGetCmsBanners'

import { useUpdateBanners } from '../banners/useUpdateBanners'

type CrudBannerParams = {
  onSuccessUpdate?: (response: BannerConfigDTO) => void
}

/**
 * Hook to use CRUD operations on banners
 *
 * @param params params
 * @returns banners, deleteBanner, updateBanner, refetch, isLoading, getLoading, deleteLoading, updateLoading, isFetching
 */
export const useCrudBanner = (params: CrudBannerParams = {}) => {
  const { onSuccessUpdate } = params
  const {
    bannersConfig,
    refetch,
    isLoading: getLoading,
    isFetching
  } = useGetCmsBanners()

  const {
    mutate: updateBanner,
    isLoading: updateLoading,
    mutateAsync: updateAsyncBanner
  } = useUpdateBanners({
    onSuccess: (params) => {
      onSuccessUpdate?.(params)
    }
  })

  const isLoading = getLoading || updateLoading

  return {
    bannersConfig,
    updateBanner,
    updateAsyncBanner,
    refetch,
    isLoading,
    getLoading,
    updateLoading,
    isFetching
  }
}
