import {
  loginApp,
  loginCms
} from '@volvo-apps/shared/core/actions/Authentication'
import LoginDTO from '@volvo-apps/shared/models/LoginDTO'

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState
} from 'react'

import { useQueryClient } from 'react-query'
import { redirect } from 'react-router-dom'

import { useLocalStorage } from 'hooks/useLocalStorage'

type IAuthContext = {
  token?: string
  login: (props: Pick<LoginDTO, 'email' | 'password'>) => Promise<void>
  adminLogin: (props: Pick<LoginDTO, 'email' | 'password'>) => Promise<void>
  logout: VoidFunction
  hasLogin: boolean
  isCms?: boolean
  setIsCms: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const warnMessage = () => {
  const msg = 'Auth Context should be present'
  console.warn(msg)
  return msg
}

const initialState: IAuthContext = {
  login: async () => {
    warnMessage()
    return Promise.resolve()
  },
  adminLogin: async () => {
    warnMessage()
    return Promise.resolve()
  },
  logout: async () => {
    warnMessage()
    return Promise.resolve()
  },
  hasLogin: false,
  token: undefined,
  isCms: false,
  setIsCms: () => {
    warnMessage()
  }
}

export const authContext = createContext<IAuthContext>(initialState)
const { Provider } = authContext

/**
 *
 * @param props Component Props
 * @returns JSX
 */
export const AuthProvider = (props: PropsWithChildren) => {
  const { children } = props
  const [token, setTokenStorage] = useLocalStorage('token', '')
  const [isCms, setIsCms] = useState<boolean | undefined>()
  const queryClient = useQueryClient()

  const login: IAuthContext['login'] = async (params) => {
    const { email, password } = params
    try {
      const tokenRequest = await loginApp({
        email,
        password,
        deviceType: 'WEB'
      })
      setTokenStorage(tokenRequest)
      setIsCms(false)
    } catch (err) {
      setTokenStorage('')
      throw err
    }
  }
  const adminLogin: IAuthContext['adminLogin'] = async (params) => {
    const { email, password } = params
    try {
      setTokenStorage('') // necessary to avoid profile data from previous login
      const tokenRequestCms = await loginCms({ email, password })
      setTokenStorage(tokenRequestCms)
      setIsCms(true)
    } catch (err) {
      setTokenStorage('')
      throw err
    }
  }

  const logout = () => {
    queryClient.removeQueries({ queryKey: 'user' })
    setTokenStorage('')
    setIsCms(false)
    redirect('/')
  }

  const providerProps = {
    token,
    login,
    adminLogin,
    logout,
    hasLogin: !!token,
    isCms,
    setIsCms
  }

  return <Provider value={providerProps}>{children}</Provider>
}

/**
 * @returns Context
 */
export const useAuth = () => useContext(authContext)
