import React from 'react'

import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Form, Modal, useModalMethods } from 'components'
import { TransitionTimeSlider } from 'components/Form/TransitionTimeSlider'
import { Loader } from 'components/Loader'
import { PlainButton } from 'components/PlainButton'

import { useGetAllTransitionInterval } from 'hooks/useGetAllTransitionInterval'
import { useUpdateTransitionInterval } from 'hooks/useUpdateTransitionInterval'

import Colors from 'constants/Colors'

import { makeResolver } from 'common/utils/form'
import { useLanguage } from 'context/Language'

const schema = makeResolver((Yup) =>
  Yup.object({
    transitionTime: Yup.number().required('validation.required.general')
  })
)

/**
 * Transition Time
 *
 * @returns TSX
 */
function TransitionTime() {
  const { open, toggleOpen, setClosed } = useModalMethods()

  const { t } = useLanguage()

  const methods = useForm<{ transitionTime: number }>({
    mode: 'onChange',
    resolver: schema,
    defaultValues: {
      transitionTime: 0
    }
  })

  const { data, isLoading: getLoading } = useGetAllTransitionInterval({
    onSuccess: (transitionTimes) => {
      methods.reset({
        transitionTime: transitionTimes[0].transitionTime
      })
    }
  })

  const { mutateAsync, isLoading: updateLoading } =
    useUpdateTransitionInterval()

  const onSubmit = React.useCallback(async () => {
    await mutateAsync({
      id: data?.length ? data[0].id : 0,
      transitionTime: methods.getValues().transitionTime
    })
    setClosed()
  }, [data, methods, mutateAsync, setClosed])

  const Content = React.useMemo(
    () => (
      <Box mt={2}>
        <Typography
          mb={4}
          variant="body1"
          color={Colors.light.greys.GREY_600}
          fontSize={16}
        >
          {t('cms.vehicles.transitionTime.description')}
        </Typography>
        <Form methods={methods} onSubmit={onSubmit}>
          {getLoading ? (
            <Loader />
          ) : (
            <TransitionTimeSlider name="transitionTime" />
          )}
        </Form>
      </Box>
    ),
    [getLoading, methods, onSubmit, t]
  )

  const isLoading = getLoading || updateLoading

  return (
    <>
      <PlainButton
        variant="outlined"
        onClick={toggleOpen}
        sx={{ py: 0.5, px: 3, mb: 4 }}
        style={{ color: Colors.light.text, width: 'fit-content' }}
      >
        {t('cms.vehicles.transitionTime.button')}
      </PlainButton>
      <Modal
        showCloseButton
        title={
          <Typography fontSize={24} sx={{ color: Colors.light.text }}>
            {t('cms.vehicles.transitionTime.title')}
          </Typography>
        }
        PaperProps={{
          style: {
            maxWidth: 'fit-content',
            padding: '16px'
          }
        }}
        content={Content}
        actions={[
          {
            children: t('general.save'),
            color: 'success',
            style: {
              backgroundColor: Colors.light.interactDefault,
              width: '100%'
            },
            onClick: methods.handleSubmit(onSubmit),
            variant: 'contained',
            type: 'submit',
            loading: isLoading,
            disabled: isLoading
          }
        ]}
        onClose={setClosed}
        open={open}
      />
    </>
  )
}

export default TransitionTime
export { TransitionTime }
