import { useForm } from 'react-hook-form'

import { NpsData, schema } from './schema'

type NpsProps = {
  defaultValues?: Partial<NpsData>
}

/**
 *
 * @param props - NpsProps
 * @returns useForm methods
 */
export const useNpsForm = (props: NpsProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<NpsData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
