import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type PushNotificationsData = {
  /**
   *
   */
  title: string
  /**
   *
   */
  body: string
  /**
   *
   */
  interval: string
  /**
   *
   */
  dayWeek: string
  /**
   *
   */
  hourDay: string
  /**
   *
   */
  beginDate: Date
  /**
   *
   */
  endDate: Date
  /**
   *
   */
  documentType: string
  /**
   *
   */
  financialProduct: string | number
  /**
   *
   */
  productLine: string | number
  /**
   *
   */
  sendToAll: boolean
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    title: Yup.string().required('validation.required.general'),
    body: Yup.string().required('validation.required.general'),
    interval: Yup.string().required('validation.required.general'),
    dayWeek: Yup.string().required('validation.required.general'),
    hourDay: Yup.string().required('validation.required.general'),
    beginDate: Yup.date()
      .nullable()
      .typeError('validation.invalid.date')
      .test(
        'startDateIsValid',
        'validation.invalid.date',
        (value?: Date | null) =>
          !!value &&
          value.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
      )
      .required('validation.required.general'),
    endDate: Yup.date()
      .nullable()
      .typeError('validation.invalid.date')
      .test(
        'endDateIsValid',
        'validation.invalid.endDate',
        (
          value?: Date | null,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          context: {
            /**
             *
             */
            parent: PushNotificationsData
          }
        ) =>
          context.parent?.beginDate instanceof Date && !!value
            ? value.setHours(0, 0, 0, 0) >=
              context.parent?.beginDate?.setHours(0, 0, 0, 0)
            : false
      )
      .required('validation.required.general'),
    documentType: Yup.string().required('validation.required.general'),
    financialProduct: Yup.string().required('validation.required.general'),
    productLine: Yup.string().required('validation.required.general'),
    sendToAll: Yup.boolean().transform((value) => {
      return !!value
    })
  })
)
