import React, { createContext, PropsWithChildren, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { useLocalStorage } from 'hooks/useLocalStorage'

import i18n from 'i18n/i18nInstance'

type ILanguageContext = {
  lng: string
  setLng?: (lng: string) => void
  i18n: typeof i18n
  t: typeof i18n.t
}

const initialState: ILanguageContext = {
  lng: 'pt',
  i18n,
  t: i18n.t
}

export const languageContext = createContext<ILanguageContext>(initialState)
const { Provider } = languageContext

/**
 *
 * @param props Component Props
 * @returns JSX
 */
export const LanguageProvider = (props: PropsWithChildren) => {
  const { children } = props
  const [lng, setLng] = useLocalStorage('lng', 'pt')
  const { t, i18n } = useTranslation()

  React.useEffect(() => {
    i18n.changeLanguage(lng)
  }, [i18n, lng])

  const providerProps = {
    lng,
    setLng,
    i18n,
    t
  }

  return <Provider value={providerProps}>{children}</Provider>
}

/**
 * @returns Context
 */
export const useLanguage = () => useContext(languageContext)
