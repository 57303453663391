import { getAllCategories } from '@volvo-apps/shared/core/actions/Category'
import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCategoriesParams = {
  onSuccess: (data: FaqCategoryDTO[]) => void
}

/**
 *
 * @param params useCategoriesParams
 * @returns Data
 */
const useCategories = (params: useCategoriesParams) => {
  const { onSuccess } = params
  const { data, isLoading } = useQuery<FaqCategoryDTO[]>(
    'categories',
    getAllCategories,
    {
      onSuccess,
      onError: handleErrorFeedback
    }
  )

  return {
    data,
    isLoading
  }
}

export default useCategories
