import { useDeleteUserRole } from '@volvo-apps/shared/core/hooks/role/useDeleteUserRole'
import { useGetUserRoles } from '@volvo-apps/shared/core/hooks/role/useGetUserRoles'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { queryClient } from 'components'
import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'
import { PlainButton } from 'components/PlainButton'

import { UserSettingsRoutesEnum } from 'routes/cms/routesEnums'

import useSearchBar from 'hooks/useSearchBar'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { columns } from './tableColumns'

/**
 * Roles List
 *
 * @returns TSX
 */
function List() {
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { searchTerm, searchBar } = useSearchBar({
    style: { margin: '24px 0 16px 0', width: 450 }
  })

  const { roles, isLoading } = useGetUserRoles()

  const { mutate } = useDeleteUserRole({
    onSuccess: async () => {
      toast.success(t('cms.roles.delete.success'))
      await queryClient.invalidateQueries({ queryKey: ['getAllUserRoles'] })
    }
  })

  const handleDelete = React.useCallback(
    (role: Record<string, unknown>) => {
      mutate({ id: role.id as number })
    },
    [mutate]
  )

  const handleCreate = React.useCallback(() => {
    navigate(UserSettingsRoutesEnum.ROLES_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.roles.list.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.roles.list.title')}
      </Typography>
      {searchBar}
      <CrudDataGrid
        type="striped"
        columns={columns}
        rows={
          Array.isArray(roles)
            ? roles.filter((roleDTO) =>
                roleDTO.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
            : []
        }
        linkEditProps={{
          to: (id) => UserSettingsRoutesEnum.ROLES_UPDATE.replace(':id', id),
          stateProperty: 'roleDTO'
        }}
        actionsConfig={{
          headerName: t('cms.roles.list.actions') as string,
          headerAlign: 'right'
        }}
        loading={isLoading}
        handleDelete={handleDelete}
        hideFooter
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
