import React from 'react'

import { ButtonBase, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { FlexBox } from 'components/FlexBox'
import { Input } from 'components/Form'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { FormSchema, Option } from './Form'

const options: Option[] = [
  { value: 1, color: '#BF2012', text: 'Péssimo!', icon: 'sad' },
  { value: 2, color: '#D92D20', text: 'Péssimo!', icon: 'sad' },
  { value: 3, color: '#F04438', text: 'Ruim!', icon: 'notGood' },
  { value: 4, color: '#F79009', text: 'Ruim!', icon: 'notGood' },
  { value: 5, color: '#FDB022', text: 'Regular!', icon: 'regular' },
  { value: 6, color: '#FEC84B', text: 'Regular!', icon: 'regular' },
  { value: 7, color: '#A8D46B', text: 'Muito bom!', icon: 'good' },
  { value: 8, color: '#8FC54E', text: 'Muito Bom!', icon: 'good' },
  { value: 9, color: '#78B833', text: 'Excelente!', icon: 'excelent' },
  { value: 10, color: '#1A6C31', text: 'Excelente!', icon: 'excelent' }
]

/**
 *
 * @returns TSX
 */
const Content = () => {
  const methods = useFormContext<FormSchema>()

  const { t } = useLanguage()

  const selectedOption = methods.watch('option')
  const reason = methods.watch('reason')

  const handleOptionClick = React.useCallback(
    (option: Option) => {
      methods.setValue('option', option, { shouldValidate: true })
    },
    [methods]
  )

  const optionsGap = 4

  return (
    <FlexBox flexDirection="column" gap={2}>
      <Typography color={Colors.light.text} textAlign="center" fontSize={20}>
        {t('nps.rateModal.subtitle')}
      </Typography>
      <Typography
        color={Colors.light.greys.GREY_600}
        textAlign="center"
        fontSize={16}
      >
        {t('nps.rateModal.info')}
      </Typography>
      <FlexBox
        style={{
          flexWrap: 'wrap',
          width: '60%',
          alignSelf: 'center',
          justifyContent: 'center',
          gap: optionsGap
        }}
      >
        {Object.values(options).map((option, index) => {
          const isSelected = selectedOption?.value === option.value
          return (
            <ButtonBase
              key={index}
              sx={{
                backgroundColor: option.color,
                width: `calc(20% - ${optionsGap * 3}px)`,
                padding: `calc(10% - ${optionsGap * 1.5}px)`,
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                border: isSelected ? '1px solid #000' : 'none',
                boxShadow: isSelected
                  ? '2px 4px 4px rgba(0, 0, 0, 0.25)'
                  : 'none',
                boxSizing: 'border-box'
              }}
              onClick={() => handleOptionClick(option)}
            >
              <Typography
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: Colors.light.background
                }}
              >
                {option.value}
              </Typography>
            </ButtonBase>
          )
        })}
      </FlexBox>
      {selectedOption && (
        <FlexBox>
          <Icon family="svg" name={selectedOption.icon} />
          <Typography color={Colors.light.greys.GREY_600} fontSize={16} pl={1}>
            {selectedOption.text}
          </Typography>
        </FlexBox>
      )}

      <Typography color={Colors.light.greys.GREY_600} fontSize={16}>
        {t('nps.rateModal.reason')}
      </Typography>
      <FlexBox flexDirection="column">
        <Input
          margin="normal"
          fullWidth
          name="reason"
          placeholder={t('nps.rateModal.reasonPlaceholder') as string}
          multiline
          maxRows={0}
          rows={5}
        />
        {selectedOption && selectedOption.value < 5 && (
          <Typography
            fontSize={12}
            color={
              methods.formState.errors.reason
                ? Colors.light.error
                : Colors.light.interactActive
            }
          >
            {t('nps.rateModal.remaining', [Math.max(140 - reason.length, 0)])}
          </Typography>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default Content
export { Content }
