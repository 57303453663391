import 'reflect-metadata'
import { jsonProperty, Serializable, jsonName } from 'ts-serializable'

/**
 * ScrApprovalDTO
 */
class ScrApprovalDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedDate!: string

  @jsonName('authorizationTime')
  @jsonProperty(String)
  public authorizationTime!: string

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('userId')
  @jsonProperty(Number)
  public userId!: number

  @jsonName('userDocumentAuthorized')
  @jsonProperty(String)
  public userDocumentAuthorized!: string

  @jsonName('geolocation')
  @jsonProperty(String)
  public geolocation!: string

  @jsonName('deviceAuthorized')
  @jsonProperty(String)
  public deviceAuthorized!: string

  @jsonName('authorized')
  @jsonProperty(Boolean)
  public authorized!: boolean

  @jsonName('userIp')
  @jsonProperty(String)
  public userIp!: string

  @jsonName('hashCode')
  @jsonProperty(String)
  public hashCode!: string
}

export default ScrApprovalDTO
