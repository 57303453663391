import UserSimulationDTO from '@volvo-apps/shared/models/UserSimulationDTO'

import React, { useEffect } from 'react'

import { Divider } from '@mui/material'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { FlexBox } from 'components/FlexBox'

import { SimulationRoutesEnum } from 'routes/simulation.routes'

import { useGetParametersDetailById } from 'hooks/simulationParameters/useGetParametersDetailById'
import { useUserSimulationValidated } from 'hooks/useUserSimulationValidated'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { toCurrency } from 'common/utils/money'
import { useLanguage } from 'context/Language'

import {
  calculateMinInstallmentAmount,
  getStartupPaidAmount
} from '../../calculator'
import StepsHeader from '../Partials/StepsHeader/StepsHeader'
import {
  GracePeriod,
  Installments,
  StartupPaidPercent,
  SubmitButton
} from './components'

export const awaitingMonthsFetchValue = -1

/**
 * @returns TSX
 */
function FinancingAmounts() {
  const { t } = useLanguage()
  const navigate = useNavigate()
  const { simulation } = useUserSimulationValidated()

  const {
    parametersDetailFiltered: { gracePeriod, installments }
  } = useGetParametersDetailById({
    cacheTime: 0,
    productLineId: simulation?.productLine as number,
    financialProductId: simulation?.financialProduct as number
  })

  const [termMonths, setTermMonths] = React.useState<number>(
    awaitingMonthsFetchValue
  )
  const [gracePeriodMonths, setGracePeriodMonths] = React.useState<number>(
    awaitingMonthsFetchValue
  )
  const [startupPaidPercent, setStartupPaidPercent] = React.useState<number>(20)

  const handleBack = () => {
    navigate(SimulationRoutesEnum.CHOOSE_FINANCING)
  }

  const depositValue = React.useMemo(
    () =>
      getStartupPaidAmount({
        startupPaidPercent,
        totalFinanceAmount: simulation?.productValue ?? 0
      }),
    [simulation?.productValue, startupPaidPercent]
  )

  const getStartupAmountSliderLabel = React.useCallback(
    (value: number) => {
      return `${toCurrency(depositValue).withSymbol} (${value}%)`
    },
    [depositValue]
  )

  const calculateMinInstallmentAmountProps = React.useMemo(() => {
    return {
      ...new UserSimulationDTO().withoutId({
        ...simulation,
        termMonths,
        gracePeriodMonths:
          gracePeriodMonths === 0 ? undefined : gracePeriodMonths,
        depositValue
      })
    }
  }, [simulation, termMonths, gracePeriodMonths, depositValue])

  const { isLoading, data, mutate } = useMutation(
    ['installmentAmount', calculateMinInstallmentAmountProps],
    calculateMinInstallmentAmount,
    {
      onError: handleErrorFeedback
    }
  )

  useEffect(() => {
    if (
      calculateMinInstallmentAmountProps.isValid ||
      termMonths === awaitingMonthsFetchValue
    )
      return
    mutate(calculateMinInstallmentAmountProps)
  }, [calculateMinInstallmentAmountProps, mutate, termMonths])

  useEffect(() => {
    if (installments) setTermMonths(installments?.valueStarts ?? 0)
    if (gracePeriod) setGracePeriodMonths(gracePeriod?.valueStarts ?? 0)
  }, [gracePeriod, installments])

  return (
    <FlexBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '60%'
      }}
    >
      <StepsHeader
        handleBack={handleBack}
        step={2}
        title={t('simulation.financingAmounts.title')}
      />
      <FlexBox
        width="100%"
        justifyContent="center"
        textAlign="center"
        flexDirection="column"
      >
        <StartupPaidPercent
          startupPaidPercent={startupPaidPercent}
          disabled={isLoading}
          setStartupPaidPercent={setStartupPaidPercent}
          label={getStartupAmountSliderLabel(startupPaidPercent)}
        />

        {installments && (
          <>
            <Divider
              sx={{
                my: 4,
                color: Colors.light.greys.GREY_300
              }}
            />
            <Installments
              installmentAmount={data}
              disabled={isLoading}
              installmentsDetail={installments}
              onChange={setTermMonths}
              termMonths={termMonths}
              gracePeriodMonths={gracePeriodMonths}
              gracePeriodDetail={gracePeriod}
            />
          </>
        )}

        {gracePeriod && (
          <>
            <Divider
              sx={{
                my: 4,
                color: Colors.light.greys.GREY_300
              }}
            />
            <GracePeriod
              gracePeriodMonths={gracePeriodMonths}
              disabled={isLoading}
              gradePeriodDetail={gracePeriod}
              onChange={setGracePeriodMonths}
            />
          </>
        )}
      </FlexBox>

      <SubmitButton
        disabled={isLoading}
        installmentAmount={data}
        payload={{ depositValue, gracePeriodMonths, termMonths }}
      />
    </FlexBox>
  )
}

export default FinancingAmounts
export { FinancingAmounts }
