import React, { Fragment } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { NoPermissionContainer } from 'components/NoPermissionContainer'

import { useURLQuery } from 'hooks/useURLQuery'

import { useAuth } from 'context/Auth'

import { CustomRouteProps } from './types'

const loginRequiredReason = 'loginRequired'

type Props = CustomRouteProps

const withoutReasonPages: unknown[] = ['/profile/changepassword']

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function CustomRoute(props: Props) {
  const {
    elementWithLogin,
    element,
    layout: Layout = Fragment,
    layoutProps,
    breadcrumbs,
    role,
    ...route
  } = props
  const { hasLogin, isCms } = useAuth()
  const { pathname } = useLocation()
  const adminLoginPath = '/admin/login'
  const pageToRedirect = useURLQuery().get('continue')

  if (!hasLogin && route.protected) {
    if (withoutReasonPages.includes(pathname)) {
      return <Navigate to="/login" />
    }

    return (
      <Navigate
        to={`/login?reason=${loginRequiredReason}&continue=${pathname}`}
      />
    )
  }

  const userNeedsAdminLogin = !isCms && pathname === adminLoginPath

  if (hasLogin && route.noAuthOnly && !userNeedsAdminLogin) {
    const homePath = isCms ? '/admin/home' : '/dashboard'
    return <Navigate to={pageToRedirect || homePath} />
  }

  return (
    <Layout breadcrumbs={breadcrumbs} {...layoutProps}>
      <NoPermissionContainer role={role}>
        {hasLogin && elementWithLogin ? elementWithLogin : element}
      </NoPermissionContainer>
    </Layout>
  )
}

export default CustomRoute
export { CustomRoute }
