import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getUserDomains } from '../../actions/UserDomains'
import FunctionalDomainDTO from '../../../models/UserDomainDTO'

type getUserDomainsParams = HookQueryParams<FunctionalDomainDTO>

/**
 * Hook to get all function domains
 *
 * @param params params
 * @returns domains, isLoading, refetch
 */
export const useGetUserDomains = (params: getUserDomainsParams = {}) => {
  const { onSuccess, onError } = params
  const {
    data: domains,
    isLoading,
    refetch
  } = useQuery('getUserDomains', {
    queryFn: getUserDomains,
    onSuccess,
    onError
  })

  return { domains, isLoading, refetch }
}
