import { addNotification } from '@volvo-apps/shared/core/actions/Leads'
import LeadNotificationDTO from '@volvo-apps/shared/models/LeadNotificationDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useAddLeadNotificationParams = {
  onSuccess?: () => void
}

/**
 * Hook to add lead notification
 *
 * @param params The params
 * @returns leadNotification, isLoading, mutate, mutateAsync
 */
export const useAddLeadNotification = (
  params: useAddLeadNotificationParams
) => {
  const { onSuccess } = params
  const {
    data: leadNotification,
    isLoading,
    mutate,
    mutateAsync
  } = useMutation('addLeadNotication', {
    mutationFn: (leadNotification: LeadNotificationDTO) =>
      addNotification(leadNotification),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { leadNotification, isLoading, mutate, mutateAsync }
}
