import { downloadExtractPdf } from '@volvo-apps/shared/core/actions/IncomeReport'
import IncomeReportDTO from '@volvo-apps/shared/models/IncomeReportDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import IncomeReportForm from './IncomeReportForm'
import { IncomeReportFormData } from './schema'
import { useIncomeReportForm } from './useIncomeReportForm'

/**
 * @returns TSX
 */
function IncomeReports() {
  const { t } = useLanguage()
  const { methods } = useIncomeReportForm({
    defaultValues: {
      document: '',
      year: undefined,
      enableQuarter: false
    }
  })

  const { mutate, isLoading } = useMutation('downloadIrPDF', {
    mutationFn: downloadExtractPdf,
    onError: () => {
      toast.error(t('financial.incomeReports.error.downloadPDF'))
    }
  })
  const onSubmit = React.useCallback(
    (formData: IncomeReportFormData) => {
      const incomeReportDTO = new IncomeReportDTO().fromJSON({
        documentNumber: formData.document?.toString(),
        year: formData.year?.toString(),
        period: !formData.enableQuarter ? 'ANO' : formData.period?.toString()
      })
      mutate(incomeReportDTO)
    },
    [mutate]
  )

  return (
    <FlexBox flexDirection={'column'} width={'80%'}>
      <Typography
        fontFamily={'Volvo-Novum-Medium'}
        fontSize={20}
        color={Colors.light.blue}
      >
        {t('financial.incomeReports.title').toUpperCase()}
      </Typography>

      <Typography
        fontFamily={'Volvo-Novum-Medium'}
        fontSize={16}
        color={Colors.light.greys.GREY_600}
      >
        {t('financial.incomeReports.subtitle')}
      </Typography>
      <IncomeReportForm
        onSubmit={onSubmit}
        methods={methods}
        isLoading={isLoading}
        submitButtonLabel={t('financial.incomeReports.getReport')}
      />
    </FlexBox>
  )
}

export default IncomeReports
export { IncomeReports }
