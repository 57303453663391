import * as React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { PlainButton } from 'components/PlainButton'

import { useLanguage } from 'context/Language'

type DialogDefaultProps = {
  triggerComponent?: JSX.Element
  title: string
  description: string
  cancelText?: string | null
  confirmText?: string | null
  onConfirm?: () => void
  onCancel?: () => void
  opened?: boolean
  disableCancel?: boolean
}

/**
 * @param props DialogDefaultProps
 * @returns TSX
 */
function DialogDefault(props: DialogDefaultProps) {
  const {
    triggerComponent,
    title,
    description,
    cancelText,
    confirmText,
    onConfirm,
    onCancel,
    opened,
    disableCancel
  } = props
  const [open, setOpen] = React.useState(opened || false)

  const { t } = useLanguage()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    onCancel?.()
    setOpen(false)
  }

  const handleConfirm = () => {
    onConfirm?.()
    setOpen(false)
  }

  const TriggerComponent = triggerComponent ? (
    React.cloneElement(triggerComponent, {
      onClick: handleClickOpen
    })
  ) : (
    <></>
  )

  return (
    <div style={{ width: 'fit-content' }}>
      {TriggerComponent}
      <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <PlainButton onClick={handleConfirm}>
            {confirmText || t('general.confirm')}
          </PlainButton>
          {!disableCancel && (
            <PlainButton onClick={handleClose}>
              {cancelText || t('general.back')}
            </PlainButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogDefault
export { DialogDefault }
