import { GridColDef } from '@mui/x-data-grid'

/**
 *
 * @param handleEdit handleEdit
 * @returns TSX
 */
export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'cms.leads.automation.list.columns.id',
    width: 200,
    sortable: false
  },
  {
    field: 'email',
    headerName: 'cms.leads.automation.list.columns.email',
    flex: 1,
    sortable: false
  }
]
