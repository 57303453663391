import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type UsersAccessData = {
  /**
   *
   */
  role?: number
  /**
   *
   */
  document: string
  /**
   *
   */
  email: string
  /**
   *
   */
  phoneNumber: string
  /**
   *
   */
  name: string
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    role: Yup.number().required('validation.required.general'),
    document: Yup.string(),
    email: Yup.string(),
    phoneNumber: Yup.string(),
    name: Yup.string()
  })
)
