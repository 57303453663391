import PolicyDTO from '@volvo-apps/shared/models/PolicyDTO'

import React from 'react'

import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'

import { InsurancePoliciesRoutesEnum } from 'routes/insurancePolicies.routes'

import Colors from 'constants/Colors'

import { stringToCurrency } from 'common/utils/money'
import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
const Detail = () => {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const locationState = useLocation().state as { policy?: PolicyDTO }

  React.useEffect(() => {
    if (!locationState?.policy) {
      navigate(InsurancePoliciesRoutesEnum.POLICIES)
    }
  }, [locationState, navigate])

  if (!locationState?.policy) {
    return null
  }

  const policyDTO = new PolicyDTO().fromJSON(locationState.policy)

  const firstItem = policyDTO.getFirstPolicy()

  const makeRow = (label: string, value: string, pt?: number) => {
    return (
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        py={2}
        pt={pt}
        sx={{
          borderBottom: `1px solid ${Colors.light.greys.GREY_300}}`
        }}
      >
        <Typography color={Colors.light.greys.GREY_700} fontSize={12}>
          {label}
        </Typography>
        <Typography color={Colors.light.text} fontSize={16}>
          {value}
        </Typography>
      </FlexBox>
    )
  }

  return (
    <ContentContainer title={t('insurancePolicies.policies.detail.policy')}>
      <Box width="100%">
        {makeRow(
          t('insurancePolicies.policies.detail.policyNumber'),
          policyDTO.policyNumber,
          0
        )}
        {makeRow(
          t('insurancePolicies.policies.detail.status'),
          firstItem.situacao
        )}
        {makeRow(
          t('insurancePolicies.policies.detail.insurer'),
          firstItem.seguradora
        )}
        {makeRow(
          t('insurancePolicies.policies.detail.startOfVigency'),
          dayjs(firstItem.inicioVigencia, 'DD/MM/YYYY HH:mm:ss').format(
            'DD/MM/YYYY'
          )
        )}
        {makeRow(
          t('insurancePolicies.policies.detail.endOfVigency'),
          dayjs(firstItem.terminoVigencia, 'DD/MM/YYYY HH:mm:ss').format(
            'DD/MM/YYYY'
          )
        )}
        {makeRow(
          t('insurancePolicies.policies.detail.itemQuantity'),
          policyDTO.itemCount
        )}
        {makeRow(
          t('insurancePolicies.policies.detail.totalPrize'),
          stringToCurrency(firstItem.premioTotal)
        )}
        {makeRow(
          t('insurancePolicies.policies.detail.portionQuantity'),
          firstItem.parcelas
        )}
      </Box>
      <FlexBox justifyContent="flex-start" mt={3}>
        <PlainButton
          variant="contained"
          onClick={() =>
            navigate(
              InsurancePoliciesRoutesEnum.VEHICLES.replace(
                ':id',
                policyDTO.policyNumber
              ),
              { state: { policy: policyDTO } }
            )
          }
          endIcon={<Icon family="mui" style={{ fontSize: 16 }} name="East" />}
          sx={{
            px: 4,
            alignSelf: 'left',
            marginBottom: '10px'
          }}
        >
          {t('insurancePolicies.policies.detail.button')}
        </PlainButton>
      </FlexBox>
    </ContentContainer>
  )
}

export default Detail
export { Detail }
