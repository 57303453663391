import { createCampaign } from '@volvo-apps/shared/core/actions/Campaign'
import { createNotification } from '@volvo-apps/shared/core/actions/Notification'
import CampaignDTO from '@volvo-apps/shared/models/CampaignDTO'
import NotificationDTO from '@volvo-apps/shared/models/NotificationDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCreateCmsNotificationParams = {
  onSuccess?: () => void
}

type createNotificationRequest = {
  campaign: Pick<
    CampaignDTO,
    'financialProduct' | 'productLine' | 'documentType'
  >
  notification: Omit<NotificationDTO, 'campaignId'>
}

/**
 *
 * @param params params
 * @returns mutate, notifications, isLoading
 */
export const useCreateCmsNotification = (
  params: useCreateCmsNotificationParams
) => {
  const { onSuccess } = params

  const fetchCreateNotification = async ({
    campaign,
    notification
  }: createNotificationRequest) =>
    createCampaign(campaign).then((response) => {
      const notificationDTO = new NotificationDTO().fromJSON({
        ...notification,
        campaignId: response.data.id
      })

      createNotification(notificationDTO)
    })

  const {
    data: notification,
    isLoading,
    mutate
  } = useMutation('fetchCreateNotification', {
    mutationFn: fetchCreateNotification,
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, notification, isLoading }
}
