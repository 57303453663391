import React from 'react'

import { useAuth } from 'context'
import { useNavigate } from 'react-router-dom'

import { FlexBox } from 'components/FlexBox'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { LanguageSelect } from 'components/LanguageSelect'

import { CmsTabsRoutesEnum } from 'routes/cms/cms.routes'

import Colors from 'constants/Colors'

import { ReactComponent as Home } from 'assets/icons/home.svg'
import { useLanguage } from 'context/Language'

import { LayoutProps } from '../types'
import Tabs from './Tabs'

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function CmsContent(props: LayoutProps) {
  const navigate = useNavigate()
  const { logout } = useAuth()
  const { t } = useLanguage()

  return (
    <FlexBox flexDirection="column" minHeight="100vh">
      <Header
        items={[
          {
            __typename: 'link',
            to: CmsTabsRoutesEnum.HOME,
            label: t('general.homepage'),
            icon: <Home />
          },
          {
            __typename: 'menu',
            options: [
              {
                label: t('general.logout'),
                onClick: () => {
                  navigate(CmsTabsRoutesEnum.LOGIN)
                  logout()
                }
              }
            ]
          },
          {
            __typename: 'component',
            component: <LanguageSelect />
          }
        ]}
      />

      <FlexBox flex={1}>
        <FlexBox flexDirection="column">
          <Tabs />
        </FlexBox>

        <FlexBox flex={1} justifyContent="flex-start" mx={8}>
          {props.children}
        </FlexBox>
      </FlexBox>

      <FlexBox
        minHeight="4em"
        alignContent="center"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        px={15}
        sx={{
          backgroundColor: Colors.light.greys.GREY_100
        }}
      >
        <Footer />
      </FlexBox>
    </FlexBox>
  )
}

export default CmsContent
export { CmsContent }
