import VehicleDTO from '@volvo-apps/shared/models/VehicleDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudList } from 'components/CrudList'
import { PlainButton } from 'components/PlainButton'

import { ParametersRoutesEnum as VehicleRoutesEnum } from 'routes/cms/routesEnums'

import { useCrudVehicle } from 'hooks/cms/useCrudVehicle'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import TransitionTime from './TransitionTime'

type VehicleJSON = ReturnType<VehicleDTO['toJSON']>

/**
 * Vehicle List
 *
 * @returns TSX
 */
function List() {
  const { vehicles, updateAsyncVehicle, getLoading } = useCrudVehicle()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const items = React.useMemo(() => {
    return (
      vehicles?.map((item) => ({
        ...item.toJSON(),
        primary: item.name,
        id: item.id
      })) ?? []
    )
  }, [vehicles])

  const handleEdit = React.useCallback(
    (vehicle: VehicleJSON) => {
      const path = VehicleRoutesEnum.VEHICLES_UPDATE.replace(
        ':id',
        vehicle.id as string
      )

      navigate(path)
    },
    [navigate]
  )

  const handletoggle = React.useCallback(
    async (vehicle: VehicleJSON, checked: boolean) => {
      const newVehicle = new VehicleDTO().fromJSON({
        ...vehicle,
        active: checked
      })

      const updateResponse = await updateAsyncVehicle({ vehicle: newVehicle })

      toast.success(
        updateResponse.active
          ? t('cms.vehicles.toggle.enabled')
          : t('cms.vehicles.toggle.disabled')
      )
    },
    [updateAsyncVehicle, t]
  )

  const handleCreate = React.useCallback(() => {
    navigate(VehicleRoutesEnum.VEHICLES_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.vehicles.list.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.vehicles.list.title')}
      </Typography>

      <TransitionTime />

      <CrudList
        items={items}
        title={t('cms.vehicles.list.subtitle')}
        toggleKey="active"
        handleEdit={handleEdit}
        handleToggle={handletoggle}
        getLoading={getLoading && !vehicles}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
