import React from 'react'

import { Box, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { InputCurrency, Select } from 'components'
import { FlexBox } from 'components/FlexBox'

import { useGetFinancialProducts } from 'hooks/financialProducts/useGetFinancialProducts'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { ChooseFinancingForm } from '../../types'

/**
 * @returns TSX
 */
function ChooseType() {
  const { watch, setValue } = useFormContext<ChooseFinancingForm>()
  const { t } = useLanguage()
  const { id } = watch('productLine', {
    id: 0,
    name: ''
  })
  const { financialProducts } = useGetFinancialProducts({
    productLine: id,
    onSuccess: (data) => {
      if (!data.length) return
      setValue('financialProduct', data[0].id.toString())
    }
  })

  const financingOptions = React.useMemo(
    () =>
      financialProducts?.map((item) => ({
        label: item.name,
        value: item.id
      })),
    [financialProducts]
  )

  return (
    <FlexBox flexDirection="row" width="100%" gap={4}>
      <Box sx={{ width: '50%' }}>
        <Typography variant="h6" color={Colors.light.greys.GREY_600}>
          {t('simulation.chooseFinancing.selectType.title')}
        </Typography>
        <Select
          name="financialProduct"
          testId="financial-product-select"
          margin="normal"
          fullWidth
          label={t('simulation.chooseFinancing.selectType.placeholder')}
          options={financingOptions}
        />
      </Box>

      <Box sx={{ width: '50%' }}>
        <Typography variant="h6" color={Colors.light.greys.GREY_600}>
          {t('simulation.chooseFinancing.valueField.title')}
        </Typography>
        <InputCurrency
          margin="normal"
          fullWidth
          testId="product-value-input"
          name="productValue"
          label={t('simulation.chooseFinancing.valueField.placeholder')}
          maxLength={11}
        />
      </Box>
    </FlexBox>
  )
}

export default ChooseType
export { ChooseType }
