import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import {
  List as FinancialProductsList,
  Create as FinancialProductsCreate,
  Update as FinancialProductsUpdate
} from 'pages/cms/Parameters/FinancialProducts'
import { Menu as ParametersMenu } from 'pages/cms/Parameters/Menu'
import {
  List as ProductLinesList,
  Create as ProductLinesCreate,
  Update as ProductLinesUpdate
} from 'pages/cms/Parameters/ProductLines'
import {
  Create as SimulationParamsCreate,
  List as SimulationParamsList,
  Update as SimulationParamsUpdate
} from 'pages/cms/Parameters/SimulationParameters'
import {
  List as VehiclesList,
  Create as VehiclesCreate,
  Update as VehiclesUpdate
} from 'pages/cms/Parameters/Vehicles'
import CmsContent from 'pages/layout/CmsContent'
import { RoleEnum } from 'types/roles'

import { ParametersRoutesEnum } from './routesEnums'

const parametersRoutes: CustomRouteProps[] = [
  {
    path: ParametersRoutesEnum.PARAMETERS,
    protected: true,
    layout: CmsContent,
    element: <ParametersMenu />
  },
  {
    path: ParametersRoutesEnum.FINANCIAL_PRODUCTS,
    protected: true,
    layout: CmsContent,
    element: <FinancialProductsList />,
    role: RoleEnum.PARAMETROS_PRODUTOS_FINANCEIROS
  },
  {
    path: ParametersRoutesEnum.FINANCIAL_PRODUCTS_CREATE,
    protected: true,
    layout: CmsContent,
    element: <FinancialProductsCreate />,
    role: RoleEnum.PARAMETROS_PRODUTOS_FINANCEIROS
  },
  {
    path: ParametersRoutesEnum.FINANCIAL_PRODUCTS_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <FinancialProductsUpdate />,
    role: RoleEnum.PARAMETROS_PRODUTOS_FINANCEIROS
  },
  {
    path: ParametersRoutesEnum.PRODUCT_LINES,
    protected: true,
    layout: CmsContent,
    element: <ProductLinesList />,
    role: RoleEnum.PARAMETROS_LINHA_PRODUTO
  },
  {
    path: ParametersRoutesEnum.PRODUCT_LINES_CREATE,
    protected: true,
    layout: CmsContent,
    element: <ProductLinesCreate />,
    role: RoleEnum.PARAMETROS_LINHA_PRODUTO
  },
  {
    path: ParametersRoutesEnum.PRODUCT_LINES_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <ProductLinesUpdate />,
    role: RoleEnum.PARAMETROS_LINHA_PRODUTO
  },
  {
    path: ParametersRoutesEnum.SIMULATION_PARAMS,
    protected: true,
    layout: CmsContent,
    element: <SimulationParamsList />,
    role: RoleEnum.PARAMETROS_SIMULACAO
  },
  {
    path: ParametersRoutesEnum.SIMULATION_PARAMS_CREATE,
    protected: true,
    layout: CmsContent,
    element: <SimulationParamsCreate />,
    role: RoleEnum.PARAMETROS_SIMULACAO
  },
  {
    path: ParametersRoutesEnum.SIMULATION_PARAMS_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <SimulationParamsUpdate />,
    role: RoleEnum.PARAMETROS_SIMULACAO
  },
  {
    path: ParametersRoutesEnum.VEHICLES,
    protected: true,
    layout: CmsContent,
    element: <VehiclesList />,
    role: RoleEnum.PARAMETROS_VEICULOS
  },
  {
    path: ParametersRoutesEnum.VEHICLES_CREATE,
    protected: true,
    layout: CmsContent,
    element: <VehiclesCreate />,
    role: RoleEnum.PARAMETROS_VEICULOS
  },
  {
    path: ParametersRoutesEnum.VEHICLES_UPDATE,
    protected: true,
    layout: CmsContent,
    element: <VehiclesUpdate />,
    role: RoleEnum.PARAMETROS_VEICULOS
  }
]

export default parametersRoutes
