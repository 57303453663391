import { createProductLine } from '@volvo-apps/shared/core/actions/ProductLine'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCreateProductLineParams = {
  onSuccess?: () => void
}

/**
 * Hook to create a product line
 *
 * @param params params
 * @returns mutate, productLine, isLoading
 */
export const useCreateProductLine = (
  params: useCreateProductLineParams = {}
) => {
  const { onSuccess } = params
  const {
    data: productLine,
    isLoading,
    mutate
  } = useMutation('createProductLine', {
    mutationFn: ({
      name,
      order,
      minimumValue
    }: {
      name: string
      order: number
      minimumValue: number
    }) => createProductLine(name, order, minimumValue),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, productLine, isLoading }
}
