import React, { PropsWithChildren } from 'react'

import { Box, BoxProps } from '@mui/material'

type UploadProps = BoxProps & {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  multiple?: boolean
  accept?: string
}

/**
 * @param props Upload Props
 * @returns TSX
 */
function Upload(props: PropsWithChildren<UploadProps>) {
  const {
    onChange,
    children,
    multiple,
    accept = 'image/*',
    ...boxProps
  } = props
  return (
    <Box {...boxProps}>
      <input
        type="file"
        accept={accept}
        id="change-picture-input"
        value=""
        multiple={multiple}
        onChange={onChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="change-picture-input">{children}</label>
    </Box>
  )
}

export default Upload
export { Upload }
