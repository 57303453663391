import { createUserRole } from '@volvo-apps/shared/core/actions/UserRoles'

import { useMutation } from 'react-query'

type useCreateUserRoleParams = {
  onSuccess?: () => void
  onError?: (error: Error) => void
}

/**
 * Hook to create a user role
 *
 * @param params params
 * @returns mutate, userRole, isLoading
 */
export const useCreateUserRole = (params: useCreateUserRoleParams = {}) => {
  const { onSuccess, onError } = params
  const {
    data: role,
    isLoading,
    mutate
  } = useMutation('createUserRole', {
    mutationFn: createUserRole,
    onSuccess,
    onError
  })

  return { mutate, role, isLoading }
}
