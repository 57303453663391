import LoginDTO from '@volvo-apps/shared/models/LoginDTO'

import React, { useState } from 'react'

import { Box, IconButton, InputAdornment, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Form, Input } from 'components'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'
import { Version } from 'components/Version/Version'

import { handleLoginError } from 'common/utils/error'
import { useAuth } from 'context/Auth'
import { useLanguage } from 'context/Language'

import { validationSchema } from './helpers'

type FormData = Pick<LoginDTO, 'email' | 'password'>

/**
 * @returns JSX
 */
function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const methods = useForm<FormData>({
    criteriaMode: 'all',
    resolver: validationSchema,
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const { adminLogin } = useAuth()
  const { t } = useLanguage()

  const { isLoading, mutate } = useMutation(adminLogin, {
    onError: (err: AxiosError) => {
      handleLoginError(err, methods)
    }
  })

  const isSubmitValid =
    !isLoading && methods.formState.isDirty && methods.formState.isValid

  return (
    <>
      <Form
        methods={methods}
        onSubmit={mutate}
        formProps={{ style: { width: '100%' } }}
      >
        <Typography
          component="p"
          variant="body1"
          align="center"
          mt="1em"
          fontSize={16}
        >
          {t('login.welcomeTo')}
        </Typography>
        <Typography component="h1" variant="h4" align="center">
          {t('cms.volvo')}
        </Typography>
        <Typography component="p" variant="body1" align="center" mt="1em">
          {t('login.loginWithYourAccount')}
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Input
            margin="normal"
            fullWidth
            name="email"
            label={t('general.email')}
            autoComplete="username"
            autoFocus
          />
          <Input
            margin="normal"
            fullWidth
            name="password"
            label={t('general.password')}
            type={passwordVisible ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setPasswordVisible((s) => !s)}>
                    {passwordVisible ? (
                      <Icon family="mui" name="Visibility" />
                    ) : (
                      <Icon family="mui" name="VisibilityOff" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <PlainButton
            type="submit"
            loading={isLoading}
            disabled={!isSubmitValid}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('general.signIn')}
          </PlainButton>
        </Box>
      </Form>
      <Version
        position="absolute"
        left="50%"
        bottom={0}
        style={{
          transform: 'translateX(-50%)'
        }}
      />
    </>
  )
}

export default Login
export { Login }
