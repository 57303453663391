import React from 'react'

import { Box } from '@mui/material'
import { useNavigate } from 'react-router'

import { List, ListItemProps } from 'components'
import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CmsMenuHeader } from 'components/CmsMenuHeader'

import { ReportsRoutesEnum } from 'routes/cms/routesEnums'

import useCmsRoles from 'hooks/cms/useCmsRoles'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function Menu() {
  const navigate = useNavigate()
  const { showMenuByPage } = useCmsRoles()
  const { t } = useLanguage()

  const menuItems: ListItemProps[] = [
    {
      primary: t('cms.dashboard.tabs.userReports'),
      onClick: () => navigate(ReportsRoutesEnum.USER_REPORTS_FORM),
      hide: !showMenuByPage[ReportsRoutesEnum.USER_REPORTS_FORM]
    },
    {
      primary: t('cms.dashboard.tabs.userSearch'),
      onClick: () => navigate(ReportsRoutesEnum.USER_SEARCH),
      hide: !showMenuByPage[ReportsRoutesEnum.USER_SEARCH]
    }
  ]

  return (
    <CmsChildrenWithHeader>
      <CmsMenuHeader
        titleText={t('cms.dashboard.tabs.reports')}
        subtitleText={t('cms.surveys.menu.subtitle')}
      />
      <Box mt={2} width={(t) => t.breakpoints.values.md}>
        <List items={menuItems} noPadding />
      </Box>
    </CmsChildrenWithHeader>
  )
}

export default Menu
export { Menu }
