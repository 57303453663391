import { getUserDataReportByDoc } from '@volvo-apps/shared/core/actions/User'

import { t } from 'i18next'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { handleErrorFeedbackIgnoreNotFound } from 'common/utils/error'

type useGetUserDataReportByDocParams = {
  onSuccess?: () => void
}

/**
 * Hook to get user data reports
 *
 * @param params params
 * @returns mutate, term, isLoading and mutateAsync
 */
export const useGetUserDataReportByDoc = (
  params: useGetUserDataReportByDocParams = {}
) => {
  const { onSuccess } = params
  const {
    mutate,
    isLoading,
    data: userDataReport,
    mutateAsync
  } = useMutation('getUserDataReportByDoc', {
    mutationFn: ({ document }: { document: string }) =>
      getUserDataReportByDoc(document),
    onSuccess,
    onError: (e) =>
      handleErrorFeedbackIgnoreNotFound(e, () =>
        toast.error(t('cms.reports.userSearch.error') as string)
      )
  })

  return { mutate, userDataReport, isLoading, mutateAsync }
}
