import TermDTO from '@volvo-apps/shared/models/TermDTO'
import { TermService } from '../service/Term.service'

/**
 * @returns TermDTO[]
 */
export function getAllTerm() {
  const service = new TermService()
  return service.getAll().then(({ data }) => data)
}

/**
 * @param id The id of the term
 * @returns term
 */
export async function getTerm(id: number) {
  const service = new TermService()
  const response = await service.getTerm(id)
  return new TermDTO().fromJSON(response.data)
}

/**
 * @param id The id of the term
 * @returns Delete term
 */
export async function deleteTerm(id: number) {
  const service = new TermService()
  const response = await service.deleteTerm(id)

  return response
}

/**
 * @param updatedTerm The updated term
 */
export async function updateTerm(updatedTerm: TermDTO) {
  const service = new TermService()
  const response = await service.updateTerm(updatedTerm)

  return response
}

/**
 * @param payload The payload to update the term
 */
export async function createTerm(
  payload: Pick<TermDTO, 'termText' | 'accepted'>
) {
  const service = new TermService()
  const response = await service.createTerm(payload)

  return response
}

/**
 * @returns TermDTO[]
 */
export async function getAllCmsTerms() {
  const service = new TermService()
  const response = await service.getAllCms()

  return response.data.map((term) => new TermDTO().fromJSON(term))
}
