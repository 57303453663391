import React, { useEffect, useMemo } from 'react'

import { useUser } from 'context'
import { useForm } from 'react-hook-form'

import { Form, Input, InputMask, Select } from 'components'
import { PlainButton } from 'components/PlainButton'

import { useCEPField } from 'hooks/useCEPField'
import { useCityField } from 'hooks/useCityField'
import { useStateField } from 'hooks/useStateField'

import { useLanguage } from 'context/Language'

import { addressInfoSchema } from './helpers'
import { FormProps, FormValuesAddressInfo } from './types'

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function AddressInfoForm(props: FormProps) {
  const { onSave, isLoading } = props
  const { userData } = useUser()
  const { t } = useLanguage()

  const nUserData = useMemo(() => {
    const formValues = userData?.getAsFormValues([
      'address',
      'zipCode',
      'addressNumber',
      'addressComplement',
      'city',
      'state'
    ])

    return {
      address: formValues?.address || '',
      zipCode: formValues?.zipCode || '',
      addressNumber: formValues?.addressNumber || '',
      addressComplement: formValues?.addressComplement || '',
      city: formValues?.city || '',
      state: formValues?.state || ''
    }
  }, [userData])

  const methods = useForm<FormValuesAddressInfo>({
    mode: 'onBlur',
    defaultValues: nUserData,
    resolver: addressInfoSchema
  })

  const zipCode = methods.watch('zipCode')
  const state = methods.watch('state')

  useEffect(() => {
    methods.reset(nUserData)
  }, [methods, nUserData])

  const isSubmitDisabled =
    !methods.formState.isValid || !methods.formState.isDirty

  const { estados } = useStateField()

  const { isLoading: isLoadingCity, cidades } = useCityField(state)

  const { refetch, isLoading: isLoadingCep } = useCEPField(
    {
      cidade: 'city',
      siglaEstado: 'state',
      endereco: 'address',
      complemento: 'addressComplement'
    },
    zipCode,
    methods
  )

  return (
    <Form
      methods={methods}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '100%'
        }),
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center'
      }}
      onSubmit={onSave}
    >
      <InputMask
        mask="99999-999"
        fullWidth
        label={t('profile.menu.profileEditAddress.zipCode')}
        name="zipCode"
        onBlur={() => {
          refetch()
        }}
      />

      <Input
        margin="normal"
        fullWidth
        label={t('profile.menu.profileEditAddress.address')}
        name="address"
      />

      <Input
        margin="normal"
        fullWidth
        label={t('profile.menu.profileEditAddress.addressNumber')}
        name="addressNumber"
      />

      <Input
        margin="normal"
        fullWidth
        label={t('profile.menu.profileEditAddress.addressComplement')}
        name="addressComplement"
      />

      <Select
        name="state"
        margin="normal"
        fullWidth
        label={t('profile.menu.profileEditAddress.state')}
        options={estados}
        onChange={() => {
          methods.setValue('city', '')
        }}
      />

      <Select
        name="city"
        margin="normal"
        fullWidth
        label={t('profile.menu.profileEditAddress.city')}
        options={isLoadingCity ? [] : cidades}
      />

      <PlainButton
        sx={(t) => ({ mt: t.spacing(1) })}
        variant="contained"
        type="submit"
        disabled={isSubmitDisabled}
        loading={isLoading || isLoadingCep}
      >
        {t('general.saveChanges')}
      </PlainButton>
    </Form>
  )
}

export default AddressInfoForm
