import React from 'react'

import { Box, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { Password, Checkbox, Link } from 'components'
import { FlexBox } from 'components/FlexBox'
import { FormPasswordValidation } from 'components/FormPasswordValidation'
import { TermsAndConditionsModal } from 'components/TermsAndConditions'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

/**
 * @returns JSX
 */
function Step2() {
  const { setValue, trigger } = useFormContext()
  const { t } = useLanguage()

  return (
    <>
      <Box mt={3}>
        <Typography
          fontSize={14}
          color={Colors.light.interactActive}
          fontWeight={500}
        >
          2 - {t('register.step2.title')}
        </Typography>
      </Box>
      <Box my={2}>
        <Password
          autoComplete="new-password"
          label={t('register.step2.password')}
          name="password"
        />
      </Box>
      <FlexBox my={2} flexDirection="column" gap={2}>
        <Password
          label={t('register.step2.passwordConfirm')}
          autoComplete="new-password"
          name="password_confirm"
        />
        <FormPasswordValidation />
      </FlexBox>
      <Box my={2}>
        <Checkbox
          name="termsAndConditions"
          label={
            <Typography>
              {t('register.step2.readTermsAndAgreed')}
              <TermsAndConditionsModal
                onClick={() => {
                  setValue('termsAndConditions', true)
                  trigger('termsAndConditions')
                }}
                triggerComponent={
                  <Link
                    color={Colors.light.interactDefault}
                    sx={{
                      ml: 0.5
                    }}
                  >
                    {t('register.step2.termsAndConditions')}
                  </Link>
                }
              />
            </Typography>
          }
        />
      </Box>
    </>
  )
}

export default Step2
export { Step2 }
