import UserReportDTO from '@volvo-apps/shared/models/UserReportDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { ReportsRoutesEnum } from 'routes/cms/routesEnums'

import { useGetUsersDataReport } from 'hooks/reports/useGetUsersDataReport'

import Colors from 'constants/Colors'

import { formatDate } from 'common/utils/date'
import { useLanguage } from 'context/Language'

import { UserReportsData } from '../Form/schema'
import { UserReportsForm } from '../Form/UserReportsForm'
import { useUserReportsForm } from '../Form/useUserReportsForm'

/**
 * User Reports Create
 *
 * @returns TSX
 */
function Create() {
  const { methods } = useUserReportsForm({
    defaultValues: { beginDateStr: new Date(), endDateStr: new Date() }
  })
  const navigate = useNavigate()
  const { t } = useLanguage()
  const { mutate, isLoading } = useGetUsersDataReport({
    onSuccess: () => {
      navigate(ReportsRoutesEnum.REPORTS_MENU)
    }
  })

  const onSubmit = React.useCallback(
    (formData: UserReportsData) => {
      const dto = new UserReportDTO().fromJSON({
        beginDateStr: formatDate(formData.beginDateStr),
        endDateStr: formatDate(formData.endDateStr)
      })
      mutate({ userReport: dto })
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.reports.userReportsTitle')}
      </Typography>
      <Typography
        mt={2}
        mb={4}
        fontSize={16}
        color={Colors.light.greys.GREY_600}
      >
        {t('cms.reports.userReportsSubtitle')}
      </Typography>
      <UserReportsForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.reports.userReportsButton')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
