import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import 'reflect-metadata'

/**
 * VehicleImagesDTO
 */
class VehicleImagesDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('vehicleId')
  @jsonProperty(Number)
  public vehicleId!: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('profilePicture')
  @jsonProperty(String)
  public profilePicture!: string

  @jsonName('azureUrl')
  @jsonProperty(String)
  public azureUrl!: string

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: string

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: string

  @jsonName('vehicleImageHash')
  @jsonProperty(String)
  public vehicleImageHash?: string

  @jsonName('coverPhoto')
  @jsonProperty(Boolean)
  public coverPhoto!: boolean

  @jsonName('fileName')
  @jsonProperty(String)
  public fileName!: string
}

export default VehicleImagesDTO
