import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as PushNotificationsRoutesEnum } from 'routes/cms/routesEnums'

import { useCreateCmsNotification } from 'hooks/notifications/useCreateCmsNotification'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { PushNotificationsForm } from '../Form/PushNotificationsForm'
import { PushNotificationsData } from '../Form/schema'
import { usePushNotificationsForm } from '../Form/usePushNotificationsForm'

/**
 * Push Notifications Create
 *
 * @returns TSX
 */
function Create() {
  const { methods, getOnSubmitDTOs } = usePushNotificationsForm({
    defaultValues: {
      beginDate: new Date(),
      endDate: new Date(),
      hourDay: '',
      dayWeek: '',
      interval: '',
      body: '',
      sendToAll: false
    }
  })
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { mutate, isLoading } = useCreateCmsNotification({
    onSuccess: () => {
      toast.success(t('cms.pushNotifications.create.success'))
      navigate(PushNotificationsRoutesEnum.PUSH_NOTIFICATIONS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: PushNotificationsData) => {
      const { campaign, notification } = getOnSubmitDTOs(formData)

      mutate({
        campaign,
        notification
      })
    },
    [getOnSubmitDTOs, mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.pushNotifications.create.title')}
      </Typography>
      <PushNotificationsForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.pushNotifications.create.button')}
        shouldSetInitialDayOfWeek
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
