import { HolidayService } from '@volvo-apps/shared/core/service/Holiday.service'
import HolidayDTO from '@volvo-apps/shared/models/HolidayDTO'

/**
 * @returns HolidayDTO[]
 */
export async function getAllHolidays() {
  const service = new HolidayService()
  const response = await service.getAll()

  return response.data.map((holiday) => new HolidayDTO().fromJSON(holiday))
}

/**
 * @param id The id of the holiday
 * @returns holiday
 */
export async function getHoliday(id: number) {
  const service = new HolidayService()
  const response = await service.getHoliday(id)
  return new HolidayDTO().fromJSON(response.data)
}

/**
 * @param id The id of the holiday
 * @returns Delete holiday
 */
export async function deleteHoliday(id: number) {
  const service = new HolidayService()
  const response = await service.deleteHoliday(id)

  return response
}

/**
 * @param updatedHoliday The updated holiday
 */
export async function updateHoliday(updatedHoliday: HolidayDTO) {
  const service = new HolidayService()
  const response = await service.updateHoliday(updatedHoliday)

  return response
}

/**
 
 * @param payload The payload to update the holiday
 */
export async function createHoliday(
  payload: Pick<HolidayDTO, 'name' | 'date'>
) {
  const service = new HolidayService()
  const response = await service.createHoliday(payload)

  return response
}

/**
 * @returns HolidayDTO[]
 */
export async function getAllCmsHolidays() {
  const service = new HolidayService()
  const response = await service.getAllCms()

  return response.data.map((holiday) => new HolidayDTO().fromJSON(holiday))
}
