import React from 'react'

import {
  Alert as MuiAlert,
  AlertProps,
  Typography,
  TypographyProps
} from '@mui/material'

import { PlainButtonProps, PlainButton } from '../PlainButton'

type CustomAlertProps = AlertProps & {
  open?: boolean
  buttonProps?: PlainButtonProps & {
    textProps?: TypographyProps & { label?: string }
  }
}

/**
 * Custom alert component.
 *
 * @param props - Component properties, including message and open state.
 * @returns JSX.Element
 */
const Alert = (props: CustomAlertProps) => {
  const { buttonProps, ...restProps } = props

  const action = buttonProps ? (
    <PlainButton
      {...buttonProps}
      style={{
        maxWidth: '188px',
        maxHeight: '56px',
        height: '-webkit-fill-available',
        padding: '12.9px 21px 13px 21px',
        gap: '10px',
        borderRadius: '4px 0px 0px 0px',
        border: '1px 0px 0px 0px',
        ...props.buttonProps?.style
      }}
      variant="outlined"
    >
      <Typography {...props.buttonProps?.textProps}>
        {props?.buttonProps?.textProps?.label}
      </Typography>
    </PlainButton>
  ) : (
    restProps.action
  )

  if (props.open) {
    return (
      <MuiAlert
        {...restProps}
        icon={props.icon ?? false}
        onClose={!props.onClose ? props.onClose : undefined}
        action={action}
        sx={{
          '& .MuiAlert-action': {
            paddingY: '4px',
            marginRight: 0,
            '@media (max-width: 1920px)': {
              height: '40px'
            },
            '@media (max-width: 1360px)': {
              height: '56px'
            }
          },
          '& .MuiAlert-message': {
            display: 'flex',
            alignItems: 'center'
          },
          '&:not(:first-of-type)': {
            marginTop: '4px'
          },
          ...restProps.sx
        }}
      />
    )
  }
  return null
}

export default Alert
export { Alert, CustomAlertProps }
