import envVars from '../envVars.json'
import { ModeEnum } from './Mode'
/**
 * Criar env var
 */

const env = process.env

/**
 * Gets the project mode (dev/homologation/prod)
 *
 * @returns The current compilation mode
 */
export function getMode(): ModeEnum {
  const variant = env.REACT_APP_VARIANT
  switch (variant) {
    case ModeEnum.HOMOLOGATION:
      return ModeEnum.HOMOLOGATION
    case ModeEnum.PROD:
      return ModeEnum.PROD
    default:
      return ModeEnum.DEV
  }
}
export const MODE = getMode()

function getChannel() {
  const { host } = window.location

  const channelByHost = envVars.channels.find((channel) =>
    channel.self.some((url) => url.includes(host))
  )

  const env = envVars as unknown as Record<string, unknown>
  const channelByEnv = (env.channels as Array<Record<string, string>>).find(
    (item) => item.name === MODE
  )

  return channelByHost ?? channelByEnv
}

export const API_FULL_PATH =
  getChannel()?.baseUrl ??
  'https://webapp-ctappbanco-gateway-dev.azurewebsites.net'
