import React from 'react'

import AliceCarousel, {
  Props as AliceCarouselProps,
  DotsItem
} from 'react-alice-carousel'

import 'react-alice-carousel/lib/alice-carousel.css'
import './carousel.css'
import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

type CarouselProps = Omit<
  AliceCarouselProps,
  'items' | 'autoPlayInterval' | 'autoPlay'
> & {
  slideIntervalInMs?: number
  items?: React.ReactNode[]
  autoPlay?: boolean
  arrowSize?: number
  removeButtonWhenDisabled?: boolean
  textAlignCenter?: boolean
}

/**
 *
 * @param props Carousel Props
 * @returns Carousel TSX
 */
function Carousel(props: CarouselProps) {
  const {
    slideIntervalInMs = 1,
    items = [],
    autoPlay,
    arrowSize = 80,
    removeButtonWhenDisabled,
    textAlignCenter,
    ...rest
  } = props

  const arrowStyle = React.useCallback(
    (isDisabled?: boolean) => ({
      color: Colors.light.interactActive,
      opacity: isDisabled ? 0.5 : 1,
      height: arrowSize,
      width: arrowSize
    }),
    [arrowSize]
  )

  const prevButton = React.useCallback(
    ({ isDisabled }: { isDisabled?: boolean | undefined }) =>
      (!isDisabled || !removeButtonWhenDisabled) && (
        <Icon family="mui" name="ChevronLeft" style={arrowStyle(isDisabled)} />
      ),
    [arrowStyle, removeButtonWhenDisabled]
  )

  const nextButton = React.useCallback(
    ({ isDisabled }: { isDisabled?: boolean | undefined }) =>
      (!isDisabled || !removeButtonWhenDisabled) && (
        <Icon family="mui" name="ChevronRight" style={arrowStyle(isDisabled)} />
      ),
    [arrowStyle, removeButtonWhenDisabled]
  )

  const dotsItem = React.useCallback((e: DotsItem) => {
    const borderColor = e.isActive
      ? Colors.light.greys.GREY_600
      : Colors.light.greys.GREY_400
    const backgroundColor = e.isActive
      ? Colors.light.greys.GREY_600
      : Colors.light.background

    return (
      <FlexBox
        style={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          cursor: e.isActive ? 'default' : 'pointer',
          backgroundColor,
          marginRight: 8,
          border: `1px solid ${borderColor}`
        }}
      />
    )
  }, [])

  const autoPlayInterval = React.useMemo(
    () => slideIntervalInMs * 1000 * items.length,
    [slideIntervalInMs, items.length]
  )

  const changeClasses = React.useCallback(() => {
    if (rest.disableDotsControls && !rest.disableButtonsControls) {
      const elmNext = document.querySelector('.alice-carousel__next-btn')
      elmNext?.classList.add('no-transform__next')
      const elmPrev = document.querySelector('.alice-carousel__prev-btn')
      elmPrev?.classList.add('no-transform__prev')
    }
  }, [rest.disableButtonsControls, rest.disableDotsControls])

  const makeCarousel = React.useMemo(() => {
    return (
      <AliceCarousel
        onInitialized={changeClasses}
        onSlideChange={changeClasses}
        renderPrevButton={prevButton}
        renderNextButton={nextButton}
        renderDotsItem={dotsItem}
        mouseTracking
        touchTracking
        items={items}
        autoPlayInterval={autoPlayInterval}
        autoPlay={autoPlay && items.length > 1}
        {...rest}
      />
    )
  }, [
    changeClasses,
    dotsItem,
    items,
    nextButton,
    prevButton,
    rest,
    autoPlay,
    autoPlayInterval
  ])

  return textAlignCenter ? (
    <div style={{ textAlign: 'center' }}>{makeCarousel}</div>
  ) : (
    makeCarousel
  )
}

export default Carousel
export { Carousel }
