import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'
import LinkedDocumentsDTO from '../../models/LinkedDocumentsDTO'
import UserDocumentDTO from '../../models/UserDocumentDTO'

/**
 * LinkedDocuments
 */
export class LinkedDocumentsService extends GenericService<LinkedDocumentsDTO> {
  readonly URI: string = 'gateway/LinkedDocuments'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all LinkedDocuments
   *
   */
  public async getAll() {
    return super.get<AxiosResponse<LinkedDocumentsDTO[]>>(
      'ListLinkedDocuments',
      {}
    )
  }

  /**
   * name by email
   *
   * @param email The email
   */
  public async searchNameByEmail(email: string) {
    return super.get<AxiosResponse<string>>(`SearchNameByEmail?email=${email}`)
  }

  /**
   * company name by document
   *
   * @param document The document
   */
  public async searchCompanyNameByDocument(document: string) {
    return super.get<AxiosResponse<string>>(
      `SearchCompanyNameByDocument?document=${document}`
    )
  }

  /**
   * Link document
   *
   * @param email The email
   * @param document The document
   */
  public async linkDocument(email: string, document: string) {
    return super.post<AxiosResponse<UserDocumentDTO>>('LinkDocument', {
      email,
      document
    })
  }

  /**
   * Unlink document
   *
   * @param id The id
   */
  public async unlink(id: number) {
    return super.delete(`Unlink?id=${id}`)
  }
}
