import { Serializable } from 'ts-serializable'

/**
 *
 * @param array - Array of objects
 * @param key - Key to be used as object key
 * @returns Object
 */
export const arraySerializableToObjectById = <T extends Serializable>(
  array: T[],
  key = 'id'
) => {
  return array?.reduce((acc, curr) => {
    acc[curr[key]] = curr
    return acc
  }, {})
}
