import React from 'react'

import { useFormContext } from 'react-hook-form'

import { FlexBox } from 'components/FlexBox'

import { useLanguage } from 'context/Language'

import Validation from './Validation'

/**
 *
 * @returns TSX
 */
function FormPasswordValidation() {
  const { t } = useLanguage()
  const password = useFormContext().watch('password')
  const checkUppercase = /[A-Z]/
  const checkNumber = /(\d+)/
  const checkSpecialCharacter = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/

  const validationsList = [
    {
      title: t('validation.password.min.length', [8]),
      state: password.length >= 8
    },
    {
      title: t('validation.password.min.uppercase'),
      state: checkUppercase.test(password)
    },
    {
      title: t('validation.password.min.number'),
      state: checkNumber.test(password)
    },
    {
      title: t('validation.password.min.specialCharacter'),
      state: checkSpecialCharacter.test(password)
    }
  ]

  return (
    <FlexBox flexDirection="column" width="100%" alignSelf="flex-start">
      {validationsList.map(({ state, title }, index) => (
        <Validation correct={state} key={index} label={title} />
      ))}
    </FlexBox>
  )
}

export default FormPasswordValidation
export { FormPasswordValidation }
