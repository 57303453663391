import Yup from './index'

const isOver18 = (birthDate?: Date | null) => {
  if (!birthDate) return false
  const today = new Date()
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age >= 18
}

export const birthdayValidator = Yup.date()
  .nullable()
  .typeError('validation.invalid.date')
  .test('birthdayIsValid', 'validation.invalid.birthday', (value) => {
    if (value === null || value === undefined) return true
    return isOver18(value)
  })

export const requiredBirthdayValidator = birthdayValidator.required()
