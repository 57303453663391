import { AuthenticationService } from '@volvo-apps/shared/core/service/Authentication.service'
import LoginDTO from '@volvo-apps/shared/models/LoginDTO'
import TokensDTO from '../../models/TokensDTO'

/**
 *
 * @param props Func Props
 * @param props.username Username
 * @param props.password Password
 */
export async function loginApp(
  props: Pick<LoginDTO, 'email' | 'password' | 'deviceType'>
) {
  const service = new AuthenticationService()
  const { data: token } = await service.loginApp(props)

  return token as string
}

/**
 *
 * @param props Func Props
 * @param props.username Username
 * @param props.password Password
 */
export async function loginMobile(
  props: Pick<LoginDTO, 'email' | 'password' | 'deviceType'>
) {
  const service = new AuthenticationService()

  const { data } = await service.loginMobile(props)

  return data as TokensDTO
}

/**
 *
 */
export async function logoutApp() {
  const service = new AuthenticationService()

  service.resetInstance()
}

/**
 *
 * @param props Props
 */
export async function refreshToken(
  props: Pick<TokensDTO, 'accessToken' | 'refreshToken'>
) {
  const service = new AuthenticationService()

  const { data } = await service.refreshToken(props)

  return data as TokensDTO
}

/**
 *
 * @param props Func Props
 * @param props.username Username
 * @param props.password Password
 */
export async function loginCms(props: Pick<LoginDTO, 'email' | 'password'>) {
  const service = new AuthenticationService()
  const { data: token } = await service.loginCms(props)

  return token as string
}
