import { useForm } from 'react-hook-form'

import { FaqCategoryData, schema } from './schema'

type FaqCategoryProps = {
  defaultValues?: FaqCategoryData
}

/**
 *
 * @param props - FaqCategoryProps
 * @returns useForm methods
 */
export const useFaqCategoryForm = (props: FaqCategoryProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<FaqCategoryData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
