import 'reflect-metadata'
import { jsonProperty, Serializable, jsonName } from 'ts-serializable'
// --------------------------------------------------------

/**
 * ParameterDetailDTO
 */
class ParameterDetailDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('maxValue')
  @jsonProperty(Number)
  public maxValue!: number

  @jsonName('minValue')
  @jsonProperty(Number)
  public minValue!: number

  @jsonName('leapOfMonths')
  @jsonProperty(Number)
  public leapOfMonths!: number

  @jsonName('valueStarts')
  @jsonProperty(Number)
  public valueStarts!: number

  @jsonName('isInstallmentParameters')
  @jsonProperty(Boolean)
  public isInstallmentParameters!: boolean

  @jsonName('simulationParametersId')
  @jsonProperty(Number)
  public simulationParametersId!: number
}

export default ParameterDetailDTO
