import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import 'reflect-metadata'

/**
 * BannerDTO
 */
class BannerDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedDate!: string

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('idBannerConfig')
  @jsonProperty(Number)
  public idBannerConfig!: number

  @jsonName('bannerBase64')
  @jsonProperty(String)
  public bannerBase64!: string

  @jsonName('title')
  @jsonProperty(String)
  public title!: string

  @jsonName('fileName')
  @jsonProperty(String)
  public fileName!: string

  @jsonName('azureUrl')
  @jsonProperty(String)
  public azureUrl!: string
}

export default BannerDTO
