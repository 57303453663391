import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type NpsData = {
  /**
   *
   */
  title: string
  /**
   *
   */
  beginDate: Date
  /**
   *
   */
  endDate: Date
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    title: Yup.string().required('validation.required.general'),
    beginDate: Yup.date()
      .nullable()
      .typeError('validation.invalid.date')
      .test(
        'startDateIsValid',
        'validation.invalid.date',
        (value?: Date | null) =>
          !!value &&
          value.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
      )
      .required(),
    endDate: Yup.date()
      .nullable()
      .typeError('validation.invalid.date')
      .test(
        'endDateIsValid',
        'validation.invalid.endDate',
        (
          value?: Date | null,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          context: {
            /**
             *
             */
            parent: NpsData
          }
        ) =>
          context.parent?.beginDate instanceof Date && !!value
            ? value.setHours(0, 0, 0, 0) >=
              context.parent?.beginDate?.setHours(0, 0, 0, 0)
            : false
      )
      .required()
  })
)
