import 'reflect-metadata'
import { jsonProperty, Serializable, jsonName } from 'ts-serializable'

/**
 * UserDomainDTO
 */
class UserDomainDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedDate!: string

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('domain')
  @jsonProperty(String)
  public domain!: string
}

export default UserDomainDTO
