import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import HeaderText from 'components/HeaderText/HeaderText'

import Colors from 'constants/Colors'

/**
 *
 * @param handleEdit handleEdit
 * @returns TSX
 */
export const columns: GridColDef[] = [
  {
    field: 'fileName',
    headerName: 'cms.consortiumResources.table.name',
    minWidth: 350,
    flex: 1,
    maxWidth: 450,
    sortable: false
  },
  {
    field: 'status',
    headerName: 'cms.consortiumResources.table.status',
    minWidth: 105,
    flex: 1,
    maxWidth: 130,
    sortable: false
  },
  {
    field: 'processingDate',
    headerName: 'cms.consortiumResources.table.date',
    minWidth: 180,
    flex: 1,
    maxWidth: 205,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const processingDate = params.row.processingDate as Date

      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {dayjs(processingDate).format('DD/MM/YYYY [às] HH:mm')}
        </Typography>
      )
    }
  },
  {
    field: 'processingRecords',
    headerName: 'cms.consortiumResources.table.processedRegisters',
    sortable: false,
    minWidth: 100,
    renderHeader: HeaderText
  },

  {
    field: 'errorsRecords',
    headerName: 'cms.consortiumResources.table.recordsWithError',
    minWidth: 100,
    sortable: false,
    renderHeader: HeaderText
  }
]
