import { GridColDef } from '@mui/x-data-grid'

/**
 *
 * @param handleEdit handleEdit
 * @returns TSX
 */
export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'cms.productLines.form.name',
    width: 200,
    sortable: false
  },
  {
    field: 'order',
    headerName: 'cms.vehicles.form.order',
    width: 100,
    sortable: false,
    align: 'center',
    headerAlign: 'center'
  }
]
