import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'
import { SalesInvoiceDTO } from './SalesInvoiceDTO'
import { SalesVehicleDTO } from './SalesVehicleDTO'

/**
 *  Rental contract model
 */
class RentalContractDTO extends Serializable {
  @jsonProperty(Number)
  numeroContrato!: number

  @jsonProperty(Date)
  dataContrato!: Date

  @jsonProperty(String)
  documento!: string

  @jsonProperty(String)
  nomeRazao!: string

  @jsonProperty(Date)
  vigenciaContrato!: Date

  @jsonProperty(Number)
  qtdVeiculos!: number

  @jsonProperty(Number)
  prazo!: number

  @jsonProperty(Number)
  nroParcela!: number

  @jsonProperty(Boolean)
  isValid!: boolean

  @jsonProperty(Array)
  rentalInvoices?: Array<SalesInvoiceDTO>

  @jsonProperty(Array)
  rentalVehicles?: Array<SalesVehicleDTO>
}

export default RentalContractDTO
