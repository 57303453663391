import { SimulationParameterService } from '@volvo-apps/shared/core/service/SimulationParameter.service'
import SimulationParameterDTO from '@volvo-apps/shared/models/SimulationParameterDTO'
import ParameterDetailDTO from '@volvo-apps/shared/models/ParameterDetailDTO'

/**
 * @returns SimulationParameterDTO[]
 */
export async function getAllSimulationParameters() {
  const service = new SimulationParameterService()
  const response = await service.getAll()

  return response.data.map((simulationParameter) =>
    new SimulationParameterDTO().fromJSON(simulationParameter)
  )
}

/**
 * @param id The id of the simulation parameter
 * @returns simulation parameter
 */
export async function getSimulationParameter(id: number) {
  const service = new SimulationParameterService()
  const response = await service.getSimulationParameter(id)
  return new SimulationParameterDTO().fromJSON(response.data)
}

/**
 * @param id The id of the simulation parameter
 * @returns Delete simulation parameter
 */
export async function deleteSimulationParameter(id: number) {
  const service = new SimulationParameterService()
  const response = await service.deleteSimulationParameter(id)

  return response
}

/**
 * @param updatedSimulationParameter The updated simulation parameter
 */
export async function updateSimulationParameter(
  updatedSimulationParameter: SimulationParameterDTO
) {
  const service = new SimulationParameterService()
  const response = await service.updateSimulationParameter(
    updatedSimulationParameter
  )

  return response
}

/**
 * @param simulationParameter The simulation parameter
 */
export async function createSimulationParameter(
  simulationParameter: SimulationParameterDTO
) {
  const service = new SimulationParameterService()
  const response = await service.createSimulationParameter(simulationParameter)

  return response
}

/**
 * @returns All cms simulation parameters
 */
export async function getAllCmsSimulationParameters() {
  const service = new SimulationParameterService()
  const response = await service.getAllCms()

  return response.data.map((simulationParameter) =>
    new SimulationParameterDTO().fromJSON(simulationParameter)
  )
}

/**
 *
 * @param productLineId The product line id
 * @param financialProductId The financial product id
 */
export async function getParametersDetailById(
  productLineId: number,
  financialProductId: number
) {
  const service = new SimulationParameterService()
  const response = await service.getParametersDetailById(
    productLineId,
    financialProductId
  )

  return response.data.parametersDetailDto.map((paramterDetail) =>
    new ParameterDetailDTO().fromJSON(paramterDetail)
  )
}
