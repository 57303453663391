import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import { UserSimulationProvider } from 'context/Simulation'
import { AppContent } from 'pages/layout/AppContent'
import {
  SimulationSummary,
  SimulationMenu,
  SimulationHistory,
  ChooseFinancing,
  DocumentSelection,
  FinancingAmounts
} from 'pages/Simulation'

export enum SimulationRoutesEnum {
  SIMULATION_MENU = '/simulation',
  DOCUMENT_SELECTION = '/simulation/new/documentselection',
  CHOOSE_FINANCING = '/simulation/new/choosefinancing',
  FINANCING_AMOUNTS = '/simulation/new/financingamounts',
  SIMULATION_SUMMARY = '/simulation/new/summary/:id',
  SIMULATION_HISTORY = '/simulation/history',
  SIMULATION_HISTORY_SUMMARY = '/simulation/history/:id'
}

const wrapper = (layout: React.ReactNode) => (
  <UserSimulationProvider>{layout}</UserSimulationProvider>
)

const simulationRoutes: CustomRouteProps[] = [
  {
    path: SimulationRoutesEnum.SIMULATION_MENU,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: '/simulation',
        label: 'breadCrumbs.simulation'
      }
    ],
    element: wrapper(<SimulationMenu />)
  },
  {
    path: SimulationRoutesEnum.DOCUMENT_SELECTION,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: '/simulation',
        label: 'breadCrumbs.simulation'
      },
      {
        to: '/simulation/new/documentselection',
        label: 'breadCrumbs.newSimulation'
      }
    ],
    element: wrapper(<DocumentSelection />)
  },
  {
    path: SimulationRoutesEnum.CHOOSE_FINANCING,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: '/simulation',
        label: 'breadCrumbs.simulation'
      },
      {
        to: '/simulation/new/documentselection',
        label: 'breadCrumbs.newSimulation'
      }
    ],
    element: wrapper(<ChooseFinancing />)
  },
  {
    path: SimulationRoutesEnum.FINANCING_AMOUNTS,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: '/simulation',
        label: 'breadCrumbs.simulation'
      },
      {
        to: '/simulation/new/documentselection',
        label: 'breadCrumbs.newSimulation'
      }
    ],
    element: wrapper(<FinancingAmounts />)
  },
  {
    path: SimulationRoutesEnum.SIMULATION_SUMMARY,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: '/simulation/new/documentselection',
        label: 'breadCrumbs.newSimulation'
      },
      {
        to: SimulationRoutesEnum.SIMULATION_SUMMARY,
        label: 'breadCrumbs.simulation',
        showId: true
      }
    ],
    element: wrapper(<SimulationSummary />)
  },
  {
    path: SimulationRoutesEnum.SIMULATION_HISTORY_SUMMARY,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: SimulationRoutesEnum.SIMULATION_HISTORY,
        label: 'breadCrumbs.simulationHistory'
      },
      {
        to: SimulationRoutesEnum.SIMULATION_HISTORY_SUMMARY,
        label: 'breadCrumbs.simulation',
        showId: true
      }
    ],
    element: wrapper(<SimulationSummary />)
  },
  {
    path: SimulationRoutesEnum.SIMULATION_HISTORY,
    layout: AppContent,
    protected: true,
    breadcrumbs: [
      {
        to: '/dashboard',
        label: 'breadCrumbs.home'
      },
      {
        to: SimulationRoutesEnum.SIMULATION_HISTORY,
        label: 'breadCrumbs.simulationHistory'
      }
    ],
    element: wrapper(<SimulationHistory />)
  }
]

export default simulationRoutes
