/**
 * Initial form type
 */
export type FormInfo = {
  /**
   * Email to send the verification code
   */
  email: string
  /**
   * Verification code sent to the email
   */
  verificationCode: string
}

export enum PasswordRetrievalStepEnum {
  VERIFY_EMAIL_AND_CODE = 'VERIFY_EMAIL_AND_CODE',
  NEW_PASSWORD = 'NEW_PASSWORD',
  PASSWORD_RETRIEVAL_SUCCESS = 'PASSWORD_RETRIEVAL_SUCCESS'
}
