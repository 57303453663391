import CampaignDTO from '@volvo-apps/shared/models/CampaignDTO'

export const optionAllId = -999
export const optionAllString = 'all'

type keys = 'financialProduct' | 'documentType' | 'productLine'

const sendToAllByResource: Record<keys, keyof CampaignDTO> = {
  financialProduct: 'sendToAllFinancialProduct',
  documentType: 'sendToAllDocumentType',
  productLine: 'sendToAllProductLine'
}

/**
 *
 * @param campaign CampaignDTO
 * @param resource financialProduct | documentType | productLine
 * @returns boolean
 */
export const handleSendToAll = (campaign: CampaignDTO, resource: keys) => {
  return (
    campaign.sendToAll || (campaign[sendToAllByResource[resource]] as boolean)
  )
}

/**
 *
 * @param value number | string
 * @returns number | undefined
 */
export const handleOptionAll = (value: number | string) => {
  return value === optionAllId || value === optionAllString ? undefined : value
}
