import React from 'react'

import { IconButton, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

import _debounce from 'lodash/debounce'

import { awaitingMonthsFetchValue } from '../../FinancingAmounts'

type InputNumberBoxProps = {
  value: number
  minValue?: number
  disabled?: boolean
  maxValue?: number
  steps?: number
  label: string
  onChange: (value: number) => void
}

/**
 *
 * @param props InputNumberBox Props
 * @returns TSX
 */
function InputNumberBox(props: InputNumberBoxProps) {
  const {
    value,
    steps = 1,
    minValue = 0,
    maxValue,
    disabled,
    label,
    onChange
  } = props

  const iconSize = 24
  const containerBorder = `1px solid ${Colors.light.greys.GREY_500}`
  const iconBorder = `1px solid ${Colors.light.greys.GREY_300}`

  const [internalValue, setInternalValue] = React.useState(value)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickDebounced = React.useCallback(
    _debounce((value: number) => {
      onChange(value)
    }, 350),
    [onChange]
  )

  const onClick = React.useCallback(
    (value: number) => {
      setInternalValue(value)

      return onClickDebounced(value)
    },
    [onClickDebounced]
  )

  const fontColor = disabled
    ? Colors.light.greys.GREY_400
    : Colors.light.interactActive

  React.useEffect(() => {
    if (internalValue === awaitingMonthsFetchValue) {
      setInternalValue(value)
    }
  }, [internalValue, value])

  return (
    <FlexBox
      border={containerBorder}
      borderRadius={1}
      justifyContent="center"
      alignItems="center"
      width="fit-content"
    >
      <FlexBox
        border={iconBorder}
        borderTop={0}
        borderLeft={0}
        borderBottom={0}
        px={0.5}
        alignItems="center"
      >
        <IconButton
          disabled={disabled || internalValue === minValue}
          onClick={() => {
            onClick(Math.max(internalValue - steps, minValue))
          }}
        >
          <Icon
            family="mui"
            name="Remove"
            style={{ width: iconSize, height: iconSize }}
          />
        </IconButton>
      </FlexBox>
      <Typography
        px={2}
        py={0.25}
        fontSize={32}
        textAlign="center"
        color={fontColor}
        lineHeight={1}
      >
        {internalValue} <br />
        <Typography fontSize={14} color={fontColor}>
          {label}
        </Typography>
      </Typography>
      <FlexBox
        border={iconBorder}
        paddingLeft={2}
        borderTop={0}
        borderRight={0}
        borderBottom={0}
        px={0.5}
        alignItems="center"
      >
        <IconButton
          disabled={disabled || internalValue === maxValue}
          onClick={() => {
            onClick(
              Math.min(
                internalValue + steps,
                maxValue || Number.MAX_SAFE_INTEGER
              )
            )
          }}
        >
          <Icon
            family="mui"
            name="Add"
            style={{ width: iconSize, height: iconSize }}
          />
        </IconButton>
      </FlexBox>
    </FlexBox>
  )
}

export default InputNumberBox
export { InputNumberBox }
