import { AxiosResponse } from 'axios'
import HolidayDTO from '@volvo-apps/shared/models/HolidayDTO'
import { GenericService } from './GenericService'

/**
 * Holiday
 */
export class HolidayService extends GenericService<HolidayDTO> {
  readonly URI: string = 'gateway/Holiday'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all holidays
   */
  public async getAll() {
    return super.get<AxiosResponse<HolidayDTO[]>>('GetAll')
  }

  /**
   *
   * @param id The id of the holiday
   */
  public async getHoliday(id: number) {
    return super.get<AxiosResponse<HolidayDTO>>('Get', { id })
  }

  /**
   * Delete holiday
   *
   * @param id The id of the holiday
   */
  public async deleteHoliday(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Update holiday
   *
   * @param updatedHoliday The updated holiday
   */
  public async updateHoliday(updatedHoliday: HolidayDTO) {
    return super.put<AxiosResponse<HolidayDTO>>('Update', {
      ...updatedHoliday
    })
  }

  /**
   * Create holiday
   *
   * @param payload The payload to update the holiday
   */
  public async createHoliday(payload: Pick<HolidayDTO, 'name' | 'date'>) {
    return super.post<AxiosResponse<HolidayDTO>>('Add', payload)
  }

  /**
   * Get all cms holidays
   */
  public async getAllCms() {
    return super.get<AxiosResponse<HolidayDTO[]>>('GetAllCms')
  }
}
