import { AxiosResponse } from 'axios'
import CampaignDTO from '@volvo-apps/shared/models/CampaignDTO'
import { GenericService } from './GenericService'

/**
 * Campaigns
 */
export class CampaignService extends GenericService<CampaignDTO> {
  readonly URI: string = 'gateway/Campaigns'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all campaigns
   */
  public async getAll() {
    return super.get<AxiosResponse<CampaignDTO[]>>('GetAll')
  }

  /**
   *
   * @param id The id of the campaign
   */
  public async getCampaign(id: number) {
    return super.get<AxiosResponse<CampaignDTO>>('Get', { id })
  }

  /**
   * Delete campaign
   *
   * @param id The id of the campaign
   */
  public async deleteCampaign(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Update campaign
   *
   * @param updatedCampaign The updated campaign
   */
  public async updateCampaign(
    updatedCampaign: Pick<
      CampaignDTO,
      'financialProduct' | 'productLine' | 'documentType' | 'id'
    >
  ) {
    return super.put<AxiosResponse<CampaignDTO>>('Update', {
      ...updatedCampaign
    })
  }

  /**
   * Create a campaign
   *
   * @param payload The payload to create the campaign
   */
  public async createCampaign(
    payload: Pick<
      CampaignDTO,
      'financialProduct' | 'productLine' | 'documentType'
    >
  ) {
    return super.post<AxiosResponse<CampaignDTO>>('Add', payload)
  }
}
