import { getTerm } from '@volvo-apps/shared/core/actions/Term'
import TermDTO from '@volvo-apps/shared/models/TermDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetTermParams = {
  onSuccess?: (term: TermDTO) => void
  id?: number
}

/**
 * Hook to get all term
 *
 * @param params params
 * @returns term, isLoading, refetch
 */
export const useGetTerm = (params: useGetTermParams) => {
  const { id, onSuccess } = params
  const {
    data: term,
    isLoading,
    refetch
  } = useQuery('getTerm', {
    enabled: !!id,
    queryFn: () => getTerm(id || 0),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { term, isLoading, refetch }
}
