import { SiemsService } from '../service/Siems.service'

/**
 * Get client has consortium
 *
 * @returns has consortium
 */
export function getClientHasConsortium() {
  const service = new SiemsService()
  return service.getClientHasConsortium().then(({ data }) => data)
}
