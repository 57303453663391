import VehicleDTO from '@volvo-apps/shared/models/VehicleDTO'
import VehicleImagesDTO from '@volvo-apps/shared/models/VehicleImagesDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { ParametersRoutesEnum as VehicleRoutesEnum } from 'routes/cms/routesEnums'

import { useCreateVehicle } from 'hooks/vehicles/useCreateVehicle'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { VehicleData } from '../Form/schema'
import { useVehicleForm } from '../Form/useVehicleForm'
import { VehicleForm } from '../Form/VehicleForm'

/**
 * Vehicle Create
 *
 * @returns TSX
 */
function Create() {
  const { methods } = useVehicleForm({
    defaultValues: {
      vehiclePictures: []
    }
  })

  const navigate = useNavigate()
  const { t } = useLanguage()

  const { mutate, isLoading } = useCreateVehicle({
    onSuccess: () => {
      toast.success(t('cms.vehicles.create.success'))
      navigate(VehicleRoutesEnum.VEHICLES)
    }
  })

  const onSubmit = React.useCallback(
    (formData: VehicleData) => {
      const { vehiclePictures, ...vehicle } = formData

      const pictures = vehiclePictures.map((picture) => {
        const { base64, coverPhoto, fileName } = picture
        return new VehicleImagesDTO().fromJSON({
          profilePicture: base64,
          coverPhoto,
          fileName
        })
      })

      mutate(
        new VehicleDTO().fromJSON({
          ...vehicle,
          profilePictures: pictures,
          vehicleImagesConfigId: 1
        })
      )
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.vehicles.create.title')}
      </Typography>
      <VehicleForm
        isLoading={isLoading}
        methods={methods}
        onSubmit={onSubmit}
        submitButtonLabel={t('cms.vehicles.create.button')}
        disableWhenDirty={false}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
