import { AxiosResponse } from 'axios'
import FinancialProductDTO from '@volvo-apps/shared/models/FinancialProductDTO'
import { GenericService } from './GenericService'

/**
 * FinancialProductService
 */
export class FinancialProductService extends GenericService<FinancialProductDTO> {
  readonly URI: string = 'gateway/FinancialProduct'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   *
   * @param productLine productLine id
   */
  public async getAll(productLine: number) {
    return super.get<AxiosResponse<FinancialProductDTO[]>>('GetAll', {
      productLine
    })
  }

  /**
   *
   * @param id The id of the financial product
   */
  public async getFinancialProduct(id: number) {
    return super.get<AxiosResponse<FinancialProductDTO>>('Get', { id })
  }

  /**
   * Delete financial product
   *
   * @param id The id of the financial product
   */
  public async deleteFinancialProduct(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Update financial product
   *
   * @param updatedFinancialProduct The updated financial product
   */
  public async updateFinancialProduct(
    updatedFinancialProduct: FinancialProductDTO
  ) {
    return super.put<AxiosResponse<FinancialProductDTO>>('Update', {
      ...updatedFinancialProduct
    })
  }

  /**
   * Create financial product
   *
   * @param name The name of the financial product
   */
  public async createFinancialProduct(name: string) {
    return super.post<AxiosResponse<FinancialProductDTO>>('Add', {
      name
    })
  }

  /**
   * Get all cms financial products
   */
  public async getAllCms() {
    return super.get<AxiosResponse<FinancialProductDTO[]>>('GetAllCms')
  }
}
