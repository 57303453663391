import { updateCampaign } from '@volvo-apps/shared/core/actions/Campaign'
import { updateNotification } from '@volvo-apps/shared/core/actions/Notification'
import CampaignDTO from '@volvo-apps/shared/models/CampaignDTO'
import NotificationDTO from '@volvo-apps/shared/models/NotificationDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateCmsNotificationParams = {
  onSuccess?: () => void
}

type updateNotificationRequest = {
  campaign: Pick<
    CampaignDTO,
    'financialProduct' | 'productLine' | 'documentType' | 'id'
  >
  notification: Omit<NotificationDTO, 'campaignId'>
}

/**
 *
 * @param params params
 * @returns mutate, notifications, isLoading
 */
export const useUpdateCmsNotification = (
  params: useUpdateCmsNotificationParams
) => {
  const { onSuccess } = params

  const fetchUpdateNotification = async ({
    campaign,
    notification
  }: updateNotificationRequest) =>
    updateCampaign(campaign).then((response) => {
      const notificationDTO = new NotificationDTO().fromJSON({
        ...notification,
        campaignId: response.data.id
      })

      updateNotification(notificationDTO)
    })

  const {
    data: notification,
    isLoading,
    mutate
  } = useMutation('fetchUpdateNotification', {
    mutationFn: fetchUpdateNotification,
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, notification, isLoading }
}
