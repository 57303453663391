import React from 'react'

import { useLocation } from 'react-router-dom'

/**
 * @returns the query string as a URLSearchParams object
 */
export const useURLQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
