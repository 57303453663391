import React from 'react'

import { Loader } from 'components/Loader'

import { useVehicleImage } from 'hooks/useVehicleImage'

type VehicleImageProps = {
  id: number
}

/**
 * @param props Vehicle Image Props
 * @returns Vehicle Image TSX
 */
function VehicleImage(props: VehicleImageProps) {
  const size = 150
  const { id } = props
  const { isLoading, data } = useVehicleImage({ id })

  const coverPhotoUrl = React.useMemo(() => {
    if (data?.length) {
      const coverPhoto = data.find((item) => item.coverPhoto)

      if (coverPhoto) {
        return coverPhoto.azureUrl
      }

      return data[0].azureUrl
    }

    return ''
  }, [data])

  return !isLoading && data ? (
    <img
      width={size}
      height={size}
      style={{ objectFit: 'contain' }}
      src={coverPhotoUrl}
    />
  ) : (
    <Loader
      boxProps={{
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: size,
        minHeight: size
      }}
    />
  )
}

export default VehicleImage
export { VehicleImage }
