import { downloadBillPDF } from '@volvo-apps/shared/core/actions/Sales'
import { SalesInvoiceDTO } from '@volvo-apps/shared/models/SalesInvoiceDTO'

import React from 'react'

import { useMutation } from 'react-query'

import { PlainButton, PlainButtonProps } from 'components/PlainButton'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

type DownloadInvoiceButtonProps = {
  invoice: SalesInvoiceDTO
} & PlainButtonProps

/**
 *
 * @param props DownloadInvoiceButtonProps
 * @returns TSX
 */
const DownloadInvoiceButton = (props: DownloadInvoiceButtonProps) => {
  const { invoice, ...plainButtonProps } = props

  const { t } = useLanguage()
  const { mutate, isLoading } = useMutation('downloadTicketPDF', {
    mutationFn: () =>
      downloadBillPDF(invoice.DocumentoCobranca, invoice.Tipo, '2'),
    onError: handleErrorFeedback
  })

  const downloadPDF = () => {
    mutate()
  }

  return (
    <PlainButton
      {...plainButtonProps}
      onClick={downloadPDF}
      variant="outlined"
      color="secondary"
      sx={{ mt: 2 }}
      loading={isLoading}
    >
      {t('financial.rental.downloadInvoice')}
    </PlainButton>
  )
}

export default DownloadInvoiceButton
