import { t } from 'i18next'
import { toast } from 'react-toastify'

import { useDeleteFaqQuestion } from 'hooks/faqQuestions/useDeleteFaqQuestion'
import { useGetCmsFaqQuestion } from 'hooks/faqQuestions/useGetCmsFaqQuestions'
import { useUpdateFaqQuestion } from 'hooks/faqQuestions/useUpdateFaqQuestions'

/**
 * Hook to use CRUD operations on faq questions
 *
 * @returns faqQuestion, deleteFaqQuestion, updateFaqQuestion, refetch, isLoading, getLoading, deleteLoading, updateLoading
 */
export const useCrudFaqQuestion = () => {
  const { faqQuestion, refetch, isLoading: getLoading } = useGetCmsFaqQuestion()
  const { mutate: deleteFaqQuestion, isLoading: deleteLoading } =
    useDeleteFaqQuestion({
      onSuccess: () => {
        refetch()
        toast.success(t('cms.pushNotifications.delete.success'))
      }
    })
  const {
    mutate: updateFaqQuestion,
    isLoading: updateLoading,
    mutateAsync: updateAsyncFaqQuestion
  } = useUpdateFaqQuestion({
    onSuccess: refetch
  })

  const isLoading = getLoading || deleteLoading || updateLoading

  return {
    faqQuestion,
    deleteFaqQuestion,
    updateFaqQuestion,
    updateAsyncFaqQuestion,
    refetch,
    isLoading,
    getLoading,
    deleteLoading,
    updateLoading
  }
}
