import { updateVehicle } from '@volvo-apps/shared/core/actions/Vehicle'
import VehicleDTO from '@volvo-apps/shared/models/VehicleDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateVehicleParams = {
  onSuccess?: () => void
}

/**
 * Hook to update one vehicle
 *
 * @param params params
 * @returns mutate, vehicle, isLoading and mutateAsync
 */
export const useUpdateVehicle = (params: useUpdateVehicleParams = {}) => {
  const { onSuccess } = params
  const {
    mutate,
    isLoading,
    data: vehicle,
    mutateAsync
  } = useMutation('updateVehicle', {
    mutationFn: ({ vehicle }: { vehicle: VehicleDTO }) =>
      updateVehicle(vehicle),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, vehicle, isLoading, mutateAsync }
}
