import {
  useGetAllCmsNps,
  useUpdateNps
} from '@volvo-apps/shared/core/hooks/nps'
import NpsDTO from '@volvo-apps/shared/models/NpsDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'
import { PlainButton } from 'components/PlainButton'

import { SurveyRoutesEnum } from 'routes/cms/routesEnums'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { columns } from './tableColumns'

/**
 * Nps List
 *
 * @returns TSX
 */
function List() {
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { allCmsNps, isLoading, refetch } = useGetAllCmsNps({
    onError: handleErrorFeedback
  })
  const { mutateAsync } = useUpdateNps({
    onSuccess: refetch,
    onError: handleErrorFeedback
  })

  const handleCreate = React.useCallback(() => {
    navigate(SurveyRoutesEnum.SURVEY_NPS_CREATE)
  }, [navigate])

  const handleToggle = React.useCallback(
    async (nps: Record<string, unknown>, checked: boolean) => {
      const newNps = new NpsDTO().fromJSON({
        ...nps,
        active: checked
      })

      const updateResponse = await mutateAsync({
        nps: newNps
      })

      toast.success(
        updateResponse.active
          ? t('cms.surveys.nps.toggle.enabled')
          : t('cms.surveys.nps.toggle.disabled')
      )
    },
    [mutateAsync, t]
  )

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.surveys.nps.list.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.surveys.nps.list.title')}
      </Typography>

      <CrudDataGrid
        columns={columns}
        rows={allCmsNps || []}
        handleToggle={handleToggle}
        linkEditProps={{
          to: (id) => SurveyRoutesEnum.SURVEY_NPS_UPDATE.replace(':id', id)
        }}
        loading={isLoading}
        hideFooter
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
