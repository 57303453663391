import LeadNotificationDTO from '@volvo-apps/shared/models/LeadNotificationDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { LeadsRoutesEnum } from 'routes/cms/routesEnums'

import { useAddLeadNotification } from 'hooks/leads/useAddLeadNotification'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { AutomationForm } from '../Form/AutomationForm'
import { AutomationData } from '../Form/schema'
import { useAutomationForm } from '../Form/useAutomationForm'

/**
 * Automation Create
 *
 * @returns TSX
 */
function Create() {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const { methods } = useAutomationForm({
    defaultValues: {
      interval: '',
      dayWeek: '',
      hourDay: '',
      email: ''
    }
  })

  const { mutate, isLoading } = useAddLeadNotification({
    onSuccess: () => {
      toast.success(t('cms.leads.automation.create.success'))
      navigate(LeadsRoutesEnum.LEADS_AUTOMATION_LIST)
    }
  })

  const onSubmit = React.useCallback(
    (formData: AutomationData) => {
      const leadNotification = new LeadNotificationDTO().fromJSON(formData)
      mutate(leadNotification)
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.leads.automation.create.title')}
      </Typography>
      <AutomationForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.leads.automation.create.button')}
        shouldSetInitialDayOfWeek
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
