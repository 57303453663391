import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import PolicyDTO from '../../../models/PolicyDTO'
import { getInsurances } from '../../actions/Insurance'

type useGetInsurancesParams = HookQueryParams<PolicyDTO[]>

/**
 * Hook to get insurances
 *
 * @param params params
 * @returns insurances, isLoading, refetch
 */
export const useGetInsurances = (params: useGetInsurancesParams = {}) => {
  const { onSuccess, onError, suspense } = params
  const {
    data: insurances,
    isLoading,
    refetch
  } = useQuery('getInsurances', {
    queryFn: getInsurances,
    onSuccess,
    onError,
    suspense
  })

  return { insurances, isLoading, refetch }
}
