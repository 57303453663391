import { arraySerializableToObjectById } from 'common/utils/transform'

import { useGetCmsFinancialProducts } from './useGetCmsFinancialProducts'

/**
 * Hook to get product lines by id
 *
 * @returns productLinesById
 */
export const useGetCmsFinancialProductsById = () => {
  const { financialProducts } = useGetCmsFinancialProducts()

  return {
    financialProductsById: arraySerializableToObjectById(
      financialProducts || []
    )
  }
}
