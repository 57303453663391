import { useAddOrUpdateNpsUser } from '@volvo-apps/shared/core/hooks/nps'
import NpsDTO from '@volvo-apps/shared/models/NpsDTO'
import NpsUserDTO from '@volvo-apps/shared/models/NpsUserDTO'

import React from 'react'

import { Typography } from '@mui/material'

import { Form } from 'components/Form'
import { Modal } from 'components/Modal'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { ModalEnum, NpsModalsGenericProps } from '../NpsModals'
import Content from './Content'
import { useRateModalForm } from './Form'

type RateModalProps = NpsModalsGenericProps & {
  origin: string
  availableNps: NpsDTO
}

/**
 * @param props RateModalProps
 * @returns TSX
 */
const RateModal = (props: RateModalProps) => {
  const { changeModal, origin, handleClose, availableNps } = props

  const { t } = useLanguage()

  const methods = useRateModalForm()

  const handleLater = () => {
    changeModal(ModalEnum.Empty)
    handleClose?.()
  }

  const { mutate, isLoading } = useAddOrUpdateNpsUser({
    onSuccess: (resp) => {
      if (resp?.completed) {
        changeModal(ModalEnum.Thanks)
      } else {
        handleLater()
      }
    },
    onError: (err) => {
      handleErrorFeedback(err)
      handleLater()
    }
  })

  const handleSubmit = (completed: boolean) => {
    const formData = methods.getValues()

    const npsUserData = availableNps.npsUsers[0]

    const npsUser = new NpsUserDTO().fromJSON({
      ...npsUserData,
      degree: formData.option?.value,
      completed,
      npsId: availableNps.id,
      userDescription: formData.reason,
      userPath: origin
    })

    mutate({ npsUser })
  }

  const _handleClose = () => {
    handleSubmit(false)
  }

  return (
    <Modal
      showCloseButton
      disableBackdropClick
      title={
        <Typography fontSize={32} color={Colors.light.interactDefault}>
          {t('nps.rateModal.title')}
        </Typography>
      }
      PaperProps={{ sx: { p: 1, borderRadius: 2.5 } }}
      content={
        <Form methods={methods}>
          <Content />
        </Form>
      }
      actionsDirection="column"
      actionsGap={1}
      actions={[
        {
          children: t('nps.rateButton'),
          loading: isLoading,
          onClick: () => {
            handleSubmit(true)
          },
          disabled: !methods.formState.isValid,
          sx: {
            backgroundColor: Colors.light.interactDefault,
            width: '100%'
          },
          variant: 'contained'
        },
        {
          children: t('nps.laterButton'),
          onClick: _handleClose,
          sx: { color: Colors.light.text, width: '100%' },
          variant: 'text'
        }
      ]}
      onClose={_handleClose}
      open={true}
    />
  )
}

export default RateModal
export { RateModal }
