import { deleteFaqQuestion } from '@volvo-apps/shared/core/actions/FaqQuestion'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useDeleteFaqQuestionParams = {
  onSuccess?: () => void
}

/**
 * Hook to delete one faq question
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteFaqQuestion = (
  params: useDeleteFaqQuestionParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data } = useMutation('deleteFaqQuestion', {
    mutationFn: ({ id }: { id: number }) => deleteFaqQuestion(id),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, data, isLoading }
}
