import { getAllCmsFaqCategory } from '@volvo-apps/shared/core/actions/Category'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all cms faq category
 *
 * @returns faqCategory, isLoading, refetch
 */
export const useGetCmsFaqCategory = () => {
  const {
    data: faqCategory,
    isLoading,
    refetch
  } = useQuery('cmsFaqCategory', {
    queryFn: getAllCmsFaqCategory,
    onError: handleErrorFeedback
  })

  return { faqCategory, isLoading, refetch }
}
