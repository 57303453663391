import { addOrUpdateNPSUser } from '@volvo-apps/shared/core/actions/Nps'

import { useMutation } from 'react-query'
import { HookQueryParams } from '../../../common/utils/types'
import NpsUserDTO from '../../../models/NpsUserDTO'

type useAddOrUpdateNpsUserParams = HookQueryParams<NpsUserDTO>

/**
 * Hook to create a nps
 *
 * @param params params
 * @returns mutate, npsUser, isLoading
 */
export const useAddOrUpdateNpsUser = (params: useAddOrUpdateNpsUserParams) => {
  const { onSuccess, onError } = params
  const {
    data: npsUser,
    isLoading,
    mutate
  } = useMutation('addOrUpdateNpsUser', {
    mutationFn: ({ npsUser }: { npsUser: NpsUserDTO }) =>
      addOrUpdateNPSUser(npsUser),
    onSuccess,
    onError
  })

  return { mutate, npsUser, isLoading }
}
