import {
  useAddUserDomain,
  useDeleteUserDomain,
  useGetUserDomains
} from '@volvo-apps/shared/core/hooks/userDomains'
import UserDomainDTO from '@volvo-apps/shared/models/UserDomainDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { GridValidRowModel } from '@mui/x-data-grid'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Form, Input } from 'components'
import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'
import { FlexBox } from 'components/FlexBox'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { FunctionalDomainData, schema } from './schema'
import { columns } from './tableColumns'

/**
 * Functional Domains
 *
 * @returns TSX
 */
function List() {
  const methods = useForm<FunctionalDomainData>({
    mode: 'onChange',
    defaultValues: {
      domain: ''
    },
    resolver: schema
  })

  const { t } = useLanguage()

  const { domains, isLoading, refetch } = useGetUserDomains({
    onError: handleErrorFeedback
  })

  const { mutate: deleteFunctionalDomain } = useDeleteUserDomain({
    onSuccess: () => {
      toast.success(t('cms.functionalDomains.delete.success'))
      refetch()
    },
    onError: handleErrorFeedback
  })

  const { mutate, isLoading: isSubmittingEmails } = useAddUserDomain({
    onSuccess: () => {
      toast.success(t('cms.functionalDomains.success'))
      methods.reset()
      refetch()
    },
    onError: handleErrorFeedback
  })

  const handleDelete = React.useCallback(
    (domain: Record<string, unknown>) => {
      deleteFunctionalDomain({ id: domain.id as number })
    },
    [deleteFunctionalDomain]
  )

  const addDomain = React.useCallback(() => {
    const { domain } = methods.getValues()
    mutate({ domain: new UserDomainDTO().fromJSON({ domain }) })
  }, [methods, mutate])

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.functionalDomains.title')}
      </Typography>
      <Form methods={methods} onSubmit={addDomain}>
        <FlexBox alignItems="center">
          <Input
            margin="dense"
            label={t('cms.functionalDomains.newDomain')}
            name="domain"
            autoFocus
            sx={{ width: 400 }}
            InputLabelProps={{
              style: {
                top: -2
              }
            }}
            inputProps={{
              style: { height: 15 }
            }}
          />
          <PlainButton
            type="submit"
            variant="contained"
            disabled={!methods.formState.isValid}
            loading={isSubmittingEmails}
            sx={{ height: 48, mx: 3, px: 5, py: 0, mt: 0.2 }}
          >
            {t('cms.functionalDomains.button')}
          </PlainButton>
        </FlexBox>
      </Form>
      <CrudDataGrid
        columns={columns}
        rows={(domains as unknown as GridValidRowModel[]) || []}
        loading={isLoading}
        handleDelete={handleDelete}
        hideFooter
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
