import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as FaqCategoryRoutesEnum } from 'routes/cms/routesEnums'

import { useGetFaqCategory } from 'hooks/faqCategories/useGetFaqCategory'
import { useUpdateFaqCategory } from 'hooks/faqCategories/useUpdateFaqCategory'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import FaqCategoryForm from '../Form/FaqCategoryForm'
import { FaqCategoryData } from '../Form/schema'
import { useFaqCategoryForm } from '../Form/useFaqCategoryForm'

/**
 * Faq Categories Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { methods } = useFaqCategoryForm({
    defaultValues: { label: '', icon: '', description: '' }
  })

  const { faqCategory, isLoading: getLoading } = useGetFaqCategory({
    id: Number(id),
    onSuccess: (faqCategory) => {
      methods.reset({
        label: faqCategory.label,
        icon: faqCategory.icon,
        description: faqCategory.description
      })
    }
  })

  const { mutate, isLoading } = useUpdateFaqCategory({
    onSuccess: () => {
      toast.success(t('cms.faq.updateCategory.success'))
      navigate(FaqCategoryRoutesEnum.FAQ_CATEGORIES_VIEW)
    }
  })

  const onSubmit = React.useCallback(
    (formData: FaqCategoryData) => {
      const newFaqCategory = new FaqCategoryDTO().fromJSON({
        ...faqCategory,
        ...formData,
        createdDate: undefined
      })
      mutate({ faqCategory: newFaqCategory })
    },
    [faqCategory, mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.faq.updateCategory.title')}
      </Typography>
      <FaqCategoryForm
        isLoading={isLoading || getLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.faq.updateCategory.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
