// --------------------------------------------------------
// GENERAL
// --------------------------------------------------------
import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'
// --------------------------------------------------------

/**
 * Login view model
 */
class AddressDTO extends Serializable {
  @jsonProperty(String)
  public bairro!: string

  @jsonProperty(String)
  public cidade!: string

  @jsonProperty(String)
  public complemento!: string

  @jsonProperty(String)
  public endereco!: string

  @jsonProperty(String)
  public estado!: string

  @jsonProperty(String)
  public codIbge!: string

  @jsonProperty(String)
  public siglaEstado!: string
}

export default AddressDTO
