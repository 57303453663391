import React from 'react'

import { Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import Colors from 'constants/Colors'

/**
 *
 * @param handleEdit handleEdit
 * @returns TSX
 */
export const columns: GridColDef[] = [
  {
    field: 'termText',
    headerName: '',
    width: 320,
    sortable: false
  },
  {
    field: 'author',
    headerName: 'cms.terms.list.author',
    flex: 1,
    sortable: false
  },
  {
    field: 'updatedDate',
    headerName: 'cms.simulationParameters.list.columns.date',
    width: 200,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const updatedDate = params.row.updatedDate as Date

      return (
        <Typography fontSize={16} color={Colors.light.text}>
          {dayjs(updatedDate).format('DD/MM/YYYY [às] HH:mm')}
        </Typography>
      )
    }
  }
]
