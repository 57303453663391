import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * NotificationDTO
 */
class NotificationDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('title')
  @jsonProperty(String)
  public title!: string

  @jsonName('body')
  @jsonProperty(String)
  public body!: string

  @jsonName('interval')
  @jsonProperty(String)
  public interval!: string

  @jsonName('campaignId')
  @jsonProperty(Number)
  public campaignId!: number

  @jsonName('dayWeek')
  @jsonProperty(String)
  public dayWeek!: string

  @jsonName('hourDay')
  @jsonProperty(String)
  public hourDay!: string

  @jsonName('beginDate')
  @jsonProperty(Date)
  public beginDate!: string

  @jsonName('endDate')
  @jsonProperty(Date)
  public endDate!: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedstring!: string

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate!: string
}

export default NotificationDTO
