import React from 'react'

import { Box, Divider, Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'

import { Datepicker, Form, InputNumber } from 'components'
import { FlexBox } from 'components/FlexBox'
import {
  FinancialProductsSelect,
  ProductLinesSelect
} from 'components/Form/Fields'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { OnSubmitSimulationParameter } from '../types'
import CdcTaxes from './CdcTaxes'
import FinameTaxes from './FinameTaxes'
import { SimulationParameterData } from './schema'

type SimulationParameterFormProps = {
  onSubmit: (formData: OnSubmitSimulationParameter) => void
  isLoading: boolean
  methods: UseFormReturn<SimulationParameterData, unknown>
  submitButtonLabel?: string | null
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function SimulationParameterForm(props: SimulationParameterFormProps) {
  const { onSubmit, isLoading, methods, submitButtonLabel } = props

  const { t } = useLanguage()

  const productLineId = methods.watch('productLineId')
  const financialProductId = methods.watch('financialProductId')
  const isCdc = financialProductId === 1

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: (t) => ({
          maxWidth: t.breakpoints.values.sm,
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        })
      }}
    >
      <Box ml={2}>
        <Typography my={3} fontSize={16} color={Colors.light.greys.GREY_600}>
          {t('cms.simulationParameters.form.productType')}
        </Typography>
        <Divider sx={{ mb: 3 }} />
      </Box>
      <ProductLinesSelect
        name="productLineId"
        margin="normal"
        fullWidth
        label={t('cms.simulationParameters.form.productLine')}
      />
      <FinancialProductsSelect
        name="financialProductId"
        margin="normal"
        fullWidth
        label={t('cms.simulationParameters.form.product')}
        productLine={productLineId}
      />
      <Typography my={3} fontSize={16} color={Colors.light.greys.GREY_600}>
        {t('cms.simulationParameters.form.installmentsMonths')}
      </Typography>
      <FlexBox gap={2}>
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.min')}
          name="installments.minValue"
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.max')}
          name="installments.maxValue"
        />
      </FlexBox>
      <FlexBox gap={2}>
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.step')}
          name="installments.leapOfMonths"
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.initialValue')}
          name="installments.valueStarts"
        />
      </FlexBox>
      <Typography my={3} fontSize={16} color={Colors.light.greys.GREY_600}>
        {t('cms.simulationParameters.form.gracePeriodMonths')}
      </Typography>
      <FlexBox gap={2}>
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.min')}
          name="gracePeriod.minValue"
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.max')}
          name="gracePeriod.maxValue"
        />
      </FlexBox>
      <FlexBox gap={2}>
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.step')}
          name="gracePeriod.leapOfMonths"
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.initialValue')}
          name="gracePeriod.valueStarts"
        />
      </FlexBox>
      <Box ml={2}>
        <Typography my={3} fontSize={16} color={Colors.light.greys.GREY_600}>
          {t('cms.simulationParameters.form.ruleValidity')}
        </Typography>
        <Divider sx={{ mb: 3 }} />
      </Box>
      <Box my={2}>
        <Datepicker
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.startDate')}
          name="startDate"
        />
      </Box>
      <Box my={2}>
        <Datepicker
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.endDate')}
          name="endDate"
        />
      </Box>
      {isCdc ? <CdcTaxes /> : <FinameTaxes />}
      <PlainButton
        type="submit"
        fullWidth
        variant="contained"
        disabled={!methods.formState.isValid || !methods.formState.isDirty}
        loading={isLoading}
        sx={{ mt: 3, mb: 2 }}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default SimulationParameterForm
export { SimulationParameterForm }
