import { makeResolver } from 'common/utils/form'
import { cpfValidator } from 'common/validators/cpfCnpj'
import { emailValidator } from 'common/validators/email'
import { nameValidator } from 'common/validators/name'
import {
  confirmPasswordValidator,
  passwordValidator
} from 'common/validators/password'

import { Step1, Step2, Step3 } from './Steps'
import {
  FormValuesStep1RequiredKeys,
  FormValuesStep2RequiredKeys
} from './Steps/types'

export const registerSchema = makeResolver((Yup) =>
  Yup.object({
    name: nameValidator,
    documents: Yup.array().of(
      Yup.object({
        value: cpfValidator
      })
    ),
    email: emailValidator,
    password: passwordValidator,
    password_confirm: confirmPasswordValidator,
    termsAndConditions: Yup.boolean()
      .oneOf([true], 'validation.required.general')
      .required('validation.required.general')
  })
)

export const steps: {
  /**
   *
   */
  title?: string
  /**
   *
   */
  component: React.FC
  /**
   *
   */
  fields: string[]
}[] = [
  {
    component: Step1,
    fields: FormValuesStep1RequiredKeys
  },
  {
    component: Step2,
    fields: FormValuesStep2RequiredKeys
  },
  {
    component: Step3,
    fields: FormValuesStep1RequiredKeys
  }
]
