import React from 'react'

import { Typography } from '@mui/material'

import { Modal, useModalMethods } from 'components'
import { FlexBox } from 'components/FlexBox'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { ModalContent } from './ModalContent'

/**
 * Link Document Button
 *
 * @returns TSX
 */
const LinkDocumentButton: React.FC = () => {
  const { open, setClosed, toggleOpen } = useModalMethods()
  const { t } = useLanguage()

  return (
    <FlexBox>
      <PlainButton variant="contained" onClick={toggleOpen}>
        {t('cms.linkUsers.list.button')}
      </PlainButton>

      <Modal
        open={open}
        showCloseButton
        onClose={setClosed}
        PaperProps={{ sx: { px: 1, py: 2, borderRadius: 2.5 } }}
        title={
          <Typography
            variant="body1"
            color={Colors.light.text}
            fontWeight={700}
            fontSize={24}
          >
            {t('cms.linkUsers.linkModal.title')}
          </Typography>
        }
        content={<ModalContent closeModal={setClosed} />}
        actionsDirection="column"
        actionsGap={2}
      />
    </FlexBox>
  )
}

export default LinkDocumentButton
export { LinkDocumentButton }
