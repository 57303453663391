import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'
import PolicyDTO from '../../models/PolicyDTO'
import InsuranceItemDTO from '../../models/InsuranceItemDTO'
import InsuranceClaimDTO from '../../models/InsuranceClaimDTO'

/**
 * Insurance
 */
export class InsuranceService extends GenericService<PolicyDTO> {
  readonly URI: string = 'gateway/Seguros'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all insurances
   *
   */
  public async getInsurances() {
    return super.get<AxiosResponse<PolicyDTO[]>>('GetInsurances')
  }

  /**
   * Get if the user has insurance contract
   *
   */
  public async getHasInsuranceContract() {
    return super.get<AxiosResponse<boolean>>('HasInsuranceContract')
  }

  /**
   * Get insurance items
   *
   * @param policyNumber string
   * @returns InsuranceItemDTO[]
   */
  public async getInsuranceItems(policyNumber: string) {
    return super.get<AxiosResponse<InsuranceItemDTO[]>>(
      `GetInsuranceItems?policyNumber=${policyNumber}`
    )
  }

  /**
   * Get insurance claims
   *
   * @returns InsuranceClaimDTO[]
   */
  public async getInsuranceClaims() {
    return super.get<AxiosResponse<InsuranceClaimDTO[]>>('GetInsuranceClaims')
  }
}
