import { getAllCmsVehicles } from '@volvo-apps/shared/core/actions/Vehicle'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all cms vehicles
 *
 * @returns Vehicles, isLoading, refetch
 */
export const useGetCmsVehicles = () => {
  const {
    data: vehicles,
    isLoading,
    refetch
  } = useQuery('cmsVehicles', {
    queryFn: getAllCmsVehicles,
    onError: handleErrorFeedback
  })

  return { vehicles, isLoading, refetch }
}
