import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'

class ParcelaFinameDTO {
  datVencto?: string
  tipoParcela?: string
  nroParcela!: number
  saldoDevedor!: number
  vlrPrincipal!: number
  vlrJuros!: number
  vlrParcela!: number
  diasCorridos!: number
  vlrParcelaProjetada!: number
  fatorIPCAMes?: number
  taxaSelicMes?: number
}

class TotalizadorFinameDTO {
  somaPrincipal!: number
  somaJuros!: number
  somaParcela!: number
  somaParcelaProjetada!: number
}

/**
 *  SimulationFiname DTO
 */
class SimulationFinameDTO extends Serializable {
  @jsonProperty(Number)
  qtdParcelas!: number

  @jsonProperty(String)
  datInicio!: string

  @jsonProperty(String)
  datPrimVencto!: string

  @jsonProperty(String)
  datUltVencto!: string

  @jsonProperty(String)
  modalidade?: string

  @jsonProperty(Number)
  taxaAnual?: number

  @jsonProperty(Number)
  taxaBasicaBNDES!: number

  @jsonProperty(Number)
  taxaRiscoBNDES!: number

  @jsonProperty(Number)
  taxaSomadaRisco!: number

  @jsonProperty(Number)
  taxaVFS!: number

  @jsonProperty(Number)
  cet!: number

  @jsonProperty(Number)
  ValorDespesas!: number

  @jsonProperty(Number)
  ValorFinanciado!: number

  @jsonProperty(Number)
  ValorLiberado!: number

  @jsonProperty(Number)
  prazoMeses!: number

  @jsonProperty(Number)
  percFGI!: number

  @jsonProperty(Number)
  valorFGI!: number

  @jsonProperty(Number)
  fatorIPCA!: number

  @jsonProperty(Number)
  taxaSelicAno!: number

  @jsonProperty(ParcelaFinameDTO)
  parcelas!: ParcelaFinameDTO[]

  @jsonProperty(TotalizadorFinameDTO)
  totalizadorFiname!: TotalizadorFinameDTO
}

export default SimulationFinameDTO
