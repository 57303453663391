import React from 'react'

import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps
} from '@mui/material'

type Props = Pick<LinearProgressProps, 'sx'> & {
  current: number
  max: number
  min?: number
}

const normalise = (value: number, min: number, max: number) =>
  ((value - min) * 100) / (max - min)

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function LinearProgress(props: Props) {
  const { current, max, min = 0, sx } = props
  return (
    <MuiLinearProgress
      variant="determinate"
      value={normalise(current, min, max)}
      sx={sx}
    />
  )
}

export default LinearProgress
export { LinearProgress }
