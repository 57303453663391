import { useGetUserListWithDocumentCms } from '@volvo-apps/shared/core/hooks/user/useGetUserListWithDocumentCms'

import React from 'react'

import { Typography } from '@mui/material'
import _ from 'lodash'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'

import { UserSettingsRoutesEnum } from 'routes/cms/routesEnums'

import useSearchBar from 'hooks/useSearchBar'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { columns } from './columns'

/**
 * Users Access List
 *
 * @returns TSX
 */
function List() {
  const { t } = useLanguage()
  const {
    userList = [],
    isLoading,
    isFetching
  } = useGetUserListWithDocumentCms({
    onError: handleErrorFeedback
  })

  const { searchTerm, searchBar } = useSearchBar({
    style: { margin: '12px 0 36px 0', width: 600 }
  })

  const items = React.useMemo(() => {
    const search = searchTerm.toLowerCase()

    if (!search) {
      return userList
    }

    return (
      userList?.filter(
        (item) =>
          _.deburr(item.name).match(new RegExp(searchTerm, 'ig')) ||
          _.deburr(item.email).match(new RegExp(searchTerm, 'ig'))
      ) ?? []
    )
  }, [userList, searchTerm])

  return (
    <CmsChildrenWithHeader>
      <Typography
        my={4}
        fontSize={20}
        color={Colors.light.greys.GREY_600}
        fontWeight="500"
        fontFamily="Volvo-Novum-Medium"
      >
        {t('cms.usersAccess.list.title')}
      </Typography>
      {searchBar}

      <CrudDataGrid
        columns={columns}
        rows={isLoading || isFetching ? [] : items}
        loading={isLoading || isFetching}
        getRowId={(row) => row.userId}
        type="striped"
        actionsConfig={{
          headerName: t('general.actions') as string,
          headerAlign: 'right'
        }}
        linkEditProps={{
          to: (id) =>
            UserSettingsRoutesEnum.USERS_ACCESS_LIST_UPDATE.replace(':id', id)
        }}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
