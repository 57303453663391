import React from 'react'

import { Box, FormHelperText, Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'

import { Form, Input, Checkbox } from 'components'
import { FlexBox } from 'components/FlexBox'
import { ProductLinesSelect } from 'components/Form/Fields'
import { UploadImageField } from 'components/Form/UploadImageField'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { VehicleData } from './schema'

type VehicleFormProps = {
  onSubmit: (formData: VehicleData) => void
  isLoading: boolean
  methods: UseFormReturn<VehicleData, unknown>
  submitButtonLabel?: string | null
  onRemoveBanner?: (id?: number) => void
  disableWhenDirty?: boolean
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function VehicleForm(props: VehicleFormProps) {
  const {
    onSubmit,
    isLoading,
    methods,
    submitButtonLabel,
    onRemoveBanner,
    disableWhenDirty = true
  } = props

  const vehiclePicturesError = methods.formState.errors.vehiclePictures

  const { t } = useLanguage()

  const makeCoverPhotoCheckbox = React.useCallback(
    (index: number) => {
      return (
        <FlexBox justifyContent="center" py={2}>
          <Checkbox
            label={t('cms.vehicles.form.coverPhoto')}
            name={`vehiclePictures[${index}].coverPhoto`}
            onChange={(checked) => {
              const { vehiclePictures } = methods.getValues()

              methods.setValue(
                'vehiclePictures',
                vehiclePictures.map((vehiclePicture, i) => {
                  if (i === index) {
                    return { ...vehiclePicture, coverPhoto: checked }
                  }
                  return { ...vehiclePicture, coverPhoto: false }
                }),
                { shouldDirty: true }
              )

              methods.trigger('vehiclePictures')
            }}
          />
        </FlexBox>
      )
    },
    [methods, t]
  )

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: {
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={(t) => ({
          maxWidth: t.breakpoints.values.sm,
          display: 'flex',
          flexDirection: 'column'
        })}
      >
        <Input
          margin="normal"
          fullWidth
          label={t('cms.vehicles.form.model')}
          name="model"
          autoFocus
        />

        <Input
          margin="normal"
          fullWidth
          label={t('cms.vehicles.form.name')}
          name="name"
        />

        <ProductLinesSelect
          name="productLineId"
          margin="normal"
          label={t('cms.vehicles.form.productLine')}
        />

        <Typography my={3} fontSize={20} color={Colors.light.greys.GREY_600}>
          {t('cms.vehicles.form.photo')}
        </Typography>

        <UploadImageField
          name="vehiclePictures"
          description={t('cms.vehicles.form.uploadDescription')}
          maxFiles={4}
          onRemove={onRemoveBanner}
          makeFooter={makeCoverPhotoCheckbox}
          width={{
            max: 2500,
            min: 0
          }}
          height={{
            max: 1600,
            min: 0
          }}
        />
        <FormHelperText error={!!vehiclePicturesError}>
          {t(vehiclePicturesError?.message || '')}
        </FormHelperText>
      </Box>
      <PlainButton
        type="submit"
        fullWidth
        variant="contained"
        disabled={
          !methods.formState.isValid ||
          (disableWhenDirty && !methods.formState.isDirty)
        }
        loading={isLoading}
        sx={(t) => ({
          maxWidth: t.breakpoints.values.sm,
          mt: 3,
          mb: 2
        })}
      >
        {submitButtonLabel}
      </PlainButton>
    </Form>
  )
}

export default VehicleForm
export { VehicleForm }
