import { useInvalidateQuery } from '@volvo-apps/shared/core/hooks'
import { useSearchCompanyNameByDocument } from '@volvo-apps/shared/core/hooks/linkedDocuments'
import { useCheckAndUpdateProfile } from '@volvo-apps/shared/core/hooks/user/useCheckAndUpdateProfile'
import DocumentDTO from '@volvo-apps/shared/models/DocumentDTO'
import UserDTO from '@volvo-apps/shared/models/UserDTO'

import React, { useState } from 'react'

import { IconButton, InputAdornment, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Document, Form, Input } from 'components'
import { DialogDefault } from 'components/DialogDefault'
import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { makeResolver } from 'common/utils/form'
import { getOnlyNumbers } from 'common/utils/string'
import {
  CpfOrCnpjValidation,
  cpfCnpjValidator
} from 'common/validators/cpfCnpj'
import { useLanguage } from 'context/Language'

type FormType = {
  document: string
  company: string
}

const schema = makeResolver((Yup) =>
  Yup.object({
    document: cpfCnpjValidator,
    company: Yup.string()
  })
)

type ModalContentProps = {
  closeModal: () => void
  buttonText: string
  user: UserDTO
}

/**
 *
 * @param props props
 * @returns TSX
 */
const ModalContent = (props: ModalContentProps) => {
  const { closeModal, buttonText, user } = props

  const { invalidate } = useInvalidateQuery()
  const { t } = useLanguage()

  const [openEmailDialogInfo, setOpenEmailDialogInfo] = useState(false)

  const methods = useForm<FormType>({
    mode: 'onChange',
    resolver: schema,
    defaultValues: {
      document: '',
      company: ''
    }
  })

  const {
    checkDocumentAndUpdateProfileAsync,
    isLoading: updateProfileLoading,
    profileEmail
  } = useCheckAndUpdateProfile({
    onError: handleErrorFeedback
  })

  const document = methods.watch('document')

  const documentIsValid = CpfOrCnpjValidation(document)

  const { isLoading: searchCompanyNameLoading, nameOrCompanyName } =
    useSearchCompanyNameByDocument({
      retry: 0,
      enabled: documentIsValid,
      document: document.replace(/\D/g, ''),
      onSuccess: (data) => {
        methods.setValue('company', data)
      },
      onError: (err) => {
        handleErrorFeedback(err)
        methods.setValue('company', '')
      }
    })

  const handleCloseModal = React.useCallback(() => {
    closeModal()
    invalidate({ queryKey: 'getDocumentsWithContracts' })
    invalidate({ queryKey: 'user' })
  }, [closeModal, invalidate])

  const handleUpdateProfile = React.useCallback(
    (data: FormType) => {
      const documents = [...(user.documents ?? [])]

      const alreadyExistsDocument = documents.find(
        (document) =>
          getOnlyNumbers(document.document) === getOnlyNumbers(data.document)
      )

      if (
        alreadyExistsDocument &&
        (!alreadyExistsDocument.isSignUpDocument ||
          alreadyExistsDocument.isContractsAttached)
      ) {
        toast.error(t('profile.menu.editProfile.documentExistsError'))
        return
      }

      const newDocument = new DocumentDTO().fromJSON({
        document: getOnlyNumbers(data.document),
        isSignUpDocument: false,
        verified: false,
        isContractsAttached: true
      })

      let indexToClean: null | number = null
      if (alreadyExistsDocument) {
        const index = documents.findIndex(
          (document) => document.document === alreadyExistsDocument.document
        )

        documents[index].isContractsAttached = true
        indexToClean = index
      } else {
        documents.push(newDocument)
      }

      const newUser = new UserDTO().fromJSON({
        ...user,
        documents
      })

      checkDocumentAndUpdateProfileAsync({
        params: { newUser, newDocument: newDocument.document }
      })
        .then((response) => {
          toast.success(t('profile.menu.editProfile.success'))
          if (response) setOpenEmailDialogInfo(true)
          else {
            handleCloseModal()
          }
        })
        .catch(() => {
          if (indexToClean) {
            documents[indexToClean].isContractsAttached = false
          }
        })
    },
    [user, checkDocumentAndUpdateProfileAsync, t, handleCloseModal]
  )

  const onSubmit = React.useCallback(
    (data: FormType) => {
      handleUpdateProfile(data)
    },
    [handleUpdateProfile]
  )

  React.useEffect(() => {
    if (!documentIsValid && document.length) {
      methods.setValue('company', '')
    }
  }, [document, documentIsValid, methods])

  const loading = searchCompanyNameLoading

  const isDisabled =
    !methods.formState.isValid ||
    !methods.formState.dirtyFields.document ||
    loading ||
    updateProfileLoading

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <FlexBox flexDirection="column" style={{ width: 500 }}>
        <FlexBox flexDirection="column">
          <Typography fontSize={16} color={Colors.light.greys.GREY_600} mt={2}>
            {t('configurations.contracts.modals.new.subtitle')}
          </Typography>
          <Document
            margin="normal"
            fullWidth
            label={t('configurations.contracts.modals.cpfCnpj')}
            name="document"
          />
          <Input
            margin="normal"
            fullWidth
            disabled
            label={t('configurations.contracts.modals.name')}
            name="company"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    style={{
                      minWidth: 40,
                      minHeight: 40
                    }}
                  >
                    {(!!nameOrCompanyName || searchCompanyNameLoading) && (
                      <Icon
                        family="mui"
                        sx={{
                          color: nameOrCompanyName
                            ? Colors.light.checkLight
                            : Colors.light.text
                        }}
                        name={nameOrCompanyName ? 'Done' : 'Loop'}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </FlexBox>

        <FlexBox gap={2} flexDirection="column" mt={2}>
          <PlainButton
            type="submit"
            style={{
              backgroundColor: isDisabled
                ? undefined
                : Colors.light.interactDefault,
              width: '100%'
            }}
            disabled={isDisabled}
            loading={loading || updateProfileLoading}
            variant="contained"
          >
            {buttonText}
          </PlainButton>
        </FlexBox>

        {openEmailDialogInfo && (
          <DialogDefault
            opened={openEmailDialogInfo}
            disableCancel
            confirmText={t('profile.menu.profileEditPersonal.dialog.confirm')}
            title={t('profile.menu.profileEditPersonal.dialog.title')}
            description={t(
              'profile.menu.profileEditPersonal.dialog.description',
              [profileEmail ?? '']
            )}
            onConfirm={handleCloseModal}
          />
        )}
      </FlexBox>
    </Form>
  )
}

export default ModalContent
export { ModalContent }
