import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 *
 */
class FaqQuestionDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('order')
  @jsonProperty(Number)
  public order?: number

  @jsonName('categoryId')
  @jsonProperty(Number)
  public categoryId?: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active?: boolean

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate?: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedDate?: string

  @jsonName('title')
  @jsonProperty(String)
  public title!: string

  @jsonName('description')
  @jsonProperty(String)
  public description!: string
}

export default FaqQuestionDTO
