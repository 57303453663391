import { useCreateNps } from '@volvo-apps/shared/core/hooks/nps'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { SurveyRoutesEnum } from 'routes/cms/routesEnums'

import Colors from 'constants/Colors'

import { setUTCEndOfDay, setUTCStartOfDay } from 'common/utils/date'
import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { NpsForm } from '../Form/NpsForm'
import { NpsData } from '../Form/schema'
import { useNpsForm } from '../Form/useNpsForm'

/**
 * Nps Create
 *
 * @returns TSX
 */
function Create() {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const { methods } = useNpsForm({
    defaultValues: {
      title: '',
      beginDate: new Date(),
      endDate: new Date()
    }
  })

  const { mutate, isLoading } = useCreateNps({
    onSuccess: () => {
      toast.success(t('cms.surveys.nps.create.success'))
      navigate(SurveyRoutesEnum.SURVEY_NPS)
    },
    onError: handleErrorFeedback
  })

  const onSubmit = React.useCallback(
    (formData: NpsData) => {
      mutate({
        ...formData,
        beginDate: setUTCStartOfDay(formData.beginDate),
        endDate: setUTCEndOfDay(formData.endDate)
      })
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.surveys.nps.create.title')}
      </Typography>
      <NpsForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.surveys.nps.create.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
