import { getAllCmsFaqQuestion } from '@volvo-apps/shared/core/actions/FaqQuestion'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all cms faq question
 *
 * @returns faqQuestion, isLoading, refetch
 */
export const useGetCmsFaqQuestion = () => {
  const {
    data: faqQuestion,
    isLoading,
    refetch
  } = useQuery('cmsFaqQuestion', {
    queryFn: getAllCmsFaqQuestion,
    onError: handleErrorFeedback
  })

  return { faqQuestion, isLoading, refetch }
}
