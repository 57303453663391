import React from 'react'

import { Box } from '@mui/material'
import { useNavigate } from 'react-router'

import { List, ListItemProps } from 'components'
import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum } from 'routes/cms/routesEnums'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function Menu() {
  const navigate = useNavigate()
  const { t } = useLanguage()

  const menuItems: ListItemProps[] = [
    {
      primary: t('cms.faq.menu.categoryRegister'),
      onClick: () => navigate(RegistersRoutesEnum.FAQ_CATEGORIES_VIEW)
    },
    {
      primary: t('cms.faq.menu.questionRegister'),
      onClick: () => navigate(RegistersRoutesEnum.FAQ_QUESTIONS_VIEW)
    }
  ]

  return (
    <CmsChildrenWithHeader>
      <Box mt={2} width={(t) => t.breakpoints.values.md}>
        <List items={menuItems} noPadding />
      </Box>
    </CmsChildrenWithHeader>
  )
}

export default Menu
export { Menu }
