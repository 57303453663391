import { updateNotification } from '@volvo-apps/shared/core/actions/Notification'
import NotificationDTO from '@volvo-apps/shared/models/NotificationDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateNotificationParams = {
  onSuccess?: () => void
}

/**
 * Hook to update one notifications
 *
 * @param params params
 * @returns mutate, notification, isLoading
 */
export const useUpdateNotification = (
  params: useUpdateNotificationParams = {}
) => {
  const { onSuccess } = params
  const {
    mutate,
    isLoading,
    data: notification,
    mutateAsync
  } = useMutation('updateNotification', {
    mutationFn: ({ notification }: { notification: NotificationDTO }) =>
      updateNotification(notification),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, notification, isLoading, mutateAsync }
}
