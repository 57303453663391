import React from 'react'

import { Box } from '@mui/material'

import { Title } from 'components'
import { Carousel } from 'components/Carousel'
import { Loader } from 'components/Loader'

import { useGetBannersWithConfig } from 'hooks/useGetBannersWithConfig'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function News() {
  const { data, isLoading } = useGetBannersWithConfig()
  const { t } = useLanguage()

  const transitionTime = data?.transitionTime ?? 0

  const handleDragStart = (e: React.DragEvent) => e.preventDefault()

  const items = React.useMemo(() => {
    return data?.banners.map((banner) => {
      return (
        <img
          key={banner.id}
          width="800px"
          height="200px"
          style={{ objectFit: 'fill' }}
          src={banner.azureUrl}
          onDragStart={handleDragStart}
        />
      )
    })
  }, [data])

  return (
    <Box>
      <Title variant="h6" fontSize={20} sx={{ my: 3 }}>
        {t('dashboard.news')}
      </Title>
      {isLoading ? (
        <Loader size={16} />
      ) : (
        <Carousel
          items={items}
          disableButtonsControls
          autoPlay
          infinite
          slideIntervalInMs={transitionTime}
          textAlignCenter
        />
      )}
    </Box>
  )
}

export default News
export { News }
