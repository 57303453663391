import { useGetVerifiedDocuments } from '@volvo-apps/shared/core/hooks/user/useGetVerifiedDocuments'

import React from 'react'

import { conditionalFormat } from 'common/utils/documents'

import { Select } from '../Select'
import { SelectProps } from './types'

type VerifiedDocumentsSelectProps = SelectProps & {
  appendOptions?: { label: string; value: string | number }[]
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function VerifiedDocumentsSelect(props: VerifiedDocumentsSelectProps) {
  const {
    name,
    label,
    margin,
    fullWidth,
    sx,
    appendOptions = [],
    disabled
  } = props

  const { verifiedDocuments } = useGetVerifiedDocuments()

  const verifiedDocumentsOptions = React.useMemo(
    () =>
      verifiedDocuments?.map((verifiedDocument) => ({
        label: conditionalFormat(verifiedDocument.document),
        value: verifiedDocument.document
      })) ?? [],
    [verifiedDocuments]
  )

  const options = [...appendOptions, ...verifiedDocumentsOptions]

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={options ?? []}
      sx={sx}
      disabled={disabled}
    />
  )
}

export default VerifiedDocumentsSelect
export { VerifiedDocumentsSelect }
