import { blobToURL, download } from '@volvo-apps/web/src/common/utils/download'
import BaseYearDTO from '../../models/BaseYearDTO'
import IncomeReportDTO from '../../models/IncomeReportDTO'
import { IncomeReportService } from '../service/IncomeReport.service'

/**
 * @returns BaseYear[]
 */
export async function getYears() {
  const service = new IncomeReportService()
  const response = await service.getYears()

  return response.data.map((year) => new BaseYearDTO().fromJSON(year))
}

/**
 *
 * Download extract PDF
 *
 * @param incomeReportDTO IncomeReportDTO
 * @returns void
 */
export async function downloadExtractPdf(incomeReportDTO: IncomeReportDTO) {
  const service = new IncomeReportService()
  return service.getIncomeReportFile(incomeReportDTO).then(({ data }) => {
    download(blobToURL(data), incomeReportDTO.documentNumber + '.pdf')
  })
}
