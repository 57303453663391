import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrowDown.svg'
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrowLeft.svg'
import { ReactComponent as CalculatorIcon } from '../../assets/icons/calculator.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { ReactComponent as ChatCalloutsIcon } from '../../assets/icons/chatCallouts.svg'
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg'
import { ReactComponent as ConsortiumIcon } from '../../assets/icons/consortium.svg'
import { ReactComponent as CreditCardIcon } from '../../assets/icons/creditCard.svg'
import { ReactComponent as DocumentIcon } from '../../assets/icons/document.svg'
import { ReactComponent as DocumentsIcon } from '../../assets/icons/documents.svg'
import { ReactComponent as DocumentsGeneralIcon } from '../../assets/icons/documentsGeneral.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as ExcelentIcon } from '../../assets/icons/excelent.svg'
import { ReactComponent as ExclamationIcon } from '../../assets/icons/exclamation.svg'
import { ReactComponent as ExportIcon } from '../../assets/icons/export.svg'
import { ReactComponent as FaqIcon } from '../../assets/icons/faq-icon.svg'
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg'
import { ReactComponent as FinanceMoneyIcon } from '../../assets/icons/financeMoney.svg'
import { ReactComponent as GoodIcon } from '../../assets/icons/good.svg'
import { ReactComponent as HallIcon } from '../../assets/icons/hall.svg'
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg'
import { ReactComponent as ImportIcon } from '../../assets/icons/import.svg'
import { ReactComponent as KeyIcon } from '../../assets/icons/key.svg'
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg'
import { ReactComponent as MoneyIcon } from '../../assets/icons/money.svg'
import { ReactComponent as NotGoodIcon } from '../../assets/icons/notGood.svg'
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg'
import { ReactComponent as PixIcon } from '../../assets/icons/pix.svg'
import { ReactComponent as PositioningIcon } from '../../assets/icons/positioning.svg'
import { ReactComponent as PositioningFilledIcon } from '../../assets/icons/positioningFilled.svg'
import { ReactComponent as PSCreditIcon } from '../../assets/icons/psCredit.svg'
import { ReactComponent as QuestionMarkIcon } from '../../assets/icons/questionMark.svg'
import { ReactComponent as RegularIcon } from '../../assets/icons/regular.svg'
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove.svg'
import { ReactComponent as SadIcon } from '../../assets/icons/sad.svg'
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg'
import { ReactComponent as SymbolDollarIcon } from '../../assets/icons/symbolDollar.svg'
import { ReactComponent as TrashCanIcon } from '../../assets/icons/trashcan.svg'
import { ReactComponent as TruckIcon } from '../../assets/icons/truck.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import { ReactComponent as UserFilledIcon } from '../../assets/icons/userFilled.svg'
import { ReactComponent as WorldGlobeIcon } from '../../assets/icons/worldGlobe.svg'

export const svgIcons = {
  calculator: CalculatorIcon,
  calendar: CalendarIcon,
  chatCallouts: ChatCalloutsIcon,
  clock: ClockIcon,
  consortium: ConsortiumIcon,
  creditCard: CreditCardIcon,
  document: DocumentIcon,
  documents: DocumentsIcon,
  documentsGeneral: DocumentsGeneralIcon,
  edit: EditIcon,
  faq: FaqIcon,
  file: FileIcon,
  financeMoney: FinanceMoneyIcon,
  hall: HallIcon,
  home: HomeIcon,
  key: KeyIcon,
  lock: LockIcon,
  logout: LogoutIcon,
  money: MoneyIcon,
  phone: PhoneIcon,
  export: ExportIcon,
  pix: PixIcon,
  positioning: PositioningIcon,
  positioningFilled: PositioningFilledIcon,
  questionMark: QuestionMarkIcon,
  remove: RemoveIcon,
  symbolDollar: SymbolDollarIcon,
  trashcan: TrashCanIcon,
  truck: TruckIcon,
  user: UserIcon,
  userFilled: UserFilledIcon,
  excelent: ExcelentIcon,
  good: GoodIcon,
  notGood: NotGoodIcon,
  regular: RegularIcon,
  sad: SadIcon,
  import: ImportIcon,
  settings: SettingsIcon,
  arrowLeft: ArrowLeftIcon,
  exclamation: ExclamationIcon,
  arrowDown: ArrowDownIcon,
  worldGlobe: WorldGlobeIcon,
  psCredit: PSCreditIcon
}

/**
 *
 */
export type SvgIconsType = keyof typeof svgIcons
