import { makeResolver } from 'common/utils/form'
import { currencyStringToNumber } from 'common/utils/money'

export const documentSelectionSchema = makeResolver((Yup) =>
  Yup.object({
    documentType: Yup.string().required('validation.required.general'),
    documentNumber: Yup.string().required('validation.required.general')
  })
)

export const chooseFinancingFormSchema = makeResolver((Yup) =>
  Yup.object({
    financialProduct: Yup.string().required('validation.required.general'),
    productLine: Yup.object({
      id: Yup.number().required('validation.required.general'),
      name: Yup.string().required('validation.required.general')
    }).required('validation.required.general'),
    vehicleId: Yup.number().required('validation.required.general'),
    productValue: Yup.string()
      .test(
        'min_amount',
        'validation.amount.minProductValue',
        (value?: string) => currencyStringToNumber(value ?? '0') >= 1000
      )
      .test(
        'max_amount',
        'validation.amount.maxProductValue',
        (value?: string) => currencyStringToNumber(value ?? '0') < 10000000
      )
      .required('validation.required.general')
  })
)
