import { useGetAvailableNps } from '@volvo-apps/shared/core/hooks/nps'

import React from 'react'

import { RateModal } from './RateModal'
import { ThanksModal } from './ThanksModal'

export enum ModalEnum {
  Empty = 'Empty',
  Rate = 'Rate',
  Thanks = 'Thanks'
}

/**
 *
 */
export type NpsModalsGenericProps = {
  /**
   *
   */
  changeModal: (modal: ModalEnum) => void
  /**
   *
   */
  handleClose?: () => void
}

type NpsModalsProps = {
  origin: string
  handleClose?: NpsModalsGenericProps['handleClose']
}

/**
 * @param props NpsModalsProps
 * @returns TSX
 */
const NpsModals = (props: NpsModalsProps) => {
  const { origin, handleClose } = props
  const [modalToShow, setModalToShow] = React.useState(ModalEnum.Rate)

  const { availableNps, isFetched } = useGetAvailableNps({ cacheTime: 0 })

  const modal = React.useMemo(() => {
    if (!availableNps) {
      if (isFetched) handleClose?.()
      return <></>
    }
    switch (modalToShow) {
      case ModalEnum.Rate:
        return (
          <RateModal
            changeModal={setModalToShow}
            origin={origin}
            handleClose={handleClose}
            availableNps={availableNps}
          />
        )
      case ModalEnum.Thanks:
        return (
          <ThanksModal changeModal={setModalToShow} handleClose={handleClose} />
        )
      default:
        return <></>
    }
  }, [availableNps, handleClose, isFetched, modalToShow, origin])

  return modal
}

export default NpsModals
export { NpsModals }
