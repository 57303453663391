import { changePasswordVerificationCode } from '@volvo-apps/shared/core/actions/User'
import LoginDTO from '@volvo-apps/shared/models/LoginDTO'
import UserDTO from '@volvo-apps/shared/models/UserDTO'

import React, { useState } from 'react'

import { Box, IconButton, InputAdornment, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Form, Input } from 'components'
import { FormPasswordValidation } from 'components/FormPasswordValidation'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { newPasswordSchema } from '../helpers'
import { FormInfo, PasswordRetrievalStepEnum } from '../types'
import HeaderBackComponent from './HeaderBackComponent'

type NewPasswordForm = Pick<LoginDTO, 'password'> & {
  confirmPassword: string
}

type NewPasswordProps = {
  goToStep: (step: PasswordRetrievalStepEnum) => void
  formInfo: FormInfo
}

/**
 *
 * @param props Component props
 * @param props.goToStep sends user to a specific step
 * @param props.formInfo form info that was filled in the previous steps
 * @returns TSX
 */
function NewPassword(props: NewPasswordProps) {
  const { goToStep, formInfo } = props

  const { t } = useLanguage()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const methods = useForm<NewPasswordForm>({
    mode: 'onChange',
    resolver: newPasswordSchema,
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  })

  const { isLoading, mutate } = useMutation(changePasswordVerificationCode, {
    onSuccess: () =>
      goToStep(PasswordRetrievalStepEnum.PASSWORD_RETRIEVAL_SUCCESS),
    onError: handleErrorFeedback
  })

  const handleSubmit = React.useCallback(
    async ({ password }: Pick<UserDTO, 'password'>) => {
      const { verificationCode, email } = formInfo
      if (!verificationCode || !email || !password) return

      mutate({ verificationCode, email, password })
    },
    [formInfo, mutate]
  )

  return (
    <HeaderBackComponent>
      <Form
        methods={methods}
        onSubmit={handleSubmit}
        formProps={{ width: '100%' }}
      >
        <Typography component="h1" variant="h4" align="center">
          {t('passwordRetrieval.alterPassword')}
        </Typography>
        <Typography component="p" variant="body1" align="center" mt="1em">
          {t('passwordRetrieval.description')}
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Input
            margin="normal"
            fullWidth
            name="password"
            label={t('general.insertNewPassword')}
            type={passwordVisible ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    onClick={() => setPasswordVisible((s) => !s)}
                  >
                    {passwordVisible ? (
                      <Icon family="mui" name="Visibility" />
                    ) : (
                      <Icon family="mui" name="VisibilityOff" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Input
            margin="normal"
            fullWidth
            name="confirmPassword"
            label={t('general.confirmNewPassword')}
            type={confirmPasswordVisible ? 'text' : 'password'}
            id="password"
            sx={{ mb: 2 }}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    onClick={() => setConfirmPasswordVisible((s) => !s)}
                  >
                    {confirmPasswordVisible ? (
                      <Icon family="mui" name="Visibility" />
                    ) : (
                      <Icon family="mui" name="VisibilityOff" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <FormPasswordValidation />
          <PlainButton
            type="submit"
            fullWidth
            variant="contained"
            disabled={!methods.formState.isValid}
            loading={isLoading}
            sx={{ mt: 3, mb: 2 }}
          >
            {t('passwordRetrieval.alterPassword1')}
          </PlainButton>
        </Box>
      </Form>
    </HeaderBackComponent>
  )
}

export default NewPassword
export { NewPassword }
