import React from 'react'

import * as Icons from '@mui/icons-material'
import {
  Icon as MuiIcon,
  IconProps as MuiIconProps,
  SvgIcon,
  SvgIconProps
} from '@mui/material'

import { SvgIconsType, svgIcons } from './SvgIcons'

type IconTypes =
  | { family: 'mui'; name: keyof typeof Icons; compProps?: MuiIconProps }
  | { family: 'svg'; name: SvgIconsType; compProps?: SvgIconProps }

/**
 *
 */
export type IconProps = {
  /**
   *
   */
  sx?: MuiIconProps['sx']
  /**
   *
   */
  style?: MuiIconProps['style']
  /**
   *
   */
  fontSize?: MuiIconProps['fontSize']
  changeViewBox?: boolean
  className?: string
} & IconTypes

/**
 *
 * @param props Icon Props
 * @returns TSX
 */
function Icon(props: IconProps) {
  const { family, name, sx, style, fontSize, changeViewBox, compProps } = props

  const Component = family === 'svg' ? svgIcons[name] : Icons[name]

  const viewBox =
    style?.width && style?.height && changeViewBox
      ? `${style.width} ${style.height}`
      : undefined

  return family === 'svg' ? (
    <SvgIcon
      sx={sx}
      style={style}
      fontSize={fontSize}
      viewBox={viewBox}
      {...compProps}
    >
      <Component />
    </SvgIcon>
  ) : (
    <MuiIcon
      sx={sx}
      component={Component}
      style={style}
      fontSize={fontSize}
      {...compProps}
    />
  )
}

export default Icon
export { Icon }
