import { handleRentalFilter } from '@volvo-apps/shared/common/utils/filterRental'
import { useGetRentalContracts } from '@volvo-apps/shared/core/hooks/sales/useGetRentalContracts'
import RentalContractDTO from '@volvo-apps/shared/models/RentalContractDTO'

import React, { useMemo } from 'react'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import GenericList from 'components/List/GenericList'
import { Loader } from 'components/Loader'
import { RentalContractCard } from 'components/RentalContractCard'

import { FinancialRoutesEnum } from 'routes/financial.routes'

import useSearchBar from 'hooks/useSearchBar'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function Rental() {
  const { t } = useLanguage()
  const { data = [], isLoading } = useGetRentalContracts({
    retry: 1,
    cacheTime: 0
  })

  const { searchBar, searchTerm } = useSearchBar({
    style: {
      margin: '0 0 16px 0'
    }
  })

  const items = useMemo(() => {
    return data?.filter((item) => handleRentalFilter(item, searchTerm)) ?? []
  }, [data, searchTerm])

  if (isLoading) {
    return <Loader />
  }

  const makeChildrenComponent = (rentalContract: RentalContractDTO) => (
    <RentalContractCard
      rentalContract={rentalContract}
      showMoreDetails
      removeFinanceType
      detailsLabel={{
        to: FinancialRoutesEnum.RENTAL_DETAIL.replace(
          ':id',
          rentalContract.documento
        ),
        state: { rentalContract }
      }}
      boxProps={{
        border: `1px solid ${Colors.light.greys.GREY_200}`,
        boxShadow: `1px 1px 5px ${Colors.light.shadow}`,
        width: '100%',
        p: 2
      }}
    />
  )

  return (
    <ContentContainer
      title={t('financial.rental.rentalContracts').toUpperCase()}
    >
      {data.length > 0 && searchBar}
      <GenericList
        listItemStyle={{ paddingRight: 0, paddingLeft: 0 }}
        hideButton
        items={items}
        makeChildrenComponent={makeChildrenComponent}
        disableNoInfoDisclaimer={!!searchTerm}
      />
    </ContentContainer>
  )
}

export default Rental
export { Rental }
