import React from 'react'

import { Box, Typography } from '@mui/material'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

/**
 * @returns JSX
 */
function Step3() {
  const { t } = useLanguage()

  return (
    <>
      <Box mt={3}>
        <Typography
          fontSize={14}
          color={Colors.light.interactActive}
          fontWeight={500}
        >
          3 - {t('register.step3.title')}
        </Typography>
      </Box>

      <Box my={2}>
        <Typography>{t('register.step3.verifyAccount')}</Typography>
      </Box>
    </>
  )
}

export default Step3
export { Step3 }
