import React from 'react'

import { useGetFaqCategories } from 'hooks/faqCategories/useGetFaqCategories'

import { Select } from '../Select'
import { SelectProps } from './types'

type FaqCategoriesSelectProps = SelectProps

/**
 *
 * @param props Props
 * @returns TSX
 */
function FaqCategoriesSelect(props: FaqCategoriesSelectProps) {
  const { name, label, margin, fullWidth, sx } = props
  const { faqCategories } = useGetFaqCategories()

  const faqCategoriesOptions = React.useMemo(
    () =>
      faqCategories?.map((faqCategories) => ({
        label: faqCategories.label,
        value: faqCategories.id
      })),
    [faqCategories]
  )

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={faqCategoriesOptions ?? []}
      sx={sx}
    />
  )
}

export default FaqCategoriesSelect
export { FaqCategoriesSelect }
