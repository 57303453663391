import { GridColDef } from '@mui/x-data-grid'

/**
 *
 * @returns TSX
 */
export const columns: GridColDef[] = [
  {
    field: 'domain',
    headerName: 'cms.functionalDomains.registeredDomain',
    width: 250,
    sortable: false
  }
]
