import PermissionDTO from '../../models/PermissionDTO'
import RoleDTO from '../../models/RoleDTO'
import { UserRolesService } from '../service/UserRoles.service'

/**
 * @returns RoleDTO[]
 */
export async function getUserRoles() {
  const service = new UserRolesService()
  const response = await service.getAll()

  return response.data.map((role) => new RoleDTO().fromJSON(role))
}

/**
 *
 * @param data RoleDTO
 */
export async function updateUserRole(data: RoleDTO) {
  const service = new UserRolesService()

  const response = await service.updateUserRole(data)

  return new RoleDTO().fromJSON(response.data)
}

/**
 * @param id The id of the role
 * @returns Delete the user role
 */
export async function deleteUserRole(id: number) {
  const service = new UserRolesService()
  const response = await service.deleteUserRole(id)

  return response
}

/**
 * @param userRole the user role
 */
export async function createUserRole(userRole: RoleDTO) {
  const service = new UserRolesService()
  const response = await service.createUserRole(userRole)

  return new RoleDTO().fromJSON(response.data)
}

/**
 *
 */
export async function getAllPermissions() {
  const service = new UserRolesService()
  const response = await service.getAllPermissions()

  return response.data.map((permission) => new RoleDTO().fromJSON(permission))
}

/**
 * @param id the user role id
 */
export async function getUserRoleById(id: RoleDTO['id']) {
  const service = new UserRolesService()
  const response = await service.getUserRoleById(id)

  return new RoleDTO().fromJSON({
    ...response.data,
    permissions: response.data.permissions.map((permission) =>
      new PermissionDTO().fromJSON(permission)
    )
  })
}
