import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * UserDocumentDTO
 */
class UserDocumentDTO extends Serializable {
  @jsonName('userId')
  @jsonProperty(Number)
  public userId!: number

  @jsonName('document')
  @jsonProperty(String)
  public document!: string

  @jsonName('verified')
  @jsonProperty(Boolean)
  public verified!: boolean

  @jsonName('verificationCode')
  @jsonProperty(String)
  public verificationCode!: string

  @jsonName('documentType')
  @jsonProperty(String)
  public documentType!: string

  @jsonName('verificationCodeCreationUtc')
  @jsonProperty(Date)
  public verificationCodeCreationUtc!: Date
}

export default UserDocumentDTO
