import { deleteFinancialProduct } from '@volvo-apps/shared/core/actions/FinancialProduct'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useDeleteFinancialProductParams = {
  onSuccess?: () => void
}

/**
 * Hook to delete one financial product
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteFinancialProduct = (
  params: useDeleteFinancialProductParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data } = useMutation('deleteFinancialProduct', {
    mutationFn: ({ id }: { id: number }) => deleteFinancialProduct(id),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, data, isLoading }
}
