import React from 'react'

import Colors from 'constants/Colors'

import { Slider } from '../Slider'

type TransitionTimeSliderProps = {
  name: string
}

/**
 *
 * @param props Props
 * @returns TSX
 */
const TransitionTimeSlider = (
  props: TransitionTimeSliderProps
): JSX.Element => {
  const { name } = props

  const sliderLabel = React.useCallback((value: number) => {
    return `${value * 1000} ms`
  }, [])

  return (
    <Slider
      name={name}
      min={0}
      max={1}
      sx={{ mt: 3, color: Colors.light.greys.GREY_600 }}
      valueLabelDisplay="on"
      step={0.1}
      valueLabelFormat={sliderLabel}
      marks={[
        {
          value: 0,
          label: 0
        },
        {
          value: 1,
          label: 1000
        }
      ]}
    />
  )
}

export default TransitionTimeSlider
export { TransitionTimeSlider }
