import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'

/**
 * PolicyItemDTO
 */
class PolicyItemDTO extends Serializable {
  @jsonProperty(String)
  apolice!: string

  @jsonProperty(String)
  situacao!: string

  @jsonProperty(String)
  inicioVigencia!: string

  @jsonProperty(String)
  terminoVigencia!: string

  @jsonProperty(String)
  dataEmissao!: string

  @jsonProperty(String)
  dataEntrada!: string

  @jsonProperty(String)
  premioTotal!: string

  @jsonProperty(String)
  parcelas!: string

  @jsonProperty(String)
  seguradora!: string

  @jsonProperty(String)
  nomefabricante!: string

  @jsonProperty(String)
  descrModelo!: string

  @jsonProperty(String)
  anoModelo!: string

  @jsonProperty(String)
  placa!: string

  @jsonProperty(String)
  chassi!: string
}

export default PolicyItemDTO
