import { useMutation } from 'react-query'
import { HookQueryParams } from '../../../common/utils/types'
import ConsortiumResourcesImportDTO from '../../../models/ConsortiumClosedResourcesImportDTO'
import { importFile } from '../../actions/ConsortiumClosedResources'

type useImportConsortiumResourcesParams =
  HookQueryParams<ConsortiumResourcesImportDTO>

/**
 * Hook to import consortium resources
 *
 * @param params params
 * @returns mutate, data, isLoading and mutateAsync
 */
export const useImportConsortiumClosedResources = (
  params: useImportConsortiumResourcesParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data, mutateAsync } = useMutation(
    'importConsortiumClosedResources',
    {
      mutationFn: ({ formData }: { formData: FormData }) =>
        importFile(formData),
      onSuccess
    }
  )

  return { mutate, data, isLoading, mutateAsync }
}
