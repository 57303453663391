import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'
import ConsortiumClosedResourcesImportDTO from '../../models/ConsortiumClosedResourcesImportDTO'
import ConsortiumClosedResourcesHistoryDTO from '../../models/ConsortiumClosedResourcesHistoryDTO'
import ConsortiumClosedResourcesDocumentDTO from '../../models/ConsortiumClosedResourcesDocumentDTO'

/**
 *
 */
export class ConsortiumClosedResourcesService extends GenericService<boolean> {
  readonly URI: string = 'gateway/ConsortiumClosedResources'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get consortium resources
   */
  public async getImportXMLHistory() {
    return super.get<AxiosResponse<ConsortiumClosedResourcesHistoryDTO[]>>(
      'GetImportXMLHistory'
    )
  }

  /**
   * Import File
   *
   * @param formData FormData
   */
  public async importFile(formData: FormData) {
    return super.post<AxiosResponse<ConsortiumClosedResourcesImportDTO>>(
      'ImportFileAsync',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
  }

  /**
   * Get consortium closed resources by document
   *
   * @param document document
   */
  public async getByDocument(document: string) {
    return super.get<AxiosResponse<ConsortiumClosedResourcesDocumentDTO>>(
      `GetByDocument?document=${document}`
    )
  }

  /**
   * Delete consortium closed resource
   *
   * @param id The id of the consortium closed resource
   */
  public async deleteConsortiumClosedResource(id: number) {
    return super.delete('Inactivate', { id })
  }
}
