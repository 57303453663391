import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'

import { getRentalContracts } from '../../actions/Sales'
import RentalContractDTO from '../../../models/RentalContractDTO'

type useGetRentalContractsParams = HookQueryParams<RentalContractDTO[]>

/**
 * @param params params
 * @returns data, isLoading, refetch
 */
export const useGetRentalContracts = (
  params: useGetRentalContractsParams = {}
) => {
  const { data, isLoading, refetch } = useQuery('getRentalContracts', {
    queryFn: () => getRentalContracts(),
    ...params
  })

  return { data, isLoading, refetch }
}
