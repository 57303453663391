import LinkedDocumentsAndNamesDTO from '../../models/LinkedDocumentsAndNamesDTO'
import LinkedDocumentsDTO from '../../models/LinkedDocumentsDTO'
import UserDocumentDTO from '../../models/UserDocumentDTO'
import { LinkedDocumentsService } from '../service/LinkedDocuments.service'

/**
 * @returns LinkedDocumentsDTO[]
 */
export async function getAllLinkedDocuments() {
  const service = new LinkedDocumentsService()
  const response = await service.getAll()
  return response.data.map((linkedDocuments) =>
    new LinkedDocumentsDTO().fromJSON(linkedDocuments)
  )
}

/**
 *
 * @param email The email
 */
export async function searchNameByEmail(email: string) {
  const service = new LinkedDocumentsService()
  const response = await service.searchNameByEmail(email)
  return response.data
}

/**
 *
 * @param document The document
 */
export async function searchCompanyNameByDocument(document: string) {
  const service = new LinkedDocumentsService()
  const response = await service.searchCompanyNameByDocument(document)
  return response.data
}

/**
 *
 * @param email The email
 * @param document The document
 */
export async function linkDocument(email: string, document: string) {
  const service = new LinkedDocumentsService()
  const response = await service.linkDocument(email, document)

  return new UserDocumentDTO().fromJSON(response.data)
}

/**
 *
 * @param id The id of the linked document
 */
export async function unlinkDocument(id: number) {
  const service = new LinkedDocumentsService()
  const response = await service.unlink(id)

  return response
}

/**
 * @returns LinkedDocumentsDTO[]
 */
export async function getAllLinkedDocumentsAndNamesToFilter() {
  const service = new LinkedDocumentsService()
  const response = await service.getAll()

  return response.data.reduce<LinkedDocumentsAndNamesDTO>((acc, curr) => {
    acc.linkedDocuments.push(new LinkedDocumentsDTO().fromJSON(curr))
    acc.names.push(curr.userName)
    return acc
  }, new LinkedDocumentsAndNamesDTO())
}
