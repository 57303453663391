import _deburr from 'lodash/deburr'
import DocumentDTO from '../../models/DocumentDTO'
import { handleContractFilter } from './filterContract'
import { getOnlyNumbers } from './string'

const documentProps: (keyof DocumentDTO)[] = [
  'document',
  'contracts',
  'verified'
]

const match = (value: string, input: string, key?: string) => {
  const inputValue = key === 'document' ? getOnlyNumbers(input) : input
  return _deburr(value).match(new RegExp(inputValue, 'ig'))
}

type MapBooleanProps = {
  [key: string]: {
    true: string
    false: string
  }
}

/**
 *
 * @param document DocumentDTO
 * @param input Input string
 * @param mapBooleanProps Map of boolean props
 * @returns Boolean
 */
export const handleDocumentFilter = (
  document: DocumentDTO,
  input: string,
  mapBooleanProps?: MapBooleanProps
) => {
  let isValid = false

  for (const key of documentProps) {
    const value = document[key]

    if (typeof value === 'boolean' && mapBooleanProps && mapBooleanProps[key]) {
      const booleanValue = value ? 'true' : 'false'

      if (mapBooleanProps[key]) {
        if (match(mapBooleanProps[key][booleanValue], input, key)) {
          isValid = true
          break
        }
      }

      continue
    }

    if (key === 'contracts') {
      for (const contract of document.contracts ?? []) {
        if (handleContractFilter(contract, input)) {
          isValid = true
          break
        }
      }
    } else {
      if (match(value as string, input, key)) {
        isValid = true
        break
      }
    }
  }

  return isValid
}
