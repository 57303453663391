import React from 'react'

import { Box, Typography } from '@mui/material'

import { MenuItem } from 'routes/types'

import Colors from 'constants/Colors'

import { ReactComponent as Logotype } from 'assets/images/Logotype.svg'

import Menu from './Menu'

type HeaderProps = {
  items: MenuItem[]
}

/**
 * @param props Header Props
 * @returns TSX
 */
function Header(props: HeaderProps) {
  const { items } = props
  return (
    <Box
      flexDirection="row"
      display="flex"
      alignItems="center"
      py="2em"
      px="4em"
      justifyContent="space-between"
      borderBottom={'1px solid ' + Colors.light.greys.GREY_200}
    >
      <Box flexDirection="row" display="flex" alignItems="center">
        <Box width={150}>
          <Logotype fill="black" />
        </Box>
        <Box>
          <Typography fontSize={16} color={Colors.light.greys.GREY_700}>
            Volvo Financial Services
          </Typography>
          <Typography fontSize={14} color={Colors.light.greys.GREY_600}>
            Brasil
          </Typography>
        </Box>
      </Box>
      {items.length > 0 && <Menu items={items} />}
    </Box>
  )
}

export default Header
export { Header }
