import React from 'react'

import { Divider, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Form } from 'components'

import { SimulationRoutesEnum } from 'routes/simulation.routes'

import { useUserSimulationValidated } from 'hooks/useUserSimulationValidated'

import Colors from 'constants/Colors'

import { currencyStringToNumber } from 'common/utils/money'
import { useLanguage } from 'context/Language'

import { chooseFinancingFormSchema } from '../helpers'
import NextButton from '../Partials/NextButton/NextButton'
import StepsHeader from '../Partials/StepsHeader/StepsHeader'
import { ChooseFinancingForm } from '../types'
import { ChooseType, ChooseVehicle } from './components'

/**
 * @returns TSX
 */
function ChooseFinancing() {
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { updateSimulation } = useUserSimulationValidated()

  const methods = useForm<ChooseFinancingForm>({
    defaultValues: {
      productValue: '1.000.000,00',
      financialProduct: ''
    },
    mode: 'onChange',
    resolver: chooseFinancingFormSchema
  })

  const onSubmit = (data: ChooseFinancingForm) => {
    const { financialProduct, productLine, productValue, vehicleId } = data
    updateSimulation({
      financialProduct: Number(financialProduct),
      productLine: Number(productLine.id),
      productValue: currencyStringToNumber(productValue),
      vehicleId
    })

    navigate(SimulationRoutesEnum.FINANCING_AMOUNTS)
  }

  const handleBack = () => {
    navigate(SimulationRoutesEnum.DOCUMENT_SELECTION)
  }

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      formProps={{
        sx: { display: 'flex', flexDirection: 'column', width: '45%' }
      }}
    >
      <StepsHeader
        handleBack={handleBack}
        step={1}
        title={t('simulation.chooseFinancing.title')}
        description={t('simulation.chooseFinancing.description')}
      />
      <ChooseVehicle />
      <Divider
        sx={{
          my: 4,
          color: Colors.light.greys.GREY_300
        }}
      />

      <ChooseType />
      <Typography
        variant="subtitle2"
        color={Colors.light.greys.GREY_600}
        mt={3}
        mb={4}
      >
        {t('simulation.chooseFinancing.disclaimer')}
      </Typography>
      <NextButton testId="choose-financing-next-button" />
    </Form>
  )
}

export default ChooseFinancing
export { ChooseFinancing }
