import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * PartsAndServicesCustomerDTO
 */
class PartsAndServicesCustomerDTO extends Serializable {
  @jsonName('document')
  @jsonProperty(String)
  public document!: string

  @jsonName('openToBuyAmount')
  @jsonProperty(Number)
  public openToBuyAmount!: number
}

export default PartsAndServicesCustomerDTO
