import { getSimulationHistory } from '@volvo-apps/shared/core/actions/UserSimulation'
import UserSimulationDTO from '@volvo-apps/shared/models/UserSimulationDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { List, ListItemProps } from 'components'
import ContentContainer from 'components/ContentContainer/ContentContainer'
import { Loader } from 'components/Loader'
import { NoInfo } from 'components/NoInfo'

import { SimulationRoutesEnum } from 'routes/simulation.routes'

import Colors from 'constants/Colors'

import { formatDate, getWorkingDays } from 'common/utils/date'
import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'
import { useUserSimulation } from 'context/Simulation'

import { getSmallestInstallmentValueFromSimulation } from '../calculator'

/**
 * @returns TSX
 */
function SimulationHistory() {
  const { data, isLoading } = useQuery('userSimulation', getSimulationHistory, {
    onError: handleErrorFeedback
  })

  const { updateSimulation } = useUserSimulation()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const navigateToSimulation = (item: UserSimulationDTO) => {
    const featuredInstallmentAmount =
      getSmallestInstallmentValueFromSimulation(item)

    const newSimulation = new UserSimulationDTO().fromJSON({ ...item })

    updateSimulation(newSimulation)
    navigate(
      SimulationRoutesEnum.SIMULATION_HISTORY_SUMMARY.replace(
        ':id',
        String(newSimulation.id)
      ),
      {
        state: {
          featuredInstallmentAmount,
          isValid: getWorkingDays(new Date(item.createdDate), new Date()) <= 2
        }
      }
    )
  }

  const items: ListItemProps[] =
    data?.map((item) => ({
      primary: (
        <Typography variant="body2" color={Colors.light.greys.GREY_600}>
          {formatDate(item.createdDate)}
        </Typography>
      ),
      secondary: (
        <>
          <Typography variant="body1">
            {t('simulationHistory.id', [item.id])}
          </Typography>
          <Typography variant="body2" color={Colors.light.greys.GREY_600}>
            {t('simulationHistory.type', [
              item.vehicleName,
              item.financialProductName
            ])}
          </Typography>
        </>
      ),
      onClick: () => {
        navigateToSimulation(item)
      }
    })) ?? []

  if (isLoading) {
    return (
      <Loader boxProps={{ justifyContent: 'center', alignItems: 'center' }} />
    )
  }

  return (
    <ContentContainer
      title={t('simulation.menu.simulationHistory').toUpperCase()}
    >
      {data && data.length > 0 ? <List items={items} noPadding /> : <NoInfo />}
    </ContentContainer>
  )
}

export default SimulationHistory
export { SimulationHistory }
