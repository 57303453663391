import { useQuery } from 'react-query'
import { getVerifiedDocuments } from '../../actions/User'
import { HookQueryParams } from '../../../common/utils/types'

type useGetVerifiedDocuments = HookQueryParams<BaseYearDTO>

/**
 * Hook to get verified documents
 *
 * @param params params
 * @returns verified documents, isLoading, refetch
 */
export const useGetVerifiedDocuments = (
  params: useGetVerifiedDocuments = {}
) => {
  const { suspense } = params

  const {
    data: verifiedDocuments,
    isLoading,
    refetch
  } = useQuery('getVerifiedDocuments', {
    queryFn: () => getVerifiedDocuments(),
    suspense
  })

  return { verifiedDocuments, isLoading, refetch }
}
