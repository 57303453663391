import { styled } from '@mui/material'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

export const RowFlexBox = styled(FlexBox)(() => ({
  display: 'flex',
  borderBottom: '1px',
  borderColor: Colors.light.greys.GREY_300,
  borderBottomStyle: 'solid',
  padding: '24px 0',
  justifyContent: 'space-between',
  height: '24px',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: Colors.light.greys.GREY_200
  }
}))
