import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import 'reflect-metadata'
import FaqQuestionDTO from './FaqQuestionDTO'

/**
 *
 */
class FaqCategoryDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('order')
  @jsonProperty(Number)
  public order?: number

  @jsonName('createdDate')
  @jsonProperty(String)
  public createdDate?: string

  @jsonName('modifiedDate')
  @jsonProperty(String)
  public modifiedDate?: string

  @jsonName('active')
  @jsonProperty(Boolean, undefined, null)
  public active?: boolean

  @jsonName('label')
  @jsonProperty(String)
  public label!: string

  @jsonName('description')
  @jsonProperty(String)
  public description!: string

  @jsonName('icon')
  @jsonProperty(String)
  public icon?: string

  @jsonName('faqQuestions')
  @jsonProperty([FaqQuestionDTO])
  public faqQuestions?: FaqQuestionDTO[]
}

export default FaqCategoryDTO
