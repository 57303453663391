import { NavBarProps } from 'components'

import { CmsTabsRoutesEnum } from 'routes/cms/cms.routes'

type CrudBreadCrumbsParams = {
  mainMenu?: string
  menu?: string
  list?: string
  create?: string
  update?: string
  translationKey: string
  mainMenuTranslationKey?: string
}

/**
 *
 * @param params CrudBreadCrumbsParams
 * @returns Crud BreadCrumbs
 */
export const getCrudBreadCrumbs = (
  params: CrudBreadCrumbsParams
): Record<string, NavBarProps['routes']> => {
  const {
    mainMenu,
    menu,
    list,
    create,
    update,
    translationKey,
    mainMenuTranslationKey
  } = params

  const home: NavBarProps['routes'][0] = {
    to: CmsTabsRoutesEnum.HOME,
    label: 'cms.breadCrumbs.home'
  }

  const mainMenuBreadcrumb: NavBarProps['routes'][0] = {
    to: mainMenu ?? '',
    label: mainMenu ? `cms.breadCrumbs.${mainMenuTranslationKey}.menu` : ''
  }

  const menuBreadcrumb: NavBarProps['routes'][0] = {
    to: menu ?? '',
    label: menu ? `cms.breadCrumbs.${translationKey}.menu` : ''
  }

  const listBreadcrumb: NavBarProps['routes'][0] = {
    to: list ?? '',
    label: list ? `cms.breadCrumbs.${translationKey}.list` : ''
  }

  const createBreadcrumb: NavBarProps['routes'][0] = {
    to: create ?? '',
    label: create ? `cms.breadCrumbs.${translationKey}.create` : ''
  }

  const updateBreadcrumb: NavBarProps['routes'][0] = {
    to: update ?? '',
    label: update ? `cms.breadCrumbs.${translationKey}.update` : ''
  }
  const resolveMainMenuBreadcrumb = mainMenu ? [mainMenuBreadcrumb] : []

  const commonBreadcrumbs = [
    ...(home ? [home] : []),
    ...resolveMainMenuBreadcrumb,
    ...(menu ? [menuBreadcrumb] : []),
    ...(list ? [listBreadcrumb] : [])
  ]

  return {
    ...(mainMenu && { [`${mainMenu}`]: [home, mainMenuBreadcrumb] }),
    ...(menu && {
      [`${menu}`]: [home, ...resolveMainMenuBreadcrumb, menuBreadcrumb]
    }),
    ...(list && { [`${list}`]: [...commonBreadcrumbs] }),
    ...(create && { [`${create}`]: [...commonBreadcrumbs, createBreadcrumb] }),
    ...(update && { [`${update}`]: [...commonBreadcrumbs, updateBreadcrumb] })
  }
}
