import React from 'react'

import { FlexBox } from 'components/FlexBox'

import { iconTranslator } from 'pages/Faq/helpers'

import { Select } from '../Select'
import { SelectProps } from './types'

type FaqIconSelectProps = Omit<SelectProps, 'labelAsValue'>

/**
 *
 * @param props Props
 * @returns TSX
 */
function FaqIconSelect(props: FaqIconSelectProps) {
  const { name, label, margin, fullWidth, sx } = props

  const iconsOptions = React.useMemo(
    () =>
      Object.entries(iconTranslator).map(([key, icon]) => ({
        label: <FlexBox sx={{ width: 24, height: 24 }}>{icon}</FlexBox>,
        value: key
      })),
    []
  )

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={iconsOptions ?? []}
      sx={sx}
    />
  )
}

export default FaqIconSelect
export { FaqIconSelect }
