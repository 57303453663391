import { useQuery } from 'react-query'
import { searchCompanyNameByDocument } from '../../actions/LinkedDocuments'
import { HookQueryParams } from '../../../common/utils/types'

type useSearchCompanyNameByDocumentParams = HookQueryParams<string> & {
  document: string
}

/**
 *
 * @param params params to use
 * @returns nameOrCompanyName, isLoading
 */
export const useSearchCompanyNameByDocument = (
  params: useSearchCompanyNameByDocumentParams
) => {
  const { document, ...rest } = params
  const { data: nameOrCompanyName, isLoading } = useQuery(
    ['searchCompanyNameByDocument', document],
    {
      queryFn: () => searchCompanyNameByDocument(document),
      ...rest
    }
  )

  return { nameOrCompanyName, isLoading }
}
