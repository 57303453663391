import { MenuItem } from '@mui/material'
import styled from 'styled-components'

import Colors from 'constants/Colors'

export const MenuItemStyled = styled(MenuItem)`
  &:hover {
    svg,
    span {
      color: ${Colors.light.interactActive};
    }
  }
`
