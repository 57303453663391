import PolicyDTO from '@volvo-apps/shared/models/PolicyDTO'

import React from 'react'

import { BoxProps, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { Link } from 'components'
import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'
type PoliciesCardProps = {
  policy: PolicyDTO
  boxProps?: BoxProps
}
/**
 *
 * @param props PoliciesCardProps
 * @returns TSX
 */
function PoliciesCard(props: PoliciesCardProps) {
  const { boxProps, policy } = props

  const { t } = useLanguage()
  const policyData = policy.getFirstPolicy()

  const isValid = dayjs(
    policyData.terminoVigencia,
    'DD/MM/YYYY HH:mm:ss'
  ).isAfter(dayjs())

  const makeField = React.useCallback(
    (label: string, value: string | number) => {
      return (
        <FlexBox width="22%">
          <Typography
            color={Colors.light.greys.GREY_500}
            fontSize={12}
            mr={0.5}
          >
            {label}
          </Typography>
          <Typography color={Colors.light.greys.GREY_600} fontSize={12}>
            {value}
          </Typography>
        </FlexBox>
      )
    },
    []
  )

  return (
    <FlexBox
      justifyContent="space-between"
      alignItems="center"
      {...boxProps}
      sx={{
        border: `1px solid ${Colors.light.greys.GREY_200}`,
        boxShadow: `1px 1px 5px ${Colors.light.shadow}`,
        width: '100%',
        p: 2
      }}
    >
      <FlexBox flexDirection="column" gap={2} width={'100%'}>
        <FlexBox justifyContent="space-between">
          <FlexBox alignItems="center">
            <Icon
              family="mui"
              name={isValid ? 'Done' : 'Close'}
              sx={{
                color: isValid ? Colors.light.checkLight : Colors.light.error,
                mr: 0.5
              }}
              fontSize="small"
            />
            <Typography
              color={Colors.light.interactDefault}
              fontSize={16}
              fontWeight="bold"
            >
              {policy.policyNumber}
            </Typography>
          </FlexBox>
          <FlexBox>
            <Link
              to="/insurancepolicies/policies/detail"
              state={{ policy }}
              color={Colors.light.interactActive}
              fontSize={14}
              sx={{ pointerEvents: 'all' }}
              fontWeight="bold"
            >
              {t('general.seeDetails').toUpperCase()}
            </Link>
          </FlexBox>
        </FlexBox>
        <FlexBox flexDirection="column" gap={1}>
          <FlexBox gap={4}>
            {makeField(
              t('insurancePolicies.policies.status'),
              policyData.situacao.charAt(0).toUpperCase() +
                policyData.situacao.slice(1)
            )}
            {makeField(
              t('insurancePolicies.policies.itemQuantity'),
              policy.itemCount
            )}
            {makeField(
              t('insurancePolicies.policies.insurer'),
              policyData.seguradora
            )}
            {makeField(
              t('insurancePolicies.policies.endOfDate'),
              dayjs(policyData.terminoVigencia, 'DD/MM/YYYY HH:mm:ss').format(
                'DD/MM/YYYY'
              )
            )}
          </FlexBox>
          <FlexBox gap={4}>
            {makeField(
              t('insurancePolicies.policies.label'),
              policyData.nomefabricante
            )}
            {makeField(
              t('insurancePolicies.policies.model'),
              policyData.descrModelo
            )}
            {makeField(
              t('insurancePolicies.policies.year'),
              policyData.anoModelo
            )}
            {makeField(
              t('insurancePolicies.policies.licensePlate'),
              policyData.placa
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export { PoliciesCardProps }
export default PoliciesCard
export { PoliciesCard }
