import { getAllLeads } from '@volvo-apps/shared/core/actions/Leads'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all leads
 *
 * @returns Leads, isLoading
 */
export const useGetLeads = () => {
  const {
    data: leads,
    isLoading,
    mutate
  } = useMutation('Leads', {
    mutationFn: ({
      startDate,
      endDate
    }: {
      startDate: string
      endDate: string
    }) => getAllLeads(startDate, endDate),
    onError: handleErrorFeedback
  })

  return { leads, isLoading, mutate }
}
