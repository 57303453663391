import React from 'react'

import { useGetIntervalEnums } from 'hooks/notifications/useGetIntervalEnums'

import { Select } from '../Select'
import { SelectProps } from './types'

type IntervalSelectProps = SelectProps

/**
 *
 * @param props Props
 * @returns TSX
 */
function IntervalSelect(props: IntervalSelectProps) {
  const { name, label, margin, fullWidth, sx, labelAsValue } = props

  const { intervals } = useGetIntervalEnums()

  const intervalsOptions = React.useMemo(
    () =>
      Object.entries(intervals ?? {}).map(([label, value]) => ({
        label,
        value: labelAsValue ? label : value
      })),
    [intervals, labelAsValue]
  )

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={intervalsOptions ?? []}
      sx={sx}
    />
  )
}

export default IntervalSelect
export { IntervalSelect }
