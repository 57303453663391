import { getUsersDataReport } from '@volvo-apps/shared/core/actions/User'
import UserReportDTO from '@volvo-apps/shared/models/UserReportDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetUsersDataReportParams = {
  onSuccess?: () => void
}

/**
 * Hook to get user data reports
 *
 * @param params params
 * @returns mutate, term, isLoading and mutateAsync
 */
export const useGetUsersDataReport = (
  params: useGetUsersDataReportParams = {}
) => {
  const { onSuccess } = params
  const {
    mutate,
    isLoading,
    data: term,
    mutateAsync
  } = useMutation('getUsersDataReport', {
    mutationFn: ({ userReport }: { userReport: UserReportDTO }) =>
      getUsersDataReport(userReport),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, term, isLoading, mutateAsync }
}
