import { NpsOriginEnum } from '@volvo-apps/shared/common/utils/enums'
import ContractDTO from '@volvo-apps/shared/models/ContractDTO'
import { SalesInvoiceDTO } from '@volvo-apps/shared/models/SalesInvoiceDTO'

import React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { Icon } from 'components/Icon'
import { RentalTicketCard } from 'components/RentalTicketCard'
import { TicketCard } from 'components/TicketCard'

import Colors from 'constants/Colors'

import Details from './Details'
import RentalDetails from './RentalDetails'

type TicketsAccordionProps = {
  contract: ContractDTO | SalesInvoiceDTO
  npsOrigin: NpsOriginEnum
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<Icon family="mui" name="ExpandMore" />}
    {...props}
  />
))(() => ({
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0px 4px 0px'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    right: '16px',
    top: '12px'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    position: 'absolute',
    right: '16px',
    top: '20px'
  }
}))

/**
 *
 * @param props TicketsAccordionProps
 * @returns TSX
 */
function TicketsAccordion(props: TicketsAccordionProps) {
  const { contract, npsOrigin } = props
  const [expanded, setExpanded] = React.useState(false)

  const handleExpanded = React.useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  const isRental = (
    contract: ContractDTO | SalesInvoiceDTO
  ): contract is SalesInvoiceDTO => {
    return !!(contract as SalesInvoiceDTO).Tipo
  }

  return isRental(contract) ? (
    <Accordion
      expanded={expanded}
      onChange={handleExpanded}
      sx={{
        border: `1px solid ${Colors.light.greys.GREY_200}`,
        boxShadow: `1px 1px 5px ${Colors.light.shadow}`
      }}
    >
      <AccordionSummary sx={{ mb: 0 }}>
        <RentalTicketCard
          detailOpened={expanded}
          key={contract.Contrato}
          invoice={contract}
          boxProps={{ width: '100%' }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <RentalDetails invoice={contract} npsOrigin={npsOrigin} />
      </AccordionDetails>
    </Accordion>
  ) : (
    <Accordion
      expanded={expanded}
      onChange={handleExpanded}
      sx={{
        border: `1px solid ${Colors.light.greys.GREY_200}`,
        boxShadow: `1px 1px 5px ${Colors.light.shadow}`
      }}
    >
      <AccordionSummary sx={{ mb: 0 }}>
        <TicketCard
          detailOpened={expanded}
          key={contract.numeroContrato}
          contract={contract}
          boxProps={{ width: '100%' }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Details contract={contract} npsOrigin={npsOrigin} />
      </AccordionDetails>
    </Accordion>
  )
}

export default TicketsAccordion
export { TicketsAccordion }
