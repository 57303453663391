import { useGetLeadNotifications } from 'hooks/leads/useGetLeadNotifications'
import { useUpdateLeadNotification } from 'hooks/leads/useUpdateLeadNotification'

/**
 * Hook to use CRUD operations on lead notifications
 *
 * @returns leadNotifications, updateLeadNotification, updateAsyncLeadNotification, refetch, isLoading, getLoading, updateLoading
 */
export const useCrudLeadNotification = () => {
  const {
    leadNotifications,
    refetch,
    isLoading: getLoading
  } = useGetLeadNotifications()

  const {
    mutate: updateLeadNotification,
    isLoading: updateLoading,
    mutateAsync: updateAsyncLeadNotification
  } = useUpdateLeadNotification({
    onSuccess: refetch
  })

  const isLoading = updateLoading || getLoading

  return {
    leadNotifications,
    updateLeadNotification,
    updateAsyncLeadNotification,
    refetch,
    isLoading,
    getLoading,
    updateLoading
  }
}
