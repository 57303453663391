import PartsAndServicesDTO from '@volvo-apps/shared/models/PartsAndServicesDTO'
import { PartsAndServicesService } from '@volvo-apps/shared/core/service/PartsAndServices.service'
import PartsAndServicesUserDTO from '../../models/PartsAndServicesUserDTO'
import PartsAndServicesImportDTO from '../../models/PartsAndServicesImportDTO'
import PartsAndServicesCustomerDTO from '../../models/PartsAndServicesCustomerDTO'

/**
 * @returns PartsAndServicesDTO[]
 */
export async function getAllPartsAndServices() {
  const service = new PartsAndServicesService()
  const response = await service.getAll()

  return response.data.map((partsAndServices) =>
    new PartsAndServicesDTO().fromJSON(partsAndServices)
  )
}

/**
 * @param id The id of the parts and services
 * @returns parts and services
 */
export async function getPartsAndServices(id: number) {
  const service = new PartsAndServicesService()
  const response = await service.getPartsAndServices(id)
  return new PartsAndServicesDTO().fromJSON(response.data)
}

/**
 * @param id The id of the parts and services
 * @returns Delete parts and services
 */
export async function deletePartsAndServices(id: number) {
  const service = new PartsAndServicesService()
  const response = await service.deletePartsAndServices(id)

  return response
}

/**
 * @param updatedPartsAndServices The updated parts and services
 */
export async function updatePartsAndServices(
  updatedPartsAndServices: PartsAndServicesDTO
) {
  const service = new PartsAndServicesService()
  const response = await service.updatePartsAndServices(updatedPartsAndServices)

  return response
}

/**
 *
 * @param sequence The sequence id of the parts and services
 * @param fileName The title of the parts and services
 * @param processingDate The description of the parts and services
 * @param processingRecords The description of the parts and services
 */
export async function createPartsAndServices(
  sequence: number,
  fileName: string,
  processingDate: Date,
  processingRecords: number
) {
  const service = new PartsAndServicesService()
  const response = await service.createPartsAndServices(
    sequence,
    fileName,
    processingDate,
    processingRecords
  )

  return response
}

/**
 * @returns GetByUser
 */
export async function getByUser() {
  const service = new PartsAndServicesService()
  const response = await service.getByUser()
  return response.data.map((partsAndServicesUser) =>
    new PartsAndServicesUserDTO().fromJSON(partsAndServicesUser)
  )
}

/**
 * @param document The document of the customer
 * @returns GetPartsAndServicesCustomer
 */
export async function getPartsAndServicesCustomer(document: string) {
  const service = new PartsAndServicesService()
  const response = await service.getPartsAndServicesCustomer(document)
  return new PartsAndServicesCustomerDTO().fromJSON(response.data)
}

/**
 *
 * @param formData FormData
 */
export async function importFile(formData: FormData) {
  const service = new PartsAndServicesService()
  const response = await service.importFile(formData)
  return new PartsAndServicesImportDTO().fromJSON(response.data)
}

/**
 * @returns boolean
 */
export function hasPartsAndServices() {
  const service = new PartsAndServicesService()
  return service.hasPartsAndServices().then(({ data }) => data)
}
