import { AxiosResponse } from 'axios'
import ProductLineDTO from '@volvo-apps/shared/models/ProductLineDTO'
import { GenericService } from './GenericService'

/**
 * ProductLine
 */
export class ProductLineService extends GenericService<ProductLineDTO> {
  readonly URI: string = 'gateway/ProductLine'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all product lines
   */
  public async getAll() {
    return super.get<{ data: ProductLineDTO[] }>('GetAll')
  }

  /**
   *
   * @param id The id of the product line
   */
  public async getProductLine(id: number) {
    return super.get<AxiosResponse<ProductLineDTO>>('Get', { id })
  }

  /**
   * Delete product line
   *
   * @param id The id of the product line
   */
  public async deleteProductLine(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Update product line
   *
   * @param updatedProductLine The updated product line
   */
  public async updateProductLine(updatedProductLine: ProductLineDTO) {
    return super.put<AxiosResponse<ProductLineDTO>>('Update', {
      ...updatedProductLine
    })
  }

  /**
   * Create product line
   *
   * @param name The name of the product line
   * @param order The order of the product line
   * @param minimumValue The initial value of the product line
   */
  public async createProductLine(
    name: string,
    order: number,
    minimumValue: number
  ) {
    return super.post<AxiosResponse<ProductLineDTO>>('Add', {
      name,
      order,
      minimumValue
    })
  }

  /**
   * Get all cms product lines
   */
  public async getAllCms() {
    return super.get<AxiosResponse<ProductLineDTO[]>>('GetAllCms')
  }
}
