import { getAllCategories } from '@volvo-apps/shared/core/actions/Category'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all faq category
 *
 * @returns faqCategories, isLoading, refetch
 */
export const useGetFaqCategories = () => {
  const {
    data: faqCategories,
    isLoading,
    refetch
  } = useQuery('faqCategories', {
    queryFn: getAllCategories,
    onError: handleErrorFeedback
  })

  return { faqCategories, isLoading, refetch }
}
