/* eslint-disable no-console */
import AddressDTO from '@volvo-apps/shared/models/AddressDTO'
import CidadeDTO from '@volvo-apps/shared/models/CidadeDTO'
import EstadoDTO from '@volvo-apps/shared/models/EstadoDTO'
import UserDTO from '@volvo-apps/shared/models/UserDTO'
import { blobToURL, download } from '@volvo-apps/web/src/common/utils/download'
import DocumentDTO from '../../models/DocumentDTO'
import UserCmsAccessDTO from '../../models/UserCmsAccessDTO'
import UserReportDTO from '../../models/UserReportDTO'
import { UserService } from '../service/User.service'
import ContractDTO from '../../models/ContractDTO'
import UserWithDocumentDTO from '../../models/UserWithDocumentDTO'
import VerifiedDocumentDTO from '../../models/VerifiedDocumentDTO'

/**
 * Verifies a documentation
 *
 * @param doc The user's doc
 * @param _code The code
 * @param confirm check if should verify or deny document
 * @returns If verified
 */
export async function verifyDocument(
  doc: string,
  _code: string,
  confirm: boolean
): Promise<boolean> {
  try {
    const service = new UserService()
    const { code } = await service.getClientVerification(doc, _code, confirm)
    return code === 200
  } catch (error) {
    console.error('doc', error)
  }
  return false
}

/**
 * Verifies a user
 *
 * @param id The user's id
 * @param _code The email code
 * @returns If verified
 */
export async function verifyEmail(id: number, _code: string): Promise<boolean> {
  try {
    const service = new UserService()
    const { code } = await service.getEmailVerification(id, _code)
    return code === 200
  } catch (error) {
    console.error('email', error)
  }
  return false
}
/**
 * Verifies a user
 *
 * @param email The user's id
 * @param emailVerificationCode The email code
 * @returns If verified
 */
export async function verifyEmailCode(
  email: string,
  emailVerificationCode: string
): Promise<boolean> {
  try {
    const service = new UserService()
    const { code } = await service.changePasswordByEmailVerificationCode(
      email,
      emailVerificationCode
    )
    return code === 200
  } catch (error) {
    console.error('email', error)
  }
  return false
}
/**
 *
 * @param data Add Params
 */
export async function addUser(data: UserDTO) {
  const service = new UserService()
  return service.add(data)
}

/**
 *
 */
export async function getProfile(): Promise<UserDTO> {
  const service = new UserService()

  const { data } = (await service.getProfile()) as {
    data: {
      entity: UserDTO
      documents?: DocumentDTO[]
    }
  }

  const { entity, documents } = data

  return new UserDTO().fromJSON({
    ...entity,
    documents
  } as object)
}

/**
 * Finds the address
 *
 * @param cep The CEP
 * @returns The address
 */
export async function getCepInfo(cep: string): Promise<AddressDTO | null> {
  const service = new UserService()

  const response = await service.getCepInfo(cep)
  if (response.status === 200 && (response.data as AddressDTO)?.cidade) {
    return new AddressDTO().fromJSON(response.data as object)
  }
  return null
}

/**
 *
 * @param params Func params
 * @param params.email Email to send verification code
 */
export async function generateVerificationCode(
  params: Required<Pick<UserDTO, 'email'>>
) {
  const service = new UserService()

  return await service.generateVerificationCode(params.email)
}

/**
 *
 */
export async function getEstados() {
  const service = new UserService()

  const { data } = await service.getEstados<EstadoDTO[]>()

  return data?.map((e) => ({
    ...e,
    label: e.nome,
    value: e.sigla
  }))
}

/**
 *
 * @param params Func params
 * @param params.siglaEstado Sigla estado
 */
export async function getMunicipiosBySiglaEstado(params: {
  siglaEstado: EstadoDTO['sigla']
}) {
  const service = new UserService()

  const { data } = await service.getMunicipiosBySiglaEstado<CidadeDTO[]>(params)

  return data?.map((c) => ({
    ...c,
    label: c.nome,
    value: c.nome
  }))
}

/**
 *
 * @param data userDTO
 */
export async function updateProfile(data: UserDTO) {
  const service = new UserService()

  const response = await service.updateProfile(data)

  if (response.status === 200) {
    return response.data
  }

  throw response
}

/**
 *
 * @param params Func params
 * @param params.email User email
 * @param params.password User new password
 * @param params.verificationCode Verification code that was sent to user email
 */
export async function changePasswordVerificationCode(
  params: Pick<UserDTO, 'email' | 'password'> & {
    /**
     *
     */
    verificationCode: string
  }
) {
  const service = new UserService()

  return service.changePasswordVerificationCode(params)
}

/**
 *
 * @param params Func params
 * @param params.email User email
 * @param params.verificationCode Verification code that was sent to user email
 */
export async function validateVerificationCode(
  params: Required<Pick<UserDTO, 'email'> & { verificationCode: string }>
) {
  const service = new UserService()

  return await service.validateVerificationCode(
    params.email,
    params.verificationCode
  )
}

/**
 *
 */
export async function deleteCurrentUser() {
  const service = new UserService()

  const response = await service.deleteCurrentUser()
  if (response.status === 200) {
    return true
  }

  return response
}

/**
 *
 * @param params Func params
 * @param params.oldPassword User new password
 * @param params.password User new password
 */
export async function changePasswordNewPassword(params: {
  oldPassword: string
  password: string
}) {
  const { oldPassword, password } = params
  const service = new UserService()
  const response = await service.changePassword(oldPassword, password)
  return response.data
}

/**
 *
 */
export async function checkFirstAccess() {
  const service = new UserService()
  return service.getFirstAccess().then(({ data }) => data as boolean)
}

/**
 *
 */
export async function updateFirstAccess() {
  const service = new UserService()

  return service.updateFirstAccess()
}

/**
 *
 */
export async function getUsersListWithDocumentCms() {
  const service = new UserService()

  const response = await service.getUsersListWithDocumentCms()

  return response.data.map((user) => new UserWithDocumentDTO().fromJSON(user))
}

/**
 *
 */
export async function getUsersListToResetPasswordCms() {
  const service = new UserService()

  const response = await service.getUsersListToResetPasswordCms()

  return response.data.map((user) => new UserWithDocumentDTO().fromJSON(user))
}

/**
 *
 * @param id id
 */
export async function getUsersListWithDocumentCmsById(id: number) {
  const service = new UserService()

  const response = await service.getUsersListWithDocumentCmsById(id)

  return new UserWithDocumentDTO().fromJSON(response.data)
}

/**
 *
 * @param user user
 */
export async function updateUserFunctionCMS(user: UserWithDocumentDTO) {
  const service = new UserService()

  const response = await service.updateUserFunctionCMS(user)

  return new UserWithDocumentDTO().fromJSON(response.data)
}

/**
 *
 * @param user UserCmsAccessDTO
 */
export async function changeUseCMS(user: UserCmsAccessDTO) {
  const service = new UserService()

  const response = await service.changeUseCMS(user)

  return new UserCmsAccessDTO().fromJSON(response.data)
}

/**
 *
 * @param document CPF or CNPJ
 */
export async function resetUserPassword(document: string) {
  const service = new UserService()

  const response = await service.resetPassword(document)

  return new UserCmsAccessDTO().fromJSON(response.data)
}

/**
 *
 * @param document CPF or CNPJ
 */
export async function resentUserRegistrationMail(document: string) {
  const service = new UserService()

  const response = await service.resendRegistrationMail(document)

  return new UserCmsAccessDTO().fromJSON(response.data)
}

/**
 *
 */
export async function userIsCMS() {
  const service = new UserService()

  const response = await service.userIsCMS()

  return response.data
}

/**
 *
 * Download User Reports
 *
 * @param userReportDTO The dto to generate the report
 * @returns void
 */
export async function getUsersDataReport(userReportDTO: UserReportDTO) {
  const service = new UserService()
  return service.getUsersDataReport(userReportDTO).then(({ data }) => {
    download(
      blobToURL(data),
      'relatório-de-usuários-' +
        userReportDTO.beginDateStr +
        '-' +
        userReportDTO.endDateStr +
        '.csv'
    )
  })
}

/**
 *
 */
export async function getDocumentsWithContracts() {
  const service = new UserService()

  // const page = 1

  // for (let i = 0; i < 30; i++) {
  //   const {  } = await service.getActiveDocumentsWithContracts(page + i)

  //   if (response.data.length === 0) {
  //     break
  //   }

  //   contracts.push(...response.data)
  // }

  // return new UserDTO().fromJSON({
  //   ...entity,
  //   documents
  // })

  const { data } = await service.getActiveDocumentsWithContracts()

  const { entity, documents } = data

  return new UserDTO().fromJSON({
    ...entity,
    documents
  })
}

/**
 *
 * @param document The document.
 */
export async function getActiveContractsByDocuments(document: string) {
  const service = new UserService()

  const response = await service.getActiveContractsByDocuments(document, 1)

  const pageOneContracts: ContractDTO[] = response.data.contracts
    ? [...response.data.contracts]
    : []

  const sortContracts: ContractDTO[] = pageOneContracts

  const numberOfPages = response.data.totalContracts / 30

  const total = numberOfPages > 1 ? Math.round(numberOfPages) : 1

  for (let i = 2; i <= total; i++) {
    const response = await service.getActiveContractsByDocuments(document, i)

    if (response.data?.contracts) {
      sortContracts.push(...response.data.contracts)
    }
  }

  return sortContracts
}

/**
 * Get users data report by doc
 *
 * @param document string
 */
export async function getUserDataReportByDoc(document: string) {
  const service = new UserService()

  const response = await service.getUserDataReportByDoc(document)

  return response.data
}

/**
 * Check if document has contracts
 *
 * @param document string
 */
export async function checkIfDocumentHasContracts(document: string) {
  const service = new UserService()

  const response = await service.checkIfDocumentHasContracts(document)

  return response.data
}

/**
 * Check document and update profile
 *
 * @param params params
 * @param params.newDocument newDocument
 * @param params.newUser newUser
 */
export async function checkDocumentAndUpdateProfile(params: {
  newDocument: string
  newUser: UserDTO
}) {
  const { newDocument, newUser } = params
  const service = new UserService()

  await service.checkIfDocumentHasContracts(newDocument)
  const response = await service.updateProfile(newUser)

  return response.data
}

/**
 * @returns boolean
 */
export function hasAutbankContract() {
  const service = new UserService()
  return service.hasAutbankContract().then(({ data }) => data)
}

/**
 * @returns Verified Documents[]
 */
export async function getVerifiedDocuments() {
  const service = new UserService()
  const response = await service.getVerifiedDocuments()

  return response.data.map((item) => new VerifiedDocumentDTO().fromJSON(item))
}
