import BannerConfigDTO from '@volvo-apps/shared/models/BannerConfigDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudList } from 'components/CrudList'
import { PlainButton } from 'components/PlainButton'

import { RegistersRoutesEnum } from 'routes/cms/routesEnums'

import { useCrudBanner } from 'hooks/cms/useCrudBanner'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type BannerConfigJSON = ReturnType<BannerConfigDTO['toJSON']>

/**
 * Banner List
 *
 * @returns TSX
 */
function List() {
  const { t } = useLanguage()

  const { bannersConfig, getLoading, updateBanner, refetch, isFetching } =
    useCrudBanner({
      onSuccessUpdate: (updateResponse) => {
        toast.success(
          updateResponse.active
            ? t('cms.banners.toggle.enabled')
            : t('cms.banners.toggle.disabled')
        )
        refetch()
      }
    })

  const navigate = useNavigate()

  const items = React.useMemo(() => {
    return (
      bannersConfig?.map((item) => ({
        ...item.toJSON(),
        primary: item.banners.length > 0 ? item.banners[0].title : '',
        id: item.id
      })) ?? []
    )
  }, [bannersConfig])

  const handleEdit = React.useCallback(
    (bannerConfig: BannerConfigJSON) => {
      const path = RegistersRoutesEnum.BANNERS_UPDATE.replace(
        ':id',
        bannerConfig.id as string
      )

      navigate(path, {
        state: {
          bannerConfig: new BannerConfigDTO().fromJSON(bannerConfig)
        }
      })
    },
    [navigate]
  )

  const handletoggle = React.useCallback(
    (bannerConfig: BannerConfigJSON, checked: boolean) => {
      if (
        !checked &&
        bannersConfig?.filter((item) => item.active).length === 1
      ) {
        toast.error(t('cms.banners.toggle.error'))

        return { hasError: true }
      }

      const newBannerConfig = new BannerConfigDTO().fromJSON({
        ...bannerConfig,
        banners: [], // don't need banners to update active property and also improves performance
        active: checked
      })

      updateBanner(newBannerConfig)

      return { hasError: false }
    },
    [bannersConfig, updateBanner, t]
  )

  const handleCreate = React.useCallback(() => {
    navigate(RegistersRoutesEnum.BANNERS_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.banners.list.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.banners.list.title')}
      </Typography>

      <CrudList
        items={items}
        title={t('cms.banners.list.subtitle')}
        toggleKey="active"
        handleEdit={handleEdit}
        handleToggle={handletoggle}
        getLoading={getLoading && !bannersConfig}
        disableToggle={isFetching}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
