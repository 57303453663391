import {
  getImageByBannerConfigId,
  getUnique
} from '@volvo-apps/shared/core/actions/Banner'
import BannerConfigDTO from '@volvo-apps/shared/models/BannerConfigDTO'
import BannerDTO from '@volvo-apps/shared/models/BannerDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetBannerConfigAndBannersParams = {
  onSuccess?: (response: {
    bannerConfig: BannerConfigDTO
    banners: BannerDTO[]
  }) => void
  id?: number
}

/**
 * Hook to get banner with images
 *
 * @param params params
 * @returns bannerConfigAndBanner, isLoading, refetch
 */
export const useGetBannerConfigAndBanners = (
  params: useGetBannerConfigAndBannersParams
) => {
  const { id, onSuccess } = params

  const getBannerConfigAndBanners = async (bannerConfigId: number) => {
    const [bannerConfig, banners] = await Promise.all([
      getUnique(bannerConfigId),
      getImageByBannerConfigId(bannerConfigId)
    ])
    return { bannerConfig, banners }
  }

  const {
    data: bannerConfigAndBanner,
    isLoading,
    refetch
  } = useQuery('getBannerConfigAndBanners', {
    enabled: !!id,
    queryFn: () => getBannerConfigAndBanners(id || 0),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { bannerConfigAndBanner, isLoading, refetch }
}
