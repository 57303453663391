import SimulationParameterDTO from '@volvo-apps/shared/models/SimulationParameterDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { ParametersRoutesEnum as SimulationParametersRoutesEnum } from 'routes/cms/routesEnums'

import { useGetSimulationParameter } from 'hooks/simulationParameters/useGetSimulationParameter'
import { useUpdateSimulationParameter } from 'hooks/simulationParameters/useUpdateSimulationParameter'

import Colors from 'constants/Colors'

import { toCurrency } from 'common/utils/money'
import { decimalStrValueToNumber } from 'common/utils/number'
import { useLanguage } from 'context/Language'

import { SimulationParameterForm } from '../Form/SimulationParameterForm'
import { useSimulationParameterForm } from '../Form/useSimulationParameterForm'
import { filterParametersDetail } from '../helpers'
import { OnSubmitSimulationParameter } from '../types'

const taxesDefault = {
  tacValue: '0.00',
  bndesCondition: '',
  bndesSequence: '',
  ipcaValue: '0.00',
  selicValue: '0.00',
  moneyLenderInsurance: false,
  isSelicInterestRate: false,
  monthlyInterestRate: '0.00',
  program: '',
  subProgram: '',
  volvoRate: '0.00'
}

/**
 * Simulation Params Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { methods } = useSimulationParameterForm({
    defaultValues: {
      taxes: taxesDefault
    }
  })

  const { simulationParameter, isLoading: getLoading } =
    useGetSimulationParameter({
      id: Number(id),
      onSuccess: (simulationParameters) => {
        methods.reset({
          ...simulationParameters,
          startDate: new Date(simulationParameters.startDate),
          endDate: new Date(simulationParameters.endDate),
          taxes: {
            tacValue: toCurrency(simulationParameters.tacValue).withoutSymbol,
            ipcaValue: toCurrency(simulationParameters.ipcaValue).withoutSymbol,
            selicValue: toCurrency(simulationParameters.selicValue)
              .withoutSymbol,
            monthlyInterestRate: toCurrency(
              simulationParameters.monthlyInterestRate
            ).withoutSymbol,
            volvoRate: toCurrency(simulationParameters.volvoRate).withoutSymbol,
            bndesCondition: simulationParameters.bndesCondition,
            bndesSequence: simulationParameters.bndesSequence,
            moneyLenderInsurance: simulationParameters.moneyLenderInsurance,
            isSelicInterestRate: simulationParameters.isSelicInterestRate,
            program: simulationParameters.program,
            subProgram: simulationParameters.subProgram
          },
          ...filterParametersDetail(simulationParameters.parametersDetailDto)
        })
      }
    })

  const { mutate, isLoading } = useUpdateSimulationParameter({
    onSuccess: () => {
      toast.success(t('cms.simulationParameters.update.success'))
      navigate(SimulationParametersRoutesEnum.SIMULATION_PARAMS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: OnSubmitSimulationParameter) => {
      const { taxes, ...formRest } = formData

      const newSimulationParameter = new SimulationParameterDTO().fromJSON({
        ...simulationParameter,
        ...formRest,
        ...taxes,
        moneyLenderInsurance: formData.moneyLenderInsurance === 'true',
        isSelicInterestRate: formData.isSelicInterestRate === 'true',
        tacValue: decimalStrValueToNumber(taxes.tacValue),
        ipcaValue: decimalStrValueToNumber(taxes.ipcaValue),
        selicValue: decimalStrValueToNumber(taxes.selicValue),
        monthlyInterestRate: decimalStrValueToNumber(taxes.monthlyInterestRate),
        volvoRate: decimalStrValueToNumber(taxes.volvoRate),
        parametersDetailDto: [
          { ...formData.installments, isInstallmentParameters: true },
          { ...formData.gracePeriod, isInstallmentParameters: false }
        ],
        createdDate: undefined
      })

      mutate({ simulationParameter: newSimulationParameter })
    },
    [mutate, simulationParameter]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.simulationParameters.update.title')}
      </Typography>
      <SimulationParameterForm
        isLoading={isLoading || getLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.simulationParameters.update.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
