import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getHasInsuranceContract } from '../../actions/Insurance'

type useGetHasInsuranceContractParams = HookQueryParams<boolean>

/**
 * Hook to get if the user has insurance contract
 *
 * @param params params
 * @returns hasInsurance, isLoading, refetch
 */
export const useGetHasInsuranceContract = (
  params: useGetHasInsuranceContractParams = {}
) => {
  const {
    data: hasInsurance,
    isLoading,
    refetch
  } = useQuery('getHasInsuranceContract', {
    queryFn: getHasInsuranceContract,
    ...params
  })

  return { hasInsurance, isLoading, refetch }
}
