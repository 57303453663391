import React from 'react'

import {
  FormHelperText,
  SliderProps as SliderPropsMUI,
  Slider as SliderMUI
} from '@mui/material'
import { Controller } from 'react-hook-form'

type SliderProps = SliderPropsMUI & {
  name: string
}

/**
 *
 * @param props Props
 * @returns TSX
 */
const Slider = (props: SliderProps): JSX.Element => {
  const { name, ...sliderProps } = props

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const { error } = fieldState
        const { onChange, ...fieldProps } = field

        const mergedProps = {
          ...sliderProps,
          ...fieldProps
        }

        return (
          <>
            <SliderMUI
              {...mergedProps}
              onChange={(_, value) => onChange(value)}
            />
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          </>
        )
      }}
    />
  )
}

export default Slider
export { Slider }
