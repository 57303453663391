import { addUser } from '@volvo-apps/shared/core/actions/User'
import UserDTO from '@volvo-apps/shared/models/UserDTO'

import React, { useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { ButtonLink, Form, LinearProgress } from 'components'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { registerSchema, steps } from './helpers'
import { FormValuesStep1, FormValuesStep2 } from './Steps/types'

type FormValues = FormValuesStep1 & FormValuesStep2

/**
 * @returns JSX
 */
function Register() {
  const [activeStep, setActiveStep] = useState(0)
  const { t } = useLanguage()
  const navigate = useNavigate()
  const methods = useForm<FormValues>({
    reValidateMode: 'onChange',
    mode: 'onTouched',
    resolver: registerSchema,
    defaultValues: {
      name: '',
      documents: [{ value: '' }],
      email: '',
      password: '',
      password_confirm: '',
      termsAndConditions: false
    }
  })

  const { component: Component } = steps[activeStep]

  const { mutate, isLoading: isSaving } = useMutation(addUser, {
    onSuccess: () => {
      setActiveStep(2)
    },
    onError: handleErrorFeedback
  })

  const termsAndConditions = methods.watch('termsAndConditions')

  const onSubmit = (data: FormValues) => {
    mutate(new UserDTO().fromJSONWithSignUpDocuments(data))
  }

  const handlePrev = () => {
    const newStep = activeStep - 1

    if (newStep >= 0) {
      setActiveStep(newStep)
    } else {
      navigate('/')
    }
  }
  const handleNext = () => {
    methods
      .trigger(steps[activeStep].fields as unknown as keyof FormValues)
      .then((isValidStep) => {
        isValidStep && setActiveStep((i) => i + 1)
      })
  }

  const handleSubmit = () => {
    methods.handleSubmit(onSubmit)()
  }

  const isValidStep = (step: number) => {
    return !steps[step].fields.some((key) => key in methods.formState.errors)
  }

  const isTouchedStep = (step: number) => {
    return steps[step].fields.some(
      (key) => key in methods.formState.touchedFields
    )
  }
  const stepLength = steps.length
  const lastStep = stepLength - 2
  const hasNext = activeStep < stepLength - 1
  const currentStepId = activeStep + 1
  const validNextStep = lastStep === activeStep ? termsAndConditions : true
  const isNextDisabled =
    !isValidStep(activeStep) || !isTouchedStep(activeStep) || !validNextStep

  return (
    <Box alignSelf="stretch">
      <Form onSubmit={onSubmit} methods={methods}>
        <Box
          mb={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>
            {t('register.stepIndicator', [currentStepId, stepLength])}
          </Typography>
          <Button
            variant="text"
            startIcon={<Icon family="mui" name="KeyboardBackspace" />}
            sx={{
              color: Colors.light.text
            }}
            onClick={handlePrev}
          >
            {t('general.back')}
          </Button>
        </Box>
        <LinearProgress current={activeStep} max={stepLength - 1} />
        <Box mx={3}>
          <Box>
            <Component />
          </Box>
          {hasNext ? (
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              {activeStep === lastStep ? (
                <PlainButton
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isNextDisabled}
                  loading={isSaving}
                  style={{ width: '146px' }}
                >
                  {t('register.save')}
                </PlainButton>
              ) : hasNext ? (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={isNextDisabled}
                >
                  {t('general.next')}
                </Button>
              ) : null}
            </Box>
          ) : (
            <ButtonLink to="/login">{t('general.makeLogin')}</ButtonLink>
          )}
        </Box>
      </Form>
    </Box>
  )
}

export default Register
export { Register }
