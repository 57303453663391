import { getAllCmsFinancialProducts } from '@volvo-apps/shared/core/actions/FinancialProduct'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all cms financial products
 *
 * @returns financialProducts, isLoading, refetch
 */
export const useGetCmsFinancialProducts = () => {
  const {
    data: financialProducts,
    isLoading,
    refetch
  } = useQuery('cmsFinancialProducts', {
    queryFn: getAllCmsFinancialProducts,
    onError: handleErrorFeedback
  })

  return { financialProducts, isLoading, refetch }
}
