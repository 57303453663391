/**
 *
 * @param obj Object
 * @returns Number of keys
 */
export const objectLength = (obj: object) => Object.keys(obj).length

/**
 *
 * @param str String to json
 * @returns Object
 */
export const safeParseJson = (str?: string) => {
  try {
    return str ? JSON.parse(str) : {}
  } catch {
    return {}
  }
}
