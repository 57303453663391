import { getAllCmsTerms } from '@volvo-apps/shared/core/actions/Term'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all cms terms
 *
 * @returns terms, isLoading, refetch
 */
export const useGetCmsTerms = () => {
  const {
    data: terms,
    isLoading,
    refetch
  } = useQuery('cmsTerms', {
    queryFn: getAllCmsTerms,
    onError: handleErrorFeedback
  })

  return { terms, isLoading, refetch }
}
