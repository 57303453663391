import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'

import { getVehiclesData } from '../../actions/Sales'
import { SalesVehicleDataDTO } from '../../../models/SalesVehicleDataDTO'

type useGetVehiclesDataParams = HookQueryParams<SalesVehicleDataDTO> & {
  document: string
}

/**
 * @param params params
 * @returns data, isLoading, refetch
 */
export const useGetVehiclesData = (
  params: useGetVehiclesDataParams = {
    document: ''
  }
) => {
  const { onSuccess, onError, document, suspense, enabled, cacheTime, retry } =
    params

  const { data, isLoading, refetch } = useQuery('getVehiclesData', {
    queryFn: () => getVehiclesData(document),
    onSuccess,
    onError,
    suspense,
    enabled,
    cacheTime,
    retry
  })

  return { data, isLoading, refetch }
}
