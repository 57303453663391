import ContractDTO from '@volvo-apps/shared/models/ContractDTO'

import React from 'react'

import { Chip, ChipProps } from '@mui/material'

import Colors from 'constants/Colors'

type ContractStatusChipProps = {
  contract: ContractDTO
  sx?: ChipProps['sx']
}

/**
 *
 * @param props ContractStatusChipProps
 * @returns TSX
 */
const ContractStatusChip: React.FC<ContractStatusChipProps> = (props) => {
  const { contract, sx } = props

  return contract.statusContrato ? (
    <Chip
      label={contract.statusContrato.toLowerCase()}
      variant="outlined"
      sx={{
        padding: '0px 20px !important',
        backgroundColor: Colors.light.background,
        borderColor: Colors.light.interactActive,
        color: Colors.light.interactActive,
        height: 24,
        minWidth: 80,
        fontSize: 14,
        marginLeft: 2,
        textTransform: 'capitalize',
        ...sx
      }}
    />
  ) : null
}

export default ContractStatusChip
export { ContractStatusChip }
