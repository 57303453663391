import FinancialProductDTO from '@volvo-apps/shared/models/FinancialProductDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { ParametersRoutesEnum as FinancialProductRoutesEnum } from 'routes/cms/routesEnums'

import { useGetFinancialProduct } from 'hooks/financialProducts/useGetFinancialProduct'
import { useUpdateFinancialProducts } from 'hooks/financialProducts/useUpdateFinancialProduct'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import FinancialProductForm from '../Form/FinancialProductForm'
import { FinancialProductData } from '../Form/schema'
import { useFinancialProductForm } from '../Form/useFinancialProductForm'

/**
 * Financial Products Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()
  const navigate = useNavigate()

  const { t } = useLanguage()

  const { methods } = useFinancialProductForm({
    defaultValues: { name: '' }
  })

  const { financialProduct, isLoading: getLoading } = useGetFinancialProduct({
    id: Number(id),
    onSuccess: (financialProduct) => {
      methods.reset({ name: financialProduct.name })
    }
  })

  const { mutate, isLoading } = useUpdateFinancialProducts({
    onSuccess: () => {
      toast.success(t('cms.financialProducts.update.success'))
      navigate(FinancialProductRoutesEnum.FINANCIAL_PRODUCTS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: FinancialProductData) => {
      const newFinancialProduct = new FinancialProductDTO().fromJSON({
        ...financialProduct,
        name: formData.name,
        createdDate: undefined
      })
      mutate({ financialProduct: newFinancialProduct })
    },
    [financialProduct, mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.financialProducts.update.title')}
      </Typography>
      <FinancialProductForm
        isLoading={isLoading || getLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.financialProducts.update.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
