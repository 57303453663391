import React from 'react'

import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
  FormHelperTextProps
} from '@mui/material'
import { Controller } from 'react-hook-form'

type Props = Pick<CheckboxProps, 'checked' | 'value'> &
  Pick<FormControlLabelProps, 'label'> & {
    name: string
    helperText?: FormHelperTextProps['children']
    onChange?: (checked: boolean) => void
  }

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function Checkbox(props: Props) {
  const { label, name, onChange, ...restProps } = props

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const { error } = fieldState

        const handleOnChange = (
          _: React.SyntheticEvent<Element, Event>,
          checked: boolean
        ) => {
          field.onChange(checked)
          onChange?.(checked)
        }

        return (
          <FormGroup>
            <FormControlLabel
              control={<MuiCheckbox {...restProps} checked={field.value} />}
              label={label}
              {...field}
              onChange={handleOnChange}
            />
            <FormHelperText error={!!error}>
              {error?.message ?? props.helperText}
            </FormHelperText>
          </FormGroup>
        )
      }}
    />
  )
}

export default Checkbox
export { Checkbox }
