import { makeResolver } from 'common/utils/form'

type FormVehiclePictures = {
  objId?: number
  azureUrl?: string
  base64: string
  coverPhoto: boolean
  fileName: string
}

/**
 *
 */
export type VehicleData = {
  /**
   *
   */
  model: string
  /**
   *
   */
  name: string
  /**
   *
   */
  productLineId: number
  /**
   *
   */
  vehiclePictures: FormVehiclePictures[]
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    model: Yup.string().required('validation.required.general'),
    name: Yup.string().required('validation.required.general'),
    productLineId: Yup.number().required('validation.required.general'),
    vehiclePictures: Yup.array()
      .min(1, 'validation.required.general')
      .of(
        Yup.object({
          objId: Yup.number(),
          base64: Yup.string(),
          coverPhoto: Yup.boolean().transform((value) => {
            return !!value
          }),
          azureUrl: Yup.string(),
          fileName: Yup.string().required('validation.required.general')
        })
      )
      .test({
        name: 'has-cover-photo',
        message: 'cms.vehicles.form.coverPhotoValidation',
        test: (array?: Partial<FormVehiclePictures>[]) =>
          !!array && array.some((item) => !!item.coverPhoto)
      })
  })
)
