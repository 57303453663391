import { getNpsById } from '@volvo-apps/shared/core/actions/Nps'

import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import NpsDTO from '../../../models/NpsDTO'

type useGetNpsByIdParams = HookQueryParams<NpsDTO> & {
  id?: number
}

/**
 * Hook to get a nps
 *
 * @param params params
 * @returns nps, isLoading, refetch
 */
export const useGetNpsById = (params: useGetNpsByIdParams) => {
  const { id, onSuccess, onError } = params
  const {
    data: nps,
    isLoading,
    refetch
  } = useQuery('getNpsById', {
    enabled: !!id,
    queryFn: () => getNpsById(id || 0),
    onSuccess,
    onError
  })

  return { nps, isLoading, refetch }
}
