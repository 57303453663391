import { deleteTerm } from '@volvo-apps/shared/core/actions/Term'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useDeleteTermParams = {
  onSuccess?: () => void
}

/**
 * Hook to delete one term
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteTerm = (params: useDeleteTermParams = {}) => {
  const { onSuccess } = params
  const { mutate, isLoading, data } = useMutation('deleteTerm', {
    mutationFn: ({ id }: { id: number }) => deleteTerm(id),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, data, isLoading }
}
