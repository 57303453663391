import { deleteNotification } from '@volvo-apps/shared/core/actions/Notification'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useDeleteNotificationParams = {
  onSuccess?: () => void
}

/**
 * Hook to delete one notification
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteNotification = (
  params: useDeleteNotificationParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data } = useMutation('deleteNotification', {
    mutationFn: ({ id }: { id: number }) => deleteNotification(id),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, data, isLoading }
}
