import React from 'react'

import { Box, Typography } from '@mui/material'
import { useUser } from 'context'
import { useNavigate } from 'react-router-dom'

import { Modal, useModalMethods } from 'components/Modal'

import { ProfileRoutesEnum } from 'routes/profile.routes'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function ChangePasswordModal() {
  const { userData } = useUser()
  const { t } = useLanguage()

  const { setClosed, open, setOpen } = useModalMethods()

  const navigate = useNavigate()

  React.useEffect(() => {
    const showModal = !!userData?.resetPasswordTime
    if (showModal) setOpen(true)
  }, [setOpen, userData?.resetPasswordTime])

  const Content = React.useMemo(() => {
    return (
      <Box>
        <Typography
          mb={1}
          variant="body1"
          fontFamily="Volvo-Novum-Medium"
          color={Colors.light.error}
          fontSize={16}
        >
          {t(`changePasswordModal.description`)}
        </Typography>
        <Typography
          variant="body1"
          color={Colors.light.greys.GREY_500}
          fontSize={16}
        >
          {t(`changePasswordModal.disclaimer`)}
        </Typography>
      </Box>
    )
  }, [t])

  const closeModal = React.useCallback(() => {
    setClosed()
  }, [setClosed])

  return (
    <Modal
      disableBackdropClick
      showCloseButton
      testId="complete-profile-modal"
      title={
        <Typography fontFamily="Volvo-Novum-Medium" fontSize={20}>
          {t(`changePasswordModal.title`)}
        </Typography>
      }
      PaperProps={{ sx: { p: 1, borderRadius: 2.5 } }}
      content={Content}
      actions={[
        {
          children: t('changePasswordModal.complete'),
          onClick: () => {
            navigate(ProfileRoutesEnum.CHANGE_PASSWORD)
            closeModal()
          },
          sx: { width: '100%' },
          variant: 'contained'
        }
      ]}
      onClose={closeModal}
      open={open}
    />
  )
}

export default ChangePasswordModal
export { ChangePasswordModal }
