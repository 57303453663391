import Yup from './index'

/**
 *
 * @param value cnpj
 * @returns Validation result
 */
export function CnpjValidation(value?: string): boolean {
  const _cnpj = value?.replace(/[^\d]+/g, '')

  if (_cnpj === '') return false

  if (_cnpj?.length !== 14) return false

  // Known invalid CNPJs
  if (
    _cnpj === '00000000000000' ||
    _cnpj === '11111111111111' ||
    _cnpj === '22222222222222' ||
    _cnpj === '33333333333333' ||
    _cnpj === '44444444444444' ||
    _cnpj === '55555555555555' ||
    _cnpj === '66666666666666' ||
    _cnpj === '77777777777777' ||
    _cnpj === '88888888888888' ||
    _cnpj === '99999999999999'
  )
    return false

  // Validate digits
  let size = _cnpj.length - 2
  let numbers = _cnpj.substring(0, size)
  const digits = _cnpj.substring(size)
  let sum = 0
  let pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += Number(numbers.charAt(size - i)) * pos--
    if (pos < 2) pos = 9
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result !== Number(digits.charAt(0))) return false

  size = size + 1
  numbers = _cnpj.substring(0, size)
  sum = 0
  pos = size - 7
  for (let j = size; j >= 1; j--) {
    sum += Number(numbers.charAt(size - j)) * pos--
    if (pos < 2) pos = 9
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result !== Number(digits.charAt(1))) return false

  return true
}

/**
 *
 * @param value CPF
 * @returns Validation result
 */
export function CpfValidation(value?: string): boolean {
  const _cpf = value?.replace(/[^\d]+/g, '')
  if (!_cpf) return false
  // Known invalid CPFs
  if (
    _cpf.length !== 11 ||
    _cpf === '00000000000' ||
    _cpf === '11111111111' ||
    _cpf === '22222222222' ||
    _cpf === '33333333333' ||
    _cpf === '44444444444' ||
    _cpf === '55555555555' ||
    _cpf === '66666666666' ||
    _cpf === '77777777777' ||
    _cpf === '88888888888' ||
    _cpf === '99999999999'
  )
    return false
  // Validates 1st digit
  let add = 0
  for (let i = 0; i < 9; i++) add += parseInt(_cpf.charAt(i)) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(_cpf.charAt(9))) return false
  // Validates 2nd digit
  add = 0
  for (let i = 0; i < 10; i++) add += parseInt(_cpf.charAt(i)) * (11 - i)
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(_cpf.charAt(10))) return false
  return true
}

/**
 *
 * @param value CPF or CNPJ
 * @returns Validation result
 */
export function CpfOrCnpjValidation(value?: string): boolean {
  const cpfCnpj = value?.replace(/[^\d]+/g, '') ?? ''
  let isValid = false
  isValid = cpfCnpj?.length > 11 ? CnpjValidation(value) : CpfValidation(value)
  return isValid
}

export const cpfCnpjValidator = Yup.string()
  .required('validation.required.cpfCnpj')
  .test({
    message: 'validation.invalid.cpfCnpj',
    test: CpfOrCnpjValidation
  })

export const cpfValidator = Yup.string()
  .required('validation.required.cpf')
  .test({
    message: 'validation.invalid.cpf',
    test: CpfValidation
  })

export const cnpjValidator = Yup.string()
  .required('validation.required.cnpj')
  .test({
    message: 'validation.invalid.cnpj',
    test: CnpjValidation
  })
