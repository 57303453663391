import { NpsOriginEnum } from '@volvo-apps/shared/common/utils/enums'
import { downloadTicketPDF } from '@volvo-apps/shared/core/actions/Finance'
import ContractDTO from '@volvo-apps/shared/models/ContractDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useMutation } from 'react-query'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'
import { NoInfo } from 'components/NoInfo'
import ShareAndRateButton from 'components/ShareAndRateButton/ShareAndRateButton'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'
// import { Barcode } from 'components/Barcode'

type DetailsProps = {
  contract: ContractDTO
  npsOrigin: NpsOriginEnum
}

/**
 *
 * @param props DetailsProps
 * @returns TSX
 */
function Details(props: DetailsProps) {
  const { contract, npsOrigin } = props

  const { t } = useLanguage()
  const { mutate, isLoading } = useMutation('downloadTicketPDF', {
    mutationFn: () => downloadTicketPDF(contract),
    onError: handleErrorFeedback
  })

  const downloadPDF = () => {
    mutate()
  }

  return contract.cedido || !contract.isValid ? (
    <NoInfo />
  ) : (
    <FlexBox flexDirection="column" justifyContent="start" width="fit-content">
      <FlexBox alignItems="center">
        <Icon
          family="mui"
          name="InsertDriveFileOutlined"
          sx={{ color: Colors.light.interactActive }}
        />

        <Typography fontSize={14} color={Colors.light.interactActive}>
          {t('financial.tickets.card.contract')}
        </Typography>
        <Typography fontSize={14} color={Colors.light.greys.GREY_700}>
          &nbsp;{contract.numeroContrato}
        </Typography>
      </FlexBox>
      {/* <Barcode value={} options={{ height: 40 }} /> */}
      <ShareAndRateButton
        variant="outlined"
        style={{ color: Colors.light.text }}
        loading={isLoading}
        onClick={downloadPDF}
        sx={{ mt: 2, py: 1, px: 10 }}
        label={t('financial.tickets.card.saveButton')}
        origin={npsOrigin}
      />
    </FlexBox>
  )
}

export default Details
export { Details }
