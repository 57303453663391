import React from 'react'

import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'

import { useLanguage } from 'context/Language'

type NextButtonProps = {
  disabled?: boolean
  onClick?: () => void
  isLoading?: boolean
  testId?: string
}

/**
 * @param props NextButton Props
 * @returns TSX
 */
function NextButton(props: NextButtonProps) {
  const { disabled, onClick, isLoading, testId } = props

  const { t } = useLanguage()
  const type = onClick ? 'button' : 'submit'

  return (
    <PlainButton
      type={type}
      variant="contained"
      testId={testId}
      disabled={disabled}
      loading={isLoading}
      sx={{ alignSelf: 'center', width: '40%', marginTop: '10px' }}
      endIcon={<Icon family="mui" name="East" />}
      onClick={onClick}
    >
      {t('general.next')}
    </PlainButton>
  )
}

export default NextButton
export { NextButton }
