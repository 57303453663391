import VehicleDTO from '@volvo-apps/shared/models/VehicleDTO'
import VehicleImagesDTO from '@volvo-apps/shared/models/VehicleImagesDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { ParametersRoutesEnum as VehicleRoutesEnum } from 'routes/cms/routesEnums'

import { useGetVehicleAndImages } from 'hooks/vehicles/useGetVehicleAndImages'
import { useUpdateVehicle } from 'hooks/vehicles/useUpdateVehicle'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { VehicleData } from '../Form/schema'
import { useVehicleForm } from '../Form/useVehicleForm'
import { VehicleForm } from '../Form/VehicleForm'

/**
 * Vehicle Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { methods } = useVehicleForm({
    defaultValues: {
      name: '',
      model: '',
      vehiclePictures: []
    }
  })

  const { vehicleAndImages, isLoading: getLoading } = useGetVehicleAndImages({
    id: Number(id),
    onSuccess: (vehicleAndImages) => {
      const { vehicle, vehicleImages } = vehicleAndImages

      const vehiclePictures = vehicleImages.map((image) => {
        return {
          ...image,
          base64: '',
          objId: image.id
        }
      })

      methods.reset({
        ...vehicle,
        vehiclePictures
      })
    }
  })

  const { mutate, isLoading } = useUpdateVehicle({
    onSuccess: () => {
      toast.success(t('cms.vehicles.update.success'))
      navigate(VehicleRoutesEnum.VEHICLES)
    }
  })

  const onRemoveBanner = React.useCallback(
    (vehicleId?: number) => {
      if (vehicleId && vehicleAndImages) {
        const index = vehicleAndImages.vehicleImages.findIndex(
          (vehicle) => vehicle.id === vehicleId
        )

        if (index === -1) return

        vehicleAndImages.vehicleImages[index].active = false
      }
    },
    [vehicleAndImages]
  )

  const onSubmit = React.useCallback(
    (formData: VehicleData) => {
      if (!vehicleAndImages) return
      const { vehiclePictures: vehiclePicturesForm, ...vehicle } = formData
      const { vehicleImages: oldVehiclePictures, vehicle: oldVehicle } =
        vehicleAndImages

      const newVehiclePictures: VehicleImagesDTO[] = []

      vehiclePicturesForm.forEach((vehiclePicture) => {
        // already created in the backend
        if (vehiclePicture.objId) {
          const index = oldVehiclePictures.findIndex(
            (vehicle) => vehicle.id === vehiclePicture.objId
          )

          if (index === -1 || !oldVehiclePictures[index].active) return

          oldVehiclePictures[index].coverPhoto = vehiclePicture.coverPhoto
        } else {
          newVehiclePictures.push(
            new VehicleImagesDTO().fromJSON({
              ...vehiclePicture,
              vehicleId: vehicleAndImages?.vehicle.id,
              azureUrl: vehiclePicture.azureUrl,
              profilePicture: vehiclePicture.base64
            })
          )
        }
      })

      mutate({
        vehicle: new VehicleDTO().fromJSON({
          ...oldVehicle,
          ...vehicle,
          profilePictures: [...oldVehiclePictures, ...newVehiclePictures],
          vehicleImagesConfigId: 1, // backend expects this
          createdDate: undefined
        })
      })
    },
    [mutate, vehicleAndImages]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.vehicles.update.title')}
      </Typography>
      <VehicleForm
        isLoading={isLoading || getLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.vehicles.update.button')}
        onRemoveBanner={onRemoveBanner}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
