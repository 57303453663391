import { getAllTerm } from '@volvo-apps/shared/core/actions/Term'

import { useQuery } from 'react-query'

/**
 * @returns data, isLoading
 */
export const useTermsAndConditions = () => {
  const { isLoading, data } = useQuery('termsAndConditions', {
    queryFn: getAllTerm
  })

  return {
    data,
    isLoading
  }
}
