import { useCheckFirstAccess } from '@volvo-apps/shared/core/hooks'

import React, { useCallback, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Alert, CustomAlertProps } from 'components/Alert'
import { FlexBox } from 'components/FlexBox'

import { ProfileRoutesEnum } from 'routes/profile.routes'

import Colors, { BROWN_ALERT } from 'constants/Colors'

import { useLanguage } from 'context/Language'
import { useUser } from 'context/User'

/**
 * Component that displays alerts.
 *
 * @returns The alerts component.
 */
const Alerts = () => {
  const { userData, refetch, isRefetching } = useUser()

  const { dismissFirstAccess } = useCheckFirstAccess({
    cacheTime: 0,
    disableCheck: true
  })

  const { t } = useLanguage()
  const [alerts, setAlerts] = useState<CustomAlertProps[]>([])
  const navigate = useNavigate()

  const showAlert = userData?.documents?.every(
    (document) => document.isSignUpDocument && !document.isContractsAttached
  )

  const handleAlerts = useCallback(() => {
    const alertList: CustomAlertProps[] = []
    if (userData?.firstAccess || showAlert) {
      if (userData?.firstAccess) {
        dismissFirstAccess()
      }
      alertList.push({
        key: 'firstAccessOrNoCompany',
        severity: 'warning',
        onClose: undefined,
        children: t('alerts.firstAccessOrNoCompany.text') || '',
        buttonProps: {
          onClick: () => navigate(ProfileRoutesEnum.LINK_DOCUMENTS),
          style: {
            color: BROWN_ALERT
          },
          textProps: {
            color: BROWN_ALERT,
            label: t('alerts.firstAccessOrNoCompany.buttonText') || ''
          }
        },
        open: true
      })
    }
    if (userData?.hasPendingDocuments) {
      alertList.push({
        key: 'waitingForApproval',
        severity: 'info',
        onClose: undefined,
        children: (
          <Box>
            <Typography color={Colors.light.interactActive} fontSize={14}>
              {t('alerts.waitingForApproval') || ''}
              <span
                style={{
                  fontWeight: 700,
                  color: Colors.light.interactActive
                }}
              >
                {userData.maskedEmailAutbank || userData.maskedEmail}
              </span>
            </Typography>
          </Box>
        ),
        open: true
      })
    }
    setAlerts(alertList)
  }, [
    userData?.firstAccess,
    userData?.hasPendingDocuments,
    userData?.maskedEmailAutbank,
    userData?.maskedEmail,
    showAlert,
    dismissFirstAccess,
    t,
    navigate
  ])

  useEffect(() => {
    refetch?.()
  }, [refetch])

  useEffect(() => {
    setAlerts([])
    if (isRefetching) return
    handleAlerts()
  }, [isRefetching, handleAlerts])

  return (
    <FlexBox flexDirection="column">
      {alerts.map((alert, index) => (
        <Alert
          {...alert}
          key={`${alert.key}-${index}`}
          severity={alert.severity}
          open={alert.open}
          sx={{ mt: index > 0 ? 1 : 0 }}
        />
      ))}
    </FlexBox>
  )
}

export default Alerts
export { Alerts }
