import React, { PropsWithChildren } from 'react'

import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import theme from 'constants/theme'

/**
 *
 * @param props Component props
 * @param props.children JSX
 * @returns JSX
 */
function Theme({ children }: PropsWithChildren) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default Theme
export { Theme }
