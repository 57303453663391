import React from 'react'

import { Button, ButtonProps } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom'

type Props = Pick<LinkProps, 'to'> &
  Pick<ButtonProps, 'children' | 'variant' | 'startIcon' | 'sx'>

/**
 *
 * @param props Component Props
 * @returns JSX
 */
function ButtonLink(props: Props) {
  return (
    <Button
      component={Link}
      fullWidth
      variant="contained"
      {...props}
      sx={{
        mt: 2,
        textTransform: 'none',
        ...props.sx
      }}
    />
  )
}

export default ButtonLink
export { ButtonLink }
