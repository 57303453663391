import React from 'react'

import { useNavigate } from 'react-router'

import { List, ListItemProps } from 'components'
import ContentContainer from 'components/ContentContainer/ContentContainer'

import { InsurancePoliciesRoutesEnum } from 'routes/insurancePolicies.routes'

import { useLanguage } from 'context/Language'

/**
 * @returns TSX
 */
function InsurancePoliciesMenu() {
  const navigate = useNavigate()
  const { t } = useLanguage()

  const menuItems: ListItemProps[] = React.useMemo(
    () => [
      {
        primary: t('insurancePolicies.menu.insurancePolicies'),
        onClick: () => navigate(InsurancePoliciesRoutesEnum.POLICIES)
      },
      {
        primary: t('insurancePolicies.menu.trackClaims'),
        onClick: () => navigate(InsurancePoliciesRoutesEnum.CLAIMS)
      }
    ],
    [navigate, t]
  )

  return (
    <ContentContainer>
      <List items={menuItems} noPadding />
    </ContentContainer>
  )
}

export default InsurancePoliciesMenu
export { InsurancePoliciesMenu }
