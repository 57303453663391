import { handleLinkedDocumentFilter } from '@volvo-apps/shared/common/utils/filterLinkedDocuments'
import {
  useLinkedDocuments,
  useUnlinkDocument
} from '@volvo-apps/shared/core/hooks/linkedDocuments'
import LinkedDocumentsDTO from '@volvo-apps/shared/models/LinkedDocumentsDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Form } from 'components'
import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'
import { Option } from 'types/Option'

import { Filters } from './Filters'
import { LinkDocumentButton } from './LinkDocumentButton/LinkDocumentButton'
import { columns } from './tableColumns'

/**
 *
 */
export type FormValues = {
  /**
   *
   */
  searchTerm: string
  /**
   *
   */
  user: string
}

/**
 * List
 *
 * @returns TSX
 */
function List() {
  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      searchTerm: '',
      user: ''
    }
  })

  const { t } = useLanguage()
  const [linkedDocumentsFilter, setLinkedDocumentsFiltered] = React.useState<
    LinkedDocumentsDTO[]
  >([])

  const {
    linkedDocuments,
    isLoading: getLoading,
    refetch
  } = useLinkedDocuments({
    onSuccess: (data) => {
      setLinkedDocumentsFiltered(data)
    },
    onError: handleErrorFeedback
  })

  const { mutate } = useUnlinkDocument({
    onSuccess: () => {
      toast.success(t('cms.linkUsers.list.delete.success'))
      refetch()
    },
    onError: handleErrorFeedback
  })

  const handleFilter = React.useCallback(
    ({ searchTerm, user }: { searchTerm: string; user: string }) => {
      const filteredDocuments = linkedDocuments?.filter((linkedDocument) => {
        const searchFilter = handleLinkedDocumentFilter(
          linkedDocument,
          searchTerm
        )

        return (
          searchFilter &&
          (user === '' ||
            linkedDocument.userName.toLowerCase() === user.toLowerCase())
        )
      })

      setLinkedDocumentsFiltered(filteredDocuments ?? [])
    },
    [linkedDocuments]
  )

  const headerSuffix = React.useMemo(() => {
    return <LinkDocumentButton />
  }, [])

  const usersToFilter: Option[] = React.useMemo(() => {
    const names =
      linkedDocuments?.map((linkedDocument) => linkedDocument.userName) ?? []

    const users: Option[] = []

    new Set(names).forEach((name) =>
      users.push({
        label: name,
        value: name
      })
    )

    return [
      {
        label: t('cms.linkUsers.list.filters.users.all') as string,
        value: ''
      },
      ...users.sort((a, b) => String(a.label).localeCompare(String(b.label)))
    ]
  }, [linkedDocuments, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.linkUsers.list.title')}
      </Typography>
      <Form methods={methods}>
        <Filters onFilter={handleFilter} users={usersToFilter} />
      </Form>
      <CrudDataGrid
        columns={columns}
        rows={linkedDocumentsFilter || []}
        getRowId={(row) => row.userDocumentId}
        loading={getLoading}
        type="striped"
        deleteModalConfig={{
          enable: true,
          title: t('cms.linkUsers.list.delete.modal.title'),
          description: t('cms.linkUsers.list.delete.modal.description'),
          cancelText: t('cms.linkUsers.list.delete.modal.cancel'),
          confirmText: t('cms.linkUsers.list.delete.modal.confirm')
        }}
        handleDelete={(row: LinkedDocumentsDTO) => {
          mutate({ id: row.userDocumentId })
        }}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
