import { makeResolver } from 'common/utils/form'
import { emailValidator } from 'common/validators/email'
import { oldPasswordValidator } from 'common/validators/password'

export const validationSchema = makeResolver((Yup) =>
  Yup.object({
    email: emailValidator,
    password: oldPasswordValidator
  })
)
