import { t } from 'i18next'
import { toast } from 'react-toastify'

import { useDeleteSimulationParameter } from 'hooks/simulationParameters/useDeleteSimulationParameter'
import { useGetCmsSimulationParameters } from 'hooks/simulationParameters/useGetCmsSimulationParameters'
import { useUpdateSimulationParameter } from 'hooks/simulationParameters/useUpdateSimulationParameter'

/**
 * Hook to use CRUD operations on simulation parameters
 *
 * @returns simulationParameters, deleteSimulationParameter, updateSimulationParameter, refetch, isLoading, getLoading, deleteLoading, updateLoading
 */
export const useCrudSimulationParameter = () => {
  const {
    simulationParameters,
    refetch,
    isLoading: getLoading
  } = useGetCmsSimulationParameters()
  const { mutate: deleteSimulationParameter, isLoading: deleteLoading } =
    useDeleteSimulationParameter({
      onSuccess: () => {
        refetch()
        toast.success(t('cms.simulationParameters.delete.success'))
      }
    })
  const {
    mutate: updateSimulationParameter,
    isLoading: updateLoading,
    mutateAsync: updateAsyncSimulationParameter
  } = useUpdateSimulationParameter({
    onSuccess: refetch
  })

  const isLoading = getLoading || deleteLoading || updateLoading

  return {
    simulationParameters,
    deleteSimulationParameter,
    updateSimulationParameter,
    updateAsyncSimulationParameter,
    refetch,
    isLoading,
    getLoading,
    deleteLoading,
    updateLoading
  }
}
