import { download, blobToURL } from '@volvo-apps/web/src/common/utils/download'
import UserSimulationDTO from '@volvo-apps/shared/models/UserSimulationDTO'
import {
  CreateUserSimulationWithoutSaveType,
  UserSimulationService
} from '../service/UserSimulation.service'

/**
 * @param data UserSimulationDTO
 * @returns UserSimulationDTO
 */
export async function createUserSimulation(data: UserSimulationDTO) {
  const service = new UserSimulationService()
  return service.createUserSimulation(data).then(({ data }) => data)
}

/**
 *
 * @param data CreateUserSimulationWithoutSaveType
 * @returns UserSimulationDTO
 */
export async function createUserSimulationWithoutSave(
  data: CreateUserSimulationWithoutSaveType
) {
  const userSimulationService = new UserSimulationService()
  return userSimulationService
    .createUserSimulationWithoutSave(data)
    .then(({ data }) => data)
}

/**
 *
 * @param simulationId Simulation ID
 */
export async function getSummarySimulationFile(simulationId: number) {
  const userSimulationService = new UserSimulationService()
  return userSimulationService.getSummarySimulationFile(simulationId)
}

/**
 *
 * @param simulationId Simulation ID
 */
export async function downloadSummarySimulationFile(simulationId: number) {
  const userSimulationService = new UserSimulationService()
  return userSimulationService
    .getSummarySimulationFile(simulationId)
    .then(({ data }) => {
      download(blobToURL(data), `summarySimulation-${simulationId}.pdf`)
    })
}

/**
 * Get simulations history
 */
export async function getSimulationHistory() {
  const service = new UserSimulationService()
  const response = await service.getSimulationHistory()

  return response.data.map((sim) => new UserSimulationDTO().fromJSON(sim))
}
