import { downloadBillPDF } from '@volvo-apps/shared/core/actions/Sales'
import RentalDetailsDTO from '@volvo-apps/shared/models/RentalDetailsDTO'

import React from 'react'

import { useMutation } from 'react-query'

import { PlainButton, PlainButtonProps } from 'components/PlainButton'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'

import { ReactComponent as Download } from '../../assets/icons/download.svg'

type DownloadMensalInvoiceButtonProps = {
  label: string
  iconStyle?: React.CSSProperties
  rental?: RentalDetailsDTO
} & PlainButtonProps

/**
 *
 * @param props DownloadMensalInvoiceButtonProps
 * @returns TSX
 */
const DownloadMensalInvoiceButton = (
  props: DownloadMensalInvoiceButtonProps
) => {
  const { label, iconStyle, rental, ...plainButtonProps } = props

  const color = rental?.hasMonthlyBillFile
    ? Colors.light.background
    : Colors.light.greys.GREY_300

  const { mutate, isLoading } = useMutation('downloadTicketPDF', {
    mutationFn: ({
      documentoCobranca,
      tipo,
      forma
    }: {
      documentoCobranca: string
      tipo: string
      forma: string
    }) => downloadBillPDF(documentoCobranca, tipo, forma),
    onError: handleErrorFeedback
  })

  const downloadPDF = () => {
    const faturas = rental?.rentalDetails?.Response?.Faturas ?? []

    const faturaFT = faturas.find((fatura) => fatura.Tipo === 'FT')

    if (faturaFT) {
      mutate({
        documentoCobranca: faturaFT.DocumentoCobranca,
        tipo: 'FT',
        forma: '1'
      })
    }
  }

  return (
    <PlainButton
      startIcon={<Download style={{ ...iconStyle, color }} />}
      {...plainButtonProps}
      onClick={downloadPDF}
      disabled={!rental?.hasMonthlyBillFile}
      loading={isLoading}
      style={{
        paddingTop: 1,
        paddingBottom: 1,
        marginBottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color,
        lineHeight: 1
      }}
      sx={{
        flexDirection: 'column',
        gap: 1,
        fontSize: 12
      }}
    >
      {label}
    </PlainButton>
  )
}

export default DownloadMensalInvoiceButton
