import { useCreateUserRole } from '@volvo-apps/shared/core/hooks/role/useCreateUserRole'
import RoleDTO from '@volvo-apps/shared/models/RoleDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { UserSettingsRoutesEnum } from 'routes/cms/routesEnums'

import Colors from 'constants/Colors'

import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import { RoleForm } from '../Form/RoleForm'
import { RoleData } from '../Form/schema'
import { useRoleForm } from '../Form/useRoleForm'
import { allPermissionsRole } from '../Form/utils'

/**
 * Role Create
 *
 * @returns TSX
 */
function Create() {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const { methods } = useRoleForm({
    defaultValues: {
      name: '',
      permissions: []
    }
  })

  const { mutate, isLoading } = useCreateUserRole({
    onSuccess: () => {
      toast.success(t('cms.roles.create.success'))
      navigate(UserSettingsRoutesEnum.ROLES_LIST)
    },
    onError: handleErrorFeedback
  })

  const onSubmit = React.useCallback(
    (formData: RoleData) => {
      const permissions = formData.permissions.filter(
        (permission) =>
          permission.id !== allPermissionsRole.id && permission.active
      )
      mutate(new RoleDTO().fromJSON({ ...formData, permissions }))
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.roles.create.title')}
      </Typography>

      <RoleForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.roles.create.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
