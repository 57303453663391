import { t } from 'i18next'
import { toast } from 'react-toastify'

import { useDeleteHoliday } from 'hooks/holidays/useDeleteHoliday'
import { useGetCmsHolidays } from 'hooks/holidays/useGetCmsHolidays'
import { useUpdateHoliday } from 'hooks/holidays/useUpdateHoliday'

/**
 * Hook to use CRUD operations on holidays
 *
 * @returns holidays, deleteHoliday, updateHoliday, refetch, isLoading, getLoading, deleteLoading, updateLoading
 */
export const useCrudHoliday = () => {
  const { holidays, refetch, isLoading: getLoading } = useGetCmsHolidays()
  const { mutate: deleteHoliday, isLoading: deleteLoading } = useDeleteHoliday({
    onSuccess: () => {
      refetch()
      toast.success(t('cms.holidays.delete.success'))
    }
  })
  const {
    mutate: updateHoliday,
    isLoading: updateLoading,
    mutateAsync: updateAsyncHoliday
  } = useUpdateHoliday({
    onSuccess: refetch
  })

  const isLoading = getLoading || deleteLoading || updateLoading

  return {
    holidays,
    deleteHoliday,
    updateHoliday,
    updateAsyncHoliday,
    refetch,
    isLoading,
    getLoading,
    deleteLoading,
    updateLoading
  }
}
