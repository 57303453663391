import { AxiosResponse } from 'axios'
import { GenericService } from './GenericService'
import NpsDTO from '../../models/NpsDTO'
import NpsUserDTO from '../../models/NpsUserDTO'

/**
 *
 */
export type CmsFields = Pick<NpsDTO, 'title' | 'beginDate' | 'endDate'>

/**
 * Nps
 */
export class NpsService extends GenericService<NpsDTO> {
  readonly URI: string = 'gateway/NPS'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all nps
   */
  public async getAll() {
    return super.get<AxiosResponse<NpsDTO[]>>('GetAllNps')
  }

  /**
   * Get the user nps
   */
  public async getNPSUser() {
    return super.get<AxiosResponse<NpsUserDTO>>('GetNPSUser')
  }

  /**
   * Get all cms nps
   */
  public async getAllCms() {
    return super.get<AxiosResponse<NpsDTO[]>>('GetAllCms')
  }

  /**
   *
   * @param id The id of the Nps
   */
  public async getById(id: number) {
    return super.get<AxiosResponse<NpsDTO>>('GetByIdNPS', { id })
  }

  /**
   * Delete nps
   *
   * @param id The id of the nps
   */
  public async deleteNps(id: number) {
    return super.delete('InactivateNPS', { id })
  }

  /**
   * Update nps in the cms
   *
   * @param updatedNps The updated nps
   */
  public async updateNps(updatedNps: CmsFields) {
    return super.put<AxiosResponse<NpsDTO>>('UpdateNPS', updatedNps)
  }

  /**
   * Create a nps in the cms
   *
   * @param payload The payload to create the nps
   */
  public async createNps(payload: CmsFields) {
    return super.post<AxiosResponse<NpsDTO>>('AddNPS', payload)
  }

  /**
   * Create a nps in the cms
   *
   * @param payload The payload to create the nps
   */
  public async addOrUpdateNPSUser(payload: NpsUserDTO) {
    return super.post<AxiosResponse<NpsUserDTO>>('AddOrUpdateNPSUser', {
      ...payload
    })
  }
}
