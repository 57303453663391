import LeadNotificationDTO from '@volvo-apps/shared/models/LeadNotificationDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'
import { PlainButton } from 'components/PlainButton'

import { LeadsRoutesEnum } from 'routes/cms/routesEnums'

import { useCrudLeadNotification } from 'hooks/cms/useCrudLeadNotification'
import { useGetLeadNotifications } from 'hooks/leads/useGetLeadNotifications'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { columns } from './tableColumns'

/**
 * Leads Notifications List
 *
 * @returns TSX
 */
function List() {
  const navigate = useNavigate()

  const { t } = useLanguage()

  const { leadNotifications, isLoading } = useGetLeadNotifications()
  const { updateAsyncLeadNotification } = useCrudLeadNotification()

  const handleCreate = React.useCallback(() => {
    navigate(LeadsRoutesEnum.LEADS_AUTOMATION_CREATE)
  }, [navigate])

  const handleToggle = React.useCallback(
    async (leadNotification: Record<string, unknown>, checked: boolean) => {
      const newLeadNotification = new LeadNotificationDTO().fromJSON({
        ...leadNotification,
        active: checked
      })

      const updateResponse = await updateAsyncLeadNotification({
        leadNotification: newLeadNotification
      })

      toast.success(
        updateResponse.data.active
          ? t('cms.leads.automation.toggle.enabled')
          : t('cms.leads.automation.toggle.disabled')
      )
    },
    [t, updateAsyncLeadNotification]
  )

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.leads.automation.list.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.leads.automation.list.title')}
      </Typography>

      <CrudDataGrid
        columns={columns}
        rows={leadNotifications || []}
        loading={isLoading}
        hideFooter
        handleToggle={handleToggle}
        linkEditProps={{
          to: (id) =>
            LeadsRoutesEnum.LEADS_AUTOMATION_UPDATE.replace(
              ':id',
              id.toString()
            ),
          stateProperty: 'leadNotification'
        }}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
