import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'

/**
 * VerifiedDocumentDTO
 */
class VerifiedDocumentDTO extends Serializable {
  @jsonProperty(Number)
  public userId!: number

  @jsonProperty(String)
  public document!: string

  @jsonProperty(Boolean)
  public isSignUpDocument!: boolean

  @jsonProperty(Boolean)
  public hasABContracts!: boolean

  @jsonProperty(Boolean)
  public isContractsAttached!: boolean

  @jsonProperty(Boolean)
  public verified!: boolean

  @jsonProperty(Date)
  public validationDate!: Date

  @jsonProperty(String)
  public documentType!: string

  @jsonProperty(Number)
  public id!: number

  @jsonProperty(Date)
  public modifiedDate!: Date

  @jsonProperty(Date)
  public createdDate!: Date

  @jsonProperty(Boolean)
  public active!: boolean
}

export default VerifiedDocumentDTO
