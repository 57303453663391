import React, { useEffect } from 'react'

import { Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'
import { PlainButton } from 'components/PlainButton'
import { Tooltip } from 'components/Tooltip'

import Colors from 'constants/Colors'

interface ActionWithTimeoutTemplateProps {
  buttonLabel: string
  buttonTooltipText: string
  buttonOnClick?: () => void
  isLoading: boolean
  timeout?: Date
}

/**
 *
 * @param date Date
 * @returns distance
 */
const getDistanceFromNow = (date: Date) => {
  const now = new Date().getTime()
  const distance = date.getTime() - now

  return distance
}

const formatDistanceToString = (distance: number) => {
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )

  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))

  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

/**
 *
 * @param props ActionWithTimeoutTemplateProps
 * @param props.buttonLabel Button label
 * @param props.buttonTooltipText Button tooltip text
 * @param props.buttonOnClick Button on click
 * @param props.timeout Timeout in milliseconds
 * @param props.isLoading Is loading
 * @returns TSX
 */
export const ActionWithTimeoutTemplate = ({
  buttonLabel,
  buttonTooltipText,
  buttonOnClick,
  isLoading,
  timeout
}: ActionWithTimeoutTemplateProps) => {
  const [timerLeft, setTimerLeft] = React.useState<string>()
  const disableButtons = !!timerLeft || !buttonOnClick || isLoading

  useEffect(() => {
    let timeoutInterval: NodeJS.Timeout

    if (timeout) {
      const updateTimeout = (timeout: Date) => {
        const distance = getDistanceFromNow(timeout)

        if (distance > 0) {
          setTimerLeft(formatDistanceToString(distance))
        }

        return distance
      }

      updateTimeout(timeout)

      timeoutInterval = setInterval(() => {
        const distance = updateTimeout(timeout)

        if (distance < 0) {
          clearInterval(timeoutInterval)
          setTimerLeft(undefined)
        }
      }, 1000)
    }

    return () => {
      clearInterval(timeoutInterval)
    }
  }, [timeout])

  return (
    <FlexBox gap="1rem" alignItems="center">
      <Tooltip title={buttonTooltipText}>
        <Typography
          color={Colors.light.interactActive}
          sx={{
            color: disableButtons
              ? Colors.light.greys.GREY_400
              : Colors.light.interactDefault,
            cursor: disableButtons ? 'not-allowed' : 'pointer',
            userSelect: 'none'
          }}
          onClick={disableButtons ? undefined : buttonOnClick}
        >
          {buttonLabel}
        </Typography>
      </Tooltip>

      {timerLeft && (
        <PlainButton
          variant="contained"
          sx={{
            cursor: 'default'
          }}
        >
          <FlexBox gap="0.5rem" width="100px">
            <Icon
              family="mui"
              name="AccessTime"
              style={{
                fill: Colors.light.background
              }}
            />

            <span>{timerLeft}</span>
          </FlexBox>
        </PlainButton>
      )}
    </FlexBox>
  )
}
