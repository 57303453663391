import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import RoleDTO from './RoleDTO'

/**
 * UserWithDocumentDTO
 */
class UserWithDocumentDTO extends Serializable {
  @jsonName('userId')
  @jsonProperty(Number)
  public userId!: number

  @jsonName('userFunctionId')
  @jsonProperty(Number)
  public userFunctionId!: number

  @jsonName('userFunction')
  @jsonProperty(RoleDTO)
  public userFunction!: RoleDTO

  @jsonName('signUpDocument')
  @jsonProperty(String)
  public signUpDocument!: string

  @jsonName('name')
  @jsonProperty(String)
  public name!: string

  @jsonName('email')
  @jsonProperty(String)
  public email!: string

  @jsonName('resendEmailTime')
  @jsonProperty(Date)
  public resendEmailTime!: Date

  @jsonName('resetPasswordTime')
  @jsonProperty(Date)
  public resetPasswordTime!: Date

  @jsonName('canAccessCms')
  @jsonProperty(Boolean)
  public canAccessCms!: boolean

  @jsonName('phoneNumber')
  @jsonProperty(String)
  public phoneNumber?: string

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: Date
}

export default UserWithDocumentDTO
