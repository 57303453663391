import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * UserReportDTO
 */
class UserReportDTO extends Serializable {
  @jsonName('beginDateStr')
  @jsonProperty(String)
  public beginDateStr!: string

  @jsonName('endDateStr')
  @jsonProperty(String)
  public endDateStr!: string
}

export default UserReportDTO
