import UserDTO from '@volvo-apps/shared/models/UserDTO'

/**
 *
 */
export type CommonStepProps = {
  /**
   *
   */
  onSubmit: VoidFunction
}

/**
 *
 */
export type FormValuesStep1 = Pick<UserDTO, 'name' | 'email'> & {
  /**
   *
   */
  documents: { value: string }[]
}

export const FormValuesStep1RequiredKeys: Array<keyof FormValuesStep1> = [
  'documents',
  'name',
  'email'
]

/**
 *
 */
export type FormValuesStep2 = Pick<UserDTO, 'password'> & {
  /**
   *
   */
  password_confirm: string
  termsAndConditions: boolean
}

export const FormValuesStep2RequiredKeys: Array<keyof FormValuesStep2> = [
  'password',
  'password_confirm'
]
