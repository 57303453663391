import React, { PropsWithChildren } from 'react'

import { AuthProvider, UserProvider } from 'context'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { LanguageProvider } from 'context/Language'

import Localization from './Localization'
import { Theme } from './Theme'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

/**
 *
 * @param props Component props
 * @returns JSX
 */
function AppProviders(props: PropsWithChildren) {
  return (
    <Theme>
      <Localization>
        <QueryClientProvider client={queryClient}>
          <LanguageProvider>
            <AuthProvider>
              <UserProvider>{props.children}</UserProvider>
            </AuthProvider>
          </LanguageProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </Localization>
    </Theme>
  )
}

export default AppProviders
export { AppProviders }
