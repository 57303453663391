import { getParametersDetailById } from '@volvo-apps/shared/core/actions/SimulationParameter'
import ParameterDetailDTO from '@volvo-apps/shared/models/ParameterDetailDTO'

import React from 'react'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'
import { filterParametersDetail } from 'pages/cms/Parameters/SimulationParameters/helpers'

type useGetParametersDetailByIdParams = {
  onSuccess?: (parametersDetail: ParameterDetailDTO[]) => void
  productLineId?: number
  financialProductId?: number
  cacheTime?: number
}

/**
 * Hook to get parameters detail by id
 *
 * @param params params
 * @returns parametersDetail, isLoading, refetch
 */
export const useGetParametersDetailById = (
  params: useGetParametersDetailByIdParams
) => {
  const {
    financialProductId = 0,
    productLineId = 0,
    onSuccess,
    cacheTime
  } = params
  const {
    data: parametersDetail,
    isLoading,
    refetch
  } = useQuery('getParametersDetail', {
    enabled: !!financialProductId && !!productLineId,
    queryFn: () => getParametersDetailById(productLineId, financialProductId),
    onSuccess,
    cacheTime,
    onError: handleErrorFeedback
  })

  const parametersDetailFiltered = React.useMemo(
    () => filterParametersDetail(parametersDetail ?? []),
    [parametersDetail]
  )

  return { parametersDetail, isLoading, refetch, parametersDetailFiltered }
}
