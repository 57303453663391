import NpsDTO from '@volvo-apps/shared/models/NpsDTO'
import { CmsFields, NpsService } from '../service/Nps.service'
import NpsUserDTO from '../../models/NpsUserDTO'

/**
 * @returns NpsDTO[]
 */
export async function getAllNps() {
  const service = new NpsService()
  const response = await service.getAll()

  return response.data.map((nps) => new NpsDTO().fromJSON(nps))
}

/**
 * @returns NpsDTO
 */
export async function getAvailableNps() {
  const service = new NpsService()
  const response = await service.getNPSUser()

  return new NpsDTO().fromJSON(response.data)
}

/**
 * @returns NpsDTO[]
 */
export async function getAllCms() {
  const service = new NpsService()
  const response = await service.getAllCms()

  return response.data.map((nps) => new NpsDTO().fromJSON(nps))
}

/**
 * @param id The id of the nps
 * @returns nps
 */
export async function getNpsById(id: number) {
  const service = new NpsService()
  const response = await service.getById(id)
  return new NpsDTO().fromJSON(response.data)
}

/**
 * @param id The id of the nps
 * @returns Delete nps
 */
export async function deleteNps(id: number) {
  const service = new NpsService()
  const response = await service.deleteNps(id)

  return response
}

/**
 * @param updatedNps The updated nps
 */
export async function updateNps(updatedNps: CmsFields) {
  const service = new NpsService()
  const response = await service.updateNps(updatedNps)

  return new NpsDTO().fromJSON(response.data)
}

/**
 *
 * @param payload The payload to create the nps
 */
export async function createNps(payload: CmsFields) {
  const service = new NpsService()
  const response = await service.createNps(payload)

  return new NpsDTO().fromJSON(response.data)
}

/**
 *
 * @param payload The payload to create the nps
 */
export async function addOrUpdateNPSUser(payload: NpsUserDTO) {
  const service = new NpsService()
  const response = await service.addOrUpdateNPSUser(payload)

  return new NpsUserDTO().fromJSON(response.data)
}
