import { useInfiniteQuery } from 'react-query'
import { HookQueryParams } from '../../../common/utils/types'
import ContractPaginationDTO from '../../../models/ContractPaginationDTO'
import { getContractByUser } from '../../actions/Finance'

type useGetPaginatedContractsParams = HookQueryParams<ContractPaginationDTO> & {
  valueToFilter?: string
  filters?: {
    valueToFilter: string
    attributeCodes: number[]
  }[]
}

/**
 * @param params params
 * @returns methods
 */
export const useGetPaginatedContracts = (
  params: useGetPaginatedContractsParams = {}
) => {
  const { retry, suspense, cacheTime, valueToFilter, filters } = params

  const fetchContracts = async ({ pageParam = 1 }) => {
    const data = await getContractByUser(pageParam, valueToFilter, filters)

    return data
  }

  const {
    data,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
    isFetched,
    refetch
  } = useInfiniteQuery({
    queryKey: ['getContractByUser', valueToFilter],
    queryFn: fetchContracts,
    getNextPageParam: (lastPage) => {
      if (lastPage.totalContracts === 0) return undefined
      const max = Math.ceil(lastPage.totalContracts / 30)
      return max > lastPage.pageNumberFilter
        ? lastPage.pageNumberFilter + 1
        : undefined
    },
    retry,
    suspense,
    cacheTime
  })

  return {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    isFetched,
    refetch,
    showData: (!isLoading && !isFetching) || isFetchingNextPage
  }
}
