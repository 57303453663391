import { getAllCmsProductLines } from '@volvo-apps/shared/core/actions/ProductLine'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all cms product lines
 *
 * @returns ProductLines, isLoading, refetch
 */
export const useGetCmsProductLines = () => {
  const {
    data: productLines,
    isLoading,
    refetch
  } = useQuery('cmsProductLines', {
    queryFn: getAllCmsProductLines,
    onError: handleErrorFeedback
  })

  return { productLines, isLoading, refetch }
}
