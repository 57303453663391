import { AxiosResponse } from 'axios'
import SimulationParameterDTO from '@volvo-apps/shared/models/SimulationParameterDTO'
import { GenericService } from './GenericService'

/**
 * SimulationParameterService
 */
export class SimulationParameterService extends GenericService<SimulationParameterDTO> {
  readonly URI: string = 'gateway/SimulationParameters'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   * Get all simulation parameters
   */
  public async getAll() {
    return super.get<AxiosResponse<SimulationParameterDTO[]>>('GetAll')
  }

  /**
   *
   * @param id The id of the simulation parameter
   */
  public async getSimulationParameter(id: number) {
    return super.get<AxiosResponse<SimulationParameterDTO>>('Get', { id })
  }

  /**
   * Delete simulation parameter
   *
   * @param id The id of the simulation parameter
   */
  public async deleteSimulationParameter(id: number) {
    return super.delete('Inactivate', { id })
  }

  /**
   * Update simulation parameter
   *
   * @param updatedSimulationParameters The updated simulation parameter
   */
  public async updateSimulationParameter(
    updatedSimulationParameters: SimulationParameterDTO
  ) {
    return super.put<AxiosResponse<SimulationParameterDTO>>('Update', {
      ...updatedSimulationParameters
    })
  }

  /**
   * Create simulation parameter
   *
   * @param simulationParameter The simulation parameter
   */
  public async createSimulationParameter(
    simulationParameter: SimulationParameterDTO
  ) {
    return super.post<AxiosResponse<SimulationParameterDTO>>(
      'Add',
      simulationParameter
    )
  }

  /**
   * Get all cms simulation parameters
   */
  public async getAllCms() {
    return super.get<AxiosResponse<SimulationParameterDTO[]>>('GetAllCms')
  }

  /**
   * Get parameters detail by id
   *
   * @param productLineId The product line id
   * @param financialProductId The financial product id
   */
  public async getParametersDetailById(
    productLineId: number,
    financialProductId: number
  ) {
    return super.get<AxiosResponse<SimulationParameterDTO>>(
      'GetParametersDetailById',
      {
        productLineId,
        financialProductId
      }
    )
  }
}
