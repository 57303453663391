import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as FaqCategoryRoutesEnum } from 'routes/cms/routesEnums'

import { useCreateFaqCategory } from 'hooks/faqCategories/useCreateFaqCategory'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { FaqCategoryForm } from '../Form/FaqCategoryForm'
import { FaqCategoryData } from '../Form/schema'
import { useFaqCategoryForm } from '../Form/useFaqCategoryForm'

/**
 * Faq Category Create
 *
 * @returns TSX
 */
function Create() {
  const { methods } = useFaqCategoryForm()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { mutate, isLoading } = useCreateFaqCategory({
    onSuccess: () => {
      toast.success(t('cms.faq.createCategory.success'))
      navigate(FaqCategoryRoutesEnum.FAQ_CATEGORIES_VIEW)
    }
  })

  const onSubmit = React.useCallback(
    (formData: FaqCategoryData) => {
      mutate(formData)
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.faq.createCategory.title')}
      </Typography>
      <FaqCategoryForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.faq.createCategory.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Create
export { Create }
