import { getAllCmsSimulationParameters } from '@volvo-apps/shared/core/actions/SimulationParameter'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get all cms simulation parameters
 *
 * @returns simulationParameters, isLoading, refetch
 */
export const useGetCmsSimulationParameters = () => {
  const {
    data: simulationParameters,
    isLoading,
    refetch
  } = useQuery('cmsSimulationParameters', {
    queryFn: getAllCmsSimulationParameters,
    onError: handleErrorFeedback
  })

  return { simulationParameters, isLoading, refetch }
}
