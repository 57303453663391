import HolidayDTO from '@volvo-apps/shared/models/HolidayDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudList } from 'components/CrudList'
import { FlexBox } from 'components/FlexBox'
import { PlainButton } from 'components/PlainButton'

import { RegistersRoutesEnum as HolidayRoutesEnum } from 'routes/cms/routesEnums'

import { useCrudHoliday } from 'hooks/cms/useCrudHoliday'

import Colors from 'constants/Colors'

import { formatDateAndLocale, setUTCStartOfDay } from 'common/utils/date'
import { useLanguage } from 'context/Language'

type HolidayJSON = ReturnType<HolidayDTO['toJSON']>

/**
 * Holidays List
 *
 * @returns TSX
 */
function List() {
  const { holidays, updateAsyncHoliday, getLoading } = useCrudHoliday()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const items = React.useMemo(() => {
    return (
      holidays?.map((item) => ({
        ...item.toJSON(),
        primary: formatDateAndLocale(item.date, 'DD [de] MMMM'),
        secondary: item.name,
        id: item.id
      })) ?? []
    )
  }, [holidays])

  const handleEdit = React.useCallback(
    (holiday: HolidayJSON) => {
      const path = HolidayRoutesEnum.HOLIDAYS_UPDATE.replace(
        ':id',
        holiday.id as string
      )

      navigate(path)
    },
    [navigate]
  )

  const handletoggle = React.useCallback(
    async (holiday: HolidayJSON, checked: boolean) => {
      const newHoliday = new HolidayDTO().fromJSON({
        ...holiday,
        date: setUTCStartOfDay(holiday.date as Date),
        active: checked
      })

      const updateResponse = await updateAsyncHoliday({
        holiday: newHoliday
      })

      toast.success(
        updateResponse.data.active
          ? t('cms.holidays.toggle.enabled')
          : t('cms.holidays.toggle.disabled')
      )
    },
    [updateAsyncHoliday, t]
  )

  const handleCreate = React.useCallback(() => {
    navigate(HolidayRoutesEnum.HOLIDAYS_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <FlexBox gap={4}>
        <PlainButton variant="contained" onClick={handleCreate}>
          {t('cms.holidays.list.button')}
        </PlainButton>
      </FlexBox>
    )
  }, [handleCreate, t])

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.holidays.list.title')}
      </Typography>

      <CrudList
        items={items}
        title={t('cms.holidays.list.subtitle')}
        toggleKey="active"
        handleEdit={handleEdit}
        handleToggle={handletoggle}
        getLoading={getLoading && !holidays}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
