import { deleteFaqCategory } from '@volvo-apps/shared/core/actions/Category'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useDeleteFaqCategoryParams = {
  onSuccess?: () => void
}

/**
 * Hook to delete one faq category
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useDeleteFaqCategory = (
  params: useDeleteFaqCategoryParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data } = useMutation('deleteFaqCategory', {
    mutationFn: ({ id }: { id: number }) => deleteFaqCategory(id),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, data, isLoading }
}
