import UserDTO from '@volvo-apps/shared/models/UserDTO'

export enum FormNamesEnum {
  personalInfo = 'personalInfo',
  addressInfo = 'addressInfo'
}

type Documents = {
  /**
   *
   */
  document: string
  isSignUpDocument: boolean
  verified: boolean
  isContractsAttached: boolean
}[]

/**
 *
 */
export type FormValuesPersonalInfo = Pick<
  UserDTO,
  'name' | 'birthday' | 'phoneNumber' | 'email'
> & {
  /**
   *
   */
  signUpDocument?: Documents[0]
}

/**
 *
 */
export type FormValuesAddressInfo = Pick<
  UserDTO,
  | 'address'
  | 'zipCode'
  | 'addressNumber'
  | 'addressComplement'
  | 'city'
  | 'state'
>

/**
 *
 */
export type FormValuesInfo =
  | FormValuesPersonalInfo
  | (FormValuesAddressInfo & {
      signUpDocument?: Documents[0]
    })

/**
 *
 */
export type FormProps = {
  /**
   *
   */
  onSave: (data: FormValuesPersonalInfo | FormValuesAddressInfo) => void
  /**
   *
   */
  isLoading?: boolean
  /**
   *
   */
  profilePicture?: string
}
