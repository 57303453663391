const authTokenKey = 'token'
const cmsKey = 'isCms'

/**
 * @returns auth token
 */
export const getAuthToken = () => {
  const token = JSON.parse(localStorage.getItem(authTokenKey) ?? '{}')
  return token
}

/**
 * unset auth token
 */
export const unsetAuthToken = () => {
  localStorage.removeItem(authTokenKey)
}

/**
 * @returns isCms
 */
export const getIsCms = () => {
  const isCms = localStorage.getItem(cmsKey) ?? false
  return isCms
}

/**
 * unset isCms
 */
export const unsetIsCms = () => {
  localStorage.removeItem(cmsKey)
}

/**
 * clear storage
 */
export const clearStorage = () => {
  unsetAuthToken()
  unsetIsCms()
}
