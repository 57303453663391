import { arraySerializableToObjectById } from 'common/utils/transform'

import { useGetCmsProductLines } from './useGetCmsProductLines'

/**
 * Hook to get product lines by id
 *
 * @returns productLinesById
 */
export const useGetCmsProductLinesById = () => {
  const { productLines } = useGetCmsProductLines()

  return { productLinesById: arraySerializableToObjectById(productLines || []) }
}
