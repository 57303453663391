import { getIntervalEnums } from '@volvo-apps/shared/core/actions/Notification'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

/**
 * Hook to get interval enums
 *
 * @returns days, isLoading, refetch
 */
export const useGetIntervalEnums = () => {
  const {
    data: intervals,
    isLoading,
    refetch
  } = useQuery('getIntervalEnums', {
    queryFn: getIntervalEnums,
    onError: handleErrorFeedback
  })

  return { intervals, isLoading, refetch }
}
