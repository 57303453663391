import { makeResolver } from 'common/utils/form'
import { domainValidator } from 'common/validators/domain'

/**
 *
 */
export type FunctionalDomainData = {
  /**
   *
   */
  domain: string
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    domain: domainValidator
  })
)
