import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { getByDocument } from '../../actions/ConsortiumClosedResources'
import ConsortiumClosedResourcesDocumentDTO from '../../../models/ConsortiumClosedResourcesDocumentDTO'

type useGetConsortiumClosedResourcesByDocumentParams =
  HookQueryParams<ConsortiumClosedResourcesDocumentDTO> & {
    document: string
  }

/**
 * Hook to get all consortium closed resources by document
 *
 * @param params params
 * @returns PartsAndServices, isLoading, refetch
 */
export const useGetConsortiumClosedResourcesByDocument = (
  params: useGetConsortiumClosedResourcesByDocumentParams
) => {
  const { onSuccess, onError, onSettled, document, enabled, cacheTime, retry } =
    params
  const {
    data: consortiumClosedResources,
    isLoading,
    refetch
  } = useQuery(['getByDocument', document], {
    queryFn: () => getByDocument(document),
    enabled,
    onSuccess,
    onError,
    onSettled,
    cacheTime,
    retry
  })

  return { consortiumClosedResources, isLoading, refetch }
}
