import React from 'react'

import { Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

type ValidationProps = {
  correct: boolean
  label: string
}

/**
 *
 * @param props ValidationProps
 * @returns TSX
 */
function Validation(props: ValidationProps) {
  const { correct, label } = props
  const icon = correct ? 'CheckCircleOutline' : 'HighlightOff'
  const color = correct ? Colors.light.success : Colors.light.error

  return (
    <FlexBox alignItems="center" gap={1} mb={0.25}>
      <Icon family="mui" name={icon} style={{ color, fontSize: 20 }} />
      <Typography variant="body2" fontSize={14}>
        {label}
      </Typography>
    </FlexBox>
  )
}

export default Validation
export { Validation }
