import { createBanners } from '@volvo-apps/shared/core/actions/Banner'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCreateBannersParams = {
  onSuccess?: () => void
}

/**
 * Hook to create a banners
 *
 * @param params params
 * @returns mutate, bannersConfig, isLoading, mutateAsync
 */
export const useCreateBanners = (params: useCreateBannersParams = {}) => {
  const { onSuccess } = params
  const {
    data: bannersConfig,
    isLoading,
    mutate,
    mutateAsync
  } = useMutation('createBanners', {
    mutationFn: createBanners,
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, bannersConfig, isLoading, mutateAsync }
}
