import React from 'react'

import { CustomRouteProps } from 'components/Project/CustomRoute/types'

import ConfirmData from 'pages/Confirm/Data'
import ConfirmEmail from 'pages/Confirm/Email'
import ConfirmPassword from 'pages/Confirm/Password'

export enum MainRoutes {
  EMAIL_CONFIRMATION = 'email_confirm',
  DATA_CONFIRMATION = 'data_confirm',
  DATA_DENY = 'data_deny',
  PASSWORD_CONFIRMATION = 'email_passChange'
}

const validationRoutes: CustomRouteProps[] = [
  {
    path: MainRoutes.DATA_CONFIRMATION,
    element: <ConfirmData confirm />
  },
  {
    path: MainRoutes.DATA_DENY,
    element: <ConfirmData confirm={false} />
  },
  {
    path: MainRoutes.EMAIL_CONFIRMATION,
    element: <ConfirmEmail />
  },
  {
    path: MainRoutes.PASSWORD_CONFIRMATION,
    element: <ConfirmPassword />
  }
]

export default validationRoutes
