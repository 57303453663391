import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'
import PermissionDTO from './PermissionDTO'

/**
 * RoleDTO
 */
export class RoleDTO extends Serializable {
  @jsonProperty(Number)
  id!: number

  @jsonProperty(Date)
  createdDate!: Date

  @jsonProperty(Date)
  modifiedDate!: Date

  @jsonProperty(Boolean)
  active!: boolean

  @jsonProperty(String)
  name!: string

  @jsonProperty(Array)
  permissions!: Array<PermissionDTO>
}

export default RoleDTO
