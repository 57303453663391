import 'reflect-metadata'
import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * LeadNotificationDTO
 */
class LeadNotificationDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('interval')
  @jsonProperty(String)
  public interval!: string

  @jsonName('dayWeek')
  @jsonProperty(String)
  public dayWeek!: string

  @jsonName('hourDay')
  @jsonProperty(String)
  public hourDay!: string

  @jsonName('email')
  @jsonProperty(String)
  public email!: string

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: string

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: string
}

export default LeadNotificationDTO
