import { useForm } from 'react-hook-form'

import { BannerData, schema } from './schema'

type BannerProps = {
  defaultValues?: Partial<BannerData>
}

/**
 *
 * @param props - BannerProps
 * @returns useForm methods
 */
export const useBannerForm = (props: BannerProps = {}) => {
  const { defaultValues } = props
  const methods = useForm<BannerData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  return { methods }
}
