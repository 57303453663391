import CampaignDTO from '@volvo-apps/shared/models/CampaignDTO'
import { CampaignService } from '../service/Campaign.service'

/**
 * @returns CampaignDTO[]
 */
export async function getAllCampaigns() {
  const service = new CampaignService()
  const response = await service.getAll()

  return response.data.map((campaigns) => new CampaignDTO().fromJSON(campaigns))
}

/**
 * @param id The id of the campaign
 * @returns campaign
 */
export async function getCampaign(id: number) {
  const service = new CampaignService()
  const response = await service.getCampaign(id)
  return new CampaignDTO().fromJSON(response.data)
}

/**
 * @param id The id of the campaign
 * @returns Delete campaign
 */
export async function deleteCampaign(id: number) {
  const service = new CampaignService()
  const response = await service.deleteCampaign(id)

  return response
}

/**
 * @param updatedCampaign The updated campaign
 */
export async function updateCampaign(
  updatedCampaign: Pick<
    CampaignDTO,
    'financialProduct' | 'productLine' | 'documentType' | 'id'
  >
) {
  const service = new CampaignService()
  const response = await service.updateCampaign(updatedCampaign)

  return response
}

/**
 *
 * @param payload The payload to create the campaign
 */
export async function createCampaign(
  payload: Pick<
    CampaignDTO,
    'financialProduct' | 'productLine' | 'documentType'
  >
) {
  const service = new CampaignService()
  const response = await service.createCampaign(payload)

  return response
}
