import { updateProfile } from '@volvo-apps/shared/core/actions/User'
import DocumentDTO from '@volvo-apps/shared/models/DocumentDTO'
import UserDTO from '@volvo-apps/shared/models/UserDTO'

import React, { useRef, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { useUser } from 'context'
import dayjs from 'dayjs'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { DialogDefault } from 'components/DialogDefault'

import { ReactComponent as Positioning } from 'assets/icons/positioning.svg'
import { ReactComponent as PositioningFilled } from 'assets/icons/positioningFilled.svg'
import { ReactComponent as User } from 'assets/icons/user.svg'
import { ReactComponent as UserFilled } from 'assets/icons/userFilled.svg'
import { handleErrorFeedback } from 'common/utils/error'
import { useLanguage } from 'context/Language'

import AccordionItem from './AccordionItem'
import AddressInfoForm from './AddressInfoForm'
import PersonalInfoForm from './PersonalInfoForm'
import { FormNamesEnum, FormValuesInfo } from './types'
import UserAvatar from './UserAvatar'

/**
 * @returns TSX
 */
function ProfileEditMenu() {
  const { userData, refetch } = useUser()
  const { t } = useLanguage()
  const [formExpanded, setFormExpanded] = useState<FormNamesEnum | null>(
    FormNamesEnum.personalInfo
  )
  const [profilePicture] = React.useState<string>(
    userData?.profilePicture || ''
  )

  const initialUserData = useRef<UserDTO>()
  const [openEmailDialogInfo, setOpenEmailDialogInfo] = useState(false)

  const toggleFormExpanded = (formName: FormNamesEnum) => () => {
    setFormExpanded((actual) => {
      return actual === formName ? null : formName
    })
  }

  const {
    mutateAsync,
    isLoading,
    data: mutationResponse
  } = useMutation({
    mutationFn: updateProfile,
    onError: handleErrorFeedback
  })

  const updateDataRef = React.useCallback((newData: UserDTO) => {
    initialUserData.current = Object.assign({}, newData)
  }, [])

  const handleUpdateProfile = (data: FormValuesInfo) => {
    let hasDocumentsChanged = false
    const documents = userData?.documents ?? []

    if (data.signUpDocument) {
      hasDocumentsChanged = !documents.some(
        (docDTO) => docDTO.document === data.signUpDocument?.document
      )
      if (hasDocumentsChanged) {
        const oldSignUpDocumentIndex = documents?.findIndex(
          (docDTO) => docDTO.isSignUpDocument
        )

        if (oldSignUpDocumentIndex !== -1) {
          documents[oldSignUpDocumentIndex] = new DocumentDTO().fromJSON({
            ...documents[oldSignUpDocumentIndex],
            document: data.signUpDocument.document,
            isSignUpDocument: true,
            verified: false,
            isContractsAttached: false
          })
        } else {
          documents.push(
            new DocumentDTO().fromJSON({
              document: data.signUpDocument.document,
              isSignUpDocument: true,
              verified: false,
              isContractsAttached: false
            })
          )
        }
      }
    }

    const newData = userData?.update({
      ...data,
      documents,
      profilePicture
    })

    if (!newData) return

    if (newData.birthday) {
      newData.birthday = dayjs(new Date(newData.birthday)).format('YYYY-MM-DD')
    }
    mutateAsync(newData)
      .then((response) => {
        setFormExpanded(null)
        toast.success(t('profile.menu.editProfile.success'))
        if (hasDocumentsChanged) {
          if (response) setOpenEmailDialogInfo(true)
        }
        refetch?.()
        updateDataRef(newData)
      })
      .catch(() => {
        if (initialUserData.current) {
          userData?.update(initialUserData.current)
        }
      })
  }

  const closeDialog = React.useCallback(() => {
    setOpenEmailDialogInfo(false)
  }, [])

  React.useEffect(() => {
    if (initialUserData.current || !userData) return

    updateDataRef(userData)
  }, [initialUserData, updateDataRef, userData])

  return (
    <Box width={(t) => t.breakpoints.values.md}>
      <Box mt={1}>
        <Box
          mx={5}
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
        >
          <UserAvatar />
          <Box>
            <Typography fontSize={16}>{userData?.name}</Typography>
          </Box>
        </Box>
        <Box mt={3} mx={3}>
          <AccordionItem
            formName={FormNamesEnum.personalInfo}
            title={t('profile.menu.profileEditPersonal.title')}
            subtitle={t('profile.menu.profileEditPersonal.description')}
            toggleFormExpanded={toggleFormExpanded}
            formExpanded={formExpanded}
            icon={<User />}
            iconActive={<UserFilled />}
          >
            <PersonalInfoForm
              onSave={handleUpdateProfile}
              isLoading={isLoading}
              profilePicture={profilePicture}
            />
            {openEmailDialogInfo && (
              <DialogDefault
                opened={openEmailDialogInfo}
                disableCancel
                confirmText={t(
                  'profile.menu.profileEditPersonal.dialog.confirm'
                )}
                title={t('profile.menu.profileEditPersonal.dialog.title')}
                description={t(
                  'profile.menu.profileEditPersonal.dialog.description',
                  [mutationResponse ?? '']
                )}
                onConfirm={closeDialog}
              />
            )}
          </AccordionItem>
          <AccordionItem
            formName={FormNamesEnum.addressInfo}
            title={t('profile.menu.profileEditAddress.title')}
            subtitle={t('profile.menu.profileEditAddress.description')}
            toggleFormExpanded={toggleFormExpanded}
            formExpanded={formExpanded}
            icon={<Positioning />}
            iconActive={<PositioningFilled />}
          >
            <AddressInfoForm
              onSave={handleUpdateProfile}
              isLoading={isLoading}
            />
          </AccordionItem>
        </Box>
      </Box>
    </Box>
  )
}

export default ProfileEditMenu
export { ProfileEditMenu }
