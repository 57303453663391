import { getProductLine } from '@volvo-apps/shared/core/actions/ProductLine'
import ProductLineDTO from '@volvo-apps/shared/models/ProductLineDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetProductLineParams = {
  onSuccess?: (ProductLine: ProductLineDTO) => void
  id?: number
}

/**
 * Hook to get product line
 *
 * @param params params
 * @returns ProductLine, isLoading, refetch
 */
export const useGetProductLine = (params: useGetProductLineParams) => {
  const { id, onSuccess } = params
  const {
    data: productLine,
    isLoading,
    refetch
  } = useQuery('getProductLine', {
    enabled: !!id,
    queryFn: () => getProductLine(id || 0),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { productLine, isLoading, refetch }
}
