import CampaignDTO from '@volvo-apps/shared/models/CampaignDTO'
import NotificationDTO from '@volvo-apps/shared/models/NotificationDTO'

import { useCallback } from 'react'

import { useForm } from 'react-hook-form'

import { setUTCEndOfDay, setUTCStartOfDay } from 'common/utils/date'
import {
  handleOptionAll,
  optionAllId,
  optionAllString
} from 'common/utils/pushNotifications'

import { PushNotificationsData, schema } from './schema'

type PushNotificationsProps = {
  defaultValues?: Partial<PushNotificationsData>
}

/**
 *
 * @param props - PushNotificationsProps
 * @returns useForm methods
 */
export const usePushNotificationsForm = (
  props: PushNotificationsProps = {}
) => {
  const { defaultValues } = props
  const methods = useForm<PushNotificationsData>({
    mode: 'onChange',
    resolver: schema,
    defaultValues
  })

  const getOnSubmitDTOs = useCallback(
    (formData: PushNotificationsData, oldNotification?: NotificationDTO) => {
      const {
        financialProduct,
        documentType,
        productLine,
        sendToAll,
        ...newNotification
      } = formData

      const notification = new NotificationDTO().fromJSON({
        ...oldNotification,
        ...newNotification,
        beginDate: setUTCStartOfDay(formData.beginDate),
        endDate: setUTCEndOfDay(formData.endDate),
        createdDate: undefined
      })
      const campaign = new CampaignDTO().fromJSON({
        documentType: handleOptionAll(documentType),
        financialProduct: handleOptionAll(Number(financialProduct)),
        productLine: handleOptionAll(Number(productLine)),
        sendToAll,
        sendToAllDocumentType: documentType === optionAllString,
        sendToAllProductLine: Number(productLine) === optionAllId,
        sendToAllFinancialProduct: Number(financialProduct) === optionAllId
      })

      return {
        campaign,
        notification
      }
    },
    []
  )

  return { methods, getOnSubmitDTOs }
}
