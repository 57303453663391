import { useMutation } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { updateUserFunctionCMS } from '../../actions/User'
import UserWithDocumentDTO from '../../../models/UserWithDocumentDTO'

type useUpdateUserFunctionParams = HookQueryParams<UserWithDocumentDTO>

/**
 *
 * @param params params
 * @returns userList, isLoading, refetch
 */
export const useUpdateUserFunction = (params: useUpdateUserFunctionParams) => {
  const { data: userFunction, ...rest } = useMutation('UpdateUserFunction', {
    mutationFn: (user: UserWithDocumentDTO) => updateUserFunctionCMS(user),
    ...params
  })

  return { userFunction, ...rest }
}
