import ConsortiumClosedResourcesDocumentDTO from '@volvo-apps/shared/models/ConsortiumClosedResourcesDocumentDTO'

import React from 'react'

import { Typography } from '@mui/material'

import { Modal } from 'components'
import { FlexBox } from 'components/FlexBox'
import { NoInfo } from 'components/NoInfo'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type ResultModalProps = {
  open: boolean
  setClosed: () => void
  consortiumClosedDocument?: ConsortiumClosedResourcesDocumentDTO
}

/**
 * @param props ResultModalProps
 * @returns TSX
 */
const ResultModal = (props: ResultModalProps) => {
  const { open, setClosed, consortiumClosedDocument } = props

  const { t } = useLanguage()

  const Content = React.useMemo(() => {
    let texts = {
      result: t('consortiumResources.modal.resultFail'),
      subtitle: t('consortiumResources.modal.subtitleFail')
    }

    if (consortiumClosedDocument) {
      const { clientName, document } = consortiumClosedDocument

      const documentInfo =
        (document.length > 11 ? 'CNPJ ' : 'CPF ') + document.toUpperCase()

      texts = {
        result:
          t('consortiumResources.modal.resultSuccess') +
          `${clientName.toUpperCase()}, ${documentInfo}.`,
        subtitle: t('consortiumResources.modal.subtitleSuccess')
      }
    }

    return (
      <FlexBox flexDirection="column">
        <Typography
          fontSize={16}
          color={Colors.light.greys.GREY_600}
          mb={2}
          whiteSpace="pre-line"
        >
          {texts.result}
        </Typography>
        <Typography fontSize={16} color={Colors.light.greys.GREY_600}>
          {texts.subtitle}
        </Typography>

        <NoInfo hideHeader width="100%" />
      </FlexBox>
    )
  }, [consortiumClosedDocument, t])

  return (
    <Modal
      showCloseButton
      disableBackdropClick
      title={
        <Typography
          fontSize={22}
          fontFamily="Volvo-Novum-Medium"
          color={Colors.light.text}
        >
          {t('consortiumResources.modal.title')}
        </Typography>
      }
      PaperProps={{ sx: { p: 1, borderRadius: 2.5 } }}
      content={Content}
      actions={[
        {
          children: t('general.ok').toUpperCase(),
          onClick: () => {
            setClosed()
          },
          style: {
            backgroundColor: Colors.light.interactDefault,
            width: '100%'
          },
          variant: 'contained'
        }
      ]}
      onClose={setClosed}
      open={open}
    />
  )
}

export default ResultModal
