import ParameterDetailDTO from '@volvo-apps/shared/models/ParameterDetailDTO'

import React from 'react'

import { Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { InputNumberBox } from '../../Partials/InputNumberBox'

type GracePeriodProps = {
  gracePeriodMonths: number
  onChange: (value: number) => void
  disabled: boolean
  gradePeriodDetail: ParameterDetailDTO
}

/**
 *
 * @param props GracePeriodProps
 * @returns TSX
 */
function GracePeriod(props: GracePeriodProps) {
  const {
    gracePeriodMonths,
    onChange,
    disabled,
    gradePeriodDetail: { maxValue, minValue, leapOfMonths }
  } = props

  const { t } = useLanguage()
  const _disabled = disabled || (!maxValue && !minValue)

  return (
    <FlexBox
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mb={4}
    >
      <Typography mb={2} variant="h6" color={Colors.light.greys.GREY_600}>
        {t('simulation.financingAmounts.gracePeriod.title')}
      </Typography>
      <InputNumberBox
        maxValue={maxValue}
        value={gracePeriodMonths}
        minValue={minValue}
        onChange={onChange}
        label={t('simulation.financingAmounts.gracePeriod.label')}
        steps={leapOfMonths}
        disabled={_disabled}
      />
    </FlexBox>
  )
}

export default GracePeriod
export { GracePeriod }
