import { jsonName, jsonProperty, Serializable } from 'ts-serializable'
import 'reflect-metadata'

/**
 * FinancialProductDTO
 */
class FinancialProductDTO extends Serializable {
  @jsonName('id')
  @jsonProperty(Number)
  public id!: number

  @jsonName('active')
  @jsonProperty(Boolean)
  public active!: boolean

  @jsonName('name')
  @jsonProperty(String)
  public name!: string

  @jsonName('modifiedDate')
  @jsonProperty(Date)
  public modifiedDate!: string

  @jsonName('createdDate')
  @jsonProperty(Date)
  public createdDate!: string
}

export default FinancialProductDTO
