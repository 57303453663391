import React from 'react'

import { useGetFinancialProducts } from 'hooks/financialProducts/useGetFinancialProducts'

import { optionAllId } from 'common/utils/pushNotifications'

import { Select } from '../Select'
import { SelectProps } from './types'

type FinancialProductsSelectProps = SelectProps & { productLine: number } & {
  appendOptions?: { label: string; value: string | number }[]
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function FinancialProductsSelect(props: FinancialProductsSelectProps) {
  const {
    name,
    label,
    margin,
    fullWidth,
    sx,
    labelAsValue,
    productLine,
    appendOptions = [],
    disabled,
    showOptionsOnlyAfterRequest
  } = props
  const { financialProducts } = useGetFinancialProducts({
    productLine: productLine === optionAllId ? 1 : productLine
  })

  const financialProductsOptions = React.useMemo(
    () =>
      financialProducts?.map((financialProduct) => ({
        label: financialProduct.name,
        value: labelAsValue ? financialProduct.name : financialProduct.id
      })) ?? [],
    [financialProducts, labelAsValue]
  )

  const options = [...appendOptions, ...financialProductsOptions]

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      options={
        showOptionsOnlyAfterRequest && options.length === appendOptions.length
          ? []
          : options
      }
      sx={sx}
      disabled={disabled}
    />
  )
}

export default FinancialProductsSelect
export { FinancialProductsSelect }
