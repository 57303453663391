import { createHoliday } from '@volvo-apps/shared/core/actions/Holiday'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCreateHolidayParams = {
  onSuccess?: () => void
}

/**
 * Hook to create a holiday
 *
 * @param params params
 * @returns mutate, holiday, isLoading
 */
export const useCreateHoliday = (params: useCreateHolidayParams = {}) => {
  const { onSuccess } = params
  const {
    data: holiday,
    isLoading,
    mutate
  } = useMutation('createHoliday', {
    mutationFn: createHoliday,
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, holiday, isLoading }
}
