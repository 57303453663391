import React from 'react'

import { NpsModals } from 'components/NpsModals'
import { PlainButton, PlainButtonProps } from 'components/PlainButton'

import { ReactComponent as Import } from '../../assets/icons/import.svg'

type ShareAndRateButtonProps = {
  label: string
  origin: string
} & PlainButtonProps

/**
 *
 * @param props ShareAndRateButtonProps
 * @returns TSX
 */
const ShareAndRateButton = (props: ShareAndRateButtonProps) => {
  const { label, origin, ...plainButtonProps } = props

  const [showNpsModals, setShowNpsModals] = React.useState(false)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    plainButtonProps.onClick?.(e)
    setShowNpsModals(true)
  }

  return (
    <>
      <PlainButton
        startIcon={<Import />}
        {...plainButtonProps}
        onClick={handleClick}
      >
        {label}
      </PlainButton>
      {showNpsModals && <NpsModals origin={origin} />}
    </>
  )
}

export default ShareAndRateButton
