import { updateTransitionInterval } from '@volvo-apps/shared/core/actions/Vehicle'
import TransitionIntervalDTO from '@volvo-apps/shared/models/TransitionIntervalDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateTransitionIntervalParams = {
  onSuccess?: (data: TransitionIntervalDTO) => void
}

/**
 * @param params - onSuccess
 * @returns Data and isLoading
 */
export const useUpdateTransitionInterval = (
  params: useUpdateTransitionIntervalParams = {}
) => {
  const { onSuccess } = params
  const { mutate, data, isLoading, mutateAsync } = useMutation(
    'updateTransitionInterval',
    {
      mutationFn: ({
        id,
        transitionTime
      }: {
        id: number
        transitionTime: number
      }) => updateTransitionInterval({ id, transitionTime }),
      onSuccess,
      onError: handleErrorFeedback
    }
  )

  return {
    mutate,
    data,
    isLoading,
    mutateAsync
  }
}
