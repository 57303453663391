import 'reflect-metadata'
import { jsonProperty, Serializable } from 'ts-serializable'

/**
 * InsuranceItemDTO
 */
class InsuranceItemDTO extends Serializable {
  @jsonProperty(String)
  nomeFabricante!: string

  @jsonProperty(String)
  descrModelo!: string

  @jsonProperty(String)
  anoModelo!: string

  @jsonProperty(String)
  placa!: string

  @jsonProperty(String)
  chassi!: string
}

export default InsuranceItemDTO
