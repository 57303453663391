import React, { PropsWithChildren } from 'react'

import { Box, BoxProps, Typography, TypographyProps } from '@mui/material'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

type ContractContentContainerProps = {
  title?: string | null
  boxProps?: BoxProps
  suffix?: React.ReactNode
  titleProps?: TypographyProps
}

/**
 *
 * @param props Component Props
 * @returns JSX
 */
const ContractContentContainer = (
  props: PropsWithChildren<ContractContentContainerProps>
) => {
  return (
    <Box width="60%" {...props.boxProps}>
      <FlexBox style={{ position: 'relative' }}>
        {props.title && (
          <Typography
            color={Colors.light.interactActive}
            fontSize={16}
            fontWeight={700}
            mb={4}
            mt={1}
            {...props.titleProps}
          >
            {props.title}
          </Typography>
        )}
        {props.suffix}
      </FlexBox>
      {props.children}
    </Box>
  )
}

export default ContractContentContainer
