import { makeResolver } from 'common/utils/form'
import {
  confirmPasswordValidator,
  oldPasswordValidator,
  passwordValidator
} from 'common/validators/password'

export const changePasswordSchema = makeResolver((Yup) =>
  Yup.object({
    oldPassword: oldPasswordValidator,
    password: passwordValidator,
    confirmPassword: confirmPasswordValidator
  })
)
