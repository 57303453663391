import { useQuery } from 'react-query'
import { searchNameByEmail } from '../../actions/LinkedDocuments'
import { HookQueryParams } from '../../../common/utils/types'

type useSearchNameByEmailParams = HookQueryParams<string> & {
  email: string
}

/**
 *
 * @param params params to use
 * @returns name, isLoading
 */
export const useSearchNameByEmail = (params: useSearchNameByEmailParams) => {
  const { email, ...rest } = params
  const { data: name, isLoading } = useQuery(['searchNameByEmail', email], {
    queryFn: () => searchNameByEmail(email),
    ...rest
  })

  return { name, isLoading }
}
