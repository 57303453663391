import React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { InputNumber, Select } from 'components'
import { Icon } from 'components/Icon'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

const AccordionStyled = styled(Accordion)(() => ({
  ':before': {
    content: 'none'
  }
}))

/**
 * Taxes
 *
 * @returns TSX
 */
function FinameTaxes() {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const { t } = useLanguage()

  const selectOptions = [
    {
      label: t('general.yes'),
      value: true
    },
    {
      label: t('general.no'),
      value: false
    }
  ]

  return (
    <AccordionStyled
      disableGutters
      expanded={expanded}
      style={{ boxShadow: 'none' }}
      onChange={() => setExpanded((prevState) => !prevState)}
    >
      <AccordionSummary
        expandIcon={<Icon family="mui" name="ExpandMore" />}
        style={{
          borderBottom: `1px solid ${Colors.light.greys.GREY_200}`
        }}
        sx={{
          ml: 2,
          p: 0,
          mb: 3
        }}
      >
        <Box width="100%">
          <Typography mb={2} fontSize={16} color={Colors.light.greys.GREY_600}>
            {t('cms.simulationParameters.form.productTax')}
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.tacValue')}
          name="taxes.tacValue"
          decimal
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.monthlyInterestRate')}
          name="taxes.monthlyInterestRate"
          decimal
        />
        <Select
          name="taxes.moneyLenderInsurance"
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.moneyLenderInsurance')}
          options={selectOptions}
        />
        <Select
          name="taxes.isSelicInterestRate"
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.isSelicInterestRate')}
          options={selectOptions}
        />

        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.program')}
          name="taxes.program"
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.subProgram')}
          name="taxes.subProgram"
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.bndesCondition')}
          name="taxes.bndesCondition"
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.bndesSequence')}
          name="taxes.bndesSequence"
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.volvoRate')}
          name="taxes.volvoRate"
          decimal
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.ipcaValue')}
          name="taxes.ipcaValue"
          decimal
        />
        <InputNumber
          margin="normal"
          fullWidth
          label={t('cms.simulationParameters.form.taxes.selicValue')}
          name="taxes.selicValue"
          decimal
        />
      </AccordionDetails>
    </AccordionStyled>
  )
}

export default FinameTaxes
export { FinameTaxes }
