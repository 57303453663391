import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'
import { userIsCMS } from '../../actions/User'

type useUserIsCmsParams = HookQueryParams<boolean>

/**
 *
 * @param params params
 * @returns userList, isLoading, refetch
 */
export const useUserIsCms = (params: useUserIsCmsParams = {}) => {
  const { onSuccess, onError, enabled } = params
  const {
    data: userIsCms,
    isLoading,
    refetch
  } = useQuery('userIsCMS', {
    queryFn: userIsCMS,
    enabled,
    onSuccess,
    onError
  })

  return { userIsCms, isLoading, refetch }
}
