import React, { useMemo } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'
import { useParams } from 'react-router-dom'

import { Subtitle, Title } from 'components'
import { Icon } from 'components/Icon'
import { Loader } from 'components/Loader'

import useQuestionsByCategory from 'hooks/useQuestionsByCategory'

import { useFaq } from 'context/Faq'

/**
 * @returns TSX
 */
function FaqDetail() {
  const { id } = useParams()
  const { categories } = useFaq()
  const { data: faqQuestions, isLoading } = useQuestionsByCategory(Number(id))

  const currentCategory = useMemo(
    () =>
      categories?.find(
        ({ id: categoryId }) => Number(categoryId) === Number(id)
      ),
    [categories, id]
  )

  return isLoading ? (
    <Loader />
  ) : (
    <Box width={(t) => t.breakpoints.values.md}>
      <Title>{currentCategory?.label}</Title>
      <Box mt={2}>
        <Subtitle>{currentCategory?.description}</Subtitle>
      </Box>

      <Box mt={3}>
        {faqQuestions?.map((item) => (
          <Accordion key={item.title} elevation={1}>
            <AccordionSummary
              expandIcon={<Icon family="mui" name="ExpandMore" />}
            >
              <Typography>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.description}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  )
}

export default FaqDetail
