import React, { useState } from 'react'

import {
  VerifyEmailAndCode,
  NewPassword,
  PasswordRetrievalSuccess
} from './Steps'
import { FormInfo, PasswordRetrievalStepEnum } from './types'

/**
 * @returns TSX
 */
function PasswordRetrieval() {
  const [step, setStep] = useState(
    PasswordRetrievalStepEnum.VERIFY_EMAIL_AND_CODE
  )
  const [formInfo, setFormInfo] = useState<FormInfo>({
    email: '',
    verificationCode: ''
  })

  const persistFormInfo = (formInfo: FormInfo) => {
    setFormInfo(formInfo)
  }

  const goToStep = (stepToGo: PasswordRetrievalStepEnum) => {
    setStep(stepToGo)
  }

  const stepComponentByStep = {
    [PasswordRetrievalStepEnum.VERIFY_EMAIL_AND_CODE]: (
      <VerifyEmailAndCode
        goToStep={goToStep}
        persistFormInfo={persistFormInfo}
      />
    ),
    [PasswordRetrievalStepEnum.NEW_PASSWORD]: (
      <NewPassword goToStep={goToStep} formInfo={formInfo} />
    ),
    [PasswordRetrievalStepEnum.PASSWORD_RETRIEVAL_SUCCESS]: (
      <PasswordRetrievalSuccess />
    )
  }

  return stepComponentByStep[step]
}

export default PasswordRetrieval
export { PasswordRetrieval }
