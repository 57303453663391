import _deburr from 'lodash/deburr'
import ContractDTO from '../../models/ContractDTO'

const props: (keyof ContractDTO)[] = [
  'cpfCnpj',
  'numeroContrato',
  'nome',
  'tipoDeProduto'
]

const match = (value: string, input: string) => {
  return _deburr(value).match(new RegExp(input, 'ig'))
}

/**
 *
 * @param contract ContractDTO
 * @param input Input string
 * @returns Boolean
 */
export const handleContractFilter = (contract: ContractDTO, input: string) => {
  let isValid = false

  for (const key of props) {
    const value = contract[key]

    if (match(value as string, input)) {
      isValid = true
      break
    }
  }

  return isValid
}
