import { getFaqCategory } from '@volvo-apps/shared/core/actions/Category'
import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetFaqCategoryParams = {
  onSuccess?: (faqCategory: FaqCategoryDTO) => void
  id?: number
}

/**
 * Hook to get a faq category
 *
 * @param params params
 * @returns faqCategory, isLoading, refetch
 */
export const useGetFaqCategory = (params: useGetFaqCategoryParams) => {
  const { id, onSuccess } = params
  const {
    data: faqCategory,
    isLoading,
    refetch
  } = useQuery('getFaqCategory', {
    enabled: !!id,
    queryFn: () => getFaqCategory(id || 0),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { faqCategory, isLoading, refetch }
}
