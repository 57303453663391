import SimulationParameterDTO from '@volvo-apps/shared/models/SimulationParameterDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'
import { CrudDataGrid } from 'components/CrudDataGrid'
import { PlainButton } from 'components/PlainButton'

import { ParametersRoutesEnum as SimulationParametersRoutesEnum } from 'routes/cms/routesEnums'

import { useCrudSimulationParameter } from 'hooks/cms/useCrudSimulationParameter'
import { useGetCmsFinancialProductsById } from 'hooks/financialProducts/useGetCmsFinancialProductsById'
import { useGetCmsProductLinesById } from 'hooks/productLine/useGetCmsProductLinesById'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

import { columns } from './tableColumns'

/**
 * SimulationParams List
 *
 * @returns TSX
 */
function List() {
  const { simulationParameters, getLoading, updateAsyncSimulationParameter } =
    useCrudSimulationParameter()
  const { productLinesById } = useGetCmsProductLinesById()
  const { financialProductsById } = useGetCmsFinancialProductsById()

  const navigate = useNavigate()
  const { t } = useLanguage()

  const handleToggle = React.useCallback(
    async (simulationParameter: Record<string, unknown>, checked: boolean) => {
      const newSimulationParameter = new SimulationParameterDTO().fromJSON({
        ...simulationParameter,
        active: checked
      })

      const updateResponse = await updateAsyncSimulationParameter({
        simulationParameter: newSimulationParameter
      })

      toast.success(
        updateResponse.data.active
          ? t('cms.simulationParameters.form.paramOn')
          : t('cms.simulationParameters.form.paramOff')
      )
    },
    [t, updateAsyncSimulationParameter]
  )

  const handleCreate = React.useCallback(() => {
    navigate(SimulationParametersRoutesEnum.SIMULATION_PARAMS_CREATE)
  }, [navigate])

  const headerSuffix = React.useMemo(() => {
    return (
      <PlainButton variant="contained" onClick={handleCreate}>
        {t('cms.simulationParameters.list.button')}
      </PlainButton>
    )
  }, [handleCreate, t])

  const rows = React.useMemo(
    () =>
      simulationParameters?.map((simulationParameter) => {
        const productLine = productLinesById[simulationParameter.productLineId]
        const financialProduct =
          financialProductsById[simulationParameter.financialProductId]

        return {
          ...simulationParameter,
          productLine: productLine?.name,
          product: financialProduct?.name
        }
      }),
    [financialProductsById, productLinesById, simulationParameters]
  )

  return (
    <CmsChildrenWithHeader headerSuffix={headerSuffix}>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.simulationParameters.list.title')}
      </Typography>
      <CrudDataGrid
        columns={columns}
        rows={rows || []}
        loading={getLoading}
        hideFooter
        handleToggle={handleToggle}
        linkEditProps={{
          to: (id) =>
            SimulationParametersRoutesEnum.SIMULATION_PARAMS_UPDATE.replace(
              ':id',
              id.toString()
            )
        }}
      />
    </CmsChildrenWithHeader>
  )
}

export default List
export { List }
