import { updateFaqCategory } from '@volvo-apps/shared/core/actions/Category'
import FaqCategoryDTO from '@volvo-apps/shared/models/FaqCategoryDTO'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useUpdateFaqCategoryParams = {
  onSuccess?: () => void
}

/**
 * Hook to update one faq category
 *
 * @param params params
 * @returns mutate, data, isLoading
 */
export const useUpdateFaqCategory = (
  params: useUpdateFaqCategoryParams = {}
) => {
  const { onSuccess } = params
  const { mutate, isLoading, data, mutateAsync } = useMutation(
    'updateFaqCategory',
    {
      mutationFn: ({ faqCategory }: { faqCategory: FaqCategoryDTO }) =>
        updateFaqCategory(faqCategory),
      onSuccess,
      onError: handleErrorFeedback
    }
  )

  return { mutate, data, isLoading, mutateAsync }
}
