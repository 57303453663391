import UserSimulationDTO from '@volvo-apps/shared/models/UserSimulationDTO'

import { useEffect, useMemo } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { SimulationRoutesEnum } from 'routes/simulation.routes'

import { useUserSimulation } from 'context/Simulation'

type UserSimulationDTOParams = Array<
  keyof (typeof UserSimulationDTO)['prototype']
>

const CHOOSE_FINANCING_PARAMS: UserSimulationDTOParams = [
  'document',
  'documentType'
]

const FINANCING_AMOUNTS_PARAMS: UserSimulationDTOParams = [
  ...CHOOSE_FINANCING_PARAMS,
  'financialProduct',
  'productLine',
  'productValue',
  'vehicleId'
]

const SIMULATION_SUMMARY: UserSimulationDTOParams = [
  ...CHOOSE_FINANCING_PARAMS,
  'termMonths',
  'financialProductId',
  'financialProductName',
  'productLineId'
]

/**
 * Hook to validate if the user simulation has all the required data to the actual page
 *
 * @returns simulation and updateSimulation
 */
export const useUserSimulationValidated = () => {
  const { simulation, updateSimulation, clearSimulation } = useUserSimulation()
  const location = useLocation()
  const navigate = useNavigate()

  const paramsToValidateByRoute: Record<string, UserSimulationDTOParams> =
    useMemo(
      () => ({
        [SimulationRoutesEnum.CHOOSE_FINANCING]: CHOOSE_FINANCING_PARAMS,
        [SimulationRoutesEnum.FINANCING_AMOUNTS]: FINANCING_AMOUNTS_PARAMS,
        [SimulationRoutesEnum.SIMULATION_SUMMARY]: SIMULATION_SUMMARY
      }),
      []
    )

  useEffect(() => {
    const params = paramsToValidateByRoute[location.pathname]

    if (!params || !simulation) return

    const hasMissingParams = params.some((param) => {
      return !simulation[param]
    })

    if (hasMissingParams) {
      navigate(SimulationRoutesEnum.SIMULATION_MENU)
    }
  }, [location.pathname, navigate, paramsToValidateByRoute, simulation])

  return {
    simulation,
    updateSimulation,
    clearSimulation
  }
}
