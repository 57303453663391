import { getAllFinancialProducts } from '@volvo-apps/shared/core/actions/FinancialProduct'
import FinancialProductDTO from '@volvo-apps/shared/models/FinancialProductDTO'

import { useQuery } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useGetFinancialProductsParams = {
  onSuccess?: (data: FinancialProductDTO[]) => void
  productLine?: number
}

/**
 * Hook to get all financial products
 *
 * @param params params
 * @returns financialProducts, isLoading, refetch
 */
export const useGetFinancialProducts = (
  params: useGetFinancialProductsParams
) => {
  const { productLine, onSuccess } = params
  const {
    data: financialProducts,
    isLoading,
    refetch
  } = useQuery(['financialProducts', productLine], {
    enabled: !!productLine,
    queryFn: () => getAllFinancialProducts(productLine as number),
    onSuccess,
    onError: handleErrorFeedback
  })

  return { financialProducts, isLoading, refetch }
}
