import TermDTO from '@volvo-apps/shared/models/TermDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { RegistersRoutesEnum as TermRoutesEnum } from 'routes/cms/routesEnums'

import { useGetTerm } from 'hooks/terms/useGetTerm'
import { useUpdateTerm } from 'hooks/terms/useUpdateTerm'

import Colors from 'constants/Colors'

import { purify } from 'common/utils/string'
import { useLanguage } from 'context/Language'

import { TermData } from '../Form/schema'
import TermForm from '../Form/TermForm'
import { useTermForm } from '../Form/useTermForm'

/**
 * Term Update
 *
 * @returns TSX
 */
function Update() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { methods } = useTermForm({
    defaultValues: { text: '' }
  })

  const { term, isLoading: getLoading } = useGetTerm({
    id: Number(id),
    onSuccess: (term) => {
      methods.reset({
        text: purify(term.termText)
      })
    }
  })

  const { mutate, isLoading } = useUpdateTerm({
    onSuccess: () => {
      toast.success(t('cms.terms.update.success'))
      navigate(TermRoutesEnum.TERMS)
    }
  })

  const onSubmit = React.useCallback(
    (formData: TermData) => {
      const newTerm = new TermDTO().fromJSON({
        ...term,
        termText: formData.text,
        createdDate: undefined
      })

      mutate({ term: newTerm })
    },
    [term, mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.terms.update.title')}
      </Typography>
      <TermForm
        isLoading={isLoading || getLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.terms.update.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
