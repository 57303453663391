import VehicleDTO from '@volvo-apps/shared/models/VehicleDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { FlexBox } from 'components/FlexBox'

import Colors from 'constants/Colors'

import { ChooseFinancingForm } from '../../types'
import { VehicleImage } from '../VehicleImage'

type VehicleProps = {
  vehicle: VehicleDTO
  onChangeVehicle: (id: number) => void
}

/**
 *
 * @param props Vehicle Card Props
 * @returns TSX
 */
const Vehicle = (props: VehicleProps) => {
  const { vehicle, onChangeVehicle } = props

  const methods = useFormContext<ChooseFinancingForm>()

  const selectedVehicleId = methods.watch('vehicleId')

  return (
    <FlexBox
      sx={{
        width: '100%',
        justifyContent: 'center'
      }}
    >
      <FlexBox
        key={vehicle.id}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="calc(100% - 22px)"
        px={1.5}
        pb={1}
        border={`2px solid ${
          selectedVehicleId === vehicle.id
            ? Colors.light.blue
            : Colors.light.greys.GREY_200
        }`}
        borderRadius={2}
        boxShadow={`1px 1px 5px ${Colors.light.shadow}`}
        onClick={() => onChangeVehicle(vehicle.id)}
      >
        <VehicleImage id={vehicle.id} />
        <Typography sx={{ alignSelf: 'flex-start' }}>{vehicle.name}</Typography>
      </FlexBox>
    </FlexBox>
  )
}

export default React.memo(Vehicle)
