import { AxiosError } from 'axios'
import { t } from 'i18next'
import { UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'

type ValidationError = {
  PropertyName: string
  ErrorMessage: string
}

type AxiosValidationErrors = AxiosError<{ ValidationErrors: ValidationError[] }>

const handleApiValidationErrors = (
  err: AxiosError,
  onHasValidationErrors: (validationError: ValidationError) => void
) => {
  const errTyped = err as AxiosValidationErrors
  errTyped.response?.data?.ValidationErrors?.forEach((validationError) => {
    onHasValidationErrors(validationError)
  })
}

/**
 *
 * @param err AxiosError
 * @param methods UseFormReturn
 */
export const handleFormErrors = (err: unknown, methods: unknown) => {
  const errTyped = err as AxiosError<{
    ValidationErrors: {
      PropertyName: string
      ErrorMessage: string
    }[]
  }>
  const methodTyped = methods as UseFormReturn

  handleApiValidationErrors(errTyped, ({ PropertyName, ErrorMessage }) => {
    methodTyped.setError(PropertyName, {
      message: ErrorMessage
    })
  })
}

/**
 *
 * @param err Error to be handled
 */
export const handleErrorFeedback = (err: unknown) => {
  if (err instanceof AxiosError) {
    const validationErrors = err.response?.data.ValidationErrors

    if (Array.isArray(validationErrors) && validationErrors.length > 0) {
      handleApiValidationErrors(err, ({ ErrorMessage }) => {
        toast.error(ErrorMessage)
      })

      return
    }

    if (err.response?.status === 500) {
      toast.error(t('general.unexpectedError'))
      return
    }

    toast.error(
      err.response?.data?.Message ||
        err.response?.data?.title ||
        err.response?.data ||
        t('general.unexpectedError')
    )
    return
  }

  toast.error(t('general.unexpectedError'))
}

/**
 *
 * @param err Error to be handled
 * @param handle404 Function to be called when 404 is returned
 */
export const handleErrorFeedbackIgnoreNotFound = (
  err: unknown,
  handle404?: (err: AxiosError) => void
) => {
  if (err instanceof AxiosError) {
    if (err.response?.status === 404) {
      handle404?.(err)
      return
    }

    handleErrorFeedback(err)

    return
  }

  toast.error(t('general.unexpectedError'))
}

/**
 *
 * @param err AxiosError
 * @param methods react-hook-form methods
 */
export const handleLoginError = (err: AxiosError, methods: unknown) => {
  const methodTyped = methods as UseFormReturn

  if (err.response?.status === 401 || err.response?.status === 404) {
    const hasMessage = typeof err.response.data === 'string'
    const message = hasMessage
      ? (err.response.data as string)
      : t('login.error.' + err.response?.status)

    methodTyped.setError('password', {
      message
    })
  } else {
    handleErrorFeedback(err)
  }
}
