import LeadNotificationDTO from '@volvo-apps/shared/models/LeadNotificationDTO'

import React from 'react'

import { Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CmsChildrenWithHeader } from 'components/CmsChildrenWithHeader'

import { LeadsRoutesEnum } from 'routes/cms/routesEnums'

import { useUpdateLeadNotification } from 'hooks/leads/useUpdateLeadNotification'

import Colors from 'constants/Colors'

import { formatDate } from 'common/utils/date'
import { useLanguage } from 'context/Language'

import { AutomationForm } from '../Form/AutomationForm'
import { AutomationData } from '../Form/schema'
import { useAutomationForm } from '../Form/useAutomationForm'

/**
 * Leads automation Update
 *
 * @returns TSX
 */
function Update() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const leadNotification = state?.leadNotification as
    | LeadNotificationDTO
    | undefined

  const { methods } = useAutomationForm({
    defaultValues: {
      interval: '',
      dayWeek: '',
      hourDay: '',
      email: ''
    }
  })

  const { mutate, isLoading } = useUpdateLeadNotification({
    onSuccess: () => {
      toast.success(t('cms.holidays.update.success'))
      navigate(LeadsRoutesEnum.LEADS_AUTOMATION_LIST)
    }
  })

  React.useEffect(() => {
    if (leadNotification) {
      methods.reset({
        ...leadNotification,
        hourDay: formatDate(new Date(leadNotification.hourDay), 'HH:mm')
      })
    } else {
      navigate(LeadsRoutesEnum.LEADS_AUTOMATION_LIST)
    }
  }, [leadNotification, methods, navigate])

  const onSubmit = React.useCallback(
    (formData: AutomationData) => {
      const leadNotification = new LeadNotificationDTO().fromJSON({
        ...formData,
        createdDate: undefined
      })
      mutate({ leadNotification })
    },
    [mutate]
  )

  return (
    <CmsChildrenWithHeader>
      <Typography my={4} fontSize={20} color={Colors.light.greys.GREY_600}>
        {t('cms.leads.automation.update.title')}
      </Typography>
      <AutomationForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        methods={methods}
        submitButtonLabel={t('cms.leads.automation.update.button')}
      />
    </CmsChildrenWithHeader>
  )
}

export default Update
export { Update }
