import { useMutation } from 'react-query'

import { changeUseCMS } from '../../actions/User'
import UserCmsAccessDTO from '../../../models/UserCmsAccessDTO'
import { HookQueryParams } from '../../../common/utils/types'

type useChangeUseCmsParams = HookQueryParams<UserCmsAccessDTO>

/**
 *
 *
 * @param params params
 * @returns mutate, user, isLoading, mutateAsync
 */
export const useChangeUseCms = (params: useChangeUseCmsParams = {}) => {
  const { onSuccess, onError } = params
  const {
    mutate,
    isLoading,
    data: user,
    mutateAsync
  } = useMutation('changeUseCMS', {
    mutationFn: ({ user }: { user: UserCmsAccessDTO }) => changeUseCMS(user),
    onSuccess,
    onError
  })

  return { mutate, user, isLoading, mutateAsync }
}
