import React, { PropsWithChildren } from 'react'

import { BoxProps, Divider, Typography } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { PlainButton } from 'components/PlainButton'
import Upload from 'components/Upload/Upload'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type UploadContainerProps = BoxProps & {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  multiple?: boolean
  description?: string | null
  loading?: boolean
  accept?: string
}

/**
 * @param props UploadContainer Props
 * @returns TSX
 */
function UploadContainer(props: PropsWithChildren<UploadContainerProps>) {
  const { onChange, multiple, description, loading, accept = '.csv' } = props

  const { t } = useLanguage()

  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      gap={2}
      pt={2}
      pb={4}
      sx={{ backgroundColor: Colors.light.greys.GREY_100 }}
    >
      <Typography fontSize={16} color={Colors.light.text}>
        {t('general.form.uploadField.uploadFiles')}
      </Typography>

      <Divider sx={{ width: '100%' }} />

      <Typography fontSize={14} color={Colors.light.text} px={2}>
        {description}
      </Typography>
      <Upload onChange={onChange} multiple={multiple} accept={accept}>
        <PlainButton
          component="span"
          variant="outlined"
          style={{ color: Colors.light.text }}
          loading={loading}
          sx={{ px: 6 }}
        >
          {t('general.form.uploadField.upload')}
        </PlainButton>
      </Upload>
    </FlexBox>
  )
}

export default UploadContainer
export { UploadContainer }
