import { makeResolver } from 'common/utils/form'

/**
 *
 */
export type IncomeReportFormData = {
  /**
   *
   */
  document: string
  /**
   *
   */
  year?: number
  /**
   *
   */
  period?: string
  /**
   *
   */
  enableQuarter?: boolean
}

export const schema = makeResolver((Yup) =>
  Yup.object({
    document: Yup.string().required('validation.required.general'),
    year: Yup.number().required('validation.required.general'),
    period: Yup.string(),
    enableQuarter: Yup.boolean()
  })
)
