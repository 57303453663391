import React from 'react'

import { Typography, BoxProps } from '@mui/material'

import { FlexBox } from 'components/FlexBox'
import { Icon } from 'components/Icon'
import { LinearProgress } from 'components/LinearProgress'
import { Link } from 'components/Project'

import Colors from 'constants/Colors'

import { useLanguage } from 'context/Language'

type ProgressCardProps = {
  boxProps?: BoxProps
  detailsLabel?: {
    to: string
    state: unknown
  }
  title: string
  subTitle: React.ReactNode
  linearProgress?: {
    currentLabel?: string | null
    maxLabel?: string | null
    current: number
    max: number
  }
  active?: boolean
  titleSuffix?: React.ReactNode
  titlePrefix?: React.ReactNode
  titleSx?: BoxProps['sx']
}

/**
 *
 * @param props ProgressCardProps
 * @returns TSX
 */
function ProgressCard(props: ProgressCardProps) {
  const {
    title,
    subTitle,
    boxProps,
    detailsLabel,
    linearProgress,
    active = true,
    titleSuffix,
    titlePrefix,
    titleSx
  } = props

  const { t } = useLanguage()

  const subTitleComponent =
    typeof subTitle === 'string' ? (
      <Typography fontSize={12} color={Colors.light.greys.GREY_600}>
        {subTitle}
      </Typography>
    ) : (
      subTitle
    )

  return (
    <FlexBox
      flexDirection="column"
      justifyContent="space-between"
      {...boxProps}
    >
      <FlexBox flexDirection="column">
        <FlexBox justifyContent="space-between" alignItems="center">
          <FlexBox alignItems="center" sx={titleSx}>
            {titlePrefix}
            <Typography variant="body1" color={Colors.light.blue}>
              {title}
            </Typography>
            {titleSuffix}
          </FlexBox>
          {detailsLabel && (
            <Link
              to={detailsLabel.to}
              state={detailsLabel.state}
              color={Colors.light.interactActive}
              fontSize={14}
              sx={{ pointerEvents: active ? 'all' : 'none' }}
            >
              {t('general.seeDetails').toUpperCase()}
            </Link>
          )}
        </FlexBox>
        {subTitleComponent}
      </FlexBox>
      {linearProgress && (
        <FlexBox flexDirection="column" mt={2}>
          {linearProgress.currentLabel && linearProgress.maxLabel && (
            <FlexBox justifyContent="space-between" alignItems="center">
              <FlexBox alignItems="center">
                <Typography fontSize={14} color={Colors.light.greys.GREY_600}>
                  {linearProgress.currentLabel}
                </Typography>
                <Icon
                  family="mui"
                  name="Done"
                  sx={{ color: Colors.light.check }}
                  fontSize="small"
                />
              </FlexBox>
              <Typography fontSize={14} color={Colors.light.greys.GREY_600}>
                {linearProgress.maxLabel}
              </Typography>
            </FlexBox>
          )}

          <LinearProgress
            current={linearProgress.current}
            max={linearProgress.max}
            sx={{
              backgroundColor: Colors.light.greys.GREY_200,
              borderRadius: 50
            }}
          />
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default ProgressCard
export { ProgressCard }
