import { useQuery } from 'react-query'

import { HookQueryParams } from '../../../common/utils/types'

import { getRentalDetails } from '../../actions/Sales'
import { RentalDetailsDTO } from '../../../models/RentalDetailsDTO'

type useGetRentalDetailsParams = HookQueryParams<RentalDetailsDTO> & {
  document: string
}

/**
 * @param params params
 * @returns data, isLoading, refetch
 */
export const useGetRentalDetails = (
  params: useGetRentalDetailsParams = {
    document: ''
  }
) => {
  const { onSuccess, onError, document, suspense, enabled, cacheTime, retry } =
    params

  const { data, isLoading, refetch } = useQuery('getRentalDetails', {
    queryFn: () => getRentalDetails(document),
    onSuccess,
    onError,
    suspense,
    enabled,
    cacheTime,
    retry
  })

  return { data, isLoading, refetch }
}
