import React from 'react'

import { Select } from '../Select'
import { SelectProps } from './types'

type DocumentTypeSelectProps = SelectProps & {
  appendOptions?: { label: string; value: string | number }[]
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function DocumentTypeSelect(props: DocumentTypeSelectProps) {
  const {
    name,
    label,
    margin,
    fullWidth,
    sx,
    appendOptions = [],
    disabled
  } = props

  const documentTypes = [
    {
      label: 'Pessoa Física',
      value: 'CPF'
    },
    {
      label: 'Pessoa Jurídica',
      value: 'CNPJ'
    } ?? []
  ]

  const options = [...appendOptions, ...documentTypes]

  return (
    <Select
      name={name}
      margin={margin}
      fullWidth={fullWidth}
      label={label}
      sx={sx}
      options={options ?? []}
      disabled={disabled}
    />
  )
}

export default DocumentTypeSelect
export { DocumentTypeSelect }
