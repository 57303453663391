import { jsonName, jsonProperty, Serializable } from 'ts-serializable'

/**
 * ConsortiumClosedResourcesDocumentDTO
 */
class ConsortiumClosedResourcesDocumentDTO extends Serializable {
  @jsonName('clientName')
  @jsonProperty(String)
  public clientName!: string

  @jsonName('document')
  @jsonProperty(String)
  public document!: string
}

export default ConsortiumClosedResourcesDocumentDTO
