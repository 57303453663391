import { t } from 'i18next'
import { toast } from 'react-toastify'

import { useGetCmsFaqCategory } from 'hooks/faqCategories/useGetCmsFaqCategory'

import { useDeleteFaqCategory } from '../faqCategories/useDeleteFaqCategory'
import { useUpdateFaqCategory } from '../faqCategories/useUpdateFaqCategory'

/**
 * Hook to use CRUD operations on faq categories
 *
 * @returns faqCategory, deleteFaqCategory, updateFaqCategory, refetch, isLoading, getLoading, deleteLoading, updateLoading
 */
export const useCrudFaqCategories = () => {
  const { faqCategory, refetch, isLoading: getLoading } = useGetCmsFaqCategory()
  const { mutate: deleteFaqCategory, isLoading: deleteLoading } =
    useDeleteFaqCategory({
      onSuccess: () => {
        refetch()
        toast.success(t('cms.pushNotifications.delete.success'))
      }
    })
  const {
    mutate: updateFaqCategory,
    isLoading: updateLoading,
    mutateAsync: updateAsyncFaqCategory
  } = useUpdateFaqCategory({
    onSuccess: refetch
  })

  const isLoading = getLoading || deleteLoading || updateLoading

  return {
    faqCategory,
    deleteFaqCategory,
    updateFaqCategory,
    updateAsyncFaqCategory,
    refetch,
    isLoading,
    getLoading,
    deleteLoading,
    updateLoading
  }
}
