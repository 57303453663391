import { createSimulationParameter } from '@volvo-apps/shared/core/actions/SimulationParameter'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCreateSimulationParameterParams = {
  onSuccess?: () => void
}

/**
 * Hook to create a simulation parameter
 *
 * @param params params
 * @returns mutate, simulationParameter, isLoading
 */
export const useCreateSimulationParameter = (
  params: useCreateSimulationParameterParams = {}
) => {
  const { onSuccess } = params
  const {
    data: simulationParameter,
    isLoading,
    mutate
  } = useMutation('createSimulationParameter', {
    mutationFn: createSimulationParameter,
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, simulationParameter, isLoading }
}
