import UserDTO from '@volvo-apps/shared/models/UserDTO'
import { AxiosResponse } from 'axios'
import DocumentDTO from '../../models/DocumentDTO'
import UserCmsAccessDTO from '../../models/UserCmsAccessDTO'
import UserReportByDocResponseDTO from '../../models/UserReportByDocResponseDTO'
import UserReportDTO from '../../models/UserReportDTO'
import { GenericService } from './GenericService'
import ContractPaginationDTO from '../../models/ContractPaginationDTO'
import UserWithDocumentDTO from '../../models/UserWithDocumentDTO'
import VerifiedDocumentDTO from '../../models/VerifiedDocumentDTO'

/**
 * User service
 */
export class UserService extends GenericService<UserDTO> {
  readonly URI: string = 'gateway/User'

  /**
   * The constructor
   */
  constructor() {
    super()
    this.setUri(this.URI)
  }

  /**
   *
   * @param request Add Params
   */
  public async add(request: UserDTO) {
    return await super.post('Add', {
      entity: { ...request, documents: undefined },
      documents: request.documents
    })
  }

  /**
   *  Verifies the user
   *
   * @param userid The user id
   * @param emailVerificationCode The email code
   * @returns if verified
   */
  public async getEmailVerification(
    userid: number,
    emailVerificationCode: string
  ) {
    return await super.get('EmailVerification', {
      userid,
      emailVerificationCode
    })
  }

  /**
   *  Verifies the email code
   *
   * @param email The user id
   * @param emailVerificationCode The email code
   * @returns if verified
   */
  public async changePasswordByEmailVerificationCode(
    email: string,
    emailVerificationCode: string
  ) {
    return await super.get(
      `ChangePasswordByEmailVerificationCode?email=${email}`,
      {
        emailVerificationCode
      }
    )
  }

  /**
   * Verifies the client's document
   *
   * @param userdocument the document
   * @param VerificationCode the email code
   * @param shouldVerify verify or deny document
   * @returns if verified
   */
  public async getClientVerification(
    userdocument: string,
    VerificationCode: string,
    shouldVerify: boolean
  ) {
    const verificationEndpoint = shouldVerify
      ? 'ClientVerification'
      : 'ClientDeny'

    return await super.get(verificationEndpoint, {
      userdocument,
      VerificationCode
    })
  }

  /**
   *
   */
  public async getProfile() {
    return await super.get('GetProfile')
  }

  /**
   * Finds an address
   *
   * @param cep The CEP to find
   * @returns The data
   */
  public async getCepInfo(cep: string) {
    return await super.get('GetCepInfo', { cep })
  }

  /**
   *
   */
  public async getEstados<T>() {
    return (await super.get('GetEstados')) as { data: T }
  }

  /**
   *
   * @param params Func params
   * @param params.siglaEstado Sigla estado
   */
  public async getMunicipiosBySiglaEstado<T>(params: { siglaEstado: string }) {
    return (await super.get('GetMunicipiosBySiglaEstado', params)) as {
      data: T
    }
  }

  /**
   *
   * @param request UserDTO
   */
  public async updateProfile(request: UserDTO) {
    return await super.put<AxiosResponse<string | undefined>>('UpdateProfile', {
      entity: { ...request, documents: undefined },
      documents: request.documents
    })
  }

  /**
   * Sends the verification code to the user's email
   *
   * @param email The user's email
   * @returns The data
   */
  public async generateVerificationCode(email: string) {
    return await super.put('GenerateVerificationCode', { email })
  }

  /**
   * Changes the user's password
   *
   * @param params Function params
   * @param params.email User email
   * @param params.password User new password
   * @param params.verificationCode The verification code that was sent to user email
   */
  public async changePasswordVerificationCode(
    params: Pick<UserDTO, 'email' | 'password'> & { verificationCode: string }
  ) {
    return super.patch('ChangePasswordVerificationCode', params)
  }

  /**
   * Validates the verification code to be used in password change
   *
   * @param email The user's email
   * @param verificationCode The verification code that was sent to user email
   */
  public async validateVerificationCode(
    email: string,
    verificationCode: string
  ) {
    return await super.patch('CheckVerificationCode', {
      email,
      verificationCode
    })
  }

  /**
   * Removes a user
   *
   * @returns if the user was removed
   */
  public async deleteCurrentUser() {
    return await super.delete('DeleteCurrentUser')
  }

  /**
   * Updates the password
   *
   * @param oldPassword Current password
   * @param password The new password
   * @returns the code
   */
  public async changePassword(oldPassword: string, password: string) {
    return await super.patch<AxiosResponse<UserDTO>>('ChangePassword', {
      oldPassword,
      password
    })
  }

  /**
   *
   */
  public async getFirstAccess() {
    // TODO: Fix type
    return super.get('IsFirstAccess')
  }

  /**
   *
   */
  public async updateFirstAccess() {
    return await super.post('SetFirstAccessFalse', {})
  }

  /**
   *
   */
  public async getProfileWithActiveDocuments() {
    return super.get('GetProfileWithActiveDocuments')
  }

  /**
   *
   */
  public async getUsersListWithDocumentCms() {
    return super.get<AxiosResponse<UserWithDocumentDTO[]>>(
      'GetUsersListWithDocumentCms'
    )
  }

  /**
   *
   */
  public async getUsersListToResetPasswordCms() {
    return super.get<AxiosResponse<UserWithDocumentDTO[]>>(
      'GetUsersListToResetPasswordCms'
    )
  }

  /**
   *
   * @param id id
   */
  public async getUsersListWithDocumentCmsById(id: number) {
    return super.get<AxiosResponse<UserWithDocumentDTO>>(
      `GetUsersListWithDocumentCmsById?id=${id}`
    )
  }

  /**
   *
   * @param user user
   */
  public async updateUserFunctionCMS(user: UserWithDocumentDTO) {
    return super.patch<AxiosResponse<UserWithDocumentDTO[]>>(
      `UpdateUserFunctionCms`,
      { ...user }
    )
  }

  /**
   *
   * @param user UserCmsAccessDTO
   */
  public async changeUseCMS(user: UserCmsAccessDTO) {
    return super.put<AxiosResponse<UserCmsAccessDTO[]>>('ChangeUseCMS', {
      ...user
    })
  }

  /**
   *
   */
  public async userIsCMS() {
    return super.get<AxiosResponse<boolean>>('UserIsCMS')
  }

  /**
   * @param userReportDTO The dto to generate the report
   * @returns Promise
   */
  public async getUsersDataReport(userReportDTO: UserReportDTO) {
    return super.get<AxiosResponse<string>>(
      `GetUsersDataReport?beginDateStr=${userReportDTO.beginDateStr}&endDateStr=${userReportDTO.endDateStr}`
    )
  }

  /**
   *
   */
  public async getActiveDocumentsWithContracts() {
    return await super.get<
      AxiosResponse<{
        entity: UserDTO
        documents?: DocumentDTO[]
      }>
    >('GetActiveDocuments')
  }

  /**
   *
   * @param document The selected document.
   * @param page The page number.
   */
  public async getActiveContractsByDocuments(document: string, page: number) {
    return await super.get<AxiosResponse<ContractPaginationDTO>>(
      `GetActiveContractsByDocument?document=${document}&page=${page}`
    )
  }

  /**
   * @param document string
   */
  public async getUserDataReportByDoc(document: string) {
    return super.get<AxiosResponse<UserReportByDocResponseDTO>>(
      `GetUserDataReportByDoc?document=${document}`
    )
  }

  /**
   * @param document CPF or CNPJ
   */
  public async resendRegistrationMail(document: string) {
    return super.get<AxiosResponse<UserCmsAccessDTO>>(
      `ResendSignUpEmail?document=${document}`
    )
  }

  /**
   * @param document CPF or CNPJ
   */
  public async resetPassword(document: string) {
    return super.get<AxiosResponse<UserCmsAccessDTO>>(
      `ResetPassword?document=${document}`
    )
  }

  /**
   * @param document string
   */
  public async checkIfDocumentHasContracts(document: string) {
    return super.get<AxiosResponse<void>>(
      `CheckIfDocumentHasContracts?document=${document}`
    )
  }

  /**
   * @returns Promise
   */
  public async hasAutbankContract() {
    return super.get('HasAutbankContract') as unknown as {
      /**
       *
       */
      data: boolean
    }
  }

  /**
   *
   */
  public async getVerifiedDocuments() {
    return await super.get<AxiosResponse<VerifiedDocumentDTO[]>>(
      'GetVerifiedDocuments'
    )
  }
}
