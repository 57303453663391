import { NpsOriginEnum } from '@volvo-apps/shared/common/utils/enums'
import { useGetPaginatedContracts } from '@volvo-apps/shared/core/hooks/contracts'

import React, { useEffect, useMemo } from 'react'

import { useInView } from 'react-intersection-observer'

import ContentContainer from 'components/ContentContainer/ContentContainer'
import { FlexBox } from 'components/FlexBox'
import { Loader } from 'components/Loader'
import { NoInfo } from 'components/NoInfo'

import { useLanguage } from 'context/Language'

import { TicketsAccordion } from './components'

/**
 * @returns TSX
 */
function Tickets() {
  const [ref, inView] = useInView()
  const { t } = useLanguage()

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    isFetched,
    showData
  } = useGetPaginatedContracts({
    retry: 0,
    suspense: false,
    cacheTime: 0
  })

  const items = useMemo(() => {
    return (
      data?.pages.flatMap((page) => {
        const contracts = page.contracts || []
        const rentalDetails = page.rentalDetails || []

        const rentalInvoices = rentalDetails
          .filter(
            (rentalDetail) =>
              rentalDetail.hasAdditionalFile || rentalDetail.hasMonthlyBillFile
          )
          .flatMap((rental) => rental.rentalInvoices?.Response?.Faturas ?? [])

        return contracts.length > 0 || rentalInvoices.length > 0
          ? [...contracts, ...rentalInvoices]
          : []
      }) ?? []
    )
  }, [data])

  useEffect(() => {
    if (inView && hasNextPage && !isFetching) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, inView, isFetching])

  return (
    <ContentContainer title={t('financial.menu.tickets').toUpperCase()}>
      <FlexBox gap={2} flexDirection="column" mb={2}>
        {showData && (
          <>
            {items && items.length > 0 ? (
              items.map((contract, index) =>
                contract ? (
                  <TicketsAccordion
                    key={index}
                    contract={contract}
                    npsOrigin={NpsOriginEnum.SHARE_TICKET}
                  />
                ) : null
              )
            ) : isFetched ? (
              <NoInfo />
            ) : null}
          </>
        )}
        <Loader
          ref={ref}
          hideCircularProgress={!isLoading && !isFetching}
          size={20}
          boxProps={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
        />
      </FlexBox>
    </ContentContainer>
  )
}

export default Tickets
export { Tickets }
