import React from 'react'

import { Divider, Typography, Icon } from '@mui/material'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { toast } from 'react-toastify'

import { FlexBox } from 'components/FlexBox'
import { PlainButton } from 'components/PlainButton'
import { Upload } from 'components/Upload'

import Colors from 'constants/Colors'

import { ReactComponent as TrashCan } from 'assets/icons/trashcan.svg'
import { getImageFromBase64 } from 'common/utils/image'
import { useLanguage } from 'context/Language'
import { VehicleData } from 'pages/cms/Parameters/Vehicles/Form/schema'
import { BannerData } from 'pages/cms/Registers/Banners/Form/schema'

type FormContextType = BannerData | VehicleData

/**
 *
 */
export type UploadImageFieldProps = {
  /**
   *
   */
  name: 'banners' | 'vehiclePictures'
  /**
   *
   */
  description?: string | null
  /**
   *
   */
  maxFiles?: number
  /**
   *
   */
  onRemove?: (objId?: number) => void
  /**
   *
   */
  makeFooter?: (index: number) => React.ReactNode
  /**
   *
   */
  width: {
    /**
     *
     */
    min: number
    /**
     *
     */
    max: number
  }
  /**
   *
   */
  height: {
    /**
     *
     */
    min: number
    /**
     *
     */
    max: number
  }
}

/**
 *
 * @param props Props
 * @returns TSX
 */
function UploadImageField(props: UploadImageFieldProps) {
  const { name, description, maxFiles, onRemove, makeFooter, width, height } =
    props
  const methods = useFormContext<FormContextType>()
  const { fields, append, remove } = useFieldArray({
    name,
    control: methods.control
  })

  const { t } = useLanguage()

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const maxFilesHandled = maxFiles
        ? maxFiles - fields.length
        : e.target.files.length

      Array.from(e.target.files)
        .slice(0, maxFilesHandled)
        .forEach((file) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onloadend = () => {
            if (reader.result) {
              const base64 = reader.result.toString().split(',')[1]
              const img = new Image()
              img.src = getImageFromBase64(base64)

              img.onload = () => {
                if (
                  img.width > width.max ||
                  img.width < width.min ||
                  img.height > height.max ||
                  img.height < height.min
                ) {
                  const translateKey =
                    width.min !== width.max
                      ? 'imageErrorMaxDimension'
                      : 'imageErrorDimension'

                  toast.error(
                    t(`general.form.uploadField.${translateKey}`, [
                      width.max,
                      height.max
                    ])
                  )
                } else append({ base64, fileName: file.name })
              }
            }
          }
        })
    }
  }

  return (
    <FlexBox
      gap={2}
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
    >
      <FlexBox gap={4}>
        {fields.map((item, index) => (
          <FlexBox key={item.id} flexDirection="column">
            <FlexBox
              style={{
                border: `2px solid ${Colors.light.tint}`,
                borderRadius: '4px',
                position: 'relative'
              }}
            >
              <FlexBox
                onClick={() => {
                  onRemove?.(item.objId)
                  remove(index)
                }}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  backgroundColor: Colors.light.background,
                  borderRadius: 0.6,
                  p: 0.5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
              >
                <Icon component={TrashCan} />
              </FlexBox>
              <img
                src={
                  item.base64 ? getImageFromBase64(item.base64) : item.azureUrl
                }
                width="357px"
                height="200px"
              />
            </FlexBox>
            {makeFooter?.(index)}
          </FlexBox>
        ))}
      </FlexBox>

      {fields.length < (maxFiles || Number.MAX_SAFE_INTEGER) && (
        <FlexBox
          flexDirection="column"
          alignItems="center"
          gap={2}
          pt={2}
          pb={4}
          sx={{ backgroundColor: Colors.light.greys.GREY_100 }}
        >
          <Typography fontSize={16} color={Colors.light.text}>
            {t('general.form.uploadField.uploadFiles')}
          </Typography>

          <Divider sx={{ width: '100%' }} />

          <Typography fontSize={14} color={Colors.light.text} px={2}>
            {description}
          </Typography>

          <Upload onChange={uploadImage} multiple>
            <PlainButton
              component="span"
              variant="outlined"
              style={{ color: Colors.light.text }}
              sx={{ px: 6 }}
            >
              {t('general.form.uploadField.upload')}
            </PlainButton>
          </Upload>
        </FlexBox>
      )}
      <Typography fontSize={14} color={Colors.light.text} px={2}>
        {t('general.form.uploadField.dimension', [width.max, height.max])}
      </Typography>
    </FlexBox>
  )
}

export default UploadImageField
export { UploadImageField }
