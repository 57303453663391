import { createVehicle } from '@volvo-apps/shared/core/actions/Vehicle'

import { useMutation } from 'react-query'

import { handleErrorFeedback } from 'common/utils/error'

type useCreateVehicleParams = {
  onSuccess?: () => void
}

/**
 * Hook to create a vehicle
 *
 * @param params params
 * @returns mutate, vehicle, isLoading
 */
export const useCreateVehicle = (params: useCreateVehicleParams = {}) => {
  const { onSuccess } = params
  const {
    data: vehicle,
    isLoading,
    mutate
  } = useMutation('createVehicle', {
    mutationFn: createVehicle,
    onSuccess,
    onError: handleErrorFeedback
  })

  return { mutate, vehicle, isLoading }
}
