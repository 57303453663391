import React, { useCallback } from 'react'

import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { Loader } from 'components/Loader'

import { useVehiclesAndProductLines } from 'hooks/useVehiclesAndProductLines'

import { toCurrency } from 'common/utils/money'
import { useLanguage } from 'context/Language'

import { VehicleList } from '../../Partials/VehicleList'
import { ChooseFinancingForm } from '../../types'

/**
 * @returns TSX
 */
function ChooseVehicle() {
  const methods = useFormContext<ChooseFinancingForm>()
  const [tab, setTab] = React.useState<number>()

  const { t } = useLanguage()

  const handleOnSuccess = useCallback(
    (
      data: Parameters<Parameters<typeof useVehiclesAndProductLines>['0']>['0']
    ) => {
      const { key, title } = data.productLinesParsed[0]
      methods.setValue('productLine', { id: key, name: title })
      setTab(key)
    },
    [methods]
  )

  const { vehiclesAndProductLines, isLoading } =
    useVehiclesAndProductLines(handleOnSuccess)

  const onChangeVehicle = useCallback(
    (id: number) => {
      methods.setValue('vehicleId', id)
      methods.trigger('vehicleId')
    },
    // Disable because 'methods' was being added to the dependency array and causing the carousel to re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehiclesAndProductLines?.productLinesParsed]
  )

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
    methods.setValue('vehicleId', undefined)
    const productLine = vehiclesAndProductLines?.productLinesParsed.find(
      (productLine) => productLine.key === newValue
    )

    if (!productLine) return

    methods.setValue(
      'productValue',
      toCurrency(productLine.minimumValue).withoutSymbol
    )
    methods.setValue('productLine', { id: newValue, name: productLine.title })
  }

  return (
    <Box width="100%">
      {isLoading || !tab ? (
        <Loader
          boxProps={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            my: 6
          }}
        />
      ) : (
        <>
          <Tabs value={tab} onChange={handleChangeTab} sx={{ mb: 2 }}>
            {vehiclesAndProductLines?.productLinesParsed?.map((item, index) => (
              <Tab key={index} value={item.key} label={item.title} />
            ))}
          </Tabs>
          <VehicleList
            onChangeVehicle={onChangeVehicle}
            items={vehiclesAndProductLines?.vehiclesByProductLineId[tab] ?? []}
          />
        </>
      )}
      <Typography fontSize={12} color={(t) => t.palette.error.light} ml={2}>
        {t(methods.formState.errors.vehicleId?.message || '')}
      </Typography>
    </Box>
  )
}

export default ChooseVehicle
export { ChooseVehicle }
