import { verifyEmailCode } from '@volvo-apps/shared/core/actions/User'

import React, { useCallback } from 'react'

import GenericConfirmation from '..'

import ReactMarkdown from 'react-markdown'

import { useLanguage } from 'context/Language'

const params = ['email', 'emailVerificationCode']
/**
 * A component that renders a data confirmation.
 *
 * @returns The component JSX.
 */
function ConfirmPassword(): JSX.Element {
  const { t } = useLanguage()

  const check = useCallback(async (cbParams: Record<string, unknown>) => {
    const email = String(cbParams[params[0]])
    const emailVerificationCode = String(cbParams[params[1]])

    return await verifyEmailCode(email, emailVerificationCode)
  }, [])

  return (
    <GenericConfirmation
      title={t('general.confirmedPasswordTitle')}
      paramNames={params}
      checkFunction={check}
    >
      <p>
        <ReactMarkdown>{String(t('general.confirmedPassword'))}</ReactMarkdown>
      </p>
    </GenericConfirmation>
  )
}

export default ConfirmPassword
