import { useQuery } from 'react-query'
import { getAllLinkedDocuments } from '../../actions/LinkedDocuments'
import { HookQueryParams } from '../../../common/utils/types'
import LinkedDocumentsDTO from '../../../models/LinkedDocumentsDTO'

type useLinkedDocumentsParams = HookQueryParams<LinkedDocumentsDTO[]>

/**
 *
 * @param params params to use
 * @returns linkedDocuments, isLoading
 */
export const useLinkedDocuments = (params: useLinkedDocumentsParams = {}) => {
  const {
    data: linkedDocuments,
    isLoading,
    refetch
  } = useQuery('linkedDocuments', getAllLinkedDocuments, {
    ...params
  })

  return { linkedDocuments, isLoading, refetch }
}
