import React from 'react'

import { Typography } from '@mui/material'
import { GridColumnHeaderParams } from '@mui/x-data-grid'

const CustomHeader = ({
  headerName,
  width
}: {
  headerName: string | undefined
  width: number | undefined
}) => (
  <Typography
    fontSize={14}
    maxWidth={width}
    flexWrap="wrap"
    whiteSpace="break-spaces"
  >
    {headerName}
  </Typography>
)

/**
 *
 * @param params props of the HeaderText
 * @returns JSX
 */
const HeaderText = (params: GridColumnHeaderParams) => {
  return (
    <CustomHeader
      headerName={params.colDef.headerName}
      width={params.colDef.width}
    />
  )
}

export default HeaderText
